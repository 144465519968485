import React, { useState, useEffect } from "react";
import logo from "../assets/IssuersApp-logo-vertical.svg";
// import macslogo from "../assets/images/macs-core-logo.png";
import DCCLlogo from "../assets/DCC-Logo.svg";
import man from "../assets/images/dashboard/user.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { withRouter } from "react-router";
import {
  firebase_app,
  googleProvider,
  facebookProvider,
  twitterProvider,
  githubProvider,
  Jwt_token,
} from "../data/config";
import { handleResponse } from "../services/fack.backend";
import { useAuth0 } from "@auth0/auth0-react";
import {
  Login,
  LOGIN,
  YourName,
  Password,
  RememberMe,
  LoginWithAuth0,
  LoginWithJWT,
} from "../constant";
import {
  loginService,
  getLoggedinUserInfo,
} from "../store/services/auth.service";
import { sendOTP, loginViaOtp } from "../store/services/loginOtp.service";
import InputMask from "react-input-mask";
import { Link } from "react-router-dom";
import Config from "../config/index";
import "./signin.css";

const config = require("../data/app_config.json");

const Signin = ({ history }) => {
  const { loginWithRedirect } = useAuth0();
  const [email, setEmail] = useState("");
  const [cnic, setCnic] = useState("");
  const [password, setPassword] = useState("");
  const [otp, setOtp] = useState("");

  const [Loading, setLoading] = useState(false);
  const [otpLoading, setOtpLoading] = useState(false);
  const [value, setValue] = useState(localStorage.getItem("profileURL" || man));

  const [otpMethod, setOtpMethod] = useState(false);
  const [passwordMethod, setPasswordMethod] = useState(true);
  const [EvotingMethod, setEvotingMethd] = useState(false);
  const [otpSent, setOtpSent] = useState(false);

  // errors
  const [emailError, setemailError] = useState(false);
  const [passwordError, setpasswordError] = useState(false);
  const [otpError, setOtpError] = useState(false);
  const [otpButtonText, setOtpButtonText] = useState("Send OTP");
  const [cnicError, setCnicError] = useState(false);
  const [togglePassword, setTogglePassword] = useState("password");

  useEffect(() => {
    document.title = Config.appTitle + " " + "(" + Config.appVersion + ")";
    if (value !== null) localStorage.setItem("profileURL", value);
    else localStorage.setItem("profileURL", man);
  }, [value]);

  const loginAuthPassword = async () => {
    if (email == "") {
      setemailError(true);
    } else {
      setemailError(false);
    }
    if (password == "") {
      setpasswordError(true);
    } else {
      setpasswordError(false);
    }
    if (email !== "" && password !== "") {
      try {
        setLoading(true);
        const response = await loginService(email, password);
        if (response.status === 200) {
          sessionStorage.setItem("token", response.data.token);
          sessionStorage.setItem("refreshToken", response.data.refreshToken);
          const userInfo = await getLoggedinUserInfo(email);
          if (userInfo.status == 200) {
            if (userInfo.data.data.user_type == "COMPANYUSER") {
              toast.success(userInfo.data.message);
              sessionStorage.setItem("email", email);
              sessionStorage.setItem("name", userInfo.data.data.name);
              sessionStorage.setItem("role", userInfo.data.data.role);
              sessionStorage.setItem("features", userInfo.data.data.features);
              // sessionStorage.setItem("token", userInfo.data.data.accessToken);
              if (
                userInfo.data.data.company_code &&
                userInfo.data.data.company_code.length === 1
              ) {
                sessionStorage.setItem(
                  "company_code",
                  userInfo.data.data.company_code[0]
                );
              }
              sessionStorage.setItem(
                "all_companies",
                JSON.stringify(userInfo.data.data.company_code)
              );
              window.location.replace(
                `${process.env.PUBLIC_URL}/admindashboard`
              );
              // window.location.replace(`${process.env.PUBLIC_URL}/election-voting-report`);
            } else {
              toast.error("Invalid Portal!");
              sessionStorage.removeItem("token");
              sessionStorage.removeItem("refreshToken");
            }
          } else {
            toast.error(userInfo.data.message);
            sessionStorage.removeItem("token");
            sessionStorage.removeItem("refreshToken");
          }
        } else {
          toast.error(
            "Oppss.. The password is invalid or the user does not have a password."
          );
        }
        setLoading(false);
      } catch (error) {
        setTimeout(() => {
          toast.error(
            "Oppss.. The password is invalid or the user does not have a password."
          );
        }, 200);
        setLoading(false);
      }
    }
  };

  const loginAuthOtp = async () => {
    if (email == "") {
      setemailError(true);
    } else {
      setemailError(false);
    }
    if (otp == "") {
      setOtpError(true);
    } else {
      setOtpError(false);
    }
    if (email !== "" && otp !== "") {
      try {
        setLoading(true);
        const response = await loginViaOtp(email, otp);
        if (response.status === 200) {
          // toast.success(response.data.message);
          // sessionStorage.setItem("email", email);
          // sessionStorage.setItem("name", response.data.data.name);
          // sessionStorage.setItem("role", response.data.data.role);
          // sessionStorage.setItem("features", response.data.data.features);
          // sessionStorage.setItem("token", response.data.data.accessToken);
          // sessionStorage.setItem(
          //   "company_code",
          //   response.data.data.company_code
          // );
          // window.location.replace(`${process.env.PUBLIC_URL}/admindashboard`);
          sessionStorage.setItem("token", response.data.token);
          const userInfo = await getLoggedinUserInfo(email);
          if (userInfo.status == 200) {
            if (userInfo.data.data.user_type == "COMPANYUSER") {
              toast.success(userInfo.data.message);
              sessionStorage.setItem("email", email);
              sessionStorage.setItem("name", userInfo.data.data.name);
              sessionStorage.setItem("role", userInfo.data.data.role);
              sessionStorage.setItem("features", userInfo.data.data.features);
              // sessionStorage.setItem("token", userInfo.data.data.accessToken);
              if (
                userInfo.data.data.company_code &&
                userInfo.data.data.company_code.length === 1
              ) {
                sessionStorage.setItem(
                  "company_code",
                  userInfo.data.data.company_code[0]
                );
              }

              window.location.replace(
                `${process.env.PUBLIC_URL}/admindashboard`
              );
              // window.location.replace(`${process.env.PUBLIC_URL}/election-voting-report`);
            } else {
              toast.error("Invalid Portal!");
              sessionStorage.removeItem("token");
            }
          } else {
            toast.error(userInfo.data.message);
            sessionStorage.removeItem("token");
          }
        } else {
          toast.error(
            "Oppss.. The otp is invalid or the user does not have an account."
          );
        }
        setLoading(false);
      } catch (error) {
        setTimeout(() => {
          toast.error(
            "Oppss.. The otp is invalid or the user does not have an account."
          );
        }, 200);
        setLoading(false);
      }
    }
  };

  const sendOTPToEmail = async () => {
    if (email == "") {
      setemailError(true);
    } else {
      setemailError(false);
    }

    if (email !== "") {
      try {
        setOtpLoading(true);
        const response = await sendOTP(email);
        if (response.status === 200) {
          toast.success(response.data.message);
          setOtpSent(true);
          setOtpButtonText("Check Email");
          setOtpLoading(false);
        } else {
          setOtpSent(false);
          setOtpLoading(false);

          toast.error("Oppss.. There was a problem sending OTP.");
        }
      } catch (error) {
        setTimeout(() => {
          toast.error("Oppss.. There was a problem sending OTP.");
        }, 200);
        setOtpSent(false);
        setOtpLoading(false);
      }
    }
    setOtpLoading(false);
  };

  const loginWithJwt = (email, password) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: { email, password },
    };

    return fetch("/users/authenticate", requestOptions)
      .then(handleResponse)
      .then((user) => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        setValue(man);
        localStorage.setItem("token", user);
        window.location.href = `${process.env.PUBLIC_URL}/admindashboard`;
        return user;
      });
  };
  const EvotingmethodCheck = () => {
    if (!cnic) {
      setCnicError(true);
    } else {
      setCnicError(false);
    }
  };
  return (
    <div className="main-login-div" style={{ height: "100dvh" }}>
      <div className="page-wrapper">
        <div className="container-fluid p-0">
          {/* <!-- login page start--> */}
          {/* <div className="authentication-main"> */}
          <div
            // className="authentication-main"
            className=""
          >
            <div className="row">
              <div className="col-md-12">
                <div className="auth-innerright">
                  <div className="col-12 authentication-box pt-5">
                    {/* <div className="text-center">
                      <img src={macslogo} width="130" alt="" />
                      <div className="text-center"></div>
                    </div> */}
                    <div className="card mt-4">
                      <div className="card-body">
                        <div className="text-center">
                          <img src={logo} alt="" className="py-3" width="120" />
                          {/* <h4>Share Registrar</h4> */}
                        </div>

                        {EvotingMethod ? (
                          <div className="d-flex justify-content-center">
                            {" "}
                            INVESSTOR
                          </div>
                        ) : (
                          ""
                        )}
                        {!otpMethod && !passwordMethod && !EvotingMethod ? (
                          <div>
                            {/* <button
                              className="btn btn-primary btn-block"
                              onClick={() => {
                                setPasswordMethod(false);
                                setOtpMethod(false);
                                setEvotingMethd(true)
                              }}
                            >
                              Login For Evoting
                            </button> */}
                            <button
                              className="btn btn-primary btn-block"
                              onClick={() => {
                                setPasswordMethod(true);
                                setOtpMethod(false);
                                setEvotingMethd(false);
                              }}
                            >
                              Login via Password
                            </button>
                            <button
                              className="btn btn-primary btn-block"
                              onClick={() => {
                                setOtpMethod(true);
                                setPasswordMethod(false);
                                setEvotingMethd(false);
                              }}
                            >
                              Login via OTP
                            </button>
                          </div>
                        ) : null}

                        {EvotingMethod ? (
                          <form className="theme-form pb-5">
                            <div className="form-group">
                              <label className="col-form-label pt-0 justify-content-center d-flex">
                                Enter CNIC to Login
                              </label>
                              <input
                                className="form-control"
                                type="cnic"
                                name="cnic"
                                value={cnic}
                                style={{
                                  width: "90%",
                                  margin: "auto",
                                  border: "1px solid #70ace7",
                                }}
                                onChange={(e) => setCnic(e.target.value)}
                                placeholder="Enter Your CNIC"
                              />
                              {cnicError && (
                                <p className="error-color">
                                  * CNIC is required
                                </p>
                              )}
                            </div>

                            <div className="form-group form-row mt-3 mb-0">
                              <button
                                className="btn btn-primary btn-block"
                                type="button"
                                style={{ width: "90%", margin: "auto" }}
                                onClick={() => EvotingmethodCheck()}
                                disabled={Boolean(Loading)}
                              >
                                {Loading ? (
                                  <>
                                    <span className="fa fa-spinner fa-spin"></span>
                                    <span> Loading...</span>
                                  </>
                                ) : (
                                  <span> Next</span>
                                )}
                              </button>

                              <Link
                                to="#"
                                className="my-3"
                                onClick={() => {
                                  setOtpMethod(false);
                                  setPasswordMethod(false);
                                  setEvotingMethd(false);
                                }}
                              >
                                Login Other method
                              </Link>
                            </div>
                          </form>
                        ) : null}

                        {passwordMethod ? (
                          <form className="theme-form">
                            <div className="form-group">
                              <label className="col-form-label pt-0">
                                {/* {YourName} */} Enter your email address
                              </label>
                              <input
                                className="form-control"
                                type="email"
                                name="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder="Email address"
                              />
                              {emailError && (
                                <p className="error-color">
                                  * Email is required
                                </p>
                              )}
                            </div>
                            <div className="form-group">
                              <label className="col-form-label">
                                {Password}
                              </label>
                              <div class="input-group mb-3">
                                <input
                                  className="form-control"
                                  type={togglePassword}
                                  name="password"
                                  placeholder="Password"
                                  value={password}
                                  onChange={(e) => setPassword(e.target.value)}
                                />
                                <div class="input-group-append">
                                  <span
                                    class="input-group-text"
                                    onClick={(e) => {
                                      if (togglePassword == "password") {
                                        setTogglePassword("text");
                                      } else {
                                        setTogglePassword("password");
                                      }
                                    }}
                                  >
                                    {togglePassword == "password" ? (
                                      <i class="fa fa-eye"></i>
                                    ) : (
                                      <i class="fa fa-eye-slash"></i>
                                    )}
                                  </span>
                                </div>
                              </div>
                              {passwordError && (
                                <p className="error-color">
                                  * Password is required
                                </p>
                              )}
                            </div>
                            {/* <div className="checkbox p-0">
                              <input id="checkbox1" type="checkbox" />
                              <label htmlFor="checkbox1">{RememberMe}</label>
                            </div> */}
                            <div className="form-group form-row mt-3 mb-0">
                              {/* <button
                              className="btn btn-primary btn-block"
                              type="button"
                              onClick={() => loginAuth()}
                            >
                              {Login}
                            </button> */}

                              <button
                                className="btn btn-primary btn-block mb-3"
                                type="button"
                                onClick={() => loginAuthPassword()}
                                disabled={Boolean(Loading)}
                              >
                                {Loading ? (
                                  <>
                                    <span className="fa fa-spinner fa-spin"></span>
                                    <span> Loading...</span>
                                  </>
                                ) : (
                                  <span> {Login}</span>
                                )}
                              </button>

                              {/* <Link
                                to="#"
                                className="my-3"
                                onClick={() => {
                                  setOtpMethod(true);
                                  setPasswordMethod(false);
                                }}
                              >
                                Login via OTP
                              </Link> */}
                            </div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <div>
                                <Link
                                  to="#"
                                  className="my-3"
                                  onClick={() => {
                                    setOtpMethod(true);
                                    setPasswordMethod(false);
                                  }}
                                >
                                  Login via OTP
                                </Link>
                              </div>
                              <div>
                                <Link to="/forget-password">
                                  Forgot Password
                                </Link>
                              </div>
                            </div>
                            {/* <hr style={{ width: "60%" }} /> */}
                            {/* <p
                            className="text-center"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              window.location.replace(
                                `${process.env.PUBLIC_URL}/forget-password`
                              );
                            }}
                          >
                            Forget Password
                          </p> */}

                            {/* <div
                              className="text-center opacity-50"
                              style={{ color: "grey" }}
                            >
                              <h6>{Config.appVersion}</h6>
                            </div> */}
                          </form>
                        ) : null}

                        {otpMethod ? (
                          <form className="theme-form">
                            <div className="form-group">
                              <label className="col-form-label pt-0">
                                {/* {YourName} */} Enter your email address
                              </label>
                              <div className="d-flex justify-content-between">
                                <input
                                  className="form-control"
                                  type="email"
                                  name="email"
                                  value={email}
                                  onChange={(e) => setEmail(e.target.value)}
                                  placeholder="Email address"
                                />
                                <button
                                  className="btn btn-primary"
                                  type="button"
                                  onClick={() => {
                                    sendOTPToEmail();
                                  }}
                                  disabled={otpLoading || otpSent}
                                >
                                  {otpLoading ? (
                                    <>
                                      <span className="fa fa-spinner fa-spin"></span>
                                      <span> Loading...</span>
                                    </>
                                  ) : (
                                    <span> {otpButtonText}</span>
                                  )}
                                </button>
                              </div>
                              {emailError && (
                                <span className="error-color">
                                  * Email is required
                                </span>
                              )}
                            </div>
                            {otpSent && (
                              <div className="form-group">
                                <label className="col-form-label">
                                  One-Time Password
                                </label>
                                <InputMask
                                  mask="999999"
                                  maskChar="-"
                                  className="form-control"
                                  type="text"
                                  name="otp"
                                  placeholder="Enter OTP Here"
                                  value={otp}
                                  onChange={(e) => setOtp(e.target.value)}
                                />
                                {otpError && (
                                  <p className="error-color">
                                    * OTP is required
                                  </p>
                                )}
                              </div>
                            )}
                            {/* <div className="checkbox p-0">
                              <input id="checkbox1" type="checkbox" />
                              <label htmlFor="checkbox1">{RememberMe}</label>
                            </div> */}
                            <div className="form-group form-row mt-3 mb-0">
                              {/* <button
                              className="btn btn-primary btn-block"
                              type="button"
                              onClick={() => loginAuth()}
                            >
                              {Login}
                            </button> */}

                              {otpSent && (
                                <button
                                  className="btn btn-primary btn-block"
                                  type="button"
                                  onClick={() => loginAuthOtp()}
                                  disabled={Boolean(Loading)}
                                >
                                  {Loading ? (
                                    <>
                                      <span className="fa fa-spinner fa-spin"></span>
                                      <span> Loading...</span>
                                    </>
                                  ) : (
                                    <span> {Login}</span>
                                  )}
                                </button>
                              )}

                              <Link
                                className="my-3"
                                to="#"
                                onClick={() => {
                                  setPasswordMethod(true);
                                  setOtpMethod(false);
                                }}
                              >
                                Login via Password
                              </Link>
                            </div>

                            {/* <hr style={{ width: "60%" }} /> */}
                            {/* <p
                            className="text-center"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              window.location.replace(
                                `${process.env.PUBLIC_URL}/forget-password`
                              );
                            }}
                          >
                            Forget Password
                          </p> */}
                            {/* <div
                              className="text-center opacity-50"
                              style={{ color: "grey" }}
                            >
                              <h6>{Config.appVersion}</h6>
                            </div> */}
                          </form>
                        ) : null}
                        {/* {(otpMethod || passwordMethod)
                        &&(
                          <> */}
                        <div className="text-center opacity-50 mt-3">
                          <span>Powered By</span>
                        </div>
                        <div className="text-center">
                          <img src={DCCLlogo} width="100" alt="" />
                          <div className="text-center"></div>
                        </div>

                        <hr style={{ width: "60%" }} />
                        <div
                          className="text-center opacity-50"
                          style={{ color: "grey" }}
                        >
                          <h6>
                            {Config.appTitle} {Config.appVersion}
                          </h6>
                        </div>
                        {/* </>
                        )} */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ToastContainer />
          {/* <!-- login page end--> */}
        </div>
      </div>
    </div>
  );
};

export default withRouter(Signin);
