import React, { useState, useEffect } from 'react'
import { PDFExport } from "@progress/kendo-react-pdf";
import PageTemplate from "./page-template";
import {
    getCompanies,
} from "../../../store/services/company.service";
import styled from 'styled-components';
import { TiTick } from "react-icons/ti";
import { numberWithCommas } from 'utilities/utilityFunctions';
export const AppendixII = ({ data, formTemplate = false }) => {
    const pdfExportComponent = React.useRef(null);
    const [CompanyData, setCompanyData] = useState(null)
    const [loading, setLoading] = useState(false);
    const [fontFamilyForStaticData, setFontFamilyForStaticData] = useState('Montserrat, sans-serif')
    const [fontSizeForStaticData, setFontSizeForStaticData] = useState('14px')
    const [fontfamilyforDynimicData, setFontFamilyForDynmicData] = useState('Segoe Print')
    const [fontColor, setFontColor] = useState('')
    const baseEmail = sessionStorage.getItem("email") || "";
    const [governance, setGovernance] = useState({})
    const getAllCompanies = async () => {
        setLoading(true)
        try {
            const response = await getCompanies(baseEmail);
            if (response.status === 200) {
                response.data.data = response.data.data.filter((item) => item?.active === 'Y')
                const parents = response.data.data;
                const company = parents?.find(ite => ite?.code == data?.company_code)
                let Allperson = company.governance && company.governance != "" ? JSON.parse(company.governance) : []
                let sec = Allperson.find(x => x.role == "Company Secretary" && x?.active?.toLowerCase() != 'n')
                let ceo = Allperson.find(x => x.role == "CEO" && x?.active?.toLowerCase() != 'n')


                if (sec) {
                    setGovernance(sec)
                } else if (ceo) {
                    setGovernance(ceo)
                }
                setCompanyData(company)
                setTimeout(() => {
                    setLoading(false)
                }, 1000);
            }
        } catch (error) {
            setLoading(false)
        }
    };
    useEffect(() => {
        getAllCompanies()
    }, [])
    return (
        <div>
            <div
                style={{ fontFamily: "sans-serif " }}
            >

                {formTemplate ?
                    <PDFExport
                        paperSize="A4"
                        margin="1cm"
                        scale={0.6}
                        fileName={` Appendix-II`}
                        pageTemplate={PageTemplate}
                        ref={pdfExportComponent}
                    >
                        <div style={{ fontFamily: `${fontFamilyForStaticData}`, fontSize: `${fontSizeForStaticData}`, fontWeight: 500 }}>
                            <FormName>
                                Appendix-II
                            </FormName>
                            <FormName>
                                [Regulation 119(3)]
                            </FormName>
                            <div className='row mt-4'>
                                <div className='col-md-12'>

                                    <HeadingWrapper className='text-center d-flex justify-content-center'>
                                        DECLARATION
                                    </HeadingWrapper>
                                    <div className='text-center d-flex justify-content-center'>
                                        (To be printed on stamp paper of requisite value)

                                    </div>

                                </div>
                            </div>



                            <div className='row'>
                                <div className='col-md-12' style={{ padding: '25px 50px', wordWrap: 'break-word' }}>
                                    <div>
                                        We, being the directors/CEO of ……………………………….. (name of the purchasing company), do hereby solemnly and sincerely declare that we have made a full inquiry into the affairs of the company and that, having done so, have formed the opinion that;

                                    </div>
                                    <Wrapper className="table-responsive mt-2" >
                                        <table
                                            className="table table-bordered"
                                            style={{ fontFamily: "Montserrat rev=1 !important" }}
                                        >
                                            <tbody>
                                                <tr>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}>• the funds required for the purchase of shares are available with the purchasing company</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}></td>


                                                </tr>
                                                <tr>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}>• there is no ground on which the purchasing company is unable to pay its debts.</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}>• barring any material unforeseen circumstances, or force majeure events, the purchasing company will remain solvent for the period of twelve months from the date of declaration.</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}></td>

                                                </tr>

                                            </tbody>
                                        </table>
                                    </Wrapper>


                                    <div className='my-2'>
                                        And we are fully aware/understand;
                                    </div>
                                    <Wrapper className="table-responsive mt-0" >
                                        <table
                                            className="table table-bordered"
                                            style={{ fontFamily: "Montserrat rev=1 !important" }}
                                        >
                                            <tbody>
                                                <tr>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}>• the funds required for the purchase of shares are available with the purchasing company• of the fact that in case we make any false statement about any of the above matters, we shall be liable for civil as well as criminal consequences in terms of section 88 and 496 of the Companies Act, 2017.</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}>• that there is no shareholding or management dispute in the purchasing company which could result in material adverse effect on its business.</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}>• the purchasing company is not restricted by any condition of any kind, and if so, or has obtained the relevant approvals.</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}>• there is no valid binding contract, which the purchasing company is party to, which restricts the purchasing company from purchasing its own shares.</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}>• that whatever stated above is true and correct to the best of our knowledge and belief based on the facts and financial position known to us on the date of this declaration.</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}></td>
                                                </tr>

                                            </tbody>
                                        </table>
                                    </Wrapper>
                                    <div style={{ fontWeight: "bold", fontSize: "10px" }} className='mt-2'>
                                        Deponents
                                    </div>
                                    <div style={{ width: "38%", fontSize: "10px" }} className='mt-2'>
                                        (Signed with names, Designations,
                                        residential addresses and CNIC numbers)

                                    </div>
                                    <div style={{fontSize: "10px" }} className='mt-2'>
                                       Date:_________
                                    </div>
                                    <div style={{ fontSize: "10px" }} className='align-items-start mt-2 d-flex justify-content-between'>
                                       <div>Place by:_________</div>
                                       <div>Verified/Attested</div>
                                    </div>
                                    <div style={{width:"38%", fontSize: "10px" }} className='align-items-center text-center mt-2 d-flex justify-content-end'>
                                    Class-I Magistrate / Oath Commissioner / Notary Public
                                    Seal of Attesting Officer
                                    </div>

                                </div>
                            </div>
                        </div>
                    </PDFExport>
                    : <PDFExport
                        paperSize="A4"
                        margin="1cm"
                        scale={0.6}
                        fileName={`Appendix-II`}
                        pageTemplate={PageTemplate}
                        ref={pdfExportComponent}
                    >
                           <div style={{ fontFamily: `${fontFamilyForStaticData}`, fontSize: `${fontSizeForStaticData}`, fontWeight: 500 }}>
                            <FormName>
                                Appendix-II
                            </FormName>
                            <FormName>
                                [Regulation 119(3)]
                            </FormName>
                            <div className='row mt-4'>
                                <div className='col-md-12'>

                                    <HeadingWrapper className='text-center d-flex justify-content-center'>
                                        DECLARATION
                                    </HeadingWrapper>
                                    <div className='text-center d-flex justify-content-center'>
                                        (To be printed on stamp paper of requisite value)

                                    </div>

                                </div>
                            </div>



                            <div className='row'>
                                <div className='col-md-12' style={{ padding: '25px 50px', wordWrap: 'break-word' }}>
                                    <div>
                                        We, being the directors/CEO of ……………………………….. (name of the purchasing company), do hereby solemnly and sincerely declare that we have made a full inquiry into the affairs of the company and that, having done so, have formed the opinion that;

                                    </div>
                                    <Wrapper className="table-responsive mt-2" >
                                        <table
                                            className="table table-bordered"
                                            style={{ fontFamily: "Montserrat rev=1 !important" }}
                                        >
                                            <tbody>
                                                <tr>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}>• the funds required for the purchase of shares are available with the purchasing company</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}></td>


                                                </tr>
                                                <tr>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}>• there is no ground on which the purchasing company is unable to pay its debts.</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}>• barring any material unforeseen circumstances, or force majeure events, the purchasing company will remain solvent for the period of twelve months from the date of declaration.</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}></td>

                                                </tr>

                                            </tbody>
                                        </table>
                                    </Wrapper>


                                    <div className='my-2'>
                                        And we are fully aware/understand;
                                    </div>
                                    <Wrapper className="table-responsive mt-0" >
                                        <table
                                            className="table table-bordered"
                                            style={{ fontFamily: "Montserrat rev=1 !important" }}
                                        >
                                            <tbody>
                                                <tr>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}>• the funds required for the purchase of shares are available with the purchasing company• of the fact that in case we make any false statement about any of the above matters, we shall be liable for civil as well as criminal consequences in terms of section 88 and 496 of the Companies Act, 2017.</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}>• that there is no shareholding or management dispute in the purchasing company which could result in material adverse effect on its business.</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}>• the purchasing company is not restricted by any condition of any kind, and if so, or has obtained the relevant approvals.</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}>• there is no valid binding contract, which the purchasing company is party to, which restricts the purchasing company from purchasing its own shares.</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}>• that whatever stated above is true and correct to the best of our knowledge and belief based on the facts and financial position known to us on the date of this declaration.</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}></td>
                                                </tr>

                                            </tbody>
                                        </table>
                                    </Wrapper>
                                    <div style={{ fontWeight: "bold", fontSize: "10px" }} className='mt-2'>
                                        Deponents
                                    </div>
                                    <div style={{ width: "38%", fontSize: "10px" }} className='mt-2'>
                                        (Signed with names, Designations,
                                        residential addresses and CNIC numbers)

                                    </div>
                                    <div style={{fontSize: "10px" }} className='mt-2'>
                                       Date:_________
                                    </div>
                                    <div style={{ fontSize: "10px" }} className='align-items-start mt-2 d-flex justify-content-between'>
                                       <div>Place by:_________</div>
                                       <div>Verified/Attested</div>
                                    </div>
                                    <div style={{width:"38%", fontSize: "10px" }} className='align-items-center text-center mt-2 d-flex justify-content-end'>
                                    Class-I Magistrate / Oath Commissioner / Notary Public
                                    Seal of Attesting Officer
                                    </div>

                                </div>
                            </div>
                        </div>
                        {/* <div style={{ fontFamily: `${fontFamilyForStaticData}`, fontSize: `${fontSizeForStaticData}`, fontWeight: 500 }}>
                            <FormName>
                                Appendix to Form-3
                            </FormName>
                            <div className='row'>
                                <div className='col-md-12' >
                                    <HeadingWrapper className='d-flex justify-content-center'>
                                        THE COMPANIES ACT, 2017
                                    </HeadingWrapper>

                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <HeadingWrapper className='d-flex justify-content-center'>
                                        THE COMPANIES REGULATIONS, 2024
                                    </HeadingWrapper>

                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <div style={{
                                        fontSize: '15px',
                                        // fontWeight: 'bold' 
                                    }} className='d-flex justify-content-center'>
                                        [Section 70(1)]
                                    </div>

                                </div>
                            </div>

                            <div className='row mt-4'>
                                <div className='col-md-12'>
                                    <div className='d-flex justify-content-center'>
                                        (On the letterhead of Auditors of the company/practicing Chartered Accountant/ Cost and Management Accountant)
                                    </div>

                                </div>
                            </div>
                            <div className='row mt-4'>
                                <div className='col-md-12'>
                                    <HeadingWrapper className='d-flex justify-content-center'>
                                        AUDITORS’ CERTIFICATE VERIFYING RECEIPT OF CONSIDERATION MONEY
                                    </HeadingWrapper>

                                </div>
                            </div>
                            <PartsWrapper className='mt-4 mb-3'>
                                PART-I
                            </PartsWrapper>
                            <div className='row'>
                                <div className='col-md-12' style={{ padding: '25px 50px', wordWrap: 'break-word' }}>
                                    <div>
                                        {`I / We have examined the record of M/S <Name of company>, and it is, hereby, reported that a total amount of Rs. < amount> has been received on account of consideration money against allotment of <# Number of shares > each of <Face value > by the company and shares have been issued to each allottee.`}
                                    </div>
                                    <div className='mt-2'>
                                        Signature:_____________________
                                    </div>
                                    <div className='mt-2'>
                                        Name:__________________________
                                    </div>
                                    <div className='mt-2'>
                                        Date:__________________________
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </PDFExport>}
            </div>
            <button disabled={loading} className='btn btn-danger' onClick={(e) => {
                // if(viewPdf)
                //   SetViewPDf(true)
                if (pdfExportComponent.current) {
                    pdfExportComponent.current.save();
                }
            }}>Download PDF</button>
        </div>
    )
}
const PartsWrapper = styled.div`
color: #121212;
text-decoration: underline;
justify-content: center;
display: flex;
margin-right: 15px;
font-size: 17px;
font-weight: bold;
`;
const ScrollWrapper = styled.div`
max-height: 50px;
overflow-y: scroll;
overflow-x: hidden;
::-webkit-scrollbar{
    height: 5px;
    width: 3px;
  }
  
  ::-webkit-scrollbar-track{
    background: #F9F9FB;
  }
  ::-webkit-scrollbar-thumb{
    background: #4E515680;
    border-radius: 5px;
  
  }
`;
const HeadingWrapper = styled.div`
font-size: 17px;
font-weight: bold;
`;
const FormName = styled.div`
color: #121212;
text-decoration: underline;
justify-content: end;
display: flex;
margin-right: 15px;
font-size: 17px;
font-weight: bold;
`;
const Wrapper = styled.div`
table.table-bordered{
    border: 1px solid #121212
     border-color: #121212; 
    // border:1px solid blue;
    // margin-top:20px;
  }
// table.table-bordered > thead > tr > th{
//     // border:1px solid blue;
//     border-color: #121212; 
// }
// table.table-bordered > tbody > tr > td{
//     // border:1px solid blue;
//     borderC-color: #121212; 
// }
 tr td {
    // border:1px solid blue;.
    border-color: #121212; 
}

`;
const WrapperDashed = styled.div`

  td {
    
    border: 1px dashed #dddcdc;
}

`;