import React, { useState, useEffect } from 'react'
import { PDFExport } from "@progress/kendo-react-pdf";
import PageTemplate from "./page-template";
import {
    getCompanies,
} from "../../../store/services/company.service";
import styled from 'styled-components';
import { TiTick } from "react-icons/ti";
import { numberWithCommas } from 'utilities/utilityFunctions';
import "./CommonForm.css"
export const StatutoryForm18 = ({ data, formTemplate = false }) => {
    const pdfExportComponent = React.useRef(null);
    const [CompanyData, setCompanyData] = useState(null)
    const [loading, setLoading] = useState(false);
    const [fontFamilyForStaticData, setFontFamilyForStaticData] = useState('Montserrat, sans-serif')
    const [fontSizeForStaticData, setFontSizeForStaticData] = useState('14px')
    const [fontfamilyforDynimicData, setFontFamilyForDynmicData] = useState('Segoe Print')
    const [fontColor, setFontColor] = useState('')
    const baseEmail = sessionStorage.getItem("email") || "";
    const [governance, setGovernance] = useState({})
    const getAllCompanies = async () => {
        setLoading(true)
        try {
            const response = await getCompanies(baseEmail);
            if (response.status === 200) {
                response.data.data = response.data.data.filter((item) => item?.active === 'Y')
                const parents = response.data.data;
                const company = parents?.find(ite => ite?.code == data?.company_code)
                let Allperson = company.governance && company.governance != "" ? JSON.parse(company.governance) : []
                let sec = Allperson.find(x => x.role == "Company Secretary" && x?.active?.toLowerCase() != 'n')
                let ceo = Allperson.find(x => x.role == "CEO" && x?.active?.toLowerCase() != 'n')


                if (sec) {
                    setGovernance(sec)
                } else if (ceo) {
                    setGovernance(ceo)
                }
                setCompanyData(company)
                setTimeout(() => {
                    setLoading(false)
                }, 1000);
            }
        } catch (error) {
            setLoading(false)
        }
    };
    useEffect(() => {
        getAllCompanies()
    }, [])
    return (
        <div>
            <div
                style={{ fontFamily: "sans-serif " }}
            >

                {formTemplate ?
                    <PDFExport
                        paperSize="A4"
                        margin="1cm"
                        scale={0.6}
                        fileName={`Form 18`}
                        pageTemplate={PageTemplate}
                        ref={pdfExportComponent}
                    >
                        <div style={{ fontFamily: `${fontFamilyForStaticData}`, fontSize: `${fontSizeForStaticData}`, fontWeight: 500 }}>
                            <FormName>
                                Form-18
                            </FormName>
                            <div className='row'>
                                <div className='col-md-12' >
                                    <HeadingWrapper className='d-flex justify-content-center'>
                                        TTHE COMPANIES ACT, 2017
                                    </HeadingWrapper>

                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <HeadingWrapper className='d-flex justify-content-center'>
                                    THE COMPANIES REGULATIONS, 2024
                                    </HeadingWrapper>

                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <div style={{
                                        fontSize: '15px',
                                        // fontWeight: 'bold' 
                                    }} className='d-flex justify-content-center'>
                                        [Section 123A(2) and Regulation 48(3) & 30]
                                    </div>

                                </div>
                            </div>

                            <div className='row mt-4'>
                                <div className='col-md-12'>
                                    <HeadingWrapper className='text-center d-flex justify-content-center'>
                                    DECLARATION BY MEMBER ABOUT CHANGE OF ULTIMATE BENEFICIAL OWNERS
                                    </HeadingWrapper>

                                </div>
                            </div>
                            <div className='row mt-4'>
                                <div className='col-md-12'>
                                    <HeadingWrapper style={{textDecoration:"underline"}} className='text-center d-flex justify-content-center'>
                                    OR PARTICULARS THEREOF   
                                     </HeadingWrapper>
                                </div>
                            </div>
                          

                            <div className='row'>
                                <div className='col-md-12' style={{ padding: '25px 50px', wordWrap: 'break-word' }}>
               
                {/* company info */}
                <div className='col-12 mb-3'>
                    <div className='d-flex'>
                        <span className='mr-1'>Name of Company</span>
                        <span className='inline-input-300'>
                            <div className='border-none border-bottom border-dark inline-input-h'></div>
                            {/* <input type='text' className='form-control border-none border-bottom border-dark inline-input-h' /> */}
                        </span>
                    </div>
                    <div className='d-flex'>
                        <span className=' mr-1'>CUIN</span>
                        <span className='inline-input-300'>
                        <div className='border-none border-bottom border-dark inline-input-h'></div>
                            {/* <input type='text' className='form-control border-none border-bottom border-dark inline-input-h' /> */}
                        </span>
                    </div>
                    <div className='d-flex'>
                        <span className=' mr-1'>Presented by</span>
                        <span className='inline-input-300'>
                        <div className='border-none border-bottom border-dark inline-input-h'></div>
                            {/* <input type='text' className='form-control border-none border-bottom border-dark inline-input-h' /> */}
                        </span>
                    </div>
                </div>
                {/* declaration 1*/}
                <div className='col-12 mb-3'>
                    <div >
                        <span className='mr-4'>1.</span>
                        <span className='word-spacing-25'>This is to declare that I (name of member in block letters) having CNIC/Passport </span>
                        <span>No.*</span>
                        <span className='inline-input-200'>
                            <div className='border-none border-bottom border-dark inline-input-h'></div> 
                        </span>
                        <span className='word-spacing-15'>and having address at</span>
                        <span className='inline-input-200'>
                        <div className='border-none border-bottom border-dark inline-input-h'></div>
                        </span>
                        <span className='word-spacing-15'>am a person whose name was </span>
                        <span className='word-spacing-10'>entered in the register of members of(name of the company) as the holder of(state the number and classes </span>
                        <span className='word-spacing-10'>of shares/extent of voting rights/control right etc.)</span>
                        <span className='inline-input-100'>
                        <div className='border-none border-bottom border-dark inline-input-h'></div>
                        </span>
                        <span>.</span>
                    </div>
                </div>
                <div className='col-12 mb-3'>
                    <div>
                        <span className='mr-4'>2.</span>
                        <span className='word-spacing-15'>With effect from the (date</span>
                        <span className='inline-input-100'>
                        <div className='border-none border-bottom border-dark inline-input-h'></div>
                        </span>
                        <span className='word-spacing-15'>), the (natural person(s)/particulars of the natural persons)*</span>
                        <span className='d-flex word-spacing-15'>who ultimately holds the beneficial ownership in the company (has/have)* been changed to:</span>
                    </div>
                </div>
                {/* personal info */}
                <div className='offset-1 col-11'>
                    <div className='row'>
                        <div className='col-8 d-flex align-items-center border-all border-right-0'>
                            <span >1. Name of ultimate beneficial owner/former ultimate beneficial owerner</span>
                        </div>
                        <div className='col-4 border-all p-0'>
                        <div className='border-none'></div>
                        </div>
                        <div className='col-8 d-flex align-items-center border-all border-top-0 border-right-0'>
                            <span >2. Number and class of shares or voting rights held</span>
                        </div>
                        <div className='col-4 border-all border-top-0 p-0'>
                        <div className='border-none'></div>
                        </div>
                        <div className='col-8 d-flex align-items-center border-all border-top-0 border-right-0'>
                            <span >3. Name of the new ultimate beneficial owner</span>
                        </div>
                        <div className='col-4 border-all border-top-0 p-0'>
                        <div className='border-none'></div>
                        </div>
                        <div className='col-8 d-flex align-items-center border-all border-top-0 border-right-0'>
                            <span>4. Father's Name/Spouse's Name</span>
                        </div>
                        <div className='col-4 border-all border-top-0 p-0'>
                        <div className='border-none'></div>
                        </div>
                        <div className='col-8 d-flex align-items-center border-all border-top-0 border-right-0'>
                            <span>5. CNIC/NICOP/Passport no. alongwith date of issue (copy attached)</span>
                        </div>
                        <div className='col-4 border-all border-top-0 p-0'>
                        <div className='border-none'></div>
                        </div>
                        <div className='col-8 d-flex align-items-center border-all border-top-0 border-right-0'>
                            <span >6. Nationality</span>
                        </div>
                        <div className='col-4 border-all border-top-0 p-0'>
                        <div className='border-none'></div>
                        </div>
                        <div className='col-8 d-flex align-items-center border-all border-top-0 border-right-0'>
                            <span >7. Country of origin (in case of foreign national or dual national)</span>
                        </div>
                        <div className='col-4 border-all border-top-0 p-0'>
                        <div className='border-none'></div>
                        </div>
                        <div className='col-8 d-flex align-items-center border-all border-top-0 border-right-0'>
                            <span >8. Usual residential address</span>
                        </div>
                        <div className='col-4 border-all border-top-0 p-0'>
                        <div className='border-none'></div>
                        </div>
                        <div className='col-8 d-flex align-items-center border-all border-top-0 border-right-0'>
                            <span >9. Email address</span>
                        </div>
                        <div className='col-4 border-all border-top-0 p-0'>
                        <div className='border-none'></div>
                        </div>
                        <div className='col-8 d-flex align-items-center border-all border-top-0 border-right-0'>
                            <span >10. Date on which shareholding, interest or control acquired in the company from former ultimate beneficial owner</span>
                        </div>
                        <div className='col-4 border-all border-top-0 p-0'>
                        <div className='border-none'></div>
                        </div>
                        <div className='col-12 border-all mb-4'>
                            <div className='d-flex'>
                                <span className='form-sr-no'>11.</span>
                                <p>In case of indirect shareholding, control or interest being exercised through intermediate companies, entities or other legal persons or legal arrangements in the chain of ownership or control, names and particulars are as follows:</p>
                            </div>
                            <div style={{borderTop:"1px solid black"}} className='table-responsive mb-2'>
                                <table className='table border border-dark'>
                                    <tbody>
                                        <tr>
                                            <td className='vertical-align-top border-all'>Name</td>
                                            <td className='border-all vertical-rl-text-start'>Legal form (Company/ LLP/ Partnership Fim/Trust/Any other body corporate (to be specified)</td>
                                            <td className='border-all vertical-rl-text-start'>Date of incorporation/ registration</td>
                                            <td className='border-all vertical-rl-text-start'>Name of registering authority</td>
                                            <td className='border-all vertical-rl-text-start'>Business Address</td>
                                            <td className='border-all vertical-rl-text-start'>Company</td>
                                            <td className='border-all vertical-rl-text-start'>Email address</td>
                                            <td className='vertical-align-top border-all'>Percentage of shareholding, control or UBO in the legal person or legal arrangement</td>
                                            <td className='vertical-align-top border-all'>Percentage of shareholding, control or interest of legal person or legal arrangement in the Company</td>
                                            <td className='vertical-align-top border-all'>Identity of Natural Person who Ultimately owns or controls the legal person or arrangement</td>
                                        </tr>
                                        <tr>
                                            <td className='text-center border-all td-h'>(a)</td>
                                            <td className='text-center border-all td-h'>(b)</td>
                                            <td className='text-center border-all td-h'>(c)</td>
                                            <td className='text-center border-all td-h'>(d)</td>
                                            <td className='text-center border-all td-h'>(e)</td>
                                            <td className='text-center border-all td-h'>(f)</td>
                                            <td className='text-center border-all td-h'>(g)</td>
                                            <td className='text-center border-all td-h'>(h)</td>
                                            <td className='text-center border-all td-h'>(i)</td>
                                            <td className='text-center border-all td-h'>(j)</td>
                                        </tr>
                                        <tr>
                                            <td className='border-all'></td>
                                            <td className='border-all'></td>
                                            <td className='border-all'></td>
                                            <td className='border-all'></td>
                                            <td className='border-all'></td>
                                            <td className='border-all'></td>
                                            <td className='border-all'></td>
                                            <td className='border-all'></td>
                                            <td className='border-all'></td>
                                            <td className='border-all'></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                {/* table */}
                <div style={{borderTop:"1px solid black"}} className='offset-1 col-11 p-0 mb-5'>
                    <table className='table border border-dark'>
                        <tbody>
                           
                            <tr>
                                <td className='border-all'>
                                    <div className='d-flex'>                         
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className='border-all'>
                                    <div className='d-flex'>
                                        <span className='form-sr-no'>12.</span>
                                        <span>Any other information incidental to or relevent to enable the company to evaluate this matter</span>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                {/* declaration 2*/}
                <div className='col-12 mb-5'>
                    <div>
                        <p className='mb-0'>And I make this solemn declaration conscientiously believing the same to be true.</p>
                        <p>This
                            <span className='inline-input-100'>
                            <div className='border-none border-bottom border-dark inline-input-h'></div>
                                {/* <input type='text' className='form-control border-none border-bottom border-dark inline-input-h' /> */}
                            </span>
                            day of
                            <span className='inline-input-200'>
                            <div className='border-none border-bottom border-dark inline-input-h'></div>
                            </span>
                            ,20
                            <span className='inline-input-100'>
                            <div className='border-none border-bottom border-dark inline-input-h'></div>
                            </span>
                        </p>
                    </div>
                </div>
                {/* name & signature */}
                <div className='col-12 mb-5'>
                    <span className='d-block inline-input-150'>
                    <div className='border-none border-bottom border-dark inline-input-h'></div>
                        {/* <input type='text' className='form-control border-none border-bottom border-dark inline-input-h' /> */}
                    </span>
                    <span>Name & signature</span>
                </div>
                {/* inapplicable points */}
                <div className='col-12'>
                    <div>
                        <span className='d-block mb-4'>* Delete whichever is inapplicable</span>
                        <p className='text-align-justify'>Note: 1. The form of declaration may be modified or adapted to the circumstances in which the non-beneficial owner is a body corporate or in which there is more than one non-beneficial owner in respect of a particular share.</p>
                    </div>
                    <div className='d-flex'>
                        <span className='form-sr-no'>2.</span>
                        <p>Please add further columns to the table in para 2 above depending upon the number of ultimate beneficial owners of the company</p>
                    </div>
                </div>
                                    
                                </div>
                            </div>
                        </div>
                    </PDFExport>
                    : <PDFExport
                        paperSize="A4"
                        margin="1cm"
                        scale={0.6}
                        fileName={`Form 18`}
                        pageTemplate={PageTemplate}
                        ref={pdfExportComponent}
                    >
                       <div style={{ fontFamily: `${fontFamilyForStaticData}`, fontSize: `${fontSizeForStaticData}`, fontWeight: 500 }}>
                            <FormName>
                                Form-18
                            </FormName>
                            <div className='row'>
                                <div className='col-md-12' >
                                    <HeadingWrapper className='d-flex justify-content-center'>
                                        TTHE COMPANIES ACT, 2017
                                    </HeadingWrapper>

                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <HeadingWrapper className='d-flex justify-content-center'>
                                    THE COMPANIES REGULATIONS, 2024
                                    </HeadingWrapper>

                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <div style={{
                                        fontSize: '15px',
                                        // fontWeight: 'bold' 
                                    }} className='d-flex justify-content-center'>
                                        [Section 123A(2) and Regulation 48(3) & 30]
                                    </div>

                                </div>
                            </div>

                            <div className='row mt-4'>
                                <div className='col-md-12'>
                                    <HeadingWrapper className='text-center d-flex justify-content-center'>
                                    DECLARATION BY MEMBER ABOUT CHANGE OF ULTIMATE BENEFICIAL OWNERS
                                    </HeadingWrapper>

                                </div>
                            </div>
                            <div className='row mt-4'>
                                <div className='col-md-12'>
                                    <HeadingWrapper style={{textDecoration:"underline"}} className='text-center d-flex justify-content-center'>
                                    OR PARTICULARS THEREOF   
                                     </HeadingWrapper>
                                </div>
                            </div>
                          

                            <div className='row'>
                                <div className='col-md-12' style={{ padding: '25px 50px', wordWrap: 'break-word' }}>
               
                {/* company info */}
                <div className='col-12 mb-3'>
                    <div className='d-flex'>
                        <span className='mr-1'>Name of Company</span>
                        <span className='inline-input-300'>
                            <div className='border-none border-bottom border-dark inline-input-h'></div>
                            {/* <input type='text' className='form-control border-none border-bottom border-dark inline-input-h' /> */}
                        </span>
                    </div>
                    <div className='d-flex'>
                        <span className=' mr-1'>CUIN</span>
                        <span className='inline-input-300'>
                        <div className='border-none border-bottom border-dark inline-input-h'></div>
                            {/* <input type='text' className='form-control border-none border-bottom border-dark inline-input-h' /> */}
                        </span>
                    </div>
                    <div className='d-flex'>
                        <span className=' mr-1'>Presented by</span>
                        <span className='inline-input-300'>
                        <div className='border-none border-bottom border-dark inline-input-h'></div>
                            {/* <input type='text' className='form-control border-none border-bottom border-dark inline-input-h' /> */}
                        </span>
                    </div>
                </div>
                {/* declaration 1*/}
                <div className='col-12 mb-3'>
                    <div >
                        <span className='mr-4'>1.</span>
                        <span className='word-spacing-25'>This is to declare that I (name of member in block letters) having CNIC/Passport </span>
                        <span>No.*</span>
                        <span className='inline-input-200'>
                            <div className='border-none border-bottom border-dark inline-input-h'></div> 
                        </span>
                        <span className='word-spacing-15'>and having address at</span>
                        <span className='inline-input-200'>
                        <div className='border-none border-bottom border-dark inline-input-h'></div>
                        </span>
                        <span className='word-spacing-15'>am a person whose name was </span>
                        <span className='word-spacing-10'>entered in the register of members of(name of the company) as the holder of(state the number and classes </span>
                        <span className='word-spacing-10'>of shares/extent of voting rights/control right etc.)</span>
                        <span className='inline-input-100'>
                        <div className='border-none border-bottom border-dark inline-input-h'></div>
                        </span>
                        <span>.</span>
                    </div>
                </div>
                <div className='col-12 mb-3'>
                    <div>
                        <span className='mr-4'>2.</span>
                        <span className='word-spacing-15'>With effect from the (date</span>
                        <span className='inline-input-100'>
                        <div className='border-none border-bottom border-dark inline-input-h'></div>
                        </span>
                        <span className='word-spacing-15'>), the (natural person(s)/particulars of the natural persons)*</span>
                        <span className='d-flex word-spacing-15'>who ultimately holds the beneficial ownership in the company (has/have)* been changed to:</span>
                    </div>
                </div>
                {/* personal info */}
                <div className='offset-1 col-11'>
                    <div className='row'>
                        <div className='col-8 d-flex align-items-center border-all border-right-0'>
                            <span >1. Name of ultimate beneficial owner/former ultimate beneficial owerner</span>
                        </div>
                        <div className='col-4 border-all p-0'>
                        <div className='border-none'></div>
                        </div>
                        <div className='col-8 d-flex align-items-center border-all border-top-0 border-right-0'>
                            <span >2. Number and class of shares or voting rights held</span>
                        </div>
                        <div className='col-4 border-all border-top-0 p-0'>
                        <div className='border-none'></div>
                        </div>
                        <div className='col-8 d-flex align-items-center border-all border-top-0 border-right-0'>
                            <span >3. Name of the new ultimate beneficial owner</span>
                        </div>
                        <div className='col-4 border-all border-top-0 p-0'>
                        <div className='border-none'></div>
                        </div>
                        <div className='col-8 d-flex align-items-center border-all border-top-0 border-right-0'>
                            <span>4. Father's Name/Spouse's Name</span>
                        </div>
                        <div className='col-4 border-all border-top-0 p-0'>
                        <div className='border-none'></div>
                        </div>
                        <div className='col-8 d-flex align-items-center border-all border-top-0 border-right-0'>
                            <span>5. CNIC/NICOP/Passport no. alongwith date of issue (copy attached)</span>
                        </div>
                        <div className='col-4 border-all border-top-0 p-0'>
                        <div className='border-none'></div>
                        </div>
                        <div className='col-8 d-flex align-items-center border-all border-top-0 border-right-0'>
                            <span >6. Nationality</span>
                        </div>
                        <div className='col-4 border-all border-top-0 p-0'>
                        <div className='border-none'></div>
                        </div>
                        <div className='col-8 d-flex align-items-center border-all border-top-0 border-right-0'>
                            <span >7. Country of origin (in case of foreign national or dual national)</span>
                        </div>
                        <div className='col-4 border-all border-top-0 p-0'>
                        <div className='border-none'></div>
                        </div>
                        <div className='col-8 d-flex align-items-center border-all border-top-0 border-right-0'>
                            <span >8. Usual residential address</span>
                        </div>
                        <div className='col-4 border-all border-top-0 p-0'>
                        <div className='border-none'></div>
                        </div>
                        <div className='col-8 d-flex align-items-center border-all border-top-0 border-right-0'>
                            <span >9. Email address</span>
                        </div>
                        <div className='col-4 border-all border-top-0 p-0'>
                        <div className='border-none'></div>
                        </div>
                        <div className='col-8 d-flex align-items-center border-all border-top-0 border-right-0'>
                            <span >10. Date on which shareholding, interest or control acquired in the company from former ultimate beneficial owner</span>
                        </div>
                        <div className='col-4 border-all border-top-0 p-0'>
                        <div className='border-none'></div>
                        </div>
                        <div className='col-12 border-all mb-4'>
                            <div className='d-flex'>
                                <span className='form-sr-no'>11.</span>
                                <p>In case of indirect shareholding, control or interest being exercised through intermediate companies, entities or other legal persons or legal arrangements in the chain of ownership or control, names and particulars are as follows:</p>
                            </div>
                            <div style={{borderTop:"1px solid black"}} className='table-responsive mb-2'>
                                <table className='table border border-dark'>
                                    <tbody>
                                        <tr>
                                            <td className='vertical-align-top border-all'>Name</td>
                                            <td className='border-all vertical-rl-text-start'>Legal form (Company/ LLP/ Partnership Fim/Trust/Any other body corporate (to be specified)</td>
                                            <td className='border-all vertical-rl-text-start'>Date of incorporation/ registration</td>
                                            <td className='border-all vertical-rl-text-start'>Name of registering authority</td>
                                            <td className='border-all vertical-rl-text-start'>Business Address</td>
                                            <td className='border-all vertical-rl-text-start'>Company</td>
                                            <td className='border-all vertical-rl-text-start'>Email address</td>
                                            <td className='vertical-align-top border-all'>Percentage of shareholding, control or UBO in the legal person or legal arrangement</td>
                                            <td className='vertical-align-top border-all'>Percentage of shareholding, control or interest of legal person or legal arrangement in the Company</td>
                                            <td className='vertical-align-top border-all'>Identity of Natural Person who Ultimately owns or controls the legal person or arrangement</td>
                                        </tr>
                                        <tr>
                                            <td className='text-center border-all td-h'>(a)</td>
                                            <td className='text-center border-all td-h'>(b)</td>
                                            <td className='text-center border-all td-h'>(c)</td>
                                            <td className='text-center border-all td-h'>(d)</td>
                                            <td className='text-center border-all td-h'>(e)</td>
                                            <td className='text-center border-all td-h'>(f)</td>
                                            <td className='text-center border-all td-h'>(g)</td>
                                            <td className='text-center border-all td-h'>(h)</td>
                                            <td className='text-center border-all td-h'>(i)</td>
                                            <td className='text-center border-all td-h'>(j)</td>
                                        </tr>
                                        <tr>
                                            <td className='border-all'></td>
                                            <td className='border-all'></td>
                                            <td className='border-all'></td>
                                            <td className='border-all'></td>
                                            <td className='border-all'></td>
                                            <td className='border-all'></td>
                                            <td className='border-all'></td>
                                            <td className='border-all'></td>
                                            <td className='border-all'></td>
                                            <td className='border-all'></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                {/* table */}
                <div style={{borderTop:"1px solid black"}} className='offset-1 col-11 p-0 mb-5'>
                    <table className='table border border-dark'>
                        <tbody>
                           
                            <tr>
                                <td className='border-all'>
                                    <div className='d-flex'>                         
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className='border-all'>
                                    <div className='d-flex'>
                                        <span className='form-sr-no'>12.</span>
                                        <span>Any other information incidental to or relevent to enable the company to evaluate this matter</span>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                {/* declaration 2*/}
                <div className='col-12 mb-5'>
                    <div>
                        <p className='mb-0'>And I make this solemn declaration conscientiously believing the same to be true.</p>
                        <p>This
                            <span className='inline-input-100'>
                            <div className='border-none border-bottom border-dark inline-input-h'></div>
                                {/* <input type='text' className='form-control border-none border-bottom border-dark inline-input-h' /> */}
                            </span>
                            day of
                            <span className='inline-input-200'>
                            <div className='border-none border-bottom border-dark inline-input-h'></div>
                            </span>
                            ,20
                            <span className='inline-input-100'>
                            <div className='border-none border-bottom border-dark inline-input-h'></div>
                            </span>
                        </p>
                    </div>
                </div>
                {/* name & signature */}
                <div className='col-12 mb-5'>
                    <span className='d-block inline-input-150'>
                    <div className='border-none border-bottom border-dark inline-input-h'></div>
                        {/* <input type='text' className='form-control border-none border-bottom border-dark inline-input-h' /> */}
                    </span>
                    <span>Name & signature</span>
                </div>
                {/* inapplicable points */}
                <div className='col-12'>
                    <div>
                        <span className='d-block mb-4'>* Delete whichever is inapplicable</span>
                        <p className='text-align-justify'>Note: 1. The form of declaration may be modified or adapted to the circumstances in which the non-beneficial owner is a body corporate or in which there is more than one non-beneficial owner in respect of a particular share.</p>
                    </div>
                    <div className='d-flex'>
                        <span className='form-sr-no'>2.</span>
                        <p>Please add further columns to the table in para 2 above depending upon the number of ultimate beneficial owners of the company</p>
                    </div>
                </div>
                                    
                                </div>
                            </div>
                        </div>
                    </PDFExport>}
            </div>
            <button disabled={loading} className='btn btn-danger' onClick={(e) => {
                // if(viewPdf)
                //   SetViewPDf(true)
                if (pdfExportComponent.current) {
                    pdfExportComponent.current.save();
                }
            }}>Download PDF</button>
        </div>
    )
}
const PartsWrapper = styled.div`
color: #121212;
text-decoration: underline;
justify-content: center;
display: flex;
margin-right: 15px;
font-size: 17px;
font-weight: bold;
`;
const ScrollWrapper = styled.div`
max-height: 50px;
overflow-y: scroll;
overflow-x: hidden;
::-webkit-scrollbar{
    height: 5px;
    width: 3px;
  }
  
  ::-webkit-scrollbar-track{
    background: #F9F9FB;
  }
  ::-webkit-scrollbar-thumb{
    background: #4E515680;
    border-radius: 5px;
  
  }
`;
const HeadingWrapper = styled.div`
font-size: 17px;
font-weight: bold;
`;
const FormName = styled.div`
color: #121212;
text-decoration: underline;
justify-content: end;
display: flex;
margin-right: 15px;
font-size: 17px;
font-weight: bold;
`;
const Wrapper = styled.div`
table.table-bordered{
    border: 1px solid #121212
     border-color: #121212; 
    // border:1px solid blue;
    // margin-top:20px;
  }
// table.table-bordered > thead > tr > th{
//     // border:1px solid blue;
//     border-color: #121212; 
// }
// table.table-bordered > tbody > tr > td{
//     // border:1px solid blue;
//     borderC-color: #121212; 
// }
 tr td {
    // border:1px solid blue;.
    border-color: #121212; 
}

`;
const WrapperDashed = styled.div`

  td {
    
    border: 1px dashed #dddcdc;
}

`;