import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { EditRequirmentScema } from "store/validations/alertValidation";
import { yupResolver } from "@hookform/resolvers/yup";
import NumberFormat from "react-number-format";
export const ViewRequirment = () => {
  const [loading, setLoading] = useState(false);

  const requirment =
    JSON.parse(sessionStorage.getItem("selectedrequirment")) || "";

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
    getValues,
    watch,
  } = useForm({
    defaultValues: EditRequirmentScema(requirment).cast(),
    resolver: yupResolver(EditRequirmentScema(requirment)),
  });
  const handleUpdateCompany = async (data) => { };
  return (
    <div>
      <form onSubmit={handleSubmit(handleUpdateCompany)}>
        <div className="row">
          <div className="col-sm-12 col-md-6 col-lg-4">
            <div className="card ">
              <div className="card-header b-t-primary">
                <h5>Requirement</h5>
              </div>
              <div className="card-body">
                <div className="form-group mt-3 ">
                  <label htmlFor="company_type">Company Type</label>
                  <input
                    name="company_type"
                    className={`form-control ${errors.company_type && "border border-danger"
                      }`}
                    {...register("company_type")}
                    readOnly
                  />
                  <small className="text-danger">
                    {errors.company_type?.message}
                  </small>
                </div>

                <div className="form-group mt-3 ">
                  <label>Category</label>
                      <input
                      name="requirement_category"
                      className={`form-control ${errors.requirement_category && "border border-danger"
                        }`}
                      {...register("requirement_category")}
                      value={requirment.requirement_category}
                      readOnly
                      />
                   

                  <small className="text-danger">
                    {errors.requirement_category?.message}
                  </small>
                </div>

                <div className="form-group mt-3 ">
                  <label htmlFor="form_code">Code</label>
                  <input
                    name="form_code"
                    className={`form-control ${errors.form_code && "border border-danger"
                      }`}
                    type="text"
                    // placeholder="Enter Code"
                    {...register("form_code")}
                    readOnly
                  />
                  <small className="text-danger">
                    {errors.form_code?.message}
                  </small>
                </div>
                <div className="form-group mt-3 ">
                  <label>Title</label>
                  <input
                    name="title"
                    className={`form-control ${errors.title && "border border-danger"
                      }`}
                    type="text"
                    // placeholder="Enter Title"
                    {...register("title")}
                    readOnly
                  />
                  <small className="text-danger">{errors.title?.message}</small>
                </div>
                <div className="form-group mt-3 ">
                  <label>Description</label>
                  <input
                    name="description"
                    className={`form-control ${errors.description && "border border-danger"
                      }`}
                    type="text"
                    placeholder="Enter Description"
                    {...register("description")}
                    readOnly
                  />
                  <small className="text-danger">
                    {errors.description?.message}
                  </small>
                </div>
                <div className="form-group mt-3 ">
                  <label>Regulations </label>
                  <input
                    name="regulations"
                    className={`form-control ${errors.regulations && "border border-danger"
                      }`}
                    type="regulations"
                    // placeholder="Enter Regulations Or Section"
                    {...register("regulations")}
                    readOnly
                  />
                  <small className="text-danger">
                    {errors.regulations?.message}
                  </small>
                </div>

                <div className="form-group mt-3" >
                  <label>Section</label>
                  <input
                    name="section"
                    className={`form-control ${errors.section && "border border-danger"
                      }`}
                    type="section"
                    placeholder="Enter Section"
                    {...register("section")}
                    readOnly
                  />
                  <small className="text-danger">
                    {errors.section?.message}
                  </small>
                </div>

            
                <div className="form-group mt-3 ">
                  <label htmlFor="secp_form">Secp Form</label>
                  <input
                    name="secp_form"
                    className={`form-control ${errors.secp_form && "border border-danger"
                      }`}
                    type="secp_form"
                    {...register("secp_form")}
                    readOnly
                  />
                  <small className="text-danger">
                    {errors.secp_form?.message}
                  </small>
                </div>
              </div>
            </div>
          </div>

          <div className="col-sm-12 col-md-6 col-xl-4">
            <div className="card ">
              <div className="card-header b-t-success">
                <h5>Compliance</h5>
              </div>
              <div className="card-body">
                <div className="form-group mt-3 ">
                  <label>Frequency </label>
                  <input
                    name="frequency"
                    className={`form-control ${errors.frequency && "border border-danger"
                      }`}
                    type="frequency"
                    // placeholder="Enter Frequency"
                    {...register("frequency")}
                    readOnly
                  />

                  <small className="text-danger">
                    {errors.frequency?.message}
                  </small>
                </div>
                <div className="form-group mt-3 ">
                  <label htmlFor="quarter">Select Quarter</label>
                  <input
                    name="quarter"
                    className={`form-control ${errors.quarter && "border border-danger"
                      }`}
                    type="quarter"
                    {...register("quarter")}
                    readOnly
                  />
                  <small className="text-danger">
                    {errors.quarter?.message}
                  </small>
                </div>
                <div className="form-group mt-3 ">
                  <label>Start Days (From Quarter) </label>

                  <Controller
                    name="start_days"
                    render={({ field }) => (
                      <NumberFormat
                        {...field}
                        className={`form-control ${errors.start_days && "border border-danger"
                          }`}
                        id="start_days"
                        allowNegative={true}
                        placeholder="Enter Start Days"
                        readOnly
                      />
                    )}
                    control={control}
                  />

                  <small className="text-danger">
                    {errors.start_days?.message}
                  </small>
                </div>
                <div className="form-group mt-3 ">
                  <label>Deadline Days (From Quarter) </label>

                  <Controller
                    name="deadline_days"
                    render={({ field }) => (
                      <NumberFormat
                        {...field}
                        className={`form-control ${errors.deadline_days && "border border-danger"
                          }`}
                        id="deadline_days"
                        allowNegative={true}
                        placeholder="Enter Deadline Days"
                        readOnly
                      />
                    )}
                    control={control}
                  />

                  <small className="text-danger">
                    {errors.deadline_days?.message}
                  </small>
                </div>
                <div className="form-group mt-3 ">
                  <label htmlFor="level_ddl">Level</label>
                  <input
                    name="level_ddl"
                    className={`form-control ${errors.level_ddl && "border border-danger"
                      }`}
                    {...register("level_ddl")}
                    readOnly
                  />
                  <small className="text-danger">
                    {errors.level_ddl?.message}
                  </small>
                </div>
           
              </div>
            </div>
            <div className="card ">
              <div className="card-header b-t-success">
                <h5>Dependency</h5>
              </div>
              <div className="card-body">
                <div className="form-group mt-3 ">
                  <label>Dependent On </label>
                  <input
                    name="dependent "
                    className={`form-control ${errors.dependent && "border border-danger"
                      }`}
                    type="dependent"
                    // placeholder="Enter Dependent"
                    value={requirment?.dependent_on || ""}
                    {...register("dependent")}
                    readOnly
                  />

                  <small className="text-danger">
                    {errors.dependent?.message}
                  </small>
                </div>
                <div className="form-group mt-3 ">
                  <label>Days To Dependent</label>

                  <Controller
                    name="days_dependent"
                    render={({ field }) => (
                      <NumberFormat
                        {...field}
                        className={`form-control ${errors.days_dependent && "border border-danger"
                          }`}
                        id="days_dependent"
                        allowNegative={true}
                        // placeholder="Enter Days To Dependent"
                        readOnly
                      />
                    )}
                    control={control}
                  />

                  <small className="text-danger">
                    {errors.days_dependent?.message}
                  </small>
                </div>
              </div>
            </div>
          </div>

          <div className="col-sm-12 col-md-6 col-xl-4">
            <div className="card ">
              <div className="card-header b-t-success">
                <h5>Notification</h5>
              </div>
              <div className="card-body">
                <div className="form-group mt-3 ">
                  <label htmlFor="notify_via">Notify Via</label>
                  <input
                    name="notify_via"
                    className={`form-control ${errors.notify_via && "border border-danger"
                      }`}
                    {...register("notify_via")}
                    readOnly
                  />
                  <small className="text-danger">
                    {errors.notify_via?.message}
                  </small>
                </div>
                <div className="form-group mt-3 ">
                  <label>Notify Days</label>

                  <Controller
                    name="notify_days"
                    render={({ field }) => (
                      <NumberFormat
                        {...field}
                        className={`form-control ${errors.notify_days && "border border-danger"
                          }`}
                        id="notify_days"
                        allowNegative={true}
                        // placeholder="Enter Notify Days"
                        readOnly
                      />
                    )}
                    control={control}
                  />

                  <small className="text-danger">
                    {errors.notify_days?.message}
                  </small>
                </div>
                <div className="form-group mt-3 ">
                  <label htmlFor="active">Active</label>
                  <input
                    name="active"
                    className={`form-control ${errors.active && "border border-danger"
                      }`}
                    {...register("active")}
                    readOnly
                  ></input>
                  <small className="text-danger">
                    {errors.active?.message}
                  </small>
                </div>
              </div>
            </div>

            <div className="card ">
              <div className="card-header b-t-success">
                <h5>Non Compliance</h5>
              </div>
              <div className="card-body">
                <div className="form-group mt-3 ">
                  <label>Non compliance Regulations/Section </label>
                  <input
                    readOnly
                    name="non_compliance_regulation"
                    className={`form-control ${errors.non_compliance_regulation && "border border-danger"
                      }`}
                    type="non_compliance_regulation"
                    placeholder="Enter Non Compliance Regulation Or Section"
                    {...register("non_compliance_regulation")}
                  />
                  <small className="text-danger">
                    {errors.non_compliance_regulation?.message}
                  </small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
