import React, { useState, useEffect } from 'react'
import { PDFExport } from "@progress/kendo-react-pdf";
import PageTemplate from "./page-template";
import {
    getCompanies,
} from "../../../store/services/company.service";
import styled from 'styled-components';
import { TiTick } from "react-icons/ti";
import { numberWithCommas } from 'utilities/utilityFunctions';
import "./CommonForm.css"
export const AppendixBtoApp4 = ({ data, formTemplate = false }) => {
    const pdfExportComponent = React.useRef(null);
    const [CompanyData, setCompanyData] = useState(null)
    const [loading, setLoading] = useState(false);
    const [fontFamilyForStaticData, setFontFamilyForStaticData] = useState('Montserrat, sans-serif')
    const [fontSizeForStaticData, setFontSizeForStaticData] = useState('14px')
    const [fontfamilyforDynimicData, setFontFamilyForDynmicData] = useState('Segoe Print')
    const [fontColor, setFontColor] = useState('')
    const baseEmail = sessionStorage.getItem("email") || "";
    const [governance, setGovernance] = useState({})
    const getAllCompanies = async () => {
        setLoading(true)
        try {
            const response = await getCompanies(baseEmail);
            if (response.status === 200) {
                response.data.data = response.data.data.filter((item) => item?.active === 'Y')
                const parents = response.data.data;
                const company = parents?.find(ite => ite?.code == data?.company_code)
                let Allperson = company.governance && company.governance != "" ? JSON.parse(company.governance) : []
                let sec = Allperson.find(x => x.role == "Company Secretary" && x?.active?.toLowerCase() != 'n')
                let ceo = Allperson.find(x => x.role == "CEO" && x?.active?.toLowerCase() != 'n')


                if (sec) {
                    setGovernance(sec)
                } else if (ceo) {
                    setGovernance(ceo)
                }
                setCompanyData(company)
                setTimeout(() => {
                    setLoading(false)
                }, 1000);
            }
        } catch (error) {
            setLoading(false)
        }
    };
    useEffect(() => {
        getAllCompanies()
    }, [])
    return (
        <div>
            <div
                style={{ fontFamily: "sans-serif " }}
            >

                {formTemplate ?
                    <PDFExport
                        paperSize="A4"
                        margin="1cm"
                        scale={0.6}
                        fileName={` Appendix-B to App-4`}
                        pageTemplate={PageTemplate}
                        ref={pdfExportComponent}
                    >
                        <div style={{ fontFamily: `${fontFamilyForStaticData}`, fontSize: `${fontSizeForStaticData}`, fontWeight: 500 }}>
                            <FormName>
                            Appendix-B to App-4
                            </FormName>
                            <div className='row'>
                                <div className='col-md-12' >
                                    <HeadingWrapper className='d-flex justify-content-center'>
                                    THE COMPANIES ACT, 2017
                                    </HeadingWrapper>
                                    <HeadingWrapper className='d-flex justify-content-center'>
                                    THE COMPANIES REGULATIONS, 2024
                                    </HeadingWrapper>

                                </div>
                            </div>

                            <div className='row'>
                                <div className='col-md-12'>
                                    <div style={{ fontSize: '15px', fontWeight: 'bold' }} className='d-flex justify-content-center'>
                                    [Regulations 145]
                                    </div>
                                </div>
                            </div>

                            <div className='row mt-4'>
                                <div className='col-md-12'>
                                    <HeadingWrapper className='text-center d-flex justify-content-center'>
                                    On stamp paper of requisite value
                                    </HeadingWrapper>
                                    <HeadingWrapper className='text-center d-flex justify-content-center'>
                                    DECLARATION/ INDEMNITY
                                    </HeadingWrapper>
                                </div>
                            </div>


                            <div className='row'>
                                <div className='col-md-12' style={{ padding: '25px 50px', wordWrap: 'break-word' }}>
                                        <div className='row'>
                                           
                                            <p style={{fontSize:"14px"}} className='font-large mb-4'>We, the following directors/ cheif executive of M/s
                                                <span className='inline-input-200 ml-2'>
                                                    <div className='border-none border-bottom border-dark inline-input-h' ></div>
                                                </span>
                                                (the ‘Company’) do hereby solemnly affirm and state as follows:-
                                            </p>
                                        </div>
                                        <ol style={{display:"block"}} className='app4-appendix-b-list1-ol'>
                                            <li className="mt-2">
                                                That the Company has
                                                <span className='inline-input-50'>
                                                    <div className='border-none border-bottom border-dark inline-input-h' ></div>
                                                </span>
                                                director including cheif executive and we form a majority of the board of directors of the Company;
                                            </li>
                                            <li className="mt-2">
                                                That a resolution has been passed by the members of the company in their  general meeting, held on
                                                <span className='inline-input-100'>
                                                    <div className='border-none border-bottom border-dark inline-input-h' ></div>
                                                </span>
                                                to furnish application seeking striking off the name of company from the register of the companies under section 426 of the Companies Act, 2017.
                                            </li>
                                            <li className="mt-2">
                                                That the resolution has been passed by three-fouth majority.
                                            </li>
                                            <li className="mt-2">
                                                That the Company has no known assets or is not carrying on any business or any operation;
                                            </li>
                                            <li className="mt-2">
                                                That the Company has no liabilites outstanding in relation to any loan(s) obtained from the banks, financial institutions, taxes, utility charges, or any obligations towards government departments (including FBR) or private parties;’
                                            </li>
                                            <li className="mt-2">
                                                That the Company has not undertaken any business of housing, real estate development or real estate marketing since its incorporation;
                                            </li>
                                            <li className="mt-2">
                                                That neither any case is pending against the company before any court of law nor is any investigation, enquiry or prosection pending against the company before Federal Government, Provincial Government, SECP, SBP, NAB, FBR or any competent authority;
                                            </li>
                                            <li className="mt-2">
                                                That in case of any loss(es) to any person or any valid claim from any person, if any, arising out of the striking off the Company from the register of companies, we hereby undertake  in writing:
                                                <ol style={{display:"block"}} className='app4-appendix-b-list2-ol'>
                                                    <li className="mt-2">
                                                        to pay and settle all lawful claims arising out of the striking off the name of the Company.
                                                    </li>
                                                    <li className="mt-2">
                                                        to indemnify any person for any such losses that may arise pursuant striking off the name of the Company.
                                                    </li>
                                                    <li className="mt-2">
                                                        to settle all lawful claims and liabilites which have not come to our notice at this stage, even after the name of the Company has been struck off in terms of 426 of the Companies Act,2017.
                                                    </li>
                                                </ol>
                                            </li>
                                            <li className="mt-2">
                                                That we are fully aware of the fact that in case we make any false statement about any of the above matters, we shall be liable for civil as well as criminal consequences.
                                            </li>
                                            <li className="mt-2">
                                                It is declared that the Company has not undertaken any business of housing, real estate development or real estate marketing business since its incorporation.
                                            </li>
                                            <li className="mt-2">
                                                That the contents of the application and whatever stated above are true and correct to the best of our knowledge and belief and nothing has been concealed.
                                            </li>
                                        </ol>
                                        <span className='d-block font-large mb-3'>Deponents</span>
                                        <span className='d-block font-large'>Place:
                                            <span className='inline-input-300'>
                                                <div className='border-none border-bottom border-dark inline-input-h' ></div>
                                            </span>
                                        </span>
                                        <span className='font-large'>Dated:
                                            <span className='inline-input-300'>
                                                <div className='border-none border-bottom border-dark inline-input-h' ></div>
                                            </span>
                                        </span>











                                </div>
                            </div>
                        </div>
                    </PDFExport>
                    : <PDFExport
                        paperSize="A4"
                        margin="1cm"
                        scale={0.6}
                        fileName={` Appendix-B to App-4`}
                        pageTemplate={PageTemplate}
                        ref={pdfExportComponent}
                    >
                       <div style={{ fontFamily: `${fontFamilyForStaticData}`, fontSize: `${fontSizeForStaticData}`, fontWeight: 500 }}>
                            <FormName>
                            Appendix-B to App-4
                            </FormName>
                            <div className='row'>
                                <div className='col-md-12' >
                                    <HeadingWrapper className='d-flex justify-content-center'>
                                    THE COMPANIES ACT, 2017
                                    </HeadingWrapper>
                                    <HeadingWrapper className='d-flex justify-content-center'>
                                    THE COMPANIES REGULATIONS, 2024
                                    </HeadingWrapper>

                                </div>
                            </div>

                            <div className='row'>
                                <div className='col-md-12'>
                                    <div style={{ fontSize: '15px', fontWeight: 'bold' }} className='d-flex justify-content-center'>
                                    [Regulations 145]
                                    </div>
                                </div>
                            </div>

                            <div className='row mt-4'>
                                <div className='col-md-12'>
                                    <HeadingWrapper className='text-center d-flex justify-content-center'>
                                    On stamp paper of requisite value
                                    </HeadingWrapper>
                                    <HeadingWrapper className='text-center d-flex justify-content-center'>
                                    DECLARATION/ INDEMNITY
                                    </HeadingWrapper>
                                </div>
                            </div>


                            <div className='row'>
                                <div className='col-md-12' style={{ padding: '25px 50px', wordWrap: 'break-word' }}>
                                        <div className='row'>
                                           
                                            <p style={{fontSize:"14px"}} className='font-large mb-4'>We, the following directors/ cheif executive of M/s
                                                <span className='inline-input-200 ml-2'>
                                                    <div className='border-none border-bottom border-dark inline-input-h' ></div>
                                                </span>
                                                (the ‘Company’) do hereby solemnly affirm and state as follows:-
                                            </p>
                                        </div>
                                        <ol style={{display:"block"}} className='app4-appendix-b-list1-ol'>
                                            <li className="mt-2">
                                                That the Company has
                                                <span className='inline-input-50'>
                                                    <div className='border-none border-bottom border-dark inline-input-h' ></div>
                                                </span>
                                                director including cheif executive and we form a majority of the board of directors of the Company;
                                            </li>
                                            <li className="mt-2">
                                                That a resolution has been passed by the members of the company in their  general meeting, held on
                                                <span className='inline-input-100'>
                                                    <div className='border-none border-bottom border-dark inline-input-h' ></div>
                                                </span>
                                                to furnish application seeking striking off the name of company from the register of the companies under section 426 of the Companies Act, 2017.
                                            </li>
                                            <li className="mt-2">
                                                That the resolution has been passed by three-fouth majority.
                                            </li>
                                            <li className="mt-2">
                                                That the Company has no known assets or is not carrying on any business or any operation;
                                            </li>
                                            <li className="mt-2">
                                                That the Company has no liabilites outstanding in relation to any loan(s) obtained from the banks, financial institutions, taxes, utility charges, or any obligations towards government departments (including FBR) or private parties;’
                                            </li>
                                            <li className="mt-2">
                                                That the Company has not undertaken any business of housing, real estate development or real estate marketing since its incorporation;
                                            </li>
                                            <li className="mt-2">
                                                That neither any case is pending against the company before any court of law nor is any investigation, enquiry or prosection pending against the company before Federal Government, Provincial Government, SECP, SBP, NAB, FBR or any competent authority;
                                            </li>
                                            <li className="mt-2">
                                                That in case of any loss(es) to any person or any valid claim from any person, if any, arising out of the striking off the Company from the register of companies, we hereby undertake  in writing:
                                                <ol style={{display:"block"}} className='app4-appendix-b-list2-ol'>
                                                    <li className="mt-2">
                                                        to pay and settle all lawful claims arising out of the striking off the name of the Company.
                                                    </li>
                                                    <li className="mt-2">
                                                        to indemnify any person for any such losses that may arise pursuant striking off the name of the Company.
                                                    </li>
                                                    <li className="mt-2">
                                                        to settle all lawful claims and liabilites which have not come to our notice at this stage, even after the name of the Company has been struck off in terms of 426 of the Companies Act,2017.
                                                    </li>
                                                </ol>
                                            </li>
                                            <li className="mt-2">
                                                That we are fully aware of the fact that in case we make any false statement about any of the above matters, we shall be liable for civil as well as criminal consequences.
                                            </li>
                                            <li className="mt-2">
                                                It is declared that the Company has not undertaken any business of housing, real estate development or real estate marketing business since its incorporation.
                                            </li>
                                            <li className="mt-2">
                                                That the contents of the application and whatever stated above are true and correct to the best of our knowledge and belief and nothing has been concealed.
                                            </li>
                                        </ol>
                                        <span className='d-block font-large mb-3'>Deponents</span>
                                        <span className='d-block font-large'>Place:
                                            <span className='inline-input-300'>
                                                <div className='border-none border-bottom border-dark inline-input-h' ></div>
                                            </span>
                                        </span>
                                        <span className='font-large'>Dated:
                                            <span className='inline-input-300'>
                                                <div className='border-none border-bottom border-dark inline-input-h' ></div>
                                            </span>
                                        </span>











                                </div>
                            </div>
                        </div>
                    </PDFExport>}
            </div>
            <button disabled={loading} className='btn btn-danger' onClick={(e) => {
                // if(viewPdf)
                //   SetViewPDf(true)
                if (pdfExportComponent.current) {
                    pdfExportComponent.current.save();
                }
            }}>Download PDF</button>
        </div>
    )
}
const PartsWrapper = styled.div`
color: #121212;
text-decoration: underline;
justify-content: center;
display: flex;
margin-right: 15px;
font-size: 17px;
font-weight: bold;
`;
const ScrollWrapper = styled.div`
max-height: 50px;
overflow-y: scroll;
overflow-x: hidden;
::-webkit-scrollbar{
    height: 5px;
    width: 3px;
  }
  
  ::-webkit-scrollbar-track{
    background: #F9F9FB;
  }
  ::-webkit-scrollbar-thumb{
    background: #4E515680;
    border-radius: 5px;
  
  }
`;
const HeadingWrapper = styled.div`
font-size: 17px;
font-weight: bold;
`;
const FormName = styled.div`
color: #121212;
text-decoration: underline;
justify-content: end;
display: flex;
margin-right: 15px;
font-size: 17px;
font-weight: bold;
`;
const Wrapper = styled.div`
table.table-bordered{
    border: 1px solid #121212
     border-color: #121212; 
    // border:1px solid blue;
    // margin-top:20px;
  }
// table.table-bordered > thead > tr > th{
//     // border:1px solid blue;
//     border-color: #121212; 
// }
// table.table-bordered > tbody > tr > td{
//     // border:1px solid blue;
//     borderC-color: #121212; 
// }
 tr td {
    // border:1px solid blue;.
    border-color: #121212; 
}

`;
const WrapperDashed = styled.div`

  td {
    
    // border: 1px dashed #dddcdc;
}

`;