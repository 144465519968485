import React, { useState, useEffect } from 'react'
import { PDFExport } from "@progress/kendo-react-pdf";
import PageTemplate from "./page-template";
import {
    getCompanies,
} from "../../../store/services/company.service";
import styled from 'styled-components';
import { TiTick } from "react-icons/ti";
import { numberWithCommas } from 'utilities/utilityFunctions';
import "./CommonForm.css"
export const StatutoryForm19 = ({ data, formTemplate = false }) => {
    const pdfExportComponent = React.useRef(null);
    const [CompanyData, setCompanyData] = useState(null)
    const [loading, setLoading] = useState(false);
    const [fontFamilyForStaticData, setFontFamilyForStaticData] = useState('Montserrat, sans-serif')
    const [fontSizeForStaticData, setFontSizeForStaticData] = useState('14px')
    const [fontfamilyforDynimicData, setFontFamilyForDynmicData] = useState('Segoe Print')
    const [fontColor, setFontColor] = useState('')
    const baseEmail = sessionStorage.getItem("email") || "";
    const [governance, setGovernance] = useState({})
    const getAllCompanies = async () => {
        setLoading(true)
        try {
            const response = await getCompanies(baseEmail);
            if (response.status === 200) {
                response.data.data = response.data.data.filter((item) => item?.active === 'Y')
                const parents = response.data.data;
                const company = parents?.find(ite => ite?.code == data?.company_code)
                let Allperson = company.governance && company.governance != "" ? JSON.parse(company.governance) : []
                let sec = Allperson.find(x => x.role == "Company Secretary" && x?.active?.toLowerCase() != 'n')
                let ceo = Allperson.find(x => x.role == "CEO" && x?.active?.toLowerCase() != 'n')


                if (sec) {
                    setGovernance(sec)
                } else if (ceo) {
                    setGovernance(ceo)
                }
                setCompanyData(company)
                setTimeout(() => {
                    setLoading(false)
                }, 1000);
            }
        } catch (error) {
            setLoading(false)
        }
    };
    useEffect(() => {
        getAllCompanies()
    }, [])
    return (
        <div>
            <div
                style={{ fontFamily: "sans-serif " }}
            >

                {formTemplate ?
                    <PDFExport
                        paperSize="A4"
                        margin="1cm"
                        scale={0.6}
                        fileName={`Form 19`}
                        pageTemplate={PageTemplate}
                        ref={pdfExportComponent}
                    >
                        <div style={{ fontFamily: `${fontFamilyForStaticData}`, fontSize: `${fontSizeForStaticData}`, fontWeight: 500 }}>
                            <FormName>
                                Form-19
                            </FormName>
                            <div className='row'>
                                <div className='col-md-12' >
                                    <HeadingWrapper className='d-flex justify-content-center'>
                                        THE COMPANIES ACT, 2017
                                    </HeadingWrapper>

                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <HeadingWrapper className='d-flex justify-content-center'>
                                        THE COMPANIES REGULATIONS, 2024
                                    </HeadingWrapper>

                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <div style={{
                                        fontSize: '15px',
                                        // fontWeight: 'bold' 
                                    }} className='d-flex justify-content-center'>
                                        [Section 123A(2) and Regulations 48(5) & 30]
                                    </div>

                                </div>
                            </div>

                            <div className='row mt-4'>
                                <div className='col-md-12'>
                                    <HeadingWrapper className='text-center d-flex justify-content-center'>
                                        DECLARATION OF COMPANIES WITH THE PROVISIONS OF SECTION 123A OF THE COMPANIES ACT, 2017
                                    </HeadingWrapper>

                                </div>
                            </div>

                            <PartsWrapper className='mt-4 mb-3'>
                                PART-I
                            </PartsWrapper>

                            <div className='row'>
                                <div className='col-md-12' style={{ padding: '25px 50px', wordWrap: 'break-word' }}>
                                    {/* heading right*/}

                                    {/* section 1.1 */}
                                    <div className='col-12 mb-3'>
                                        <div className='row'>
                                            <div className='col-5 d-flex '>
                                                <span className='form-sr-no'>1.1 </span>CUIN (Registration Number)</div>
                                            <div className='col-7 reg-cells'>
                                                <div style={{ minHeight: "25px" }} className="reg-no reg-cell" ></div>
                                                <div style={{ minHeight: "25px" }} className="reg-no reg-cell" ></div>
                                                <div style={{ minHeight: "25px" }} className="reg-no reg-cell" ></div>
                                                <div style={{ minHeight: "25px" }} className="reg-no reg-cell" ></div>
                                                <div style={{ minHeight: "25px" }} className="reg-no reg-cell" ></div>
                                                <div style={{ minHeight: "25px" }} className="reg-no reg-cell" ></div>
                                                <div style={{ minHeight: "25px" }} className="reg-no border-all" ></div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* section 1.2 */}
                                    <div className='col-12 mb-2'>
                                        <div className='row align-items-start'>
                                            <div className='col-5 d-flex '><span className='form-sr-no mt-0'>1.2 </span>
                                                <span className='mt-0'>Name of the Company</span>
                                            </div>
                                            <div className='col-7'>
                                                <div style={{ minHeight: "25px" }} className="company-name border-all ml-1" ></div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* section 1.3 */}
                                    <div className='col-12 mb-3'>
                                        <div className='row mt-0'>
                                            <div className='col-12 d-flex '><span className='form-sr-no'>1.3 </span>Fee Payment Details</div>
                                            {/* section 1.3.1 */}
                                            <div className='offset-3 col-9 mb-4'>
                                                <div className="d-flex align-items-middle ">
                                                    <span className='form-sr-no'>1.3.1 </span><span className='ml-1'>Challan No</span>
                                                    <div style={{ minHeight: "25px" }} className="form-19-challan-no border-all" ></div>
                                                </div>
                                            </div>
                                            {/* section 1.3.2 */}
                                            <div className='offset-3 col-9'>
                                                <div className="d-flex align-items-middle ">
                                                    <span className='form-sr-no'>1.3.2 </span><span style={{ width: "9%" }} className='ml-1'>Amount</span>
                                                    <div style={{ minHeight: "25px" }} className="form-19-challan-no border-all" ></div>
                                                    {/* <input type="text" className="form-control form-19-amount border-all" /> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* part II */}
                                    <PartsWrapper className='mt-4 mb-3'>
                                        PART-II
                                    </PartsWrapper>
                                    {/* <div className='col-12'>
                                        <h6 className='text-center'><u>PART-II</u></h6>
                                    </div> */}
                                    <div className='mb-3'>
                                        <div className='d-flex '>
                                            <span className='form-sr-no'>2.1</span>
                                            <span>Compliance against notice issue under sub-regulation (1) of Regulation 48;</span>
                                        </div>
                                    </div>
                                    <table className="table bt-1 mb-5">
                                        <tbody>
                                            <tr>
                                                <th style={{ borderTop: "1px solid black" }} className="border-all w-25 tr-h">Sr. No</th>
                                                <th style={{ borderTop: "1px solid black" }} className="border-all text-center w-50 tr-h">Particulars</th>
                                                <th style={{ borderTop: "1px solid black" }} className="border-all text-center w-25 tr-h">Response</th>
                                            </tr>
                                            <tr>
                                                <td className='vertical-align-top border-all'>i.</td>
                                                <td className='border-all'>
                                                    <p>The Company in {"<"}month{">"} has issued, a notice as per Form-16 to every member directly holding at least twenty five percent of shares or voting rights in the company or to the representative of every legal person or legal arrangement which holds at least twenty five percent of shares or voting rights in the Company, to obtain information of its ultimate beneficial owners, in compliance with sub-regulation (1) of regulation 48.</p>
                                                    <p className='d-flex mt-3 mb-4'>
                                                        The total number of notices issued is
                                                        <span className='d-block inline-input-150 ml-2'>
                                                            <div className='border-none border-bottom border-dark inline-input-h' ></div>
                                                        </span>.
                                                    </p>
                                                </td>
                                                <td className='border-all'>
                                                    <div className='d-flex justify-content-center align-items-middle'>
                                                        <div class="form-check form-check-inline ">
                                                            {/* <input class="form-check-input" type="checkbox" id="iYes" /> */}
                                                            <label class="form-check-label" htmlFor="iYes">Yes</label>
                                                            <span>/</span>
                                                        </div>
                                                        <div class="form-check form-check-inline ">
                                                            {/* <input class="form-check-input" type="checkbox" id="iNo" /> */}
                                                            <label class="form-check-label" htmlFor="iNo">No</label>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className='vertical-align-top border-all'>ii. If reply to (i) is Yes,</td>
                                                <td className='border-all'>
                                                    <p>The Company has received declaration as per Form-17 from the members/persons to whom notices have been issued, as required under sub-regulation (2) of regulation 48.</p>
                                                    <p>
                                                        Total no. of members directly holding at least twenty five percent of shares or voting rights in the company and representatives of legal persons or legal arrangements holding twenty five percent of shares or voting rights in the company, as on the date of Form-19:
                                                        <span className='inline-input-150'>
                                                            {/* <input type='text' className='form-control border-none border-bottom border-dark inline-input-h' /> */}
                                                        </span>
                                                    </p>
                                                    <p>
                                                        No. of members or submitting the declarations against the notice(s) issued is:
                                                        <span className='inline-input-150'>
                                                            {/* <input type='text' className='form-control border-none border-bottom border-dark inline-input-h' /> */}
                                                        </span>
                                                    </p>
                                                    <p>
                                                        No. of members or representatives who have failed to submit the declaration against the notice(s) issued is:
                                                        <span className='inline-input-150'>
                                                            {/* <input type='text' className='form-control border-none border-bottom border-dark inline-input-h' /> */}
                                                        </span>
                                                    </p>
                                                </td>
                                                <td className='border-all'>
                                                    <div className='d-flex justify-content-center align-items-middle'>
                                                        <div class="form-check form-check-inline ">
                                                            {/* <input class="form-check-input" type="checkbox" id="iiYes" /> */}
                                                            <label class="form-check-label" htmlFor="iiYes">Yes</label>
                                                            <span>/</span>
                                                        </div>
                                                        <div class="form-check form-check-inline ">
                                                            {/* <input class="form-check-input" type="checkbox" id="iiNo" /> */}
                                                            <label class="form-check-label" htmlFor="iiNo">No</label>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className='vertical-align-top border-all'>iii. If reply to (ii) is Yes,</td>
                                                <td className='border-all'>
                                                    <p>The Company has noted accurate particulars of its ultimate beneficial owners, received from the persons vide declaration as per Form-17, in a register of ultimate beneficial owners, as specified under sub-regulation (4) of regulation 48, and in compliance with the provisions of sub-section (2) of section 123A of the Act.</p>
                                                </td>
                                                <td className='border-all'>
                                                    <div className='d-flex justify-content-center align-items-middle'>
                                                        <div class="form-check form-check-inline ">
                                                            {/* <input class="form-check-input" type="checkbox" id="iiiYes" /> */}
                                                            <label class="form-check-label" htmlFor="iiiYes">Yes</label>
                                                            <span>/</span>
                                                        </div>
                                                        <div class="form-check form-check-inline ">
                                                            {/* <input class="form-check-input" type="checkbox" id="iiiNo" /> */}
                                                            <label class="form-check-label" htmlFor="iiiNo">No</label>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className='vertical-align-top border-all'>iv.</td>
                                                <td className='border-all'>
                                                    <p>The board of directors of the Company has authorized its cheif executive officer or one of its directors or offers to provide the information required under sub-regulation (6) of regulation 48 to the registar or any other authority or agency pursuant to the powers to call for information entrusted by law to such authority or agency, and to provide further assistance as may be required.</p>
                                                </td>
                                                <td className='border-all'>
                                                    <div className='d-flex justify-content-center align-items-middle'>
                                                        <div class="form-check form-check-inline ">
                                                            {/* <input class="form-check-input" type="checkbox" id="ivYes" /> */}
                                                            <label class="form-check-label" htmlFor="ivYes">Yes</label>
                                                            <span>/</span>
                                                        </div>
                                                        <div class="form-check form-check-inline ">
                                                            {/* <input class="form-check-input" type="checkbox" id="ivNo" /> */}
                                                            <label class="form-check-label" htmlFor="ivNo">No</label>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className='vertical-align-top border-all'>v. If reply to (iv) is yes.</td>
                                                <td className='border-all vertical-align-top'>
                                                    <p>The Company has nominated the following officer, as required in terms of sub-regulation (6) of regulation 48:</p>
                                                </td>
                                                <td className='border-all'>
                                                    <span className='d-block '>1. Name</span>
                                                    {/* <input type='text' className='form-control border-none border-bottom border-dark inline-input-h' /> */}
                                                    <span className='d-block '>2. Father's Name</span>
                                                    {/* <input type='text' className='form-control border-none border-bottom border-dark inline-input-h' /> */}
                                                    <span className='d-block '>3. Designation</span>
                                                    {/* <input type='text' className='form-control border-none border-bottom border-dark inline-input-h' /> */}
                                                    <span className='d-block '>4. CNIC No.</span>
                                                    {/* <input type='text' className='form-control border-none border-bottom border-dark inline-input-h' /> */}
                                                    <span className='d-block '>5. Cell No.</span>
                                                    {/* <input type='text' className='form-control border-none border-bottom border-dark inline-input-h' /> */}
                                                    <span className='d-block '>6. Email Address</span>
                                                    {/* <input type='text' className='form-control border-none border-bottom border-dark inline-input-h' /> */}
                                                    <span className='d-block '>7. Usual residential address</span>
                                                    {/* <input type='text' className='form-control border-none border-bottom border-dark inline-input-h mb-2' /> */}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <PartsWrapper className='mt-4 mb-3'>
                                            PART-III
                                    </PartsWrapper>
                                    <div className='row'>
                                        {/* part III */}
                                       
                                        {/* <div className='col-12'>
                                            <h6 className='text-center'><u>PART-III</u></h6>
                                        </div> */}
                                    </div>
                                    <div className='mb-3'>
                                        <div className='d-flex '>
                                            <span className='form-sr-no'>3.1</span>
                                            <span>Compliance in respect of induction of new members in terms of sub-regulation (2) or changes in particulars of ultimate beneficial owners in terms of sub-regulation (3) of Regulation 48:</span>
                                        </div>
                                    </div>
                                    <table className="table bt-1 mb-4">
                                        <tbody>
                                            <tr>
                                                <th style={{borderTop:"1px solid black"}} className="border-all w-25 tr-h">Sr. No</th>
                                                <th style={{borderTop:"1px solid black"}} className="border-all text-center w-50 tr-h">Particulars</th>
                                                <th style={{borderTop:"1px solid black"}} className="border-all text-center w-25 tr-h">Response</th>
                                            </tr>
                                            <tr>
                                                <td className='vertical-align-top border-all'>i.</td>
                                                <td className='border-all'>
                                                    <p>The Company has received, during the {"<"}year{">"}, declaration filed by the new members in Form-17, or declaration(s) for changes in the beneficial ownership or controlling interest from the member(s) in Form-18, as required under sub-regulation (2) or sub-regulation (3) of regulation 48, respectively.</p>
                                                </td>
                                                <td className='border-all'>
                                                    <div className='d-flex justify-content-center align-items-middle'>
                                                        <div class="form-check form-check-inline ">
                                                            {/* <input class="form-check-input" type="checkbox" id="p_3_iYes" /> */}
                                                            <label class="form-check-label" htmlFor="p_3_iYes">Yes</label>
                                                            <span>/</span>
                                                        </div>
                                                        <div class="form-check form-check-inline ">
                                                            {/* <input class="form-check-input" type="checkbox" id="p_3_iNo" /> */}
                                                            <label class="form-check-label" htmlFor="p_3_iNo">No</label>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className='vertical-align-top border-all'>ii. If reply to (i) is Yes,</td>
                                                <td className='border-all'>
                                                    <p>The Company has noted the accurate and updated particulars of its ultimate beneficial owners received through declaration(s) during the {"<"}year{">"} in the register of ultimate beneficial owners, as specified under sub-regulation (4) of regulation 48, and in compliance with the provisions of sub-section (2) of section 123A of the Act.</p>
                                                </td>
                                                <td className='border-all'>
                                                    <div className='d-flex justify-content-center align-items-middle'>
                                                        <div class="form-check form-check-inline ">
                                                            {/* <input class="form-check-input" type="checkbox" id="p_3_iiYes" /> */}
                                                            <label class="form-check-label" htmlFor="p_3_iiYes">Yes</label>
                                                            <span>/</span>
                                                        </div>
                                                        <div class="form-check form-check-inline ">
                                                            {/* <input class="form-check-input" type="checkbox" id="p_3_iiNo" /> */}
                                                            <label class="form-check-label" htmlFor="p_3_iiNo">No</label>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className='vertical-align-top border-all'>iii.</td>
                                                <td className='border-all'>
                                                    <p>The board of directors of the Company has authorized its cheif executive officer or one of its directors or officers to provide the information required under sub-regulation(6) of regulation 48 to the registar or any other authority or agency pursuant to the powers to call for information entrusted by law to such authority or agency, and to provide further assistance as may be required.</p>
                                                </td>
                                                <td className='border-all'>
                                                    <div className='d-flex justify-content-center align-items-middle'>
                                                        <div class="form-check form-check-inline ">
                                                            {/* <input class="form-check-input" type="checkbox" id="p_3_iiiYes" /> */}
                                                            <label class="form-check-label" htmlFor="p_3_iiiYes">Yes</label>
                                                            <span>/</span>
                                                        </div>
                                                        <div class="form-check form-check-inline ">
                                                            {/* <input class="form-check-input" type="checkbox" id="p_3_iiiNo" /> */}
                                                            <label class="form-check-label" htmlFor="p_3_iiiNo">No</label>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className='vertical-align-top border-all'>iv. If reply to (iii) is yes,</td>
                                                <td className='border-all vertical-align-top'>
                                                    <p>The Company has nominated the following officer, as required in terms of sub-regulation (6) of regulation 48:</p>
                                                </td>
                                                <td className='border-all'>
                                                    <span className='d-block '>1. Name</span>
                                                    {/* <input type='text' className='form-control border-none border-bottom border-dark inline-input-h' /> */}
                                                    <span className='d-block '>2. Father's Name</span>
                                                    {/* <input type='text' className='form-control border-none border-bottom border-dark inline-input-h' /> */}
                                                    <span className='d-block '>3. Designation</span>
                                                    {/* <input type='text' className='form-control border-none border-bottom border-dark inline-input-h' /> */}
                                                    <span className='d-block '>4. CNIC No.</span>
                                                    {/* <input type='text' className='form-control border-none border-bottom border-dark inline-input-h' /> */}
                                                    <span className='d-block '>5. Cell No.</span>
                                                    {/* <input type='text' className='form-control border-none border-bottom border-dark inline-input-h' /> */}
                                                    <span className='d-block '>6. Email Address</span>
                                                    {/* <input type='text' className='form-control border-none border-bottom border-dark inline-input-h' /> */}
                                                    <span className='d-block '>7. Usual residential address</span>
                                                    {/* <input type='text' className='form-control border-none border-bottom border-dark inline-input-h mb-2' /> */}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <PartsWrapper className='mt-4 mb-3'>
                                            PART-IV
                                    </PartsWrapper>
                                    <div className='row'>
                                        {/* part IV */}
                                       
                                        {/* <div className='col-12 mb-1'>
                                            <h6 className='text-center'><u>PART-IV</u></h6>
                                        </div> */}
                                        {/* section 4.1 */}
                                        <div className='col-12'>
                                            <div className='d-flex '>
                                                <span className='form-sr-no'>4.1</span><strong className='ml-2'>Declaration:</strong>
                                            </div>
                                        </div>
                                        <div className='col-12'>
                                            <div className='col-12  mb-3'>
                                                <div className='declaration my-1'>
                                                    I do hereby solemnly and sincerely declare that the information provided in the form is:
                                                </div>
                                                <ol style={{display:"block"}} className="declaration-ol">
                                                    <li className='mb-4'>true and correct to the best of my knowlege, in consonance with the record as maintained by the Company and nothing has been concealed; and</li>
                                                    <li>hereby reported after complying with and fulfilling all requirements under the relevant provisions of law, rules, regulations, directives, circulars and notifications whichever is applicable.</li>
                                                </ol>
                                            </div>
                                        </div>
                                        {/* section 4.2 */}
                                        <div className='col-12  mb-3'>
                                            <div className='row'>
                                                <div className='col-6 d-flex'><span className='form-sr-no'>4.2</span>
                                                    Name of Authorized Officer with designation
                                                </div>
                                                <div className='col-6 d-flex'>
                                                    
                                                        <div style={{width:"50%",minHeight:"38px"}} className=' border-all' ></div>
                                                    
                                                    
                                                        <div style={{width:"50%",minHeight:"38px"}} className=' border-all border-left-0' ></div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        {/* section 4.3 */}
                                        <div className='col-12  mb-5'>
                                            <div className='row'>
                                                <div className='col-6 d-flex'><span className='form-sr-no'>4.3</span>
                                                    Signatures
                                                </div>
                                                <div className='col-6 d-flex'>
                                                    <div className="signatures border-all"></div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* section 4.4 */}
                                        <div className='col-12 '>
                                            <div className='row'>
                                                <div className='offset-6 col-6 text-center'>
                                                    <div className="row">
                                                        <div className="col-3"><span className='ml-2'>Day</span></div>
                                                        <div className="col-3"><span className='ml-2'>Month</span></div>
                                                        <div className="col-6">Year</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-12  mb-3'>
                                            <div className='row'>
                                                <div className='col-6 d-flex'><span className='form-sr-no mt-2'>4.4</span><span className='mt-2'>Date</span></div>
                                                <div className='col-6'>
                                                    <div className="row">
                                                        <div className="col-3">
                                                            <table className="w-100">
                                                                <tbody>
                                                                    <tr>
                                                                        <td className='d-flex justify-content-center'>
                                                                            <div className="date-day text-center border-all" ></div>
                                                                            <div className="date-day text-center border-all border-left-0"></div>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        <div className="col-3">
                                                            <table className="w-100">
                                                                <tbody>
                                                                    <tr>
                                                                        <td className='d-flex justify-content-center'>
                                                                            <div className="date-month text-center border-all" ></div>
                                                                            <div className="date-month text-center border-all border-left-0" ></div>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        <div className="col-6">
                                                            <table className="w-100">
                                                                <tbody>
                                                                    <tr>
                                                                        <td className='d-flex justify-content-center'>
                                                                            <div className="date-year text-center border-all" ></div>
                                                                            <div className="date-year text-center border-all border-left-0" ></div>
                                                                            <div className="date-year text-center border-all border-left-0" ></div>
                                                                            <div className="date-year text-center border-all border-left-0" ></div>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-12'>
                                            <p className=' pl-5'>* For the first time the company issues notice to its members in Form-16, the month during which such notices have been issued shall be mentioned.</p>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </PDFExport>
                    : <PDFExport
                        paperSize="A4"
                        margin="1cm"
                        scale={0.6}
                        fileName={`Form 19`}
                        pageTemplate={PageTemplate}
                        ref={pdfExportComponent}
                    >
                        <div style={{ fontFamily: `${fontFamilyForStaticData}`, fontSize: `${fontSizeForStaticData}`, fontWeight: 500 }}>
                            <FormName>
                                Form-19
                            </FormName>
                            <div className='row'>
                                <div className='col-md-12' >
                                    <HeadingWrapper className='d-flex justify-content-center'>
                                        THE COMPANIES ACT, 2017
                                    </HeadingWrapper>

                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <HeadingWrapper className='d-flex justify-content-center'>
                                        THE COMPANIES REGULATIONS, 2024
                                    </HeadingWrapper>

                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <div style={{
                                        fontSize: '15px',
                                        // fontWeight: 'bold' 
                                    }} className='d-flex justify-content-center'>
                                        [Section 123A(2) and Regulations 48(5) & 30]
                                    </div>

                                </div>
                            </div>

                            <div className='row mt-4'>
                                <div className='col-md-12'>
                                    <HeadingWrapper className='text-center d-flex justify-content-center'>
                                        DECLARATION OF COMPANIES WITH THE PROVISIONS OF SECTION 123A OF THE COMPANIES ACT, 2017
                                    </HeadingWrapper>

                                </div>
                            </div>

                            <PartsWrapper className='mt-4 mb-3'>
                                PART-I
                            </PartsWrapper>

                            <div className='row'>
                                <div className='col-md-12' style={{ padding: '25px 50px', wordWrap: 'break-word' }}>
                                    {/* heading right*/}

                                    {/* section 1.1 */}
                                    <div className='col-12 mb-3'>
                                        <div className='row'>
                                            <div className='col-5 d-flex '>
                                                <span className='form-sr-no'>1.1 </span>CUIN (Registration Number)</div>
                                            <div className='col-7 reg-cells'>
                                                <div style={{ minHeight: "25px" }} className="reg-no reg-cell" ></div>
                                                <div style={{ minHeight: "25px" }} className="reg-no reg-cell" ></div>
                                                <div style={{ minHeight: "25px" }} className="reg-no reg-cell" ></div>
                                                <div style={{ minHeight: "25px" }} className="reg-no reg-cell" ></div>
                                                <div style={{ minHeight: "25px" }} className="reg-no reg-cell" ></div>
                                                <div style={{ minHeight: "25px" }} className="reg-no reg-cell" ></div>
                                                <div style={{ minHeight: "25px" }} className="reg-no border-all" ></div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* section 1.2 */}
                                    <div className='col-12 mb-2'>
                                        <div className='row align-items-start'>
                                            <div className='col-5 d-flex '><span className='form-sr-no mt-0'>1.2 </span>
                                                <span className='mt-0'>Name of the Company</span>
                                            </div>
                                            <div className='col-7'>
                                                <div style={{ minHeight: "25px" }} className="company-name border-all ml-1" ></div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* section 1.3 */}
                                    <div className='col-12 mb-3'>
                                        <div className='row mt-0'>
                                            <div className='col-12 d-flex '><span className='form-sr-no'>1.3 </span>Fee Payment Details</div>
                                            {/* section 1.3.1 */}
                                            <div className='offset-3 col-9 mb-4'>
                                                <div className="d-flex align-items-middle ">
                                                    <span className='form-sr-no'>1.3.1 </span><span className='ml-1'>Challan No</span>
                                                    <div style={{ minHeight: "25px" }} className="form-19-challan-no border-all" ></div>
                                                </div>
                                            </div>
                                            {/* section 1.3.2 */}
                                            <div className='offset-3 col-9'>
                                                <div className="d-flex align-items-middle ">
                                                    <span className='form-sr-no'>1.3.2 </span><span style={{ width: "9%" }} className='ml-1'>Amount</span>
                                                    <div style={{ minHeight: "25px" }} className="form-19-challan-no border-all" ></div>
                                                    {/* <input type="text" className="form-control form-19-amount border-all" /> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* part II */}
                                    <PartsWrapper className='mt-4 mb-3'>
                                        PART-II
                                    </PartsWrapper>
                                    {/* <div className='col-12'>
                                        <h6 className='text-center'><u>PART-II</u></h6>
                                    </div> */}
                                    <div className='mb-3'>
                                        <div className='d-flex '>
                                            <span className='form-sr-no'>2.1</span>
                                            <span>Compliance against notice issue under sub-regulation (1) of Regulation 48;</span>
                                        </div>
                                    </div>
                                    <table className="table bt-1 mb-5">
                                        <tbody>
                                            <tr>
                                                <th style={{ borderTop: "1px solid black" }} className="border-all w-25 tr-h">Sr. No</th>
                                                <th style={{ borderTop: "1px solid black" }} className="border-all text-center w-50 tr-h">Particulars</th>
                                                <th style={{ borderTop: "1px solid black" }} className="border-all text-center w-25 tr-h">Response</th>
                                            </tr>
                                            <tr>
                                                <td className='vertical-align-top border-all'>i.</td>
                                                <td className='border-all'>
                                                    <p>The Company in {"<"}month{">"} has issued, a notice as per Form-16 to every member directly holding at least twenty five percent of shares or voting rights in the company or to the representative of every legal person or legal arrangement which holds at least twenty five percent of shares or voting rights in the Company, to obtain information of its ultimate beneficial owners, in compliance with sub-regulation (1) of regulation 48.</p>
                                                    <p className='d-flex mt-3 mb-4'>
                                                        The total number of notices issued is
                                                        <span className='d-block inline-input-150 ml-2'>
                                                            <div className='border-none border-bottom border-dark inline-input-h' ></div>
                                                        </span>.
                                                    </p>
                                                </td>
                                                <td className='border-all'>
                                                    <div className='d-flex justify-content-center align-items-middle'>
                                                        <div class="form-check form-check-inline ">
                                                            {/* <input class="form-check-input" type="checkbox" id="iYes" /> */}
                                                            <label class="form-check-label" htmlFor="iYes">Yes</label>
                                                            <span>/</span>
                                                        </div>
                                                        <div class="form-check form-check-inline ">
                                                            {/* <input class="form-check-input" type="checkbox" id="iNo" /> */}
                                                            <label class="form-check-label" htmlFor="iNo">No</label>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className='vertical-align-top border-all'>ii. If reply to (i) is Yes,</td>
                                                <td className='border-all'>
                                                    <p>The Company has received declaration as per Form-17 from the members/persons to whom notices have been issued, as required under sub-regulation (2) of regulation 48.</p>
                                                    <p>
                                                        Total no. of members directly holding at least twenty five percent of shares or voting rights in the company and representatives of legal persons or legal arrangements holding twenty five percent of shares or voting rights in the company, as on the date of Form-19:
                                                        <span className='inline-input-150'>
                                                            {/* <input type='text' className='form-control border-none border-bottom border-dark inline-input-h' /> */}
                                                        </span>
                                                    </p>
                                                    <p>
                                                        No. of members or submitting the declarations against the notice(s) issued is:
                                                        <span className='inline-input-150'>
                                                            {/* <input type='text' className='form-control border-none border-bottom border-dark inline-input-h' /> */}
                                                        </span>
                                                    </p>
                                                    <p>
                                                        No. of members or representatives who have failed to submit the declaration against the notice(s) issued is:
                                                        <span className='inline-input-150'>
                                                            {/* <input type='text' className='form-control border-none border-bottom border-dark inline-input-h' /> */}
                                                        </span>
                                                    </p>
                                                </td>
                                                <td className='border-all'>
                                                    <div className='d-flex justify-content-center align-items-middle'>
                                                        <div class="form-check form-check-inline ">
                                                            {/* <input class="form-check-input" type="checkbox" id="iiYes" /> */}
                                                            <label class="form-check-label" htmlFor="iiYes">Yes</label>
                                                            <span>/</span>
                                                        </div>
                                                        <div class="form-check form-check-inline ">
                                                            {/* <input class="form-check-input" type="checkbox" id="iiNo" /> */}
                                                            <label class="form-check-label" htmlFor="iiNo">No</label>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className='vertical-align-top border-all'>iii. If reply to (ii) is Yes,</td>
                                                <td className='border-all'>
                                                    <p>The Company has noted accurate particulars of its ultimate beneficial owners, received from the persons vide declaration as per Form-17, in a register of ultimate beneficial owners, as specified under sub-regulation (4) of regulation 48, and in compliance with the provisions of sub-section (2) of section 123A of the Act.</p>
                                                </td>
                                                <td className='border-all'>
                                                    <div className='d-flex justify-content-center align-items-middle'>
                                                        <div class="form-check form-check-inline ">
                                                            {/* <input class="form-check-input" type="checkbox" id="iiiYes" /> */}
                                                            <label class="form-check-label" htmlFor="iiiYes">Yes</label>
                                                            <span>/</span>
                                                        </div>
                                                        <div class="form-check form-check-inline ">
                                                            {/* <input class="form-check-input" type="checkbox" id="iiiNo" /> */}
                                                            <label class="form-check-label" htmlFor="iiiNo">No</label>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className='vertical-align-top border-all'>iv.</td>
                                                <td className='border-all'>
                                                    <p>The board of directors of the Company has authorized its cheif executive officer or one of its directors or offers to provide the information required under sub-regulation (6) of regulation 48 to the registar or any other authority or agency pursuant to the powers to call for information entrusted by law to such authority or agency, and to provide further assistance as may be required.</p>
                                                </td>
                                                <td className='border-all'>
                                                    <div className='d-flex justify-content-center align-items-middle'>
                                                        <div class="form-check form-check-inline ">
                                                            {/* <input class="form-check-input" type="checkbox" id="ivYes" /> */}
                                                            <label class="form-check-label" htmlFor="ivYes">Yes</label>
                                                            <span>/</span>
                                                        </div>
                                                        <div class="form-check form-check-inline ">
                                                            {/* <input class="form-check-input" type="checkbox" id="ivNo" /> */}
                                                            <label class="form-check-label" htmlFor="ivNo">No</label>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className='vertical-align-top border-all'>v. If reply to (iv) is yes.</td>
                                                <td className='border-all vertical-align-top'>
                                                    <p>The Company has nominated the following officer, as required in terms of sub-regulation (6) of regulation 48:</p>
                                                </td>
                                                <td className='border-all'>
                                                    <span className='d-block '>1. Name</span>
                                                    {/* <input type='text' className='form-control border-none border-bottom border-dark inline-input-h' /> */}
                                                    <span className='d-block '>2. Father's Name</span>
                                                    {/* <input type='text' className='form-control border-none border-bottom border-dark inline-input-h' /> */}
                                                    <span className='d-block '>3. Designation</span>
                                                    {/* <input type='text' className='form-control border-none border-bottom border-dark inline-input-h' /> */}
                                                    <span className='d-block '>4. CNIC No.</span>
                                                    {/* <input type='text' className='form-control border-none border-bottom border-dark inline-input-h' /> */}
                                                    <span className='d-block '>5. Cell No.</span>
                                                    {/* <input type='text' className='form-control border-none border-bottom border-dark inline-input-h' /> */}
                                                    <span className='d-block '>6. Email Address</span>
                                                    {/* <input type='text' className='form-control border-none border-bottom border-dark inline-input-h' /> */}
                                                    <span className='d-block '>7. Usual residential address</span>
                                                    {/* <input type='text' className='form-control border-none border-bottom border-dark inline-input-h mb-2' /> */}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <PartsWrapper className='mt-4 mb-3'>
                                            PART-III
                                    </PartsWrapper>
                                    <div className='row'>
                                        {/* part III */}
                                       
                                        {/* <div className='col-12'>
                                            <h6 className='text-center'><u>PART-III</u></h6>
                                        </div> */}
                                    </div>
                                    <div className='mb-3'>
                                        <div className='d-flex '>
                                            <span className='form-sr-no'>3.1</span>
                                            <span>Compliance in respect of induction of new members in terms of sub-regulation (2) or changes in particulars of ultimate beneficial owners in terms of sub-regulation (3) of Regulation 48:</span>
                                        </div>
                                    </div>
                                    <table className="table bt-1 mb-4">
                                        <tbody>
                                            <tr>
                                                <th style={{borderTop:"1px solid black"}} className="border-all w-25 tr-h">Sr. No</th>
                                                <th style={{borderTop:"1px solid black"}} className="border-all text-center w-50 tr-h">Particulars</th>
                                                <th style={{borderTop:"1px solid black"}} className="border-all text-center w-25 tr-h">Response</th>
                                            </tr>
                                            <tr>
                                                <td className='vertical-align-top border-all'>i.</td>
                                                <td className='border-all'>
                                                    <p>The Company has received, during the {"<"}year{">"}, declaration filed by the new members in Form-17, or declaration(s) for changes in the beneficial ownership or controlling interest from the member(s) in Form-18, as required under sub-regulation (2) or sub-regulation (3) of regulation 48, respectively.</p>
                                                </td>
                                                <td className='border-all'>
                                                    <div className='d-flex justify-content-center align-items-middle'>
                                                        <div class="form-check form-check-inline ">
                                                            {/* <input class="form-check-input" type="checkbox" id="p_3_iYes" /> */}
                                                            <label class="form-check-label" htmlFor="p_3_iYes">Yes</label>
                                                            <span>/</span>
                                                        </div>
                                                        <div class="form-check form-check-inline ">
                                                            {/* <input class="form-check-input" type="checkbox" id="p_3_iNo" /> */}
                                                            <label class="form-check-label" htmlFor="p_3_iNo">No</label>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className='vertical-align-top border-all'>ii. If reply to (i) is Yes,</td>
                                                <td className='border-all'>
                                                    <p>The Company has noted the accurate and updated particulars of its ultimate beneficial owners received through declaration(s) during the {"<"}year{">"} in the register of ultimate beneficial owners, as specified under sub-regulation (4) of regulation 48, and in compliance with the provisions of sub-section (2) of section 123A of the Act.</p>
                                                </td>
                                                <td className='border-all'>
                                                    <div className='d-flex justify-content-center align-items-middle'>
                                                        <div class="form-check form-check-inline ">
                                                            {/* <input class="form-check-input" type="checkbox" id="p_3_iiYes" /> */}
                                                            <label class="form-check-label" htmlFor="p_3_iiYes">Yes</label>
                                                            <span>/</span>
                                                        </div>
                                                        <div class="form-check form-check-inline ">
                                                            {/* <input class="form-check-input" type="checkbox" id="p_3_iiNo" /> */}
                                                            <label class="form-check-label" htmlFor="p_3_iiNo">No</label>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className='vertical-align-top border-all'>iii.</td>
                                                <td className='border-all'>
                                                    <p>The board of directors of the Company has authorized its cheif executive officer or one of its directors or officers to provide the information required under sub-regulation(6) of regulation 48 to the registar or any other authority or agency pursuant to the powers to call for information entrusted by law to such authority or agency, and to provide further assistance as may be required.</p>
                                                </td>
                                                <td className='border-all'>
                                                    <div className='d-flex justify-content-center align-items-middle'>
                                                        <div class="form-check form-check-inline ">
                                                            {/* <input class="form-check-input" type="checkbox" id="p_3_iiiYes" /> */}
                                                            <label class="form-check-label" htmlFor="p_3_iiiYes">Yes</label>
                                                            <span>/</span>
                                                        </div>
                                                        <div class="form-check form-check-inline ">
                                                            {/* <input class="form-check-input" type="checkbox" id="p_3_iiiNo" /> */}
                                                            <label class="form-check-label" htmlFor="p_3_iiiNo">No</label>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className='vertical-align-top border-all'>iv. If reply to (iii) is yes,</td>
                                                <td className='border-all vertical-align-top'>
                                                    <p>The Company has nominated the following officer, as required in terms of sub-regulation (6) of regulation 48:</p>
                                                </td>
                                                <td className='border-all'>
                                                    <span className='d-block '>1. Name</span>
                                                    {/* <input type='text' className='form-control border-none border-bottom border-dark inline-input-h' /> */}
                                                    <span className='d-block '>2. Father's Name</span>
                                                    {/* <input type='text' className='form-control border-none border-bottom border-dark inline-input-h' /> */}
                                                    <span className='d-block '>3. Designation</span>
                                                    {/* <input type='text' className='form-control border-none border-bottom border-dark inline-input-h' /> */}
                                                    <span className='d-block '>4. CNIC No.</span>
                                                    {/* <input type='text' className='form-control border-none border-bottom border-dark inline-input-h' /> */}
                                                    <span className='d-block '>5. Cell No.</span>
                                                    {/* <input type='text' className='form-control border-none border-bottom border-dark inline-input-h' /> */}
                                                    <span className='d-block '>6. Email Address</span>
                                                    {/* <input type='text' className='form-control border-none border-bottom border-dark inline-input-h' /> */}
                                                    <span className='d-block '>7. Usual residential address</span>
                                                    {/* <input type='text' className='form-control border-none border-bottom border-dark inline-input-h mb-2' /> */}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <PartsWrapper className='mt-4 mb-3'>
                                            PART-IV
                                    </PartsWrapper>
                                    <div className='row'>
                                        {/* part IV */}
                                       
                                        {/* <div className='col-12 mb-1'>
                                            <h6 className='text-center'><u>PART-IV</u></h6>
                                        </div> */}
                                        {/* section 4.1 */}
                                        <div className='col-12'>
                                            <div className='d-flex '>
                                                <span className='form-sr-no'>4.1</span><strong className='ml-2'>Declaration:</strong>
                                            </div>
                                        </div>
                                        <div className='col-12'>
                                            <div className='col-12  mb-3'>
                                                <div className='declaration my-1'>
                                                    I do hereby solemnly and sincerely declare that the information provided in the form is:
                                                </div>
                                                <ol style={{display:"block"}} className="declaration-ol">
                                                    <li className='mb-4'>true and correct to the best of my knowlege, in consonance with the record as maintained by the Company and nothing has been concealed; and</li>
                                                    <li>hereby reported after complying with and fulfilling all requirements under the relevant provisions of law, rules, regulations, directives, circulars and notifications whichever is applicable.</li>
                                                </ol>
                                            </div>
                                        </div>
                                        {/* section 4.2 */}
                                        <div className='col-12  mb-3'>
                                            <div className='row'>
                                                <div className='col-6 d-flex'><span className='form-sr-no'>4.2</span>
                                                    Name of Authorized Officer with designation
                                                </div>
                                                <div className='col-6 d-flex'>
                                                    
                                                        <div style={{width:"50%",minHeight:"38px"}} className=' border-all' ></div>
                                                    
                                                    
                                                        <div style={{width:"50%",minHeight:"38px"}} className=' border-all border-left-0' ></div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        {/* section 4.3 */}
                                        <div className='col-12  mb-5'>
                                            <div className='row'>
                                                <div className='col-6 d-flex'><span className='form-sr-no'>4.3</span>
                                                    Signatures
                                                </div>
                                                <div className='col-6 d-flex'>
                                                    <div className="signatures border-all"></div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* section 4.4 */}
                                        <div className='col-12 '>
                                            <div className='row'>
                                                <div className='offset-6 col-6 text-center'>
                                                    <div className="row">
                                                        <div className="col-3"><span className='ml-2'>Day</span></div>
                                                        <div className="col-3"><span className='ml-2'>Month</span></div>
                                                        <div className="col-6">Year</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-12  mb-3'>
                                            <div className='row'>
                                                <div className='col-6 d-flex'><span className='form-sr-no mt-2'>4.4</span><span className='mt-2'>Date</span></div>
                                                <div className='col-6'>
                                                    <div className="row">
                                                        <div className="col-3">
                                                            <table className="w-100">
                                                                <tbody>
                                                                    <tr>
                                                                        <td className='d-flex justify-content-center'>
                                                                            <div className="date-day text-center border-all" ></div>
                                                                            <div className="date-day text-center border-all border-left-0"></div>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        <div className="col-3">
                                                            <table className="w-100">
                                                                <tbody>
                                                                    <tr>
                                                                        <td className='d-flex justify-content-center'>
                                                                            <div className="date-month text-center border-all" ></div>
                                                                            <div className="date-month text-center border-all border-left-0" ></div>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        <div className="col-6">
                                                            <table className="w-100">
                                                                <tbody>
                                                                    <tr>
                                                                        <td className='d-flex justify-content-center'>
                                                                            <div className="date-year text-center border-all" ></div>
                                                                            <div className="date-year text-center border-all border-left-0" ></div>
                                                                            <div className="date-year text-center border-all border-left-0" ></div>
                                                                            <div className="date-year text-center border-all border-left-0" ></div>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-12'>
                                            <p className=' pl-5'>* For the first time the company issues notice to its members in Form-16, the month during which such notices have been issued shall be mentioned.</p>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </PDFExport>}
            </div>
            <button disabled={loading} className='btn btn-danger' onClick={(e) => {
                // if(viewPdf)
                //   SetViewPDf(true)
                if (pdfExportComponent.current) {
                    pdfExportComponent.current.save();
                }
            }}>Download PDF</button>
        </div>
    )
}
const PartsWrapper = styled.div`
color: #121212;
text-decoration: underline;
justify-content: center;
display: flex;
margin-right: 15px;
font-size: 17px;
font-weight: bold;
`;
const ScrollWrapper = styled.div`
max-height: 50px;
overflow-y: scroll;
overflow-x: hidden;
::-webkit-scrollbar{
    height: 5px;
    width: 3px;
  }
  
  ::-webkit-scrollbar-track{
    background: #F9F9FB;
  }
  ::-webkit-scrollbar-thumb{
    background: #4E515680;
    border-radius: 5px;
  
  }
`;
const HeadingWrapper = styled.div`
font-size: 17px;
font-weight: bold;
`;
const FormName = styled.div`
color: #121212;
text-decoration: underline;
justify-content: end;
display: flex;
margin-right: 15px;
font-size: 17px;
font-weight: bold;
`;
const Wrapper = styled.div`
table.table-bordered{
    border: 1px solid #121212
     border-color: #121212; 
    // border:1px solid blue;
    // margin-top:20px;
  }
// table.table-bordered > thead > tr > th{
//     // border:1px solid blue;
//     border-color: #121212; 
// }
// table.table-bordered > tbody > tr > td{
//     // border:1px solid blue;
//     borderC-color: #121212; 
// }
 tr td {
    // border:1px solid blue;.
    border-color: #121212; 
}

`;
const WrapperDashed = styled.div`

  td {
    
    border: 1px dashed #dddcdc;
}

`;