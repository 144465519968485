import React, { useState, useEffect } from 'react'
import { PDFExport } from "@progress/kendo-react-pdf";
import PageTemplate from "./page-template";
import {
    getCompanies,
} from "../../../store/services/company.service";
import styled from 'styled-components';
import { TiTick } from "react-icons/ti";
import { numberWithCommas } from 'utilities/utilityFunctions';
export const StatutoryForm27 = ({ data, formTemplate = false }) => {
    const pdfExportComponent = React.useRef(null);
    const [CompanyData, setCompanyData] = useState(null)
    const [loading, setLoading] = useState(false);
    const [fontFamilyForStaticData, setFontFamilyForStaticData] = useState('Montserrat, sans-serif')
    const [fontSizeForStaticData, setFontSizeForStaticData] = useState('14px')
    const [fontfamilyforDynimicData, setFontFamilyForDynmicData] = useState('Segoe Print')
    const [fontColor, setFontColor] = useState('')
    const baseEmail = sessionStorage.getItem("email") || "";
    const [governance, setGovernance] = useState({})
    const getAllCompanies = async () => {
        setLoading(true)
        try {
            const response = await getCompanies(baseEmail);
            if (response.status === 200) {
                response.data.data = response.data.data.filter((item) => item?.active === 'Y')
                const parents = response.data.data;
                const company = parents?.find(ite => ite?.code == data?.company_code)
                let Allperson = company.governance && company.governance != "" ? JSON.parse(company.governance) : []
                let sec = Allperson.find(x => x.role == "Company Secretary" && x?.active?.toLowerCase() != 'n')
                let ceo = Allperson.find(x => x.role == "CEO" && x?.active?.toLowerCase() != 'n')


                if (sec) {
                    setGovernance(sec)
                } else if (ceo) {
                    setGovernance(ceo)
                }
                setCompanyData(company)
                setTimeout(() => {
                    setLoading(false)
                }, 1000);
            }
        } catch (error) {
            setLoading(false)
        }
    };
    useEffect(() => {
        getAllCompanies()
    }, [])
    return (
        <div>
            <div
                style={{ fontFamily: "sans-serif " }}
            >

                {formTemplate ?
                    <PDFExport
                        paperSize="A4"
                        margin="1cm"
                        scale={0.6}
                        fileName={`Form 27`}
                        pageTemplate={PageTemplate}
                        ref={pdfExportComponent}
                    >
                        <div style={{ fontFamily: `${fontFamilyForStaticData}`, fontSize: `${fontSizeForStaticData}`, fontWeight: 500 }}>
                            <FormName>
                                Form-27
                            </FormName>
                            <div className='row'>
                                <div className='col-md-12' >
                                    <HeadingWrapper className='d-flex justify-content-center'>
                                        THE COMPANIES ACT, 2017
                                    </HeadingWrapper>

                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <HeadingWrapper className='d-flex justify-content-center'>
                                        THE COMPANIES REGULATIONS, 2024
                                    </HeadingWrapper>

                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <div style={{ fontSize: '15px', fontWeight: 'bold' }} className='d-flex justify-content-center'>
                                        [Section 88 and Regulation 121(1)(f)]
                                    </div>
                                </div>
                            </div>

                            <div className='row mt-4'>
                                <div className='col-md-12'>
                                    <HeadingWrapper className='text-center d-flex justify-content-center'>
                                        FINAL RETURN FOR BUY-BACK OF SHARES OF UNLISTED COMPANIES
                                    </HeadingWrapper>
                                </div>
                            </div>
                            <PartsWrapper className='mt-4 mb-3'>
                                PART-I
                            </PartsWrapper>
                            <div className='row'>
                                <div className='col-md-12' style={{ padding: '25px 50px', wordWrap: 'break-word' }}>
                                    <div> <i>(Please complete in bold capital letters)</i></div>


                                    <div className='d-flex' >
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '1%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>1.1</div>
                                        <div className='align-items-center' style={{ width: '28%', padding: '0px 10px 10px 10px' }}>CUIN (Registration Number)  </div>
                                        <div className='align-items-center  d-flex'  >{
                                            '1234 552'.split("")?.map((item, idx) => {
                                                if (item == ' ') return
                                                return <div style={{ minWidth: '30px', minHeight: '34px', border: '1px solid #121212', borderLeft: idx != 0 ? "none" : "1px solid #121212", padding: '6px 9px' }}></div>
                                            })
                                        }
                                        </div>
                                    </div>



                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '1%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>1.2</div>
                                        <div className='align-items-center' style={{ width: '29%', padding: '0px 10px 10px 10px' }}>Name of the Company  </div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '68%', padding: '0px 10px 10px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                    </div>




                                    <div className='d-flex  mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>1.3</div>
                                        <div className='align-items-center' style={{ width: '20.20%', padding: '0px 10px 10px 10px' }}>Fee payment Details*</div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '5%' }}>1.3.1</div>
                                        <div className='align-items-center' style={{ width: '13.20%', paddingLeft: '20px' }}>Challan No.</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '17.20%', paddingLeft: '20px', minHeight: '34px' }}></ScrollWrapper>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '5%' }}>1.3.2</div>
                                        <div className='align-items-center' style={{ width: '13.20%', paddingLeft: '20px' }}>Amount</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '23.20%', paddingLeft: '20px', minHeight: '34px' }}></ScrollWrapper>
                                    </div>
                                    <PartsWrapper className='mt-4 mb-3'>
                                        PART-II
                                    </PartsWrapper>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '1%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%', fontWeight: "bold" }}>2.</div>
                                        <div className='align-items-center' style={{ padding: '0px 10px 10px 10px', fontWeight: "bold" }}>Share Capital</div>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '1%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>2.1</div>
                                        <div className='align-items-center' style={{ width: '29%', padding: '0px 10px 10px 10px' }}>Paid up capital (in Rupees before buy back)</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '68%', padding: '0px 10px 10px 10px' }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '1%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>2.2</div>
                                        <div className='align-items-center' style={{ width: '29%', padding: '0px 10px 10px 10px' }}>Number of shares purchased</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '68%', padding: '0px 10px 10px 10px' }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '1%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>2.3</div>
                                        <div className='align-items-center' style={{ width: '29%', padding: '0px 10px 10px 10px' }}>Paid up Capital (in Rupees after buy back)</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '68%', padding: '0px 10px 10px 10px' }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '1%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>2.4</div>
                                        <div className='align-items-center' style={{ padding: '0px 10px 10px 10px', fontWeight: "bold" }}> Source of funds utilized</div>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '1%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '4%' }}></div>
                                        <div className='align-items-center' style={{ width: '32%', padding: '0px 10px 10px 10px' }}>(i) Distributable profit, and / or</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '68%', padding: '0px 10px 10px 10px' }}> Mention amount (Rs.)</ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '1%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '4%' }}></div>
                                        <div className='align-items-center' style={{ width: '32%', padding: '0px 10px 10px 10px' }}>(ii) Reserves, and/or</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '68%', padding: '0px 10px 10px 10px' }}> Mention amount (Rs.)</ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '1%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '4%' }}></div>
                                        <div className='align-items-center' style={{ width: '32%', padding: '0px 10px 10px 10px' }}>(iii) other resources (in case of startups in terms of first proviso to regulation 118(1)</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '68%', padding: '0px 10px 10px 10px' }}> Mention amount (Rs.)</ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '1%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%', fontWeight: "bold" }}>2.5</div>
                                        <div className='align-items-center' style={{ padding: '0px 10px 10px 10px', fontWeight: "bold" }}>Dates relevant to purchase (buy-back) of shares</div>
                                    </div>
                                    <Wrapper className="table-responsive mt-0" >
                                        <table
                                            className="table table-bordered"
                                            style={{ fontFamily: "Montserrat rev=1 !important" }}
                                        >
                                            <thead>
                                                <th style={{ verticalAlign: 'sub', textAlign: "center", border: '1px solid #121212', textAlign: "center" }}>
                                                    Activity
                                                </th>
                                                <th style={{ verticalAlign: 'sub', textAlign: "center", border: '1px solid #121212', textAlign: "center" }}>
                                                    DD-MM-YYYY
                                                </th>

                                            </thead>

                                            <tbody>
                                                <tr>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}>(i) Last purchase before present purchase (buy-back)</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}>(ii) Board of directors meeting held on</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}>(iii) Notice for general meeting issued on</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}>(iv) General meeting in which special resolution was passed</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}>(v) Completion of purchase process</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}>(vi) Cancellation of shares</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}></td>
                                                </tr>


                                            </tbody>
                                        </table>
                                    </Wrapper>

                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '1%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%', fontWeight: "bold" }}>2.6</div>
                                        <div className='align-items-center' style={{ padding: '0px 10px 10px 10px', fontWeight: "bold" }}> Dates relevant to Offer and Acceptance</div>
                                    </div>
                                    <Wrapper className="table-responsive mt-0" >
                                        <table
                                            className="table table-bordered"
                                            style={{ fontFamily: "Montserrat rev=1 !important" }}
                                        >


                                            <tbody>
                                                <tr>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}>(i) 1st Offer</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}>DD-MM-YYYY</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}>(ii) Offer Acceptance deadline</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}>DD-MM-YYYY</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}>(iii) 2nd Offer (if any)</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}>DD-MM-YYYY</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}>(iv) Offer Acceptance deadline</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}>DD-MM-YYYY</td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={2} style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}><i>User may add additional rows in case of subsequent offers</i></td>
                                                </tr>



                                            </tbody>
                                        </table>
                                    </Wrapper>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '1%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%', fontWeight: "bold" }}>2.7</div>
                                        <div className='align-items-center' style={{ padding: '0px 10px 10px 10px', fontWeight: "bold" }}> Details of purchase (buy-back) of shares</div>
                                    </div>
                                    <Wrapper className="table-responsive mt-0" >
                                        <table
                                            className="table table-bordered"
                                            style={{ fontFamily: "Montserrat rev=1 !important" }}
                                        >
                                            <tbody>
                                                <tr>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}>(i) Total number of shares tendered/surrendered for purchase (Buy-back)</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}>(ii) Total number of shares accepted for purchase (buy-back)</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}>*Date-wise and shareholders wise breakup</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}><i>As per format given below</i></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </Wrapper>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '1%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%', fontWeight: "bold" }}></div>
                                        <div className='align-items-center' style={{ padding: '0px 10px 10px 10px', fontWeight: "bold" }}>*Date-wise and shareholders wise breakup</div>
                                    </div>
                                    <Wrapper className="table-responsive mt-0" >
                                        <table
                                            className="table table-bordered"
                                            style={{ fontFamily: "Montserrat rev=1 !important" }}
                                        >
                                            <thead>
                                                <th style={{ verticalAlign: 'sub', border: '1px solid #121212', textAlign: "center" }}>
                                                    <div>
                                                        <div>Date(s) of purchase/ Buy-back of shares</div>
                                                        <div>DD-MM-YYYY</div>
                                                    </div>
                                                </th>
                                                <th style={{ verticalAlign: 'sub', border: '1px solid #121212', textAlign: "center" }}>
                                                    <div>
                                                        Name of shareholder in full
                                                    </div>
                                                </th>
                                                <th style={{ verticalAlign: 'sub', border: '1px solid #121212', textAlign: "center" }}>
                                                    <div>
                                                        Number of shares purchased
                                                    </div>
                                                </th>
                                                <th style={{ verticalAlign: 'sub', border: '1px solid #121212', textAlign: "center" }}>
                                                    <div>
                                                        Class of shares purchased (Class A, Class B, Preferred: Participatory: Redeemable at company's option, Preferred: Participatory: Redeemable at Shareholder's Option, Preferred : Non-Participatory: Non-Redeemable, Preferred: Non-Participatory: Non-Redeemable Preferred)
                                                    </div>
                                                </th>
                                                <th style={{ verticalAlign: 'sub', border: '1px solid #121212', textAlign: "center" }}>
                                                    <div>
                                                        Purchase Price/share
                                                    </div>
                                                </th>
                                                <th style={{ verticalAlign: 'sub', border: '1px solid #121212', textAlign: "center" }}>
                                                    <div>
                                                        Number of shares held by shareholder, if any after buy-back of shares
                                                    </div>
                                                </th>
                                                <th style={{ verticalAlign: 'sub', border: '1px solid #121212', textAlign: "center" }}>
                                                    <div>
                                                        CNIC No./NICOP/Passport No. of member / Registration Number, if any (in case of each other than natural person)
                                                    </div>
                                                    <div>
                                                        <i>Please enter CNIC No. without (-)</i>
                                                    </div>
                                                </th>
                                                <th style={{ verticalAlign: 'sub', border: '1px solid #121212', textAlign: "center" }}>
                                                    <div>
                                                        Date of payment
                                                    </div>
                                                </th>
                                                <th style={{ verticalAlign: 'sub', border: '1px solid #121212', textAlign: "center" }}>
                                                    <div>
                                                        Amount paid (Rs.)
                                                    </div>
                                                </th>
                                                <th style={{ verticalAlign: 'sub', border: '1px solid #121212', textAlign: "center" }}>
                                                    <div>
                                                        Mode of payment
                                                    </div>
                                                </th>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', fontWeight: "bold" }}>1</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', fontWeight: "bold" }}>2</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', fontWeight: "bold" }}>3</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', fontWeight: "bold" }}>4</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', fontWeight: "bold" }}>5</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', fontWeight: "bold" }}>6</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', fontWeight: "bold" }}>7</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', fontWeight: "bold" }}>8</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', fontWeight: "bold" }}>9</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', fontWeight: "bold" }}>10</td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={10} style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', fontWeight: "bold" }}>Ordinary Shares:</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', fontWeight: "bold" }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', fontWeight: "bold" }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', fontWeight: "bold" }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', fontWeight: "bold" }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', fontWeight: "bold" }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', fontWeight: "bold" }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', fontWeight: "bold" }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', fontWeight: "bold" }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', fontWeight: "bold" }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', fontWeight: "bold" }}></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', fontWeight: "bold" }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', fontWeight: "bold" }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', fontWeight: "bold" }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', fontWeight: "bold" }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', fontWeight: "bold" }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', fontWeight: "bold" }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', fontWeight: "bold" }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', fontWeight: "bold" }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', fontWeight: "bold" }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', fontWeight: "bold" }}></td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={10} style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', fontWeight: "bold" }}>Preference Shares:</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', fontWeight: "bold" }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', fontWeight: "bold" }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', fontWeight: "bold" }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', fontWeight: "bold" }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', fontWeight: "bold" }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', fontWeight: "bold" }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', fontWeight: "bold" }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', fontWeight: "bold" }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', fontWeight: "bold" }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', fontWeight: "bold" }}></td>
                                                </tr>

                                            </tbody>
                                        </table>
                                    </Wrapper>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '1%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%', fontWeight: "bold" }}>2.8</div>
                                        <div className='align-items-center' style={{ padding: '0px 10px 10px 10px', fontWeight: "bold" }}>Names of shareholders and their shareholding after completion of process of buy-back:</div>
                                    </div>


                                    <Wrapper className="table-responsive mt-0" >
                                        <table
                                            className="table table-bordered"
                                            style={{ fontFamily: "Montserrat rev=1 !important" }}
                                        >
                                            <thead>
                                                <th style={{ verticalAlign: 'sub', border: '1px solid #121212', textAlign: "center" }}>
                                                    S#
                                                </th>
                                                <th style={{ verticalAlign: 'sub', border: '1px solid #121212', textAlign: "center" }}>
                                                    Folio #
                                                </th>
                                                <th style={{ verticalAlign: 'sub', border: '1px solid #121212', textAlign: "center" }}>
                                                    Name
                                                </th>
                                                <th style={{ verticalAlign: 'sub', border: '1px solid #121212', textAlign: "center" }}>
                                                    Address
                                                </th>
                                                <th style={{ verticalAlign: 'sub', border: '1px solid #121212', textAlign: "center" }}>
                                                    CNIC/Passport No./CUIN No.
                                                </th>
                                                <th style={{ verticalAlign: 'sub', border: '1px solid #121212', textAlign: "center" }}>
                                                    Nationality
                                                </th>
                                                <th style={{ verticalAlign: 'sub', border: '1px solid #121212', textAlign: "center" }}>
                                                    No. of shares held
                                                </th>

                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}>1</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}>2</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}>3</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}>4</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}>5</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}>6</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}>7</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </Wrapper>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>3.1</div>
                                        <div className='align-items-center' style={{ padding: '5px 10px 0px 10px', fontWeight: "bold" }}>Declaration</div>
                                    </div>
                                    <div className='d-flex'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '1%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ padding: ' 0px 10px 10px 10px' }}>I do hereby solemnly, and sincerely declare that the information provided in the form is:</div>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '1%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '0%' }}></div>
                                        <div className='align-items-start d-flex' style={{ padding: ' 5px 10px', gap: "12px" }}><div>
                                            (i)</div>
                                            <div style={{ marginLeft: '14px' }}>true and correct to the best of my knowledge, in consonance with the record as maintained by the Company and nothing has been concealed; and </div>
                                        </div>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '1%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '1%' }}></div>
                                        <div className='align-items-start d-flex' style={{ padding: ' 5px 10px', gap: "12px" }}><div>
                                            (ii)</div>
                                            <div style={{ marginLeft: '11px' }}>hereby reported after complying with and fulfilling all requirements under the relevant provisions of law, rules, regulations, directives, circulars and notifications whichever is applicable.</div>
                                        </div>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>3.2</div>
                                        <div className='align-items-center' style={{ width: '28%', padding: '0px 10px 10px 10px' }}>  Name of Director/Company Secretary</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '32.5%', padding: ' 5px 10px', }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', borderLeft: "none", width: '32.5%', padding: ' 5px 10px', }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>6.3</div>
                                        <div className='align-items-center' style={{ width: '28%', padding: '0px 10px 10px 10px' }}>Signatures </div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '65%', padding: ' 5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>6.4</div>
                                        <div className='align-items-center' style={{ width: '40%', padding: ' 0px 10px 10px 10px' }}> Registration No of Authorized Intermediary, if applicable</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '53%', padding: ' 5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ width: '55%', padding: ' 5px 10px' }}> </div>
                                        <div className='align-items-center text-center' style={{ width: '8%', padding: ' 5px' }}> Day</div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center text-center' style={{ width: '8%', padding: ' 5px' }}> Month</div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center text-center' style={{ width: '16%', padding: ' 5px' }}>Year </div>
                                        <div></div>
                                    </div>
                                    <div className='d-flex '>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>3.5</div>
                                        <div className='align-items-center' style={{ width: '55%', padding: ' 0px 10px 10px 10px' }}>Date</div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ border: '1px solid #121212', width: '4%', padding: ' 5px 10px' }}> </div>
                                        <div className='align-items-center' style={{ paddingLeft: '8px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ border: '1px solid #121212', width: '4%', padding: ' 5px 10px', borderRight: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ paddingLeft: '8px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', border: '1px solid #121212', }}> </div>
                                    </div>
                                    <div className='d-flex my-2' style={{ fontWeight: "bold" }}>
                                        Encl:
                                    </div>
                                    <div style={{ marginLeft: "1rem" }} className='d-flex align-items-baseline'>
                                        1- Resolution(s) of Board of Directors recommending the purchase
                                    </div>
                                    <div style={{ marginLeft: "1rem" }} className='d-flex align-items-baseline'>
                                        2- Notice of meeting along with relevant attachments
                                    </div>
                                    <div style={{ marginLeft: "1rem" }} className='d-flex align-items-baseline'>
                                        3- Copy of special resolution, if not already submitted separately.
                                    </div>
                                    <div style={{ marginLeft: "1rem" }} className='d-flex align-items-baseline'>
                                        4- Statement of compliance by all Directors/CEO as per Appendix-1
                                    </div>


                                </div>
                            </div>
                        </div>
                    </PDFExport>
                    : <PDFExport
                        paperSize="A4"
                        margin="1cm"
                        scale={0.6}
                        fileName={`Form 27`}
                        pageTemplate={PageTemplate}
                        ref={pdfExportComponent}
                    >
                       <div style={{ fontFamily: `${fontFamilyForStaticData}`, fontSize: `${fontSizeForStaticData}`, fontWeight: 500 }}>
                            <FormName>
                                Form-27
                            </FormName>
                            <div className='row'>
                                <div className='col-md-12' >
                                    <HeadingWrapper className='d-flex justify-content-center'>
                                        THE COMPANIES ACT, 2017
                                    </HeadingWrapper>

                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <HeadingWrapper className='d-flex justify-content-center'>
                                        THE COMPANIES REGULATIONS, 2024
                                    </HeadingWrapper>

                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <div style={{ fontSize: '15px', fontWeight: 'bold' }} className='d-flex justify-content-center'>
                                        [Section 88 and Regulation 121(1)(f)]
                                    </div>
                                </div>
                            </div>

                            <div className='row mt-4'>
                                <div className='col-md-12'>
                                    <HeadingWrapper className='text-center d-flex justify-content-center'>
                                        FINAL RETURN FOR BUY-BACK OF SHARES OF UNLISTED COMPANIES
                                    </HeadingWrapper>
                                </div>
                            </div>
                            <PartsWrapper className='mt-4 mb-3'>
                                PART-I
                            </PartsWrapper>
                            <div className='row'>
                                <div className='col-md-12' style={{ padding: '25px 50px', wordWrap: 'break-word' }}>
                                    <div> <i>(Please complete in bold capital letters)</i></div>


                                    <div className='d-flex' >
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '1%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>1.1</div>
                                        <div className='align-items-center' style={{ width: '28%', padding: '0px 10px 10px 10px' }}>CUIN (Registration Number)  </div>
                                        <div className='align-items-center  d-flex'  >{
                                            '1234 552'.split("")?.map((item, idx) => {
                                                if (item == ' ') return
                                                return <div style={{ minWidth: '30px', minHeight: '34px', border: '1px solid #121212', borderLeft: idx != 0 ? "none" : "1px solid #121212", padding: '6px 9px' }}></div>
                                            })
                                        }
                                        </div>
                                    </div>



                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '1%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>1.2</div>
                                        <div className='align-items-center' style={{ width: '29%', padding: '0px 10px 10px 10px' }}>Name of the Company  </div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '68%', padding: '0px 10px 10px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                    </div>




                                    <div className='d-flex  mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>1.3</div>
                                        <div className='align-items-center' style={{ width: '20.20%', padding: '0px 10px 10px 10px' }}>Fee payment Details*</div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '5%' }}>1.3.1</div>
                                        <div className='align-items-center' style={{ width: '13.20%', paddingLeft: '20px' }}>Challan No.</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '17.20%', paddingLeft: '20px', minHeight: '34px' }}></ScrollWrapper>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '5%' }}>1.3.2</div>
                                        <div className='align-items-center' style={{ width: '13.20%', paddingLeft: '20px' }}>Amount</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '23.20%', paddingLeft: '20px', minHeight: '34px' }}></ScrollWrapper>
                                    </div>
                                    <PartsWrapper className='mt-4 mb-3'>
                                        PART-II
                                    </PartsWrapper>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '1%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%', fontWeight: "bold" }}>2.</div>
                                        <div className='align-items-center' style={{ padding: '0px 10px 10px 10px', fontWeight: "bold" }}>Share Capital</div>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '1%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>2.1</div>
                                        <div className='align-items-center' style={{ width: '29%', padding: '0px 10px 10px 10px' }}>Paid up capital (in Rupees before buy back)</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '68%', padding: '0px 10px 10px 10px' }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '1%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>2.2</div>
                                        <div className='align-items-center' style={{ width: '29%', padding: '0px 10px 10px 10px' }}>Number of shares purchased</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '68%', padding: '0px 10px 10px 10px' }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '1%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>2.3</div>
                                        <div className='align-items-center' style={{ width: '29%', padding: '0px 10px 10px 10px' }}>Paid up Capital (in Rupees after buy back)</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '68%', padding: '0px 10px 10px 10px' }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '1%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>2.4</div>
                                        <div className='align-items-center' style={{ padding: '0px 10px 10px 10px', fontWeight: "bold" }}> Source of funds utilized</div>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '1%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '4%' }}></div>
                                        <div className='align-items-center' style={{ width: '32%', padding: '0px 10px 10px 10px' }}>(i) Distributable profit, and / or</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '68%', padding: '0px 10px 10px 10px' }}> Mention amount (Rs.)</ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '1%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '4%' }}></div>
                                        <div className='align-items-center' style={{ width: '32%', padding: '0px 10px 10px 10px' }}>(ii) Reserves, and/or</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '68%', padding: '0px 10px 10px 10px' }}> Mention amount (Rs.)</ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '1%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '4%' }}></div>
                                        <div className='align-items-center' style={{ width: '32%', padding: '0px 10px 10px 10px' }}>(iii) other resources (in case of startups in terms of first proviso to regulation 118(1)</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '68%', padding: '0px 10px 10px 10px' }}> Mention amount (Rs.)</ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '1%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%', fontWeight: "bold" }}>2.5</div>
                                        <div className='align-items-center' style={{ padding: '0px 10px 10px 10px', fontWeight: "bold" }}>Dates relevant to purchase (buy-back) of shares</div>
                                    </div>
                                    <Wrapper className="table-responsive mt-0" >
                                        <table
                                            className="table table-bordered"
                                            style={{ fontFamily: "Montserrat rev=1 !important" }}
                                        >
                                            <thead>
                                                <th style={{ verticalAlign: 'sub', textAlign: "center", border: '1px solid #121212', textAlign: "center" }}>
                                                    Activity
                                                </th>
                                                <th style={{ verticalAlign: 'sub', textAlign: "center", border: '1px solid #121212', textAlign: "center" }}>
                                                    DD-MM-YYYY
                                                </th>

                                            </thead>

                                            <tbody>
                                                <tr>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}>(i) Last purchase before present purchase (buy-back)</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}>(ii) Board of directors meeting held on</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}>(iii) Notice for general meeting issued on</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}>(iv) General meeting in which special resolution was passed</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}>(v) Completion of purchase process</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}>(vi) Cancellation of shares</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}></td>
                                                </tr>


                                            </tbody>
                                        </table>
                                    </Wrapper>

                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '1%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%', fontWeight: "bold" }}>2.6</div>
                                        <div className='align-items-center' style={{ padding: '0px 10px 10px 10px', fontWeight: "bold" }}> Dates relevant to Offer and Acceptance</div>
                                    </div>
                                    <Wrapper className="table-responsive mt-0" >
                                        <table
                                            className="table table-bordered"
                                            style={{ fontFamily: "Montserrat rev=1 !important" }}
                                        >


                                            <tbody>
                                                <tr>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}>(i) 1st Offer</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}>DD-MM-YYYY</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}>(ii) Offer Acceptance deadline</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}>DD-MM-YYYY</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}>(iii) 2nd Offer (if any)</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}>DD-MM-YYYY</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}>(iv) Offer Acceptance deadline</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}>DD-MM-YYYY</td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={2} style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}><i>User may add additional rows in case of subsequent offers</i></td>
                                                </tr>



                                            </tbody>
                                        </table>
                                    </Wrapper>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '1%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%', fontWeight: "bold" }}>2.7</div>
                                        <div className='align-items-center' style={{ padding: '0px 10px 10px 10px', fontWeight: "bold" }}> Details of purchase (buy-back) of shares</div>
                                    </div>
                                    <Wrapper className="table-responsive mt-0" >
                                        <table
                                            className="table table-bordered"
                                            style={{ fontFamily: "Montserrat rev=1 !important" }}
                                        >
                                            <tbody>
                                                <tr>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}>(i) Total number of shares tendered/surrendered for purchase (Buy-back)</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}>(ii) Total number of shares accepted for purchase (buy-back)</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}>*Date-wise and shareholders wise breakup</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}><i>As per format given below</i></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </Wrapper>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '1%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%', fontWeight: "bold" }}></div>
                                        <div className='align-items-center' style={{ padding: '0px 10px 10px 10px', fontWeight: "bold" }}>*Date-wise and shareholders wise breakup</div>
                                    </div>
                                    <Wrapper className="table-responsive mt-0" >
                                        <table
                                            className="table table-bordered"
                                            style={{ fontFamily: "Montserrat rev=1 !important" }}
                                        >
                                            <thead>
                                                <th style={{ verticalAlign: 'sub', border: '1px solid #121212', textAlign: "center" }}>
                                                    <div>
                                                        <div>Date(s) of purchase/ Buy-back of shares</div>
                                                        <div>DD-MM-YYYY</div>
                                                    </div>
                                                </th>
                                                <th style={{ verticalAlign: 'sub', border: '1px solid #121212', textAlign: "center" }}>
                                                    <div>
                                                        Name of shareholder in full
                                                    </div>
                                                </th>
                                                <th style={{ verticalAlign: 'sub', border: '1px solid #121212', textAlign: "center" }}>
                                                    <div>
                                                        Number of shares purchased
                                                    </div>
                                                </th>
                                                <th style={{ verticalAlign: 'sub', border: '1px solid #121212', textAlign: "center" }}>
                                                    <div>
                                                        Class of shares purchased (Class A, Class B, Preferred: Participatory: Redeemable at company's option, Preferred: Participatory: Redeemable at Shareholder's Option, Preferred : Non-Participatory: Non-Redeemable, Preferred: Non-Participatory: Non-Redeemable Preferred)
                                                    </div>
                                                </th>
                                                <th style={{ verticalAlign: 'sub', border: '1px solid #121212', textAlign: "center" }}>
                                                    <div>
                                                        Purchase Price/share
                                                    </div>
                                                </th>
                                                <th style={{ verticalAlign: 'sub', border: '1px solid #121212', textAlign: "center" }}>
                                                    <div>
                                                        Number of shares held by shareholder, if any after buy-back of shares
                                                    </div>
                                                </th>
                                                <th style={{ verticalAlign: 'sub', border: '1px solid #121212', textAlign: "center" }}>
                                                    <div>
                                                        CNIC No./NICOP/Passport No. of member / Registration Number, if any (in case of each other than natural person)
                                                    </div>
                                                    <div>
                                                        <i>Please enter CNIC No. without (-)</i>
                                                    </div>
                                                </th>
                                                <th style={{ verticalAlign: 'sub', border: '1px solid #121212', textAlign: "center" }}>
                                                    <div>
                                                        Date of payment
                                                    </div>
                                                </th>
                                                <th style={{ verticalAlign: 'sub', border: '1px solid #121212', textAlign: "center" }}>
                                                    <div>
                                                        Amount paid (Rs.)
                                                    </div>
                                                </th>
                                                <th style={{ verticalAlign: 'sub', border: '1px solid #121212', textAlign: "center" }}>
                                                    <div>
                                                        Mode of payment
                                                    </div>
                                                </th>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', fontWeight: "bold" }}>1</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', fontWeight: "bold" }}>2</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', fontWeight: "bold" }}>3</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', fontWeight: "bold" }}>4</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', fontWeight: "bold" }}>5</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', fontWeight: "bold" }}>6</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', fontWeight: "bold" }}>7</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', fontWeight: "bold" }}>8</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', fontWeight: "bold" }}>9</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', fontWeight: "bold" }}>10</td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={10} style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', fontWeight: "bold" }}>Ordinary Shares:</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', fontWeight: "bold" }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', fontWeight: "bold" }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', fontWeight: "bold" }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', fontWeight: "bold" }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', fontWeight: "bold" }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', fontWeight: "bold" }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', fontWeight: "bold" }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', fontWeight: "bold" }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', fontWeight: "bold" }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', fontWeight: "bold" }}></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', fontWeight: "bold" }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', fontWeight: "bold" }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', fontWeight: "bold" }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', fontWeight: "bold" }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', fontWeight: "bold" }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', fontWeight: "bold" }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', fontWeight: "bold" }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', fontWeight: "bold" }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', fontWeight: "bold" }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', fontWeight: "bold" }}></td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={10} style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', fontWeight: "bold" }}>Preference Shares:</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', fontWeight: "bold" }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', fontWeight: "bold" }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', fontWeight: "bold" }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', fontWeight: "bold" }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', fontWeight: "bold" }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', fontWeight: "bold" }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', fontWeight: "bold" }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', fontWeight: "bold" }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', fontWeight: "bold" }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', fontWeight: "bold" }}></td>
                                                </tr>

                                            </tbody>
                                        </table>
                                    </Wrapper>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '1%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%', fontWeight: "bold" }}>2.8</div>
                                        <div className='align-items-center' style={{ padding: '0px 10px 10px 10px', fontWeight: "bold" }}>Names of shareholders and their shareholding after completion of process of buy-back:</div>
                                    </div>


                                    <Wrapper className="table-responsive mt-0" >
                                        <table
                                            className="table table-bordered"
                                            style={{ fontFamily: "Montserrat rev=1 !important" }}
                                        >
                                            <thead>
                                                <th style={{ verticalAlign: 'sub', border: '1px solid #121212', textAlign: "center" }}>
                                                    S#
                                                </th>
                                                <th style={{ verticalAlign: 'sub', border: '1px solid #121212', textAlign: "center" }}>
                                                    Folio #
                                                </th>
                                                <th style={{ verticalAlign: 'sub', border: '1px solid #121212', textAlign: "center" }}>
                                                    Name
                                                </th>
                                                <th style={{ verticalAlign: 'sub', border: '1px solid #121212', textAlign: "center" }}>
                                                    Address
                                                </th>
                                                <th style={{ verticalAlign: 'sub', border: '1px solid #121212', textAlign: "center" }}>
                                                    CNIC/Passport No./CUIN No.
                                                </th>
                                                <th style={{ verticalAlign: 'sub', border: '1px solid #121212', textAlign: "center" }}>
                                                    Nationality
                                                </th>
                                                <th style={{ verticalAlign: 'sub', border: '1px solid #121212', textAlign: "center" }}>
                                                    No. of shares held
                                                </th>

                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}>1</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}>2</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}>3</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}>4</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}>5</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}>6</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}>7</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </Wrapper>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>3.1</div>
                                        <div className='align-items-center' style={{ padding: '5px 10px 0px 10px', fontWeight: "bold" }}>Declaration</div>
                                    </div>
                                    <div className='d-flex'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '1%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ padding: ' 0px 10px 10px 10px' }}>I do hereby solemnly, and sincerely declare that the information provided in the form is:</div>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '1%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '0%' }}></div>
                                        <div className='align-items-start d-flex' style={{ padding: ' 5px 10px', gap: "12px" }}><div>
                                            (i)</div>
                                            <div style={{ marginLeft: '14px' }}>true and correct to the best of my knowledge, in consonance with the record as maintained by the Company and nothing has been concealed; and </div>
                                        </div>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '1%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '1%' }}></div>
                                        <div className='align-items-start d-flex' style={{ padding: ' 5px 10px', gap: "12px" }}><div>
                                            (ii)</div>
                                            <div style={{ marginLeft: '11px' }}>hereby reported after complying with and fulfilling all requirements under the relevant provisions of law, rules, regulations, directives, circulars and notifications whichever is applicable.</div>
                                        </div>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>3.2</div>
                                        <div className='align-items-center' style={{ width: '28%', padding: '0px 10px 10px 10px' }}>  Name of Director/Company Secretary</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '32.5%', padding: ' 5px 10px', }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', borderLeft: "none", width: '32.5%', padding: ' 5px 10px', }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>6.3</div>
                                        <div className='align-items-center' style={{ width: '28%', padding: '0px 10px 10px 10px' }}>Signatures </div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '65%', padding: ' 5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>6.4</div>
                                        <div className='align-items-center' style={{ width: '40%', padding: ' 0px 10px 10px 10px' }}> Registration No of Authorized Intermediary, if applicable</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '53%', padding: ' 5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ width: '55%', padding: ' 5px 10px' }}> </div>
                                        <div className='align-items-center text-center' style={{ width: '8%', padding: ' 5px' }}> Day</div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center text-center' style={{ width: '8%', padding: ' 5px' }}> Month</div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center text-center' style={{ width: '16%', padding: ' 5px' }}>Year </div>
                                        <div></div>
                                    </div>
                                    <div className='d-flex '>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>3.5</div>
                                        <div className='align-items-center' style={{ width: '55%', padding: ' 0px 10px 10px 10px' }}>Date</div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ border: '1px solid #121212', width: '4%', padding: ' 5px 10px' }}> </div>
                                        <div className='align-items-center' style={{ paddingLeft: '8px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ border: '1px solid #121212', width: '4%', padding: ' 5px 10px', borderRight: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ paddingLeft: '8px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', border: '1px solid #121212', }}> </div>
                                    </div>
                                    <div className='d-flex my-2' style={{ fontWeight: "bold" }}>
                                        Encl:
                                    </div>
                                    <div style={{ marginLeft: "1rem" }} className='d-flex align-items-baseline'>
                                        1- Resolution(s) of Board of Directors recommending the purchase
                                    </div>
                                    <div style={{ marginLeft: "1rem" }} className='d-flex align-items-baseline'>
                                        2- Notice of meeting along with relevant attachments
                                    </div>
                                    <div style={{ marginLeft: "1rem" }} className='d-flex align-items-baseline'>
                                        3- Copy of special resolution, if not already submitted separately.
                                    </div>
                                    <div style={{ marginLeft: "1rem" }} className='d-flex align-items-baseline'>
                                        4- Statement of compliance by all Directors/CEO as per Appendix-1
                                    </div>


                                </div>
                            </div>
                        </div>
                    </PDFExport>}
            </div>
            <button disabled={loading} className='btn btn-danger' onClick={(e) => {
                // if(viewPdf)
                //   SetViewPDf(true)
                if (pdfExportComponent.current) {
                    pdfExportComponent.current.save();
                }
            }}>Download PDF</button>
        </div>
    )
}
const PartsWrapper = styled.div`
color: #121212;
text-decoration: underline;
justify-content: center;
display: flex;
margin-right: 15px;
font-size: 17px;
font-weight: bold;
`;
const ScrollWrapper = styled.div`
max-height: 50px;
overflow-y: scroll;
overflow-x: hidden;
::-webkit-scrollbar{
    height: 5px;
    width: 3px;
  }
  
  ::-webkit-scrollbar-track{
    background: #F9F9FB;
  }
  ::-webkit-scrollbar-thumb{
    background: #4E515680;
    border-radius: 5px;
  
  }
`;
const HeadingWrapper = styled.div`
font-size: 17px;
font-weight: bold;
`;
const FormName = styled.div`
color: #121212;
text-decoration: underline;
justify-content: end;
display: flex;
margin-right: 15px;
font-size: 17px;
font-weight: bold;
`;
const Wrapper = styled.div`
table.table-bordered{
    border: 1px solid #121212
     border-color: #121212; 
    // border:1px solid blue;
    // margin-top:20px;
  }
// table.table-bordered > thead > tr > th{
//     // border:1px solid blue;
//     border-color: #121212; 
// }
// table.table-bordered > tbody > tr > td{
//     // border:1px solid blue;
//     borderC-color: #121212; 
// }
 tr td {
    // border:1px solid blue;.
    border-color: #121212; 
}

`;
const WrapperDashed = styled.div`

  td {
    
    // border: 1px dashed #dddcdc;
}

`;