import React, { useState, useEffect } from 'react'
import { PDFExport } from "@progress/kendo-react-pdf";
import PageTemplate from "./page-template";
import {
    getCompanies,
} from "../../../store/services/company.service";
import styled from 'styled-components';
import { TiTick } from "react-icons/ti";
import { numberWithCommas } from 'utilities/utilityFunctions';
export const StatutoryForm10 = ({ data, formTemplate = false }) => {
    const pdfExportComponent = React.useRef(null);
    const [CompanyData, setCompanyData] = useState(null)
    const [loading, setLoading] = useState(false);
    const [fontFamilyForStaticData, setFontFamilyForStaticData] = useState('Montserrat, sans-serif')
    const [fontSizeForStaticData, setFontSizeForStaticData] = useState('14px')
    const [fontfamilyforDynimicData, setFontFamilyForDynmicData] = useState('Segoe Print')
    const [fontColor, setFontColor] = useState('')
    const baseEmail = sessionStorage.getItem("email") || "";
    const [governance, setGovernance] = useState({})
    const getAllCompanies = async () => {
        setLoading(true)
        try {
            const response = await getCompanies(baseEmail);
            if (response.status === 200) {
                response.data.data = response.data.data.filter((item) => item?.active === 'Y')
                const parents = response.data.data;
                const company = parents?.find(ite => ite?.code == data?.company_code)
                let Allperson = company.governance && company.governance != "" ? JSON.parse(company.governance) : []
                let sec = Allperson.find(x => x.role == "Company Secretary" && x?.active?.toLowerCase() != 'n')
                let ceo = Allperson.find(x => x.role == "CEO" && x?.active?.toLowerCase() != 'n')


                if (sec) {
                    setGovernance(sec)
                } else if (ceo) {
                    setGovernance(ceo)
                }
                setCompanyData(company)
                setTimeout(() => {
                    setLoading(false)
                }, 1000);
            }
        } catch (error) {
            setLoading(false)
        }
    };
    useEffect(() => {
        getAllCompanies()
    }, [])
    return (
        <div>
            <div
                style={{ fontFamily: "sans-serif " }}
            >

                {formTemplate ?
                    <PDFExport
                        paperSize="A4"
                        margin="1cm"
                        scale={0.6}
                        fileName={`Statutory Form 10`}
                        pageTemplate={PageTemplate}
                        ref={pdfExportComponent}
                    >
                        <div style={{ fontFamily: `${fontFamilyForStaticData}`, fontSize: `${fontSizeForStaticData}`, fontWeight: 500 }}>
                            <FormName>
                                Form-10
                            </FormName>
                            <div className='row'>
                                <div className='col-md-12' >
                                    <HeadingWrapper className='d-flex justify-content-center'>
                                        THE COMPANIES ACT, 2017
                                    </HeadingWrapper>

                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <HeadingWrapper className='d-flex justify-content-center'>
                                        THE COMPANIES REGULATIONS, 2024
                                    </HeadingWrapper>

                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <div style={{ fontSize: '15px', fontWeight: 'bold' }} className='d-flex justify-content-center'>
                                        [Sections 100, 106, 109 & 448 and Regulations 45 & 30]
                                    </div>
                                </div>
                            </div>

                            <div className='row mt-4'>
                                <div className='col-md-12'>
                                    <HeadingWrapper className='text-center d-flex justify-content-center'>
                                        PARTICULARS OF MORTGAGE, CHARGE OR PLEDGE ETC. OR ANY MODIFICATION THEREIN OR SATISFACTION THEREOF OR PARTICULARS OF MORTGAGE OR CHARGE SUBJECT TO WHICH PROPERTY HAS BEEN ACQUIRED
                                    </HeadingWrapper>
                                </div>
                            </div>
                            <PartsWrapper className='mt-4 mb-3'>
                                PART-I
                            </PartsWrapper>
                            <div className='row'>
                                <div className='col-md-12' style={{ padding: '25px 50px', wordWrap: 'break-word' }}>
                                    <div> <i>(Please complete in bold capital letters)</i></div>


                                    <div className='d-flex' >
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>1.1</div>
                                        <div className='align-items-center' style={{ width: '28%', padding: '0px 10px 10px 10px' }}>CUIN (Registration Number)  </div>
                                        <div className='align-items-center  d-flex'  >{
                                            '1234 552'.split("")?.map((item, idx) => {
                                                if (item == ' ') return
                                                return <div style={{ minWidth: '30px', minHeight: '34px', border: '1px solid #121212', borderLeft: idx != 0 ? "none" : "1px solid #121212", padding: '6px 9px' }}></div>
                                            })
                                        }
                                        </div>
                                    </div>



                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>1.2</div>
                                        <div className='align-items-center' style={{ width: '22%', padding: '0px 10px 10px 10px' }}>Name of the Company  </div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '71%', padding: '0px 10px 10px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                    </div>




                                    <div className='d-flex  mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>1.3</div>
                                        <div className='align-items-center' style={{ width: '20.20%', padding: '0px 10px 10px 10px' }}>Filing Fee payment Details*</div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '5%' }}>1.3.1</div>
                                        <div className='align-items-center' style={{ width: '13.20%', paddingLeft: '20px' }}>Challan No.</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '17.20%', paddingLeft: '20px', minHeight: '34px' }}></ScrollWrapper>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '5%' }}>1.3.2</div>
                                        <div className='align-items-center' style={{ width: '13.20%', paddingLeft: '20px' }}>Amount</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '19.20%', paddingLeft: '20px', minHeight: '34px' }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '85%', borderBottom: "none", paddingLeft: '20px', minHeight: '70px', fontWeight: "bold" }}>1.4 Particulars of —</ScrollWrapper>
                                        <ScrollWrapper className='align-items-center text-center' style={{ border: '1px solid #121212', borderLeft: "none", borderBottom: "none", width: '15%', padding: '10px', minHeight: '70px' }}>Please tick the relevant box</ScrollWrapper>
                                    </div>
                                    <div className='d-flex'>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '21%', paddingLeft: '20px', borderBottom: "none", minHeight: '34px', fontWeight: "bold" }}>Part-II</ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '64%', borderLeft: "none", borderBottom: "none", paddingLeft: '20px', minHeight: '34px' }}> Registration of Mortgage, charge, pledge etc., [section 100]</ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '15%', borderLeft: "none", borderBottom: "none", paddingLeft: '20px', minHeight: '34px' }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex'>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '21%', paddingLeft: '20px', minHeight: '34px', fontWeight: "bold" }}>Part-III</ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '64%', borderLeft: "none", paddingLeft: '20px', minHeight: '34px' }}> Modification of mortgage, charge, pledge, etc. [section 106]</ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '15%', borderLeft: "none", paddingLeft: '20px', minHeight: '34px' }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex'>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '21%', paddingLeft: '20px', minHeight: '34px', borderTop: "none", fontWeight: "bold" }}>Part-IV</ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '64%', borderLeft: "none", paddingLeft: '20px', borderTop: "none", minHeight: '34px' }}> Memorandum of complete satisfaction of mortgage, charge, pledge, etc.[section 109]</ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '15%', borderLeft: "none", paddingLeft: '20px', borderTop: "none", minHeight: '34px' }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex'>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '21%', paddingLeft: '20px', minHeight: '34px', borderTop: "none", fontWeight: "bold" }}>Part-V</ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '64%', borderLeft: "none", paddingLeft: '20px', borderTop: "none", minHeight: '34px' }}> Mortgage or charge subject to which property has been acquired [section 100(4)]</ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '15%', borderLeft: "none", paddingLeft: '20px', borderTop: "none", minHeight: '34px' }}></ScrollWrapper>
                                    </div>
                                    <div style={{ fontWeight: "bold" }} className='d-flex'>
                                        <i>Note:- Only one item can be selected for reporting at one time. Remaining items shall become disable after
                                            selection of any item.</i>
                                    </div>

                                    <PartsWrapper className='mt-4 mb-3'>
                                        PART-II
                                    </PartsWrapper>
                                    <div className='row mt-4'>
                                        <div className='col-md-12'>
                                            <HeadingWrapper className='d-flex text-center justify-content-center'>
                                                (PARTICULARS OF MORTGAGES, CHARGES OR PLEDGE ETC.)
                                            </HeadingWrapper>
                                        </div>
                                    </div>

                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%', }}></div>
                                        <div className='align-items-center' style={{ width: '51%', padding: ' 5px 10px', }}> </div>
                                        <div className='align-items-center text-center' style={{ width: '10%', padding: ' 5px', }}> dd</div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%', }}></div>
                                        <div className='align-items-center text-center' style={{ width: '10%', padding: ' 5px', }}> mm</div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%', }}></div>
                                        <div className='align-items-center text-center' style={{ width: '16%', padding: ' 5px 10px' }}>yyyy</div>
                                        <div></div>
                                    </div>
                                    <div className='d-flex '>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%', }}>2.1</div>
                                        <div className='align-items-center' style={{ width: '51%', padding: ' 0px 10px 10px 10px', }}> Date of instrument creating mortgage, Charge or pledge</div>
                                        <div className='align-items-center' style={{ width: '5%', padding: ' 5px 10px', border: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '5%', padding: ' 5px 10px', border: '1px solid #121212', borderLeft: 'none' }}> </div>
                                        <div className='align-items-center text-center' style={{ paddingLeft: '2px', width: '3%', }}></div>
                                        <div className='align-items-center' style={{ width: '5%', padding: ' 5px 10px', border: '1px solid #121212', }}> </div>
                                        <div className='align-items-center' style={{ width: '5%', padding: ' 5px 10px', border: '1px solid #121212', borderLeft: 'none' }}> </div>
                                        <div className='align-items-center text-center' style={{ paddingLeft: '2px', width: '3%', }}></div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', border: '1px solid #121212', }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', border: '1px solid #121212', borderLeft: 'none' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', border: '1px solid #121212', borderLeft: 'none' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', border: '1px solid #121212', borderLeft: 'none', borderRight: '1px solid #121212' }}> </div>
                                    </div>

                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>2.2</div>
                                        <div className='align-items-center' style={{ width: '30%', padding: '0px 10px 10px 10px' }}> Name of instrument (if any) creating or evidencing the mortgage or charge or pledge or other interest (to be specified).</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212',minHeight:"67px", width: '63%', padding: '0px 10px 10px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>2.3</div>
                                        <div className='align-items-center' style={{ width: '30%', padding: '0px 10px 10px 10px' }}> Amount of mortgage or charge, pledge etc.</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '63%', padding: '0px 10px 10px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>2.4</div>
                                        <div className='align-items-center' style={{ width: '30%', padding: '0px 10px 10px 10px' }}> Short particulars of the property mortgaged, charge, pledge or other interest</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212',minHeight:"61px", width: '63%', padding: '0px 10px 10px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>2.5</div>
                                        <div className='align-items-center' style={{ width: '30%', padding: '0px 10px 0px 10px' }}> Gist of the terms and conditions and extent and operation of the mortgage, charge, pledge or other interest</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212',minHeight:"66px", width: '63%', padding: '0px 10px 10px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                    </div>
                                    <div style={{marginLeft:"5.3rem"}}>
                                        <i>(Also include description of the nature of the mortgage/charge e.g. equitable, pari-passu, etc.)</i>
                                    </div>
                                    <div className='d-flex mt-3'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>2.6</div>
                                        <div className='align-items-center' style={{ width: '30%', padding: '0px 10px 10px 10px' }}> Name and address of the person(s) entitled to the mortgage, charge, pledge or other interest.</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212',minHeight:"97px", width: '63%', padding: '0px 10px 10px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                    </div>

                                    <PartsWrapper className='mt-4 mb-3'>
                                        PART-III
                                    </PartsWrapper>
                                    <div className='row mt-4'>
                                        <div className='col-md-12'>
                                            <HeadingWrapper className='d-flex text-center justify-content-center'>
                                                (PARTICULARS OF MODIFICATION OF MORTGAGES, CHARGES OR PLEDGE ETC.)
                                            </HeadingWrapper>
                                        </div>
                                    </div>
                                    <div className='d-flex '>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%', }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%', }}>3.1</div>
                                        <div className='align-items-center' style={{ width: '30%', padding: ' 0px 10px 10px 10px', }}> Date of Instrument creating the original mortgage / charge, pledge etc</div>
                                        <div className='align-items-center' style={{ width: '21%', padding: ' 0px 10px 10px 10px', }}></div>
                                        <div className='d-flex flex-column' style={{ width: '10%',}}>
                                            <div className='align-items-center text-center' style={{ width: '100%', }}>Day</div>
                                            <div className='d-flex'>
                                                <div className='align-items-center' style={{ width: '50%',minHeight:"25px", padding: ' 5px 10px', border: '1px solid #121212' }}> </div>
                                                <div className='align-items-center' style={{ width: '50%',minHeight:"25px", padding: ' 5px 10px', border: '1px solid #121212', borderLeft: 'none' }}> </div>
                                            </div>
                                        </div>
                                        <div className='align-items-center text-center' style={{ paddingLeft: '2px', width: '3%', }}></div>
                                        <div className='d-flex flex-column' style={{ width: '10%',}}>
                                            <div className='align-items-center text-center' style={{ width: '100%', }}>Month</div>
                                            <div className='d-flex'>
                                                <div className='align-items-center' style={{ width: '50%',minHeight:"25px", padding: ' 5px 10px', border: '1px solid #121212' }}> </div>
                                                <div className='align-items-center' style={{ width: '50%',minHeight:"25px", padding: ' 5px 10px', border: '1px solid #121212', borderLeft: 'none' }}> </div>
                                            </div>
                                        </div>
                                        <div className='align-items-center text-center' style={{ paddingLeft: '2px', width: '3%', }}></div>
                                        <div className='d-flex flex-column' style={{ width: '16%',}}>
                                            <div className='align-items-center text-center' style={{ width: '100%', }}>Year</div>
                                            <div className='d-flex'>
                                                <div className='align-items-center' style={{ width: '50%',minHeight:"25px", padding: ' 5px 10px', border: '1px solid #121212' }}> </div>
                                                <div className='align-items-center' style={{ width: '50%',minHeight:"25px", padding: ' 5px 10px', border: '1px solid #121212', borderLeft: 'none' }}> </div>
                                                <div className='align-items-center' style={{ width: '50%',minHeight:"25px", padding: ' 5px 10px', border: '1px solid #121212', borderLeft: 'none' }}> </div>
                                                <div className='align-items-center' style={{ width: '50%',minHeight:"25px", padding: ' 5px 10px', border: '1px solid #121212', borderLeft: 'none' }}> </div>
                                            </div>
                                        </div>
                                      
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>3.2</div>
                                        <div className='align-items-center' style={{ width: '28%', padding: '0px 10px 10px 10px' }}> Brief description of instrument(s)creating the original mortgage, charge, pledge etc.</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '62%', padding: '0px 10px 10px 10px'}}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex '>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%', }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%', }}>3.3</div>
                                        <div className='align-items-center' style={{ width: '30%', padding: ' 0px 10px 10px 10px', }}> Date(s) of modification of the mortgage, charge, pledge etc. prior to present modification</div>
                                        <div className='align-items-center' style={{ width: '21%', padding: ' 0px 10px 10px 10px', }}></div>
                                        <div className='d-flex flex-column' style={{ width: '10%',}}>
                                            <div className='align-items-center text-center' style={{ width: '100%', }}>Day</div>
                                            <div className='d-flex'>
                                                <div className='align-items-center' style={{ width: '50%',minHeight:"25px", padding: ' 5px 10px', border: '1px solid #121212' }}> </div>
                                                <div className='align-items-center' style={{ width: '50%',minHeight:"25px", padding: ' 5px 10px', border: '1px solid #121212', borderLeft: 'none' }}> </div>
                                            </div>
                                        </div>
                                        <div className='align-items-center text-center' style={{ paddingLeft: '2px', width: '3%', }}></div>
                                        <div className='d-flex flex-column' style={{ width: '10%',}}>
                                            <div className='align-items-center text-center' style={{ width: '100%', }}>Month</div>
                                            <div className='d-flex'>
                                                <div className='align-items-center' style={{ width: '50%',minHeight:"25px", padding: ' 5px 10px', border: '1px solid #121212' }}> </div>
                                                <div className='align-items-center' style={{ width: '50%',minHeight:"25px", padding: ' 5px 10px', border: '1px solid #121212', borderLeft: 'none' }}> </div>
                                            </div>
                                        </div>
                                        <div className='align-items-center text-center' style={{ paddingLeft: '2px', width: '3%', }}></div>
                                        <div className='d-flex flex-column' style={{ width: '16%',}}>
                                            <div className='align-items-center text-center' style={{ width: '100%', }}>Year</div>
                                            <div className='d-flex'>
                                                <div className='align-items-center' style={{ width: '50%',minHeight:"25px", padding: ' 5px 10px', border: '1px solid #121212' }}> </div>
                                                <div className='align-items-center' style={{ width: '50%',minHeight:"25px", padding: ' 5px 10px', border: '1px solid #121212', borderLeft: 'none' }}> </div>
                                                <div className='align-items-center' style={{ width: '50%',minHeight:"25px", padding: ' 5px 10px', border: '1px solid #121212', borderLeft: 'none' }}> </div>
                                                <div className='align-items-center' style={{ width: '50%',minHeight:"25px", padding: ' 5px 10px', border: '1px solid #121212', borderLeft: 'none' }}> </div>
                                            </div>
                                        </div>
                                      
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>3.4</div>
                                        <div className='align-items-center' style={{ width: '28%', padding: '0px 10px 10px 10px' }}> Brief description of instrument(s)modifying the mortgage, charge, pledge etc. prior to present modification.</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '62%', padding: '0px 10px 10px 10px'}}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex '>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%', }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%', }}>3.5</div>
                                        <div className='align-items-center' style={{ width: '30%', padding: ' 0px 10px 10px 10px', }}> Date of present modification of the mortgage, charge, pledge etc. </div>
                                        <div className='align-items-center' style={{ width: '21%', padding: ' 0px 10px 10px 10px', }}></div>
                                        <div className='d-flex flex-column' style={{ width: '10%',}}>
                                            <div className='align-items-center text-center' style={{ width: '100%', }}>Day</div>
                                            <div className='d-flex'>
                                                <div className='align-items-center' style={{ width: '50%',minHeight:"25px", padding: ' 5px 10px', border: '1px solid #121212' }}> </div>
                                                <div className='align-items-center' style={{ width: '50%',minHeight:"25px", padding: ' 5px 10px', border: '1px solid #121212', borderLeft: 'none' }}> </div>
                                            </div>
                                        </div>
                                        <div className='align-items-center text-center' style={{ paddingLeft: '2px', width: '3%', }}></div>
                                        <div className='d-flex flex-column' style={{ width: '10%',}}>
                                            <div className='align-items-center text-center' style={{ width: '100%', }}>Month</div>
                                            <div className='d-flex'>
                                                <div className='align-items-center' style={{ width: '50%',minHeight:"25px", padding: ' 5px 10px', border: '1px solid #121212' }}> </div>
                                                <div className='align-items-center' style={{ width: '50%',minHeight:"25px", padding: ' 5px 10px', border: '1px solid #121212', borderLeft: 'none' }}> </div>
                                            </div>
                                        </div>
                                        <div className='align-items-center text-center' style={{ paddingLeft: '2px', width: '3%', }}></div>
                                        <div className='d-flex flex-column' style={{ width: '16%',}}>
                                            <div className='align-items-center text-center' style={{ width: '100%', }}>Year</div>
                                            <div className='d-flex'>
                                                <div className='align-items-center' style={{ width: '50%',minHeight:"25px", padding: ' 5px 10px', border: '1px solid #121212' }}> </div>
                                                <div className='align-items-center' style={{ width: '50%',minHeight:"25px", padding: ' 5px 10px', border: '1px solid #121212', borderLeft: 'none' }}> </div>
                                                <div className='align-items-center' style={{ width: '50%',minHeight:"25px", padding: ' 5px 10px', border: '1px solid #121212', borderLeft: 'none' }}> </div>
                                                <div className='align-items-center' style={{ width: '50%',minHeight:"25px", padding: ' 5px 10px', border: '1px solid #121212', borderLeft: 'none' }}> </div>
                                            </div>
                                        </div>
                                      
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>3.6</div>
                                        <div className='align-items-center' style={{ width: '28%', padding: '0px 10px 10px 10px' }}> Brief description of instrument(s)modifying the present mortgage, charge, pledge etc.</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '62%', padding: '0px 10px 10px 10px'}}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>3.7</div>
                                        <div className='align-items-center' style={{ width: '28%', padding: '0px 10px 10px 10px' }}> Particulars of the present modification specifying the terms or conditions or the extent or operation and the details of the modification.</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '62%', padding: '0px 10px 10px 10px'}}></ScrollWrapper>
                                    </div>

                                    <PartsWrapper className='mt-4 mb-3'>
                                        PART-IV
                                    </PartsWrapper>
                                    <div className='row mt-4'>
                                        <div className='col-md-12'>
                                            <HeadingWrapper className='d-flex text-center justify-content-center'>
                                            (MEMORANDUM OF COMPLETE SATISFACTION OF MORTGAGE, CHARGES OR PLEDGE ETC.)
                                            </HeadingWrapper>
                                        </div>
                                    </div>

                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '5%' }}>4</div>
                                        <div className='align-items-center' style={{  padding: '0px 10px 10px 0px' }}>Particulars of the mortgage/charge satisfaction thereof :</div>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>4.1</div>
                                        <div className='align-items-center' style={{ width: '24%', padding: '0px 10px 10px 10px' }}> Amount of mortgage / pledge / charge etc. (Rs)</div>
                                        <div className='align-items-center' style={{ width: '4%', padding: '0px 10px 10px 10px' }}></div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '62%', padding: '0px 10px 10px 10px'}}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-3'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>4.2</div>
                                        <div className='align-items-center' style={{ width: '30%', padding: ' 0px 10px 10px 10px' }}> Date of registration of mortgage or pledge or charge.</div>
                                        <div className='align-items-center' style={{ width: '23%', padding: ' 0px 10px 10px 10px' }}></div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ border: '1px solid #121212', width: '4%', padding: ' 5px 10px' }}> </div>
                                        <div className='align-items-center' style={{ paddingLeft: '8px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ border: '1px solid #121212', width: '4%', padding: ' 5px 10px', borderRight: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ paddingLeft: '8px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', border: '1px solid #121212', }}> </div>
                                    </div>
                                    <div className='d-flex mt-3'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>4.3</div>
                                        <div className='align-items-center' style={{ width: '30%', padding: ' 0px 10px 10px 10px' }}> Date of last modification, if any of mortgage or pledge or charge</div>
                                        <div className='align-items-center' style={{ width: '23%', padding: ' 0px 10px 10px 10px' }}></div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ border: '1px solid #121212', width: '4%', padding: ' 5px 10px' }}> </div>
                                        <div className='align-items-center' style={{ paddingLeft: '8px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ border: '1px solid #121212', width: '4%', padding: ' 5px 10px', borderRight: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ paddingLeft: '8px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', border: '1px solid #121212', }}> </div>
                                    </div>
                                    <div className='d-flex mt-3'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>4.4</div>
                                        <div className='align-items-center' style={{ width: '30%', padding: ' 0px 10px 10px 10px' }}>Date of satisfaction.</div>
                                        <div className='align-items-center' style={{ width: '23%', padding: ' 0px 10px 10px 10px' }}></div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ border: '1px solid #121212', width: '4%', padding: ' 5px 10px' }}> </div>
                                        <div className='align-items-center' style={{ paddingLeft: '8px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ border: '1px solid #121212', width: '4%', padding: ' 5px 10px', borderRight: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ paddingLeft: '8px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', border: '1px solid #121212', }}> </div>
                                    </div>
                                    <div className='d-flex mt-3'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>4.5</div>
                                        <div className='align-items-center' style={{ width: '37%', padding: '0px 10px 10px 10px' }}> Name(s) and address(es) of the mortgagees(s) / trustee(s) for the redeemable capital / debenture-holders, etc. (give description)</div>
                                        <div className='align-items-center' style={{ width: '15%', padding: '0px 10px 10px 10px' }}></div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '38%', padding: '0px 10px 10px 10px'}}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-3'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>4.6</div>
                                        <div className='align-items-center' style={{ width: '27%', padding: '0px 10px 10px 10px' }}> Brief description of instrument(s) through which mortgage, charge, pledge etc. was created or modified and is now satisfied.</div>
                                        <div className='align-items-center' style={{ width: '15%', padding: '0px 10px 10px 10px' }}></div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212',minHeight:"80px", width: '48%', padding: '0px 10px 10px 10px'}}></ScrollWrapper>
                                    </div>
                                    <PartsWrapper className='mt-4 mb-3'>
                                        PART-V
                                    </PartsWrapper>
                                    <div className='row mt-4'>
                                        <div className='col-md-12'>
                                            <HeadingWrapper className='d-flex text-center justify-content-center'>
                                            (REGISTRATION OF MORTGAGE, CHARGES ETC. SUBJECT TO WHICH PROPERTY HAS BEEN ACQUIRED)
                                            </HeadingWrapper>
                                        </div>
                                    </div>
                                    <div className='d-flex '>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%', }}>5.1</div>
                                        <div className='align-items-center' style={{ width: '51%', padding: ' 0px 10px 10px 10px', }}> Date of Creation.</div>
                                        <div className='d-flex flex-column' style={{ width: '10%',}}>
                                            <div className='align-items-center text-center' style={{ width: '100%', }}>Day</div>
                                            <div className='d-flex'>
                                                <div className='align-items-center' style={{ width: '50%',minHeight:"25px", padding: ' 5px 10px', border: '1px solid #121212' }}> </div>
                                                <div className='align-items-center' style={{ width: '50%',minHeight:"25px", padding: ' 5px 10px', border: '1px solid #121212', borderLeft: 'none' }}> </div>
                                            </div>
                                        </div>
                                        <div className='align-items-center text-center' style={{ paddingLeft: '2px', width: '3%', }}></div>
                                        <div className='d-flex flex-column' style={{ width: '10%',}}>
                                            <div className='align-items-center text-center' style={{ width: '100%', }}>Month</div>
                                            <div className='d-flex'>
                                                <div className='align-items-center' style={{ width: '50%',minHeight:"25px", padding: ' 5px 10px', border: '1px solid #121212' }}> </div>
                                                <div className='align-items-center' style={{ width: '50%',minHeight:"25px", padding: ' 5px 10px', border: '1px solid #121212', borderLeft: 'none' }}> </div>
                                            </div>
                                        </div>
                                        <div className='align-items-center text-center' style={{ paddingLeft: '2px', width: '3%', }}></div>
                                        <div className='d-flex flex-column' style={{ width: '16%',}}>
                                            <div className='align-items-center text-center' style={{ width: '100%', }}>Year</div>
                                            <div className='d-flex'>
                                                <div className='align-items-center' style={{ width: '50%',minHeight:"25px", padding: ' 5px 10px', border: '1px solid #121212' }}> </div>
                                                <div className='align-items-center' style={{ width: '50%',minHeight:"25px", padding: ' 5px 10px', border: '1px solid #121212', borderLeft: 'none' }}> </div>
                                                <div className='align-items-center' style={{ width: '50%',minHeight:"25px", padding: ' 5px 10px', border: '1px solid #121212', borderLeft: 'none' }}> </div>
                                                <div className='align-items-center' style={{ width: '50%',minHeight:"25px", padding: ' 5px 10px', border: '1px solid #121212', borderLeft: 'none' }}> </div>
                                            </div>
                                        </div>
                                      
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>5.2</div>
                                        <div className='align-items-center' style={{ width: '28%', padding: '0px 10px 10px 10px' }}> Description of the instrument(s)creating or evidencing the mortgage, charge, pledge etc</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212',minHeight:"66px", width: '65%', padding: '0px 10px 10px 10px'}}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>5.3</div>
                                        <div className='align-items-center' style={{ width: '28%', padding: '0px 10px 10px 10px' }}> Memorandum or any other instrument regarding mortgage or charge or pledge subject to which property has been acquired.</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212',minHeight:"80px", width: '65%', padding: '0px 10px 10px 10px'}}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%', }}></div>
                                        <div className='align-items-center' style={{ width: '51%', padding: ' 5px 10px', }}> </div>
                                        <div className='align-items-center text-center' style={{ width: '10%', padding: ' 5px', }}>Day</div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%', }}></div>
                                        <div className='align-items-center text-center' style={{ width: '10%', padding: ' 5px', }}>Month</div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%', }}></div>
                                        <div className='align-items-center text-center' style={{ width: '16%', padding: ' 5px 10px' }}>Year</div>
                                        <div></div>
                                    </div>
                                    <div className='d-flex '>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%', }}>5.4</div>
                                        <div className='align-items-center' style={{ width: '51%', padding: ' 0px 10px 10px 10px', }}>Date of acquisition of the property</div>
                                        <div className='align-items-center' style={{ width: '5%', padding: ' 5px 10px', border: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '5%', padding: ' 5px 10px', border: '1px solid #121212', borderLeft: 'none' }}> </div>
                                        <div className='align-items-center text-center' style={{ paddingLeft: '2px', width: '3%', }}></div>
                                        <div className='align-items-center' style={{ width: '5%', padding: ' 5px 10px', border: '1px solid #121212', }}> </div>
                                        <div className='align-items-center' style={{ width: '5%', padding: ' 5px 10px', border: '1px solid #121212', borderLeft: 'none' }}> </div>
                                        <div className='align-items-center text-center' style={{ paddingLeft: '2px', width: '3%', }}></div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', border: '1px solid #121212', }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', border: '1px solid #121212', borderLeft: 'none' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', border: '1px solid #121212', borderLeft: 'none' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', border: '1px solid #121212', borderLeft: 'none', borderRight: '1px solid #121212' }}> </div>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>5.5</div>
                                        <div className='align-items-center' style={{ width: '28%', padding: '0px 10px 10px 10px' }}>Amount owing on security of the mortgage or charge</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '65%', padding: '0px 10px 10px 10px'}}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>5.6</div>
                                        <div className='align-items-center' style={{ width: '28%', padding: '0px 10px 10px 10px' }}> Short particulars of the property acquired subject to mortgage, charge or pledge.</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '65%', padding: '0px 10px 10px 10px'}}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>5.7</div>
                                        <div className='align-items-center' style={{ width: '28%', padding: '0px 10px 10px 10px' }}> Gist of the terms and conditions and extent and operation of the mortgage, charge, pledge or other interest</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '65%', padding: '0px 10px 10px 10px'}}></ScrollWrapper>
                                    </div>
                                    <PartsWrapper className='mt-4 mb-3'>
                                        PART-VI
                                    </PartsWrapper>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '5%' }}>6.1</div>
                                        <div className='align-items-center' style={{ padding: '5px 10px 0px 10px',fontWeight:"bold" }}>Declaration</div>
                                    </div>
                                    <div className='d-flex'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}></div>
                                        <div className='align-items-center' style={{ padding: ' 0px 10px 10px 10px' }}>I do hereby solemnly and sincerely declare that the information provided in the form is:</div>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '0%' }}></div>
                                        <div className='align-items-start d-flex' style={{ padding: ' 5px 10px', gap: "12px" }}><div>
                                            (i)</div>
                                            <div style={{ marginLeft: '14px' }}>true and correct to the best of my knowledge, in consonance with the record as maintained by the Company and nothing has been concealed; and </div>
                                        </div>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '0%' }}></div>
                                        <div className='align-items-start d-flex' style={{ padding: ' 5px 10px', gap: "12px" }}><div>
                                            (ii)</div>
                                            <div style={{ marginLeft: '11px' }}>hereby reported after complying with and fulfilling all requirements under the relevant provisions of law, rules, regulations, directives, circulars and notifications whichever is applicable.</div>
                                        </div>
                                    </div>


                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>6.2</div>
                                        <div className='align-items-center' style={{ width: '28%', padding: '0px 10px 10px 10px' }}> Name of Authorized Officer with designation/ Authorized Intermediary</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '32.5%', padding: ' 5px 10px', }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', borderLeft: "none", width: '32.5%', padding: ' 5px 10px', }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>6.3</div>
                                        <div className='align-items-center' style={{ width: '28%', padding: '0px 10px 10px 10px' }}>Signatures </div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '65%', padding: ' 5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>6.4</div>
                                        <div className='align-items-center' style={{ width: '40%', padding: ' 0px 10px 10px 10px' }}>Registration No of Authorized Intermediary, if applicable</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '53%', padding: ' 5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}></div>
                                        <div className='align-items-center' style={{ width: '55%', padding: ' 5px 10px' }}> </div>
                                        <div className='align-items-center text-center' style={{ width: '8%', padding: ' 5px' }}> Day</div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center text-center' style={{ width: '8%', padding: ' 5px' }}> Month</div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center text-center' style={{ width: '16%', padding: ' 5px' }}>Year </div>
                                        <div></div>
                                    </div>
                                    <div className='d-flex '>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>3.5</div>
                                        <div className='align-items-center' style={{ width: '55%', padding: ' 0px 10px 10px 10px' }}>Date</div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ border: '1px solid #121212', width: '4%', padding: ' 5px 10px' }}> </div>
                                        <div className='align-items-center' style={{ paddingLeft: '8px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ border: '1px solid #121212', width: '4%', padding: ' 5px 10px', borderRight: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ paddingLeft: '8px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', border: '1px solid #121212', }}> </div>
                                    </div>
                                    <div className='d-flex my-2' style={{ fontWeight: "bold" }}>
                                        Enclosure:
                                    </div>
                                    <div className='d-flex align-items-baseline'>
                                        <div style={{ marginLeft: "5px" }}>1.</div><div style={{ marginLeft: '23px' }}>Copy of instrument(s) creating/ modifying the mortgage/charge/pledge, as the case may be. (Applicable in case of creation/ modification of mortgage/ charge/ pledge and registration of mortgage/charges/pledge etc. subject to which property has been acquired)</div>
                                    </div>
                                    <div className='d-flex'>
                                        <div style={{ marginLeft: "5px" }}>2.</div><div style={{ marginLeft: '20px' }}>No objection certificate from the charge holder in case of modification of mortgage/charge/pledge (if applicable). </div>
                                    </div>
                                    <div className='d-flex'>
                                        <div style={{ marginLeft: "5px" }}>3.</div><div style={{ marginLeft: '20px' }}>No objection certificate from the creditor in case of satisfaction of mortgage/charge/pledge (if available)</div>
                                    </div>
                                    <div className='d-flex'>
                                        <div style={{ marginLeft: "5px" }}>4.</div><div style={{ marginLeft: '19px' }}>Affidavit under regulation 45 duly signed by the person who signed this application, verifying that the contents of the application and attached documents are true and correct, attested by an Oath Commissioner (scanned image in case of online filing).</div>
                                    </div>
                                    <div className='d-flex'>
                                        <div style={{ marginLeft: "5px" }}>5.</div><div style={{ marginLeft: '19px' }}>Original challan or other evidence of payment of fee specified in Seventh Schedule of the Act (not applicable in case of online filing)</div>
                                    </div>
                                    

                                    

                                </div>
                            </div>
                        </div>
                    </PDFExport>
                    : <PDFExport
                        paperSize="A4"
                        margin="1cm"
                        scale={0.6}
                        fileName={`Statutory Form 10`}
                        pageTemplate={PageTemplate}
                        ref={pdfExportComponent}
                    >
                        <div style={{ fontFamily: `${fontFamilyForStaticData}`, fontSize: `${fontSizeForStaticData}`, fontWeight: 500 }}>
                            <FormName>
                                Form-10
                            </FormName>
                            <div className='row'>
                                <div className='col-md-12' >
                                    <HeadingWrapper className='d-flex justify-content-center'>
                                        THE COMPANIES ACT, 2017
                                    </HeadingWrapper>

                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <HeadingWrapper className='d-flex justify-content-center'>
                                        THE COMPANIES REGULATIONS, 2024
                                    </HeadingWrapper>

                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <div style={{ fontSize: '15px', fontWeight: 'bold' }} className='d-flex justify-content-center'>
                                        [Sections 100, 106, 109 & 448 and Regulations 45 & 30]
                                    </div>
                                </div>
                            </div>

                            <div className='row mt-4'>
                                <div className='col-md-12'>
                                    <HeadingWrapper className='text-center d-flex justify-content-center'>
                                        PARTICULARS OF MORTGAGE, CHARGE OR PLEDGE ETC. OR ANY MODIFICATION THEREIN OR SATISFACTION THEREOF OR PARTICULARS OF MORTGAGE OR CHARGE SUBJECT TO WHICH PROPERTY HAS BEEN ACQUIRED
                                    </HeadingWrapper>
                                </div>
                            </div>
                            <PartsWrapper className='mt-4 mb-3'>
                                PART-I
                            </PartsWrapper>
                            <div className='row'>
                                <div className='col-md-12' style={{ padding: '25px 50px', wordWrap: 'break-word' }}>
                                    <div> <i>(Please complete in bold capital letters)</i></div>


                                    <div className='d-flex' >
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>1.1</div>
                                        <div className='align-items-center' style={{ width: '28%', padding: '0px 10px 10px 10px' }}>CUIN (Registration Number)  </div>
                                        <div className='align-items-center  d-flex'  >{
                                            '1234 552'.split("")?.map((item, idx) => {
                                                if (item == ' ') return
                                                return <div style={{ minWidth: '30px', minHeight: '34px', border: '1px solid #121212', borderLeft: idx != 0 ? "none" : "1px solid #121212", padding: '6px 9px' }}></div>
                                            })
                                        }
                                        </div>
                                    </div>



                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>1.2</div>
                                        <div className='align-items-center' style={{ width: '22%', padding: '0px 10px 10px 10px' }}>Name of the Company  </div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '71%', padding: '0px 10px 10px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                    </div>




                                    <div className='d-flex  mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>1.3</div>
                                        <div className='align-items-center' style={{ width: '20.20%', padding: '0px 10px 10px 10px' }}>Filing Fee payment Details*</div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '5%' }}>1.3.1</div>
                                        <div className='align-items-center' style={{ width: '13.20%', paddingLeft: '20px' }}>Challan No.</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '17.20%', paddingLeft: '20px', minHeight: '34px' }}></ScrollWrapper>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '5%' }}>1.3.2</div>
                                        <div className='align-items-center' style={{ width: '13.20%', paddingLeft: '20px' }}>Amount</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '19.20%', paddingLeft: '20px', minHeight: '34px' }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '85%', borderBottom: "none", paddingLeft: '20px', minHeight: '70px', fontWeight: "bold" }}>1.4 Particulars of —</ScrollWrapper>
                                        <ScrollWrapper className='align-items-center text-center' style={{ border: '1px solid #121212', borderLeft: "none", borderBottom: "none", width: '15%', padding: '10px', minHeight: '70px' }}>Please tick the relevant box</ScrollWrapper>
                                    </div>
                                    <div className='d-flex'>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '21%', paddingLeft: '20px', borderBottom: "none", minHeight: '34px', fontWeight: "bold" }}>Part-II</ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '64%', borderLeft: "none", borderBottom: "none", paddingLeft: '20px', minHeight: '34px' }}> Registration of Mortgage, charge, pledge etc., [section 100]</ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '15%', borderLeft: "none", borderBottom: "none", paddingLeft: '20px', minHeight: '34px' }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex'>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '21%', paddingLeft: '20px', minHeight: '34px', fontWeight: "bold" }}>Part-III</ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '64%', borderLeft: "none", paddingLeft: '20px', minHeight: '34px' }}> Modification of mortgage, charge, pledge, etc. [section 106]</ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '15%', borderLeft: "none", paddingLeft: '20px', minHeight: '34px' }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex'>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '21%', paddingLeft: '20px', minHeight: '34px', borderTop: "none", fontWeight: "bold" }}>Part-IV</ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '64%', borderLeft: "none", paddingLeft: '20px', borderTop: "none", minHeight: '34px' }}> Memorandum of complete satisfaction of mortgage, charge, pledge, etc.[section 109]</ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '15%', borderLeft: "none", paddingLeft: '20px', borderTop: "none", minHeight: '34px' }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex'>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '21%', paddingLeft: '20px', minHeight: '34px', borderTop: "none", fontWeight: "bold" }}>Part-V</ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '64%', borderLeft: "none", paddingLeft: '20px', borderTop: "none", minHeight: '34px' }}> Mortgage or charge subject to which property has been acquired [section 100(4)]</ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '15%', borderLeft: "none", paddingLeft: '20px', borderTop: "none", minHeight: '34px' }}></ScrollWrapper>
                                    </div>
                                    <div style={{ fontWeight: "bold" }} className='d-flex'>
                                        <i>Note:- Only one item can be selected for reporting at one time. Remaining items shall become disable after
                                            selection of any item.</i>
                                    </div>

                                    <PartsWrapper className='mt-4 mb-3'>
                                        PART-II
                                    </PartsWrapper>
                                    <div className='row mt-4'>
                                        <div className='col-md-12'>
                                            <HeadingWrapper className='d-flex text-center justify-content-center'>
                                                (PARTICULARS OF MORTGAGES, CHARGES OR PLEDGE ETC.)
                                            </HeadingWrapper>
                                        </div>
                                    </div>

                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%', }}></div>
                                        <div className='align-items-center' style={{ width: '51%', padding: ' 5px 10px', }}> </div>
                                        <div className='align-items-center text-center' style={{ width: '10%', padding: ' 5px', }}> dd</div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%', }}></div>
                                        <div className='align-items-center text-center' style={{ width: '10%', padding: ' 5px', }}> mm</div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%', }}></div>
                                        <div className='align-items-center text-center' style={{ width: '16%', padding: ' 5px 10px' }}>yyyy</div>
                                        <div></div>
                                    </div>
                                    <div className='d-flex '>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%', }}>2.1</div>
                                        <div className='align-items-center' style={{ width: '51%', padding: ' 0px 10px 10px 10px', }}> Date of instrument creating mortgage, Charge or pledge</div>
                                        <div className='align-items-center' style={{ width: '5%', padding: ' 5px 10px', border: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '5%', padding: ' 5px 10px', border: '1px solid #121212', borderLeft: 'none' }}> </div>
                                        <div className='align-items-center text-center' style={{ paddingLeft: '2px', width: '3%', }}></div>
                                        <div className='align-items-center' style={{ width: '5%', padding: ' 5px 10px', border: '1px solid #121212', }}> </div>
                                        <div className='align-items-center' style={{ width: '5%', padding: ' 5px 10px', border: '1px solid #121212', borderLeft: 'none' }}> </div>
                                        <div className='align-items-center text-center' style={{ paddingLeft: '2px', width: '3%', }}></div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', border: '1px solid #121212', }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', border: '1px solid #121212', borderLeft: 'none' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', border: '1px solid #121212', borderLeft: 'none' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', border: '1px solid #121212', borderLeft: 'none', borderRight: '1px solid #121212' }}> </div>
                                    </div>

                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>2.2</div>
                                        <div className='align-items-center' style={{ width: '30%', padding: '0px 10px 10px 10px' }}> Name of instrument (if any) creating or evidencing the mortgage or charge or pledge or other interest (to be specified).</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212',minHeight:"67px", width: '63%', padding: '0px 10px 10px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>2.3</div>
                                        <div className='align-items-center' style={{ width: '30%', padding: '0px 10px 10px 10px' }}> Amount of mortgage or charge, pledge etc.</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '63%', padding: '0px 10px 10px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>2.4</div>
                                        <div className='align-items-center' style={{ width: '30%', padding: '0px 10px 10px 10px' }}> Short particulars of the property mortgaged, charge, pledge or other interest</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212',minHeight:"61px", width: '63%', padding: '0px 10px 10px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>2.5</div>
                                        <div className='align-items-center' style={{ width: '30%', padding: '0px 10px 0px 10px' }}> Gist of the terms and conditions and extent and operation of the mortgage, charge, pledge or other interest</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212',minHeight:"66px", width: '63%', padding: '0px 10px 10px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                    </div>
                                    <div style={{marginLeft:"5.3rem"}}>
                                        <i>(Also include description of the nature of the mortgage/charge e.g. equitable, pari-passu, etc.)</i>
                                    </div>
                                    <div className='d-flex mt-3'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>2.6</div>
                                        <div className='align-items-center' style={{ width: '30%', padding: '0px 10px 10px 10px' }}> Name and address of the person(s) entitled to the mortgage, charge, pledge or other interest.</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212',minHeight:"97px", width: '63%', padding: '0px 10px 10px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                    </div>

                                    <PartsWrapper className='mt-4 mb-3'>
                                        PART-III
                                    </PartsWrapper>
                                    <div className='row mt-4'>
                                        <div className='col-md-12'>
                                            <HeadingWrapper className='d-flex text-center justify-content-center'>
                                                (PARTICULARS OF MODIFICATION OF MORTGAGES, CHARGES OR PLEDGE ETC.)
                                            </HeadingWrapper>
                                        </div>
                                    </div>
                                    <div className='d-flex '>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%', }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%', }}>3.1</div>
                                        <div className='align-items-center' style={{ width: '30%', padding: ' 0px 10px 10px 10px', }}> Date of Instrument creating the original mortgage / charge, pledge etc</div>
                                        <div className='align-items-center' style={{ width: '21%', padding: ' 0px 10px 10px 10px', }}></div>
                                        <div className='d-flex flex-column' style={{ width: '10%',}}>
                                            <div className='align-items-center text-center' style={{ width: '100%', }}>Day</div>
                                            <div className='d-flex'>
                                                <div className='align-items-center' style={{ width: '50%',minHeight:"25px", padding: ' 5px 10px', border: '1px solid #121212' }}> </div>
                                                <div className='align-items-center' style={{ width: '50%',minHeight:"25px", padding: ' 5px 10px', border: '1px solid #121212', borderLeft: 'none' }}> </div>
                                            </div>
                                        </div>
                                        <div className='align-items-center text-center' style={{ paddingLeft: '2px', width: '3%', }}></div>
                                        <div className='d-flex flex-column' style={{ width: '10%',}}>
                                            <div className='align-items-center text-center' style={{ width: '100%', }}>Month</div>
                                            <div className='d-flex'>
                                                <div className='align-items-center' style={{ width: '50%',minHeight:"25px", padding: ' 5px 10px', border: '1px solid #121212' }}> </div>
                                                <div className='align-items-center' style={{ width: '50%',minHeight:"25px", padding: ' 5px 10px', border: '1px solid #121212', borderLeft: 'none' }}> </div>
                                            </div>
                                        </div>
                                        <div className='align-items-center text-center' style={{ paddingLeft: '2px', width: '3%', }}></div>
                                        <div className='d-flex flex-column' style={{ width: '16%',}}>
                                            <div className='align-items-center text-center' style={{ width: '100%', }}>Year</div>
                                            <div className='d-flex'>
                                                <div className='align-items-center' style={{ width: '50%',minHeight:"25px", padding: ' 5px 10px', border: '1px solid #121212' }}> </div>
                                                <div className='align-items-center' style={{ width: '50%',minHeight:"25px", padding: ' 5px 10px', border: '1px solid #121212', borderLeft: 'none' }}> </div>
                                                <div className='align-items-center' style={{ width: '50%',minHeight:"25px", padding: ' 5px 10px', border: '1px solid #121212', borderLeft: 'none' }}> </div>
                                                <div className='align-items-center' style={{ width: '50%',minHeight:"25px", padding: ' 5px 10px', border: '1px solid #121212', borderLeft: 'none' }}> </div>
                                            </div>
                                        </div>
                                      
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>3.2</div>
                                        <div className='align-items-center' style={{ width: '28%', padding: '0px 10px 10px 10px' }}> Brief description of instrument(s)creating the original mortgage, charge, pledge etc.</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '62%', padding: '0px 10px 10px 10px'}}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex '>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%', }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%', }}>3.3</div>
                                        <div className='align-items-center' style={{ width: '30%', padding: ' 0px 10px 10px 10px', }}> Date(s) of modification of the mortgage, charge, pledge etc. prior to present modification</div>
                                        <div className='align-items-center' style={{ width: '21%', padding: ' 0px 10px 10px 10px', }}></div>
                                        <div className='d-flex flex-column' style={{ width: '10%',}}>
                                            <div className='align-items-center text-center' style={{ width: '100%', }}>Day</div>
                                            <div className='d-flex'>
                                                <div className='align-items-center' style={{ width: '50%',minHeight:"25px", padding: ' 5px 10px', border: '1px solid #121212' }}> </div>
                                                <div className='align-items-center' style={{ width: '50%',minHeight:"25px", padding: ' 5px 10px', border: '1px solid #121212', borderLeft: 'none' }}> </div>
                                            </div>
                                        </div>
                                        <div className='align-items-center text-center' style={{ paddingLeft: '2px', width: '3%', }}></div>
                                        <div className='d-flex flex-column' style={{ width: '10%',}}>
                                            <div className='align-items-center text-center' style={{ width: '100%', }}>Month</div>
                                            <div className='d-flex'>
                                                <div className='align-items-center' style={{ width: '50%',minHeight:"25px", padding: ' 5px 10px', border: '1px solid #121212' }}> </div>
                                                <div className='align-items-center' style={{ width: '50%',minHeight:"25px", padding: ' 5px 10px', border: '1px solid #121212', borderLeft: 'none' }}> </div>
                                            </div>
                                        </div>
                                        <div className='align-items-center text-center' style={{ paddingLeft: '2px', width: '3%', }}></div>
                                        <div className='d-flex flex-column' style={{ width: '16%',}}>
                                            <div className='align-items-center text-center' style={{ width: '100%', }}>Year</div>
                                            <div className='d-flex'>
                                                <div className='align-items-center' style={{ width: '50%',minHeight:"25px", padding: ' 5px 10px', border: '1px solid #121212' }}> </div>
                                                <div className='align-items-center' style={{ width: '50%',minHeight:"25px", padding: ' 5px 10px', border: '1px solid #121212', borderLeft: 'none' }}> </div>
                                                <div className='align-items-center' style={{ width: '50%',minHeight:"25px", padding: ' 5px 10px', border: '1px solid #121212', borderLeft: 'none' }}> </div>
                                                <div className='align-items-center' style={{ width: '50%',minHeight:"25px", padding: ' 5px 10px', border: '1px solid #121212', borderLeft: 'none' }}> </div>
                                            </div>
                                        </div>
                                      
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>3.4</div>
                                        <div className='align-items-center' style={{ width: '28%', padding: '0px 10px 10px 10px' }}> Brief description of instrument(s)modifying the mortgage, charge, pledge etc. prior to present modification.</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '62%', padding: '0px 10px 10px 10px'}}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex '>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%', }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%', }}>3.5</div>
                                        <div className='align-items-center' style={{ width: '30%', padding: ' 0px 10px 10px 10px', }}> Date of present modification of the mortgage, charge, pledge etc. </div>
                                        <div className='align-items-center' style={{ width: '21%', padding: ' 0px 10px 10px 10px', }}></div>
                                        <div className='d-flex flex-column' style={{ width: '10%',}}>
                                            <div className='align-items-center text-center' style={{ width: '100%', }}>Day</div>
                                            <div className='d-flex'>
                                                <div className='align-items-center' style={{ width: '50%',minHeight:"25px", padding: ' 5px 10px', border: '1px solid #121212' }}> </div>
                                                <div className='align-items-center' style={{ width: '50%',minHeight:"25px", padding: ' 5px 10px', border: '1px solid #121212', borderLeft: 'none' }}> </div>
                                            </div>
                                        </div>
                                        <div className='align-items-center text-center' style={{ paddingLeft: '2px', width: '3%', }}></div>
                                        <div className='d-flex flex-column' style={{ width: '10%',}}>
                                            <div className='align-items-center text-center' style={{ width: '100%', }}>Month</div>
                                            <div className='d-flex'>
                                                <div className='align-items-center' style={{ width: '50%',minHeight:"25px", padding: ' 5px 10px', border: '1px solid #121212' }}> </div>
                                                <div className='align-items-center' style={{ width: '50%',minHeight:"25px", padding: ' 5px 10px', border: '1px solid #121212', borderLeft: 'none' }}> </div>
                                            </div>
                                        </div>
                                        <div className='align-items-center text-center' style={{ paddingLeft: '2px', width: '3%', }}></div>
                                        <div className='d-flex flex-column' style={{ width: '16%',}}>
                                            <div className='align-items-center text-center' style={{ width: '100%', }}>Year</div>
                                            <div className='d-flex'>
                                                <div className='align-items-center' style={{ width: '50%',minHeight:"25px", padding: ' 5px 10px', border: '1px solid #121212' }}> </div>
                                                <div className='align-items-center' style={{ width: '50%',minHeight:"25px", padding: ' 5px 10px', border: '1px solid #121212', borderLeft: 'none' }}> </div>
                                                <div className='align-items-center' style={{ width: '50%',minHeight:"25px", padding: ' 5px 10px', border: '1px solid #121212', borderLeft: 'none' }}> </div>
                                                <div className='align-items-center' style={{ width: '50%',minHeight:"25px", padding: ' 5px 10px', border: '1px solid #121212', borderLeft: 'none' }}> </div>
                                            </div>
                                        </div>
                                      
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>3.6</div>
                                        <div className='align-items-center' style={{ width: '28%', padding: '0px 10px 10px 10px' }}> Brief description of instrument(s)modifying the present mortgage, charge, pledge etc.</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '62%', padding: '0px 10px 10px 10px'}}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>3.7</div>
                                        <div className='align-items-center' style={{ width: '28%', padding: '0px 10px 10px 10px' }}> Particulars of the present modification specifying the terms or conditions or the extent or operation and the details of the modification.</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '62%', padding: '0px 10px 10px 10px'}}></ScrollWrapper>
                                    </div>

                                    <PartsWrapper className='mt-4 mb-3'>
                                        PART-IV
                                    </PartsWrapper>
                                    <div className='row mt-4'>
                                        <div className='col-md-12'>
                                            <HeadingWrapper className='d-flex text-center justify-content-center'>
                                            (MEMORANDUM OF COMPLETE SATISFACTION OF MORTGAGE, CHARGES OR PLEDGE ETC.)
                                            </HeadingWrapper>
                                        </div>
                                    </div>

                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '5%' }}>4</div>
                                        <div className='align-items-center' style={{  padding: '0px 10px 10px 0px' }}>Particulars of the mortgage/charge satisfaction thereof :</div>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>4.1</div>
                                        <div className='align-items-center' style={{ width: '24%', padding: '0px 10px 10px 10px' }}> Amount of mortgage / pledge / charge etc. (Rs)</div>
                                        <div className='align-items-center' style={{ width: '4%', padding: '0px 10px 10px 10px' }}></div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '62%', padding: '0px 10px 10px 10px'}}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-3'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>4.2</div>
                                        <div className='align-items-center' style={{ width: '30%', padding: ' 0px 10px 10px 10px' }}> Date of registration of mortgage or pledge or charge.</div>
                                        <div className='align-items-center' style={{ width: '23%', padding: ' 0px 10px 10px 10px' }}></div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ border: '1px solid #121212', width: '4%', padding: ' 5px 10px' }}> </div>
                                        <div className='align-items-center' style={{ paddingLeft: '8px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ border: '1px solid #121212', width: '4%', padding: ' 5px 10px', borderRight: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ paddingLeft: '8px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', border: '1px solid #121212', }}> </div>
                                    </div>
                                    <div className='d-flex mt-3'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>4.3</div>
                                        <div className='align-items-center' style={{ width: '30%', padding: ' 0px 10px 10px 10px' }}> Date of last modification, if any of mortgage or pledge or charge</div>
                                        <div className='align-items-center' style={{ width: '23%', padding: ' 0px 10px 10px 10px' }}></div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ border: '1px solid #121212', width: '4%', padding: ' 5px 10px' }}> </div>
                                        <div className='align-items-center' style={{ paddingLeft: '8px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ border: '1px solid #121212', width: '4%', padding: ' 5px 10px', borderRight: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ paddingLeft: '8px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', border: '1px solid #121212', }}> </div>
                                    </div>
                                    <div className='d-flex mt-3'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>4.4</div>
                                        <div className='align-items-center' style={{ width: '30%', padding: ' 0px 10px 10px 10px' }}>Date of satisfaction.</div>
                                        <div className='align-items-center' style={{ width: '23%', padding: ' 0px 10px 10px 10px' }}></div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ border: '1px solid #121212', width: '4%', padding: ' 5px 10px' }}> </div>
                                        <div className='align-items-center' style={{ paddingLeft: '8px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ border: '1px solid #121212', width: '4%', padding: ' 5px 10px', borderRight: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ paddingLeft: '8px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', border: '1px solid #121212', }}> </div>
                                    </div>
                                    <div className='d-flex mt-3'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>4.5</div>
                                        <div className='align-items-center' style={{ width: '37%', padding: '0px 10px 10px 10px' }}> Name(s) and address(es) of the mortgagees(s) / trustee(s) for the redeemable capital / debenture-holders, etc. (give description)</div>
                                        <div className='align-items-center' style={{ width: '15%', padding: '0px 10px 10px 10px' }}></div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '38%', padding: '0px 10px 10px 10px'}}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-3'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>4.6</div>
                                        <div className='align-items-center' style={{ width: '27%', padding: '0px 10px 10px 10px' }}> Brief description of instrument(s) through which mortgage, charge, pledge etc. was created or modified and is now satisfied.</div>
                                        <div className='align-items-center' style={{ width: '15%', padding: '0px 10px 10px 10px' }}></div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212',minHeight:"80px", width: '48%', padding: '0px 10px 10px 10px'}}></ScrollWrapper>
                                    </div>
                                    <PartsWrapper className='mt-4 mb-3'>
                                        PART-V
                                    </PartsWrapper>
                                    <div className='row mt-4'>
                                        <div className='col-md-12'>
                                            <HeadingWrapper className='d-flex text-center justify-content-center'>
                                            (REGISTRATION OF MORTGAGE, CHARGES ETC. SUBJECT TO WHICH PROPERTY HAS BEEN ACQUIRED)
                                            </HeadingWrapper>
                                        </div>
                                    </div>
                                    <div className='d-flex '>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%', }}>5.1</div>
                                        <div className='align-items-center' style={{ width: '51%', padding: ' 0px 10px 10px 10px', }}> Date of Creation.</div>
                                        <div className='d-flex flex-column' style={{ width: '10%',}}>
                                            <div className='align-items-center text-center' style={{ width: '100%', }}>Day</div>
                                            <div className='d-flex'>
                                                <div className='align-items-center' style={{ width: '50%',minHeight:"25px", padding: ' 5px 10px', border: '1px solid #121212' }}> </div>
                                                <div className='align-items-center' style={{ width: '50%',minHeight:"25px", padding: ' 5px 10px', border: '1px solid #121212', borderLeft: 'none' }}> </div>
                                            </div>
                                        </div>
                                        <div className='align-items-center text-center' style={{ paddingLeft: '2px', width: '3%', }}></div>
                                        <div className='d-flex flex-column' style={{ width: '10%',}}>
                                            <div className='align-items-center text-center' style={{ width: '100%', }}>Month</div>
                                            <div className='d-flex'>
                                                <div className='align-items-center' style={{ width: '50%',minHeight:"25px", padding: ' 5px 10px', border: '1px solid #121212' }}> </div>
                                                <div className='align-items-center' style={{ width: '50%',minHeight:"25px", padding: ' 5px 10px', border: '1px solid #121212', borderLeft: 'none' }}> </div>
                                            </div>
                                        </div>
                                        <div className='align-items-center text-center' style={{ paddingLeft: '2px', width: '3%', }}></div>
                                        <div className='d-flex flex-column' style={{ width: '16%',}}>
                                            <div className='align-items-center text-center' style={{ width: '100%', }}>Year</div>
                                            <div className='d-flex'>
                                                <div className='align-items-center' style={{ width: '50%',minHeight:"25px", padding: ' 5px 10px', border: '1px solid #121212' }}> </div>
                                                <div className='align-items-center' style={{ width: '50%',minHeight:"25px", padding: ' 5px 10px', border: '1px solid #121212', borderLeft: 'none' }}> </div>
                                                <div className='align-items-center' style={{ width: '50%',minHeight:"25px", padding: ' 5px 10px', border: '1px solid #121212', borderLeft: 'none' }}> </div>
                                                <div className='align-items-center' style={{ width: '50%',minHeight:"25px", padding: ' 5px 10px', border: '1px solid #121212', borderLeft: 'none' }}> </div>
                                            </div>
                                        </div>
                                      
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>5.2</div>
                                        <div className='align-items-center' style={{ width: '28%', padding: '0px 10px 10px 10px' }}> Description of the instrument(s)creating or evidencing the mortgage, charge, pledge etc</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212',minHeight:"66px", width: '65%', padding: '0px 10px 10px 10px'}}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>5.3</div>
                                        <div className='align-items-center' style={{ width: '28%', padding: '0px 10px 10px 10px' }}> Memorandum or any other instrument regarding mortgage or charge or pledge subject to which property has been acquired.</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212',minHeight:"80px", width: '65%', padding: '0px 10px 10px 10px'}}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%', }}></div>
                                        <div className='align-items-center' style={{ width: '51%', padding: ' 5px 10px', }}> </div>
                                        <div className='align-items-center text-center' style={{ width: '10%', padding: ' 5px', }}>Day</div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%', }}></div>
                                        <div className='align-items-center text-center' style={{ width: '10%', padding: ' 5px', }}>Month</div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%', }}></div>
                                        <div className='align-items-center text-center' style={{ width: '16%', padding: ' 5px 10px' }}>Year</div>
                                        <div></div>
                                    </div>
                                    <div className='d-flex '>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%', }}>5.4</div>
                                        <div className='align-items-center' style={{ width: '51%', padding: ' 0px 10px 10px 10px', }}>Date of acquisition of the property</div>
                                        <div className='align-items-center' style={{ width: '5%', padding: ' 5px 10px', border: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '5%', padding: ' 5px 10px', border: '1px solid #121212', borderLeft: 'none' }}> </div>
                                        <div className='align-items-center text-center' style={{ paddingLeft: '2px', width: '3%', }}></div>
                                        <div className='align-items-center' style={{ width: '5%', padding: ' 5px 10px', border: '1px solid #121212', }}> </div>
                                        <div className='align-items-center' style={{ width: '5%', padding: ' 5px 10px', border: '1px solid #121212', borderLeft: 'none' }}> </div>
                                        <div className='align-items-center text-center' style={{ paddingLeft: '2px', width: '3%', }}></div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', border: '1px solid #121212', }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', border: '1px solid #121212', borderLeft: 'none' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', border: '1px solid #121212', borderLeft: 'none' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', border: '1px solid #121212', borderLeft: 'none', borderRight: '1px solid #121212' }}> </div>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>5.5</div>
                                        <div className='align-items-center' style={{ width: '28%', padding: '0px 10px 10px 10px' }}>Amount owing on security of the mortgage or charge</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '65%', padding: '0px 10px 10px 10px'}}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>5.6</div>
                                        <div className='align-items-center' style={{ width: '28%', padding: '0px 10px 10px 10px' }}> Short particulars of the property acquired subject to mortgage, charge or pledge.</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '65%', padding: '0px 10px 10px 10px'}}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>5.7</div>
                                        <div className='align-items-center' style={{ width: '28%', padding: '0px 10px 10px 10px' }}> Gist of the terms and conditions and extent and operation of the mortgage, charge, pledge or other interest</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '65%', padding: '0px 10px 10px 10px'}}></ScrollWrapper>
                                    </div>
                                    <PartsWrapper className='mt-4 mb-3'>
                                        PART-VI
                                    </PartsWrapper>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '5%' }}>6.1</div>
                                        <div className='align-items-center' style={{ padding: '5px 10px 0px 10px',fontWeight:"bold" }}>Declaration</div>
                                    </div>
                                    <div className='d-flex'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}></div>
                                        <div className='align-items-center' style={{ padding: ' 0px 10px 10px 10px' }}>I do hereby solemnly and sincerely declare that the information provided in the form is:</div>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '0%' }}></div>
                                        <div className='align-items-start d-flex' style={{ padding: ' 5px 10px', gap: "12px" }}><div>
                                            (i)</div>
                                            <div style={{ marginLeft: '14px' }}>true and correct to the best of my knowledge, in consonance with the record as maintained by the Company and nothing has been concealed; and </div>
                                        </div>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '0%' }}></div>
                                        <div className='align-items-start d-flex' style={{ padding: ' 5px 10px', gap: "12px" }}><div>
                                            (ii)</div>
                                            <div style={{ marginLeft: '11px' }}>hereby reported after complying with and fulfilling all requirements under the relevant provisions of law, rules, regulations, directives, circulars and notifications whichever is applicable.</div>
                                        </div>
                                    </div>


                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>6.2</div>
                                        <div className='align-items-center' style={{ width: '28%', padding: '0px 10px 10px 10px' }}> Name of Authorized Officer with designation/ Authorized Intermediary</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '32.5%', padding: ' 5px 10px', }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', borderLeft: "none", width: '32.5%', padding: ' 5px 10px', }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>6.3</div>
                                        <div className='align-items-center' style={{ width: '28%', padding: '0px 10px 10px 10px' }}>Signatures </div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '65%', padding: ' 5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>6.4</div>
                                        <div className='align-items-center' style={{ width: '40%', padding: ' 0px 10px 10px 10px' }}>Registration No of Authorized Intermediary, if applicable</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '53%', padding: ' 5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}></div>
                                        <div className='align-items-center' style={{ width: '55%', padding: ' 5px 10px' }}> </div>
                                        <div className='align-items-center text-center' style={{ width: '8%', padding: ' 5px' }}> Day</div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center text-center' style={{ width: '8%', padding: ' 5px' }}> Month</div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center text-center' style={{ width: '16%', padding: ' 5px' }}>Year </div>
                                        <div></div>
                                    </div>
                                    <div className='d-flex '>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>3.5</div>
                                        <div className='align-items-center' style={{ width: '55%', padding: ' 0px 10px 10px 10px' }}>Date</div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ border: '1px solid #121212', width: '4%', padding: ' 5px 10px' }}> </div>
                                        <div className='align-items-center' style={{ paddingLeft: '8px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ border: '1px solid #121212', width: '4%', padding: ' 5px 10px', borderRight: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ paddingLeft: '8px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', border: '1px solid #121212', }}> </div>
                                    </div>
                                    <div className='d-flex my-2' style={{ fontWeight: "bold" }}>
                                        Enclosure:
                                    </div>
                                    <div className='d-flex align-items-baseline'>
                                        <div style={{ marginLeft: "5px" }}>1.</div><div style={{ marginLeft: '23px' }}>Copy of instrument(s) creating/ modifying the mortgage/charge/pledge, as the case may be. (Applicable in case of creation/ modification of mortgage/ charge/ pledge and registration of mortgage/charges/pledge etc. subject to which property has been acquired)</div>
                                    </div>
                                    <div className='d-flex'>
                                        <div style={{ marginLeft: "5px" }}>2.</div><div style={{ marginLeft: '20px' }}>No objection certificate from the charge holder in case of modification of mortgage/charge/pledge (if applicable). </div>
                                    </div>
                                    <div className='d-flex'>
                                        <div style={{ marginLeft: "5px" }}>3.</div><div style={{ marginLeft: '20px' }}>No objection certificate from the creditor in case of satisfaction of mortgage/charge/pledge (if available)</div>
                                    </div>
                                    <div className='d-flex'>
                                        <div style={{ marginLeft: "5px" }}>4.</div><div style={{ marginLeft: '19px' }}>Affidavit under regulation 45 duly signed by the person who signed this application, verifying that the contents of the application and attached documents are true and correct, attested by an Oath Commissioner (scanned image in case of online filing).</div>
                                    </div>
                                    <div className='d-flex'>
                                        <div style={{ marginLeft: "5px" }}>5.</div><div style={{ marginLeft: '19px' }}>Original challan or other evidence of payment of fee specified in Seventh Schedule of the Act (not applicable in case of online filing)</div>
                                    </div>
                                    

                                    

                                </div>
                            </div>
                        </div>
                    </PDFExport>}
            </div>
            <button disabled={loading} className='btn btn-danger' onClick={(e) => {
                // if(viewPdf)
                //   SetViewPDf(true)
                if (pdfExportComponent.current) {
                    pdfExportComponent.current.save();
                }
            }}>Download PDF</button>
        </div>
    )
}
const PartsWrapper = styled.div`
color: #121212;
text-decoration: underline;
justify-content: center;
display: flex;
margin-right: 15px;
font-size: 17px;
font-weight: bold;
`;
const ScrollWrapper = styled.div`
max-height: 50px;
overflow-y: scroll;
overflow-x: hidden;
::-webkit-scrollbar{
    height: 5px;
    width: 3px;
  }
  
  ::-webkit-scrollbar-track{
    background: #F9F9FB;
  }
  ::-webkit-scrollbar-thumb{
    background: #4E515680;
    border-radius: 5px;
  
  }
`;
const HeadingWrapper = styled.div`
font-size: 17px;
font-weight: bold;
`;
const FormName = styled.div`
color: #121212;
text-decoration: underline;
justify-content: end;
display: flex;
margin-right: 15px;
font-size: 17px;
font-weight: bold;
`;
const Wrapper = styled.div`
table.table-bordered{
    border: 1px solid #121212
     border-color: #121212; 
    // border:1px solid blue;
    // margin-top:20px;
  }
// table.table-bordered > thead > tr > th{
//     // border:1px solid blue;
//     border-color: #121212; 
// }
// table.table-bordered > tbody > tr > td{
//     // border:1px solid blue;
//     borderC-color: #121212; 
// }
 tr td {
    // border:1px solid blue;.
    border-color: #121212; 
}

`;
const WrapperDashed = styled.div`

  td {
    
    // border: 1px dashed #dddcdc;
}

`;