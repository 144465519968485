import React, { useState, useEffect } from 'react'
import { PDFExport } from "@progress/kendo-react-pdf";
import PageTemplate from "./page-template";
import {
    getCompanies,
} from "../../../store/services/company.service";
import styled from 'styled-components';
import { TiTick } from "react-icons/ti";
import { numberWithCommas } from 'utilities/utilityFunctions';
export const StatutoryForm23 = ({ data, formTemplate = false }) => {
    const pdfExportComponent = React.useRef(null);
    const [CompanyData, setCompanyData] = useState(null)
    const [loading, setLoading] = useState(false);
    const [fontFamilyForStaticData, setFontFamilyForStaticData] = useState('Montserrat, sans-serif')
    const [fontSizeForStaticData, setFontSizeForStaticData] = useState('14px')
    const [fontfamilyforDynimicData, setFontFamilyForDynmicData] = useState('Segoe Print')
    const [fontColor, setFontColor] = useState('')
    const baseEmail = sessionStorage.getItem("email") || "";
    const [governance, setGovernance] = useState({})
    const getAllCompanies = async () => {
        setLoading(true)
        try {
            const response = await getCompanies(baseEmail);
            if (response.status === 200) {
                response.data.data = response.data.data.filter((item) => item?.active === 'Y')
                const parents = response.data.data;
                const company = parents?.find(ite => ite?.code == data?.company_code)
                let Allperson = company.governance && company.governance != "" ? JSON.parse(company.governance) : []
                let sec = Allperson.find(x => x.role == "Company Secretary" && x?.active?.toLowerCase() != 'n')
                let ceo = Allperson.find(x => x.role == "CEO" && x?.active?.toLowerCase() != 'n')


                if (sec) {
                    setGovernance(sec)
                } else if (ceo) {
                    setGovernance(ceo)
                }
                setCompanyData(company)
                setTimeout(() => {
                    setLoading(false)
                }, 1000);
            }
        } catch (error) {
            setLoading(false)
        }
    };
    useEffect(() => {
        getAllCompanies()
    }, [])
    return (
        <div>
            <div
                style={{ fontFamily: "sans-serif " }}
            >

                {formTemplate ?
                    <PDFExport
                        paperSize="A4"
                        margin="1cm"
                        scale={0.6}
                        fileName={`Form 23`}
                        pageTemplate={PageTemplate}
                        ref={pdfExportComponent}
                    >
                        <div style={{ fontFamily: `${fontFamilyForStaticData}`, fontSize: `${fontSizeForStaticData}`, fontWeight: 500 }}>
                            <FormName>
                                Form-23
                            </FormName>
                            <div className='row'>
                                <div className='col-md-12' >
                                    <HeadingWrapper className='d-flex justify-content-center'>
                                        THE COMPANIES ACT, 2017
                                    </HeadingWrapper>

                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <HeadingWrapper className='d-flex justify-content-center'>
                                        THE COMPANIES REGULATIONS, 2024
                                    </HeadingWrapper>

                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <div style={{ fontSize: '15px', fontWeight: 'bold' }} className='d-flex justify-content-center'>
                                        [Section 285(5) and Regulation 59 & 30]
                                    </div>
                                </div>
                            </div>

                            <div className='row mt-4'>
                                <div className='col-md-12'>
                                    <HeadingWrapper className='text-center d-flex justify-content-center'>
                                        INFORMATION TO BE FURNISHED IN RELATION TO ANY OFFER OF A SCHEME OR CONTRACT INVOLVING THE TRANSFER OF SHARES OR ANY CLASS OF SHARES IN THE TRANSFEROR COMPANY TO THE TRANSFEREE COMPANY
                                    </HeadingWrapper>
                                </div>
                            </div>
                            <PartsWrapper className='mt-4 mb-3'>
                                PART-I
                            </PartsWrapper>
                            <div className='row'>
                                <div className='col-md-12' style={{ padding: '25px 50px', wordWrap: 'break-word' }}>
                                    <div> <i>(Please complete in bold capital letters)</i></div>


                                    <div className='d-flex' >
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>1.1</div>
                                        <div className='align-items-center' style={{ width: '28%', padding: '0px 10px 10px 10px' }}>CUIN (Registration Number)  </div>
                                        <div className='align-items-center  d-flex'  >{
                                            '1234 552'.split("")?.map((item, idx) => {
                                                if (item == ' ') return
                                                return <div style={{ minWidth: '30px', minHeight: '34px', border: '1px solid #121212', borderLeft: idx != 0 ? "none" : "1px solid #121212", padding: '6px 9px' }}></div>
                                            })
                                        }
                                        </div>
                                    </div>



                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>1.2</div>
                                        <div className='align-items-center' style={{ width: '22%', padding: '0px 10px 10px 10px' }}>Name of the Company  </div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '71%', padding: '0px 10px 10px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                    </div>




                                    <div className='d-flex  mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>1.3</div>
                                        <div className='align-items-center' style={{ width: '20.20%', padding: '0px 10px 10px 10px' }}>Fee payment Details*</div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '5%' }}>1.3.1</div>
                                        <div className='align-items-center' style={{ width: '13.20%', paddingLeft: '20px' }}>Challan No.</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '17.20%', paddingLeft: '20px', minHeight: '34px' }}></ScrollWrapper>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '5%' }}>1.3.2</div>
                                        <div className='align-items-center' style={{ width: '13.20%', paddingLeft: '20px' }}>Amount</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '19.20%', paddingLeft: '20px', minHeight: '34px' }}></ScrollWrapper>
                                    </div>

                                    <PartsWrapper className='mt-4 mb-3'>
                                        PART-II
                                    </PartsWrapper>

                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>2.1</div>
                                        <div className='align-items-center' style={{ width: '36%', padding: '0px 10px 10px 10px' }}> Name of the transferee company making the offer or issuing any circular in connection with the offer.</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '57%', padding: '0px 10px 10px 10px',  }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>2.2</div>
                                        <div className='align-items-center' style={{ width: '36%', padding: '0px 10px 10px 10px' }}>Capital structure of the transferee Company;</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '57%', padding: '0px 10px 10px 10px',  }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>2.3</div>
                                        <div className='align-items-center' style={{ width: '36%', padding: '0px 10px 10px 10px' }}> Management structure of the transferee Company (composition of Board of Directors and particulars regarding Chief Executive).</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '57%', padding: '0px 10px 10px 10px',  }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>2.4</div>
                                        <div className='align-items-center' style={{ width: '36%', padding: '0px 10px 10px 10px' }}> Extent of shareholding of the Chief Executive, Directors, Secretary or their associates in the transferee Company</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '57%', padding: '0px 10px 10px 10px',  }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>2.5</div>
                                        <div className='align-items-center' style={{ width: '36%', padding: '0px 10px 10px 10px' }}> Extent of shareholding of the Chief Executive, Directors, Managing Agents (if any), Secretary and Chief Accountant or their associates of the transferee Company in the transferor Company.</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '57%', padding: '0px 10px 10px 10px',  }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>2.6</div>
                                        <div className='align-items-center' style={{ width: '36%', padding: '0px 10px 10px 10px' }}> If the offer is being made on behalf of the Company by any other person, the interest of the person in the Company which is making the offer</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '57%', padding: '0px 10px 10px 10px',  }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>2.7</div>
                                        <div className='align-items-center' style={{ width: '36%', padding: '0px 10px 10px 10px' }}> Name of the transferor Company</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '57%', padding: '0px 10px 10px 10px',  }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>2.8</div>
                                        <div className='align-items-center' style={{ width: '36%', padding: '0px 10px 10px 10px' }}>Capital structure of the transferor Company</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '57%', padding: '0px 10px 10px 10px',  }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>2.9</div>
                                        <div className='align-items-center' style={{ width: '36%', padding: '0px 10px 10px 10px' }}>Management structure (composition of Board of Directors, and particulars regarding Chief Executive).</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '57%', padding: '0px 10px 10px 10px',  }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>2.10</div>
                                        <div className='align-items-center' style={{ width: '36%', padding: '0px 10px 10px 10px' }}> Extent of shareholdings of the Chief Executive, Directors, Managing Agents (if any), Secretary and Chief Accountant or their associates, if any, of the transferor Company in the transferor and transferee Companies</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '57%', padding: '0px 10px 10px 10px',  }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>2.11</div>
                                        <div className='align-items-center' style={{ padding: '0px 10px 10px 10px' }}> A summary of the financial position of the transferee and transferor Companies to be given separately in the pro forma contained in the Appendix</div>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>2.12</div>
                                        <div className='align-items-center' style={{ width: '36%', padding: '0px 10px 10px 10px' }}> The prices offered for the purchase of the shares of the transferor company; or</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '57%', padding: '0px 10px 10px 10px',  }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>2.13</div>
                                        <div className='align-items-center' style={{ width: '36%', padding: '0px 10px 10px 10px' }}> Full particulars of the consideration offered other than cash for the purchase of shares of the transferor company.</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '57%', padding: '0px 10px 10px 10px',  }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>2.14</div>
                                        <div className='align-items-center' style={{ padding: '0px 10px 10px 10px' }}> If the consideration offered involved allotment of shares in transferee company, then specify full particulars of; </div>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '4%' }}>2.14.1</div>
                                        <div className='align-items-center' style={{ width: '35%', padding: '0px 10px 10px 10px' }}> shares; </div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '57%', padding: '0px 10px 10px 10px',  }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '4%' }}>2.14.2</div>
                                        <div className='align-items-center' style={{ width: '35%', padding: '0px 10px 10px 10px' }}>the right attached thereto;</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '57%', padding: '0px 10px 10px 10px',  }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '4%' }}>2.14.3</div>
                                        <div className='align-items-center' style={{ width: '35%', padding: '0px 10px 10px 10px' }}> basis of valuation of shares of transferor company</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '57%', padding: '0px 10px 10px 10px',  }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '4%' }}>2.14.4</div>
                                        <div className='align-items-center' style={{ width: '35%', padding: '0px 10px 10px 10px' }}> valuation of the shares of transferee &nbsp;&nbsp;&nbsp;&nbsp;company proposed to be allotted. </div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '57%', padding: '0px 10px 10px 10px',  }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '4%' }}>2.15</div>
                                        <div className='align-items-center' style={{ width: '35%', padding: '0px 10px 10px 10px' }}>Sources from which the transferee Company proposes to pay for the acquisition of the said shares, if the consideration is cash.</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '57%', padding: '0px 10px 10px 10px',  }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '4%' }}>2.16</div>
                                        <div className='align-items-center' style={{ width: '35%', padding: '0px 10px 10px 10px' }}> Details of transfers of shares in the transferor Company by its Chief Executive, Directors, Managing Agents (if any), Secretary and Chief Accountant or their associates in the two years preceding the offer.</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '57%', padding: '0px 10px 10px 10px',  }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '4%' }}>2.17</div>
                                        <div className='align-items-center' style={{ width: '35%', padding: '0px 10px 10px 10px' }}> Reasons for which the offer has been recommended for acceptance by the members of the transferor Company by its Directors. Every recommendation to the members of the transferor Company shall also contain a statement of the interest of its Chief Executive, Directors, Managing Agents (if any), Secretary and Chief Accountant or their associates in the transferee Company. </div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '57%', padding: '0px 10px 10px 10px',  }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>2.18</div>
                                        <div className='align-items-center' style={{ padding: '0px 10px 10px 10px' }}>The transferee company has taken necessary steps to ensure the availability of cash for the purpose of clause (b) of sub-section (5) of section 285;</div>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>2.19</div>
                                        <div className='align-items-center' style={{ padding: '0px 10px 10px 10px' }}>A certificate from the Auditor of the transferee Company that the information contained in the offer/ recommendation is correct is attached</div>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>2.20</div>
                                        <div className='align-items-center' style={{ padding: '0px 10px 10px 10px' }}> Financial liquidity position of the Company according to the latest audited balance sheet.</div>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ width: '36%', padding: '0px 10px 10px 10px' }}></div>
                                        <ScrollWrapper className='align-items-center' style={{  width: '27.5%', padding: '0px 10px 10px 10px',fontWeight:"bold"  }}>Rs.</ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{  width: '2%', padding: '0px 10px 10px 10px',fontWeight:"bold"  }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{  width: '27.5%', padding: '0px 10px 10px 10px',fontWeight:"bold"  }}>Rs.</ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '4%' }}>2.20.1</div>
                                        <div className='align-items-center' style={{ width: '35%', padding: '0px 10px 10px 10px' }}>Current assets (including investments other than trade investments and investments in subsidiary Companies</div>
                                        <ScrollWrapper className='align-items-center' style={{  width: '27.5%', padding: '0px 10px 10px 10px',fontWeight:"bold", border: '1px solid #121212',  }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{  width: '2%', padding: '0px 10px 10px 10px',fontWeight:"bold"  }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{  width: '27.5%', padding: '0px 10px 10px 10px',fontWeight:"bold", border: '1px solid #121212',  }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '4%' }}>2.20.2</div>
                                        <div className='align-items-center' style={{ width: '35%', padding: '0px 10px 10px 10px' }}> Less</div>
                                        <ScrollWrapper className='align-items-center' style={{  width: '27.5%', padding: '0px 10px 10px 10px',fontWeight:"bold", border: '1px solid #121212',  }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{  width: '2%', padding: '0px 10px 10px 10px',fontWeight:"bold"  }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{  width: '27.5%', padding: '0px 10px 10px 10px',fontWeight:"bold", border: '1px solid #121212',  }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '4%' }}>2.20.3</div>
                                        <div className='align-items-center' style={{ width: '35%', padding: '0px 10px 10px 10px' }}> Current liabilities (including short-term loans liabilities).</div>
                                        <ScrollWrapper className='align-items-center' style={{  width: '27.5%', padding: '0px 10px 10px 10px',fontWeight:"bold", border: '1px solid #121212',  }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{  width: '2%', padding: '0px 10px 10px 10px',fontWeight:"bold"  }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{  width: '27.5%', padding: '0px 10px 10px 10px',fontWeight:"bold", border: '1px solid #121212',  }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '4%' }}>2.20.4</div>
                                        <div className='align-items-center' style={{ width: '35%', padding: '0px 10px 10px 10px',fontWeight:"bold" }}> Liquid Surplus</div>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '4%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '34%' }}>Add____________</div>
                                        <ScrollWrapper className='align-items-center' style={{  width: '27.5%', padding: '0px 10px 10px 10px',fontWeight:"bold", borderBottom: '1px solid #121212',  }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{  width: '2%', padding: '0px 10px 10px 10px',fontWeight:"bold"  }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{  width: '27.5%', padding: '0px 10px 10px 10px',fontWeight:"bold", borderBottom: '1px solid #121212',  }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '4%' }}>2.20.5</div>
                                        <div className='align-items-center' style={{ width: '35%', padding: '0px 10px 10px 10px' }}>Fixed Assets</div>
                                        <ScrollWrapper className='align-items-center' style={{  width: '27.5%', padding: '0px 10px 10px 10px',fontWeight:"bold", border: '1px solid #121212',  }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{  width: '2%', padding: '0px 10px 10px 10px',fontWeight:"bold"  }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{  width: '27.5%', padding: '0px 10px 10px 10px',fontWeight:"bold", border: '1px solid #121212',  }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '4%' }}>2.20.6</div>
                                        <div className='align-items-center' style={{ width: '35%', padding: '0px 10px 10px 10px' }}> Trade investments and investments in subsidiary Companies</div>
                                        <ScrollWrapper className='align-items-center' style={{  width: '27.5%', padding: '0px 10px 10px 10px',fontWeight:"bold", border: '1px solid #121212',  }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{  width: '2%', padding: '0px 10px 10px 10px',fontWeight:"bold"  }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{  width: '27.5%', padding: '0px 10px 10px 10px',fontWeight:"bold", border: '1px solid #121212',  }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ width: '35%', padding: '0px 10px 10px 10px',fontWeight:"bold" }}>Less</div>
                                        <ScrollWrapper className='align-items-center' style={{  width: '27.5%', padding: '0px 10px 10px 10px',fontWeight:"bold", }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{  width: '2%', padding: '0px 10px 10px 10px',fontWeight:"bold"  }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{  width: '27.5%', padding: '0px 10px 10px 10px',fontWeight:"bold",}}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '4%' }}>20.20.7</div>
                                        <div className='align-items-center' style={{ width: '35%', padding: '0px 10px 10px 10px',fontWeight:"bold" }}> Long term loans and liabilities</div>
                                        <ScrollWrapper className='align-items-center' style={{  width: '27.5%', padding: '0px 10px 10px 10px',fontWeight:"bold", }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{  width: '2%', padding: '0px 10px 10px 10px',fontWeight:"bold"  }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{  width: '27.5%', padding: '0px 10px 10px 10px',fontWeight:"bold",}}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '4%' }}>20.20.7</div>
                                        <div className='align-items-center' style={{ width: '35%', padding: '0px 10px 10px 10px',}}> Net worth as on ____________ <br></br>(date of statement of financial position)</div>
                                        <ScrollWrapper className='align-items-center' style={{  width: '27.5%', padding: '0px 10px 10px 10px',fontWeight:"bold", }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{  width: '2%', padding: '0px 10px 10px 10px',fontWeight:"bold"  }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{  width: '27.5%', padding: '0px 10px 10px 10px',fontWeight:"bold",}}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>20.20.9</div>
                                        <div className='align-items-center' style={{ padding: '0px 10px 10px 10px',}}>Note: In making the above computation of net worth, adjustment in respect of the following items shall be made:-</div>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '4%' }}>20.20.10</div>
                                        <div className='align-items-center' style={{ width: '35%', padding: '0px 10px 10px 10px',}}>  Intangible assets, e.g., goodwill, etc.</div>
                                        <ScrollWrapper className='align-items-center' style={{  width: '27.5%', padding: '0px 10px 10px 10px',fontWeight:"bold", }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{  width: '2%', padding: '0px 10px 10px 10px',fontWeight:"bold"  }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{  width: '27.5%', padding: '0px 10px 10px 10px',fontWeight:"bold",}}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '4%' }}>20.20.11</div>
                                        <div className='align-items-center' style={{ width: '35%', padding: '0px 10px 10px 10px',}}> Doubtful assets, e.g. doubtful and bade debts, etc.</div>
                                        <ScrollWrapper className='align-items-center' style={{  width: '27.5%', padding: '0px 10px 10px 10px',borderBottom:"1px solid grey" }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{  width: '2%', padding: '0px 10px 10px 10px'}}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{  width: '27.5%', padding: '0px 10px 10px 10px',borderBottom:"1px solid grey"}}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '4%' }}>20.20.12</div>
                                        <div className='align-items-center' style={{ width: '35%', padding: '0px 10px 10px 10px',}}> Deferred revenue expenditure</div>
                                        <ScrollWrapper className='align-items-center' style={{  width: '27.5%', padding: '0px 10px 10px 10px',borderBottom:"1px solid grey" }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{  width: '2%', padding: '0px 10px 10px 10px'}}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{  width: '27.5%', padding: '0px 10px 10px 10px',borderBottom:"1px solid grey"}}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '4%' }}>20.20.13</div>
                                        <div className='align-items-center' style={{ width: '35%', padding: '0px 10px 10px 10px',}}> Accumulated losses </div>
                                        <ScrollWrapper className='align-items-center' style={{  width: '27.5%', padding: '0px 10px 10px 10px',borderBottom:"1px solid grey" }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{  width: '2%', padding: '0px 10px 10px 10px'}}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{  width: '27.5%', padding: '0px 10px 10px 10px',borderBottom:"1px solid grey"}}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '4%' }}>20.20.14</div>
                                        <div className='align-items-center' style={{ width: '35%', padding: '0px 10px 10px 10px',}}> Arrears of depreciation</div>
                                        <ScrollWrapper className='align-items-center' style={{  width: '27.5%', padding: '0px 10px 10px 10px',borderBottom:"1px solid grey" }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{  width: '2%', padding: '0px 10px 10px 10px'}}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{  width: '27.5%', padding: '0px 10px 10px 10px',borderBottom:"1px solid grey"}}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '4%' }}>20.20.15</div>
                                        <div className='align-items-center' style={{ width: '35%', padding: '0px 10px 10px 10px',}}>Any other amount, appearing in the statement of financial position required to be deducted in accordance with accounting practices.</div>
                                        <ScrollWrapper className='align-items-center' style={{  width: '27.5%', padding: '0px 10px 10px 10px',borderBottom:"1px solid grey" }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{  width: '2%', padding: '0px 10px 10px 10px'}}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{  width: '27.5%', padding: '0px 10px 10px 10px',borderBottom:"1px solid grey"}}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ width: '36%', padding: '0px 10px 10px 10px',}}></div>
                                        <ScrollWrapper className='align-items-center' style={{  width: '27.5%', padding: '0px 10px 10px 10px',borderBottom:"1px solid grey" }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{  width: '2%', padding: '0px 10px 10px 10px'}}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{  width: '27.5%', padding: '0px 10px 10px 10px',borderBottom:"1px solid grey"}}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ width: '36%', padding: '0px 10px 10px 10px',}}></div>
                                        <ScrollWrapper className='align-items-center' style={{  width: '5%', padding: '0px 10px 10px 10px', }}>Total</ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{  width: '22.5%', padding: '0px 10px 10px 10px',borderBottom:"1px solid grey" }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{  width: '2%', padding: '0px 10px 10px 10px'}}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{  width: '27.5%', padding: '0px 10px 10px 10px',borderBottom:"1px solid grey"}}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '4%' }}>2.21</div>
                                        <div className='align-items-center' style={{ width: '35%', padding: '0px 10px 10px 10px' }}> RECONCILIATION OF NET WORTH</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '57%', padding: '0px 10px 10px 10px',  }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ width: '36%', padding: '0px 10px 10px 10px',}}></div>
                                        <ScrollWrapper className='align-items-center' style={{  width: '27.5%', padding: '0px 10px 10px 10px',textAlign:"center" }}>Rs.</ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{  width: '2%', padding: '0px 10px 10px 10px'}}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{  width: '27.5%', padding: '0px 10px 10px 10px',textAlign:"center"}}>Rs.</ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '4%' }}>2.21.1</div>
                                        <div className='align-items-center' style={{ width: '35%', padding: '0px 10px 10px 10px',}}>Paid up Capital</div>
                                        <ScrollWrapper className='align-items-center' style={{  width: '27.5%', padding: '0px 10px 10px 10px',textAlign:"center" }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{  width: '2%', padding: '0px 10px 10px 10px'}}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{  width: '27.5%', padding: '0px 10px 10px 10px',textAlign:"center"}}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '5%' }}></div>
                                        <div className='align-items-center' style={{ width: '36%', padding: '0px 10px 10px 10px',}}>Add___________</div>
                                        <ScrollWrapper className='align-items-center' style={{  width: '27.5%', padding: '0px 10px 10px 10px',textAlign:"center" }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{  width: '2%', padding: '0px 10px 10px 10px'}}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{  width: '27.5%', padding: '0px 10px 10px 10px',textAlign:"center"}}></ScrollWrapper>
                                    </div>

                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '4%' }}>2.21.2</div>
                                        <div className='align-items-center' style={{ width: '35%', padding: '0px 10px 10px 10px',}}>Reserves (Please specify details)</div>
                                        <ScrollWrapper className='align-items-center' style={{  width: '27.5%', padding: '0px 10px 10px 10px',textAlign:"center" }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{  width: '2%', padding: '0px 10px 10px 10px'}}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{  width: '27.5%', padding: '0px 10px 10px 10px',textAlign:"center"}}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '4%' }}>2.21.3</div>
                                        <div className='align-items-center' style={{ width: '35%', padding: '0px 10px 10px 10px',}}>Less_______________</div>
                                        <ScrollWrapper className='align-items-center' style={{  width: '27.5%', padding: '0px 10px 10px 10px',textAlign:"center" }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{  width: '2%', padding: '0px 10px 10px 10px'}}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{  width: '27.5%', padding: '0px 10px 10px 10px',textAlign:"center"}}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '4%' }}>2.21.4</div>
                                        <div className='align-items-center' style={{ width: '35%', padding: '0px 10px 10px 10px',}}>Intangible assets and any other amount required to be deducted (vide note above) </div>
                                        <ScrollWrapper className='align-items-center' style={{  width: '27.5%', padding: '0px 10px 10px 10px',textAlign:"center",borderBottom:"1px solid grey",borderStyle:"dashed" }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{  width: '2%', padding: '0px 10px 10px 10px'}}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{  width: '27.5%', padding: '0px 10px 10px 10px',textAlign:"center",borderBottom:"1px solid grey",borderStyle:"dashed"}}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '4%' }}>2.21.5</div>
                                        <div className='align-items-center' style={{ width: '35%', padding: '0px 10px 10px 10px',}}> Net worth as on................</div>
                                        <ScrollWrapper className='align-items-center' style={{  width: '27.5%', padding: '0px 10px 10px 10px',textAlign:"center",borderBottom:"1px solid grey",borderStyle:"dashed" }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{  width: '2%', padding: '0px 10px 10px 10px'}}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{  width: '27.5%', padding: '0px 10px 10px 10px',textAlign:"center",borderBottom:"1px solid grey",borderStyle:"dashed"}}></ScrollWrapper>
                                    </div>
                                    <PartsWrapper className='mt-4 mb-3'>
                                        Part - III
                                    </PartsWrapper>

                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>3.1</div>
                                        <div className='align-items-center' style={{ padding: ' 5px 10px' }}>Declaration</div>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}></div>
                                        <div className='align-items-center' style={{ padding: ' 5px 10px' }}>I do hereby solemnly and sincerely declare that the information provided in the form is:</div>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '2%' }}></div>
                                        <div className='align-items-center d-flex' style={{ padding: ' 5px 10px', gap: "12px" }}><div>
                                            (i)</div>
                                            <div>true and correct to the best of my knowledge, in consonance with the record as maintained by the Company and nothing has been concealed; and</div>
                                        </div>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '2%' }}></div>
                                        <div className='align-items-center d-flex' style={{ padding: ' 5px 10px', gap: "12px" }}><div>
                                            (ii)</div>
                                            <div>hereby reported after complying with and fulfilling all requirements under the relevant provisions of law, rules, regulations, directives, circulars and notifications whichever is applicable. </div>
                                        </div>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '2%' }}></div>
                                        <div className='align-items-center d-flex' style={{ padding: ' 5px 10px', gap: "12px" }}><div>
                                            (iii)</div>
                                            <div>The special resolution has been filed with the registrar on the prescribed form</div>
                                        </div>
                                    </div>

                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>3.2</div>
                                        <div className='align-items-center' style={{ width: '28%', padding: ' 5px 10px' }}> Name of Authorized Officer with designation/ Authorized Intermediary, if any</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '32.5%', padding: ' 5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', borderLeft: "none", width: '32.5%', padding: ' 5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>3.3</div>
                                        <div className='align-items-center' style={{ width: '28%', padding: ' 5px 10px' }}>Signatures </div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '65%', padding: ' 5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>3.4</div>
                                        <div className='align-items-center' style={{ width: '40%', padding: ' 5px 10px' }}>Registration No of Authorized Intermediary, if applicable</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '53%', padding: ' 5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}></div>
                                        <div className='align-items-center' style={{ width: '55%', padding: ' 5px 10px' }}> </div>
                                        <div className='align-items-center text-center' style={{ width: '8%', padding: ' 5px 10px' }}> Day</div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center text-center' style={{ width: '8%', padding: ' 5px 10px' }}> Month</div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center text-center' style={{ width: '16%', padding: ' 5px 10px' }}>Year </div>
                                        <div></div>
                                    </div>
                                    <div className='d-flex '>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>5.5</div>
                                        <div className='align-items-center' style={{ width: '55%', padding: ' 5px 10px' }}>Date</div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ border: '1px solid #121212', width: '4%', padding: ' 5px 10px' }}> </div>
                                        <div className='align-items-center' style={{ paddingLeft: '8px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ border: '1px solid #121212', width: '4%', padding: ' 5px 10px', borderRight: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ paddingLeft: '8px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', border: '1px solid #121212', }}> </div>
                                    </div>
                                    <div className='d-flex my-2' style={{fontWeight:"bold"}}>
                                        Enclosure
                                    </div>
                                    <div className='d-flex align-items-baseline'>
                                    <div style={{marginLeft:"5px"}}>1.</div><div style={{marginLeft:'23px'}}> A certificate from the Auditor of the transferee Company that the information contained in the offer/ recommendation is correct</div>
                                    </div>
                                    <div className='d-flex'>
                                    <div style={{marginLeft:"5px"}}>2.</div><div style={{marginLeft:'20px'}}> Original challan or other evidence of payment of fee specified in Seventh Schedule of the Act (not applicable in case of online filing)</div>
                                    </div>
                                    <div className='d-flex'>
                                    <div style={{marginLeft:"5px"}}>3.</div><div style={{marginLeft:'20px'}}> Any other document as deemed necessary.</div>
                                    </div>
                                

                                </div>
                            </div>
                        </div>
                    </PDFExport>
                    : <PDFExport
                        paperSize="A4"
                        margin="1cm"
                        scale={0.6}
                        fileName={`Form 23`}
                        pageTemplate={PageTemplate}
                        ref={pdfExportComponent}
                    >
                        <div style={{ fontFamily: `${fontFamilyForStaticData}`, fontSize: `${fontSizeForStaticData}`, fontWeight: 500 }}>
                            <FormName>
                                Form-23
                            </FormName>
                            <div className='row'>
                                <div className='col-md-12' >
                                    <HeadingWrapper className='d-flex justify-content-center'>
                                        THE COMPANIES ACT, 2017
                                    </HeadingWrapper>

                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <HeadingWrapper className='d-flex justify-content-center'>
                                        THE COMPANIES REGULATIONS, 2024
                                    </HeadingWrapper>

                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <div style={{ fontSize: '15px', fontWeight: 'bold' }} className='d-flex justify-content-center'>
                                        [Section 285(5) and Regulation 59 & 30]
                                    </div>
                                </div>
                            </div>

                            <div className='row mt-4'>
                                <div className='col-md-12'>
                                    <HeadingWrapper className='text-center d-flex justify-content-center'>
                                        INFORMATION TO BE FURNISHED IN RELATION TO ANY OFFER OF A SCHEME OR CONTRACT INVOLVING THE TRANSFER OF SHARES OR ANY CLASS OF SHARES IN THE TRANSFEROR COMPANY TO THE TRANSFEREE COMPANY
                                    </HeadingWrapper>
                                </div>
                            </div>
                            <PartsWrapper className='mt-4 mb-3'>
                                PART-I
                            </PartsWrapper>
                            <div className='row'>
                                <div className='col-md-12' style={{ padding: '25px 50px', wordWrap: 'break-word' }}>
                                    <div> <i>(Please complete in bold capital letters)</i></div>


                                    <div className='d-flex' >
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>1.1</div>
                                        <div className='align-items-center' style={{ width: '28%', padding: '0px 10px 10px 10px' }}>CUIN (Registration Number)  </div>
                                        <div className='align-items-center  d-flex'  >{
                                            '1234 552'.split("")?.map((item, idx) => {
                                                if (item == ' ') return
                                                return <div style={{ minWidth: '30px', minHeight: '34px', border: '1px solid #121212', borderLeft: idx != 0 ? "none" : "1px solid #121212", padding: '6px 9px' }}></div>
                                            })
                                        }
                                        </div>
                                    </div>



                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>1.2</div>
                                        <div className='align-items-center' style={{ width: '22%', padding: '0px 10px 10px 10px' }}>Name of the Company  </div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '71%', padding: '0px 10px 10px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                    </div>




                                    <div className='d-flex  mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>1.3</div>
                                        <div className='align-items-center' style={{ width: '20.20%', padding: '0px 10px 10px 10px' }}>Fee payment Details*</div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '5%' }}>1.3.1</div>
                                        <div className='align-items-center' style={{ width: '13.20%', paddingLeft: '20px' }}>Challan No.</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '17.20%', paddingLeft: '20px', minHeight: '34px' }}></ScrollWrapper>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '5%' }}>1.3.2</div>
                                        <div className='align-items-center' style={{ width: '13.20%', paddingLeft: '20px' }}>Amount</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '19.20%', paddingLeft: '20px', minHeight: '34px' }}></ScrollWrapper>
                                    </div>

                                    <PartsWrapper className='mt-4 mb-3'>
                                        PART-II
                                    </PartsWrapper>

                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>2.1</div>
                                        <div className='align-items-center' style={{ width: '36%', padding: '0px 10px 10px 10px' }}> Name of the transferee company making the offer or issuing any circular in connection with the offer.</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '57%', padding: '0px 10px 10px 10px',  }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>2.2</div>
                                        <div className='align-items-center' style={{ width: '36%', padding: '0px 10px 10px 10px' }}>Capital structure of the transferee Company;</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '57%', padding: '0px 10px 10px 10px',  }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>2.3</div>
                                        <div className='align-items-center' style={{ width: '36%', padding: '0px 10px 10px 10px' }}> Management structure of the transferee Company (composition of Board of Directors and particulars regarding Chief Executive).</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '57%', padding: '0px 10px 10px 10px',  }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>2.4</div>
                                        <div className='align-items-center' style={{ width: '36%', padding: '0px 10px 10px 10px' }}> Extent of shareholding of the Chief Executive, Directors, Secretary or their associates in the transferee Company</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '57%', padding: '0px 10px 10px 10px',  }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>2.5</div>
                                        <div className='align-items-center' style={{ width: '36%', padding: '0px 10px 10px 10px' }}> Extent of shareholding of the Chief Executive, Directors, Managing Agents (if any), Secretary and Chief Accountant or their associates of the transferee Company in the transferor Company.</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '57%', padding: '0px 10px 10px 10px',  }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>2.6</div>
                                        <div className='align-items-center' style={{ width: '36%', padding: '0px 10px 10px 10px' }}> If the offer is being made on behalf of the Company by any other person, the interest of the person in the Company which is making the offer</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '57%', padding: '0px 10px 10px 10px',  }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>2.7</div>
                                        <div className='align-items-center' style={{ width: '36%', padding: '0px 10px 10px 10px' }}> Name of the transferor Company</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '57%', padding: '0px 10px 10px 10px',  }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>2.8</div>
                                        <div className='align-items-center' style={{ width: '36%', padding: '0px 10px 10px 10px' }}>Capital structure of the transferor Company</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '57%', padding: '0px 10px 10px 10px',  }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>2.9</div>
                                        <div className='align-items-center' style={{ width: '36%', padding: '0px 10px 10px 10px' }}>Management structure (composition of Board of Directors, and particulars regarding Chief Executive).</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '57%', padding: '0px 10px 10px 10px',  }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>2.10</div>
                                        <div className='align-items-center' style={{ width: '36%', padding: '0px 10px 10px 10px' }}> Extent of shareholdings of the Chief Executive, Directors, Managing Agents (if any), Secretary and Chief Accountant or their associates, if any, of the transferor Company in the transferor and transferee Companies</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '57%', padding: '0px 10px 10px 10px',  }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>2.11</div>
                                        <div className='align-items-center' style={{ padding: '0px 10px 10px 10px' }}> A summary of the financial position of the transferee and transferor Companies to be given separately in the pro forma contained in the Appendix</div>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>2.12</div>
                                        <div className='align-items-center' style={{ width: '36%', padding: '0px 10px 10px 10px' }}> The prices offered for the purchase of the shares of the transferor company; or</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '57%', padding: '0px 10px 10px 10px',  }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>2.13</div>
                                        <div className='align-items-center' style={{ width: '36%', padding: '0px 10px 10px 10px' }}> Full particulars of the consideration offered other than cash for the purchase of shares of the transferor company.</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '57%', padding: '0px 10px 10px 10px',  }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>2.14</div>
                                        <div className='align-items-center' style={{ padding: '0px 10px 10px 10px' }}> If the consideration offered involved allotment of shares in transferee company, then specify full particulars of; </div>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '4%' }}>2.14.1</div>
                                        <div className='align-items-center' style={{ width: '35%', padding: '0px 10px 10px 10px' }}> shares; </div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '57%', padding: '0px 10px 10px 10px',  }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '4%' }}>2.14.2</div>
                                        <div className='align-items-center' style={{ width: '35%', padding: '0px 10px 10px 10px' }}>the right attached thereto;</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '57%', padding: '0px 10px 10px 10px',  }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '4%' }}>2.14.3</div>
                                        <div className='align-items-center' style={{ width: '35%', padding: '0px 10px 10px 10px' }}> basis of valuation of shares of transferor company</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '57%', padding: '0px 10px 10px 10px',  }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '4%' }}>2.14.4</div>
                                        <div className='align-items-center' style={{ width: '35%', padding: '0px 10px 10px 10px' }}> valuation of the shares of transferee &nbsp;&nbsp;&nbsp;&nbsp;company proposed to be allotted. </div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '57%', padding: '0px 10px 10px 10px',  }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '4%' }}>2.15</div>
                                        <div className='align-items-center' style={{ width: '35%', padding: '0px 10px 10px 10px' }}>Sources from which the transferee Company proposes to pay for the acquisition of the said shares, if the consideration is cash.</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '57%', padding: '0px 10px 10px 10px',  }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '4%' }}>2.16</div>
                                        <div className='align-items-center' style={{ width: '35%', padding: '0px 10px 10px 10px' }}> Details of transfers of shares in the transferor Company by its Chief Executive, Directors, Managing Agents (if any), Secretary and Chief Accountant or their associates in the two years preceding the offer.</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '57%', padding: '0px 10px 10px 10px',  }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '4%' }}>2.17</div>
                                        <div className='align-items-center' style={{ width: '35%', padding: '0px 10px 10px 10px' }}> Reasons for which the offer has been recommended for acceptance by the members of the transferor Company by its Directors. Every recommendation to the members of the transferor Company shall also contain a statement of the interest of its Chief Executive, Directors, Managing Agents (if any), Secretary and Chief Accountant or their associates in the transferee Company. </div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '57%', padding: '0px 10px 10px 10px',  }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>2.18</div>
                                        <div className='align-items-center' style={{ padding: '0px 10px 10px 10px' }}>The transferee company has taken necessary steps to ensure the availability of cash for the purpose of clause (b) of sub-section (5) of section 285;</div>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>2.19</div>
                                        <div className='align-items-center' style={{ padding: '0px 10px 10px 10px' }}>A certificate from the Auditor of the transferee Company that the information contained in the offer/ recommendation is correct is attached</div>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>2.20</div>
                                        <div className='align-items-center' style={{ padding: '0px 10px 10px 10px' }}> Financial liquidity position of the Company according to the latest audited balance sheet.</div>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ width: '36%', padding: '0px 10px 10px 10px' }}></div>
                                        <ScrollWrapper className='align-items-center' style={{  width: '27.5%', padding: '0px 10px 10px 10px',fontWeight:"bold"  }}>Rs.</ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{  width: '2%', padding: '0px 10px 10px 10px',fontWeight:"bold"  }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{  width: '27.5%', padding: '0px 10px 10px 10px',fontWeight:"bold"  }}>Rs.</ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '4%' }}>2.20.1</div>
                                        <div className='align-items-center' style={{ width: '35%', padding: '0px 10px 10px 10px' }}>Current assets (including investments other than trade investments and investments in subsidiary Companies</div>
                                        <ScrollWrapper className='align-items-center' style={{  width: '27.5%', padding: '0px 10px 10px 10px',fontWeight:"bold", border: '1px solid #121212',  }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{  width: '2%', padding: '0px 10px 10px 10px',fontWeight:"bold"  }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{  width: '27.5%', padding: '0px 10px 10px 10px',fontWeight:"bold", border: '1px solid #121212',  }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '4%' }}>2.20.2</div>
                                        <div className='align-items-center' style={{ width: '35%', padding: '0px 10px 10px 10px' }}> Less</div>
                                        <ScrollWrapper className='align-items-center' style={{  width: '27.5%', padding: '0px 10px 10px 10px',fontWeight:"bold", border: '1px solid #121212',  }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{  width: '2%', padding: '0px 10px 10px 10px',fontWeight:"bold"  }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{  width: '27.5%', padding: '0px 10px 10px 10px',fontWeight:"bold", border: '1px solid #121212',  }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '4%' }}>2.20.3</div>
                                        <div className='align-items-center' style={{ width: '35%', padding: '0px 10px 10px 10px' }}> Current liabilities (including short-term loans liabilities).</div>
                                        <ScrollWrapper className='align-items-center' style={{  width: '27.5%', padding: '0px 10px 10px 10px',fontWeight:"bold", border: '1px solid #121212',  }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{  width: '2%', padding: '0px 10px 10px 10px',fontWeight:"bold"  }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{  width: '27.5%', padding: '0px 10px 10px 10px',fontWeight:"bold", border: '1px solid #121212',  }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '4%' }}>2.20.4</div>
                                        <div className='align-items-center' style={{ width: '35%', padding: '0px 10px 10px 10px',fontWeight:"bold" }}> Liquid Surplus</div>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '4%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '34%' }}>Add____________</div>
                                        <ScrollWrapper className='align-items-center' style={{  width: '27.5%', padding: '0px 10px 10px 10px',fontWeight:"bold", borderBottom: '1px solid #121212',  }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{  width: '2%', padding: '0px 10px 10px 10px',fontWeight:"bold"  }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{  width: '27.5%', padding: '0px 10px 10px 10px',fontWeight:"bold", borderBottom: '1px solid #121212',  }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '4%' }}>2.20.5</div>
                                        <div className='align-items-center' style={{ width: '35%', padding: '0px 10px 10px 10px' }}>Fixed Assets</div>
                                        <ScrollWrapper className='align-items-center' style={{  width: '27.5%', padding: '0px 10px 10px 10px',fontWeight:"bold", border: '1px solid #121212',  }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{  width: '2%', padding: '0px 10px 10px 10px',fontWeight:"bold"  }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{  width: '27.5%', padding: '0px 10px 10px 10px',fontWeight:"bold", border: '1px solid #121212',  }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '4%' }}>2.20.6</div>
                                        <div className='align-items-center' style={{ width: '35%', padding: '0px 10px 10px 10px' }}> Trade investments and investments in subsidiary Companies</div>
                                        <ScrollWrapper className='align-items-center' style={{  width: '27.5%', padding: '0px 10px 10px 10px',fontWeight:"bold", border: '1px solid #121212',  }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{  width: '2%', padding: '0px 10px 10px 10px',fontWeight:"bold"  }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{  width: '27.5%', padding: '0px 10px 10px 10px',fontWeight:"bold", border: '1px solid #121212',  }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ width: '35%', padding: '0px 10px 10px 10px',fontWeight:"bold" }}>Less</div>
                                        <ScrollWrapper className='align-items-center' style={{  width: '27.5%', padding: '0px 10px 10px 10px',fontWeight:"bold", }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{  width: '2%', padding: '0px 10px 10px 10px',fontWeight:"bold"  }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{  width: '27.5%', padding: '0px 10px 10px 10px',fontWeight:"bold",}}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '4%' }}>20.20.7</div>
                                        <div className='align-items-center' style={{ width: '35%', padding: '0px 10px 10px 10px',fontWeight:"bold" }}> Long term loans and liabilities</div>
                                        <ScrollWrapper className='align-items-center' style={{  width: '27.5%', padding: '0px 10px 10px 10px',fontWeight:"bold", }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{  width: '2%', padding: '0px 10px 10px 10px',fontWeight:"bold"  }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{  width: '27.5%', padding: '0px 10px 10px 10px',fontWeight:"bold",}}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '4%' }}>20.20.7</div>
                                        <div className='align-items-center' style={{ width: '35%', padding: '0px 10px 10px 10px',}}> Net worth as on ____________ <br></br>(date of statement of financial position)</div>
                                        <ScrollWrapper className='align-items-center' style={{  width: '27.5%', padding: '0px 10px 10px 10px',fontWeight:"bold", }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{  width: '2%', padding: '0px 10px 10px 10px',fontWeight:"bold"  }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{  width: '27.5%', padding: '0px 10px 10px 10px',fontWeight:"bold",}}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>20.20.9</div>
                                        <div className='align-items-center' style={{ padding: '0px 10px 10px 10px',}}>Note: In making the above computation of net worth, adjustment in respect of the following items shall be made:-</div>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '4%' }}>20.20.10</div>
                                        <div className='align-items-center' style={{ width: '35%', padding: '0px 10px 10px 10px',}}>  Intangible assets, e.g., goodwill, etc.</div>
                                        <ScrollWrapper className='align-items-center' style={{  width: '27.5%', padding: '0px 10px 10px 10px',fontWeight:"bold", }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{  width: '2%', padding: '0px 10px 10px 10px',fontWeight:"bold"  }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{  width: '27.5%', padding: '0px 10px 10px 10px',fontWeight:"bold",}}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '4%' }}>20.20.11</div>
                                        <div className='align-items-center' style={{ width: '35%', padding: '0px 10px 10px 10px',}}> Doubtful assets, e.g. doubtful and bade debts, etc.</div>
                                        <ScrollWrapper className='align-items-center' style={{  width: '27.5%', padding: '0px 10px 10px 10px',borderBottom:"1px solid grey" }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{  width: '2%', padding: '0px 10px 10px 10px'}}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{  width: '27.5%', padding: '0px 10px 10px 10px',borderBottom:"1px solid grey"}}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '4%' }}>20.20.12</div>
                                        <div className='align-items-center' style={{ width: '35%', padding: '0px 10px 10px 10px',}}> Deferred revenue expenditure</div>
                                        <ScrollWrapper className='align-items-center' style={{  width: '27.5%', padding: '0px 10px 10px 10px',borderBottom:"1px solid grey" }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{  width: '2%', padding: '0px 10px 10px 10px'}}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{  width: '27.5%', padding: '0px 10px 10px 10px',borderBottom:"1px solid grey"}}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '4%' }}>20.20.13</div>
                                        <div className='align-items-center' style={{ width: '35%', padding: '0px 10px 10px 10px',}}> Accumulated losses </div>
                                        <ScrollWrapper className='align-items-center' style={{  width: '27.5%', padding: '0px 10px 10px 10px',borderBottom:"1px solid grey" }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{  width: '2%', padding: '0px 10px 10px 10px'}}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{  width: '27.5%', padding: '0px 10px 10px 10px',borderBottom:"1px solid grey"}}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '4%' }}>20.20.14</div>
                                        <div className='align-items-center' style={{ width: '35%', padding: '0px 10px 10px 10px',}}> Arrears of depreciation</div>
                                        <ScrollWrapper className='align-items-center' style={{  width: '27.5%', padding: '0px 10px 10px 10px',borderBottom:"1px solid grey" }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{  width: '2%', padding: '0px 10px 10px 10px'}}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{  width: '27.5%', padding: '0px 10px 10px 10px',borderBottom:"1px solid grey"}}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '4%' }}>20.20.15</div>
                                        <div className='align-items-center' style={{ width: '35%', padding: '0px 10px 10px 10px',}}>Any other amount, appearing in the statement of financial position required to be deducted in accordance with accounting practices.</div>
                                        <ScrollWrapper className='align-items-center' style={{  width: '27.5%', padding: '0px 10px 10px 10px',borderBottom:"1px solid grey" }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{  width: '2%', padding: '0px 10px 10px 10px'}}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{  width: '27.5%', padding: '0px 10px 10px 10px',borderBottom:"1px solid grey"}}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ width: '36%', padding: '0px 10px 10px 10px',}}></div>
                                        <ScrollWrapper className='align-items-center' style={{  width: '27.5%', padding: '0px 10px 10px 10px',borderBottom:"1px solid grey" }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{  width: '2%', padding: '0px 10px 10px 10px'}}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{  width: '27.5%', padding: '0px 10px 10px 10px',borderBottom:"1px solid grey"}}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ width: '36%', padding: '0px 10px 10px 10px',}}></div>
                                        <ScrollWrapper className='align-items-center' style={{  width: '5%', padding: '0px 10px 10px 10px', }}>Total</ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{  width: '22.5%', padding: '0px 10px 10px 10px',borderBottom:"1px solid grey" }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{  width: '2%', padding: '0px 10px 10px 10px'}}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{  width: '27.5%', padding: '0px 10px 10px 10px',borderBottom:"1px solid grey"}}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '4%' }}>2.21</div>
                                        <div className='align-items-center' style={{ width: '35%', padding: '0px 10px 10px 10px' }}> RECONCILIATION OF NET WORTH</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '57%', padding: '0px 10px 10px 10px',  }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ width: '36%', padding: '0px 10px 10px 10px',}}></div>
                                        <ScrollWrapper className='align-items-center' style={{  width: '27.5%', padding: '0px 10px 10px 10px',textAlign:"center" }}>Rs.</ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{  width: '2%', padding: '0px 10px 10px 10px'}}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{  width: '27.5%', padding: '0px 10px 10px 10px',textAlign:"center"}}>Rs.</ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '4%' }}>2.21.1</div>
                                        <div className='align-items-center' style={{ width: '35%', padding: '0px 10px 10px 10px',}}>Paid up Capital</div>
                                        <ScrollWrapper className='align-items-center' style={{  width: '27.5%', padding: '0px 10px 10px 10px',textAlign:"center" }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{  width: '2%', padding: '0px 10px 10px 10px'}}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{  width: '27.5%', padding: '0px 10px 10px 10px',textAlign:"center"}}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '5%' }}></div>
                                        <div className='align-items-center' style={{ width: '36%', padding: '0px 10px 10px 10px',}}>Add___________</div>
                                        <ScrollWrapper className='align-items-center' style={{  width: '27.5%', padding: '0px 10px 10px 10px',textAlign:"center" }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{  width: '2%', padding: '0px 10px 10px 10px'}}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{  width: '27.5%', padding: '0px 10px 10px 10px',textAlign:"center"}}></ScrollWrapper>
                                    </div>

                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '4%' }}>2.21.2</div>
                                        <div className='align-items-center' style={{ width: '35%', padding: '0px 10px 10px 10px',}}>Reserves (Please specify details)</div>
                                        <ScrollWrapper className='align-items-center' style={{  width: '27.5%', padding: '0px 10px 10px 10px',textAlign:"center" }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{  width: '2%', padding: '0px 10px 10px 10px'}}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{  width: '27.5%', padding: '0px 10px 10px 10px',textAlign:"center"}}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '4%' }}>2.21.3</div>
                                        <div className='align-items-center' style={{ width: '35%', padding: '0px 10px 10px 10px',}}>Less_______________</div>
                                        <ScrollWrapper className='align-items-center' style={{  width: '27.5%', padding: '0px 10px 10px 10px',textAlign:"center" }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{  width: '2%', padding: '0px 10px 10px 10px'}}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{  width: '27.5%', padding: '0px 10px 10px 10px',textAlign:"center"}}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '4%' }}>2.21.4</div>
                                        <div className='align-items-center' style={{ width: '35%', padding: '0px 10px 10px 10px',}}>Intangible assets and any other amount required to be deducted (vide note above) </div>
                                        <ScrollWrapper className='align-items-center' style={{  width: '27.5%', padding: '0px 10px 10px 10px',textAlign:"center",borderBottom:"1px solid grey",borderStyle:"dashed" }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{  width: '2%', padding: '0px 10px 10px 10px'}}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{  width: '27.5%', padding: '0px 10px 10px 10px',textAlign:"center",borderBottom:"1px solid grey",borderStyle:"dashed"}}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '4%' }}>2.21.5</div>
                                        <div className='align-items-center' style={{ width: '35%', padding: '0px 10px 10px 10px',}}> Net worth as on................</div>
                                        <ScrollWrapper className='align-items-center' style={{  width: '27.5%', padding: '0px 10px 10px 10px',textAlign:"center",borderBottom:"1px solid grey",borderStyle:"dashed" }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{  width: '2%', padding: '0px 10px 10px 10px'}}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{  width: '27.5%', padding: '0px 10px 10px 10px',textAlign:"center",borderBottom:"1px solid grey",borderStyle:"dashed"}}></ScrollWrapper>
                                    </div>
                                    <PartsWrapper className='mt-4 mb-3'>
                                        Part - III
                                    </PartsWrapper>

                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>3.1</div>
                                        <div className='align-items-center' style={{ padding: ' 5px 10px' }}>Declaration</div>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}></div>
                                        <div className='align-items-center' style={{ padding: ' 5px 10px' }}>I do hereby solemnly and sincerely declare that the information provided in the form is:</div>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '2%' }}></div>
                                        <div className='align-items-center d-flex' style={{ padding: ' 5px 10px', gap: "12px" }}><div>
                                            (i)</div>
                                            <div>true and correct to the best of my knowledge, in consonance with the record as maintained by the Company and nothing has been concealed; and</div>
                                        </div>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '2%' }}></div>
                                        <div className='align-items-center d-flex' style={{ padding: ' 5px 10px', gap: "12px" }}><div>
                                            (ii)</div>
                                            <div>hereby reported after complying with and fulfilling all requirements under the relevant provisions of law, rules, regulations, directives, circulars and notifications whichever is applicable. </div>
                                        </div>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '2%' }}></div>
                                        <div className='align-items-center d-flex' style={{ padding: ' 5px 10px', gap: "12px" }}><div>
                                            (iii)</div>
                                            <div>The special resolution has been filed with the registrar on the prescribed form</div>
                                        </div>
                                    </div>

                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>3.2</div>
                                        <div className='align-items-center' style={{ width: '28%', padding: ' 5px 10px' }}> Name of Authorized Officer with designation/ Authorized Intermediary, if any</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '32.5%', padding: ' 5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', borderLeft: "none", width: '32.5%', padding: ' 5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>3.3</div>
                                        <div className='align-items-center' style={{ width: '28%', padding: ' 5px 10px' }}>Signatures </div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '65%', padding: ' 5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>3.4</div>
                                        <div className='align-items-center' style={{ width: '40%', padding: ' 5px 10px' }}>Registration No of Authorized Intermediary, if applicable</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '53%', padding: ' 5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}></div>
                                        <div className='align-items-center' style={{ width: '55%', padding: ' 5px 10px' }}> </div>
                                        <div className='align-items-center text-center' style={{ width: '8%', padding: ' 5px 10px' }}> Day</div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center text-center' style={{ width: '8%', padding: ' 5px 10px' }}> Month</div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center text-center' style={{ width: '16%', padding: ' 5px 10px' }}>Year </div>
                                        <div></div>
                                    </div>
                                    <div className='d-flex '>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>5.5</div>
                                        <div className='align-items-center' style={{ width: '55%', padding: ' 5px 10px' }}>Date</div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ border: '1px solid #121212', width: '4%', padding: ' 5px 10px' }}> </div>
                                        <div className='align-items-center' style={{ paddingLeft: '8px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ border: '1px solid #121212', width: '4%', padding: ' 5px 10px', borderRight: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ paddingLeft: '8px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', border: '1px solid #121212', }}> </div>
                                    </div>
                                    <div className='d-flex my-2' style={{fontWeight:"bold"}}>
                                        Enclosure
                                    </div>
                                    <div className='d-flex align-items-baseline'>
                                    <div style={{marginLeft:"5px"}}>1.</div><div style={{marginLeft:'23px'}}> A certificate from the Auditor of the transferee Company that the information contained in the offer/ recommendation is correct</div>
                                    </div>
                                    <div className='d-flex'>
                                    <div style={{marginLeft:"5px"}}>2.</div><div style={{marginLeft:'20px'}}> Original challan or other evidence of payment of fee specified in Seventh Schedule of the Act (not applicable in case of online filing)</div>
                                    </div>
                                    <div className='d-flex'>
                                    <div style={{marginLeft:"5px"}}>3.</div><div style={{marginLeft:'20px'}}> Any other document as deemed necessary.</div>
                                    </div>
                                

                                </div>
                            </div>
                        </div>
                    </PDFExport>}
            </div>
            <button disabled={loading} className='btn btn-danger' onClick={(e) => {
                // if(viewPdf)
                //   SetViewPDf(true)
                if (pdfExportComponent.current) {
                    pdfExportComponent.current.save();
                }
            }}>Download PDF</button>
        </div>
    )
}
const PartsWrapper = styled.div`
color: #121212;
text-decoration: underline;
justify-content: center;
display: flex;
margin-right: 15px;
font-size: 17px;
font-weight: bold;
`;
const ScrollWrapper = styled.div`
max-height: 50px;
overflow-y: scroll;
overflow-x: hidden;
::-webkit-scrollbar{
    height: 5px;
    width: 3px;
  }
  
  ::-webkit-scrollbar-track{
    background: #F9F9FB;
  }
  ::-webkit-scrollbar-thumb{
    background: #4E515680;
    border-radius: 5px;
  
  }
`;
const HeadingWrapper = styled.div`
font-size: 17px;
font-weight: bold;
`;
const FormName = styled.div`
color: #121212;
text-decoration: underline;
justify-content: end;
display: flex;
margin-right: 15px;
font-size: 17px;
font-weight: bold;
`;
const Wrapper = styled.div`
table.table-bordered{
    border: 1px solid #121212
     border-color: #121212; 
    // border:1px solid blue;
    // margin-top:20px;
  }
// table.table-bordered > thead > tr > th{
//     // border:1px solid blue;
//     border-color: #121212; 
// }
// table.table-bordered > tbody > tr > td{
//     // border:1px solid blue;
//     borderC-color: #121212; 
// }
 tr td {
    // border:1px solid blue;.
    border-color: #121212; 
}

`;
const WrapperDashed = styled.div`

  td {
    
    // border: 1px dashed #dddcdc;
}

`;