import React, { useState, useEffect } from 'react'
import { PDFExport } from "@progress/kendo-react-pdf";
import PageTemplate from "./page-template";
import {
    getCompanies,
} from "../../../store/services/company.service";
import styled from 'styled-components';
import { TiTick } from "react-icons/ti";
import { numberWithCommas } from 'utilities/utilityFunctions';
export const StatutoryForm14 = ({ data, formTemplate = false }) => {
    const pdfExportComponent = React.useRef(null);
    const [CompanyData, setCompanyData] = useState(null)
    const [loading, setLoading] = useState(false);
    const [fontFamilyForStaticData, setFontFamilyForStaticData] = useState('Montserrat, sans-serif')
    const [fontSizeForStaticData, setFontSizeForStaticData] = useState('14px')
    const [fontfamilyforDynimicData, setFontFamilyForDynmicData] = useState('Segoe Print')
    const [fontColor, setFontColor] = useState('')
    const baseEmail = sessionStorage.getItem("email") || "";
    const [governance, setGovernance] = useState({})
    const getAllCompanies = async () => {
        setLoading(true)
        try {
            const response = await getCompanies(baseEmail);
            if (response.status === 200) {
                response.data.data = response.data.data.filter((item) => item?.active === 'Y')
                const parents = response.data.data;
                const company = parents?.find(ite => ite?.code == data?.company_code)
                let Allperson = company.governance && company.governance != "" ? JSON.parse(company.governance) : []
                let sec = Allperson.find(x => x.role == "Company Secretary" && x?.active?.toLowerCase() != 'n')
                let ceo = Allperson.find(x => x.role == "CEO" && x?.active?.toLowerCase() != 'n')


                if (sec) {
                    setGovernance(sec)
                } else if (ceo) {
                    setGovernance(ceo)
                }
                setCompanyData(company)
                setTimeout(() => {
                    setLoading(false)
                }, 1000);
            }
        } catch (error) {
            setLoading(false)
        }
    };
    useEffect(() => {
        getAllCompanies()
    }, [])
    return (
        <div>
            <div
                style={{ fontFamily: "sans-serif " }}
            >

                {formTemplate ?
                    <PDFExport
                        paperSize="A4"
                        margin="1cm"
                        scale={0.6}
                        fileName={`Form 14`}
                        pageTemplate={PageTemplate}
                        ref={pdfExportComponent}
                    >
                        <div style={{ fontFamily: `${fontFamilyForStaticData}`, fontSize: `${fontSizeForStaticData}`, fontWeight: 500 }}>
                            <FormName>
                                Form-14
                            </FormName>
                            <div className='row'>
                                <div className='col-md-12' >
                                    <HeadingWrapper className='d-flex justify-content-center'>
                                        THE COMPANIES ACT, 2017
                                    </HeadingWrapper>

                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <HeadingWrapper className='d-flex justify-content-center'>
                                        THE COMPANIES REGULATIONS, 2024
                                    </HeadingWrapper>

                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <div style={{
                                        fontSize: '15px',
                                        // fontWeight: 'bold' 
                                    }} className='d-flex justify-content-center'>
                                        [Section 60A and Regulations 43(1) & 30]
                                    </div>

                                </div>
                            </div>

                            <div className='row mt-4'>
                                <div className='col-md-12'>
                                    <HeadingWrapper className='text-center d-flex justify-content-center'>
                                        PUBLIC NOTICE TO HOLDERS OF SECURITIES OF BEARER NATURE ISSUED BY A COMPANY
                                    </HeadingWrapper>

                                </div>
                            </div>
                            <div className='row mt-4'>
                                <div className='col-md-12'>
                                    <HeadingWrapper className='text-center d-flex justify-content-center'>
                                        (Name of Company)
                                    </HeadingWrapper>
                                </div>
                            </div>

                            <div className='row'>
                                <div className='col-md-12' style={{ padding: '25px 50px', wordWrap: 'break-word' }}>
                                    <div className='mb-5 mt-1'>
                                    {`<<Insert Date>>`} 
                                    </div>
                                    <div>
                                        Take Notice that by virtue of section 60A of the Companies Act 2017, no company shall allot, issue,
                                        sell, transfer or assign any bearer share, bearer share warrant or any other equity or debt security of a
                                        bearer nature, by whatever name called.

                                    </div>
                                    <div className="mt-3">
                                        Sub-section 2 of section 60A of the Act requires that all existing bearer shares or bearer share warrants
                                        if any, shall either be registered or cancelled.

                                    </div>
                                    <div className="mt-3">
                                        {` Sub-regulation (2) of regulation 43 of the Regulations requires every holder of any securities of a 
                                    bearer nature issued by a <company name> to surrender it to the company for registration.`}

                                    </div>
                                    <div className="mt-3">
                                        {`Accordingly, every person who is a holder of such securities is advised to surrender the securities of 
a bearer nature issued by <company name> at < registered office address of the company >, for 
registration, before the expiration of three months of this notice. `}
                                    </div>
                                    <div className="mt-3">
                                        It is, therefore, in the interest of every bearer of such securities to present the securities for registration
                                        within the stipulated time period.
                                    </div>
                                    <div className='mt-5'>
                                    Name & Designation 
                                    </div>
                                    <div>
                                    (Person authorized to issue notice)  
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </PDFExport>
                    : <PDFExport
                        paperSize="A4"
                        margin="1cm"
                        scale={0.6}
                        fileName={`Form 14`}
                        pageTemplate={PageTemplate}
                        ref={pdfExportComponent}
                    >
                      <div style={{ fontFamily: `${fontFamilyForStaticData}`, fontSize: `${fontSizeForStaticData}`, fontWeight: 500 }}>
                            <FormName>
                                Form-14
                            </FormName>
                            <div className='row'>
                                <div className='col-md-12' >
                                    <HeadingWrapper className='d-flex justify-content-center'>
                                        THE COMPANIES ACT, 2017
                                    </HeadingWrapper>

                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <HeadingWrapper className='d-flex justify-content-center'>
                                        THE COMPANIES REGULATIONS, 2024
                                    </HeadingWrapper>

                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <div style={{
                                        fontSize: '15px',
                                        // fontWeight: 'bold' 
                                    }} className='d-flex justify-content-center'>
                                        [Section 60A and Regulations 43(1) & 30]
                                    </div>

                                </div>
                            </div>

                            <div className='row mt-4'>
                                <div className='col-md-12'>
                                    <HeadingWrapper className='text-center d-flex justify-content-center'>
                                        PUBLIC NOTICE TO HOLDERS OF SECURITIES OF BEARER NATURE ISSUED BY A COMPANY
                                    </HeadingWrapper>

                                </div>
                            </div>
                            <div className='row mt-4'>
                                <div className='col-md-12'>
                                    <HeadingWrapper className='text-center d-flex justify-content-center'>
                                        (Name of Company)
                                    </HeadingWrapper>
                                </div>
                            </div>

                            <div className='row'>
                                <div className='col-md-12' style={{ padding: '25px 50px', wordWrap: 'break-word' }}>
                                    <div className='mb-5 mt-1'>
                                    {`<<Insert Date>>`} 
                                    </div>
                                    <div>
                                        Take Notice that by virtue of section 60A of the Companies Act 2017, no company shall allot, issue,
                                        sell, transfer or assign any bearer share, bearer share warrant or any other equity or debt security of a
                                        bearer nature, by whatever name called.

                                    </div>
                                    <div className="mt-3">
                                        Sub-section 2 of section 60A of the Act requires that all existing bearer shares or bearer share warrants
                                        if any, shall either be registered or cancelled.

                                    </div>
                                    <div className="mt-3">
                                        {` Sub-regulation (2) of regulation 43 of the Regulations requires every holder of any securities of a 
                                    bearer nature issued by a <company name> to surrender it to the company for registration.`}

                                    </div>
                                    <div className="mt-3">
                                        {`Accordingly, every person who is a holder of such securities is advised to surrender the securities of 
a bearer nature issued by <company name> at < registered office address of the company >, for 
registration, before the expiration of three months of this notice. `}
                                    </div>
                                    <div className="mt-3">
                                        It is, therefore, in the interest of every bearer of such securities to present the securities for registration
                                        within the stipulated time period.
                                    </div>
                                    <div className='mt-5'>
                                    Name & Designation 
                                    </div>
                                    <div>
                                    (Person authorized to issue notice)  
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </PDFExport>}
            </div>
            <button disabled={loading} className='btn btn-danger' onClick={(e) => {
                // if(viewPdf)
                //   SetViewPDf(true)
                if (pdfExportComponent.current) {
                    pdfExportComponent.current.save();
                }
            }}>Download PDF</button>
        </div>
    )
}
const PartsWrapper = styled.div`
color: #121212;
text-decoration: underline;
justify-content: center;
display: flex;
margin-right: 15px;
font-size: 17px;
font-weight: bold;
`;
const ScrollWrapper = styled.div`
max-height: 50px;
overflow-y: scroll;
overflow-x: hidden;
::-webkit-scrollbar{
    height: 5px;
    width: 3px;
  }
  
  ::-webkit-scrollbar-track{
    background: #F9F9FB;
  }
  ::-webkit-scrollbar-thumb{
    background: #4E515680;
    border-radius: 5px;
  
  }
`;
const HeadingWrapper = styled.div`
font-size: 17px;
font-weight: bold;
`;
const FormName = styled.div`
color: #121212;
text-decoration: underline;
justify-content: end;
display: flex;
margin-right: 15px;
font-size: 17px;
font-weight: bold;
`;
const Wrapper = styled.div`
table.table-bordered{
    border: 1px solid #121212
     border-color: #121212; 
    // border:1px solid blue;
    // margin-top:20px;
  }
// table.table-bordered > thead > tr > th{
//     // border:1px solid blue;
//     border-color: #121212; 
// }
// table.table-bordered > tbody > tr > td{
//     // border:1px solid blue;
//     borderC-color: #121212; 
// }
 tr td {
    // border:1px solid blue;.
    border-color: #121212; 
}

`;
const WrapperDashed = styled.div`

  td {
    
    border: 1px dashed #dddcdc;
}

`;