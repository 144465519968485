import React, { useState, useEffect } from 'react'
import { PDFExport } from "@progress/kendo-react-pdf";
import PageTemplate from "./page-template";
import {
    getCompanies,
} from "../../../store/services/company.service";
import styled from 'styled-components';
import { TiTick } from "react-icons/ti";
import { numberWithCommas } from 'utilities/utilityFunctions';
export const StatutoryForm2 = ({ data, formTemplate = false }) => {
    const pdfExportComponent = React.useRef(null);
    const [CompanyData, setCompanyData] = useState(null)
    const [loading, setLoading] = useState(false);
    const [fontFamilyForStaticData, setFontFamilyForStaticData] = useState('Montserrat, sans-serif')
    const [fontSizeForStaticData, setFontSizeForStaticData] = useState('14px')
    const [fontfamilyforDynimicData, setFontFamilyForDynmicData] = useState('Segoe Print')
    const [fontColor, setFontColor] = useState('')
    const baseEmail = sessionStorage.getItem("email") || "";
    const [governance, setGovernance] = useState({})
    const getAllCompanies = async () => {
        setLoading(true)
        try {
            const response = await getCompanies(baseEmail);
            if (response.status === 200) {
                response.data.data = response.data.data.filter((item) => item?.active === 'Y')
                const parents = response.data.data;
                const company = parents?.find(ite => ite?.code == data?.company_code)
                let Allperson = company.governance && company.governance != "" ? JSON.parse(company.governance) : []
                let sec = Allperson.find(x => x.role == "Company Secretary" && x?.active?.toLowerCase() != 'n')
                let ceo = Allperson.find(x => x.role == "CEO" && x?.active?.toLowerCase() != 'n')


                if (sec) {
                    setGovernance(sec)
                } else if (ceo) {
                    setGovernance(ceo)
                }
                setCompanyData(company)
                setTimeout(() => {
                    setLoading(false)
                }, 1000);
            }
        } catch (error) {
            setLoading(false)
        }
    };
    useEffect(() => {
        getAllCompanies()
    }, [])
    return (
        <div>
            <div
                style={{ fontFamily: "sans-serif " }}
            >

                {formTemplate ?
                    <PDFExport
                        paperSize="A4"
                        margin="1cm"
                        scale={0.6}
                        fileName={`Statutory Form 2`}
                        pageTemplate={PageTemplate}
                        ref={pdfExportComponent}
                    >
                        <div style={{ fontFamily: `${fontFamilyForStaticData}`, fontSize: `${fontSizeForStaticData}`, fontWeight: 500 }}>
                            <FormName>
                                Form 2
                            </FormName>
                            <div className='row'>
                                <div className='col-md-12' >
                                    <HeadingWrapper className='d-flex justify-content-center'>
                                        THE COMPANIES ACT, 2017
                                    </HeadingWrapper>

                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <HeadingWrapper className='d-flex justify-content-center'>
                                        THE COMPANIES REGULATIONS, 2024
                                    </HeadingWrapper>

                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <div style={{
                                        fontSize: '15px',
                                        // fontWeight: 'bold' 
                                    }} className='d-flex justify-content-center'>
                                        [Section 435 and Regulations 21 & 30]
                                    </div>

                                </div>
                            </div>

                            <div className='row mt-4'>
                                <div className='col-md-12'>
                                    <HeadingWrapper className='d-flex justify-content-center'>
                                        REGISTRATION OF DOCUMENT OF A FOREIGN COMPANY
                                    </HeadingWrapper>

                                </div>
                            </div>
                            <PartsWrapper className='mt-4 mb-3'>
                                PART-I
                            </PartsWrapper>
                            <div className='row'>
                                <div className='col-md-12' style={{ padding: '25px 50px', wordWrap: 'break-word' }}>
                                    <div style={{

                                        paddingLeft: '11px'
                                    }}><i> (Please complete in bold capital letters)</i></div>


                                    {/* <div className='d-flex' >
                            <div className='align-items-center' style={{ border: '1px dashed #dddcdc', paddingLeft: '2px', width: '7%' }}>1.1</div>
                            <div className='align-items-center' style={{ border: '1px dashed #dddcdc', width: '28%', padding: ' 5px 10px' }}>Name of the Company</div>
                            <div className='align-items-center  d-flex'  >{
                                '1234 552'.split("")?.map((item, idx) => {
                                    if (item == ' ') return
                                    return <div style={{ minWidth: '30px', minHeight: '34px', marginLeft: '2px', border: '1px solid #121212', borderRadius: '3px', padding: '6px 9px' }}></div>
                                })
                            }
                            </div>
                        </div> */}


                                    <div className='d-flex mt-3'>
                                        <div className='align-items-center' style={{ padding: '0px 10px 10px 10px', }}>1.1</div>
                                        <div className='align-items-center' style={{ width: '30%', padding: ' 0px 10px 10px 10px' }}> Name of the Foreign Company in the country of origin as already reserved</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '79%', minHeight: '40px', padding: ' 5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                    </div>

                                    <div className='d-flex  mt-3'>
                                        <div className='align-items-center' style={{ padding: '0px 10px 10px 10px', }}>1.2</div>
                                        <div className='align-items-center' style={{ width: '17.2%', paddingLeft: '10px' }}>Fee Payment Details</div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '5%' }}>1.2.1</div>
                                        <div className='align-items-center' style={{ width: '8.2%', paddingLeft: '20px' }}>Challan No</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '26%', paddingLeft: '20px', minHeight: '34px' }}></ScrollWrapper>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '5%' }}>1.2.2</div>
                                        <div className='align-items-center' style={{ width: '13.20%', paddingLeft: '20px' }}>Amount(Rs.)</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '26%', paddingLeft: '20px', minHeight: '34px' }}></ScrollWrapper>
                                    </div>
                                    <PartsWrapper className='mt-4 mb-3'>
                                        PART-II
                                    </PartsWrapper>
                                    <div className='d-flex align-items-center mt-3'>
                                        <div style={{ padding: "5px 10px" }}>
                                            2.1
                                        </div>
                                        <div style={{ padding: "5px 10px", width: "48%" }}>
                                            Incorporated in: <i>(state name of the country of origin)</i>

                                        </div>
                                        <div style={{ padding: "5px 10px", width: "20%" }}>
                                        </div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '84%', minHeight: "45px", padding: '5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-0'>
                                        <div className='align-items-center' style={{ padding: "5px 10px", width: '7%' }}></div>
                                        <div className='align-items-center' style={{ width: '55%', padding: ' 5px 10px' }}> </div>
                                        <div className='align-items-center text-center' style={{ width: '8%', padding: ' 10px 10px 0px 10px' }}>dd</div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center text-center' style={{ width: '8%', padding: ' 10px 10px 0px 10px' }}>mm</div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center text-center ' style={{ width: '16%', padding: ' 10px 10px 0px 10px' }}>yyyy</div>
                                        <div></div>
                                    </div>
                                    <div className='d-flex '>
                                        <div className='align-items-center' style={{ padding: "0px 10px 10px 10px", }}>2.2</div>
                                        <div className='align-items-center' style={{ width: '60%', padding: ' 0px 10px 10px 10px' }}> Date of incorporation: <i>(in the country of origin)</i></div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ border: '1px solid #121212', width: '4%', padding: ' 5px 10px' }}> </div>
                                        <div className='align-items-center' style={{ border: '1px dashed #dddcdc', paddingLeft: '8px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ border: '1px solid #121212', width: '4%', padding: ' 5px 10px', borderRight: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ paddingLeft: '8px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', border: '1px solid #121212', }}> </div>
                                    </div>
                                    <div className='d-flex align-items-center mt-3'>
                                        <div style={{ padding: "5px 10px" }}>
                                            2.3
                                        </div>
                                        <div style={{ padding: "5px 10px", width: "60%" }}>
                                            Name of parent / holding company, if any
                                        </div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '89%', minHeight: "45px", padding: '5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                    </div>
                                    <PartsWrapper className='mt-4 mb-3'>
                                        PART-III
                                    </PartsWrapper>

                                    <div className='row mt-4'>
                                        <div className='col-md-12'>
                                            <HeadingWrapper className='text-center d-flex justify-content-center'>
                                                STATUS OF FOREIGN COMPANY IN PAKISTAN AND KIND OF A FOREIGN COMPANY IN THE COUNTRY OF ORIGIN
                                            </HeadingWrapper>
                                        </div>
                                    </div>
                                    <div className='d-flex align-items-center mt-3'>
                                        <div style={{ padding: "5px 10px" }}>
                                            3.1
                                        </div>
                                        <div style={{ padding: "5px 10px", width: "62%" }}>
                                            Status of the foreign company in Pakistan
                                        </div>
                                        <div style={{ padding: "5px 10px", width: "20%" }}>
                                            Liaison Office
                                        </div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '10%', minHeight: "45px", padding: '5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                        <div style={{ padding: "5px 10px", width: "20%" }}>
                                            Branch office
                                        </div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '10%', minHeight: "45px", padding: '5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>

                                    </div>
                                    <div className='d-flex align-items-center mt-3'>
                                        <div style={{ padding: "5px 10px", width: "60%" }}>

                                        </div>
                                        <div style={{ padding: "5px 10px" }}>
                                            <i>(Mark the appropriate box)</i>
                                        </div>

                                    </div>
                                    <PartsWrapper className='mt-4 mb-3'>
                                        PART-IV
                                    </PartsWrapper>

                                    <div className='row mt-4'>
                                        <div className='col-md-12'>
                                            <HeadingWrapper className='d-flex justify-content-center'>
                                                CHARTER, STATUTE ETC. OF A FOREIGN COMPANY
                                            </HeadingWrapper>
                                        </div>
                                    </div>
                                    <div className='d-flex align-items-center mt-3'>
                                        <div style={{ padding: "5px 10px" }}>
                                            4.1
                                        </div>
                                        <div style={{ padding: "5px 10px", }}>
                                            Name of instrument constituting the company:
                                        </div>


                                    </div>
                                    <div className='d-flex align-items-center mt-0'>
                                        <div style={{ padding: "5px 10px" }}>

                                        </div>
                                        <div style={{ padding: "5px 10px", width: "30%" }}>

                                        </div>
                                        <div style={{ padding: "5px 10px", width: "42%" }}>
                                            Memorandum & Article of Association
                                        </div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '10%', minHeight: "45px", padding: '5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                        <div style={{ padding: "5px 10px", width: "9%" }}>
                                            Charter
                                        </div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '10%', minHeight: "45px", padding: '5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                        <div style={{ padding: "5px 10px", width: "9%" }}>
                                            Statute
                                        </div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '10%', minHeight: "45px", padding: '5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>

                                    </div>
                                    <div className='d-flex align-items-start mt-0'>
                                        <div style={{ padding: "5px 10px" }}>

                                        </div>
                                        <div style={{ padding: "5px 10px", width: "30%" }}>

                                        </div>
                                        <div style={{ padding: "5px 10px", width: "20%" }}>
                                            OR
                                        </div>
                                        <div className='align-items-center' style={{ width: '10%', minHeight: "45px", padding: '5px 10px', }}></div>
                                        <div style={{ padding: "5px 10px", width: "20%" }}>

                                        </div>
                                        <div className='align-items-center' style={{ width: '10%', minHeight: "45px", padding: '5px 10px', }}></div>
                                        <div style={{ padding: "5px 10px", width: "20%" }}>

                                        </div>
                                        <div className='align-items-center' style={{ width: '10%', minHeight: "45px", padding: '5px 10px', }}></div>

                                    </div>
                                    <div className='d-flex align-items-start mt-0'>
                                        <div style={{ padding: "5px 10px" }}>

                                        </div>
                                        <div style={{ padding: "5px 10px", width: "24.2%" }}>

                                        </div>
                                        <div style={{ padding: "5px 10px", width: "34%" }}>
                                            Other instrument (to be specified)
                                        </div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '40%', minHeight: "29px", padding: '5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>

                                    </div>

                                    <div className='mt-2'>
                                        <div style={{ padding: "5px 10px" }}>
                                            <i>(Copy of instrument duly certified by public officer / notary public of country of origin and signed by Pakistani diplomat posted in that country as required under these Regulations shall be filed herewith.)</i>
                                        </div>
                                    </div>

                                    <div className='d-flex align-items-center mt-3'>
                                        <div style={{ padding: "5px 10px" }}>
                                            4.2
                                        </div>
                                        <div style={{ padding: "5px 10px", width: "78%" }}>
                                            Language in which the above documents filed in the country of origin.
                                        </div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '18%', minHeight: "45px", padding: '5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex align-items-center mt-3'>
                                        <div style={{ padding: "5px 10px" }}>
                                            4.3
                                        </div>
                                        <div style={{ padding: "5px 10px", width: "59%" }}>
                                            Certified translation thereof in English or Urdu language:
                                        </div>
                                        <div style={{ padding: "5px 10px" }}>
                                            Enclosed
                                        </div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '10%', minHeight: "45px", padding: '5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                        <div style={{ padding: "5px 10px" }}>
                                            Not required
                                        </div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '10%', minHeight: "45px", padding: '5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                    </div>
                                    <div className='ml-4'>
                                        <i style={{ marginLeft: "25px" }}>(Not required if original documents are in Urdu or English)</i>
                                    </div>
                                    <div className='d-flex align-items-center mt-3'>
                                        <div style={{ padding: "5px 10px" }}>
                                            4.4
                                        </div>
                                        <div style={{ padding: "5px 10px" }}>
                                            Brief description of the main object of the company
                                        </div>

                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '69%', minHeight: "45px", padding: '5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>

                                    </div>
                                    <div className='d-flex align-items-center mt-3'>
                                        <div style={{ padding: "5px 10px" }}>
                                            4.5
                                        </div>
                                        <div style={{ padding: "5px 10px", width: "34%" }}>
                                            Whether the company is doing business in Pakistan itself or online or through authorized agent
                                        </div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '69%', minHeight: "63px", padding: '5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                    </div>

                                    <PartsWrapper className='mt-4 mb-3'>
                                        PART-V
                                    </PartsWrapper>

                                    <div className='row mt-4'>
                                        <div className='col-md-12'>
                                            <HeadingWrapper className='d-flex justify-content-center'>
                                                ADDRESS OF THE REGISTERED OR PRINCIPAL OFFICE IN THE COUNTRY OF ORIGIN
                                            </HeadingWrapper>
                                        </div>
                                    </div>
                                    <div className='d-flex align-items-center mt-3'>
                                        <div style={{ padding: "5px 10px" }}>
                                            5.1
                                        </div>
                                        <div style={{ padding: "5px 10px" }}>
                                            Registered or Principal office address <i>(in the country of origin)</i>
                                        </div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '69%', minHeight: "45px", padding: '5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex align-items-center mt-3'>
                                        <div style={{ padding: "5px 10px", width: "20%" }}>
                                        </div>
                                        <div style={{ padding: "5px 10px" }}>
                                            Landline Number
                                        </div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '30%', minHeight: "45px", padding: '5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                        <div style={{ padding: "5px 10px" }}>
                                            Email
                                        </div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '32%', minHeight: "45px", padding: '5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>

                                    </div>
                                    <div className='d-flex align-items-center mt-3'>
                                        <div style={{ padding: "5px 10px", width: "16.6%" }}>
                                        </div>
                                        <div style={{ padding: "5px 10px" }}>
                                            Website Address, if any
                                        </div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '67.3%', minHeight: "45px", padding: '5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                    </div>
                                    <PartsWrapper className='mt-4 mb-3'>
                                        PART-VI
                                    </PartsWrapper>

                                    <div className='row mt-4'>
                                        <div className='col-md-12'>
                                            <HeadingWrapper className='d-flex justify-content-center'>
                                                ADDRESS OF THE PRINCIPAL PLACE(S) OF BUSINESS/ LIAISON OFFICE(S) IN PAKISTAN
                                            </HeadingWrapper>
                                        </div>
                                    </div>
                                    <div className='d-flex align-items-center mt-3'>
                                        <div style={{ padding: "5px 10px" }}>
                                            6.1
                                        </div>
                                        <div style={{ padding: "5px 10px" }}>
                                            Principal place of business and other places of business / Liaison office(s):
                                        </div>
                                    </div>

                                    <div className='d-flex '>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "none", borderBottom: "none", padding: ' 5px 10px', width: '10%', }}></div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "none", borderBottom: "none", padding: ' 5px 10px', width: '20%', }}></div>
                                        <div className='align-items-center text-right' style={{ minHeight: "28px", border: "1px solid #121212", borderBottom: "none", padding: ' 5px 10px', width: '20%', }}>Principal place of business</div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none", borderBottom: "none", width: '17%', padding: ' 5px 10px', }}>Other Place 1 (if any)</div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none", borderBottom: "none", width: '14%', padding: ' 5px 10px', }}>No. of proposed directors</div>
                                    </div>
                                    <div className='d-flex '>
                                        <div className='align-items-center text-right' style={{ minHeight: "28px", border: "none", borderBottom: "none", padding: ' 5px 10px', width: '10%', }}></div>
                                        <div className='align-items-center text-right' style={{ minHeight: "28px", border: "none", borderBottom: "none", padding: ' 5px 10px', width: '20%', }}>Complete Address</div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderBottom: "none", padding: ' 5px 10px', width: '20%', }}></div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none", borderBottom: "none", width: '17%', padding: ' 5px 10px', fontWeight: "bold" }}></div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none", borderBottom: "none", width: '14%', padding: ' 5px 10px', fontWeight: "bold" }}></div>
                                    </div>
                                    <div className='d-flex '>
                                        <div className='align-items-center text-right' style={{ minHeight: "28px", border: "none", borderBottom: "none", padding: ' 5px 10px', width: '10%', }}></div>
                                        <div className='align-items-center text-right' style={{ minHeight: "28px", border: "none", borderBottom: "none", padding: ' 5px 10px', width: '20%', }}>City</div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderBottom: "none", padding: ' 5px 10px', width: '20%', }}></div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none", borderBottom: "none", width: '17%', padding: ' 5px 10px', fontWeight: "bold" }}></div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none", borderBottom: "none", width: '14%', padding: ' 5px 10px', fontWeight: "bold" }}></div>
                                    </div>
                                    <div className='d-flex '>
                                        <div className='align-items-center text-right' style={{ minHeight: "28px", border: "none", borderBottom: "none", padding: ' 5px 10px', width: '10%', }}></div>
                                        <div className='align-items-center text-right' style={{ minHeight: "28px", border: "none", borderBottom: "none", padding: ' 5px 10px', width: '20%', }}>District</div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderBottom: "none", padding: ' 5px 10px', width: '20%', }}></div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none", borderBottom: "none", width: '17%', padding: ' 5px 10px', fontWeight: "bold" }}></div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none", borderBottom: "none", width: '14%', padding: ' 5px 10px', fontWeight: "bold" }}></div>
                                    </div>
                                    <div className='d-flex '>
                                        <div className='align-items-center text-right' style={{ minHeight: "28px", border: "none", borderBottom: "none", padding: ' 5px 10px', width: '10%', }}></div>
                                        <div className='align-items-center text-right' style={{ minHeight: "28px", border: "none", borderBottom: "none", padding: ' 5px 10px', width: '20%', }}>Province</div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderBottom: "none", padding: ' 5px 10px', width: '20%', }}></div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none", borderBottom: "none", width: '17%', padding: ' 5px 10px', fontWeight: "bold" }}></div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none", borderBottom: "none", width: '14%', padding: ' 5px 10px', fontWeight: "bold" }}></div>
                                    </div>
                                    <div className='d-flex '>
                                        <div className='align-items-center text-right' style={{ minHeight: "28px", border: "none", borderBottom: "none", padding: ' 5px 10px', width: '10%', }}></div>
                                        <div className='align-items-center text-right' style={{ minHeight: "28px", border: "none", borderBottom: "none", padding: ' 5px 10px', width: '20%', }}>Landline Number</div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderBottom: "none", padding: ' 5px 10px', width: '20%', }}></div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none", borderBottom: "none", width: '17%', padding: ' 5px 10px', fontWeight: "bold" }}></div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none", borderBottom: "none", width: '14%', padding: ' 5px 10px', fontWeight: "bold" }}></div>
                                    </div>
                                    <div className='d-flex '>
                                        <div className='align-items-center text-right' style={{ minHeight: "28px", border: "none", borderBottom: "none", padding: ' 5px 10px', width: '10%', }}></div>
                                        <div className='align-items-center text-right' style={{ minHeight: "28px", border: "none", borderBottom: "none", padding: ' 5px 10px', width: '20%', }}>Mobile Number of Principal Officer</div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderBottom: "none", padding: ' 5px 10px', width: '20%', }}></div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none", borderBottom: "none", width: '17%', padding: ' 5px 10px', fontWeight: "bold" }}></div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none", borderBottom: "none", width: '14%', padding: ' 5px 10px', fontWeight: "bold" }}></div>
                                    </div>
                                    <div className='d-flex '>
                                        <div className='align-items-center text-right' style={{ minHeight: "28px", border: "none", borderBottom: "none", padding: ' 5px 10px', width: '10%', }}></div>
                                        <div className='align-items-center text-right' style={{ minHeight: "28px", border: "none", borderBottom: "none", padding: ' 5px 10px', width: '20%', }}>Email Address</div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderBottom: "none", padding: ' 5px 10px', width: '20%', }}></div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none", borderBottom: "none", width: '17%', padding: ' 5px 10px', fontWeight: "bold" }}></div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none", borderBottom: "none", width: '14%', padding: ' 5px 10px', fontWeight: "bold" }}></div>
                                    </div>
                                    <div className='d-flex '>
                                        <div className='align-items-center text-right' style={{ minHeight: "28px", padding: ' 5px 10px', width: '10%', }}></div>
                                        <div className='align-items-center text-right' style={{ minHeight: "28px", padding: ' 5px 10px', width: '20%', }}>Website, if any</div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", padding: ' 5px 10px', width: '20%', }}></div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none", width: '17%', padding: ' 5px 10px', fontWeight: "bold" }}></div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none", width: '14%', padding: ' 5px 10px', fontWeight: "bold" }}></div>
                                    </div>
                                    <div style={{ padding: "5px 10px" }}>
                                        <i>(Here state full address of all places of business in Pakistan, add further pages if required)</i>
                                    </div>
                                    <PartsWrapper className='mt-4 mb-3'>
                                        PART-VII
                                    </PartsWrapper>

                                    <div className='row mt-4'>
                                        <div className='col-md-12'>
                                            <HeadingWrapper className='text-center d-flex justify-content-center'>
                                                PARTICULARS OF DIRECTORS, CHIEF EXECUTIVE, SECRETARY, PRINCIPAL OFFICER AND PERSON(S) AUTHORIZED TO ACCEPT DOCUMENTS/ PROCESSES ON BEHALF OF COMPANY
                                            </HeadingWrapper>
                                        </div>
                                    </div>

                                    <div className='d-flex align-items-center mt-3'>
                                        <div style={{ padding: "5px 10px" }}>
                                            7.1
                                        </div>
                                        <div style={{ padding: "5px 10px", fontWeight: "bold" }}>
                                            Particulars of Directors, Chief Executive, Secretary, Principal officer or Person(s) authorized to accept documents/processes on behalf of Company (in case of individuals only):
                                        </div>
                                    </div>
                                    <Wrapper className="table-responsive mt-0" >
                                        <table
                                            className="table table-bordered"
                                            style={{ fontFamily: "Montserrat rev=1 !important" }}
                                        >
                                            <tbody>
                                                <tr>
                                                    <td style={{   width: '7%',writingMode:"vertical-rl", textOrientation:"mixed",transform:"rotate(180deg)",height:"250px",textAlign:"center" }}>Present and surname in full</td>
                                                    <td style={{   width: '7%',writingMode:"vertical-rl", textOrientation:"mixed",transform:"rotate(180deg)",height:"250px",textAlign:"center" }}>Former & surname (if any)</td>
                                                    <td style={{   width: '7%',writingMode:"vertical-rl", textOrientation:"mixed",transform:"rotate(180deg)",height:"250px",textAlign:"center" }}>Usual resident address</td>
                                                    <td style={{   width: '7%',writingMode:"vertical-rl", textOrientation:"mixed",transform:"rotate(180deg)",height:"250px",textAlign:"center" }}>Nationality</td>
                                                    <td style={{   width: '7%',writingMode:"vertical-rl", textOrientation:"mixed",transform:"rotate(180deg)",height:"250px",textAlign:"center" }}>Nationality of origin(if other than the present nationality)</td>
                                                    <td style={{   width: '7%',writingMode:"vertical-rl", textOrientation:"mixed",transform:"rotate(180deg)",height:"250px",textAlign:"center" }}>Occupation</td>
                                                    <td style={{  width: '7%',writingMode:"vertical-rl", textOrientation:"mixed",transform:"rotate(180deg)",height:"250px",textAlign:"center" }}>Other directorship.If any</td>
                                                    <td style={{   width: '7%',writingMode:"vertical-rl", textOrientation:"mixed",transform:"rotate(180deg)",height:"250px" ,textAlign:"center"}}>Status / Designation(Director / Chief Executive, Principal officer, Authorized person resident in Pakistan)</td>
                                                    <td style={{  width: '7%',writingMode:"vertical-rl", textOrientation:"mixed",transform:"rotate(180deg)",height:"250px",textAlign:"center" }}>N.I.C Number or Passport Number (Whichever is applicable)</td>
                                                    <td style={{  width: '7%',writingMode:"vertical-rl", textOrientation:"mixed",transform:"rotate(180deg)",height:"250px",textAlign:"center" }}>Date of appointment/ Cessation/ change in particulars</td>
                                                    <td style={{  width: '7%',writingMode:"vertical-rl", textOrientation:"mixed",transform:"rotate(180deg)",height:"250px",textAlign:"center" }}>Remarks, if any</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%',textAlign:"center" }}>(a)</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%',textAlign:"center" }}>(b)</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%',textAlign:"center" }}>(c)</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%',textAlign:"center" }}>(d)</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%',textAlign:"center" }}>(e)</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%',textAlign:"center" }}>(f)</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%',textAlign:"center" }}>(g)</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%',textAlign:"center" }}>(h)</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%',textAlign:"center" }}>(i)</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%',textAlign:"center" }}>(j)</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%',textAlign:"center" }}>(k)</td>
                                                </tr>
                                               

                                            </tbody>
                                        </table>
                                    </Wrapper>

                                    <div style={{ padding: "5px 10px" }}>
                                        <i>Note: In case of Secretary, particulars as per (a) & (c) may be stated. </i>
                                    </div>

                                    <div className='d-flex align-items-center mt-3'>
                                        <div style={{ padding: "5px 10px" }}>
                                            7.1.1
                                        </div>
                                        <div style={{ padding: "5px 10px", fontWeight: "bold" }}>
                                            Particulars of Directors and Secretary (In case of body corporate):
                                        </div>
                                    </div>

                                    <div className='d-flex'>
                                        <div>
                                            <div className='d-flex '>
                                                <div className='align-items-center ' style={{ minHeight: "75px", border: "1px solid #121212", padding: ' 5px 10px', width: '87.1%' }}>Particulars of each corporate body which is director/secretary of the foreign company</div>
                                            </div>
                                            <div className='d-flex '>
                                                <div className='align-items-center ' style={{ height: "175px", border: "1px solid #121212", padding: ' 5px 10px', borderTop: "none", width: '20%' }}>Corporate Name</div>
                                                <div className='align-items-center ' style={{ height: "175px", border: "1px solid #121212", borderLeft: "none", borderTop: "none", width: '20%', padding: ' 5px 10px' }}>Registeredor Principal Office address</div>
                                                <div className='align-items-center ' style={{ height: "175px", border: "1px solid #121212", borderLeft: "none", borderTop: "none", width: '17%', padding: ' 5px 10px' }}>Status /Designation(Director/Secretary)</div>
                                                <div className='align-items-center ' style={{ height: "175px", border: "1px solid #121212", borderLeft: "none", borderTop: "none", width: '15%', padding: ' 5px 10px' }}>Date of appointment/Cessation/change inparticulars</div>
                                                <div className='align-items-center ' style={{ height: "175px", border: "1px solid #121212", borderLeft: "none", borderTop: "none", width: '15.1%', padding: ' 5px 10px' }}>Remarks</div>
                                            </div>
                                            <div className='d-flex '>
                                                <div className='align-items-center  text-center' style={{ minHeight: "28px", border: "1px solid #121212", padding: ' 5px 10px', borderTop: "none", width: '20%' }}>(a)</div>
                                                <div className='align-items-center  text-center' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none", borderTop: "none", width: '20%', padding: ' 5px 10px' }}>(b)</div>
                                                <div className='align-items-center  text-center' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none", borderTop: "none", width: '17%', padding: ' 5px 10px' }}>(c)</div>
                                                <div className='align-items-center  text-center' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none", borderTop: "none", width: '15%', padding: ' 5px 10px' }}>(e)</div>
                                                <div className='align-items-center text-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none", borderTop: "none", width: '15.1%', padding: ' 5px 10px' }}>(f)</div>
                                            </div>
                                            <div className='d-flex '>
                                                <div className='align-items-center ' style={{ minHeight: "32px", border: "1px solid #121212", padding: ' 5px 10px', borderTop: "none", width: '20%' }}></div>
                                                <div className='align-items-center ' style={{ minHeight: "32px", border: "1px solid #121212", borderLeft: "none", borderTop: "none", width: '20%', padding: ' 5px 10px' }}></div>
                                                <div className='align-items-center ' style={{ minHeight: "32px", border: "1px solid #121212", borderLeft: "none", borderTop: "none", width: '17%', padding: ' 5px 10px' }}></div>
                                                <div className='align-items-center ' style={{ minHeight: "32px", border: "1px solid #121212", borderLeft: "none", borderTop: "none", width: '15%', padding: ' 5px 10px' }}></div>
                                                <div className='align-items-center ' style={{ minHeight: "32px", border: "1px solid #121212", borderLeft: "none", borderTop: "none", width: '15.1%', padding: ' 5px 10px' }}></div>
                                            </div>
                                            <div className='d-flex '>
                                                <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", padding: ' 5px 10px', borderTop: "none", width: '20%' }}></div>
                                                <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none", borderTop: "none", width: '20%', padding: ' 5px 10px' }}></div>
                                                <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none", borderTop: "none", width: '17%', padding: ' 5px 10px' }}></div>
                                                <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none", borderTop: "none", width: '15%', padding: ' 5px 10px' }}></div>
                                                <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none", borderTop: "none", width: '15.1%', padding: ' 5px 10px' }}></div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className='d-flex '>
                                                <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", padding: ' 5px 10px', borderBottom: "none", width: '100%' }}>Particulars of each director/partner of corporate body which is director/secretary of the foreign company</div>
                                            </div>
                                            <div className='d-flex '>
                                                <div className='align-items-center text-center' style={{ height: "175px", border: "1px solid #121212", padding: ' 5px 10px', width: '22%' }}><span className='text-center d-flex align-items-center h-100'>Full Name *</span></div>
                                                <div className='align-items-center text-center' style={{ height: "175px", border: "1px solid #121212", borderLeft: "none", width: '26%', padding: ' 5px 10px' }}><span style={{ writingMode: "vertical-rl", transform: "rotate(-180deg)", height: "100%", textAlign: "center" }}>Address*</span></div>
                                                <div className='align-items-center text-center' style={{ height: "175px", border: "1px solid #121212", borderLeft: "none", width: '29%', padding: ' 5px 10px' }}><span style={{ writingMode: "vertical-rl", transform: "rotate(-180deg)", height: "100%", textAlign: "center" }}>Nationality</span></div>
                                                <div className='align-items-center text-center' style={{ height: "175px", border: "1px solid #121212", borderLeft: "none", width: '39%', padding: ' 5px 10px' }}><span style={{ writingMode: "vertical-rl", transform: "rotate(-180deg)", height: "100%", textAlign: "center" }}>Nationality of origin (if other than the present nationality)</span></div>
                                            </div>
                                            <div className='d-flex '>
                                                <div className='align-items-center text-center' style={{ minHeight: "28px", border: "1px solid #121212", padding: ' 5px 10px', width: '19.5%', borderTop: "none" }}>(g)</div>
                                                <div className='align-items-center text-center' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none", width: '22.1%', padding: ' 5px 10px', borderTop: "none" }}>(h)</div>
                                                <div className='align-items-center text-center' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none", width: '25.2%', padding: ' 5px 10px', borderTop: "none" }}>(i)</div>
                                                <div className='align-items-center text-center' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none", width: '33.23%', padding: ' 5px 10px', borderTop: "none" }}>(j)</div>
                                            </div>
                                            <div className='d-flex '>
                                                <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", padding: ' 5px 10px', width: '19.5%', borderTop: "none" }}></div>
                                                <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none", width: '22.1%', padding: ' 5px 10px', borderTop: "none" }}></div>
                                                <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none", width: '25.2%', padding: ' 5px 10px', borderTop: "none" }}></div>
                                                <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none", width: '33.23%', padding: ' 5px 10px', borderTop: "none" }}></div>
                                            </div>
                                            <div className='d-flex '>
                                                <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", padding: ' 5px 10px', width: '19.5%', borderTop: "none" }}></div>
                                                <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none", width: '22.1%', padding: ' 5px 10px', borderTop: "none" }}></div>
                                                <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none", width: '25.2%', padding: ' 5px 10px', borderTop: "none" }}></div>
                                                <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none", width: '33.23%', padding: ' 5px 10px', borderTop: "none" }}></div>
                                            </div>


                                        </div>
                                    </div>
                                    <div>
                                        *&nbsp;&nbsp;&nbsp;&nbsp;  <i> Where a body corporate is a secretary and all the partners of the firm are joint secretaries of the foreign company, then the name and principal office of the body corporate may be stated instead of particulars of each such secretary.</i>
                                    </div>

                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center text-center' style={{ minHeight: "28px", border: "1px solid #121212", borderBottom: 'none', padding: ' 5px 10px', width: '100%' }}>Particulars of the nominee directors</div>
                                    </div>
                                    <div className='d-flex '>
                                        <div className='d-flex'>
                                            <div className='align-items-center text-center' style={{ minHeight: "28px", border: "1px solid #121212", padding: ' 5px 10px', width: '50%' }}>Name of the nominee director</div>
                                            <div className='align-items-center text-center' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none", width: '50%', padding: ' 5px 10px' }}>Name of the member nominating the director</div>
                                        </div>
                                        <div>
                                            <div className='d-flex flex-column'>
                                                <div className='align-items-center text-center' style={{ minHeight: "28px", border: "1px solid #121212", borderBottom: "none", borderLeft: "none", padding: ' 5px 10px', width: '100%' }}>In case the nominating member is a legal person or legal arrangement</div>
                                                <div className='d-flex'>
                                                    <div className='align-items-center text-center' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none", padding: ' 5px 10px', width: '25%' }}>Legal form (Company/LLP/Partnership Firm/Trust/Any other body corporate (to be specified))</div>
                                                    <div className='align-items-center text-center' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none", width: '25%', padding: ' 5px 10px' }}>Date of incorporation/ registration</div>
                                                    <div className='align-items-center text-center' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none", width: '25%', padding: ' 5px 10px' }}>Name of registration authority</div>
                                                    <div className='align-items-center text-center' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none", width: '25%', padding: ' 5px 10px' }}>Business address</div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className='d-flex'>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderTop: "none", padding: ' 5px 10px', width: '69.9%' }}></div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: 'none', borderTop: "none", padding: ' 5px 10px', width: '70%' }}></div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: 'none', borderTop: "none", padding: ' 5px 10px', width: '80%' }}></div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: 'none', borderTop: "none", padding: ' 5px 10px', width: '80%' }}></div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: 'none', borderTop: "none", padding: ' 5px 10px', width: '80%' }}></div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: 'none', borderTop: "none", padding: ' 5px 10px', width: '80%' }}></div>
                                    </div>

                                    <div className='d-flex align-items-start mt-3'>
                                        <div style={{ padding: "5px 10px" }}>
                                            7.2
                                        </div>
                                        <div style={{ padding: "5px 10px" }}>
                                            It is hereby declared that necessary information about the ultimate beneficial owners of the foreign company, if any, as specified in regulation 23, and as defined in the Companies Regulations, 2024 has been obtained and is available on record.
                                        </div>
                                    </div>


                                    <PartsWrapper className='mt-4 mb-3'>
                                        PART-VIII
                                    </PartsWrapper>
                                    <div className='d-flex align-items-center mt-3'>
                                        <div style={{ padding: "5px 10px" }}>
                                            8.1
                                        </div>
                                        <div style={{ padding: "10px 10px 0px 10px" }}>
                                            Declaration
                                        </div>
                                    </div>
                                    <div style={{ marginLeft: '35px' }} className='d-flex flex-column mt-3'>

                                        <div style={{ padding: "5px 10px" }}>
                                            I do hereby solemnly and sincerely declare that the information provided in the form is:
                                        </div>
                                        <div className='d-flex align-items-baseline' style={{ padding: "5px 10px" }}>
                                            <div>(i)</div> <div style={{ marginLeft: "24px" }}>true and correct to the best of my knowledge, in consonance with the record as maintained by the Company and nothing has been concealed; and</div>
                                        </div>
                                        <div className='d-flex align-items-baseline' style={{ padding: "5px 10px" }}>
                                            <div>(ii)</div><div style={{ marginLeft: '20px' }}>hereby reported after complying with and fulfilling all requirements under the relevant provisions of law, rules, regulations, directives, circulars and notifications whichever is applicable</div>
                                        </div>
                                    </div>

                                    <div className='d-flex align-items-start mt-3'>
                                        <div style={{ padding: "5px 10px" }}>
                                            8.2
                                        </div>
                                        <div style={{ padding: "5px 10px", width: '29%' }}>
                                            Name of Authorized Officer with designation/ Authorized Intermediary
                                        </div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '34%', minHeight: "45px", padding: '5px 10px', }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '34%', minHeight: "45px", padding: '5px 10px', borderLeft: "none" }}></ScrollWrapper>

                                    </div>
                                    <div className='d-flex align-items-center mt-3'>
                                        <div style={{ padding: "5px 10px" }}>
                                            8.3
                                        </div>
                                        <div style={{ padding: "5px 10px", width: '35%' }}>
                                            Signatures
                                        </div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '62%', minHeight: "45px", padding: '5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>

                                    </div>
                                    <div className='d-flex align-items-center mt-3'>
                                        <div style={{ padding: "5px 10px" }}>
                                            8.4
                                        </div>
                                        <div style={{ padding: "5px 10px", width: "46%" }}>
                                            Registration No of Authorized Intermediary, if applicable
                                        </div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '51%', minHeight: "45px", padding: '5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>

                                    </div>
                                    <div className='d-flex align-items-start mt-3'>
                                        <div style={{ padding: "5px 10px" }}>
                                            8.5
                                        </div>
                                        <div style={{ padding: "5px 10px", width: '28%' }}>
                                            Contact details of the applicant, i.e. address, email and cell No. etc.
                                        </div>
                                        <div style={{ width: '100%' }}>
                                            <div className='d-flex '>
                                                <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderBottom: "none", padding: ' 5px 10px', width: '20%', }}>Address</div>
                                                <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderBottom: "none", borderLeft: "none", padding: ' 5px 10px', width: '80%', fontWeight: "bold" }}></div>
                                            </div>
                                            <div className='d-flex '>
                                                <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderBottom: "none", padding: ' 5px 10px', width: '20%', }}>Email</div>
                                                <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderBottom: "none", borderLeft: "none", padding: ' 5px 10px', width: '80%', fontWeight: "bold" }}></div>
                                            </div>
                                            <div className='d-flex '>
                                                <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", padding: ' 5px 10px', width: '20%', }}>Cell No.</div>
                                                <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none", padding: ' 5px 10px', width: '80%', fontWeight: "bold" }}></div>
                                            </div>
                                        </div>

                                    </div>


                                    <div className='d-flex mt-0'>
                                        <div className='align-items-center' style={{ padding: "5px 10px", width: '7%' }}></div>
                                        <div className='align-items-center' style={{ width: '55%', padding: ' 5px 10px' }}> </div>
                                        <div className='align-items-center text-center' style={{ width: '8%', padding: ' 10px 10px 0px 10px' }}> Day</div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center text-center' style={{ width: '8%', padding: ' 10px 10px 0px 10px' }}> Month</div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center text-center ' style={{ width: '16%', padding: ' 10px 10px 0px 10px' }}>Year </div>
                                        <div></div>
                                    </div>
                                    <div className='d-flex '>
                                        <div className='align-items-center' style={{ padding: "0px 10px 10px 10px", }}>3.6</div>
                                        <div className='align-items-center' style={{ width: '60%', padding: ' 0px 10px 10px 10px' }}>Date</div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ border: '1px solid #121212', width: '4%', padding: ' 5px 10px' }}> </div>
                                        <div className='align-items-center' style={{ border: '1px dashed #dddcdc', paddingLeft: '8px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ border: '1px solid #121212', width: '4%', padding: ' 5px 10px', borderRight: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ paddingLeft: '8px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', border: '1px solid #121212', }}> </div>
                                    </div>

                                    <div className='mt-4'>
                                        <div className="d-flex align-items-start">
                                            <div style={{ marginLeft: "8px" }}>1.</div><div style={{ marginLeft: "23px" }}>Certified copy of the charter/statute/memorandum and articles of association or instrument defining the constitution of the foreign company</div>
                                        </div>
                                        <div className="d-flex align-items-start">
                                            <div style={{ marginLeft: "8px" }}>2.</div><div style={{ marginLeft: "20px" }}>Board Resolution regarding appointment of the principal officer and consent of person authorized to accept on behalf of the company service of process / any notice / document</div>
                                        </div>
                                        <div className="d-flex align-items-start">
                                            <div style={{ marginLeft: "8px" }}>3.</div><div style={{ marginLeft: "20px" }}>Consent of the Principal Officer and persons authorized to accept on behalf of the company service of process / any notice / document</div>
                                        </div>
                                        <div className="d-flex align-items-start">
                                            <div style={{ marginLeft: "8px" }}>4.</div><div style={{ marginLeft: "19px" }}>Certified translation of instrument constituting the foreign company, if applicable</div>
                                        </div>
                                        <div className="d-flex align-items-start">
                                            <div style={{ marginLeft: "8px" }}>5.</div><div style={{ marginLeft: "20px" }}>Valid approval letter from Board of Investment, Government of Pakistan, if not already provided with the application for reservation of name.</div>
                                        </div>
                                        <div className="d-flex align-items-start">
                                            <div style={{ marginLeft: "8px" }}>6.</div><div style={{ marginLeft: "20px" }}>Original challan or other evidence of payment of fee specified in Seventh Schedule of the Act (not applicable in case of online filing)</div>
                                        </div>
                                        <div className="d-flex align-items-start">
                                            <div style={{ marginLeft: "8px" }}>7.</div><div style={{ marginLeft: "21px" }}>Any other document deemed necessary</div>
                                        </div>
                                    </div>



                                </div>
                            </div>
                        </div>
                    </PDFExport>
                    : <PDFExport
                        paperSize="A4"
                        margin="1cm"
                        scale={0.6}
                        fileName={`Statutory Form 2`}
                        pageTemplate={PageTemplate}
                        ref={pdfExportComponent}
                    >
                        <div style={{ fontFamily: `${fontFamilyForStaticData}`, fontSize: `${fontSizeForStaticData}`, fontWeight: 500 }}>
                            <FormName>
                                Form 2
                            </FormName>
                            <div className='row'>
                                <div className='col-md-12' >
                                    <HeadingWrapper className='d-flex justify-content-center'>
                                        THE COMPANIES ACT, 2017
                                    </HeadingWrapper>

                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <HeadingWrapper className='d-flex justify-content-center'>
                                        THE COMPANIES REGULATIONS, 2024
                                    </HeadingWrapper>

                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <div style={{
                                        fontSize: '15px',
                                        // fontWeight: 'bold' 
                                    }} className='d-flex justify-content-center'>
                                        [Section 435 and Regulations 21 & 30]
                                    </div>

                                </div>
                            </div>

                            <div className='row mt-4'>
                                <div className='col-md-12'>
                                    <HeadingWrapper className='d-flex justify-content-center'>
                                        REGISTRATION OF DOCUMENT OF A FOREIGN COMPANY
                                    </HeadingWrapper>

                                </div>
                            </div>
                            <PartsWrapper className='mt-4 mb-3'>
                                PART-I
                            </PartsWrapper>
                            <div className='row'>
                                <div className='col-md-12' style={{ padding: '25px 50px', wordWrap: 'break-word' }}>
                                    <div style={{

                                        paddingLeft: '11px'
                                    }}><i> (Please complete in bold capital letters)</i></div>


                                    {/* <div className='d-flex' >
                            <div className='align-items-center' style={{ border: '1px dashed #dddcdc', paddingLeft: '2px', width: '7%' }}>1.1</div>
                            <div className='align-items-center' style={{ border: '1px dashed #dddcdc', width: '28%', padding: ' 5px 10px' }}>Name of the Company</div>
                            <div className='align-items-center  d-flex'  >{
                                '1234 552'.split("")?.map((item, idx) => {
                                    if (item == ' ') return
                                    return <div style={{ minWidth: '30px', minHeight: '34px', marginLeft: '2px', border: '1px solid #121212', borderRadius: '3px', padding: '6px 9px' }}></div>
                                })
                            }
                            </div>
                        </div> */}


                                    <div className='d-flex mt-3'>
                                        <div className='align-items-center' style={{ padding: '0px 10px 10px 10px', }}>1.1</div>
                                        <div className='align-items-center' style={{ width: '30%', padding: ' 0px 10px 10px 10px' }}> Name of the Foreign Company in the country of origin as already reserved</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '79%', minHeight: '40px', padding: ' 5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                    </div>

                                    <div className='d-flex  mt-3'>
                                        <div className='align-items-center' style={{ padding: '0px 10px 10px 10px', }}>1.2</div>
                                        <div className='align-items-center' style={{ width: '17.2%', paddingLeft: '10px' }}>Fee Payment Details</div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '5%' }}>1.2.1</div>
                                        <div className='align-items-center' style={{ width: '8.2%', paddingLeft: '20px' }}>Challan No</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '26%', paddingLeft: '20px', minHeight: '34px' }}></ScrollWrapper>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '5%' }}>1.2.2</div>
                                        <div className='align-items-center' style={{ width: '13.20%', paddingLeft: '20px' }}>Amount(Rs.)</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '26%', paddingLeft: '20px', minHeight: '34px' }}></ScrollWrapper>
                                    </div>
                                    <PartsWrapper className='mt-4 mb-3'>
                                        PART-II
                                    </PartsWrapper>
                                    <div className='d-flex align-items-center mt-3'>
                                        <div style={{ padding: "5px 10px" }}>
                                            2.1
                                        </div>
                                        <div style={{ padding: "5px 10px", width: "48%" }}>
                                            Incorporated in: <i>(state name of the country of origin)</i>

                                        </div>
                                        <div style={{ padding: "5px 10px", width: "20%" }}>
                                        </div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '84%', minHeight: "45px", padding: '5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-0'>
                                        <div className='align-items-center' style={{ padding: "5px 10px", width: '7%' }}></div>
                                        <div className='align-items-center' style={{ width: '55%', padding: ' 5px 10px' }}> </div>
                                        <div className='align-items-center text-center' style={{ width: '8%', padding: ' 10px 10px 0px 10px' }}>dd</div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center text-center' style={{ width: '8%', padding: ' 10px 10px 0px 10px' }}>mm</div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center text-center ' style={{ width: '16%', padding: ' 10px 10px 0px 10px' }}>yyyy</div>
                                        <div></div>
                                    </div>
                                    <div className='d-flex '>
                                        <div className='align-items-center' style={{ padding: "0px 10px 10px 10px", }}>2.2</div>
                                        <div className='align-items-center' style={{ width: '60%', padding: ' 0px 10px 10px 10px' }}> Date of incorporation: <i>(in the country of origin)</i></div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ border: '1px solid #121212', width: '4%', padding: ' 5px 10px' }}> </div>
                                        <div className='align-items-center' style={{ border: '1px dashed #dddcdc', paddingLeft: '8px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ border: '1px solid #121212', width: '4%', padding: ' 5px 10px', borderRight: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ paddingLeft: '8px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', border: '1px solid #121212', }}> </div>
                                    </div>
                                    <div className='d-flex align-items-center mt-3'>
                                        <div style={{ padding: "5px 10px" }}>
                                            2.3
                                        </div>
                                        <div style={{ padding: "5px 10px", width: "60%" }}>
                                            Name of parent / holding company, if any
                                        </div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '89%', minHeight: "45px", padding: '5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                    </div>
                                    <PartsWrapper className='mt-4 mb-3'>
                                        PART-III
                                    </PartsWrapper>

                                    <div className='row mt-4'>
                                        <div className='col-md-12'>
                                            <HeadingWrapper className='text-center d-flex justify-content-center'>
                                                STATUS OF FOREIGN COMPANY IN PAKISTAN AND KIND OF A FOREIGN COMPANY IN THE COUNTRY OF ORIGIN
                                            </HeadingWrapper>
                                        </div>
                                    </div>
                                    <div className='d-flex align-items-center mt-3'>
                                        <div style={{ padding: "5px 10px" }}>
                                            3.1
                                        </div>
                                        <div style={{ padding: "5px 10px", width: "62%" }}>
                                            Status of the foreign company in Pakistan
                                        </div>
                                        <div style={{ padding: "5px 10px", width: "20%" }}>
                                            Liaison Office
                                        </div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '10%', minHeight: "45px", padding: '5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                        <div style={{ padding: "5px 10px", width: "20%" }}>
                                            Branch office
                                        </div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '10%', minHeight: "45px", padding: '5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>

                                    </div>
                                    <div className='d-flex align-items-center mt-3'>
                                        <div style={{ padding: "5px 10px", width: "60%" }}>

                                        </div>
                                        <div style={{ padding: "5px 10px" }}>
                                            <i>(Mark the appropriate box)</i>
                                        </div>

                                    </div>
                                    <PartsWrapper className='mt-4 mb-3'>
                                        PART-IV
                                    </PartsWrapper>

                                    <div className='row mt-4'>
                                        <div className='col-md-12'>
                                            <HeadingWrapper className='d-flex justify-content-center'>
                                                CHARTER, STATUTE ETC. OF A FOREIGN COMPANY
                                            </HeadingWrapper>
                                        </div>
                                    </div>
                                    <div className='d-flex align-items-center mt-3'>
                                        <div style={{ padding: "5px 10px" }}>
                                            4.1
                                        </div>
                                        <div style={{ padding: "5px 10px", }}>
                                            Name of instrument constituting the company:
                                        </div>


                                    </div>
                                    <div className='d-flex align-items-center mt-0'>
                                        <div style={{ padding: "5px 10px" }}>

                                        </div>
                                        <div style={{ padding: "5px 10px", width: "30%" }}>

                                        </div>
                                        <div style={{ padding: "5px 10px", width: "42%" }}>
                                            Memorandum & Article of Association
                                        </div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '10%', minHeight: "45px", padding: '5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                        <div style={{ padding: "5px 10px", width: "9%" }}>
                                            Charter
                                        </div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '10%', minHeight: "45px", padding: '5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                        <div style={{ padding: "5px 10px", width: "9%" }}>
                                            Statute
                                        </div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '10%', minHeight: "45px", padding: '5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>

                                    </div>
                                    <div className='d-flex align-items-start mt-0'>
                                        <div style={{ padding: "5px 10px" }}>

                                        </div>
                                        <div style={{ padding: "5px 10px", width: "30%" }}>

                                        </div>
                                        <div style={{ padding: "5px 10px", width: "20%" }}>
                                            OR
                                        </div>
                                        <div className='align-items-center' style={{ width: '10%', minHeight: "45px", padding: '5px 10px', }}></div>
                                        <div style={{ padding: "5px 10px", width: "20%" }}>

                                        </div>
                                        <div className='align-items-center' style={{ width: '10%', minHeight: "45px", padding: '5px 10px', }}></div>
                                        <div style={{ padding: "5px 10px", width: "20%" }}>

                                        </div>
                                        <div className='align-items-center' style={{ width: '10%', minHeight: "45px", padding: '5px 10px', }}></div>

                                    </div>
                                    <div className='d-flex align-items-start mt-0'>
                                        <div style={{ padding: "5px 10px" }}>

                                        </div>
                                        <div style={{ padding: "5px 10px", width: "24.2%" }}>

                                        </div>
                                        <div style={{ padding: "5px 10px", width: "34%" }}>
                                            Other instrument (to be specified)
                                        </div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '40%', minHeight: "29px", padding: '5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>

                                    </div>

                                    <div className='mt-2'>
                                        <div style={{ padding: "5px 10px" }}>
                                            <i>(Copy of instrument duly certified by public officer / notary public of country of origin and signed by Pakistani diplomat posted in that country as required under these Regulations shall be filed herewith.)</i>
                                        </div>
                                    </div>

                                    <div className='d-flex align-items-center mt-3'>
                                        <div style={{ padding: "5px 10px" }}>
                                            4.2
                                        </div>
                                        <div style={{ padding: "5px 10px", width: "78%" }}>
                                            Language in which the above documents filed in the country of origin.
                                        </div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '18%', minHeight: "45px", padding: '5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex align-items-center mt-3'>
                                        <div style={{ padding: "5px 10px" }}>
                                            4.3
                                        </div>
                                        <div style={{ padding: "5px 10px", width: "59%" }}>
                                            Certified translation thereof in English or Urdu language:
                                        </div>
                                        <div style={{ padding: "5px 10px" }}>
                                            Enclosed
                                        </div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '10%', minHeight: "45px", padding: '5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                        <div style={{ padding: "5px 10px" }}>
                                            Not required
                                        </div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '10%', minHeight: "45px", padding: '5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                    </div>
                                    <div className='ml-4'>
                                        <i style={{ marginLeft: "25px" }}>(Not required if original documents are in Urdu or English)</i>
                                    </div>
                                    <div className='d-flex align-items-center mt-3'>
                                        <div style={{ padding: "5px 10px" }}>
                                            4.4
                                        </div>
                                        <div style={{ padding: "5px 10px" }}>
                                            Brief description of the main object of the company
                                        </div>

                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '69%', minHeight: "45px", padding: '5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>

                                    </div>
                                    <div className='d-flex align-items-center mt-3'>
                                        <div style={{ padding: "5px 10px" }}>
                                            4.5
                                        </div>
                                        <div style={{ padding: "5px 10px", width: "34%" }}>
                                            Whether the company is doing business in Pakistan itself or online or through authorized agent
                                        </div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '69%', minHeight: "63px", padding: '5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                    </div>

                                    <PartsWrapper className='mt-4 mb-3'>
                                        PART-V
                                    </PartsWrapper>

                                    <div className='row mt-4'>
                                        <div className='col-md-12'>
                                            <HeadingWrapper className='d-flex justify-content-center'>
                                                ADDRESS OF THE REGISTERED OR PRINCIPAL OFFICE IN THE COUNTRY OF ORIGIN
                                            </HeadingWrapper>
                                        </div>
                                    </div>
                                    <div className='d-flex align-items-center mt-3'>
                                        <div style={{ padding: "5px 10px" }}>
                                            5.1
                                        </div>
                                        <div style={{ padding: "5px 10px" }}>
                                            Registered or Principal office address <i>(in the country of origin)</i>
                                        </div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '69%', minHeight: "45px", padding: '5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex align-items-center mt-3'>
                                        <div style={{ padding: "5px 10px", width: "20%" }}>
                                        </div>
                                        <div style={{ padding: "5px 10px" }}>
                                            Landline Number
                                        </div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '30%', minHeight: "45px", padding: '5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                        <div style={{ padding: "5px 10px" }}>
                                            Email
                                        </div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '32%', minHeight: "45px", padding: '5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>

                                    </div>
                                    <div className='d-flex align-items-center mt-3'>
                                        <div style={{ padding: "5px 10px", width: "16.6%" }}>
                                        </div>
                                        <div style={{ padding: "5px 10px" }}>
                                            Website Address, if any
                                        </div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '67.3%', minHeight: "45px", padding: '5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                    </div>
                                    <PartsWrapper className='mt-4 mb-3'>
                                        PART-VI
                                    </PartsWrapper>

                                    <div className='row mt-4'>
                                        <div className='col-md-12'>
                                            <HeadingWrapper className='d-flex justify-content-center'>
                                                ADDRESS OF THE PRINCIPAL PLACE(S) OF BUSINESS/ LIAISON OFFICE(S) IN PAKISTAN
                                            </HeadingWrapper>
                                        </div>
                                    </div>
                                    <div className='d-flex align-items-center mt-3'>
                                        <div style={{ padding: "5px 10px" }}>
                                            6.1
                                        </div>
                                        <div style={{ padding: "5px 10px" }}>
                                            Principal place of business and other places of business / Liaison office(s):
                                        </div>
                                    </div>

                                    <div className='d-flex '>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "none", borderBottom: "none", padding: ' 5px 10px', width: '10%', }}></div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "none", borderBottom: "none", padding: ' 5px 10px', width: '20%', }}></div>
                                        <div className='align-items-center text-right' style={{ minHeight: "28px", border: "1px solid #121212", borderBottom: "none", padding: ' 5px 10px', width: '20%', }}>Principal place of business</div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none", borderBottom: "none", width: '17%', padding: ' 5px 10px', }}>Other Place 1 (if any)</div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none", borderBottom: "none", width: '14%', padding: ' 5px 10px', }}>No. of proposed directors</div>
                                    </div>
                                    <div className='d-flex '>
                                        <div className='align-items-center text-right' style={{ minHeight: "28px", border: "none", borderBottom: "none", padding: ' 5px 10px', width: '10%', }}></div>
                                        <div className='align-items-center text-right' style={{ minHeight: "28px", border: "none", borderBottom: "none", padding: ' 5px 10px', width: '20%', }}>Complete Address</div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderBottom: "none", padding: ' 5px 10px', width: '20%', }}></div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none", borderBottom: "none", width: '17%', padding: ' 5px 10px', fontWeight: "bold" }}></div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none", borderBottom: "none", width: '14%', padding: ' 5px 10px', fontWeight: "bold" }}></div>
                                    </div>
                                    <div className='d-flex '>
                                        <div className='align-items-center text-right' style={{ minHeight: "28px", border: "none", borderBottom: "none", padding: ' 5px 10px', width: '10%', }}></div>
                                        <div className='align-items-center text-right' style={{ minHeight: "28px", border: "none", borderBottom: "none", padding: ' 5px 10px', width: '20%', }}>City</div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderBottom: "none", padding: ' 5px 10px', width: '20%', }}></div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none", borderBottom: "none", width: '17%', padding: ' 5px 10px', fontWeight: "bold" }}></div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none", borderBottom: "none", width: '14%', padding: ' 5px 10px', fontWeight: "bold" }}></div>
                                    </div>
                                    <div className='d-flex '>
                                        <div className='align-items-center text-right' style={{ minHeight: "28px", border: "none", borderBottom: "none", padding: ' 5px 10px', width: '10%', }}></div>
                                        <div className='align-items-center text-right' style={{ minHeight: "28px", border: "none", borderBottom: "none", padding: ' 5px 10px', width: '20%', }}>District</div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderBottom: "none", padding: ' 5px 10px', width: '20%', }}></div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none", borderBottom: "none", width: '17%', padding: ' 5px 10px', fontWeight: "bold" }}></div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none", borderBottom: "none", width: '14%', padding: ' 5px 10px', fontWeight: "bold" }}></div>
                                    </div>
                                    <div className='d-flex '>
                                        <div className='align-items-center text-right' style={{ minHeight: "28px", border: "none", borderBottom: "none", padding: ' 5px 10px', width: '10%', }}></div>
                                        <div className='align-items-center text-right' style={{ minHeight: "28px", border: "none", borderBottom: "none", padding: ' 5px 10px', width: '20%', }}>Province</div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderBottom: "none", padding: ' 5px 10px', width: '20%', }}></div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none", borderBottom: "none", width: '17%', padding: ' 5px 10px', fontWeight: "bold" }}></div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none", borderBottom: "none", width: '14%', padding: ' 5px 10px', fontWeight: "bold" }}></div>
                                    </div>
                                    <div className='d-flex '>
                                        <div className='align-items-center text-right' style={{ minHeight: "28px", border: "none", borderBottom: "none", padding: ' 5px 10px', width: '10%', }}></div>
                                        <div className='align-items-center text-right' style={{ minHeight: "28px", border: "none", borderBottom: "none", padding: ' 5px 10px', width: '20%', }}>Landline Number</div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderBottom: "none", padding: ' 5px 10px', width: '20%', }}></div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none", borderBottom: "none", width: '17%', padding: ' 5px 10px', fontWeight: "bold" }}></div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none", borderBottom: "none", width: '14%', padding: ' 5px 10px', fontWeight: "bold" }}></div>
                                    </div>
                                    <div className='d-flex '>
                                        <div className='align-items-center text-right' style={{ minHeight: "28px", border: "none", borderBottom: "none", padding: ' 5px 10px', width: '10%', }}></div>
                                        <div className='align-items-center text-right' style={{ minHeight: "28px", border: "none", borderBottom: "none", padding: ' 5px 10px', width: '20%', }}>Mobile Number of Principal Officer</div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderBottom: "none", padding: ' 5px 10px', width: '20%', }}></div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none", borderBottom: "none", width: '17%', padding: ' 5px 10px', fontWeight: "bold" }}></div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none", borderBottom: "none", width: '14%', padding: ' 5px 10px', fontWeight: "bold" }}></div>
                                    </div>
                                    <div className='d-flex '>
                                        <div className='align-items-center text-right' style={{ minHeight: "28px", border: "none", borderBottom: "none", padding: ' 5px 10px', width: '10%', }}></div>
                                        <div className='align-items-center text-right' style={{ minHeight: "28px", border: "none", borderBottom: "none", padding: ' 5px 10px', width: '20%', }}>Email Address</div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderBottom: "none", padding: ' 5px 10px', width: '20%', }}></div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none", borderBottom: "none", width: '17%', padding: ' 5px 10px', fontWeight: "bold" }}></div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none", borderBottom: "none", width: '14%', padding: ' 5px 10px', fontWeight: "bold" }}></div>
                                    </div>
                                    <div className='d-flex '>
                                        <div className='align-items-center text-right' style={{ minHeight: "28px", padding: ' 5px 10px', width: '10%', }}></div>
                                        <div className='align-items-center text-right' style={{ minHeight: "28px", padding: ' 5px 10px', width: '20%', }}>Website, if any</div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", padding: ' 5px 10px', width: '20%', }}></div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none", width: '17%', padding: ' 5px 10px', fontWeight: "bold" }}></div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none", width: '14%', padding: ' 5px 10px', fontWeight: "bold" }}></div>
                                    </div>
                                    <div style={{ padding: "5px 10px" }}>
                                        <i>(Here state full address of all places of business in Pakistan, add further pages if required)</i>
                                    </div>
                                    <PartsWrapper className='mt-4 mb-3'>
                                        PART-VII
                                    </PartsWrapper>

                                    <div className='row mt-4'>
                                        <div className='col-md-12'>
                                            <HeadingWrapper className='text-center d-flex justify-content-center'>
                                                PARTICULARS OF DIRECTORS, CHIEF EXECUTIVE, SECRETARY, PRINCIPAL OFFICER AND PERSON(S) AUTHORIZED TO ACCEPT DOCUMENTS/ PROCESSES ON BEHALF OF COMPANY
                                            </HeadingWrapper>
                                        </div>
                                    </div>

                                    <div className='d-flex align-items-center mt-3'>
                                        <div style={{ padding: "5px 10px" }}>
                                            7.1
                                        </div>
                                        <div style={{ padding: "5px 10px", fontWeight: "bold" }}>
                                            Particulars of Directors, Chief Executive, Secretary, Principal officer or Person(s) authorized to accept documents/processes on behalf of Company (in case of individuals only):
                                        </div>
                                    </div>
                                    <Wrapper className="table-responsive mt-0" >
                                        <table
                                            className="table table-bordered"
                                            style={{ fontFamily: "Montserrat rev=1 !important" }}
                                        >
                                            <tbody>
                                                <tr>
                                                    <td style={{   width: '7%',writingMode:"vertical-rl", textOrientation:"mixed",transform:"rotate(180deg)",height:"250px",textAlign:"center" }}>Present and surname in full</td>
                                                    <td style={{   width: '7%',writingMode:"vertical-rl", textOrientation:"mixed",transform:"rotate(180deg)",height:"250px",textAlign:"center" }}>Former & surname (if any)</td>
                                                    <td style={{   width: '7%',writingMode:"vertical-rl", textOrientation:"mixed",transform:"rotate(180deg)",height:"250px",textAlign:"center" }}>Usual resident address</td>
                                                    <td style={{   width: '7%',writingMode:"vertical-rl", textOrientation:"mixed",transform:"rotate(180deg)",height:"250px",textAlign:"center" }}>Nationality</td>
                                                    <td style={{   width: '7%',writingMode:"vertical-rl", textOrientation:"mixed",transform:"rotate(180deg)",height:"250px",textAlign:"center" }}>Nationality of origin(if other than the present nationality)</td>
                                                    <td style={{   width: '7%',writingMode:"vertical-rl", textOrientation:"mixed",transform:"rotate(180deg)",height:"250px",textAlign:"center" }}>Occupation</td>
                                                    <td style={{  width: '7%',writingMode:"vertical-rl", textOrientation:"mixed",transform:"rotate(180deg)",height:"250px",textAlign:"center" }}>Other directorship.If any</td>
                                                    <td style={{   width: '7%',writingMode:"vertical-rl", textOrientation:"mixed",transform:"rotate(180deg)",height:"250px" ,textAlign:"center"}}>Status / Designation(Director / Chief Executive, Principal officer, Authorized person resident in Pakistan)</td>
                                                    <td style={{  width: '7%',writingMode:"vertical-rl", textOrientation:"mixed",transform:"rotate(180deg)",height:"250px",textAlign:"center" }}>N.I.C Number or Passport Number (Whichever is applicable)</td>
                                                    <td style={{  width: '7%',writingMode:"vertical-rl", textOrientation:"mixed",transform:"rotate(180deg)",height:"250px",textAlign:"center" }}>Date of appointment/ Cessation/ change in particulars</td>
                                                    <td style={{  width: '7%',writingMode:"vertical-rl", textOrientation:"mixed",transform:"rotate(180deg)",height:"250px",textAlign:"center" }}>Remarks, if any</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%',textAlign:"center" }}>(a)</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%',textAlign:"center" }}>(b)</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%',textAlign:"center" }}>(c)</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%',textAlign:"center" }}>(d)</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%',textAlign:"center" }}>(e)</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%',textAlign:"center" }}>(f)</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%',textAlign:"center" }}>(g)</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%',textAlign:"center" }}>(h)</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%',textAlign:"center" }}>(i)</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%',textAlign:"center" }}>(j)</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%',textAlign:"center" }}>(k)</td>
                                                </tr>
                                               

                                            </tbody>
                                        </table>
                                    </Wrapper>

                                    <div style={{ padding: "5px 10px" }}>
                                        <i>Note: In case of Secretary, particulars as per (a) & (c) may be stated. </i>
                                    </div>

                                    <div className='d-flex align-items-center mt-3'>
                                        <div style={{ padding: "5px 10px" }}>
                                            7.1.1
                                        </div>
                                        <div style={{ padding: "5px 10px", fontWeight: "bold" }}>
                                            Particulars of Directors and Secretary (In case of body corporate):
                                        </div>
                                    </div>

                                    <div className='d-flex'>
                                        <div>
                                            <div className='d-flex '>
                                                <div className='align-items-center ' style={{ minHeight: "75px", border: "1px solid #121212", padding: ' 5px 10px', width: '87.1%' }}>Particulars of each corporate body which is director/secretary of the foreign company</div>
                                            </div>
                                            <div className='d-flex '>
                                                <div className='align-items-center ' style={{ height: "175px", border: "1px solid #121212", padding: ' 5px 10px', borderTop: "none", width: '20%' }}>Corporate Name</div>
                                                <div className='align-items-center ' style={{ height: "175px", border: "1px solid #121212", borderLeft: "none", borderTop: "none", width: '20%', padding: ' 5px 10px' }}>Registeredor Principal Office address</div>
                                                <div className='align-items-center ' style={{ height: "175px", border: "1px solid #121212", borderLeft: "none", borderTop: "none", width: '17%', padding: ' 5px 10px' }}>Status /Designation(Director/Secretary)</div>
                                                <div className='align-items-center ' style={{ height: "175px", border: "1px solid #121212", borderLeft: "none", borderTop: "none", width: '15%', padding: ' 5px 10px' }}>Date of appointment/Cessation/change inparticulars</div>
                                                <div className='align-items-center ' style={{ height: "175px", border: "1px solid #121212", borderLeft: "none", borderTop: "none", width: '15.1%', padding: ' 5px 10px' }}>Remarks</div>
                                            </div>
                                            <div className='d-flex '>
                                                <div className='align-items-center  text-center' style={{ minHeight: "28px", border: "1px solid #121212", padding: ' 5px 10px', borderTop: "none", width: '20%' }}>(a)</div>
                                                <div className='align-items-center  text-center' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none", borderTop: "none", width: '20%', padding: ' 5px 10px' }}>(b)</div>
                                                <div className='align-items-center  text-center' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none", borderTop: "none", width: '17%', padding: ' 5px 10px' }}>(c)</div>
                                                <div className='align-items-center  text-center' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none", borderTop: "none", width: '15%', padding: ' 5px 10px' }}>(e)</div>
                                                <div className='align-items-center text-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none", borderTop: "none", width: '15.1%', padding: ' 5px 10px' }}>(f)</div>
                                            </div>
                                            <div className='d-flex '>
                                                <div className='align-items-center ' style={{ minHeight: "32px", border: "1px solid #121212", padding: ' 5px 10px', borderTop: "none", width: '20%' }}></div>
                                                <div className='align-items-center ' style={{ minHeight: "32px", border: "1px solid #121212", borderLeft: "none", borderTop: "none", width: '20%', padding: ' 5px 10px' }}></div>
                                                <div className='align-items-center ' style={{ minHeight: "32px", border: "1px solid #121212", borderLeft: "none", borderTop: "none", width: '17%', padding: ' 5px 10px' }}></div>
                                                <div className='align-items-center ' style={{ minHeight: "32px", border: "1px solid #121212", borderLeft: "none", borderTop: "none", width: '15%', padding: ' 5px 10px' }}></div>
                                                <div className='align-items-center ' style={{ minHeight: "32px", border: "1px solid #121212", borderLeft: "none", borderTop: "none", width: '15.1%', padding: ' 5px 10px' }}></div>
                                            </div>
                                            <div className='d-flex '>
                                                <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", padding: ' 5px 10px', borderTop: "none", width: '20%' }}></div>
                                                <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none", borderTop: "none", width: '20%', padding: ' 5px 10px' }}></div>
                                                <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none", borderTop: "none", width: '17%', padding: ' 5px 10px' }}></div>
                                                <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none", borderTop: "none", width: '15%', padding: ' 5px 10px' }}></div>
                                                <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none", borderTop: "none", width: '15.1%', padding: ' 5px 10px' }}></div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className='d-flex '>
                                                <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", padding: ' 5px 10px', borderBottom: "none", width: '100%' }}>Particulars of each director/partner of corporate body which is director/secretary of the foreign company</div>
                                            </div>
                                            <div className='d-flex '>
                                                <div className='align-items-center text-center' style={{ height: "175px", border: "1px solid #121212", padding: ' 5px 10px', width: '22%' }}><span className='text-center d-flex align-items-center h-100'>Full Name *</span></div>
                                                <div className='align-items-center text-center' style={{ height: "175px", border: "1px solid #121212", borderLeft: "none", width: '26%', padding: ' 5px 10px' }}><span style={{ writingMode: "vertical-rl", transform: "rotate(-180deg)", height: "100%", textAlign: "center" }}>Address*</span></div>
                                                <div className='align-items-center text-center' style={{ height: "175px", border: "1px solid #121212", borderLeft: "none", width: '29%', padding: ' 5px 10px' }}><span style={{ writingMode: "vertical-rl", transform: "rotate(-180deg)", height: "100%", textAlign: "center" }}>Nationality</span></div>
                                                <div className='align-items-center text-center' style={{ height: "175px", border: "1px solid #121212", borderLeft: "none", width: '39%', padding: ' 5px 10px' }}><span style={{ writingMode: "vertical-rl", transform: "rotate(-180deg)", height: "100%", textAlign: "center" }}>Nationality of origin (if other than the present nationality)</span></div>
                                            </div>
                                            <div className='d-flex '>
                                                <div className='align-items-center text-center' style={{ minHeight: "28px", border: "1px solid #121212", padding: ' 5px 10px', width: '19.5%', borderTop: "none" }}>(g)</div>
                                                <div className='align-items-center text-center' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none", width: '22.1%', padding: ' 5px 10px', borderTop: "none" }}>(h)</div>
                                                <div className='align-items-center text-center' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none", width: '25.2%', padding: ' 5px 10px', borderTop: "none" }}>(i)</div>
                                                <div className='align-items-center text-center' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none", width: '33.23%', padding: ' 5px 10px', borderTop: "none" }}>(j)</div>
                                            </div>
                                            <div className='d-flex '>
                                                <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", padding: ' 5px 10px', width: '19.5%', borderTop: "none" }}></div>
                                                <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none", width: '22.1%', padding: ' 5px 10px', borderTop: "none" }}></div>
                                                <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none", width: '25.2%', padding: ' 5px 10px', borderTop: "none" }}></div>
                                                <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none", width: '33.23%', padding: ' 5px 10px', borderTop: "none" }}></div>
                                            </div>
                                            <div className='d-flex '>
                                                <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", padding: ' 5px 10px', width: '19.5%', borderTop: "none" }}></div>
                                                <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none", width: '22.1%', padding: ' 5px 10px', borderTop: "none" }}></div>
                                                <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none", width: '25.2%', padding: ' 5px 10px', borderTop: "none" }}></div>
                                                <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none", width: '33.23%', padding: ' 5px 10px', borderTop: "none" }}></div>
                                            </div>


                                        </div>
                                    </div>
                                    <div>
                                        *&nbsp;&nbsp;&nbsp;&nbsp;  <i> Where a body corporate is a secretary and all the partners of the firm are joint secretaries of the foreign company, then the name and principal office of the body corporate may be stated instead of particulars of each such secretary.</i>
                                    </div>

                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center text-center' style={{ minHeight: "28px", border: "1px solid #121212", borderBottom: 'none', padding: ' 5px 10px', width: '100%' }}>Particulars of the nominee directors</div>
                                    </div>
                                    <div className='d-flex '>
                                        <div className='d-flex'>
                                            <div className='align-items-center text-center' style={{ minHeight: "28px", border: "1px solid #121212", padding: ' 5px 10px', width: '50%' }}>Name of the nominee director</div>
                                            <div className='align-items-center text-center' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none", width: '50%', padding: ' 5px 10px' }}>Name of the member nominating the director</div>
                                        </div>
                                        <div>
                                            <div className='d-flex flex-column'>
                                                <div className='align-items-center text-center' style={{ minHeight: "28px", border: "1px solid #121212", borderBottom: "none", borderLeft: "none", padding: ' 5px 10px', width: '100%' }}>In case the nominating member is a legal person or legal arrangement</div>
                                                <div className='d-flex'>
                                                    <div className='align-items-center text-center' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none", padding: ' 5px 10px', width: '25%' }}>Legal form (Company/LLP/Partnership Firm/Trust/Any other body corporate (to be specified))</div>
                                                    <div className='align-items-center text-center' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none", width: '25%', padding: ' 5px 10px' }}>Date of incorporation/ registration</div>
                                                    <div className='align-items-center text-center' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none", width: '25%', padding: ' 5px 10px' }}>Name of registration authority</div>
                                                    <div className='align-items-center text-center' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none", width: '25%', padding: ' 5px 10px' }}>Business address</div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className='d-flex'>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderTop: "none", padding: ' 5px 10px', width: '69.9%' }}></div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: 'none', borderTop: "none", padding: ' 5px 10px', width: '70%' }}></div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: 'none', borderTop: "none", padding: ' 5px 10px', width: '80%' }}></div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: 'none', borderTop: "none", padding: ' 5px 10px', width: '80%' }}></div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: 'none', borderTop: "none", padding: ' 5px 10px', width: '80%' }}></div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: 'none', borderTop: "none", padding: ' 5px 10px', width: '80%' }}></div>
                                    </div>

                                    <div className='d-flex align-items-start mt-3'>
                                        <div style={{ padding: "5px 10px" }}>
                                            7.2
                                        </div>
                                        <div style={{ padding: "5px 10px" }}>
                                            It is hereby declared that necessary information about the ultimate beneficial owners of the foreign company, if any, as specified in regulation 23, and as defined in the Companies Regulations, 2024 has been obtained and is available on record.
                                        </div>
                                    </div>


                                    <PartsWrapper className='mt-4 mb-3'>
                                        PART-VIII
                                    </PartsWrapper>
                                    <div className='d-flex align-items-center mt-3'>
                                        <div style={{ padding: "5px 10px" }}>
                                            8.1
                                        </div>
                                        <div style={{ padding: "10px 10px 0px 10px" }}>
                                            Declaration
                                        </div>
                                    </div>
                                    <div style={{ marginLeft: '35px' }} className='d-flex flex-column mt-3'>

                                        <div style={{ padding: "5px 10px" }}>
                                            I do hereby solemnly and sincerely declare that the information provided in the form is:
                                        </div>
                                        <div className='d-flex align-items-baseline' style={{ padding: "5px 10px" }}>
                                            <div>(i)</div> <div style={{ marginLeft: "24px" }}>true and correct to the best of my knowledge, in consonance with the record as maintained by the Company and nothing has been concealed; and</div>
                                        </div>
                                        <div className='d-flex align-items-baseline' style={{ padding: "5px 10px" }}>
                                            <div>(ii)</div><div style={{ marginLeft: '20px' }}>hereby reported after complying with and fulfilling all requirements under the relevant provisions of law, rules, regulations, directives, circulars and notifications whichever is applicable</div>
                                        </div>
                                    </div>

                                    <div className='d-flex align-items-start mt-3'>
                                        <div style={{ padding: "5px 10px" }}>
                                            8.2
                                        </div>
                                        <div style={{ padding: "5px 10px", width: '29%' }}>
                                            Name of Authorized Officer with designation/ Authorized Intermediary
                                        </div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '34%', minHeight: "45px", padding: '5px 10px', }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '34%', minHeight: "45px", padding: '5px 10px', borderLeft: "none" }}></ScrollWrapper>

                                    </div>
                                    <div className='d-flex align-items-center mt-3'>
                                        <div style={{ padding: "5px 10px" }}>
                                            8.3
                                        </div>
                                        <div style={{ padding: "5px 10px", width: '35%' }}>
                                            Signatures
                                        </div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '62%', minHeight: "45px", padding: '5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>

                                    </div>
                                    <div className='d-flex align-items-center mt-3'>
                                        <div style={{ padding: "5px 10px" }}>
                                            8.4
                                        </div>
                                        <div style={{ padding: "5px 10px", width: "46%" }}>
                                            Registration No of Authorized Intermediary, if applicable
                                        </div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '51%', minHeight: "45px", padding: '5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>

                                    </div>
                                    <div className='d-flex align-items-start mt-3'>
                                        <div style={{ padding: "5px 10px" }}>
                                            8.5
                                        </div>
                                        <div style={{ padding: "5px 10px", width: '28%' }}>
                                            Contact details of the applicant, i.e. address, email and cell No. etc.
                                        </div>
                                        <div style={{ width: '100%' }}>
                                            <div className='d-flex '>
                                                <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderBottom: "none", padding: ' 5px 10px', width: '20%', }}>Address</div>
                                                <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderBottom: "none", borderLeft: "none", padding: ' 5px 10px', width: '80%', fontWeight: "bold" }}></div>
                                            </div>
                                            <div className='d-flex '>
                                                <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderBottom: "none", padding: ' 5px 10px', width: '20%', }}>Email</div>
                                                <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderBottom: "none", borderLeft: "none", padding: ' 5px 10px', width: '80%', fontWeight: "bold" }}></div>
                                            </div>
                                            <div className='d-flex '>
                                                <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", padding: ' 5px 10px', width: '20%', }}>Cell No.</div>
                                                <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none", padding: ' 5px 10px', width: '80%', fontWeight: "bold" }}></div>
                                            </div>
                                        </div>

                                    </div>


                                    <div className='d-flex mt-0'>
                                        <div className='align-items-center' style={{ padding: "5px 10px", width: '7%' }}></div>
                                        <div className='align-items-center' style={{ width: '55%', padding: ' 5px 10px' }}> </div>
                                        <div className='align-items-center text-center' style={{ width: '8%', padding: ' 10px 10px 0px 10px' }}> Day</div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center text-center' style={{ width: '8%', padding: ' 10px 10px 0px 10px' }}> Month</div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center text-center ' style={{ width: '16%', padding: ' 10px 10px 0px 10px' }}>Year </div>
                                        <div></div>
                                    </div>
                                    <div className='d-flex '>
                                        <div className='align-items-center' style={{ padding: "0px 10px 10px 10px", }}>3.6</div>
                                        <div className='align-items-center' style={{ width: '60%', padding: ' 0px 10px 10px 10px' }}>Date</div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ border: '1px solid #121212', width: '4%', padding: ' 5px 10px' }}> </div>
                                        <div className='align-items-center' style={{ border: '1px dashed #dddcdc', paddingLeft: '8px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ border: '1px solid #121212', width: '4%', padding: ' 5px 10px', borderRight: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ paddingLeft: '8px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', border: '1px solid #121212', }}> </div>
                                    </div>

                                    <div className='mt-4'>
                                        <div className="d-flex align-items-start">
                                            <div style={{ marginLeft: "8px" }}>1.</div><div style={{ marginLeft: "23px" }}>Certified copy of the charter/statute/memorandum and articles of association or instrument defining the constitution of the foreign company</div>
                                        </div>
                                        <div className="d-flex align-items-start">
                                            <div style={{ marginLeft: "8px" }}>2.</div><div style={{ marginLeft: "20px" }}>Board Resolution regarding appointment of the principal officer and consent of person authorized to accept on behalf of the company service of process / any notice / document</div>
                                        </div>
                                        <div className="d-flex align-items-start">
                                            <div style={{ marginLeft: "8px" }}>3.</div><div style={{ marginLeft: "20px" }}>Consent of the Principal Officer and persons authorized to accept on behalf of the company service of process / any notice / document</div>
                                        </div>
                                        <div className="d-flex align-items-start">
                                            <div style={{ marginLeft: "8px" }}>4.</div><div style={{ marginLeft: "19px" }}>Certified translation of instrument constituting the foreign company, if applicable</div>
                                        </div>
                                        <div className="d-flex align-items-start">
                                            <div style={{ marginLeft: "8px" }}>5.</div><div style={{ marginLeft: "20px" }}>Valid approval letter from Board of Investment, Government of Pakistan, if not already provided with the application for reservation of name.</div>
                                        </div>
                                        <div className="d-flex align-items-start">
                                            <div style={{ marginLeft: "8px" }}>6.</div><div style={{ marginLeft: "20px" }}>Original challan or other evidence of payment of fee specified in Seventh Schedule of the Act (not applicable in case of online filing)</div>
                                        </div>
                                        <div className="d-flex align-items-start">
                                            <div style={{ marginLeft: "8px" }}>7.</div><div style={{ marginLeft: "21px" }}>Any other document deemed necessary</div>
                                        </div>
                                    </div>



                                </div>
                            </div>
                        </div>
                    </PDFExport>}
            </div>
            <button disabled={loading} className='btn btn-danger' onClick={(e) => {
                // if(viewPdf)
                //   SetViewPDf(true)
                if (pdfExportComponent.current) {
                    pdfExportComponent.current.save();
                }
            }}>Download PDF</button>
        </div>
    )
}
const PartsWrapper = styled.div`
color: #121212;
text-decoration: underline;
justify-content: center;
display: flex;
margin-right: 15px;
font-size: 17px;
font-weight: bold;
`;
const ScrollWrapper = styled.div`
max-height: 50px;
overflow-y: scroll;
overflow-x: hidden;
::-webkit-scrollbar{
    height: 5px;
    width: 3px;
  }
  
  ::-webkit-scrollbar-track{
    background: #F9F9FB;
  }
  ::-webkit-scrollbar-thumb{
    background: #4E515680;
    border-radius: 5px;
  
  }
`;
const HeadingWrapper = styled.div`
font-size: 17px;
font-weight: bold;
`;
const FormName = styled.div`
color: #121212;
text-decoration: underline;
justify-content: end;
display: flex;
margin-right: 15px;
font-size: 17px;
font-weight: bold;
`;
const Wrapper = styled.div`
table.table-bordered{
    border: 1px solid #121212
     border-color: #121212; 
    // border:1px solid blue;
    // margin-top:20px;
  }
// table.table-bordered > thead > tr > th{
//     // border:1px solid blue;
//     border-color: #121212; 
// }
// table.table-bordered > tbody > tr > td{
//     // border:1px solid blue;
//     borderC-color: #121212; 
// }
 tr td {
    // border:1px solid blue;.
    border-color: #121212; 
}

`;
const WrapperDashed = styled.div`

  td {
    
    border: 1px dashed #dddcdc;
}

`;