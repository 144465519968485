import React, { useEffect, useState } from "react";
import { RiCalculatorFill, RiDeleteBin5Line } from "react-icons/ri";
import { VscHistory } from "react-icons/vsc";
import { FaCalculator } from "react-icons/fa";
import { Container, Row, Col, UncontrolledTooltip } from "reactstrap";
import { numberWithCommas } from "utilities/utilityFunctions";
import { IoMdClose } from "react-icons/io";
import "./instantCalculator.css";

const FixedPlugin = (props) => {
  const {
    calculatorFlag,
    setCalculatorFlag,
    setHistory,
    history
  } = props

  // const [calculatorFlag, setCalculatorFlag] = useState(false);

  let [input, setInput] = useState("0");
  let [input1, setInput1] = useState(0);
  let [percent, setPercent] = useState(0);
  const [screen, setScreen] = useState();
  let [output, setOutput] = useState("");
  let [keyPress, setKeyPress] = useState("");
  // const [history, setHistory] = useState(false);
  const [calculationHistory, setCalculationHistory] = useState([]);
  const [input1Focused, setIsInput1Focused] = useState(false);
  const inputRef = React.useRef(null);

  useEffect(() => {
    if (input1 >= 1) handleEqual();
  }, [input1]);

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown1);
    return () => {
      document.removeEventListener("keydown", handleKeyDown1);
    };
  }, []);
  useEffect(() => {
    const handleResize = () => {
      setScreen(window?.outerWidth);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleKeyDown1 = (event) => {
    if (event.keyCode === 27) {
      setInput("0");
      setOutput("");
    }
    if (
      (event.keyCode === 13 && event.key === "Enter") ||
      (event.keyCode === 61 && event.key === "=")
    ) {
      setInput1(1);
    }
  };

  const handleEqual = () => {
    setInput1(0);
    if (input !== "") {
      if (input !== "0") {
        try {
          let result = eval(input.replaceAll("%", "/100")).toString();
          setOutput(result);
          setCalculationHistory((prevHistory) => [
            ...prevHistory,
            `${input.toString()} = ${result.includes(".")
              ? numberWithCommas(parseFloat(result || "").toFixed(4))
              : result
            }`,
          ]);
          setInput("0");
        } catch (error) {
          setOutput("Error");
        }
      }
    }
  };

  const checkOperator = (expression) => {
    const operators = ["*-", "/-"];
    for (const operator of operators) {
      if (expression.includes(operator)) {
        const index = expression.indexOf(operator);
        if (
          index + 2 < expression.length &&
          /[+\-*/]/.test(expression[index + 2])
        ) {
          return false;
        }
      }
    }
    return true;
  };

  const checkDoubleOperator = (expression) => {
    const operators = ["+", "-", "*", "/"];
    for (let i = 0; i < expression.length - 1; i++) {
      const currentChar = expression[i];
      const nextChar = expression[i + 1];
      if (operators.includes(currentChar) && currentChar === nextChar) {
        return false;
      }
    }
    return true;
  };

  function checkDecimalPoints(data) {
    for (let i = 0; i < data.length; i++) {
      const decimalCount = data[i].split(".").length - 1;
      if (decimalCount > 1) {
        return false;
      }
    }
    return true;
  }

  const handleInputChange = async (event) => {
    const { value, selectionStart, selectionEnd } = event.target;
    let valueBeforeCursor = value.substring(0, selectionStart);
    let valueAfterCursor = value.substring(selectionStart);
    let inputValue = event.target.value === "" ? "0" : event.target.value;

    if (
      (inputRef.current.selectionStart === 1 &&
        inputValue === "0" &&
        input !== "0.") ||
      event.target.value.charAt(0) === "*" ||
      (event.target.value.charAt(0) === "0" &&
        event.target.value.charAt(1) === "*") ||
      event.target.value.charAt(0) === "%" ||
      (event.target.value.charAt(0) === "0" &&
        event.target.value.charAt(1) === "%") ||
      event.target.value.charAt(0) === "/" ||
      (event.target.value.charAt(0) === "0" &&
        event.target.value.charAt(1) === "/")
    ) {
      return;
    } else {
      let pointVal = "";
      const operators = ["*", "/", "-", "+", "%"];
      let valueAfterLastOperator = "";
      let expression = valueBeforeCursor;
      let regixoperators = /[*/+\-%]/g;
      let matches = expression.match(regixoperators);
      let lastOperator = matches ? matches.pop() : null;
      let valueBeforeFirstOperator = "";

      if (!expression.includes(lastOperator)) {
        valueBeforeFirstOperator = expression;
      } else {
        valueBeforeFirstOperator = "";
      }
      const parts = expression.split(lastOperator);

      if (parts.length > 1) {
        valueAfterLastOperator = parts[parts.length - 1];
      }
      const regex = /[1-9]/;

      if (
        regex.test(valueAfterLastOperator?.charAt(1)) &&
        valueAfterLastOperator.charAt(0) === "0"
      ) {
        valueAfterLastOperator = valueAfterLastOperator.replace(
          valueAfterLastOperator.charAt(0),
          ""
        );
        valueBeforeCursor = valueBeforeCursor.slice(0, -1);
        valueBeforeCursor = valueBeforeCursor.slice(0, -1);
        const modifiedValue =
          valueBeforeCursor + valueAfterLastOperator + valueAfterCursor;

        inputRef.current.value = modifiedValue;
        inputValue = modifiedValue;
      }

      if (
        valueAfterLastOperator.charAt(0) === "0" &&
        valueAfterLastOperator?.charAt(1) !== "." &&
        regex.test(valueAfterCursor?.charAt(0))
      ) {
        valueAfterLastOperator = valueAfterLastOperator.replace(
          valueAfterLastOperator.charAt(0),
          ""
        );
        valueBeforeCursor = valueBeforeCursor.slice(0, -1);
        const modifiedValue =
          valueBeforeCursor + valueAfterLastOperator + valueAfterCursor;

        inputRef.current.value = modifiedValue;
        inputValue = modifiedValue;

        const newCursorPosition = valueBeforeCursor.length - 1 + 1;

        inputRef.current.setSelectionRange(
          newCursorPosition,
          newCursorPosition
        );

        inputRef.current.focus();
      }

      if (valueAfterLastOperator === ".") {
        const modifiedValue =
          valueBeforeCursor.slice(0, -1) + "0." + valueAfterCursor;

        inputRef.current.value = modifiedValue;
        inputValue = modifiedValue;

        const newCursorPosition = valueBeforeCursor.length - 1 + "0.".length;

        inputRef.current.setSelectionRange(
          newCursorPosition,
          newCursorPosition
        );

        inputRef.current.focus();
      }
      let flag = checkDecimalPoints(
        inputValue.split(/[\+\-\*\/%]/g).filter((operator) => operator !== "")
      );
      if (
        event.nativeEvent.inputType === "deleteContentBackward" ||
        event.nativeEvent.inputType === "deleteContentForward"
      ) {
        setInput(inputValue === "" ? "0" : inputValue);
        try {
          let result = await eval(inputValue.replaceAll("%", "/100"));
          setOutput(result);
        } catch (e) {
          if (inputValue !== "") {
            return;
          }
          setOutput("");
        }
      } else {
        const isValidExpression =
          flag &&
          /^[0-9+\-*.\/%]+(\.[0-9]+)?$/.test(inputValue) &&
          (valueBeforeFirstOperator.match(/\./g) || []).length <= 1 &&
          (valueAfterLastOperator.match(/\./g) || []).length <= 1 &&
          !valueAfterLastOperator.startsWith("00") &&
          !inputValue.endsWith("%%") &&
          !/(\+[\-]|[\-]\+|\-[*/])/.test(inputValue) &&
          !/([+\/*]{2,}|(?<=\d)-{2,})/.test(inputValue) &&
          checkOperator(inputValue) &&
          checkDoubleOperator(inputValue);

        if (isValidExpression) {
          if (inputValue.charAt(0) === "0" && inputValue.charAt(1) != ".") {
            if (inputValue.charAt(0)) inputValue = inputValue.slice(1);
          }
          setInput(
            inputValue === ""
              ? "0"
              : inputValue === "."
                ? "0" + inputValue
                : inputValue
          );

          let operatorAtLast = ["+", "-", "*", "/"].includes(
            inputValue.charAt(inputValue.length - 1)
          );
          inputValue = operatorAtLast ? inputValue.slice(0, -1) : inputValue;
          if (
            ["+", "-", "*", "/"].includes(
              inputValue.charAt(inputValue.length - 1)
            ) === false
          ) {
            try {
              let result = await eval(
                inputValue === "" ? "0" : inputValue.replaceAll("%", "/100")
              ).toString();
              setOutput(result);
            } catch (e) {
              setOutput("Error");
              if (inputValue !== "") {
                return;
              }
            }
          }
        } else {
          if (selectionStart > 0 && selectionStart < value.length) {
            setInput((prevValue) => {
              const newCursorPosition = selectionStart + 1;

              setTimeout(() => {
                event.target.setSelectionRange(
                  newCursorPosition - 2,
                  newCursorPosition - 2
                );
              }, 0.0001);
              prevValue =
                prevValue.slice(0, selectionStart) +
                prevValue.slice(selectionStart);
              return prevValue;
            });
          }
        }
      }
    }
  };

  const handleInput1 = async (value) => {
    if (value === "0" && (input === "0" || input.length === 0)) {
      return;
    } else {
      const inputEl = inputRef.current;
      const startPos = inputEl.selectionStart;
      const endPos = inputEl.selectionEnd;
      let currentValue = inputEl.value;
      let inputData =
        currentValue.slice(0, startPos) + value + currentValue.slice(endPos);
      const operators = ["*", "/", "-", "+", "%"];
      let valueAfterLastOperator = "";
      let expression = currentValue.slice(0, startPos) + value;
      let regixoperators = /[*/+\-%]/g;
      let matches = expression.match(regixoperators);
      let lastOperator = matches ? matches.pop() : null;
      let valueBeforeFirstOperator = "";

      if (!expression.includes(lastOperator)) {
        valueBeforeFirstOperator = expression;
      } else {
        valueBeforeFirstOperator = "";
      }
      const parts = expression.split(lastOperator);
      if (parts.length > 1) {
        valueAfterLastOperator = parts[parts.length - 1];
      }

      if (valueAfterLastOperator === ".") {
        value = "0.";
        inputData =
          currentValue.slice(0, startPos) + value + currentValue.slice(endPos);
      }

      const regex = /[1-9]/;

      if (
        regex.test(valueAfterLastOperator?.charAt(1)) &&
        valueAfterLastOperator.charAt(0) === "0"
      ) {
        currentValue = currentValue.slice(0, startPos);
        currentValue = currentValue.slice(0, -1);
        inputData = currentValue + value + currentValue.slice(endPos);
      }
      if (
        valueAfterLastOperator.charAt(0) === "0" &&
        valueAfterLastOperator?.charAt(1) !== "." &&
        regex.test(currentValue.slice(endPos)?.charAt(0))
      ) {
        value = "";
        inputEl.focus();
        return;
      }
      let flag1 = checkDecimalPoints(
        inputData.split(/[\+\-\*\/%]/g).filter((operator) => operator !== "")
      );
      const isValidExpression =
        flag1 &&
        /^[0-9+\-*.\/%]+(\.[0-9]+)?$/.test(
          currentValue.slice(0, startPos) + value
        ) &&
        !(currentValue.slice(0, startPos) + value).startsWith("0*") &&
        !(currentValue.slice(0, startPos) + value).startsWith("*") &&
        !(currentValue.slice(0, startPos) + value).startsWith("0/") &&
        !(currentValue.slice(0, startPos) + value).startsWith("/") &&
        (valueBeforeFirstOperator.match(/\./g) || []).length <= 1 &&
        (valueAfterLastOperator.match(/\./g) || []).length <= 1 &&
        !valueAfterLastOperator.startsWith("00") &&
        !(currentValue.slice(0, startPos) + value).endsWith("%%") &&
        !/([+\/*]{2,}|(?<=\d)-{2,})/.test(inputData) &&
        !/(\+[\-]|[\-]\+|\-[*/])/.test(inputData);

      let flag = false;
      if (currentValue.slice(endPos).length) {
        flag = true;
      }
      if (
        isValidExpression &&
        checkOperator(currentValue.slice(0, startPos) + value) === true &&
        checkDoubleOperator(currentValue.slice(0, startPos) + value) === true
      ) {
        let outputVal =
          currentValue.slice(0, startPos) + value + currentValue.slice(endPos);

        setInput((prevInput) =>
          (outputVal.charAt(0) === "0" && outputVal.charAt(1)) == "."
            ? outputVal
            : outputVal.charAt(0) === "0"
              ? outputVal.substring(1)
              : outputVal
        );

        if (flag || ["+", "-", "*", "/"].includes(value) === false) {
          if (keyPress !== "Enter") {
            inputData =
              inputData.charAt(0) === "0" && inputData.charAt(1) !== "."
                ? inputData.slice(1)
                : inputData;
            inputEl.value = inputData;

            const newCursorPos = startPos + value.length;
            inputEl.setSelectionRange(newCursorPos, newCursorPos);

            inputEl.focus();
            let operatorAtLast = ["+", "-", "*", "/"].includes(
              inputData.charAt(inputData.length - 1)
            );
            inputData = operatorAtLast ? inputData.slice(0, -1) : inputData;
            try {
              inputData = inputData === "" ? "0" : inputData;
              let result = await eval(
                ["+", "-", "*", "/", "%"].includes(
                  inputData.charAt(inputData.length - 2)
                ) && inputData.charAt(inputData.length - 1) === "."
                  ? inputData
                    .replace(inputData.charAt(inputData.length - 1), "0.")
                    .replaceAll("%", "/100")
                  : inputData.replaceAll("%", "/100")
              ).toString();
              setOutput(result);
            } catch (error) {
              if (input === "0") {
                setOutput("0");
              } else {
                setOutput("Error");
              }
            }
          }
        } else {
          inputEl.focus();
        }
      }
    }
  };

  const [percentCount, setPercentCount] = useState(0);
  useEffect(() => {
    const percentFuntion = () => {
      setPercentCount(0);
      let value = "%";
      const inputEl = inputRef.current;
      const startPos = inputEl.selectionStart;
      const endPos = inputEl.selectionEnd;
      let currentValue = inputEl.value;
      let inputData =
        currentValue.slice(0, startPos) + value + currentValue.slice(endPos);
      inputEl.value = inputData;
      if (
        inputData.charAt(0) === "%" ||
        inputData === "%" ||
        inputData === "0%"
      ) {
        return;
      } else if ((currentValue.slice(0, startPos) + value).endsWith("%%")) {
        return;
      } else {
        const newCursorPos = startPos + value.length;
        inputEl.setSelectionRange(newCursorPos, newCursorPos);

        inputEl.focus();
        try {
          setInput(inputData);

          let result = eval(inputData.replaceAll("%", "/100")).toString();
          setCalculationHistory((prevHistory) => [
            ...prevHistory,
            `${input + "%"} = ${result.includes(".")
              ? numberWithCommas(parseFloat(result || "").toFixed(4))
              : result
            }`,
          ]);
          setOutput(result);
        } catch (error) {
          setOutput("Error");
        }
      }
    };
    if (percentCount == 1) {
      percentFuntion();
    }
  }, [percentCount]);

  const handlePercentage = () => {
    if (keyPress !== "Enter") {
      setPercentCount(1);
    }
  };

  const handleNegation = async () => {
    let prevInput = input;
    let lastOperator = "";
    const operators = ["+", "-", "/", "*"];
    let hyphenCount = (prevInput.match(/(?<=.)-/g) || []).length;
    if (
      prevInput.includes("+") ||
      hyphenCount > 0 ||
      prevInput.includes("*") ||
      prevInput.includes("/")
    ) {
      for (let i = prevInput.length - 1; i >= 0; i--) {
        const char = prevInput[i];
        if (char === "+" || char === "-" || char === "*" || char === "/") {
          lastOperator = char;
          const arr = prevInput.split("");
          if (prevInput[i - 1] == "*" && char === "-") {
            arr[i] = "";
          } else if (prevInput[i - 1] == "/" && char === "-") {
            arr[i] = "";
          } else if (char === "*") {
            arr[i] = "*-";
          } else if (char === "/") {
            arr[i] = "/-";
          } else if (char === "+") {
            arr[i] = "-";
          } else if (char === "-") {
            arr[i] = "+";
          }
          prevInput = arr.join("");
          break;
        }
      }
    } else {
      if (prevInput.charAt(0) === "-") {
        prevInput = prevInput.slice(1);
      } else {
        if (prevInput === "0") {
          prevInput = prevInput;
        } else {
          prevInput = "-" + prevInput;
        }
      }
    }
    setInput(prevInput);
    if (operators.includes(prevInput.charAt(prevInput.length - 1)) === false) {
      try {
        let result = await eval(prevInput.replaceAll("%", "/100")).toString();
        setOutput(result);
      } catch (error) {
        if (input === "0") {
          setOutput("0");
        } else {
          setOutput("Error");
        }
      }
    }

    inputRef.current.focus();
    inputRef.current.setSelectionRange(
      inputRef.current.value.length,
      inputRef.current.value.length
    );
  };

  const handleClear = () => {
    if (keyPress !== "Enter") {
      setInput("0");
      setOutput("");
    }
  };

  const handleDelete = async () => {
    const inputVal = inputRef.current;
    const start = inputVal.selectionStart;
    const end = inputVal.selectionEnd;
    let inputValue = inputVal.value === "" ? "0" : inputVal.value;

    let newValue;
    if (start === end) {
      newValue = inputValue.slice(0, start - 1) + inputValue.slice(end);
      inputVal.value = newValue;
      inputVal.setSelectionRange(start - 1, start - 1);
      inputVal.focus();
    } else {
      newValue = inputValue.slice(0, start) + inputValue.slice(end);
      inputVal.value = newValue;
      inputVal.setSelectionRange(start, start);
      inputVal.focus();
    }

    if (inputValue.length !== newValue.length) {
      setInput(newValue === "" ? "0" : newValue);

      if (!["+", "-", "*", "/"].includes(newValue[newValue.length - 1])) {
        try {
          newValue = newValue === "" ? "0" : newValue;
          const result = await eval(
            newValue.replaceAll("%", "/100")
          ).toString();
          setOutput(result);
        } catch (error) {
          if (newValue === "" || newValue === "0") {
            setOutput("");
          } else {
            setOutput("Error");
          }
        }
      } else {
        try {
          newValue = newValue.slice(0, -1) === "" ? "0" : newValue.slice(0, -1);
          const result = await eval(
            newValue.replaceAll("%", "/100")
          ).toString();
          setOutput(result);
        } catch (error) {
          if (newValue === "0") {
            setOutput("");
          } else {
            setOutput("Error");
          }
        }
      }
    } else {
      const val = inputValue.slice(0, start - 2);
      setInput(val === "" ? "0" : val);

      if (!["+", "-", "*", "/"].includes(val[val.length - 1])) {
        try {
          const result = await eval(val.replaceAll("%", "/100")).toString();
          setOutput(result);
        } catch (error) {
          setOutput("Error");
        }
      } else {
        try {
          const result = await eval(
            val.slice(0, -1).replaceAll("%", "/100")
          ).toString();
          setOutput(result);
        } catch (error) {
          setOutput("Error");
        }
      }
    }
  };

  async function handlePaste(event) {
    event.preventDefault();
    let pastedValue = event.clipboardData.getData("Text").replaceAll(",", "");
    pastedValue = pastedValue.trim();
    const regex = /^[-+]?[\d.]+(?:[-+*/][\d.]+)*$/;
    if (regex.test(pastedValue)) {
      pastedValue = pastedValue;
    } else {
      if (input === "0") {
        pastedValue = "";
        setOutput("invalid input");
        return;
      } else {
        pastedValue = "";
      }
    }
    const inputEl = inputRef.current;
    const startPos = inputEl.selectionStart;
    const endPos = inputEl.selectionEnd;
    const currentValue = inputEl.value;

    let inputData = "";
    if (
      currentValue.slice(0, startPos) === "0" ||
      currentValue.slice(0, startPos) === "0"
    ) {
      inputData = pastedValue;
    } else {
      if (
        ["+", "-"].includes(
          currentValue
            .slice(0, startPos)
            .charAt(currentValue.slice(0, startPos).length - 1)
        )
      ) {
        if (["+", "-"].includes(pastedValue.charAt(0))) {
          inputData =
            currentValue.slice(0, startPos).slice(0, -1) +
            pastedValue +
            currentValue.slice(endPos);
        } else {
          inputData =
            currentValue.slice(0, startPos) +
            pastedValue +
            currentValue.slice(endPos);
        }
      } else {
        inputData =
          currentValue.slice(0, startPos) +
          pastedValue +
          currentValue.slice(endPos);
      }
    }

    inputEl.value = inputData;

    setTimeout(() => {
      const newCursorPos = startPos + pastedValue.length;
      inputEl.setSelectionRange(newCursorPos, newCursorPos);

      inputEl.focus();
    }, 10);

    if (parseFloat(inputData) !== undefined) {
      try {
        let result = await eval(inputData.replaceAll("%", "/100")).toString();
        setOutput(result);
      } catch (error) {
        if (pastedValue === "") {
          setOutput(output);
        } else {
          setOutput("Error");
        }
      }
      setInput((prevInput) => (currentValue === "0" ? pastedValue : inputData));
    } else {
      setInput("0");
      setOutput("Error");
    }
  }

  const handleDrop = async (event) => {
    event.preventDefault();
    let drag_and_drop = event.dataTransfer
      .getData("text")
      .replaceAll(",", "")
      .trim();
    const regex = /^[-+]?[\d.]+(?:[-+*/][\d.]+)*$/;
    if (regex.test(drag_and_drop)) {
      drag_and_drop = drag_and_drop;
    } else {
      if (input === "0") {
        drag_and_drop = "";
        setOutput("invalid input");
        return;
      } else {
        drag_and_drop = "";
      }
    }

    let data = "";
    if (parseFloat(drag_and_drop) !== undefined) {
      try {
        input = input === "0" ? "" : input;
        if (["+", "-", "/", "*"].includes(input.charAt(input.length - 1))) {
          if (["+", "-", "/", "*"].includes(drag_and_drop.charAt(0))) {
            data =
              input.slice(0, -1) + drag_and_drop.trim().replaceAll("%", "/100");
          } else {
            data = input + drag_and_drop.trim().replaceAll("%", "/100");
          }
        } else {
          data = input + drag_and_drop.trim().replaceAll("%", "/100");
        }

        data = data === "" ? "0" : data;
        let result = await eval(
          data.charAt(0) === "0" && data.charAt(1) !== "."
            ? data.slice(1)
            : data
        ).toString();
        setOutput(result);
      } catch (error) {
        if (drag_and_drop === "") {
          setOutput(output);
        } else {
          setOutput("Error");
        }
      }
      setInput((prevValue) => data.trim().replaceAll(",", ""));
    } else {
      setInput("0");
      setOutput("Error");
    }
  };
  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const [color, setColor] = useState(localStorage.getItem("primary_color"));
  const stylesCrossIcon = {
    color: color,
  };
  const stylesFunctionBtn = {
    backgroundColor: localStorage.getItem("primary_color")
  };
  const stylesNumbersBtn = {
    backgroundColor: localStorage.getItem("secondary_color")
  };

  const viewCalculator = () => {
    switch (calculatorFlag) {
      case true:
        return (
          <>
            <div
              // style={{
              //   minHeight:screen <= 500 ? "37vh" : "46vh",
              //   maxHeight:screen <= 500 ? "37vh" : "46vh" 
              // }}
              className="calculator "
            // style={{ transform: "translateX(-86%)" }}
            >
              <div style={{ borderBottom: "1px solid rgba(0,0,0,0.2)", padding: "7px 10px" }} >
                <Row className="p-1 align-items-center">
                  <Col xs="8" md="8" className="pl-0">
                    <div className="d-flex">
                      <svg
                        className="mx-1"
                        style={{
                          width: "22px",
                          height: "22px"
                        }}
                        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
                        <path fill={localStorage?.getItem("primary_color")} stroke={localStorage?.getItem("primary_color")} strokeWidth="1" d="M13.5 4C10.48 4 8 6.48 8 9.5v29c0 3.02 2.48 5.5 5.5 5.5h21c3.02 0 5.5-2.48 5.5-5.5v-29C40 6.48 37.52 4 34.5 4h-21zm0 3h21C35.898 7 37 8.102 37 9.5v29c0 1.398-1.102 2.5-2.5 2.5h-21a2.478 2.478 0 01-2.5-2.5v-29C11 8.102 12.102 7 13.5 7zm2.96 3.998c-1.34 0-2.46 1.122-2.46 2.463v6.078C14 20.88 15.12 22 16.46 22h15.08c1.34 0 2.46-1.12 2.462-2.459v-6.08c0-1.34-1.122-2.463-2.463-2.463H16.461zm.54 3h14.002V19H17v-5.002zM16.5 25a2.5 2.5 0 000 5 2.5 2.5 0 000-5zm7.5 0a2.5 2.5 0 000 5 2.5 2.5 0 000-5zm7.5 0a2.5 2.5 0 00-2.5 2.5v7a2.5 2.5 0 105 0v-7a2.5 2.5 0 00-2.5-2.5zm-15 7a2.5 2.5 0 000 5 2.5 2.5 0 000-5zm7.5 0a2.5 2.5 0 000 5 2.5 2.5 0 000-5z"></path>
                      </svg>
                      {/* <svg
                        className="mx-1"
                        style={{
                          width: "16px",
                          height: "20px"
                        }}
                        xmlns="http://www.w3.org/2000/svg"
                        fillRule="evenodd"
                        clipRule="evenodd"
                        imageRendering="optimizeQuality"
                        shapeRendering="geometricPrecision"
                        textRendering="geometricPrecision"
                        viewBox="0 0 1652 2271"
                      >
                        <g>
                          <g>
                            <path
                              fill={localStorage?.getItem("primary_color")} stroke={localStorage?.getItem("primary_color")} strokeWidth="3"
                              d="M1276 206H376c-70 0-128 58-128 129v322c0 70 58 128 128 128h900c70 0 128-58 128-128V335c0-71-58-129-128-129zm46 451c0 24-21 45-46 45H376c-25 0-46-21-46-45V335c0-25 21-46 46-46h900c25 0 46 21 46 46v322z"
                              className="fil0"
                            ></path>
                            <path
                              fill={localStorage?.getItem("primary_color")} stroke={localStorage?.getItem("primary_color")} strokeWidth="3"
                              d="M1404 0H248C112 0 0 112 0 248v1776c0 136 112 247 248 247h1156c136 0 248-111 248-247V248c0-136-112-248-248-248zm165 2024c0 91-74 165-165 165H248c-91 0-165-74-165-165V248c0-91 74-165 165-165h1156c91 0 165 74 165 165v1776z"
                              className="fil0"
                            ></path>
                            <path
                              fill={localStorage?.getItem("primary_color")} stroke={localStorage?.getItem("primary_color")} strokeWidth="3"
                              d="M413 1239c91 0 165-74 165-165s-74-165-165-165-165 74-165 165 74 165 165 165zm0-248c45 0 83 37 83 83 0 45-38 82-83 82s-83-37-83-82c0-46 38-83 83-83zM826 909c-91 0-165 74-165 165s74 165 165 165 165-74 165-165-74-165-165-165zm0 247c-45 0-83-37-83-82 0-46 38-83 83-83s83 37 83 83c0 45-38 82-83 82zM1239 909c-91 0-165 74-165 165s74 165 165 165 165-74 165-165-74-165-165-165zm0 247c-45 0-83-37-83-82 0-46 38-83 83-83s83 37 83 83c0 45-38 82-83 82zM413 1652c91 0 165-74 165-165s-74-165-165-165-165 74-165 165 74 165 165 165zm0-248c45 0 83 37 83 83 0 45-38 82-83 82s-83-37-83-82c0-46 38-83 83-83zM826 1322c-91 0-165 74-165 165s74 165 165 165 165-74 165-165-74-165-165-165zm0 247c-45 0-83-37-83-82 0-46 38-83 83-83s83 37 83 83c0 45-38 82-83 82zM1239 1322c-91 0-165 74-165 165s74 165 165 165 165-74 165-165-74-165-165-165zm0 247c-45 0-83-37-83-82 0-46 38-83 83-83s83 37 83 83c0 45-38 82-83 82zM1239 1735c-91 0-165 74-165 165s74 165 165 165 165-74 165-165-74-165-165-165zm0 247c-45 0-83-37-83-82 0-46 38-83 83-83s83 37 83 83c0 45-38 82-83 82zM826 1735H413c-91 0-165 74-165 165s74 165 165 165h413c91 0 165-74 165-165s-74-165-165-165zm0 247H413c-45 0-83-37-83-82 0-46 38-83 83-83h413c45 0 83 37 83 83 0 45-38 82-83 82z"
                              className="fil0"
                            ></path>
                          </g>
                        </g>
                      </svg> */}
                      {/* <svg
                        className="mr-1"
                        style={{
                          width: "16px",
                          height: "20px"
                        }}
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 48 48">
                        <path fill={localStorage?.getItem("primary_color")} stroke={localStorage?.getItem("primary_color")} strokeWidth="1" d="M13.5 4C10.48 4 8 6.48 8 9.5v29c0 3.02 2.48 5.5 5.5 5.5h21c3.02 0 5.5-2.48 5.5-5.5v-29C40 6.48 37.52 4 34.5 4h-21zm0 3h21C35.898 7 37 8.102 37 9.5v29c0 1.398-1.102 2.5-2.5 2.5h-21a2.478 2.478 0 01-2.5-2.5v-29C11 8.102 12.102 7 13.5 7zm2.96 3.998c-1.34 0-2.46 1.122-2.46 2.463v6.078C14 20.88 15.12 22 16.46 22h15.08c1.34 0 2.46-1.12 2.462-2.459v-6.08c0-1.34-1.122-2.463-2.463-2.463H16.461zm.54 3h14.002V19H17v-5.002zM16.5 25a2.5 2.5 0 000 5 2.5 2.5 0 000-5zm7.5 0a2.5 2.5 0 000 5 2.5 2.5 0 000-5zm7.5 0a2.5 2.5 0 00-2.5 2.5v7a2.5 2.5 0 105 0v-7a2.5 2.5 0 00-2.5-2.5zm-15 7a2.5 2.5 0 000 5 2.5 2.5 0 000-5zm7.5 0a2.5 2.5 0 000 5 2.5 2.5 0 000-5z"></path>
                      </svg> */}
                      <div style={{ fontWeight: "bold" }} className="output1 text-left mb-1">Instant Calculator</div>
                    </div>
                  </Col>
                  <Col xs="2" md="2"  >
                    {!history ?
                      <VscHistory
                        data-placement="top" title="History"
                        size={18}
                        style={{ marginLeft: "100%", cursor: "pointer", color: localStorage.getItem("primary_color") }}
                        onClick={() => {
                          setHistory(!history);
                        }}
                      /> :
                      <div
                        className="mb-1"
                        data-placement="top" title="Calculator"
                        style={{
                          width: "17px",
                          height: "17px"
                        }}>
                        <svg
                           style={{
                            marginLeft: "100%",
                            cursor: "pointer",
                            color: localStorage.getItem("primary_color"),
                            width: "17px",
                            height: "17px"
                          }}
                          onClick={() => {
                            setHistory(!history);
                          }}
                          xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
                          <path fill={localStorage?.getItem("primary_color")} stroke={localStorage?.getItem("primary_color")} strokeWidth="1" d="M13.5 4C10.48 4 8 6.48 8 9.5v29c0 3.02 2.48 5.5 5.5 5.5h21c3.02 0 5.5-2.48 5.5-5.5v-29C40 6.48 37.52 4 34.5 4h-21zm0 3h21C35.898 7 37 8.102 37 9.5v29c0 1.398-1.102 2.5-2.5 2.5h-21a2.478 2.478 0 01-2.5-2.5v-29C11 8.102 12.102 7 13.5 7zm2.96 3.998c-1.34 0-2.46 1.122-2.46 2.463v6.078C14 20.88 15.12 22 16.46 22h15.08c1.34 0 2.46-1.12 2.462-2.459v-6.08c0-1.34-1.122-2.463-2.463-2.463H16.461zm.54 3h14.002V19H17v-5.002zM16.5 25a2.5 2.5 0 000 5 2.5 2.5 0 000-5zm7.5 0a2.5 2.5 0 000 5 2.5 2.5 0 000-5zm7.5 0a2.5 2.5 0 00-2.5 2.5v7a2.5 2.5 0 105 0v-7a2.5 2.5 0 00-2.5-2.5zm-15 7a2.5 2.5 0 000 5 2.5 2.5 0 000-5zm7.5 0a2.5 2.5 0 000 5 2.5 2.5 0 000-5z"></path>
                        </svg>
                        {/* <svg
                          style={{
                            marginLeft: "100%",
                            cursor: "pointer",
                            color: localStorage.getItem("primary_color"),
                            width: "17px",
                            height: "17px"
                          }}
                          onClick={() => {
                            setHistory(!history);
                          }}
                          xmlns="http://www.w3.org/2000/svg"
                          fillRule="evenodd"
                          clipRule="evenodd"
                          imageRendering="optimizeQuality"
                          shapeRendering="geometricPrecision"
                          textRendering="geometricPrecision"
                          viewBox="0 0 1652 2271"
                        >
                          <g>
                            <g>
                              <path
                                fill={localStorage?.getItem("primary_color")} stroke={localStorage?.getItem("primary_color")} strokeWidth="3"
                                d="M1276 206H376c-70 0-128 58-128 129v322c0 70 58 128 128 128h900c70 0 128-58 128-128V335c0-71-58-129-128-129zm46 451c0 24-21 45-46 45H376c-25 0-46-21-46-45V335c0-25 21-46 46-46h900c25 0 46 21 46 46v322z"
                                className="fil0"
                              ></path>
                              <path
                                fill={localStorage?.getItem("primary_color")} stroke={localStorage?.getItem("primary_color")} strokeWidth="3"
                                d="M1404 0H248C112 0 0 112 0 248v1776c0 136 112 247 248 247h1156c136 0 248-111 248-247V248c0-136-112-248-248-248zm165 2024c0 91-74 165-165 165H248c-91 0-165-74-165-165V248c0-91 74-165 165-165h1156c91 0 165 74 165 165v1776z"
                                className="fil0"
                              ></path>
                              <path
                                fill={localStorage?.getItem("primary_color")} stroke={localStorage?.getItem("primary_color")} strokeWidth="3"
                                d="M413 1239c91 0 165-74 165-165s-74-165-165-165-165 74-165 165 74 165 165 165zm0-248c45 0 83 37 83 83 0 45-38 82-83 82s-83-37-83-82c0-46 38-83 83-83zM826 909c-91 0-165 74-165 165s74 165 165 165 165-74 165-165-74-165-165-165zm0 247c-45 0-83-37-83-82 0-46 38-83 83-83s83 37 83 83c0 45-38 82-83 82zM1239 909c-91 0-165 74-165 165s74 165 165 165 165-74 165-165-74-165-165-165zm0 247c-45 0-83-37-83-82 0-46 38-83 83-83s83 37 83 83c0 45-38 82-83 82zM413 1652c91 0 165-74 165-165s-74-165-165-165-165 74-165 165 74 165 165 165zm0-248c45 0 83 37 83 83 0 45-38 82-83 82s-83-37-83-82c0-46 38-83 83-83zM826 1322c-91 0-165 74-165 165s74 165 165 165 165-74 165-165-74-165-165-165zm0 247c-45 0-83-37-83-82 0-46 38-83 83-83s83 37 83 83c0 45-38 82-83 82zM1239 1322c-91 0-165 74-165 165s74 165 165 165 165-74 165-165-74-165-165-165zm0 247c-45 0-83-37-83-82 0-46 38-83 83-83s83 37 83 83c0 45-38 82-83 82zM1239 1735c-91 0-165 74-165 165s74 165 165 165 165-74 165-165-74-165-165-165zm0 247c-45 0-83-37-83-82 0-46 38-83 83-83s83 37 83 83c0 45-38 82-83 82zM826 1735H413c-91 0-165 74-165 165s74 165 165 165h413c91 0 165-74 165-165s-74-165-165-165zm0 247H413c-45 0-83-37-83-82 0-46 38-83 83-83h413c45 0 83 37 83 83 0 45-38 82-83 82z"
                                className="fil0"
                              ></path>
                            </g>
                          </g>
                        </svg> */}
                        {/* <svg
                          style={{
                            marginLeft: "100%",
                            cursor: "pointer",
                            color: localStorage.getItem("primary_color"),
                            width: "17px",
                            height: "17px"
                          }}
                          onClick={() => {
                            setHistory(!history);
                          }}
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 48 48">
                          <path fill={localStorage?.getItem("primary_color")} stroke={localStorage?.getItem("primary_color")} strokeWidth="1" d="M13.5 4C10.48 4 8 6.48 8 9.5v29c0 3.02 2.48 5.5 5.5 5.5h21c3.02 0 5.5-2.48 5.5-5.5v-29C40 6.48 37.52 4 34.5 4h-21zm0 3h21C35.898 7 37 8.102 37 9.5v29c0 1.398-1.102 2.5-2.5 2.5h-21a2.478 2.478 0 01-2.5-2.5v-29C11 8.102 12.102 7 13.5 7zm2.96 3.998c-1.34 0-2.46 1.122-2.46 2.463v6.078C14 20.88 15.12 22 16.46 22h15.08c1.34 0 2.46-1.12 2.462-2.459v-6.08c0-1.34-1.122-2.463-2.463-2.463H16.461zm.54 3h14.002V19H17v-5.002zM16.5 25a2.5 2.5 0 000 5 2.5 2.5 0 000-5zm7.5 0a2.5 2.5 0 000 5 2.5 2.5 0 000-5zm7.5 0a2.5 2.5 0 00-2.5 2.5v7a2.5 2.5 0 105 0v-7a2.5 2.5 0 00-2.5-2.5zm-15 7a2.5 2.5 0 000 5 2.5 2.5 0 000-5zm7.5 0a2.5 2.5 0 000 5 2.5 2.5 0 000-5z"></path>
                        </svg> */}
                      </div>
                      // <FaCalculator
                      //   data-placement="top" title="Calculator"
                      //   size={16}
                      //   style={{ marginLeft: "100%", cursor: "pointer", color: localStorage.getItem("primary_color") }}
                      //   onClick={() => {
                      //     setHistory(!history);
                      //   }}
                      // />

                    }

                  </Col>
                  <Col xs="2" md="2" >
                    <IoMdClose
                      data-placement="top" title="Closs"
                      className="closeIcon-Styling pr-2"
                      style={stylesCrossIcon}
                      onMouseEnter={() => setColor("black")}
                      onMouseLeave={() => setColor(localStorage.getItem("primary_color"))}
                      onClick={() => {
                        setCalculatorFlag(false);
                        setHistory(false);
                      }}
                    />

                  </Col>
                </Row>
              </div>
              {history ? (
                <>
                  <div style={{ padding: "0px 10px" }} className="mt-2 d-flex justify-content-between align-items-center">
                    <div style={{ fontSize: "16px" }} className="pl-1 output1">History</div>
                    <Row>
                      <Col className="float-right pr-3">
                        <RiDeleteBin5Line
                          data-placement="top" title="Delete All"
                          onClick={() => {
                            setCalculationHistory([]);
                          }}
                          size={18}
                          style={{ cursor: "pointer", color: localStorage.getItem("primary_color") }}
                        />

                      </Col>
                    </Row>
                  </div>
                  <div style={{ padding: "0px 10px" }} className="scrollable1-div">
                    {calculationHistory.map((calculation, index) => {
                      return (
                        <div key={index}>
                          <Container fluid>
                            <Row>
                              <Col className="output1 text-right mt-2">
                                <div
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    setHistory(!history);
                                    setInput(calculation.split("=")[0].trim());
                                    setOutput(calculation.split("=")[1].trim());
                                  }}
                                >
                                  <span style={{ fontSize: "10px" }}>
                                    {calculation.split("=")[0].trim()}
                                  </span>{" "}
                                  = {calculation.split("=")[1].trim()}
                                </div>
                              </Col>

                            </Row>
                          </Container>
                          <div className="d-flex justify-content-center">
                            <hr className="m-2 " style={{ borderTop: "1px solid rgba(0,0,0,0.2)", width: "30%" }}></hr>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </>
              ) : (
                <>
                  <div style={{ padding: "0px 10px" }} className="display">
                    <div
                      className="output1 text-right pr-3 pt-1"
                      style={{ height: "25px", fontWeight: "bold" }}
                    >
                      {output === null || output === undefined
                        ? ""
                        : output === "Error" || output === "invalid input"
                          ? output
                          : (() => {
                            const formattedNumber = parseFloat(output || "0")
                              .toFixed(4)
                              .replace(/\.?0+$/, "");
                            const numberWithCommas = formattedNumber.replace(
                              /\B(?=(\d{3})+(?!\d))/g,
                              ","
                            );
                            const parts = numberWithCommas.split(".");
                            return (
                              parts[0] +
                              (parts[1]
                                ? `.${parts[1].replace(",", "")}`
                                : "") || ""
                            );
                          })()}
                    </div>
                    <div className="form-group ml-1">
                      <input
                        type="text"
                        ref={inputRef}
                        autoFocus={true}
                        style={{ width: "237px" }}
                        onChange={handleInputChange}
                        onFocus={() => setIsInput1Focused(true)}
                        onBlur={() => setIsInput1Focused(false)}
                        className={"form-control text-right"}
                        value={input}
                        onPaste={handlePaste}
                        onDragOver={handleDragOver}
                        onDrop={handleDrop}
                      />
                    </div>
                  </div>
                  <div style={{ padding: "0px 10px" }} className="buttons">
                    <button
                      onClick={() => {
                        setKeyPress("");
                        handlePercentage();
                      }}
                      // className="btn btn-secondary"
                      className="Button-Styling"
                      style={stylesNumbersBtn}
                    >
                      %
                    </button>
                    <button
                      onClick={() => {
                        setKeyPress("");
                        setOutput("");
                        handleClear();
                        const inputVal = inputRef.current;
                        inputVal.focus();
                      }}
                      // className="btn btn-secondary"
                      className="Button-Styling"
                      style={stylesNumbersBtn}

                    >
                      C
                    </button>
                    <button
                      onClick={() => {
                        setKeyPress("");
                        handleDelete();
                        const inputVal = inputRef.current;
                        inputVal.focus();
                      }}
                      // className="btn btn-secondary"
                      className="Button-Styling"
                      style={stylesNumbersBtn}
                    >
                      ←
                    </button>
                    <button
                      onClick={() => {
                        setKeyPress("");
                        handleInput1("/");
                      }}
                      // className="btn btn-secondary"
                      className="Button-Styling"
                      style={stylesNumbersBtn}
                    >
                      ÷
                    </button>
                    {screen >= 500 && (
                      <>
                        <button
                          onClick={() => {
                            setKeyPress("");
                            handleInput1("7");
                          }}
                          // className="btn btn-light"
                          className="Button-Styling"
                          style={stylesNumbersBtn}
                        >
                          7
                        </button>
                        <button
                          onClick={() => {
                            setKeyPress("");
                            handleInput1("8");
                          }}
                          // className="btn btn-light"
                          className="Button-Styling"
                          style={stylesNumbersBtn}
                        >
                          8
                        </button>
                        <button
                          onClick={() => {
                            setKeyPress("");
                            handleInput1("9");
                          }}
                          // className="btn btn-light"
                          className="Button-Styling"
                          style={stylesNumbersBtn}
                        >
                          9
                        </button>
                      </>
                    )}
                    <button
                      onClick={() => {
                        setKeyPress("");
                        handleInput1("*");
                      }}
                      // className="btn btn-secondary"
                      className="Button-Styling"
                      style={stylesNumbersBtn}
                    >
                      ×
                    </button>
                    {screen >= 500 && (
                      <>
                        <button
                          onClick={() => {
                            setKeyPress("");
                            handleInput1("4");
                          }}
                          // className="btn btn-light"
                          className="Button-Styling"
                          style={stylesNumbersBtn}
                        >
                          4
                        </button>
                        <button
                          onClick={() => {
                            setKeyPress("");
                            handleInput1("5");
                          }}
                          // className="btn btn-light"
                          className="Button-Styling"
                          style={stylesNumbersBtn}
                        >
                          5
                        </button>
                        <button
                          onClick={() => {
                            setKeyPress("");
                            handleInput1("6");
                          }}
                          // className="btn btn-light"
                          className="Button-Styling"
                          style={stylesNumbersBtn}
                        >
                          6
                        </button>
                      </>)}
                    <button
                      onClick={() => {
                        setKeyPress("");
                        handleInput1("-");
                      }}
                      // className="btn btn-secondary"
                      className="Button-Styling"
                      style={stylesNumbersBtn}
                    >
                      -
                    </button>
                    {screen >= 500 && (
                      <>
                        <button
                          onClick={() => {
                            setKeyPress("");
                            handleInput1("1");
                          }}
                          // className="btn btn-light"
                          className="Button-Styling"
                          style={stylesNumbersBtn}
                        >
                          1
                        </button>
                        <button
                          onClick={() => {
                            setKeyPress("");
                            handleInput1("2");
                          }}
                          // className="btn btn-light"
                          className="Button-Styling"
                          style={stylesNumbersBtn}
                        >
                          2
                        </button>
                        <button
                          onClick={() => {
                            setKeyPress("");
                            handleInput1("3");
                          }}
                          // className="btn btn-light"
                          className="Button-Styling"
                          style={stylesNumbersBtn}
                        >
                          3
                        </button>
                      </>)
                    }
                    <button
                      onClick={() => {
                        setKeyPress("");
                        handleInput1("+");
                      }}
                      // className="btn btn-secondary"
                      className="Button-Styling"
                      style={stylesNumbersBtn}
                    >
                      +
                    </button>
                    <button
                      onClick={() => {
                        setKeyPress("");
                        handleNegation();
                      }}
                      // className="btn btn-secondary"
                      className="Button-Styling"
                      style={stylesNumbersBtn}
                    >
                      +/-
                    </button>
                    {screen >= 500 && (
                      <button
                        onClick={() => {
                          setKeyPress("");
                          handleInput1("0");
                        }}
                        // className="btn btn-light"
                        className="Button-Styling"
                        style={stylesNumbersBtn}
                      >
                        0
                      </button>)}
                    <button
                      onClick={() => {
                        setKeyPress("");
                        handleInput1(".");
                      }}
                      // className="btn btn-light"
                      className="Button-Styling"
                      style={stylesNumbersBtn}
                    >
                      .
                    </button>
                    <button
                      onClick={() => {
                        setKeyPress("");
                        handleEqual();
                        const inputVal = inputRef.current;
                        inputVal.focus();
                      }}
                      // className="btn btn-primary"
                      className="Button-Styling"
                      style={stylesFunctionBtn}
                    >
                      =
                    </button>
                  </div>
                </>
              )}
            </div>
          </>
        );
      default:
        return "";
    }
  };



  return (
    <div className="fixed-plugin">
      {/* <div>
        <a
          href="#pablo"
          onClick={(e) => {
            e.preventDefault();
            setHistory(false);
            // setCalculatorFlag(!calculatorFlag)
          }}
        >
          <RiCalculatorFill
            color="primary"
            size={40}
            onClick={() => setCalculatorFlag(!calculatorFlag)}
          />
        </a>
      </div> */}
      {viewCalculator()}
    </div>
  );
};

export default FixedPlugin;
