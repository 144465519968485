import React, { useState, useEffect } from 'react'
import { PDFExport } from "@progress/kendo-react-pdf";
import PageTemplate from "./page-template";
import {
    getCompanies,
} from "../../../store/services/company.service";
import styled from 'styled-components';
import { TiTick } from "react-icons/ti";
import { numberWithCommas } from 'utilities/utilityFunctions';
export const StatutoryForm1 = ({ data, formTemplate = false }) => {
    const pdfExportComponent = React.useRef(null);
    const [CompanyData, setCompanyData] = useState(null)
    const [loading, setLoading] = useState(false);
    const [fontFamilyForStaticData, setFontFamilyForStaticData] = useState('Montserrat, sans-serif')
    const [fontSizeForStaticData, setFontSizeForStaticData] = useState('14px')
    const [fontfamilyforDynimicData, setFontFamilyForDynmicData] = useState('Segoe Print')
    const [fontColor, setFontColor] = useState('')
    const baseEmail = sessionStorage.getItem("email") || "";
    const [governance, setGovernance] = useState({})
    const getAllCompanies = async () => {
        setLoading(true)
        try {
            const response = await getCompanies(baseEmail);
            if (response.status === 200) {
                response.data.data = response.data.data.filter((item) => item?.active === 'Y')
                const parents = response.data.data;
                const company = parents?.find(ite => ite?.code == data?.company_code)
                let Allperson = company.governance && company.governance != "" ? JSON.parse(company.governance) : []
                let sec = Allperson.find(x => x.role == "Company Secretary" && x?.active?.toLowerCase() != 'n')
                let ceo = Allperson.find(x => x.role == "CEO" && x?.active?.toLowerCase() != 'n')


                if (sec) {
                    setGovernance(sec)
                } else if (ceo) {
                    setGovernance(ceo)
                }
                setCompanyData(company)
                setTimeout(() => {
                    setLoading(false)
                }, 1000);
            }
        } catch (error) {
            setLoading(false)
        }
    };
    useEffect(() => {
        getAllCompanies()
    }, [])
    return (
        <div>
            <div
                style={{ fontFamily: "sans-serif " }}
            >

                {formTemplate ?
                    <PDFExport
                        paperSize="A4"
                        margin="1cm"
                        scale={0.6}
                        fileName={`Statutory Form 1`}
                        pageTemplate={PageTemplate}
                        ref={pdfExportComponent}
                    >
                        <div style={{ fontFamily: `${fontFamilyForStaticData}`, fontSize: `${fontSizeForStaticData}`, fontWeight: 500 }}>
                            <FormName>
                                Form 1
                            </FormName>
                            <div className='row'>
                                <div className='col-md-12' >
                                    <HeadingWrapper className='d-flex justify-content-center'>
                                        THE COMPANIES ACT, 2017
                                    </HeadingWrapper>

                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <HeadingWrapper className='d-flex justify-content-center'>
                                        THE COMPANIES REGULATIONS, 2024
                                    </HeadingWrapper>

                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <div style={{
                                        fontSize: '15px',
                                        // fontWeight: 'bold' 
                                    }} className='d-flex justify-content-center'>
                                        [Section 16 and Regulations 8, 9, 10 & 30]
                                    </div>

                                </div>
                            </div>

                            <div className='row mt-4'>
                                <div className='col-md-12'>
                                    <HeadingWrapper className='d-flex justify-content-center'>
                                        APPLICATION FOR COMPANY INCORPORATION
                                    </HeadingWrapper>

                                </div>
                            </div>
                            <PartsWrapper className='mt-4 mb-3'>
                                PART-I
                            </PartsWrapper>
                            <div className='row'>
                                <div className='col-md-12' style={{ padding: '25px 50px', wordWrap: 'break-word' }}>
                                    <div style={{
                                        
                                        // paddingLeft: '10px' 
                                    }}><i> (To be completed by the applicant in block letters)</i></div>


                                    {/* <div className='d-flex' >
                            <div className='align-items-center' style={{ border: '1px dashed #dddcdc', paddingLeft: '2px', width: '7%' }}>1.1</div>
                            <div className='align-items-center' style={{ border: '1px dashed #dddcdc', width: '28%', padding: ' 5px 10px' }}>Name of the Company</div>
                            <div className='align-items-center  d-flex'  >{
                                '1234 552'.split("")?.map((item, idx) => {
                                    if (item == ' ') return
                                    return <div style={{ minWidth: '30px', minHeight: '34px', marginLeft: '2px', border: '1px solid #121212', borderRadius: '3px', padding: '6px 9px' }}></div>
                                })
                            }
                            </div>
                        </div> */}


                                    <div className='d-flex mt-3'>
                                        <div className='align-items-center' style={{padding: '0px 10px 10px 10px',  }}>1.1</div>
                                        <div className='align-items-center' style={{width: '18%', padding: ' 0px 10px 10px 10px' }}>Name of the Company  </div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '79%',minHeight:'40px', padding: ' 5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                    </div>

                                    <div className='d-flex  mt-3'>
                                        <div className='align-items-center' style={{padding: '0px 10px 10px 10px',}}>1.2</div>
                                        <div className='align-items-center' style={{width: '13.2%', paddingLeft: '10px' }}>Fee Payment Details</div>
                                        <div className='align-items-center' style={{paddingLeft: '2px', width: '5%' }}>1.2.1</div>
                                        <div className='align-items-center' style={{width: '8.2%', paddingLeft: '20px' }}>Challan No</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '26%', paddingLeft: '20px', minHeight: '34px' }}></ScrollWrapper>
                                        <div className='align-items-center' style={{paddingLeft: '2px', width: '5%' }}>1.2.2</div>
                                        <div className='align-items-center' style={{ width: '13.20%', paddingLeft: '20px' }}>Amount(Rs.)</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '26%', paddingLeft: '20px', minHeight: '34px' }}></ScrollWrapper>
                                    </div>
                                    <PartsWrapper className='mt-4 mb-3'>
                                        PART-II
                                    </PartsWrapper>
                                    <div style={{ fontWeight: "bold" }}>
                                        Section &#8722; A &#8722; <span style={{ textDecoration: "underline" }}>Company information</span>
                                    </div>

                                    <div className='d-flex align-items-center mt-3'>
                                        <div style={{ padding: "5px 10px" }}>
                                            2.1
                                        </div>
                                        <div style={{ padding: "5px 10px" }}>
                                            Registered office Address, if any
                                        </div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '89%', minHeight: "45px", padding: '5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>


                                    </div>
                                    <div style={{
                                        marginLeft: "35px"
                                    }} className='d-flex align-items-center mt-3'>
                                        <div style={{ padding: "0px 10px 10px 10px", width: "12.5%" }}>
                                            City
                                        </div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '30%', minHeight: "35px", padding: ' 5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                        <div style={{ padding: "0px 10px 10px 10px", width: "12%" }}>
                                            District
                                        </div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '30%', minHeight: "35px", padding: ' 5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                        <div style={{ padding: "0px 10px 10px 10px", width: "12%" }}>
                                            Province
                                        </div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '30%', minHeight: "35px", padding: ' 5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>

                                    </div>
                                    <div style={{
                                        marginLeft: "35px"
                                    }} className='d-flex align-items-center mt-3'>
                                        <div style={{ padding: "5px 10px", width: "10%" }}>
                                            Telephone Number
                                        </div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '30%', minHeight: "35px", padding: ' 5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                        <div style={{ padding: "0px 10px 10px 10px", width: "12%" }}>
                                            Email Address
                                        </div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '49%', minHeight: "35px", padding: ' 5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>


                                    </div>
                                    <div style={{
                                        marginLeft: "35px"
                                    }} className='d-flex align-items-center mt-3'>
                                        <div style={{ padding: "5px 10px", width: "10%" }}>
                                            Mobile Number
                                        </div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '29.66%', minHeight: "35px", padding: ' 5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>

                                    </div>


                                    <div className='d-flex align-items-center mt-3'>
                                        <div style={{ padding: "5px 10px" }}>
                                            2.2*
                                        </div>
                                        <div style={{ padding: "0px 10px 10px 10px", width: '20%' }}>
                                            Correspondence Address
                                        </div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '89%', minHeight: "45px", padding: '5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>


                                    </div>
                                    <div style={{
                                        marginLeft: "35px"
                                    }} className='d-flex align-items-center mt-3'>
                                        <div style={{ padding: "0px 10px 10px 10px", width: "12.5%" }}>
                                            City
                                        </div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '30%', minHeight: "35px", padding: ' 5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                        <div style={{ padding: "0px 10px 10px 10px", width: "12%" }}>
                                            District
                                        </div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '30%', minHeight: "35px", padding: ' 5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                        <div style={{ padding: "0px 10px 10px 10px", width: "12%" }}>
                                            Province
                                        </div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '30%', minHeight: "35px", padding: ' 5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>

                                    </div>
                                    <div style={{
                                        marginLeft: "35px"
                                    }} className='d-flex align-items-center mt-3'>
                                        <div style={{ padding: "5px 10px", width: "10%" }}>
                                            Telephone Number
                                        </div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '30%', minHeight: "35px", padding: ' 5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                        <div style={{ padding: "0px 10px 10px 10px", width: "12%" }}>
                                            Email Address
                                        </div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '49%', minHeight: "35px", padding: ' 5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>


                                    </div>
                                    <div style={{
                                        marginLeft: "35px"
                                    }} className='d-flex align-items-center mt-3'>
                                        <div style={{ padding: "5px 10px", width: "10%" }}>
                                            Mobile Number
                                        </div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '29.66%', minHeight: "35px", padding: ' 5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>

                                    </div>

                                    <div>
                                        *Information regarding Correspondence address is to be provided only if the company has not given
                                        its registered office at the time of incorporation of company in 2.1 above.
                                    </div>

                                    <div className='d-flex align-items-center mt-3'>
                                        <div style={{ padding: "0px 10px 5px 10px" }}>
                                            2.3*
                                        </div>
                                        <div style={{ padding: "5px 10px", width: '19%' }}>
                                            Principal line of business (Brief objects as per clause 3 (i) of the Memorandum may be mentioned)
                                        </div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '89%', minHeight: "105px", padding: '5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>


                                    </div>

                                    <div className='mt-3' style={{ fontWeight: "bold" }}>
                                        Section — B – <span style={{ textDecoration: "underline" }}>Capital Structure</span>
                                    </div>

                                    <div className='d-flex align-items-center mt-3'>
                                        <div className='align-items-center' style={{ minHeight: "28px", padding: ' 5px 10px', width: '7%' }}></div>
                                        <div className='align-items-center' style={{ minHeight: "28px", width: '43%', padding: ' 5px 10px' }}></div>
                                        <div className='align-items-center text-center' style={{ minHeight: "28px", padding: ' 0px 10px 17px 10px', width: '20%', fontWeight: "bold" }}>Class/ Kind</div>
                                        <div className='align-items-center text-center' style={{ minHeight: "28px", width: '10%', padding: ' 5px 10px', fontWeight: "bold" }}>Face Value</div>
                                        <div className='align-items-center text-center' style={{ minHeight: "28px", width: '15%', padding: ' 5px 10px', fontWeight: "bold" }}>Number of shares</div>
                                        <div className='align-items-center text-center' style={{ minHeight: "28px", width: '25%', padding: ' 0px 10px 17px 10px', fontWeight: "bold" }}>Total Amount</div>


                                    </div>

                                    <div className='d-flex '>
                                        <div className='align-items-center' style={{ minHeight: "28px", padding: ' 0px 10px 10px 10px', width: '7%' }}>2.4</div>
                                        <div className='align-items-center' style={{ minHeight: "28px", width: '43%', padding: ' 0px 10px 10px 10px' }}>Authorized capital</div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderBottom: "none", padding: ' 5px 10px', width: '20%' }}></div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none", borderBottom: "none", width: '10%', padding: ' 5px 10px' }}></div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none", borderBottom: "none", width: '15%', padding: ' 5px 10px' }}></div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none", borderBottom: "none", width: '25%', padding: ' 5px 10px' }}></div>
                                    </div>
                                    <div className='d-flex '>
                                        <div className='align-items-center' style={{ minHeight: "28px", padding: ' 5px 10px', width: '7%' }}></div>
                                        <div className='align-items-center' style={{ minHeight: "28px", width: '43%', padding: ' 0px 10px 10px 10px' }}></div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", padding: ' 5px 10px', width: '20%' }}></div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none", width: '10%', padding: ' 5px 10px' }}></div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none", width: '15%', padding: ' 5px 10px' }}></div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none", width: '25%', padding: ' 5px 10px' }}></div>
                                    </div>


                                    <div className='d-flex mt-3'>
                                        <div className='align-items-center' style={{ minHeight: "28px", padding: ' 0px 10px 10px 10px', width: '7%' }}>2.5</div>
                                        <div className='align-items-center' style={{ minHeight: "28px", width: '43%', padding: ' 0px 10px 10px 10px' }}>Paid Up Capital</div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderBottom: "none", padding: ' 5px 10px', width: '20%' }}></div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none", borderBottom: "none", width: '10%', padding: ' 5px 10px' }}></div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none", borderBottom: "none", width: '15%', padding: ' 5px 10px' }}></div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none", borderBottom: "none", width: '25%', padding: ' 5px 10px' }}></div>
                                    </div>
                                    <div className='d-flex '>
                                        <div className='align-items-center' style={{ minHeight: "28px", padding: ' 5px 10px', width: '7%' }}></div>
                                        <div className='align-items-center' style={{ minHeight: "28px", width: '43%', padding: ' 0px 10px 10px 10px' }}></div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", padding: ' 5px 10px', width: '20%' }}></div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none", width: '10%', padding: ' 5px 10px' }}></div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none", width: '15%', padding: ' 5px 10px' }}></div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none", width: '25%', padding: ' 5px 10px' }}></div>
                                    </div>
                                    <div className='mt-3' style={{ fontWeight: "bold" }}>
                                        Section — C – <span style={{ textDecoration: "underline" }}>Special business information*</span>
                                    </div>
                                    <div>
                                        (Applicable in case of Banking Company, Non-Banking Finance Company, Asset Management company,
                                        Leasing company, Investment finance Company, Investment Advisor, REIT Management Company, Housing
                                        Finance Company, Private fund management company, Discount House, Pension Fund Manager, Microfinance
                                        or Microcredit business company, Corporate Restructuring company, Insurance Business, Modaraba
                                        Management Company, Stock Brokerage business, forex, managing agency, business of providing the services of
                                        security guards, securities broker, securities and futures adviser, securities manager, share registrar, credit rating
                                        company, balloter, underwriter, debt securities trustee or any other business restricted under any law for the time
                                        being in force or as may be notified by the Commission or any other government authority
                                    </div>
                                    <div className='d-flex align-items-center mt-3'>
                                        <div style={{ padding: "0px 10px 5px 10px" }}>
                                            2.6*
                                        </div>
                                        <div style={{ padding: "5px 10px", width: '22%' }}>
                                            Nature of business in case of specialized business requiring license / permission / approval (please specify and also attach NOC / approval of the relevant authority)
                                        </div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '70%', minHeight: "105px", padding: '5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex align-items-center mt-0'>
                                        <div style={{ padding: "0px 10px 5px 10px" }}>

                                        </div>
                                        <div style={{ padding: "5px 10px", width: '22%' }}>

                                        </div>
                                        <div style={{ width: '70%', padding: '5px 10px', }}>
                                            <i> *(Additional documents may be required by the registrar)</i>
                                        </div>
                                    </div>
                                    <div className='mt-3' style={{ fontWeight: "bold" }}>
                                        Section - D - <span style={{ textDecoration: "underline" }}> Company subscribers, directors, chief executive officer, and in case of single member company, name of nominee </span>
                                    </div>
                                    <div className='d-flex align-items-center mt-3'>
                                        <div style={{ padding: "5px 10px" }}>
                                            2.7
                                        </div>
                                        <div style={{ padding: "5px 10px",}}>
                                            State Number of directors fixed by subscribers:
                                        </div>
                                    </div>

                                    <div className='mt-3' style={{ padding: "0px 10px 5px 5px" }}>
                                        <i>[Please note that as per law a company must have minimum director as follows:]</i>
                                    </div>
                                    <div className='ml-2'>
                                    <div className='d-flex '>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderBottom: "none", padding: ' 5px 10px', width: '20%', fontWeight: "bold" }}>Kind of Company</div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none", borderBottom: "none", width: '17%', padding: ' 5px 10px', fontWeight: "bold" }}>Minimum number of directors required by law</div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none", borderBottom: "none", width: '14%', padding: ' 5px 10px', fontWeight: "bold" }}>No. of proposed directors</div>
                                    </div>
                                    <div className='d-flex '>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderBottom:"none", padding: ' 5px 10px', width: '20%' }}><i>Single Member Company</i></div>
                                        <div className='align-items-center text-center' style={{ minHeight: "28px", border: "1px solid #121212",borderBottom:"none", borderLeft: "none", width: '17%', padding: ' 5px 10px' }}>01</div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212",borderBottom:"none", borderLeft: "none", width: '14%', padding: ' 5px 10px' }}></div>
                                    </div>
                                    <div className='d-flex '>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212",borderBottom:"none", padding: ' 5px 10px', width: '20%' }}><i>Private Limited Company</i></div>
                                        <div className='align-items-center text-center' style={{ minHeight: "28px", border: "1px solid #121212",borderBottom:"none", borderLeft: "none", width: '17%', padding: ' 5px 10px' }}>02</div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212",borderBottom:"none", borderLeft: "none", width: '14%', padding: ' 5px 10px' }}></div>
                                    </div>
                                    <div className='d-flex '>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", padding: ' 5px 10px', width: '20%' }}><i>Private Limited Company</i></div>
                                        <div className='align-items-center text-center' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none", width: '17%', padding: ' 5px 10px' }}>03</div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none", width: '14%', padding: ' 5px 10px' }}></div>
                                    </div>
                                    </div>


                                    <div className='d-flex align-items-center mt-3'>
                                        <div style={{ padding: "0px 10px 5px 10px" }}>
                                            2.8
                                        </div>
                                        <div style={{ padding: "5px 10px", }}>
                                            Details of subscribers, directors and chief executive officer *
                                        </div>

                                    </div>
                                    <Wrapper className="table-responsive mt-0" >
                                        <table
                                            className="table table-bordered"
                                            style={{ fontFamily: "Montserrat rev=1 !important" }}
                                        >
                                            <tbody>
                                                <tr>
                                                    <td style={{   width: '7%',writingMode:"vertical-rl", textOrientation:"mixed",transform:"rotate(180deg)",height:"250px",textAlign:"center" }}>Name and surname (present and former) in full</td>
                                                    <td style={{   width: '7%',writingMode:"vertical-rl", textOrientation:"mixed",transform:"rotate(180deg)",height:"250px",textAlign:"center" }}>Fathers/Husbands Name in Full</td>
                                                    <td style={{   width: '7%',writingMode:"vertical-rl", textOrientation:"mixed",transform:"rotate(180deg)",height:"250px",textAlign:"center" }}>NIC/NICOP (in case of pakistani national) or Passport No (in case of foreigner</td>
                                                    <td style={{   width: '7%',writingMode:"vertical-rl", textOrientation:"mixed",transform:"rotate(180deg)",height:"250px",textAlign:"center" }}>** Incorporation/ Registration No.</td>
                                                    <td style={{   width: '7%',writingMode:"vertical-rl", textOrientation:"mixed",transform:"rotate(180deg)",height:"250px",textAlign:"center" }}>Nationality (with former nationality and nationality of the origin,if different</td>
                                                    <td style={{   width: '7%',writingMode:"vertical-rl", textOrientation:"mixed",transform:"rotate(180deg)",height:"250px",textAlign:"center" }}>***Occupation</td>
                                                    <td style={{  width: '7%',writingMode:"vertical-rl", textOrientation:"mixed",transform:"rotate(180deg)",height:"250px",textAlign:"center" }}>Resident address/ registered office address or principal office (in case of a Subscriber other than a natural person</td>
                                                    <td style={{   width: '7%',writingMode:"vertical-rl", textOrientation:"mixed",transform:"rotate(180deg)",height:"250px" ,textAlign:"center"}}>NTN (in case of director,where applicable)</td>
                                                    <td style={{  width: '7%',writingMode:"vertical-rl", textOrientation:"mixed",transform:"rotate(180deg)",height:"250px",textAlign:"center" }}>Designation (Director/Subscriber/ CEO)</td>
                                                    <td style={{  width: '7%',writingMode:"vertical-rl", textOrientation:"mixed",transform:"rotate(180deg)",height:"250px",textAlign:"center" }}>Nature of directorship (appointed,nominee/ independent/ other)</td>
                                                    <td style={{  width: '7%',writingMode:"vertical-rl", textOrientation:"mixed",transform:"rotate(180deg)",height:"250px",textAlign:"center" }}>Name of entity nominating the director</td>
                                                    <td style={{  width: '7%',writingMode:"vertical-rl", textOrientation:"mixed",transform:"rotate(180deg)",height:"250px",textAlign:"center" }}>No. of shares subscribed (for subscriber)</td>
                                                    <td style={{  width: '7%',writingMode:"vertical-rl", textOrientation:"mixed",transform:"rotate(180deg)",height:"250px",textAlign:"center" }}>**** Signaturess</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%',textAlign:"center" }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%',textAlign:"center" }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%',textAlign:"center" }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%',textAlign:"center" }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%',textAlign:"center" }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%',textAlign:"center" }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%',textAlign:"center" }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%',textAlign:"center" }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%',textAlign:"center" }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%',textAlign:"center" }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%',textAlign:"center" }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%',textAlign:"center" }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%',textAlign:"center" }}></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%',textAlign:"center" }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%',textAlign:"center" }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%',textAlign:"center" }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%',textAlign:"center" }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%',textAlign:"center" }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%',textAlign:"center" }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%',textAlign:"center" }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%',textAlign:"center" }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%',textAlign:"center" }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%',textAlign:"center" }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%',textAlign:"center" }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%',textAlign:"center" }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%',textAlign:"center" }}></td>
                                                </tr>
                                               

                                            </tbody>
                                        </table>
                                    </Wrapper>
                                    
                                    <div className='mt-3'>
                                        * Add details as applicable
                                    </div>
                                    <div >
                                        ** Applicable on subscribers other than natural persons
                                    </div>
                                    <div >
                                        ***Please also mention names of other companies where directorship is held.
                                    </div>
                                    <div >
                                        **** Signature of subscribers and consent to act as director or chief executive as the case may be. In case of online submission, the document will be signed electronically.
                                    </div>
                                    <div className='mt-3'>
                                        <span style={{ fontWeight: "bold" }}>2.9 Details of Nominee</span> (only in case of single member company - Nominee shall not be a person other than relatives of the member- namely, a spouse, father, mother, brother, sister and son or daughter)
                                    </div>

                                    <div className='d-flex mt-3'>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212",   width: '25%', padding: ' 0px 10px 10px 10px',}}>Name of Nominee</div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none", width: '75%', padding: ' 5px 10px',}}></div>
                                    </div>
                                    <div className='d-flex mt-3'>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", width: '25%', padding: '0px 10px 10px 10px',}}>NIC of Nominee</div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none", width: '75%', padding: ' 5px 10px',}}></div>
                                    </div>
                                    <div className='d-flex mt-3'>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", width: '25%', padding: '0px 10px 10px 10px',}}>Residential address of Nominee</div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none",width: '75%', padding: ' 5px 10px',}}></div>
                                    </div>
                                    <div className='d-flex mt-3'>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", width: '25%', padding: '0px 10px 10px 10px',}}>Telephone number of Nominee</div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none",width: '75%', padding: ' 5px 10px',}}></div>
                                    </div>
                                    <div className='d-flex mt-3'>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", width: '25%', padding: '0px 10px 10px 10px',}}>Email address of Nominee</div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none",width: '75%', padding: ' 5px 10px',}}></div>
                                    </div>
                                    <div className='d-flex mt-3'>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212",width: '25%', padding: '0px 10px 10px 10px',}}>Relationship of Nominee with subscriber</div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none",width: '75%', padding: ' 5px 10px',}}></div>
                                    </div>
                                    <div className='d-flex mt-3'>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212",  width: '25%', padding: '0px 10px 10px 10px',}}>Signature of Nominee</div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none", width: '75%', padding: ' 5px 10px',}}></div>
                                    </div>

                                    <div className='mt-3' style={{ fontWeight: "bold" }}>
                                        Section — E – <span style={{ textDecoration: "underline" }}> Articles of Association</span>
                                    </div>
                                    <div className='mt-3' style={{ fontWeight: "bold" }}>
                                        2.10<span style={{ textDecoration: "underline", paddingLeft: "5px" }}> If the company intends to adopt the Articles contained in First Schedule to the Act, please tick the relevant box.</span>
                                    </div>
                                    <div className='d-flex mt-3'>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212",  borderBottom: "none", width: '79%', padding: ' 5px 10px', fontWeight: "bold" }}>Status of company</div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none", borderBottom: "none", width: '12%', padding: ' 5px 10px', fontWeight: "bold" }}>Applicable relevant table of First Schedule</div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none", borderBottom: "none", width: '10%', padding: ' 5px 10px', fontWeight: "bold" }}><i>Please tick relevant box</i></div>
                                    </div>
                                    <div className='d-flex '>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212",  borderBottom: "none", width: '79%', padding: ' 5px 10px',  }}>Company limited by shares</div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none", borderBottom: "none", width: '12%', padding: ' 5px 10px',}}>Table A - Part-I</div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none", borderBottom: "none", width: '10%', padding: ' 5px 10px', }}></div>
                                    </div>
                                    <div className='d-flex '>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212",  borderBottom: "none", width: '79%', padding: ' 5px 10px', }}>Single member company limited by shares</div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none", borderBottom: "none", width: '12%', padding: ' 5px 10px', }}>Table A - Part-II</div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none", borderBottom: "none", width: '10%', padding: ' 5px 10px',}}></div>
                                    </div>
                                    <div className='d-flex '>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212",  borderBottom: "none", width: '79%', padding: ' 5px 10px',}}>Company limited by guarantee and not having a share capital </div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none", borderBottom: "none", width: '12%', padding: ' 5px 10px',}}>Table C</div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none", borderBottom: "none", width: '10%', padding: ' 5px 10px',}}></div>
                                    </div>
                                    <div className='d-flex '>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212",  borderBottom: "none", width: '79%', padding: ' 5px 10px',}}>Company limited by guarantee and having a share capital</div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none", borderBottom: "none", width: '12%', padding: ' 5px 10px',}}>Table D</div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none", borderBottom: "none", width: '10%', padding: ' 5px 10px',}}></div>
                                    </div>
                                    <div className='d-flex'>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212",   width: '79%', padding: ' 5px 10px',}}>Unlimited company having a share capital</div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none", width: '12%', padding: ' 5px 10px',}}>Table E</div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none",  width: '10%', padding: ' 5px 10px',}}></div>
                                    </div>
                                    <div className='mt-3' style={{ fontWeight: "bold" }}>
                                        2.11<span style={{ textDecoration: "underline", paddingLeft: "5px" }}> If the company has not adopted articles of association contained in First Schedule to the Act, it shall attach the articles of association.</span>
                                    </div>
                                    <PartsWrapper className='mt-4 mb-3'>
                                        PART-III
                                    </PartsWrapper>
                                    <div style={{ fontWeight: "bold",textDecoration:"underline" }} className='mt-3'>
                                        Declaration under section 16
                                    </div>
                                    <div className='d-flex mt-3'>
                                        <div style={{ padding: "0px 10px 10px 10px" }}>
                                            3.1
                                        </div>
                                        <div style={{ padding: "0px 10px 10px 10px", width: '21%' }}>
                                            Declarant Name
                                        </div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '89%', minHeight: "45px", padding: '5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-3'>
                                        <div style={{ padding: "0px 10px 10px 10px" }}>
                                            3.2
                                        </div>
                                        <div style={{ padding: "0px 10px 10px 10px", width: '21%' }}>
                                           <div> Declarant Profession / Designation
                                           </div>
                                           <div style={{width:"92%"}}>
                                            <i>(Please tick the relevant box)</i>
                                            </div>
                                        </div>
                                        <div className='d-flex flex-column'>
                                            <div className="d-flex">
                                                <input
                                                    name="Authorized_Intermediary"
                                                    type="checkbox"
                                                />
                                                <label className="mb-0 ml-2">Authorized Intermediary</label>
                                            </div>
                                            <div className="d-flex">
                                                <input
                                                    name="Authorized_Intermediary"
                                                    type="checkbox"
                                                />
                                                <label className="mb-0 ml-2">a person named in the articles as Director of the proposed company</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='d-flex mt-3'>
                                        <div style={{ padding: "0px 10px 10px 10px" }}>
                                            3.3
                                        </div>
                                        <div style={{ padding: "0px 10px 10px 10px", width: '21%' }}>
                                            Declaration
                                        </div>
                                        <div  style={{ border: '1px solid #121212', width: '89%', minHeight: "45px", padding: '5px 10px', }}>
                                            <div>
                                                I do hereby solemnly and sincerely declare that:
                                            </div>
                                            <div className='mt-3 ml-4' style={{ paddingLeft: "10px",textIndent:"-1.5rem" }}>
                                                <span className='pr-2'>a) </span> I have been authorized as declarant by the subscribers;
                                            </div>
                                            <div className='mt-1 ml-4' style={{ paddingLeft: "10px",textIndent:"-1.5rem" }}>
                                            <span className='pr-2'>b) </span> all the requirements of the Companies Act, 2017, and the regulations made there under in respect of matters precedent to the registration of the said Company and incidental thereto have been complied with.
                                            </div>
                                            <div className='mt-1 ml-4' style={{ paddingLeft: "10px",textIndent:"-1.5rem" }}>
                                            <span className='pr-2'>c) </span> necessary information about the ultimate beneficial owners of the proposed company, if any, as specified in regulation 13, has been obtained and is available on record.
                                            </div>
                                            <div className='mt-1 ml-4' style={{ paddingLeft: "10px",textIndent:"-1.5rem" }}>
                                            <span className='pr-2'>d) </span> I make this solemn declaration conscientiously believing the same to be true.
                                            </div>
                                        </div>
                                    </div>
                                    <div className='d-flex mt-3'>
                                        <div style={{ padding: "0px 10px 10px 10px"}}>
                                            3.4
                                        </div>
                                        <div style={{ padding: "0px 10px 10px 10px", width: '21%' }}>
                                            Declarant Signature
                                        </div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '89%', minHeight: "45px", padding: '5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-3'>
                                        <div style={{ padding: "0px 10px 10px 10px" }}>
                                            3.5
                                        </div>
                                        <div style={{ padding: "0px 10px 10px 10px", width: '21%' }}>
                                            Registration No of authorized intermediary, if applicable
                                        </div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '89%', minHeight: "74px", padding: '5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-0'>
                                        <div className='align-items-center' style={{  padding: "5px 10px", width: '7%' }}></div>
                                        <div className='align-items-center' style={{  width: '55%', padding: ' 5px 10px' }}> </div>
                                        <div className='align-items-center text-center' style={{  width: '8%', padding: ' 10px 10px 0px 10px' }}> Day</div>
                                        <div className='align-items-center' style={{  paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center text-center' style={{  width: '8%', padding: ' 10px 10px 0px 10px' }}> Month</div>
                                        <div className='align-items-center' style={{  paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center text-center ' style={{ width: '16%', padding: ' 10px 10px 0px 10px' }}>Year </div>
                                        <div></div>
                                    </div>
                                    <div className='d-flex '>
                                        <div className='align-items-center' style={{  padding: "0px 10px 10px 10px", }}>3.6</div>
                                        <div className='align-items-center' style={{  width: '60%', padding: ' 0px 10px 10px 10px' }}>Date</div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ border: '1px solid #121212', width: '4%', padding: ' 5px 10px' }}> </div>
                                        <div className='align-items-center' style={{ border: '1px dashed #dddcdc', paddingLeft: '8px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ border: '1px solid #121212', width: '4%', padding: ' 5px 10px', borderRight: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ borderTop:"1px solid #121212", paddingLeft: '8px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', border: '1px solid #121212', }}> </div>
                                    </div>
                                    <div style={{fontWeight:"bold"}} className='mt-3'>
                                        Enclosures:
                                    </div>
                                    <div className='mt-3 ml-3'>
                                    1. Memorandum of Association;
                                    </div>
                                    <div className='ml-3'>
                                    2. Articles of Association, (if the company has not adopted the Articles from the First 
                                        Schedule);
                                    </div>
                                    <div className='ml-3'>
                                    3. Copies of valid NIC/NICOP of the subscribers/directors/chief executive officer or copy of Passport in case of a foreigner;
                                    </div>
                                    <div className='ml-3'>
                                    4. Copy of valid NIC/NICOP of nominee only in case of single member company or copy of 
                                    Passport in case of a foreigner;
                                    </div>
                                    <div className='ml-3'>
                                    5. Copy of valid NIC of witness in case of physical filing;
                                    </div>
                                    <div className='ml-3'>
                                    6. NOC/Letter of Intent/ License (if any) of the relevant regulatory authority in case of 
                                    specialized business;
                                    </div>
                                    <div className='ml-3'>
                                    7. Authority letter for filing of documents for the proposed company as per requirement of clause (vi) of sub-regulation (2) of regulation 8;
                                    </div>
                                    <div className='ml-3'>
                                    8. Copy of valid NIC/Passport of person duly authorized by the Board of directors of a body corporate which is a subscriber along with copy of Board resolution and attendance sheet. In case of a subscriber which is a limited liability partnership, copy of valid NIC/ Passport of designated partner empowered to act as such, along with copy of instrument empowering him;
                                    </div>
                                    <div className='ml-3'>
                                    9. In case the subscriber is a foreign company or a foreign body corporate, the profile of the foreign company, detail of its directors, their nationality and country of origin, certified copy of its charter, statute or memorandum and articles, copy of the certificate of incorporation, Board resolution by the foreign company for appointment of nominee and authorization to acquire shares in the proposed company, an undertaking on stamp paper of requisite value duly signed, notarized and witnessed;
                                    </div>
                                    <div className='ml-3'>
                                    10. In case the subscriber is an individual of foreign nationality, nine sets of copies of bio data, copies of valid passport and an undertaking on stamp paper of requisite value duly signed, notarized and witnessed;
                                    </div>
                                    <div className='ml-3'>
                                    11. Original challan or other evidence of payment of fee specified in Seventh Schedule of the Act (not applicable in case of online filing).
                                    </div>
                                    <div className='mt-3'>
                                    Witness to above signatures: <i>(Only for the documents submitted in physical form)</i>
                                    </div>


                                    <div className='d-flex '>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212",  borderBottom: "none",  padding: ' 3px 10px',width:'20%'  }}>Signature</div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none", borderBottom: "none", width: '80%', padding: ' 3px 10px',  }}></div>
                                    </div>
                                    <div className='d-flex '>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212",  borderBottom: "none",  padding: ' 3px 10px',width:'20%'  }}>Full Name (in Block Letters)</div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none", borderBottom: "none", width: '80%', padding: ' 3px 10px',  }}></div>
                                    </div>
                                    <div className='d-flex '>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderBottom: "none",  padding: ' 3px 10px',width:'20%' }}>Nationality</div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none", borderBottom: "none", width: '80%', padding: ' 3px 10px',  }}></div>
                                    </div>
                                    <div className='d-flex '>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212",  borderBottom: "none",  padding: ' 3px 10px',width:'20%' }}>CNIC No.</div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none", borderBottom: "none", width: '80%', padding: ' 3px 10px', }}></div>
                                    </div>
                                    <div className='d-flex'>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212",   padding: '3px 10px',width:'20%'  }}>Usual residential address</div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none",  width: '80%', padding: ' 3px 10px', }}></div>
                                    </div>




                                </div>
                            </div>
                        </div>
                    </PDFExport>
                    : <PDFExport
                        paperSize="A4"
                        margin="1cm"
                        scale={0.6}
                        fileName={`Statutory Form 1`}
                        pageTemplate={PageTemplate}
                        ref={pdfExportComponent}
                    >
                       <div style={{ fontFamily: `${fontFamilyForStaticData}`, fontSize: `${fontSizeForStaticData}`, fontWeight: 500 }}>
                            <FormName>
                                Form 1
                            </FormName>
                            <div className='row'>
                                <div className='col-md-12' >
                                    <HeadingWrapper className='d-flex justify-content-center'>
                                        THE COMPANIES ACT, 2017
                                    </HeadingWrapper>

                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <HeadingWrapper className='d-flex justify-content-center'>
                                        THE COMPANIES REGULATIONS, 2024
                                    </HeadingWrapper>

                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <div style={{
                                        fontSize: '15px',
                                        // fontWeight: 'bold' 
                                    }} className='d-flex justify-content-center'>
                                        [Section 16 and Regulations 8, 9, 10 & 30]
                                    </div>

                                </div>
                            </div>

                            <div className='row mt-4'>
                                <div className='col-md-12'>
                                    <HeadingWrapper className='d-flex justify-content-center'>
                                        APPLICATION FOR COMPANY INCORPORATION
                                    </HeadingWrapper>

                                </div>
                            </div>
                            <PartsWrapper className='mt-4 mb-3'>
                                PART-I
                            </PartsWrapper>
                            <div className='row'>
                                <div className='col-md-12' style={{ padding: '25px 50px', wordWrap: 'break-word' }}>
                                    <div style={{
                                        
                                        // paddingLeft: '10px' 
                                    }}><i> (To be completed by the applicant in block letters)</i></div>


                                    {/* <div className='d-flex' >
                            <div className='align-items-center' style={{ border: '1px dashed #dddcdc', paddingLeft: '2px', width: '7%' }}>1.1</div>
                            <div className='align-items-center' style={{ border: '1px dashed #dddcdc', width: '28%', padding: ' 5px 10px' }}>Name of the Company</div>
                            <div className='align-items-center  d-flex'  >{
                                '1234 552'.split("")?.map((item, idx) => {
                                    if (item == ' ') return
                                    return <div style={{ minWidth: '30px', minHeight: '34px', marginLeft: '2px', border: '1px solid #121212', borderRadius: '3px', padding: '6px 9px' }}></div>
                                })
                            }
                            </div>
                        </div> */}


                                    <div className='d-flex mt-3'>
                                        <div className='align-items-center' style={{padding: '0px 10px 10px 10px',  }}>1.1</div>
                                        <div className='align-items-center' style={{width: '18%', padding: ' 0px 10px 10px 10px' }}>Name of the Company  </div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '79%',minHeight:'40px', padding: ' 5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                    </div>

                                    <div className='d-flex  mt-3'>
                                        <div className='align-items-center' style={{padding: '0px 10px 10px 10px',}}>1.2</div>
                                        <div className='align-items-center' style={{width: '13.2%', paddingLeft: '10px' }}>Fee Payment Details</div>
                                        <div className='align-items-center' style={{paddingLeft: '2px', width: '5%' }}>1.2.1</div>
                                        <div className='align-items-center' style={{width: '8.2%', paddingLeft: '20px' }}>Challan No</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '26%', paddingLeft: '20px', minHeight: '34px' }}></ScrollWrapper>
                                        <div className='align-items-center' style={{paddingLeft: '2px', width: '5%' }}>1.2.2</div>
                                        <div className='align-items-center' style={{ width: '13.20%', paddingLeft: '20px' }}>Amount(Rs.)</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '26%', paddingLeft: '20px', minHeight: '34px' }}></ScrollWrapper>
                                    </div>
                                    <PartsWrapper className='mt-4 mb-3'>
                                        PART-II
                                    </PartsWrapper>
                                    <div style={{ fontWeight: "bold" }}>
                                        Section &#8722; A &#8722; <span style={{ textDecoration: "underline" }}>Company information</span>
                                    </div>

                                    <div className='d-flex align-items-center mt-3'>
                                        <div style={{ padding: "5px 10px" }}>
                                            2.1
                                        </div>
                                        <div style={{ padding: "5px 10px" }}>
                                            Registered office Address, if any
                                        </div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '89%', minHeight: "45px", padding: '5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>


                                    </div>
                                    <div style={{
                                        marginLeft: "35px"
                                    }} className='d-flex align-items-center mt-3'>
                                        <div style={{ padding: "0px 10px 10px 10px", width: "12.5%" }}>
                                            City
                                        </div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '30%', minHeight: "35px", padding: ' 5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                        <div style={{ padding: "0px 10px 10px 10px", width: "12%" }}>
                                            District
                                        </div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '30%', minHeight: "35px", padding: ' 5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                        <div style={{ padding: "0px 10px 10px 10px", width: "12%" }}>
                                            Province
                                        </div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '30%', minHeight: "35px", padding: ' 5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>

                                    </div>
                                    <div style={{
                                        marginLeft: "35px"
                                    }} className='d-flex align-items-center mt-3'>
                                        <div style={{ padding: "5px 10px", width: "10%" }}>
                                            Telephone Number
                                        </div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '30%', minHeight: "35px", padding: ' 5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                        <div style={{ padding: "0px 10px 10px 10px", width: "12%" }}>
                                            Email Address
                                        </div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '49%', minHeight: "35px", padding: ' 5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>


                                    </div>
                                    <div style={{
                                        marginLeft: "35px"
                                    }} className='d-flex align-items-center mt-3'>
                                        <div style={{ padding: "5px 10px", width: "10%" }}>
                                            Mobile Number
                                        </div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '29.66%', minHeight: "35px", padding: ' 5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>

                                    </div>


                                    <div className='d-flex align-items-center mt-3'>
                                        <div style={{ padding: "5px 10px" }}>
                                            2.2*
                                        </div>
                                        <div style={{ padding: "0px 10px 10px 10px", width: '20%' }}>
                                            Correspondence Address
                                        </div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '89%', minHeight: "45px", padding: '5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>


                                    </div>
                                    <div style={{
                                        marginLeft: "35px"
                                    }} className='d-flex align-items-center mt-3'>
                                        <div style={{ padding: "0px 10px 10px 10px", width: "12.5%" }}>
                                            City
                                        </div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '30%', minHeight: "35px", padding: ' 5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                        <div style={{ padding: "0px 10px 10px 10px", width: "12%" }}>
                                            District
                                        </div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '30%', minHeight: "35px", padding: ' 5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                        <div style={{ padding: "0px 10px 10px 10px", width: "12%" }}>
                                            Province
                                        </div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '30%', minHeight: "35px", padding: ' 5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>

                                    </div>
                                    <div style={{
                                        marginLeft: "35px"
                                    }} className='d-flex align-items-center mt-3'>
                                        <div style={{ padding: "5px 10px", width: "10%" }}>
                                            Telephone Number
                                        </div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '30%', minHeight: "35px", padding: ' 5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                        <div style={{ padding: "0px 10px 10px 10px", width: "12%" }}>
                                            Email Address
                                        </div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '49%', minHeight: "35px", padding: ' 5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>


                                    </div>
                                    <div style={{
                                        marginLeft: "35px"
                                    }} className='d-flex align-items-center mt-3'>
                                        <div style={{ padding: "5px 10px", width: "10%" }}>
                                            Mobile Number
                                        </div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '29.66%', minHeight: "35px", padding: ' 5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>

                                    </div>

                                    <div>
                                        *Information regarding Correspondence address is to be provided only if the company has not given
                                        its registered office at the time of incorporation of company in 2.1 above.
                                    </div>

                                    <div className='d-flex align-items-center mt-3'>
                                        <div style={{ padding: "0px 10px 5px 10px" }}>
                                            2.3*
                                        </div>
                                        <div style={{ padding: "5px 10px", width: '19%' }}>
                                            Principal line of business (Brief objects as per clause 3 (i) of the Memorandum may be mentioned)
                                        </div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '89%', minHeight: "105px", padding: '5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>


                                    </div>

                                    <div className='mt-3' style={{ fontWeight: "bold" }}>
                                        Section — B – <span style={{ textDecoration: "underline" }}>Capital Structure</span>
                                    </div>

                                    <div className='d-flex align-items-center mt-3'>
                                        <div className='align-items-center' style={{ minHeight: "28px", padding: ' 5px 10px', width: '7%' }}></div>
                                        <div className='align-items-center' style={{ minHeight: "28px", width: '43%', padding: ' 5px 10px' }}></div>
                                        <div className='align-items-center text-center' style={{ minHeight: "28px", padding: ' 0px 10px 17px 10px', width: '20%', fontWeight: "bold" }}>Class/ Kind</div>
                                        <div className='align-items-center text-center' style={{ minHeight: "28px", width: '10%', padding: ' 5px 10px', fontWeight: "bold" }}>Face Value</div>
                                        <div className='align-items-center text-center' style={{ minHeight: "28px", width: '15%', padding: ' 5px 10px', fontWeight: "bold" }}>Number of shares</div>
                                        <div className='align-items-center text-center' style={{ minHeight: "28px", width: '25%', padding: ' 0px 10px 17px 10px', fontWeight: "bold" }}>Total Amount</div>


                                    </div>

                                    <div className='d-flex '>
                                        <div className='align-items-center' style={{ minHeight: "28px", padding: ' 0px 10px 10px 10px', width: '7%' }}>2.4</div>
                                        <div className='align-items-center' style={{ minHeight: "28px", width: '43%', padding: ' 0px 10px 10px 10px' }}>Authorized capital</div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderBottom: "none", padding: ' 5px 10px', width: '20%' }}></div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none", borderBottom: "none", width: '10%', padding: ' 5px 10px' }}></div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none", borderBottom: "none", width: '15%', padding: ' 5px 10px' }}></div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none", borderBottom: "none", width: '25%', padding: ' 5px 10px' }}></div>
                                    </div>
                                    <div className='d-flex '>
                                        <div className='align-items-center' style={{ minHeight: "28px", padding: ' 5px 10px', width: '7%' }}></div>
                                        <div className='align-items-center' style={{ minHeight: "28px", width: '43%', padding: ' 0px 10px 10px 10px' }}></div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", padding: ' 5px 10px', width: '20%' }}></div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none", width: '10%', padding: ' 5px 10px' }}></div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none", width: '15%', padding: ' 5px 10px' }}></div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none", width: '25%', padding: ' 5px 10px' }}></div>
                                    </div>


                                    <div className='d-flex mt-3'>
                                        <div className='align-items-center' style={{ minHeight: "28px", padding: ' 0px 10px 10px 10px', width: '7%' }}>2.5</div>
                                        <div className='align-items-center' style={{ minHeight: "28px", width: '43%', padding: ' 0px 10px 10px 10px' }}>Paid Up Capital</div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderBottom: "none", padding: ' 5px 10px', width: '20%' }}></div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none", borderBottom: "none", width: '10%', padding: ' 5px 10px' }}></div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none", borderBottom: "none", width: '15%', padding: ' 5px 10px' }}></div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none", borderBottom: "none", width: '25%', padding: ' 5px 10px' }}></div>
                                    </div>
                                    <div className='d-flex '>
                                        <div className='align-items-center' style={{ minHeight: "28px", padding: ' 5px 10px', width: '7%' }}></div>
                                        <div className='align-items-center' style={{ minHeight: "28px", width: '43%', padding: ' 0px 10px 10px 10px' }}></div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", padding: ' 5px 10px', width: '20%' }}></div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none", width: '10%', padding: ' 5px 10px' }}></div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none", width: '15%', padding: ' 5px 10px' }}></div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none", width: '25%', padding: ' 5px 10px' }}></div>
                                    </div>
                                    <div className='mt-3' style={{ fontWeight: "bold" }}>
                                        Section — C – <span style={{ textDecoration: "underline" }}>Special business information*</span>
                                    </div>
                                    <div>
                                        (Applicable in case of Banking Company, Non-Banking Finance Company, Asset Management company,
                                        Leasing company, Investment finance Company, Investment Advisor, REIT Management Company, Housing
                                        Finance Company, Private fund management company, Discount House, Pension Fund Manager, Microfinance
                                        or Microcredit business company, Corporate Restructuring company, Insurance Business, Modaraba
                                        Management Company, Stock Brokerage business, forex, managing agency, business of providing the services of
                                        security guards, securities broker, securities and futures adviser, securities manager, share registrar, credit rating
                                        company, balloter, underwriter, debt securities trustee or any other business restricted under any law for the time
                                        being in force or as may be notified by the Commission or any other government authority
                                    </div>
                                    <div className='d-flex align-items-center mt-3'>
                                        <div style={{ padding: "0px 10px 5px 10px" }}>
                                            2.6*
                                        </div>
                                        <div style={{ padding: "5px 10px", width: '22%' }}>
                                            Nature of business in case of specialized business requiring license / permission / approval (please specify and also attach NOC / approval of the relevant authority)
                                        </div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '70%', minHeight: "105px", padding: '5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex align-items-center mt-0'>
                                        <div style={{ padding: "0px 10px 5px 10px" }}>

                                        </div>
                                        <div style={{ padding: "5px 10px", width: '22%' }}>

                                        </div>
                                        <div style={{ width: '70%', padding: '5px 10px', }}>
                                            <i> *(Additional documents may be required by the registrar)</i>
                                        </div>
                                    </div>
                                    <div className='mt-3' style={{ fontWeight: "bold" }}>
                                        Section - D - <span style={{ textDecoration: "underline" }}> Company subscribers, directors, chief executive officer, and in case of single member company, name of nominee </span>
                                    </div>
                                    <div className='d-flex align-items-center mt-3'>
                                        <div style={{ padding: "5px 10px" }}>
                                            2.7
                                        </div>
                                        <div style={{ padding: "5px 10px",}}>
                                            State Number of directors fixed by subscribers:
                                        </div>
                                    </div>

                                    <div className='mt-3' style={{ padding: "0px 10px 5px 5px" }}>
                                        <i>[Please note that as per law a company must have minimum director as follows:]</i>
                                    </div>
                                    <div className='ml-2'>
                                    <div className='d-flex '>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderBottom: "none", padding: ' 5px 10px', width: '20%', fontWeight: "bold" }}>Kind of Company</div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none", borderBottom: "none", width: '17%', padding: ' 5px 10px', fontWeight: "bold" }}>Minimum number of directors required by law</div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none", borderBottom: "none", width: '14%', padding: ' 5px 10px', fontWeight: "bold" }}>No. of proposed directors</div>
                                    </div>
                                    <div className='d-flex '>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderBottom:"none", padding: ' 5px 10px', width: '20%' }}><i>Single Member Company</i></div>
                                        <div className='align-items-center text-center' style={{ minHeight: "28px", border: "1px solid #121212",borderBottom:"none", borderLeft: "none", width: '17%', padding: ' 5px 10px' }}>01</div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212",borderBottom:"none", borderLeft: "none", width: '14%', padding: ' 5px 10px' }}></div>
                                    </div>
                                    <div className='d-flex '>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212",borderBottom:"none", padding: ' 5px 10px', width: '20%' }}><i>Private Limited Company</i></div>
                                        <div className='align-items-center text-center' style={{ minHeight: "28px", border: "1px solid #121212",borderBottom:"none", borderLeft: "none", width: '17%', padding: ' 5px 10px' }}>02</div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212",borderBottom:"none", borderLeft: "none", width: '14%', padding: ' 5px 10px' }}></div>
                                    </div>
                                    <div className='d-flex '>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", padding: ' 5px 10px', width: '20%' }}><i>Private Limited Company</i></div>
                                        <div className='align-items-center text-center' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none", width: '17%', padding: ' 5px 10px' }}>03</div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none", width: '14%', padding: ' 5px 10px' }}></div>
                                    </div>
                                    </div>


                                    <div className='d-flex align-items-center mt-3'>
                                        <div style={{ padding: "0px 10px 5px 10px" }}>
                                            2.8
                                        </div>
                                        <div style={{ padding: "5px 10px", }}>
                                            Details of subscribers, directors and chief executive officer *
                                        </div>

                                    </div>
                                    <Wrapper className="table-responsive mt-0" >
                                        <table
                                            className="table table-bordered"
                                            style={{ fontFamily: "Montserrat rev=1 !important" }}
                                        >
                                            <tbody>
                                                <tr>
                                                    <td style={{   width: '7%',writingMode:"vertical-rl", textOrientation:"mixed",transform:"rotate(180deg)",height:"250px",textAlign:"center" }}>Name and surname (present and former) in full</td>
                                                    <td style={{   width: '7%',writingMode:"vertical-rl", textOrientation:"mixed",transform:"rotate(180deg)",height:"250px",textAlign:"center" }}>Fathers/Husbands Name in Full</td>
                                                    <td style={{   width: '7%',writingMode:"vertical-rl", textOrientation:"mixed",transform:"rotate(180deg)",height:"250px",textAlign:"center" }}>NIC/NICOP (in case of pakistani national) or Passport No (in case of foreigner</td>
                                                    <td style={{   width: '7%',writingMode:"vertical-rl", textOrientation:"mixed",transform:"rotate(180deg)",height:"250px",textAlign:"center" }}>** Incorporation/ Registration No.</td>
                                                    <td style={{   width: '7%',writingMode:"vertical-rl", textOrientation:"mixed",transform:"rotate(180deg)",height:"250px",textAlign:"center" }}>Nationality (with former nationality and nationality of the origin,if different</td>
                                                    <td style={{   width: '7%',writingMode:"vertical-rl", textOrientation:"mixed",transform:"rotate(180deg)",height:"250px",textAlign:"center" }}>***Occupation</td>
                                                    <td style={{  width: '7%',writingMode:"vertical-rl", textOrientation:"mixed",transform:"rotate(180deg)",height:"250px",textAlign:"center" }}>Resident address/ registered office address or principal office (in case of a Subscriber other than a natural person</td>
                                                    <td style={{   width: '7%',writingMode:"vertical-rl", textOrientation:"mixed",transform:"rotate(180deg)",height:"250px" ,textAlign:"center"}}>NTN (in case of director,where applicable)</td>
                                                    <td style={{  width: '7%',writingMode:"vertical-rl", textOrientation:"mixed",transform:"rotate(180deg)",height:"250px",textAlign:"center" }}>Designation (Director/Subscriber/ CEO)</td>
                                                    <td style={{  width: '7%',writingMode:"vertical-rl", textOrientation:"mixed",transform:"rotate(180deg)",height:"250px",textAlign:"center" }}>Nature of directorship (appointed,nominee/ independent/ other)</td>
                                                    <td style={{  width: '7%',writingMode:"vertical-rl", textOrientation:"mixed",transform:"rotate(180deg)",height:"250px",textAlign:"center" }}>Name of entity nominating the director</td>
                                                    <td style={{  width: '7%',writingMode:"vertical-rl", textOrientation:"mixed",transform:"rotate(180deg)",height:"250px",textAlign:"center" }}>No. of shares subscribed (for subscriber)</td>
                                                    <td style={{  width: '7%',writingMode:"vertical-rl", textOrientation:"mixed",transform:"rotate(180deg)",height:"250px",textAlign:"center" }}>**** Signaturess</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%',textAlign:"center" }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%',textAlign:"center" }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%',textAlign:"center" }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%',textAlign:"center" }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%',textAlign:"center" }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%',textAlign:"center" }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%',textAlign:"center" }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%',textAlign:"center" }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%',textAlign:"center" }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%',textAlign:"center" }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%',textAlign:"center" }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%',textAlign:"center" }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%',textAlign:"center" }}></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%',textAlign:"center" }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%',textAlign:"center" }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%',textAlign:"center" }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%',textAlign:"center" }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%',textAlign:"center" }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%',textAlign:"center" }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%',textAlign:"center" }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%',textAlign:"center" }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%',textAlign:"center" }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%',textAlign:"center" }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%',textAlign:"center" }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%',textAlign:"center" }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%',textAlign:"center" }}></td>
                                                </tr>
                                               

                                            </tbody>
                                        </table>
                                    </Wrapper>
                                    
                                    <div className='mt-3'>
                                        * Add details as applicable
                                    </div>
                                    <div >
                                        ** Applicable on subscribers other than natural persons
                                    </div>
                                    <div >
                                        ***Please also mention names of other companies where directorship is held.
                                    </div>
                                    <div >
                                        **** Signature of subscribers and consent to act as director or chief executive as the case may be. In case of online submission, the document will be signed electronically.
                                    </div>
                                    <div className='mt-3'>
                                        <span style={{ fontWeight: "bold" }}>2.9 Details of Nominee</span> (only in case of single member company - Nominee shall not be a person other than relatives of the member- namely, a spouse, father, mother, brother, sister and son or daughter)
                                    </div>

                                    <div className='d-flex mt-3'>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212",   width: '25%', padding: ' 0px 10px 10px 10px',}}>Name of Nominee</div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none", width: '75%', padding: ' 5px 10px',}}></div>
                                    </div>
                                    <div className='d-flex mt-3'>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", width: '25%', padding: '0px 10px 10px 10px',}}>NIC of Nominee</div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none", width: '75%', padding: ' 5px 10px',}}></div>
                                    </div>
                                    <div className='d-flex mt-3'>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", width: '25%', padding: '0px 10px 10px 10px',}}>Residential address of Nominee</div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none",width: '75%', padding: ' 5px 10px',}}></div>
                                    </div>
                                    <div className='d-flex mt-3'>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", width: '25%', padding: '0px 10px 10px 10px',}}>Telephone number of Nominee</div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none",width: '75%', padding: ' 5px 10px',}}></div>
                                    </div>
                                    <div className='d-flex mt-3'>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", width: '25%', padding: '0px 10px 10px 10px',}}>Email address of Nominee</div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none",width: '75%', padding: ' 5px 10px',}}></div>
                                    </div>
                                    <div className='d-flex mt-3'>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212",width: '25%', padding: '0px 10px 10px 10px',}}>Relationship of Nominee with subscriber</div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none",width: '75%', padding: ' 5px 10px',}}></div>
                                    </div>
                                    <div className='d-flex mt-3'>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212",  width: '25%', padding: '0px 10px 10px 10px',}}>Signature of Nominee</div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none", width: '75%', padding: ' 5px 10px',}}></div>
                                    </div>

                                    <div className='mt-3' style={{ fontWeight: "bold" }}>
                                        Section — E – <span style={{ textDecoration: "underline" }}> Articles of Association</span>
                                    </div>
                                    <div className='mt-3' style={{ fontWeight: "bold" }}>
                                        2.10<span style={{ textDecoration: "underline", paddingLeft: "5px" }}> If the company intends to adopt the Articles contained in First Schedule to the Act, please tick the relevant box.</span>
                                    </div>
                                    <div className='d-flex mt-3'>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212",  borderBottom: "none", width: '79%', padding: ' 5px 10px', fontWeight: "bold" }}>Status of company</div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none", borderBottom: "none", width: '12%', padding: ' 5px 10px', fontWeight: "bold" }}>Applicable relevant table of First Schedule</div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none", borderBottom: "none", width: '10%', padding: ' 5px 10px', fontWeight: "bold" }}><i>Please tick relevant box</i></div>
                                    </div>
                                    <div className='d-flex '>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212",  borderBottom: "none", width: '79%', padding: ' 5px 10px',  }}>Company limited by shares</div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none", borderBottom: "none", width: '12%', padding: ' 5px 10px',}}>Table A - Part-I</div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none", borderBottom: "none", width: '10%', padding: ' 5px 10px', }}></div>
                                    </div>
                                    <div className='d-flex '>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212",  borderBottom: "none", width: '79%', padding: ' 5px 10px', }}>Single member company limited by shares</div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none", borderBottom: "none", width: '12%', padding: ' 5px 10px', }}>Table A - Part-II</div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none", borderBottom: "none", width: '10%', padding: ' 5px 10px',}}></div>
                                    </div>
                                    <div className='d-flex '>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212",  borderBottom: "none", width: '79%', padding: ' 5px 10px',}}>Company limited by guarantee and not having a share capital </div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none", borderBottom: "none", width: '12%', padding: ' 5px 10px',}}>Table C</div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none", borderBottom: "none", width: '10%', padding: ' 5px 10px',}}></div>
                                    </div>
                                    <div className='d-flex '>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212",  borderBottom: "none", width: '79%', padding: ' 5px 10px',}}>Company limited by guarantee and having a share capital</div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none", borderBottom: "none", width: '12%', padding: ' 5px 10px',}}>Table D</div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none", borderBottom: "none", width: '10%', padding: ' 5px 10px',}}></div>
                                    </div>
                                    <div className='d-flex'>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212",   width: '79%', padding: ' 5px 10px',}}>Unlimited company having a share capital</div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none", width: '12%', padding: ' 5px 10px',}}>Table E</div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none",  width: '10%', padding: ' 5px 10px',}}></div>
                                    </div>
                                    <div className='mt-3' style={{ fontWeight: "bold" }}>
                                        2.11<span style={{ textDecoration: "underline", paddingLeft: "5px" }}> If the company has not adopted articles of association contained in First Schedule to the Act, it shall attach the articles of association.</span>
                                    </div>
                                    <PartsWrapper className='mt-4 mb-3'>
                                        PART-III
                                    </PartsWrapper>
                                    <div style={{ fontWeight: "bold",textDecoration:"underline" }} className='mt-3'>
                                        Declaration under section 16
                                    </div>
                                    <div className='d-flex mt-3'>
                                        <div style={{ padding: "0px 10px 10px 10px" }}>
                                            3.1
                                        </div>
                                        <div style={{ padding: "0px 10px 10px 10px", width: '21%' }}>
                                            Declarant Name
                                        </div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '89%', minHeight: "45px", padding: '5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-3'>
                                        <div style={{ padding: "0px 10px 10px 10px" }}>
                                            3.2
                                        </div>
                                        <div style={{ padding: "0px 10px 10px 10px", width: '21%' }}>
                                           <div> Declarant Profession / Designation
                                           </div>
                                           <div style={{width:"92%"}}>
                                            <i>(Please tick the relevant box)</i>
                                            </div>
                                        </div>
                                        <div className='d-flex flex-column'>
                                            <div className="d-flex">
                                                <input
                                                    name="Authorized_Intermediary"
                                                    type="checkbox"
                                                />
                                                <label className="mb-0 ml-2">Authorized Intermediary</label>
                                            </div>
                                            <div className="d-flex">
                                                <input
                                                    name="Authorized_Intermediary"
                                                    type="checkbox"
                                                />
                                                <label className="mb-0 ml-2">a person named in the articles as Director of the proposed company</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='d-flex mt-3'>
                                        <div style={{ padding: "0px 10px 10px 10px" }}>
                                            3.3
                                        </div>
                                        <div style={{ padding: "0px 10px 10px 10px", width: '21%' }}>
                                            Declaration
                                        </div>
                                        <div  style={{ border: '1px solid #121212', width: '89%', minHeight: "45px", padding: '5px 10px', }}>
                                            <div>
                                                I do hereby solemnly and sincerely declare that:
                                            </div>
                                            <div className='mt-3 ml-4' style={{ paddingLeft: "10px",textIndent:"-1.5rem" }}>
                                                <span className='pr-2'>a) </span> I have been authorized as declarant by the subscribers;
                                            </div>
                                            <div className='mt-1 ml-4' style={{ paddingLeft: "10px",textIndent:"-1.5rem" }}>
                                            <span className='pr-2'>b) </span> all the requirements of the Companies Act, 2017, and the regulations made there under in respect of matters precedent to the registration of the said Company and incidental thereto have been complied with.
                                            </div>
                                            <div className='mt-1 ml-4' style={{ paddingLeft: "10px",textIndent:"-1.5rem" }}>
                                            <span className='pr-2'>c) </span> necessary information about the ultimate beneficial owners of the proposed company, if any, as specified in regulation 13, has been obtained and is available on record.
                                            </div>
                                            <div className='mt-1 ml-4' style={{ paddingLeft: "10px",textIndent:"-1.5rem" }}>
                                            <span className='pr-2'>d) </span> I make this solemn declaration conscientiously believing the same to be true.
                                            </div>
                                        </div>
                                    </div>
                                    <div className='d-flex mt-3'>
                                        <div style={{ padding: "0px 10px 10px 10px"}}>
                                            3.4
                                        </div>
                                        <div style={{ padding: "0px 10px 10px 10px", width: '21%' }}>
                                            Declarant Signature
                                        </div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '89%', minHeight: "45px", padding: '5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-3'>
                                        <div style={{ padding: "0px 10px 10px 10px" }}>
                                            3.5
                                        </div>
                                        <div style={{ padding: "0px 10px 10px 10px", width: '21%' }}>
                                            Registration No of authorized intermediary, if applicable
                                        </div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '89%', minHeight: "74px", padding: '5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-0'>
                                        <div className='align-items-center' style={{  padding: "5px 10px", width: '7%' }}></div>
                                        <div className='align-items-center' style={{  width: '55%', padding: ' 5px 10px' }}> </div>
                                        <div className='align-items-center text-center' style={{  width: '8%', padding: ' 10px 10px 0px 10px' }}> Day</div>
                                        <div className='align-items-center' style={{  paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center text-center' style={{  width: '8%', padding: ' 10px 10px 0px 10px' }}> Month</div>
                                        <div className='align-items-center' style={{  paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center text-center ' style={{ width: '16%', padding: ' 10px 10px 0px 10px' }}>Year </div>
                                        <div></div>
                                    </div>
                                    <div className='d-flex '>
                                        <div className='align-items-center' style={{  padding: "0px 10px 10px 10px", }}>3.6</div>
                                        <div className='align-items-center' style={{  width: '60%', padding: ' 0px 10px 10px 10px' }}>Date</div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ border: '1px solid #121212', width: '4%', padding: ' 5px 10px' }}> </div>
                                        <div className='align-items-center' style={{ border: '1px dashed #dddcdc', paddingLeft: '8px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ border: '1px solid #121212', width: '4%', padding: ' 5px 10px', borderRight: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ borderTop:"1px solid #121212", paddingLeft: '8px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', border: '1px solid #121212', }}> </div>
                                    </div>
                                    <div style={{fontWeight:"bold"}} className='mt-3'>
                                        Enclosures:
                                    </div>
                                    <div className='mt-3 ml-3'>
                                    1. Memorandum of Association;
                                    </div>
                                    <div className='ml-3'>
                                    2. Articles of Association, (if the company has not adopted the Articles from the First 
                                        Schedule);
                                    </div>
                                    <div className='ml-3'>
                                    3. Copies of valid NIC/NICOP of the subscribers/directors/chief executive officer or copy of Passport in case of a foreigner;
                                    </div>
                                    <div className='ml-3'>
                                    4. Copy of valid NIC/NICOP of nominee only in case of single member company or copy of 
                                    Passport in case of a foreigner;
                                    </div>
                                    <div className='ml-3'>
                                    5. Copy of valid NIC of witness in case of physical filing;
                                    </div>
                                    <div className='ml-3'>
                                    6. NOC/Letter of Intent/ License (if any) of the relevant regulatory authority in case of 
                                    specialized business;
                                    </div>
                                    <div className='ml-3'>
                                    7. Authority letter for filing of documents for the proposed company as per requirement of clause (vi) of sub-regulation (2) of regulation 8;
                                    </div>
                                    <div className='ml-3'>
                                    8. Copy of valid NIC/Passport of person duly authorized by the Board of directors of a body corporate which is a subscriber along with copy of Board resolution and attendance sheet. In case of a subscriber which is a limited liability partnership, copy of valid NIC/ Passport of designated partner empowered to act as such, along with copy of instrument empowering him;
                                    </div>
                                    <div className='ml-3'>
                                    9. In case the subscriber is a foreign company or a foreign body corporate, the profile of the foreign company, detail of its directors, their nationality and country of origin, certified copy of its charter, statute or memorandum and articles, copy of the certificate of incorporation, Board resolution by the foreign company for appointment of nominee and authorization to acquire shares in the proposed company, an undertaking on stamp paper of requisite value duly signed, notarized and witnessed;
                                    </div>
                                    <div className='ml-3'>
                                    10. In case the subscriber is an individual of foreign nationality, nine sets of copies of bio data, copies of valid passport and an undertaking on stamp paper of requisite value duly signed, notarized and witnessed;
                                    </div>
                                    <div className='ml-3'>
                                    11. Original challan or other evidence of payment of fee specified in Seventh Schedule of the Act (not applicable in case of online filing).
                                    </div>
                                    <div className='mt-3'>
                                    Witness to above signatures: <i>(Only for the documents submitted in physical form)</i>
                                    </div>


                                    <div className='d-flex '>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212",  borderBottom: "none",  padding: ' 3px 10px',width:'20%'  }}>Signature</div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none", borderBottom: "none", width: '80%', padding: ' 3px 10px',  }}></div>
                                    </div>
                                    <div className='d-flex '>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212",  borderBottom: "none",  padding: ' 3px 10px',width:'20%'  }}>Full Name (in Block Letters)</div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none", borderBottom: "none", width: '80%', padding: ' 3px 10px',  }}></div>
                                    </div>
                                    <div className='d-flex '>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderBottom: "none",  padding: ' 3px 10px',width:'20%' }}>Nationality</div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none", borderBottom: "none", width: '80%', padding: ' 3px 10px',  }}></div>
                                    </div>
                                    <div className='d-flex '>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212",  borderBottom: "none",  padding: ' 3px 10px',width:'20%' }}>CNIC No.</div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none", borderBottom: "none", width: '80%', padding: ' 3px 10px', }}></div>
                                    </div>
                                    <div className='d-flex'>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212",   padding: '3px 10px',width:'20%'  }}>Usual residential address</div>
                                        <div className='align-items-center ' style={{ minHeight: "28px", border: "1px solid #121212", borderLeft: "none",  width: '80%', padding: ' 3px 10px', }}></div>
                                    </div>




                                </div>
                            </div>
                        </div>
                    </PDFExport>}
            </div>
            <button disabled={loading} className='btn btn-danger' onClick={(e) => {
                // if(viewPdf)
                //   SetViewPDf(true)
                if (pdfExportComponent.current) {
                    pdfExportComponent.current.save();
                }
            }}>Download PDF</button>
        </div>
    )
}
const PartsWrapper = styled.div`
color: #121212;
text-decoration: underline;
justify-content: center;
display: flex;
margin-right: 15px;
font-size: 17px;
font-weight: bold;
`;
const ScrollWrapper = styled.div`
max-height: 50px;
overflow-y: scroll;
overflow-x: hidden;
::-webkit-scrollbar{
    height: 5px;
    width: 3px;
  }
  
  ::-webkit-scrollbar-track{
    background: #F9F9FB;
  }
  ::-webkit-scrollbar-thumb{
    background: #4E515680;
    border-radius: 5px;
  
  }
`;
const HeadingWrapper = styled.div`
font-size: 17px;
font-weight: bold;
`;
const FormName = styled.div`
color: #121212;
text-decoration: underline;
justify-content: end;
display: flex;
margin-right: 15px;
font-size: 17px;
font-weight: bold;
`;
const Wrapper = styled.div`
table.table-bordered{
    border: 1px solid #121212
     border-color: #121212; 
    // border:1px solid blue;
    // margin-top:20px;
  }
// table.table-bordered > thead > tr > th{
//     // border:1px solid blue;
//     border-color: #121212; 
// }
// table.table-bordered > tbody > tr > td{
//     // border:1px solid blue;
//     borderC-color: #121212; 
// }
 tr td {
    // border:1px solid blue;.
    border-color: #121212; 
}

`;
const WrapperDashed = styled.div`

  td {
    
    border: 1px dashed #dddcdc;
}

`;