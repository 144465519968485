import React, { useState, useEffect } from 'react'
import { PDFExport } from "@progress/kendo-react-pdf";
import PageTemplate from "./page-template";
import {
    getCompanies,
} from "../../../store/services/company.service";
import styled from 'styled-components';
import { TiTick } from "react-icons/ti";
import { numberWithCommas } from 'utilities/utilityFunctions';
export const AppendixBToApp6 = ({ data, formTemplate = false }) => {
    const pdfExportComponent = React.useRef(null);
    const [CompanyData, setCompanyData] = useState(null)
    const [loading, setLoading] = useState(false);
    const [fontFamilyForStaticData, setFontFamilyForStaticData] = useState('Montserrat, sans-serif')
    const [fontSizeForStaticData, setFontSizeForStaticData] = useState('14px')
    const [fontfamilyforDynimicData, setFontFamilyForDynmicData] = useState('Segoe Print')
    const [fontColor, setFontColor] = useState('')
    const baseEmail = sessionStorage.getItem("email") || "";
    const [governance, setGovernance] = useState({})
    const getAllCompanies = async () => {
        setLoading(true)
        try {
            const response = await getCompanies(baseEmail);
            if (response.status === 200) {
                response.data.data = response.data.data.filter((item) => item?.active === 'Y')
                const parents = response.data.data;
                const company = parents?.find(ite => ite?.code == data?.company_code)
                let Allperson = company.governance && company.governance != "" ? JSON.parse(company.governance) : []
                let sec = Allperson.find(x => x.role == "Company Secretary" && x?.active?.toLowerCase() != 'n')
                let ceo = Allperson.find(x => x.role == "CEO" && x?.active?.toLowerCase() != 'n')


                if (sec) {
                    setGovernance(sec)
                } else if (ceo) {
                    setGovernance(ceo)
                }
                setCompanyData(company)
                setTimeout(() => {
                    setLoading(false)
                }, 1000);
            }
        } catch (error) {
            setLoading(false)
        }
    };
    useEffect(() => {
        getAllCompanies()
    }, [])
    return (
        <div>
            <div
                style={{ fontFamily: "sans-serif " }}
            >

                {formTemplate ?
                    <PDFExport
                        paperSize="A4"
                        margin="1cm"
                        scale={0.6}
                        fileName={`Appendix-B to App-6`}
                        pageTemplate={PageTemplate}
                        ref={pdfExportComponent}
                    >
                        <div style={{ fontFamily: `${fontFamilyForStaticData}`, fontSize: `${fontSizeForStaticData}`, fontWeight: 500 }}>
                            <FormName style={{textDecoration:"none"}}>
                                Appendix-B to App-6
                            </FormName>
                            <div className='row mt-4'>
                                <div className='col-md-12' >
                                    <HeadingWrapper className='d-flex justify-content-center'>
                                        THE COMPANIES ACT, 2017
                                    </HeadingWrapper>

                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <HeadingWrapper className='d-flex justify-content-center'>
                                        THE COMPANIES REGULATIONS, 2024
                                    </HeadingWrapper>

                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <div style={{
                                        fontSize: '15px',
                                        // fontWeight: 'bold' 
                                    }} className='d-flex justify-content-center'>
                                        [Regulation 138 & 140]
                                    </div>

                                </div>
                            </div>

                            <div className='row mt-4'>
                                <div className='col-md-12'>
                                    <HeadingWrapper className='text-center d-flex justify-content-center'>
                                        AFFIDAVIT
                                    </HeadingWrapper>

                                </div>
                            </div>

                            <div className='row'>
                                <div className='col-md-12' style={{ padding: '25px 50px', wordWrap: 'break-word' }}>
                                    <div>
                                        We, the directors of_____________________ (name of the holding company) do hereby, solemnly
                                        affirm and testify that the contents of the application and the documents submitted to the Securities
                                        and Exchange Commission of Pakistan for grant of approval to form a Group under the Companies
                                        Regulations, 2024 are true and correct to the best of our knowledge and belief and nothing has been
                                        concealed therein.

                                    </div>
                                    <div className='mt-4 mr-4 d-flex flex-column justify-content-end align-items-end text-center'>
                                        <div className='mt-2'>
                                            DEPONENTS
                                        </div>
                                        <div className='mt-2'>
                                            (Signature)
                                        </div>
                                    </div>
                                    <div className='mt-4'>
                                        The Deponents(s) is/are identified by me
                                    </div>
                                    <div style={{fontWeight:"bold"}} className='mt-5 d-flex justify-content-end'>
                                        Signature__________________________
                                    </div>
                                    <div className='mt-4 d-flex flex-column justify-content-end align-items-end text-center'>
                                        <div style={{fontWeight:"bold"}} className='mt-2'>
                                            ADVOCATE
                                        </div>
                                        <div style={{fontWeight:"bold"}} className='mt-2'>
                                            (Name and Seal)
                                        </div>
                                    </div>
                                    <div className='mt-5'>
                                    Solemnly affirmed before me on this ______ day of _____________ at ______________ by the 
                                    Deponent(s) above named who is/are identified to me by ________________, Advocate.
                                    </div>
                                    <div style={{fontWeight:"bold"}} className='mt-5 d-flex justify-content-end'>
                                        Signature________________________
                                    </div>
                                    <div className='mt-0 d-flex flex-column justify-content-end align-items-end text-right'>
                                        <div style={{fontWeight:"bold"}} className='mt-2'>
                                        OATH COMMISSIONER FOR TAKING AFFIDAVIT
                                        </div>
                                        <div style={{fontWeight:"bold"}} className='mt-2'>
                                            (Name and Seal)
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </PDFExport>
                    : <PDFExport
                        paperSize="A4"
                        margin="1cm"
                        scale={0.6}
                        fileName={`Appendix-B to App-6`}
                        pageTemplate={PageTemplate}
                        ref={pdfExportComponent}
                    >
                       <div style={{ fontFamily: `${fontFamilyForStaticData}`, fontSize: `${fontSizeForStaticData}`, fontWeight: 500 }}>
                            <FormName style={{textDecoration:"none"}}>
                                Appendix-B to App-6
                            </FormName>
                            <div className='row mt-4'>
                                <div className='col-md-12' >
                                    <HeadingWrapper className='d-flex justify-content-center'>
                                        THE COMPANIES ACT, 2017
                                    </HeadingWrapper>

                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <HeadingWrapper className='d-flex justify-content-center'>
                                        THE COMPANIES REGULATIONS, 2024
                                    </HeadingWrapper>

                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <div style={{
                                        fontSize: '15px',
                                        // fontWeight: 'bold' 
                                    }} className='d-flex justify-content-center'>
                                        [Regulation 138 & 140]
                                    </div>

                                </div>
                            </div>

                            <div className='row mt-4'>
                                <div className='col-md-12'>
                                    <HeadingWrapper className='text-center d-flex justify-content-center'>
                                        AFFIDAVIT
                                    </HeadingWrapper>

                                </div>
                            </div>

                            <div className='row'>
                                <div className='col-md-12' style={{ padding: '25px 50px', wordWrap: 'break-word' }}>
                                    <div>
                                        We, the directors of_____________________ (name of the holding company) do hereby, solemnly
                                        affirm and testify that the contents of the application and the documents submitted to the Securities
                                        and Exchange Commission of Pakistan for grant of approval to form a Group under the Companies
                                        Regulations, 2024 are true and correct to the best of our knowledge and belief and nothing has been
                                        concealed therein.

                                    </div>
                                    <div className='mt-4 mr-4 d-flex flex-column justify-content-end align-items-end text-center'>
                                        <div className='mt-2'>
                                            DEPONENTS
                                        </div>
                                        <div className='mt-2'>
                                            (Signature)
                                        </div>
                                    </div>
                                    <div className='mt-4'>
                                        The Deponents(s) is/are identified by me
                                    </div>
                                    <div style={{fontWeight:"bold"}} className='mt-5 d-flex justify-content-end'>
                                        Signature__________________________
                                    </div>
                                    <div className='mt-4 d-flex flex-column justify-content-end align-items-end text-center'>
                                        <div style={{fontWeight:"bold"}} className='mt-2'>
                                            ADVOCATE
                                        </div>
                                        <div style={{fontWeight:"bold"}} className='mt-2'>
                                            (Name and Seal)
                                        </div>
                                    </div>
                                    <div className='mt-5'>
                                    Solemnly affirmed before me on this ______ day of _____________ at ______________ by the 
                                    Deponent(s) above named who is/are identified to me by ________________, Advocate.
                                    </div>
                                    <div style={{fontWeight:"bold"}} className='mt-5 d-flex justify-content-end'>
                                        Signature________________________
                                    </div>
                                    <div className='mt-0 d-flex flex-column justify-content-end align-items-end text-right'>
                                        <div style={{fontWeight:"bold"}} className='mt-2'>
                                        OATH COMMISSIONER FOR TAKING AFFIDAVIT
                                        </div>
                                        <div style={{fontWeight:"bold"}} className='mt-2'>
                                            (Name and Seal)
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </PDFExport>}
            </div>
            <button disabled={loading} className='btn btn-danger' onClick={(e) => {
                // if(viewPdf)
                //   SetViewPDf(true)
                if (pdfExportComponent.current) {
                    pdfExportComponent.current.save();
                }
            }}>Download PDF</button>
        </div>
    )
}
const PartsWrapper = styled.div`
color: #121212;
text-decoration: underline;
justify-content: center;
display: flex;
margin-right: 15px;
font-size: 17px;
font-weight: bold;
`;
const ScrollWrapper = styled.div`
max-height: 50px;
overflow-y: scroll;
overflow-x: hidden;
::-webkit-scrollbar{
    height: 5px;
    width: 3px;
  }
  
  ::-webkit-scrollbar-track{
    background: #F9F9FB;
  }
  ::-webkit-scrollbar-thumb{
    background: #4E515680;
    border-radius: 5px;
  
  }
`;
const HeadingWrapper = styled.div`
font-size: 17px;
font-weight: bold;
`;
const FormName = styled.div`
color: #121212;
text-decoration: underline;
justify-content: end;
display: flex;
margin-right: 15px;
font-size: 17px;
font-weight: bold;
`;
const Wrapper = styled.div`
table.table-bordered{
    border: 1px solid #121212
     border-color: #121212; 
    // border:1px solid blue;
    // margin-top:20px;
  }
// table.table-bordered > thead > tr > th{
//     // border:1px solid blue;
//     border-color: #121212; 
// }
// table.table-bordered > tbody > tr > td{
//     // border:1px solid blue;
//     borderC-color: #121212; 
// }
 tr td {
    // border:1px solid blue;.
    border-color: #121212; 
}

`;
const WrapperDashed = styled.div`

  td {
    
    border: 1px dashed #dddcdc;
}

`;