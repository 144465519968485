import React, { useState, useEffect } from 'react'
import { PDFExport } from "@progress/kendo-react-pdf";
import PageTemplate from "./page-template";
import {
    getCompanies,
} from "../../../store/services/company.service";
import styled from 'styled-components';
import { TiTick } from "react-icons/ti";
import { numberWithCommas } from 'utilities/utilityFunctions';
export const StatutoryForm16 = ({ data, formTemplate = false }) => {
    const pdfExportComponent = React.useRef(null);
    const [CompanyData, setCompanyData] = useState(null)
    const [loading, setLoading] = useState(false);
    const [fontFamilyForStaticData, setFontFamilyForStaticData] = useState('Montserrat, sans-serif')
    const [fontSizeForStaticData, setFontSizeForStaticData] = useState('14px')
    const [fontfamilyforDynimicData, setFontFamilyForDynmicData] = useState('Segoe Print')
    const [fontColor, setFontColor] = useState('')
    const baseEmail = sessionStorage.getItem("email") || "";
    const [governance, setGovernance] = useState({})
    const getAllCompanies = async () => {
        setLoading(true)
        try {
            const response = await getCompanies(baseEmail);
            if (response.status === 200) {
                response.data.data = response.data.data.filter((item) => item?.active === 'Y')
                const parents = response.data.data;
                const company = parents?.find(ite => ite?.code == data?.company_code)
                let Allperson = company.governance && company.governance != "" ? JSON.parse(company.governance) : []
                let sec = Allperson.find(x => x.role == "Company Secretary" && x?.active?.toLowerCase() != 'n')
                let ceo = Allperson.find(x => x.role == "CEO" && x?.active?.toLowerCase() != 'n')


                if (sec) {
                    setGovernance(sec)
                } else if (ceo) {
                    setGovernance(ceo)
                }
                setCompanyData(company)
                setTimeout(() => {
                    setLoading(false)
                }, 1000);
            }
        } catch (error) {
            setLoading(false)
        }
    };
    useEffect(() => {
        getAllCompanies()
    }, [])
    return (
        <div>
            <div
                style={{ fontFamily: "sans-serif " }}
            >

                {formTemplate ?
                    <PDFExport
                        paperSize="A4"
                        margin="1cm"
                        scale={0.6}
                        fileName={`Form 16`}
                        pageTemplate={PageTemplate}
                        ref={pdfExportComponent}
                    >
                        <div style={{ fontFamily: `${fontFamilyForStaticData}`, fontSize: `${fontSizeForStaticData}`, fontWeight: 500 }}>
                            <FormName>
                                Form-16
                            </FormName>
                            <div className='row'>
                                <div className='col-md-12' >
                                    <HeadingWrapper className='d-flex justify-content-center'>
                                        THE COMPANIES ACT, 2017
                                    </HeadingWrapper>

                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <HeadingWrapper className='d-flex justify-content-center'>
                                        THE COMPANIES REGULATIONS, 2024
                                    </HeadingWrapper>

                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <div style={{
                                        fontSize: '15px',
                                        // fontWeight: 'bold' 
                                    }} className='d-flex justify-content-center'>
                                        [Section 123A(1) and Regulations 48(1) & 30]
                                    </div>

                                </div>
                            </div>

                            <div className='row mt-4'>
                                <div className='col-md-12'>
                                    <HeadingWrapper className='text-center d-flex justify-content-center'>
                                    NOTICE TO MEMBERS FOR PROVIDING PARTICULARS OF ULTIMATE BENEFICIAL OWNERS
                                    </HeadingWrapper>

                                </div>
                            </div>
                           

                            <div className='row'>
                                <div className='col-md-12' style={{ padding: '25px 50px', wordWrap: 'break-word' }}>
                                    <div className='mb-4 mt-1'>
                                    {`<<Insert Date>> `}
                                    </div>
                                    <div className=''>
                                        [By post/email] 
                                    </div>
                                    <div className=''>
                                        To: Name and address of member 
                                    </div>
                                    <div className=''>
                                        Date: 
                                    </div>
                                    <div className='row mb-4'>
                                <div className='col-md-12'>
                                    <HeadingWrapper className='d-flex justify-content-center'>
                                    Subject: Notice under regulation 48 of the Companies Regulations, 2024 (“the Regulations”)
                                    </HeadingWrapper>
                                </div>
                            </div>
                                    <div>
                                    1. Take Notice that sub-regulation (2) of Regulation 48 of the Regulations requires every member of 
the company who is not the ultimate beneficial owner and who directly holds at least 25% shares or 
voting rights in the company to submit a declaration to the company providing information and 
particulars of the ultimate beneficial owner(s), as defined in Regulations. 
                                    </div>
                                    <div className="mt-3">
                                    2. Sub-regulation (7) of regulation 48 defines an ultimate beneficial owner as a natural person who 
ultimately owns or controls a company, whether directly or indirectly, through at least twenty five 
percent of shares or voting rights or by exercising effective control in that company through other 
means. Exercise of control through other means may be exercised through a chain of ownership or 
through close relatives or associates having significant influence or control over the finances or 
decisions of the company.

                                    </div>
                                    <div className="mt-3">
                                    3. Sub-section (3) of section 123A of the Companies Act, 2017 (the “Act”) provides, inter-alia, that 
any contravention or default in complying with requirement of the said section shall be liable in case 
of a director or officer of the company or any other person, to a penalty which may extend to one 
million rupees.
                                    </div>
                                    <div className="mt-3">
                                    4. Accordingly, in case you do not hold beneficial interest in the shares or voting rights in the 
Company yourself, please submit the following information about the ultimate beneficial owner(s) of 
the company, as defined in the Regulations, on whose behalf you are holding such interest, within 
fourteen days of the date of this notice in accordance with regulation 48(2) of the Regulations, failing 
which the company will proceed in the matter without further notice as per the provisions of the Act 
and the Regulations*: 
                                    </div>
                                    <div className="mt-3">
                                    1. Name 
                                    </div>
                                    <div className="mt-3">
                                        2. Father’s Name/Spouse’s Name 
                                    </div>
                                    <div className="mt-3">
                                        3. CNIC/NICOP/Passport no. alongwith date of issue (attach copy) 
                                    </div>
                                    <div className="mt-3">
                                        4. Nationality 
 
                                    </div>
                                    <div className="mt-3">
                                        5. Country of origin (in case of foreign national or dual national) 

                                    </div>
                                    <div className="mt-3">
                                        6. Usual residential address 

                                    </div>
                                    <div className="mt-3">

                                        7. Email address 

                                    </div>
                                    <div className="mt-3">
                                    
                                        8. Date on which shareholding, control or interest acquired in the company 
 
                                    </div>
                                    <div className="mt-3">
                                    
                                    9. In case of indirect shareholding, control or interest being exercised through intermediary 
companies, entities or other legal persons or legal arrangements in the chain of ownership or control, 
following additional particulars to be provided: 
 
                                    </div>
                                    <Wrapper className="table-responsive mt-0" >
                                            <table
                                                className="table table-bordered"
                                                style={{ fontFamily: "Montserrat rev=1 !important" }}
                                            >
                                                <thead>
                                                    <th style={{ verticalAlign: 'sub', border: '1px solid #121212', textAlign: "center" }}>
                                                    Name
                                                    </th>
                                                    <th style={{ verticalAlign: 'sub', border: '1px solid #121212', textAlign: "center" }}>
                                                    Legal form (Company/LLP/ Partnership Firm/ Trust/Any other body corporate (to be specified))
                                                    </th>
                                                    <th style={{ verticalAlign: 'sub', border: '1px solid #121212', textAlign: "center" }}>
                                                    Date of incorporation/ registration
                                                    </th>
                                                    <th style={{ verticalAlign: 'sub', border: '1px solid #121212', textAlign: "center" }}>
                                                    Name of registration authority
                                                    </th>
                                                    <th style={{ verticalAlign: 'sub', border: '1px solid #121212', textAlign: "center" }}>
                                                    Business Address
                                                    </th>
                                                    <th style={{ verticalAlign: 'sub', border: '1px solid #121212', textAlign: "center" }}>
                                                    Country
                                                    </th>
                                                    <th style={{ verticalAlign: 'sub', border: '1px solid #121212', textAlign: "center" }}>
                                                    Email address
                                                    </th>
                                                    <th style={{ verticalAlign: 'sub', border: '1px solid #121212', textAlign: "center" }}>
                                                    Percentage of shareholding, control or interest of UBO in the legal person or legal arrangement
                                                    </th>
                                                    <th style={{ verticalAlign: 'sub', border: '1px solid #121212', textAlign: "center" }}>
                                                    Percentage of shareholding, control or interest of legal person or legal arrangement in the Company 
                                                    </th>
                                                    <th style={{ verticalAlign: 'sub', border: '1px solid #121212', textAlign: "center" }}>
                                                    Identity of Natural Person who ultimately owns or controls the legal person or arrangement
                                                    </th>

                                                </thead>

                                                <tbody>
                                                    <tr>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}>(a)</td>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}>(b)</td>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}>(c)</td>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}>(d)</td>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}>(e)</td>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}>(f)</td>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}>(g)</td>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}>(h)</td>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}>(i)</td>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}>(j)</td>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}>(k)</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}></td>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}></td>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}></td>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}></td>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}></td>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}></td>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}></td>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}></td>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}></td>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}></td>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}></td>
                                                    </tr>


                                                </tbody>
                                            </table>
                                        </Wrapper>
                                        <div className='mt-2'>
                                        10. Any other information incidental to or relevant to enable the company to evaluate this matter.
 
                                        </div>
                                        <div className='mt-4'>
                                        Name & signature 
 
                                        </div>
                                        <div>
                                            (Person authorized to issue notice on behalf of the company) 
 
                                        </div>
                                        <div>
                                        *&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;In case the government or any company or body corporate owned or controlled by it is a member of a 
company, the particulars required vide this para shall be provided in respect of the senior management official(s) 
nominated by the said government on the board of directors or governing body of the said company or body 
corporate, to the extent of investment of the government in the company.
 
                                        </div>
                                    
                                </div>
                            </div>
                        </div>
                    </PDFExport>
                    : <PDFExport
                        paperSize="A4"
                        margin="1cm"
                        scale={0.6}
                        fileName={`Form 16`}
                        pageTemplate={PageTemplate}
                        ref={pdfExportComponent}
                    >
                        <div style={{ fontFamily: `${fontFamilyForStaticData}`, fontSize: `${fontSizeForStaticData}`, fontWeight: 500 }}>
                            <FormName>
                                Form-16
                            </FormName>
                            <div className='row'>
                                <div className='col-md-12' >
                                    <HeadingWrapper className='d-flex justify-content-center'>
                                        THE COMPANIES ACT, 2017
                                    </HeadingWrapper>

                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <HeadingWrapper className='d-flex justify-content-center'>
                                        THE COMPANIES REGULATIONS, 2024
                                    </HeadingWrapper>

                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <div style={{
                                        fontSize: '15px',
                                        // fontWeight: 'bold' 
                                    }} className='d-flex justify-content-center'>
                                        [Section 123A(1) and Regulations 48(1) & 30]
                                    </div>

                                </div>
                            </div>

                            <div className='row mt-4'>
                                <div className='col-md-12'>
                                    <HeadingWrapper className='text-center d-flex justify-content-center'>
                                    NOTICE TO MEMBERS FOR PROVIDING PARTICULARS OF ULTIMATE BENEFICIAL OWNERS
                                    </HeadingWrapper>

                                </div>
                            </div>
                           

                            <div className='row'>
                                <div className='col-md-12' style={{ padding: '25px 50px', wordWrap: 'break-word' }}>
                                    <div className='mb-4 mt-1'>
                                    {`<<Insert Date>> `}
                                    </div>
                                    <div className=''>
                                        [By post/email] 
                                    </div>
                                    <div className=''>
                                        To: Name and address of member 
                                    </div>
                                    <div className=''>
                                        Date: 
                                    </div>
                                    <div className='row mb-4'>
                                <div className='col-md-12'>
                                    <HeadingWrapper className='d-flex justify-content-center'>
                                    Subject: Notice under regulation 48 of the Companies Regulations, 2024 (“the Regulations”)
                                    </HeadingWrapper>
                                </div>
                            </div>
                                    <div>
                                    1. Take Notice that sub-regulation (2) of Regulation 48 of the Regulations requires every member of 
the company who is not the ultimate beneficial owner and who directly holds at least 25% shares or 
voting rights in the company to submit a declaration to the company providing information and 
particulars of the ultimate beneficial owner(s), as defined in Regulations. 
                                    </div>
                                    <div className="mt-3">
                                    2. Sub-regulation (7) of regulation 48 defines an ultimate beneficial owner as a natural person who 
ultimately owns or controls a company, whether directly or indirectly, through at least twenty five 
percent of shares or voting rights or by exercising effective control in that company through other 
means. Exercise of control through other means may be exercised through a chain of ownership or 
through close relatives or associates having significant influence or control over the finances or 
decisions of the company.

                                    </div>
                                    <div className="mt-3">
                                    3. Sub-section (3) of section 123A of the Companies Act, 2017 (the “Act”) provides, inter-alia, that 
any contravention or default in complying with requirement of the said section shall be liable in case 
of a director or officer of the company or any other person, to a penalty which may extend to one 
million rupees.
                                    </div>
                                    <div className="mt-3">
                                    4. Accordingly, in case you do not hold beneficial interest in the shares or voting rights in the 
Company yourself, please submit the following information about the ultimate beneficial owner(s) of 
the company, as defined in the Regulations, on whose behalf you are holding such interest, within 
fourteen days of the date of this notice in accordance with regulation 48(2) of the Regulations, failing 
which the company will proceed in the matter without further notice as per the provisions of the Act 
and the Regulations*: 
                                    </div>
                                    <div className="mt-3">
                                    1. Name 
                                    </div>
                                    <div className="mt-3">
                                        2. Father’s Name/Spouse’s Name 
                                    </div>
                                    <div className="mt-3">
                                        3. CNIC/NICOP/Passport no. alongwith date of issue (attach copy) 
                                    </div>
                                    <div className="mt-3">
                                        4. Nationality 
 
                                    </div>
                                    <div className="mt-3">
                                        5. Country of origin (in case of foreign national or dual national) 

                                    </div>
                                    <div className="mt-3">
                                        6. Usual residential address 

                                    </div>
                                    <div className="mt-3">

                                        7. Email address 

                                    </div>
                                    <div className="mt-3">
                                    
                                        8. Date on which shareholding, control or interest acquired in the company 
 
                                    </div>
                                    <div className="mt-3">
                                    
                                    9. In case of indirect shareholding, control or interest being exercised through intermediary 
companies, entities or other legal persons or legal arrangements in the chain of ownership or control, 
following additional particulars to be provided: 
 
                                    </div>
                                    <Wrapper className="table-responsive mt-0" >
                                            <table
                                                className="table table-bordered"
                                                style={{ fontFamily: "Montserrat rev=1 !important" }}
                                            >
                                                <thead>
                                                    <th style={{ verticalAlign: 'sub', border: '1px solid #121212', textAlign: "center" }}>
                                                    Name
                                                    </th>
                                                    <th style={{ verticalAlign: 'sub', border: '1px solid #121212', textAlign: "center" }}>
                                                    Legal form (Company/LLP/ Partnership Firm/ Trust/Any other body corporate (to be specified))
                                                    </th>
                                                    <th style={{ verticalAlign: 'sub', border: '1px solid #121212', textAlign: "center" }}>
                                                    Date of incorporation/ registration
                                                    </th>
                                                    <th style={{ verticalAlign: 'sub', border: '1px solid #121212', textAlign: "center" }}>
                                                    Name of registration authority
                                                    </th>
                                                    <th style={{ verticalAlign: 'sub', border: '1px solid #121212', textAlign: "center" }}>
                                                    Business Address
                                                    </th>
                                                    <th style={{ verticalAlign: 'sub', border: '1px solid #121212', textAlign: "center" }}>
                                                    Country
                                                    </th>
                                                    <th style={{ verticalAlign: 'sub', border: '1px solid #121212', textAlign: "center" }}>
                                                    Email address
                                                    </th>
                                                    <th style={{ verticalAlign: 'sub', border: '1px solid #121212', textAlign: "center" }}>
                                                    Percentage of shareholding, control or interest of UBO in the legal person or legal arrangement
                                                    </th>
                                                    <th style={{ verticalAlign: 'sub', border: '1px solid #121212', textAlign: "center" }}>
                                                    Percentage of shareholding, control or interest of legal person or legal arrangement in the Company 
                                                    </th>
                                                    <th style={{ verticalAlign: 'sub', border: '1px solid #121212', textAlign: "center" }}>
                                                    Identity of Natural Person who ultimately owns or controls the legal person or arrangement
                                                    </th>

                                                </thead>

                                                <tbody>
                                                    <tr>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}>(a)</td>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}>(b)</td>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}>(c)</td>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}>(d)</td>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}>(e)</td>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}>(f)</td>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}>(g)</td>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}>(h)</td>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}>(i)</td>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}>(j)</td>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}>(k)</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}></td>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}></td>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}></td>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}></td>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}></td>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}></td>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}></td>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}></td>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}></td>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}></td>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}></td>
                                                    </tr>


                                                </tbody>
                                            </table>
                                        </Wrapper>
                                        <div className='mt-2'>
                                        10. Any other information incidental to or relevant to enable the company to evaluate this matter.
 
                                        </div>
                                        <div className='mt-4'>
                                        Name & signature 
 
                                        </div>
                                        <div>
                                            (Person authorized to issue notice on behalf of the company) 
 
                                        </div>
                                        <div>
                                        *&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;In case the government or any company or body corporate owned or controlled by it is a member of a 
company, the particulars required vide this para shall be provided in respect of the senior management official(s) 
nominated by the said government on the board of directors or governing body of the said company or body 
corporate, to the extent of investment of the government in the company.
 
                                        </div>
                                    
                                </div>
                            </div>
                        </div>
                    </PDFExport>}
            </div>
            <button disabled={loading} className='btn btn-danger' onClick={(e) => {
                // if(viewPdf)
                //   SetViewPDf(true)
                if (pdfExportComponent.current) {
                    pdfExportComponent.current.save();
                }
            }}>Download PDF</button>
        </div>
    )
}
const PartsWrapper = styled.div`
color: #121212;
text-decoration: underline;
justify-content: center;
display: flex;
margin-right: 15px;
font-size: 17px;
font-weight: bold;
`;
const ScrollWrapper = styled.div`
max-height: 50px;
overflow-y: scroll;
overflow-x: hidden;
::-webkit-scrollbar{
    height: 5px;
    width: 3px;
  }
  
  ::-webkit-scrollbar-track{
    background: #F9F9FB;
  }
  ::-webkit-scrollbar-thumb{
    background: #4E515680;
    border-radius: 5px;
  
  }
`;
const HeadingWrapper = styled.div`
font-size: 17px;
font-weight: bold;
`;
const FormName = styled.div`
color: #121212;
text-decoration: underline;
justify-content: end;
display: flex;
margin-right: 15px;
font-size: 17px;
font-weight: bold;
`;
const Wrapper = styled.div`
table.table-bordered{
    border: 1px solid #121212
     border-color: #121212; 
    // border:1px solid blue;
    // margin-top:20px;
  }
// table.table-bordered > thead > tr > th{
//     // border:1px solid blue;
//     border-color: #121212; 
// }
// table.table-bordered > tbody > tr > td{
//     // border:1px solid blue;
//     borderC-color: #121212; 
// }
 tr td {
    // border:1px solid blue;.
    border-color: #121212; 
}

`;
const WrapperDashed = styled.div`

  td {
    
    border: 1px dashed #dddcdc;
}

`;