import React, { useState, useEffect } from 'react'
import { PDFExport } from "@progress/kendo-react-pdf";
import PageTemplate from "./page-template";
import {
    getCompanies,
} from "../../../store/services/company.service";
import styled from 'styled-components';
import { TiTick } from "react-icons/ti";
import { numberWithCommas } from 'utilities/utilityFunctions';
import "./CommonForm.css"
export const StatutoryForm12 = ({ data, formTemplate = false }) => {
    const pdfExportComponent = React.useRef(null);
    const [CompanyData, setCompanyData] = useState(null)
    const [loading, setLoading] = useState(false);
    const [fontFamilyForStaticData, setFontFamilyForStaticData] = useState('Montserrat, sans-serif')
    const [fontSizeForStaticData, setFontSizeForStaticData] = useState('14px')
    const [fontfamilyforDynimicData, setFontFamilyForDynmicData] = useState('Segoe Print')
    const [fontColor, setFontColor] = useState('')
    const baseEmail = sessionStorage.getItem("email") || "";
    const [governance, setGovernance] = useState({})
    const getAllCompanies = async () => {
        setLoading(true)
        try {
            const response = await getCompanies(baseEmail);
            if (response.status === 200) {
                response.data.data = response.data.data.filter((item) => item?.active === 'Y')
                const parents = response.data.data;
                const company = parents?.find(ite => ite?.code == data?.company_code)
                let Allperson = company.governance && company.governance != "" ? JSON.parse(company.governance) : []
                let sec = Allperson.find(x => x.role == "Company Secretary" && x?.active?.toLowerCase() != 'n')
                let ceo = Allperson.find(x => x.role == "CEO" && x?.active?.toLowerCase() != 'n')


                if (sec) {
                    setGovernance(sec)
                } else if (ceo) {
                    setGovernance(ceo)
                }
                setCompanyData(company)
                setTimeout(() => {
                    setLoading(false)
                }, 1000);
            }
        } catch (error) {
            setLoading(false)
        }
    };
    useEffect(() => {
        getAllCompanies()
    }, [])
    return (
        <div>
            <div
                style={{ fontFamily: "sans-serif " }}
            >

                {formTemplate ?
                    <PDFExport
                        paperSize="A4"
                        margin="1cm"
                        scale={0.6}
                        fileName={`Form 12`}
                        pageTemplate={PageTemplate}
                        ref={pdfExportComponent}
                    >
                        <div style={{ fontFamily: `${fontFamilyForStaticData}`, fontSize: `${fontSizeForStaticData}`, fontWeight: 500 }}>
                            <FormName>
                                Form-12
                            </FormName>
                            <div className='row'>
                                <div className='col-md-12' >
                                    <HeadingWrapper className='d-flex justify-content-center'>
                                        THE COMPANIES REGULATIONS, 2024
                                    </HeadingWrapper>

                                </div>
                            </div>

                            <div className='row'>
                                <div className='col-md-12'>
                                    <div style={{
                                        fontSize: '15px',
                                        // fontWeight: 'bold' 
                                    }} className='d-flex justify-content-center'>
                                        [Section 83(3) and Regulations 39 & 108 & 30]
                                    </div>

                                </div>
                            </div>

                            <div className='row mt-4'>
                                <div className='col-md-12'>
                                    <HeadingWrapper className='text-center d-flex justify-content-center'>
                                        Circular to be sent to the Members along-with the Offer letter
                                    </HeadingWrapper>

                                </div>
                            </div>
                            <PartsWrapper className='mt-4 mb-3'>
                                PART-I
                            </PartsWrapper>


                            <div className='row'>
                                <div className='col-md-12' style={{ padding: '25px 50px', wordWrap: 'break-word' }}>

                                    {/* section 1.1 */}
                                    <div className='col-12 mb-3'>
                                        <div className='d-flex  mb-3'>
                                            <h6 className='form-sr-no'>1.1 </h6>
                                            <h6 className='border-bottom-3'>Information pertaining to company offering right issue:</h6>
                                        </div>
                                        <div>
                                            <div style={{ marginLeft: "2rem" }} className='d-flex mb-3'>
                                                <div>(a)</div><div style={{ marginLeft: "2rem",fontWeight:"bold",fontSize:"16px" }}>Company profile and history:</div>
                                            </div>
                                            <div style={{ marginLeft: "5rem" }} className='d-flex mb-3'>
                                                <div>(i)</div><div style={{ marginLeft: "2rem" }}>Name of Company</div>
                                            </div>
                                            <div style={{ marginLeft: "5rem" }} className='d-flex mb-3'>
                                                <div>(ii)</div><div style={{ marginLeft: "2rem" }}>Corporate Unique Identification Number(CUIN)</div>
                                            </div>
                                            <div style={{ marginLeft: "5rem" }} className='d-flex mb-3'>
                                                <div>(iii)</div><div style={{ marginLeft: "2rem" }}>Date of Incorpration</div>
                                            </div>
                                            <div style={{ marginLeft: "5rem" }} className='d-flex mb-3'>
                                                <div>(iv)</div><div style={{ marginLeft: "2rem" }}>Registered office address</div>
                                            </div>
                                            <div style={{ marginLeft: "5rem" }} className='d-flex mb-3'>
                                                <div>(v)</div><div style={{ marginLeft: "2rem" }}>Date of commencement of business, if any<span className=' text-primary ml-2'>[Not applicable for private companies]</span></div>
                                            </div>
                                            <div style={{ marginLeft: "5rem" }} className='d-flex mb-3'>
                                                <div>(vi)</div><div style={{ marginLeft: "2rem" }}>Kind of the company (private/public etc.)</div>
                                            </div>
                                            <div style={{ marginLeft: "5rem" }} className='d-flex mb-3'>
                                                <div>(vii)</div><div style={{ marginLeft: "2rem" }}>Website, if any.</div>
                                            </div>
                                        </div>
                                        <div>
                                            <div style={{ marginLeft: "2rem" }} className='d-flex mb-3'>
                                                <div>(b)</div><div style={{ marginLeft: "2rem",fontWeight:"bold",fontSize:"16px" }}>Profile of management and sponsors:<span style={{color:"#007bff"}} className='ml-2'>[Not applicable to private companies]</span></div>
                                            </div>
                                            <div style={{ marginLeft: "5rem" }} className='d-flex mb-3'>
                                                <div>(i)</div><div style={{ marginLeft: "2rem" }}>Brief description of profile of directors</div>
                                            </div>
                                            <div style={{ marginLeft: "5rem" }} className='d-flex mb-3'>
                                                <div>(ii)</div><div style={{ marginLeft: "2rem" }}>Other directorships held (provide names of the company(ies))</div>
                                            </div>
                                        </div>
                                        <div>
                                            <div style={{ marginLeft: "2rem" }} className='d-flex mb-3'>
                                                <div>(c)</div><div style={{ marginLeft: "2rem",fontWeight:"bold",fontSize:"16px" }}>Name(s) of the statutory auditor(s):</div>
                                            </div>
                                           
                                        </div>
                                        <div>
                                            <div style={{ marginLeft: "2rem" }} className='d-flex mb-3'>
                                                <div>(d)</div><div style={{ marginLeft: "2rem",fontWeight:"bold",fontSize:"16px" }}>Existing capital indicating classes of shares, if any, separately</div>
                                            </div>
                                            <div style={{ marginLeft: "5rem" }} className='d-flex mb-3'>
                                                <div>(i)</div><div style={{ marginLeft: "2rem" }}>Authorized Capital amounting to Rs._______ divided in to _____________ shares of Rs. _____ each.</div>
                                            </div>
                                            <div style={{ marginLeft: "5rem" }} className='d-flex mb-3'>
                                                <div>(ii)</div><div style={{ marginLeft: "2rem" }}>Paid-up capital amounting to Rs._______ divided in to _____________ shares of Rs. _____ each. </div>
                                            </div>
                                            
                                        </div>
                                        <div>
                                            <div style={{ marginLeft: "2rem" }} className='d-flex mb-3'>
                                                <div>(e)</div><div style={{ marginLeft: "2rem",fontWeight:"bold",fontSize:"16px" }}>Reason / Justification for the proposed issue:</div>
                                            </div>
                                          
                                            
                                        </div>
                                        <div>
                                            <div style={{ marginLeft: "2rem" }} className='d-flex mb-3'>
                                                <div>(f)</div><div style={{ marginLeft: "2rem",fontWeight:"bold",fontSize:"16px" }}>Name of holding/subsidiary company, if any:</div>
                                            </div>
                                           
                                            
                                        </div>

                                        
                                    </div>
                                    {/* section 1.2 */}
                                    <div className='col-12 mb-3'>
                                        <div className='d-flex  mb-3'>
                                            <h6 className='form-sr-no'>1.2 </h6>
                                            <h6 className='border-bottom-3'>Financial Highlights</h6>
                                        </div>
                                        <ol style={{ display: "block" }} className="fh-ol">
                                            <li className='mb-3'>
                                                <span className=''>Financial highlights of company for preceding three years, if applicable in tabular form including following:</span>
                                                <ol style={{ display: "block" }} className="fh-sub-ol mt-3">
                                                    <li>Sales/Revenue</li>
                                                    <li>Gross profit</li>
                                                    <li>Profit before interest & taxes</li>
                                                    <li>Profit after tax</li>
                                                    <li>Accumulated profit / (loss)</li>
                                                    <li>Total Assets</li>
                                                    <li>Total Liabilities</li>
                                                    <li>Net equity</li>
                                                    <li>Break-up value per share</li>
                                                    <li>Earnings per share</li>
                                                    <li>Cash Dividend, if any</li>
                                                    <li>Bonus issue, if any.</li>
                                                </ol>
                                                <em className='em-instruction'>(As per requirment of section 83(3) of the Companies Act, 2017, the issuer is required to send the lastest statement of Accounts along-with the circular)</em>
                                            </li>
                                            <li className='mb-3'>
                                                <span className=''>Financial highlights of consolidated financial statement same as (a) above, if any</span>
                                            </li>
                                            <li className='text-primary'>
                                                <span className=' text-dark'>Major related part Transactions</span> <span className=''>[Not applicable to Private Companies]</span>
                                            </li>
                                            <li className='text-primary'>
                                                <div className='mb-2'>
                                                    <span className=' text-dark'>Detail of issue of capital in previous five years </span> <span className=''>[Not applicable to Private Companies]</span>
                                                </div>
                                                <div className='d-flex justify-content-center'>
                                                    <ol style={{ display: "block" }} className='center-ol text-dark'>
                                                        <li>Year wise detail of issue of capital</li>
                                                        <li>Detail of fund utilization</li>
                                                    </ol>
                                                </div>
                                            </li>
                                        </ol>
                                    </div>
                                    {/* section 1.3 */}
                                    <div className='col-12 mb-3'>
                                        <div className='d-flex  mb-3'>
                                            <h6 className='form-sr-no'>1.3 </h6>
                                            <h6 className='border-bottom-3'>Details of the current right issue:</h6>
                                        </div>
                                        <ol style={{ display: "block" }} className="cri-ol ">
                                            <li>Description of issue:
                                                <ul className='list-unstyled'>
                                                    <li className='mb-2'>
                                                        <div className='row'>
                                                            <div className='col'>Amount of the proposed issue</div>
                                                            <div className='col'>Rs.<span className='inline-input'>
                                                                <div className='border-none border-bottom border-dark inline-input-h' ></div>
                                                            </span></div>
                                                        </div>
                                                    </li>
                                                    <li className='mb-2'>
                                                        <div className='row'>
                                                            <div className='col'>divided into</div>
                                                            <div className='col'>
                                                                <span className='inline-input'>
                                                                    <div className='border-none border-bottom border-dark inline-input-h' ></div>
                                                                </span>
                                                                <span className='inline-input ml-2'>
                                                                    <div className='border-none border-bottom border-dark inline-input-h' ></div>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className='row'>
                                                            <div className='col'>of Rs.</div>
                                                            <div className='col'><span className='inline-input'>
                                                                <div className='border-none border-bottom border-dark inline-input-h' ></div>
                                                            </span>each
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </li>
                                            <li>
                                                <div className='row'>
                                                    <div className='col'>
                                                        Face value of the share
                                                    </div>
                                                    <div className='col'>
                                                        Rs.<span className='inline-input'>
                                                            <div className='border-none border-bottom border-dark inline-input-h' ></div>
                                                        </span>each
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                Basic of determination of price of the right issue <span className='text-primary'>[Not applicable to Private companies].</span>
                                            </li>
                                            <li>
                                                Proportion of new issue to existing issued shares with condition, if any:
                                            </li>
                                            <li>
                                                Date of meeting of board of directors (BOD) wherein the right issue was approved
                                            </li>
                                            <li>
                                                Names of directors attended the BOD meeting:
                                            </li>
                                            <li>
                                                Excepted benefits arising from right issue
                                            </li>
                                            <li>
                                                Financial effects arising from right issue <span className='text-primary'>[Not applicable to Private companies].</span>
                                                <div className='d-flex justify-content-center'>
                                                    <ol style={{ display: "block" }} className='text-dark' type='i'>
                                                        <li>increase in paid-up share capital.</li>
                                                        <li>net asset/breakup value per share before and after right issue.</li>
                                                        <li>gearing ratio before and after right issue.</li>
                                                    </ol>
                                                </div>
                                            </li>
                                        </ol>
                                    </div>
                                    {/* section 1.4 */}
                                    <div className='col-12 mb-3'>
                                        <div className='d-flex  mb-3'>
                                            <h6 className='form-sr-no'>1.4 </h6>
                                            <h6 className='border-bottom-3'>Eligibility criteria & associated matters:</h6>
                                        </div>
                                        <ol style={{ display: "block" }} className="cri-ol ">
                                            <li>Eligibility Shareholder</li>
                                            <li>Date up to which the offer, if not accepted, shall be deemed to have been declined</li>
                                            <li>
                                                Mode of acceptance (only through banking channel)
                                                <ol style={{ display: "block" }} className='acceptance-mode-ol'>
                                                    <li>Bank account number(s)</li>
                                                    <li>Date by which amount to be credited in bank account to contitute valid acceptance</li>
                                                </ol>
                                            </li>
                                        </ol>
                                    </div>
                                    {/* section 1.5 */}
                                    <div className='col-12 mb-3'>
                                        <div className='d-flex  mb-3'>
                                            <h6 className='form-sr-no'>1.5 </h6>
                                            <h6 className='border-bottom-3'>Information regarding risk and risk mitigating factors, as applicable: <span className='text-primary'>[Not applicable for private companies]</span></h6>
                                        </div>
                                        <ol style={{ display: "block" }} className="cri-ol ">
                                            <li>description of major risks and company's efforts to mitigate them:
                                                <ol style={{ display: "block" }} className='major-risks-ol'>
                                                    <li>risks relating to the project for which proceeds of the right issue are to be utilized</li>
                                                    <li>risks relating to subscription/under-subscription of right issue.</li>
                                                    <li>material contingencies.</li>
                                                    <li>material commitments.</li>
                                                    <li>any adverse issue reported by the auditor in their audit reports in previous five years.</li>
                                                </ol>
                                            </li>
                                            <li>Any other material information that may have direct or indirect bearing on the investment decision.</li>
                                        </ol>
                                    </div>
                                    {/* part II */}
                                    <div className='col-12 mb-3'>
                                        <h6 className='text-center'><u>PART-II</u></h6>
                                    </div>
                                    {/* section 2.1 */}
                                    <div className='col-12  mb-3'>
                                        <div className='row'>
                                            <div className='col-6 d-flex'>
                                                <span className='form-sr-no mt-3'>2.1</span>
                                                <span className='mt-3'>Signatures of all directors or an officer authorized by the Board.</span>
                                            </div>
                                            <div className='col-6'>
                                                <table className='table border-all'>
                                                    <tbody>
                                                        <tr>
                                                            <th style={{borderTop:"1px solid black"}} className='text-center border-all'>Name</th>
                                                            <th style={{borderTop:"1px solid black"}} className='text-center border-all'>Designation</th>
                                                            <th style={{borderTop:"1px solid black"}} className='text-center border-all'>Signature</th>
                                                        </tr>
                                                        <tr>
                                                            <td className='border-all p-0'>
                                                                <div className='d-flex align-items-center'>
                                                                    <span className='pl-1'>1.</span>
                                                                    <div className='border-none h-100' ></div>
                                                                </div>
                                                            </td>
                                                            <td className='borader-all border-all p-0'>
                                                                <div className='border-none h-100' ></div>
                                                            </td>
                                                            <td className='borader-all border-all'></td>
                                                        </tr>
                                                        <tr>
                                                            <td className='border-all p-0'>
                                                                <div className='d-flex align-items-center'>
                                                                    <span className='pl-1'>2.</span>
                                                                    <div className='border-none h-100' ></div>
                                                                </div>
                                                            </td>
                                                            <td className='borader-all border-all p-0'>
                                                                <div className='border-none h-100' ></div>
                                                            </td>
                                                            <td className='borader-all border-all'></td>
                                                        </tr>
                                                        <tr>
                                                            <td className='border-all p-0'>
                                                                <div className='d-flex align-items-center'>
                                                                    <span className='pl-1'>3.</span>
                                                                    <div className='border-none h-100' ></div>
                                                                </div>
                                                            </td>
                                                            <td className='borader-all border-all p-0'>
                                                                <div className='border-none h-100' ></div>
                                                            </td>
                                                            <td className='borader-all border-all'></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    {/* section 2.2 */}
                                    <div className='col-12  mb-3'>
                                        <div className='row'>
                                            <div className='col-6 d-flex'>
                                                <span className='form-sr-no mt-3'>2.2</span>
                                                <span className='mt-3'>Place</span>
                                            </div>
                                            <div className='col-6'>
                                                <div style={{minHeight:"35px"}} className='border-all' ></div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* section 2.3 */}
                                    <div className='col-12 '>
                                        <div className='row'>
                                            <div className='offset-6 col-6 text-center'>
                                                <div className="row">
                                                    <div className="col-3 pl-4">DD</div>
                                                    <div className="col-3 pl-4">MM</div>
                                                    <div className="col-6">YYYY</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-12  mb-5'>
                                        <div className='row'>
                                            <div className='col-6 d-flex'><span className='form-sr-no mt-2'>2.3</span><span className='mt-2'>Date</span></div>
                                            <div className='col-6'>
                                                <div className="row">
                                                    <div className="col-3">
                                                        <table className="w-100">
                                                            <tbody>
                                                                <tr>
                                                                    <td className='d-flex justify-content-center'>
                                                                        <div style={{width:"50%",minHeight:"30px"}} className="text-center border-all" ></div>
                                                                        <div style={{width:"50%",minHeight:"30px"}} className="text-center border-all border-left-0" ></div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div className="col-3">
                                                        <table className="w-100">
                                                            <tbody>
                                                                <tr>
                                                                    <td className='d-flex justify-content-center'>
                                                                        <div style={{width:"50%",minHeight:"30px"}} className=" text-center border-all" ></div>
                                                                        <div style={{width:"50%",minHeight:"30px"}} className=" text-center border-all border-left-0" ></div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div className="col-6">
                                                        <table className="w-100">
                                                            <tbody>
                                                                <tr>
                                                                    <td className='d-flex justify-content-center'>
                                                                        <div style={{width:"25%",minHeight:"30px"}} className="text-center border-all" ></div>
                                                                        <div style={{width:"25%",minHeight:"30px"}} className="text-center border-all border-left-0" ></div>
                                                                        <div style={{width:"25%",minHeight:"30px"}} className="text-center border-all border-left-0" ></div>
                                                                        <div style={{width:"25%",minHeight:"30px"}} className="text-center border-all border-left-0" ></div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* enclosures */}
                                    <div className='col-12'>
                                        <div style={{ fontWeight: "bold" }}>Enclosures:</div>
                                        <ol className='enclosure-ol flex-column'>
                                            <li>Copy of the board resolution authorizing an officer of the company to sign the circular.</li>
                                            <li>Original challan or other evidance of payment of fee specified in Seventh Schedule of the Act.</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </PDFExport>
                    : <PDFExport
                        paperSize="A4"
                        margin="1cm"
                        scale={0.6}
                        fileName={`Form 12`}
                        pageTemplate={PageTemplate}
                        ref={pdfExportComponent}
                    >
                       <div style={{ fontFamily: `${fontFamilyForStaticData}`, fontSize: `${fontSizeForStaticData}`, fontWeight: 500 }}>
                            <FormName>
                                Form-12
                            </FormName>
                            <div className='row'>
                                <div className='col-md-12' >
                                    <HeadingWrapper className='d-flex justify-content-center'>
                                        THE COMPANIES REGULATIONS, 2024
                                    </HeadingWrapper>

                                </div>
                            </div>

                            <div className='row'>
                                <div className='col-md-12'>
                                    <div style={{
                                        fontSize: '15px',
                                        // fontWeight: 'bold' 
                                    }} className='d-flex justify-content-center'>
                                        [Section 83(3) and Regulations 39 & 108 & 30]
                                    </div>

                                </div>
                            </div>

                            <div className='row mt-4'>
                                <div className='col-md-12'>
                                    <HeadingWrapper className='text-center d-flex justify-content-center'>
                                        Circular to be sent to the Members along-with the Offer letter
                                    </HeadingWrapper>

                                </div>
                            </div>
                            <PartsWrapper className='mt-4 mb-3'>
                                PART-I
                            </PartsWrapper>


                            <div className='row'>
                                <div className='col-md-12' style={{ padding: '25px 50px', wordWrap: 'break-word' }}>

                                    {/* section 1.1 */}
                                    <div className='col-12 mb-3'>
                                        <div className='d-flex  mb-3'>
                                            <h6 className='form-sr-no'>1.1 </h6>
                                            <h6 className='border-bottom-3'>Information pertaining to company offering right issue:</h6>
                                        </div>
                                        <div>
                                            <div style={{ marginLeft: "2rem" }} className='d-flex mb-3'>
                                                <div>(a)</div><div style={{ marginLeft: "2rem",fontWeight:"bold",fontSize:"16px" }}>Company profile and history:</div>
                                            </div>
                                            <div style={{ marginLeft: "5rem" }} className='d-flex mb-3'>
                                                <div>(i)</div><div style={{ marginLeft: "2rem" }}>Name of Company</div>
                                            </div>
                                            <div style={{ marginLeft: "5rem" }} className='d-flex mb-3'>
                                                <div>(ii)</div><div style={{ marginLeft: "2rem" }}>Corporate Unique Identification Number(CUIN)</div>
                                            </div>
                                            <div style={{ marginLeft: "5rem" }} className='d-flex mb-3'>
                                                <div>(iii)</div><div style={{ marginLeft: "2rem" }}>Date of Incorpration</div>
                                            </div>
                                            <div style={{ marginLeft: "5rem" }} className='d-flex mb-3'>
                                                <div>(iv)</div><div style={{ marginLeft: "2rem" }}>Registered office address</div>
                                            </div>
                                            <div style={{ marginLeft: "5rem" }} className='d-flex mb-3'>
                                                <div>(v)</div><div style={{ marginLeft: "2rem" }}>Date of commencement of business, if any<span className=' text-primary ml-2'>[Not applicable for private companies]</span></div>
                                            </div>
                                            <div style={{ marginLeft: "5rem" }} className='d-flex mb-3'>
                                                <div>(vi)</div><div style={{ marginLeft: "2rem" }}>Kind of the company (private/public etc.)</div>
                                            </div>
                                            <div style={{ marginLeft: "5rem" }} className='d-flex mb-3'>
                                                <div>(vii)</div><div style={{ marginLeft: "2rem" }}>Website, if any.</div>
                                            </div>
                                        </div>
                                        <div>
                                            <div style={{ marginLeft: "2rem" }} className='d-flex mb-3'>
                                                <div>(b)</div><div style={{ marginLeft: "2rem",fontWeight:"bold",fontSize:"16px" }}>Profile of management and sponsors:<span style={{color:"#007bff"}} className='ml-2'>[Not applicable to private companies]</span></div>
                                            </div>
                                            <div style={{ marginLeft: "5rem" }} className='d-flex mb-3'>
                                                <div>(i)</div><div style={{ marginLeft: "2rem" }}>Brief description of profile of directors</div>
                                            </div>
                                            <div style={{ marginLeft: "5rem" }} className='d-flex mb-3'>
                                                <div>(ii)</div><div style={{ marginLeft: "2rem" }}>Other directorships held (provide names of the company(ies))</div>
                                            </div>
                                        </div>
                                        <div>
                                            <div style={{ marginLeft: "2rem" }} className='d-flex mb-3'>
                                                <div>(c)</div><div style={{ marginLeft: "2rem",fontWeight:"bold",fontSize:"16px" }}>Name(s) of the statutory auditor(s):</div>
                                            </div>
                                           
                                        </div>
                                        <div>
                                            <div style={{ marginLeft: "2rem" }} className='d-flex mb-3'>
                                                <div>(d)</div><div style={{ marginLeft: "2rem",fontWeight:"bold",fontSize:"16px" }}>Existing capital indicating classes of shares, if any, separately</div>
                                            </div>
                                            <div style={{ marginLeft: "5rem" }} className='d-flex mb-3'>
                                                <div>(i)</div><div style={{ marginLeft: "2rem" }}>Authorized Capital amounting to Rs._______ divided in to _____________ shares of Rs. _____ each.</div>
                                            </div>
                                            <div style={{ marginLeft: "5rem" }} className='d-flex mb-3'>
                                                <div>(ii)</div><div style={{ marginLeft: "2rem" }}>Paid-up capital amounting to Rs._______ divided in to _____________ shares of Rs. _____ each. </div>
                                            </div>
                                            
                                        </div>
                                        <div>
                                            <div style={{ marginLeft: "2rem" }} className='d-flex mb-3'>
                                                <div>(e)</div><div style={{ marginLeft: "2rem",fontWeight:"bold",fontSize:"16px" }}>Reason / Justification for the proposed issue:</div>
                                            </div>
                                          
                                            
                                        </div>
                                        <div>
                                            <div style={{ marginLeft: "2rem" }} className='d-flex mb-3'>
                                                <div>(f)</div><div style={{ marginLeft: "2rem",fontWeight:"bold",fontSize:"16px" }}>Name of holding/subsidiary company, if any:</div>
                                            </div>
                                           
                                            
                                        </div>

                                        
                                    </div>
                                    {/* section 1.2 */}
                                    <div className='col-12 mb-3'>
                                        <div className='d-flex  mb-3'>
                                            <h6 className='form-sr-no'>1.2 </h6>
                                            <h6 className='border-bottom-3'>Financial Highlights</h6>
                                        </div>
                                        <ol style={{ display: "block" }} className="fh-ol">
                                            <li className='mb-3'>
                                                <span className=''>Financial highlights of company for preceding three years, if applicable in tabular form including following:</span>
                                                <ol style={{ display: "block" }} className="fh-sub-ol mt-3">
                                                    <li>Sales/Revenue</li>
                                                    <li>Gross profit</li>
                                                    <li>Profit before interest & taxes</li>
                                                    <li>Profit after tax</li>
                                                    <li>Accumulated profit / (loss)</li>
                                                    <li>Total Assets</li>
                                                    <li>Total Liabilities</li>
                                                    <li>Net equity</li>
                                                    <li>Break-up value per share</li>
                                                    <li>Earnings per share</li>
                                                    <li>Cash Dividend, if any</li>
                                                    <li>Bonus issue, if any.</li>
                                                </ol>
                                                <em className='em-instruction'>(As per requirment of section 83(3) of the Companies Act, 2017, the issuer is required to send the lastest statement of Accounts along-with the circular)</em>
                                            </li>
                                            <li className='mb-3'>
                                                <span className=''>Financial highlights of consolidated financial statement same as (a) above, if any</span>
                                            </li>
                                            <li className='text-primary'>
                                                <span className=' text-dark'>Major related part Transactions</span> <span className=''>[Not applicable to Private Companies]</span>
                                            </li>
                                            <li className='text-primary'>
                                                <div className='mb-2'>
                                                    <span className=' text-dark'>Detail of issue of capital in previous five years </span> <span className=''>[Not applicable to Private Companies]</span>
                                                </div>
                                                <div className='d-flex justify-content-center'>
                                                    <ol style={{ display: "block" }} className='center-ol text-dark'>
                                                        <li>Year wise detail of issue of capital</li>
                                                        <li>Detail of fund utilization</li>
                                                    </ol>
                                                </div>
                                            </li>
                                        </ol>
                                    </div>
                                    {/* section 1.3 */}
                                    <div className='col-12 mb-3'>
                                        <div className='d-flex  mb-3'>
                                            <h6 className='form-sr-no'>1.3 </h6>
                                            <h6 className='border-bottom-3'>Details of the current right issue:</h6>
                                        </div>
                                        <ol style={{ display: "block" }} className="cri-ol ">
                                            <li>Description of issue:
                                                <ul className='list-unstyled'>
                                                    <li className='mb-2'>
                                                        <div className='row'>
                                                            <div className='col'>Amount of the proposed issue</div>
                                                            <div className='col'>Rs.<span className='inline-input'>
                                                                <div className='border-none border-bottom border-dark inline-input-h' ></div>
                                                            </span></div>
                                                        </div>
                                                    </li>
                                                    <li className='mb-2'>
                                                        <div className='row'>
                                                            <div className='col'>divided into</div>
                                                            <div className='col'>
                                                                <span className='inline-input'>
                                                                    <div className='border-none border-bottom border-dark inline-input-h' ></div>
                                                                </span>
                                                                <span className='inline-input ml-2'>
                                                                    <div className='border-none border-bottom border-dark inline-input-h' ></div>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className='row'>
                                                            <div className='col'>of Rs.</div>
                                                            <div className='col'><span className='inline-input'>
                                                                <div className='border-none border-bottom border-dark inline-input-h' ></div>
                                                            </span>each
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </li>
                                            <li>
                                                <div className='row'>
                                                    <div className='col'>
                                                        Face value of the share
                                                    </div>
                                                    <div className='col'>
                                                        Rs.<span className='inline-input'>
                                                            <div className='border-none border-bottom border-dark inline-input-h' ></div>
                                                        </span>each
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                Basic of determination of price of the right issue <span className='text-primary'>[Not applicable to Private companies].</span>
                                            </li>
                                            <li>
                                                Proportion of new issue to existing issued shares with condition, if any:
                                            </li>
                                            <li>
                                                Date of meeting of board of directors (BOD) wherein the right issue was approved
                                            </li>
                                            <li>
                                                Names of directors attended the BOD meeting:
                                            </li>
                                            <li>
                                                Excepted benefits arising from right issue
                                            </li>
                                            <li>
                                                Financial effects arising from right issue <span className='text-primary'>[Not applicable to Private companies].</span>
                                                <div className='d-flex justify-content-center'>
                                                    <ol style={{ display: "block" }} className='text-dark' type='i'>
                                                        <li>increase in paid-up share capital.</li>
                                                        <li>net asset/breakup value per share before and after right issue.</li>
                                                        <li>gearing ratio before and after right issue.</li>
                                                    </ol>
                                                </div>
                                            </li>
                                        </ol>
                                    </div>
                                    {/* section 1.4 */}
                                    <div className='col-12 mb-3'>
                                        <div className='d-flex  mb-3'>
                                            <h6 className='form-sr-no'>1.4 </h6>
                                            <h6 className='border-bottom-3'>Eligibility criteria & associated matters:</h6>
                                        </div>
                                        <ol style={{ display: "block" }} className="cri-ol ">
                                            <li>Eligibility Shareholder</li>
                                            <li>Date up to which the offer, if not accepted, shall be deemed to have been declined</li>
                                            <li>
                                                Mode of acceptance (only through banking channel)
                                                <ol style={{ display: "block" }} className='acceptance-mode-ol'>
                                                    <li>Bank account number(s)</li>
                                                    <li>Date by which amount to be credited in bank account to contitute valid acceptance</li>
                                                </ol>
                                            </li>
                                        </ol>
                                    </div>
                                    {/* section 1.5 */}
                                    <div className='col-12 mb-3'>
                                        <div className='d-flex  mb-3'>
                                            <h6 className='form-sr-no'>1.5 </h6>
                                            <h6 className='border-bottom-3'>Information regarding risk and risk mitigating factors, as applicable: <span className='text-primary'>[Not applicable for private companies]</span></h6>
                                        </div>
                                        <ol style={{ display: "block" }} className="cri-ol ">
                                            <li>description of major risks and company's efforts to mitigate them:
                                                <ol style={{ display: "block" }} className='major-risks-ol'>
                                                    <li>risks relating to the project for which proceeds of the right issue are to be utilized</li>
                                                    <li>risks relating to subscription/under-subscription of right issue.</li>
                                                    <li>material contingencies.</li>
                                                    <li>material commitments.</li>
                                                    <li>any adverse issue reported by the auditor in their audit reports in previous five years.</li>
                                                </ol>
                                            </li>
                                            <li>Any other material information that may have direct or indirect bearing on the investment decision.</li>
                                        </ol>
                                    </div>
                                    {/* part II */}
                                    <div className='col-12 mb-3'>
                                        <h6 className='text-center'><u>PART-II</u></h6>
                                    </div>
                                    {/* section 2.1 */}
                                    <div className='col-12  mb-3'>
                                        <div className='row'>
                                            <div className='col-6 d-flex'>
                                                <span className='form-sr-no mt-3'>2.1</span>
                                                <span className='mt-3'>Signatures of all directors or an officer authorized by the Board.</span>
                                            </div>
                                            <div className='col-6'>
                                                <table className='table border-all'>
                                                    <tbody>
                                                        <tr>
                                                            <th style={{borderTop:"1px solid black"}} className='text-center border-all'>Name</th>
                                                            <th style={{borderTop:"1px solid black"}} className='text-center border-all'>Designation</th>
                                                            <th style={{borderTop:"1px solid black"}} className='text-center border-all'>Signature</th>
                                                        </tr>
                                                        <tr>
                                                            <td className='border-all p-0'>
                                                                <div className='d-flex align-items-center'>
                                                                    <span className='pl-1'>1.</span>
                                                                    <div className='border-none h-100' ></div>
                                                                </div>
                                                            </td>
                                                            <td className='borader-all border-all p-0'>
                                                                <div className='border-none h-100' ></div>
                                                            </td>
                                                            <td className='borader-all border-all'></td>
                                                        </tr>
                                                        <tr>
                                                            <td className='border-all p-0'>
                                                                <div className='d-flex align-items-center'>
                                                                    <span className='pl-1'>2.</span>
                                                                    <div className='border-none h-100' ></div>
                                                                </div>
                                                            </td>
                                                            <td className='borader-all border-all p-0'>
                                                                <div className='border-none h-100' ></div>
                                                            </td>
                                                            <td className='borader-all border-all'></td>
                                                        </tr>
                                                        <tr>
                                                            <td className='border-all p-0'>
                                                                <div className='d-flex align-items-center'>
                                                                    <span className='pl-1'>3.</span>
                                                                    <div className='border-none h-100' ></div>
                                                                </div>
                                                            </td>
                                                            <td className='borader-all border-all p-0'>
                                                                <div className='border-none h-100' ></div>
                                                            </td>
                                                            <td className='borader-all border-all'></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    {/* section 2.2 */}
                                    <div className='col-12  mb-3'>
                                        <div className='row'>
                                            <div className='col-6 d-flex'>
                                                <span className='form-sr-no mt-3'>2.2</span>
                                                <span className='mt-3'>Place</span>
                                            </div>
                                            <div className='col-6'>
                                                <div style={{minHeight:"35px"}} className='border-all' ></div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* section 2.3 */}
                                    <div className='col-12 '>
                                        <div className='row'>
                                            <div className='offset-6 col-6 text-center'>
                                                <div className="row">
                                                    <div className="col-3 pl-4">DD</div>
                                                    <div className="col-3 pl-4">MM</div>
                                                    <div className="col-6">YYYY</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-12  mb-5'>
                                        <div className='row'>
                                            <div className='col-6 d-flex'><span className='form-sr-no mt-2'>2.3</span><span className='mt-2'>Date</span></div>
                                            <div className='col-6'>
                                                <div className="row">
                                                    <div className="col-3">
                                                        <table className="w-100">
                                                            <tbody>
                                                                <tr>
                                                                    <td className='d-flex justify-content-center'>
                                                                        <div style={{width:"50%",minHeight:"30px"}} className="text-center border-all" ></div>
                                                                        <div style={{width:"50%",minHeight:"30px"}} className="text-center border-all border-left-0" ></div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div className="col-3">
                                                        <table className="w-100">
                                                            <tbody>
                                                                <tr>
                                                                    <td className='d-flex justify-content-center'>
                                                                        <div style={{width:"50%",minHeight:"30px"}} className=" text-center border-all" ></div>
                                                                        <div style={{width:"50%",minHeight:"30px"}} className=" text-center border-all border-left-0" ></div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div className="col-6">
                                                        <table className="w-100">
                                                            <tbody>
                                                                <tr>
                                                                    <td className='d-flex justify-content-center'>
                                                                        <div style={{width:"25%",minHeight:"30px"}} className="text-center border-all" ></div>
                                                                        <div style={{width:"25%",minHeight:"30px"}} className="text-center border-all border-left-0" ></div>
                                                                        <div style={{width:"25%",minHeight:"30px"}} className="text-center border-all border-left-0" ></div>
                                                                        <div style={{width:"25%",minHeight:"30px"}} className="text-center border-all border-left-0" ></div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* enclosures */}
                                    <div className='col-12'>
                                        <div style={{ fontWeight: "bold" }}>Enclosures:</div>
                                        <ol className='enclosure-ol flex-column'>
                                            <li>Copy of the board resolution authorizing an officer of the company to sign the circular.</li>
                                            <li>Original challan or other evidance of payment of fee specified in Seventh Schedule of the Act.</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </PDFExport>}
            </div>
            <button disabled={loading} className='btn btn-danger' onClick={(e) => {
                // if(viewPdf)
                //   SetViewPDf(true)
                if (pdfExportComponent.current) {
                    pdfExportComponent.current.save();
                }
            }}>Download PDF</button>
        </div>
    )
}
const PartsWrapper = styled.div`
color: #121212;
text-decoration: underline;
justify-content: center;
display: flex;
margin-right: 15px;
font-size: 17px;
font-weight: bold;
`;
const ScrollWrapper = styled.div`
max-height: 50px;
overflow-y: scroll;
overflow-x: hidden;
::-webkit-scrollbar{
    height: 5px;
    width: 3px;
  }
  
  ::-webkit-scrollbar-track{
    background: #F9F9FB;
  }
  ::-webkit-scrollbar-thumb{
    background: #4E515680;
    border-radius: 5px;
  
  }
`;
const HeadingWrapper = styled.div`
font-size: 17px;
font-weight: bold;
`;
const FormName = styled.div`
color: #121212;
text-decoration: underline;
justify-content: end;
display: flex;
margin-right: 15px;
font-size: 17px;
font-weight: bold;
`;
const Wrapper = styled.div`
table.table-bordered{
    border: 1px solid #121212
     border-color: #121212; 
    // border:1px solid blue;
    // margin-top:20px;
  }
// table.table-bordered > thead > tr > th{
//     // border:1px solid blue;
//     border-color: #121212; 
// }
// table.table-bordered > tbody > tr > td{
//     // border:1px solid blue;
//     borderC-color: #121212; 
// }
 tr td {
    // border:1px solid blue;.
    border-color: #121212; 
}

`;
const WrapperDashed = styled.div`

  td {
    
    border: 1px dashed #dddcdc;
}

`;