import React, { useState, useEffect } from 'react'
import { PDFExport } from "@progress/kendo-react-pdf";
import PageTemplate from "./page-template";
import {
    getCompanies,
} from "../../../store/services/company.service";
import styled from 'styled-components';
import { TiTick } from "react-icons/ti";
import { numberWithCommas } from 'utilities/utilityFunctions';
export const AppendixToForm25 = ({ data, formTemplate = false }) => {
    const pdfExportComponent = React.useRef(null);
    const [CompanyData, setCompanyData] = useState(null)
    const [loading, setLoading] = useState(false);
    const [fontFamilyForStaticData, setFontFamilyForStaticData] = useState('Montserrat, sans-serif')
    const [fontSizeForStaticData, setFontSizeForStaticData] = useState('14px')
    const [fontfamilyforDynimicData, setFontFamilyForDynmicData] = useState('Segoe Print')
    const [fontColor, setFontColor] = useState('')
    const baseEmail = sessionStorage.getItem("email") || "";
    const [governance, setGovernance] = useState({})
    const getAllCompanies = async () => {
        setLoading(true)
        try {
            const response = await getCompanies(baseEmail);
            if (response.status === 200) {
                response.data.data = response.data.data.filter((item) => item?.active === 'Y')
                const parents = response.data.data;
                const company = parents?.find(ite => ite?.code == data?.company_code)
                let Allperson = company.governance && company.governance != "" ? JSON.parse(company.governance) : []
                let sec = Allperson.find(x => x.role == "Company Secretary" && x?.active?.toLowerCase() != 'n')
                let ceo = Allperson.find(x => x.role == "CEO" && x?.active?.toLowerCase() != 'n')


                if (sec) {
                    setGovernance(sec)
                } else if (ceo) {
                    setGovernance(ceo)
                }
                setCompanyData(company)
                setTimeout(() => {
                    setLoading(false)
                }, 1000);
            }
        } catch (error) {
            setLoading(false)
        }
    };
    useEffect(() => {
        getAllCompanies()
    }, [])
    return (
        <div>
            <div
                style={{ fontFamily: "sans-serif " }}
            >

                {formTemplate ?
                    <PDFExport
                        paperSize="A4"
                        margin="1cm"
                        scale={0.6}
                        fileName={`Appendix to Form-25`}
                        pageTemplate={PageTemplate}
                        ref={pdfExportComponent}
                    >
                        <div style={{ fontFamily: `${fontFamilyForStaticData}`, fontSize: `${fontSizeForStaticData}`, fontWeight: 500 }}>
                            <FormName>
                                Appendix to Form-25
                            </FormName>
                            <div className='row'>
                                <div className='col-md-12' >
                                    <HeadingWrapper className='d-flex justify-content-center'>
                                        THE COMPANIES ACT, 2017
                                    </HeadingWrapper>

                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <HeadingWrapper className='d-flex justify-content-center'>
                                        THE COMPANIES REGULATIONS, 2024
                                    </HeadingWrapper>

                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <div style={{ fontSize: '15px', fontWeight: 'bold' }} className='d-flex justify-content-center'>
                                        [Section 131(6)]
                                    </div>
                                </div>
                            </div>

                            <div className='row mt-4'>
                                <div className='col-md-12'>
                                    <HeadingWrapper className='text-center d-flex justify-content-center'>
                                        STATUTORY REPORT TO THE MEMBERS
                                    </HeadingWrapper>
                                </div>
                            </div>

                            <div className='row'>
                                <div className='col-md-12' style={{ padding: '25px 50px', wordWrap: 'break-word' }}>

                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>1</div>
                                        <div className='align-items-center' style={{ width: '29%', padding: '0px 10px 10px 10px' }}>Name of the Company  </div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '68%', padding: '0px 10px 10px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>2</div>
                                        <div className='align-items-center' style={{ padding: '0px 10px 10px 10px', fontWeight: "bold" }}> Shares allotted and cash received up to the date of this report</div>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '4%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>2.1</div>
                                        <div className='align-items-center' style={{ width: '29%', padding: '0px 10px 10px 10px' }}>Nominal share capital of the company </div>
                                        <div className='align-items-center' style={{ width: '20%', padding: '0px 10px 10px 10px' }}>No of shares</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '26%', padding: '0px 10px 10px 10px', }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '4%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ width: '29%', padding: '0px 10px 10px 10px' }}></div>
                                        <div className='align-items-center' style={{ width: '20%', padding: '0px 10px 10px 10px' }}>Rs. per share</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '26%', padding: '0px 10px 10px 10px', }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '4%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ width: '29%', padding: '0px 10px 10px 10px' }}></div>
                                        <div className='align-items-center' style={{ width: '20%', padding: '0px 10px 10px 10px' }}>Amount</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '26%', padding: '0px 10px 10px 10px', }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '4%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>2.2</div>
                                        <div className='align-items-center' style={{ width: '29%', padding: '0px 10px 10px 10px' }}>Particulars</div>
                                        <ScrollWrapper className='align-items-center' style={{ minHeight: "60px", border: '1px solid #121212', width: '16%', padding: '0px 10px 10px 10px', marginRight: "1%", }}>Kind/ Class of shares</ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ minHeight: "60px", border: '1px solid #121212', width: '16%', padding: '0px 10px 10px 10px', marginRight: "1%", }}>No of shares</ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ minHeight: "60px", border: '1px solid #121212', width: '16%', padding: '0px 10px 10px 10px', marginRight: "1%", }}>Nominal value of each share</ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ minHeight: "60px", border: '1px solid #121212', width: '16%', padding: '0px 10px 10px 10px', }}>Cash received</ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '4%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>2.3</div>
                                        <div className='align-items-center' style={{ width: '29%', padding: '0px 10px 10px 10px' }}>Allotted subject to payment thereof in cash.</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '16%', padding: '0px 10px 10px 10px', marginRight: "1%", }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '16%', padding: '0px 10px 10px 10px', marginRight: "1%", }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '16%', padding: '0px 10px 10px 10px', marginRight: "1%", }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '16%', padding: '0px 10px 10px 10px', }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '4%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>2.4</div>
                                        <div className='align-items-center' style={{ width: '29%', padding: '0px 10px 10px 10px' }}>Allotted as fully paid up otherwise than in cash and the consideration for which the shares have been so allotted being: (to be specified)</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '16%', padding: '0px 10px 10px 10px', marginRight: "1%", }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '16%', padding: '0px 10px 10px 10px', marginRight: "1%", }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '16%', padding: '0px 10px 10px 10px', marginRight: "1%", }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '16%', padding: '0px 10px 10px 10px', }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '4%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>2.5</div>
                                        <div className='align-items-center' style={{ width: '35%', padding: '0px 10px 10px 10px' }}>Allotted at a discount per share of Rs.</div>
                                        <ScrollWrapper className='align-items-center' style={{ width: '14%', padding: '0px 10px 10px 10px', marginRight: "1%", }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ width: '14%', padding: '0px 10px 10px 10px', marginRight: "1%", }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ width: '14%', padding: '0px 10px 10px 10px', marginRight: "1%", }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '14%', padding: '0px 10px 10px 10px', }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '4%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>2.6</div>
                                        <div className='align-items-center' style={{ width: '35%', padding: '0px 10px 10px 10px' }}>Allotted at a premium per share of Rs.</div>
                                        <ScrollWrapper className='align-items-center' style={{ width: '14%', padding: '0px 10px 10px 10px', marginRight: "1%", }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ width: '14%', padding: '0px 10px 10px 10px', marginRight: "1%", }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ width: '14%', padding: '0px 10px 10px 10px', marginRight: "1%", }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '14%', padding: '0px 10px 10px 10px', }}></ScrollWrapper>
                                    </div>

                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>3</div>
                                        <div className='align-items-center' style={{ padding: '0px 10px 10px 10px', fontWeight: "bold" }}> Abstract of receipts and payments of the company made upto a date within 15 days of the date of this report:</div>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '4%' }}></div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '30%', padding: '0px 10px 10px 10px', marginRight: "1%", textAlign: "center" }}>Receipt</ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '17%', padding: '0px 10px 10px 10px', marginRight: "1%", textAlign: "center" }}>Rs</ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '30%', padding: '0px 10px 10px 10px', marginRight: "1%", textAlign: "center" }}>Payments</ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '16%', padding: '0px 10px 10px 10px', }}>Rs</ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '4%' }}></div>
                                        <ScrollWrapper className='align-items-center' style={{ minHeight: "625px", border: '1px solid #121212', width: '30%', padding: '0px 10px 10px 10px', marginRight: "1%", textAlign: "center" }}>
                                            <div className='text-left'>
                                                <div className='mb-2'>Shares:</div>
                                                <div className='mb-5'>Advance payment for shares / Redeemable Capital / debentures</div>
                                                <div className='mb-2'>Loans:</div>
                                                <div className='mb-5'>Deposits</div>
                                                <div>Other Sources (to be specified):</div>
                                            </div>
                                        </ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ minHeight: "625px", border: '1px solid #121212', width: '17%', padding: '0px 10px 10px 10px', marginRight: "1%", textAlign: "center" }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ minHeight: "625px", border: '1px solid #121212', width: '30%', padding: '0px 10px 10px 10px', marginRight: "1%", textAlign: "center" }}>
                                            <div className='text-left'>
                                                <div className='mb-2'>Preliminary expenses</div>
                                                <div className='mb-2'>Commission on issue or sale of shares / redeemable Capital / debentures</div>
                                                <div className='mb-2'>Discount on issue or sale of shares / redeemable Capital / debentures</div>
                                                <div className='mb-2'>Capital expenditure:</div>
                                                <div className='mb-2'>Land.............</div>
                                                <div className='mb-2'>Building.............</div>
                                                <div className='mb-2'>Plant.............</div>
                                                <div className='mb-2'>Machinery.............</div>
                                                <div className='mb-2'>Other Items (to be specified)</div>
                                                <div className='mb-2'>Balances.............</div>
                                                <div className='mb-2'>In hand.............</div>
                                                <div>At Banks.............</div>
                                            </div>
                                        </ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ minHeight: "625px", border: '1px solid #121212', width: '16%', padding: '0px 10px 10px 10px', }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '4%' }}></div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '30%', padding: '0px 10px 10px 10px', marginRight: "1%", textAlign: "center" }}>Total:</ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '17%', padding: '0px 10px 10px 10px', marginRight: "1%", textAlign: "center" }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '30%', padding: '0px 10px 10px 10px', marginRight: "1%", textAlign: "center" }}>Total:</ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '16%', padding: '0px 10px 10px 10px', }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>4.</div>
                                        <div className='align-items-center' style={{ padding: '0px 10px 10px 10px', fontWeight: "bold" }}>Preliminary expenses—</div>
                                    </div>

                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '4%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>4.1</div>
                                        <div className='align-items-center' style={{ width: '29%', padding: '0px 10px 10px 10px' }}> Estimated in the Prospectus/Statement in lieu of Prospectus </div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '68%', padding: '0px 10px 10px 10px', }}>(Rs.):-</ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '4%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>4.2</div>
                                        <div className='align-items-center' style={{ padding: '0px 10px 10px 10px' }}>Preliminary expenses actually incurred up to the aforesaid date:-</div>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '4%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '4%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>(a)</div>
                                        <div className='align-items-center' style={{ padding: '0px 10px 10px 10px' }}>Legal & professional Charges</div>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '4%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '4%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>(b)</div>
                                        <div className='align-items-center' style={{ padding: '0px 10px 10px 10px' }}>Printing expenses of Memorandum and Articles</div>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '4%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '4%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>(c)</div>
                                        <div className='align-items-center' style={{ padding: '0px 10px 10px 10px' }}> Stamp and Registration fees</div>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '4%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '4%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>(d)</div>
                                        <div className='align-items-center' style={{ padding: '0px 10px 10px 10px' }}>Advertisement</div>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '4%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '4%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>(e)</div>
                                        <div className='align-items-center' style={{ padding: '0px 10px 10px 10px' }}> Commission on issue or sale of shares / redeemable capital/debentures.</div>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '4%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '4%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>(f)</div>
                                        <div className='align-items-center' style={{ padding: '0px 10px 10px 10px' }}>Discount on issue or sale of shares / redeemable capital/debentures.</div>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '4%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '4%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>(g)</div>
                                        <div className='align-items-center' style={{ padding: '0px 10px 10px 10px' }}>Other initial expenses (to be specified).</div>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '4%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '4%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ padding: '0px 10px 10px 10px' }}>Total:-</div>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>5.</div>
                                        <div className='align-items-center' style={{ padding: '0px 10px 10px 10px', fontWeight: "bold" }}> Particulars of the Directors, Chief Executive, Secretary, Auditors and Legal Advisers of the Company and the changes, if any, which have occurred since the date of the incorporation</div>
                                    </div>
                                    <Wrapper className="table-responsive mt-2" >
                                        <table
                                            className="table table-bordered"
                                            style={{ fontSize: "10px", fontFamily: "Montserrat rev=1" }}
                                        >

                                            <tbody>
                                                <tr >
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px' }}>Name</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px' }}>NIC No. for Pakistani, or NICOP No. for overseas Pakistanis or Passport No. in case of a foreigner </td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px' }}>Addresses</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px' }}>Occupation</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px' }}>Particulars of Changes, if any</td>
                                                </tr>
                                                <tr >
                                                    <td colSpan={5} style={{ verticalAlign: 'sub', minHeight: '24px' }}>5.1 Directors</td>
                                                </tr>
                                                <tr >
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px' }}></td>
                                                </tr>
                                                <tr >
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px' }}></td>
                                                </tr>
                                                <tr >
                                                    <td colSpan={5} style={{ verticalAlign: 'sub', minHeight: '24px' }}>5.2 Chief Executive</td>
                                                </tr>
                                                <tr >
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px' }}></td>
                                                </tr>
                                                <tr >
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px' }}></td>
                                                </tr>
                                                <tr >
                                                    <td colSpan={5} style={{ verticalAlign: 'sub', minHeight: '24px' }}>5.3 Secretary</td>
                                                </tr>
                                                <tr >
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px' }}></td>
                                                </tr>
                                                <tr >
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px' }}></td>
                                                </tr>
                                                <tr >
                                                    <td colSpan={5} style={{ verticalAlign: 'sub', minHeight: '24px' }}>5.4 Auditors</td>
                                                </tr>
                                                <tr >
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px' }}></td>
                                                </tr>
                                                <tr >
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px' }}></td>
                                                </tr>
                                                <tr >
                                                    <td colSpan={5} style={{ verticalAlign: 'sub', minHeight: '24px' }}>5.4 Legal Advisors</td>
                                                </tr>
                                                <tr >
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px' }}></td>
                                                </tr>
                                                <tr >
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px' }}></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </Wrapper>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>6.</div>
                                        <div className='align-items-center' style={{ width: '29%', padding: '0px 10px 10px 10px' }}>Particulars of any contract the modification of which is to be submitted to the meeting for its approval together with the particulars of the modification or proposed modification.</div>
                                        <ScrollWrapper className='align-items-center' style={{minHeight:"108px", border: '1px solid #121212', width: '68%', padding: '0px 10px 10px 10px' }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '18%' }}></div>
                                        <div className='align-items-center' style={{ width: '50%', padding: '0px 10px 10px 10px' }}><i>The particulars must include dates of changes</i></div>
                                    </div>
                                    <div className='d-flex'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>7.</div>
                                        <div className='align-items-center' style={{ width: '29%', padding: '0px 10px 10px 10px' }}>The extent to which underwriting contract if any, has been carried out, if contract not carried out fully, extent to which it has not been carried out and reasons therefor</div>
                                        <ScrollWrapper className='align-items-center' style={{minHeight:"81px", border: '1px solid #121212', width: '68%', padding: '0px 10px 10px 10px' }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>8.</div>
                                        <div className='align-items-center' style={{ width: '29%', padding: '0px 10px 10px 10px' }}>The particulars of any commission or brokerage paid or to be paid in connection with the issue or sale of shares to any director, chief executive, secretary or officer or to a private company of which he is director</div>
                                        <ScrollWrapper className='align-items-center' style={{minHeight:"120px", border: '1px solid #121212', width: '68%', padding: '0px 10px 10px 10px' }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>9.</div>
                                        <div className='align-items-center' style={{ width: '29%', padding: '0px 10px 10px 10px' }}>Brief account of the Company’s affairs since its incorporation and the business plan, including any change affecting the interest of shareholders and business prospects of the company</div>
                                        <ScrollWrapper className='align-items-center' style={{minHeight:"103px", border: '1px solid #121212', width: '68%', padding: '0px 10px 10px 10px' }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>10.</div>
                                        <div className='align-items-center' style={{padding: '0px 10px 10px 10px' }}> We hereby certify this Report as correct and that nothing has been concealed or with held in this regard.</div>
                                    </div>
                                    <Wrapper className="table-responsive mt-2" >
                                        <table
                                            className="table table-bordered"
                                            style={{ fontSize: "10px", fontFamily: "Montserrat rev=1" }}
                                        >

                                            <tbody>
                                                <tr >
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px' }}>10.1</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px' }}>Signature </td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px' }}></td>
                                                </tr>
                                                <tr >
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px' }}>10.2</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px' }}>Name of Signatories</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px' }}></td>
                                                </tr>
                                                <tr >
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px' }}>10.3</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px' }}>Designation</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px' }}>Chief Executive Officer</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px' }}>Director</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px' }}>Chief Financial Officer (only in case of listed company)</td>
                                                </tr>
                                                
                                            </tbody>
                                        </table>
                                    </Wrapper>




                                </div>
                            </div>
                        </div>
                    </PDFExport>
                    : <PDFExport
                        paperSize="A4"
                        margin="1cm"
                        scale={0.6}
                        fileName={`Appendix to Form-25`}
                        pageTemplate={PageTemplate}
                        ref={pdfExportComponent}
                    >
                       <div style={{ fontFamily: `${fontFamilyForStaticData}`, fontSize: `${fontSizeForStaticData}`, fontWeight: 500 }}>
                            <FormName>
                                Appendix to Form-25
                            </FormName>
                            <div className='row'>
                                <div className='col-md-12' >
                                    <HeadingWrapper className='d-flex justify-content-center'>
                                        THE COMPANIES ACT, 2017
                                    </HeadingWrapper>

                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <HeadingWrapper className='d-flex justify-content-center'>
                                        THE COMPANIES REGULATIONS, 2024
                                    </HeadingWrapper>

                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <div style={{ fontSize: '15px', fontWeight: 'bold' }} className='d-flex justify-content-center'>
                                        [Section 131(6)]
                                    </div>
                                </div>
                            </div>

                            <div className='row mt-4'>
                                <div className='col-md-12'>
                                    <HeadingWrapper className='text-center d-flex justify-content-center'>
                                        STATUTORY REPORT TO THE MEMBERS
                                    </HeadingWrapper>
                                </div>
                            </div>

                            <div className='row'>
                                <div className='col-md-12' style={{ padding: '25px 50px', wordWrap: 'break-word' }}>

                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>1</div>
                                        <div className='align-items-center' style={{ width: '29%', padding: '0px 10px 10px 10px' }}>Name of the Company  </div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '68%', padding: '0px 10px 10px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>2</div>
                                        <div className='align-items-center' style={{ padding: '0px 10px 10px 10px', fontWeight: "bold" }}> Shares allotted and cash received up to the date of this report</div>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '4%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>2.1</div>
                                        <div className='align-items-center' style={{ width: '29%', padding: '0px 10px 10px 10px' }}>Nominal share capital of the company </div>
                                        <div className='align-items-center' style={{ width: '20%', padding: '0px 10px 10px 10px' }}>No of shares</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '26%', padding: '0px 10px 10px 10px', }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '4%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ width: '29%', padding: '0px 10px 10px 10px' }}></div>
                                        <div className='align-items-center' style={{ width: '20%', padding: '0px 10px 10px 10px' }}>Rs. per share</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '26%', padding: '0px 10px 10px 10px', }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '4%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ width: '29%', padding: '0px 10px 10px 10px' }}></div>
                                        <div className='align-items-center' style={{ width: '20%', padding: '0px 10px 10px 10px' }}>Amount</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '26%', padding: '0px 10px 10px 10px', }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '4%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>2.2</div>
                                        <div className='align-items-center' style={{ width: '29%', padding: '0px 10px 10px 10px' }}>Particulars</div>
                                        <ScrollWrapper className='align-items-center' style={{ minHeight: "60px", border: '1px solid #121212', width: '16%', padding: '0px 10px 10px 10px', marginRight: "1%", }}>Kind/ Class of shares</ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ minHeight: "60px", border: '1px solid #121212', width: '16%', padding: '0px 10px 10px 10px', marginRight: "1%", }}>No of shares</ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ minHeight: "60px", border: '1px solid #121212', width: '16%', padding: '0px 10px 10px 10px', marginRight: "1%", }}>Nominal value of each share</ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ minHeight: "60px", border: '1px solid #121212', width: '16%', padding: '0px 10px 10px 10px', }}>Cash received</ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '4%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>2.3</div>
                                        <div className='align-items-center' style={{ width: '29%', padding: '0px 10px 10px 10px' }}>Allotted subject to payment thereof in cash.</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '16%', padding: '0px 10px 10px 10px', marginRight: "1%", }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '16%', padding: '0px 10px 10px 10px', marginRight: "1%", }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '16%', padding: '0px 10px 10px 10px', marginRight: "1%", }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '16%', padding: '0px 10px 10px 10px', }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '4%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>2.4</div>
                                        <div className='align-items-center' style={{ width: '29%', padding: '0px 10px 10px 10px' }}>Allotted as fully paid up otherwise than in cash and the consideration for which the shares have been so allotted being: (to be specified)</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '16%', padding: '0px 10px 10px 10px', marginRight: "1%", }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '16%', padding: '0px 10px 10px 10px', marginRight: "1%", }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '16%', padding: '0px 10px 10px 10px', marginRight: "1%", }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '16%', padding: '0px 10px 10px 10px', }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '4%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>2.5</div>
                                        <div className='align-items-center' style={{ width: '35%', padding: '0px 10px 10px 10px' }}>Allotted at a discount per share of Rs.</div>
                                        <ScrollWrapper className='align-items-center' style={{ width: '14%', padding: '0px 10px 10px 10px', marginRight: "1%", }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ width: '14%', padding: '0px 10px 10px 10px', marginRight: "1%", }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ width: '14%', padding: '0px 10px 10px 10px', marginRight: "1%", }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '14%', padding: '0px 10px 10px 10px', }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '4%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>2.6</div>
                                        <div className='align-items-center' style={{ width: '35%', padding: '0px 10px 10px 10px' }}>Allotted at a premium per share of Rs.</div>
                                        <ScrollWrapper className='align-items-center' style={{ width: '14%', padding: '0px 10px 10px 10px', marginRight: "1%", }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ width: '14%', padding: '0px 10px 10px 10px', marginRight: "1%", }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ width: '14%', padding: '0px 10px 10px 10px', marginRight: "1%", }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '14%', padding: '0px 10px 10px 10px', }}></ScrollWrapper>
                                    </div>

                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>3</div>
                                        <div className='align-items-center' style={{ padding: '0px 10px 10px 10px', fontWeight: "bold" }}> Abstract of receipts and payments of the company made upto a date within 15 days of the date of this report:</div>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '4%' }}></div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '30%', padding: '0px 10px 10px 10px', marginRight: "1%", textAlign: "center" }}>Receipt</ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '17%', padding: '0px 10px 10px 10px', marginRight: "1%", textAlign: "center" }}>Rs</ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '30%', padding: '0px 10px 10px 10px', marginRight: "1%", textAlign: "center" }}>Payments</ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '16%', padding: '0px 10px 10px 10px', }}>Rs</ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '4%' }}></div>
                                        <ScrollWrapper className='align-items-center' style={{ minHeight: "625px", border: '1px solid #121212', width: '30%', padding: '0px 10px 10px 10px', marginRight: "1%", textAlign: "center" }}>
                                            <div className='text-left'>
                                                <div className='mb-2'>Shares:</div>
                                                <div className='mb-5'>Advance payment for shares / Redeemable Capital / debentures</div>
                                                <div className='mb-2'>Loans:</div>
                                                <div className='mb-5'>Deposits</div>
                                                <div>Other Sources (to be specified):</div>
                                            </div>
                                        </ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ minHeight: "625px", border: '1px solid #121212', width: '17%', padding: '0px 10px 10px 10px', marginRight: "1%", textAlign: "center" }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ minHeight: "625px", border: '1px solid #121212', width: '30%', padding: '0px 10px 10px 10px', marginRight: "1%", textAlign: "center" }}>
                                            <div className='text-left'>
                                                <div className='mb-2'>Preliminary expenses</div>
                                                <div className='mb-2'>Commission on issue or sale of shares / redeemable Capital / debentures</div>
                                                <div className='mb-2'>Discount on issue or sale of shares / redeemable Capital / debentures</div>
                                                <div className='mb-2'>Capital expenditure:</div>
                                                <div className='mb-2'>Land.............</div>
                                                <div className='mb-2'>Building.............</div>
                                                <div className='mb-2'>Plant.............</div>
                                                <div className='mb-2'>Machinery.............</div>
                                                <div className='mb-2'>Other Items (to be specified)</div>
                                                <div className='mb-2'>Balances.............</div>
                                                <div className='mb-2'>In hand.............</div>
                                                <div>At Banks.............</div>
                                            </div>
                                        </ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ minHeight: "625px", border: '1px solid #121212', width: '16%', padding: '0px 10px 10px 10px', }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '4%' }}></div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '30%', padding: '0px 10px 10px 10px', marginRight: "1%", textAlign: "center" }}>Total:</ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '17%', padding: '0px 10px 10px 10px', marginRight: "1%", textAlign: "center" }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '30%', padding: '0px 10px 10px 10px', marginRight: "1%", textAlign: "center" }}>Total:</ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '16%', padding: '0px 10px 10px 10px', }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>4.</div>
                                        <div className='align-items-center' style={{ padding: '0px 10px 10px 10px', fontWeight: "bold" }}>Preliminary expenses—</div>
                                    </div>

                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '4%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>4.1</div>
                                        <div className='align-items-center' style={{ width: '29%', padding: '0px 10px 10px 10px' }}> Estimated in the Prospectus/Statement in lieu of Prospectus </div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '68%', padding: '0px 10px 10px 10px', }}>(Rs.):-</ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '4%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>4.2</div>
                                        <div className='align-items-center' style={{ padding: '0px 10px 10px 10px' }}>Preliminary expenses actually incurred up to the aforesaid date:-</div>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '4%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '4%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>(a)</div>
                                        <div className='align-items-center' style={{ padding: '0px 10px 10px 10px' }}>Legal & professional Charges</div>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '4%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '4%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>(b)</div>
                                        <div className='align-items-center' style={{ padding: '0px 10px 10px 10px' }}>Printing expenses of Memorandum and Articles</div>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '4%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '4%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>(c)</div>
                                        <div className='align-items-center' style={{ padding: '0px 10px 10px 10px' }}> Stamp and Registration fees</div>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '4%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '4%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>(d)</div>
                                        <div className='align-items-center' style={{ padding: '0px 10px 10px 10px' }}>Advertisement</div>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '4%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '4%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>(e)</div>
                                        <div className='align-items-center' style={{ padding: '0px 10px 10px 10px' }}> Commission on issue or sale of shares / redeemable capital/debentures.</div>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '4%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '4%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>(f)</div>
                                        <div className='align-items-center' style={{ padding: '0px 10px 10px 10px' }}>Discount on issue or sale of shares / redeemable capital/debentures.</div>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '4%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '4%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>(g)</div>
                                        <div className='align-items-center' style={{ padding: '0px 10px 10px 10px' }}>Other initial expenses (to be specified).</div>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '4%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '4%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ padding: '0px 10px 10px 10px' }}>Total:-</div>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>5.</div>
                                        <div className='align-items-center' style={{ padding: '0px 10px 10px 10px', fontWeight: "bold" }}> Particulars of the Directors, Chief Executive, Secretary, Auditors and Legal Advisers of the Company and the changes, if any, which have occurred since the date of the incorporation</div>
                                    </div>
                                    <Wrapper className="table-responsive mt-2" >
                                        <table
                                            className="table table-bordered"
                                            style={{ fontSize: "10px", fontFamily: "Montserrat rev=1" }}
                                        >

                                            <tbody>
                                                <tr >
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px' }}>Name</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px' }}>NIC No. for Pakistani, or NICOP No. for overseas Pakistanis or Passport No. in case of a foreigner </td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px' }}>Addresses</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px' }}>Occupation</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px' }}>Particulars of Changes, if any</td>
                                                </tr>
                                                <tr >
                                                    <td colSpan={5} style={{ verticalAlign: 'sub', minHeight: '24px' }}>5.1 Directors</td>
                                                </tr>
                                                <tr >
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px' }}></td>
                                                </tr>
                                                <tr >
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px' }}></td>
                                                </tr>
                                                <tr >
                                                    <td colSpan={5} style={{ verticalAlign: 'sub', minHeight: '24px' }}>5.2 Chief Executive</td>
                                                </tr>
                                                <tr >
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px' }}></td>
                                                </tr>
                                                <tr >
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px' }}></td>
                                                </tr>
                                                <tr >
                                                    <td colSpan={5} style={{ verticalAlign: 'sub', minHeight: '24px' }}>5.3 Secretary</td>
                                                </tr>
                                                <tr >
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px' }}></td>
                                                </tr>
                                                <tr >
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px' }}></td>
                                                </tr>
                                                <tr >
                                                    <td colSpan={5} style={{ verticalAlign: 'sub', minHeight: '24px' }}>5.4 Auditors</td>
                                                </tr>
                                                <tr >
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px' }}></td>
                                                </tr>
                                                <tr >
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px' }}></td>
                                                </tr>
                                                <tr >
                                                    <td colSpan={5} style={{ verticalAlign: 'sub', minHeight: '24px' }}>5.4 Legal Advisors</td>
                                                </tr>
                                                <tr >
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px' }}></td>
                                                </tr>
                                                <tr >
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px' }}></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </Wrapper>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>6.</div>
                                        <div className='align-items-center' style={{ width: '29%', padding: '0px 10px 10px 10px' }}>Particulars of any contract the modification of which is to be submitted to the meeting for its approval together with the particulars of the modification or proposed modification.</div>
                                        <ScrollWrapper className='align-items-center' style={{minHeight:"108px", border: '1px solid #121212', width: '68%', padding: '0px 10px 10px 10px' }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '18%' }}></div>
                                        <div className='align-items-center' style={{ width: '50%', padding: '0px 10px 10px 10px' }}><i>The particulars must include dates of changes</i></div>
                                    </div>
                                    <div className='d-flex'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>7.</div>
                                        <div className='align-items-center' style={{ width: '29%', padding: '0px 10px 10px 10px' }}>The extent to which underwriting contract if any, has been carried out, if contract not carried out fully, extent to which it has not been carried out and reasons therefor</div>
                                        <ScrollWrapper className='align-items-center' style={{minHeight:"81px", border: '1px solid #121212', width: '68%', padding: '0px 10px 10px 10px' }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>8.</div>
                                        <div className='align-items-center' style={{ width: '29%', padding: '0px 10px 10px 10px' }}>The particulars of any commission or brokerage paid or to be paid in connection with the issue or sale of shares to any director, chief executive, secretary or officer or to a private company of which he is director</div>
                                        <ScrollWrapper className='align-items-center' style={{minHeight:"120px", border: '1px solid #121212', width: '68%', padding: '0px 10px 10px 10px' }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>9.</div>
                                        <div className='align-items-center' style={{ width: '29%', padding: '0px 10px 10px 10px' }}>Brief account of the Company’s affairs since its incorporation and the business plan, including any change affecting the interest of shareholders and business prospects of the company</div>
                                        <ScrollWrapper className='align-items-center' style={{minHeight:"103px", border: '1px solid #121212', width: '68%', padding: '0px 10px 10px 10px' }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>10.</div>
                                        <div className='align-items-center' style={{padding: '0px 10px 10px 10px' }}> We hereby certify this Report as correct and that nothing has been concealed or with held in this regard.</div>
                                    </div>
                                    <Wrapper className="table-responsive mt-2" >
                                        <table
                                            className="table table-bordered"
                                            style={{ fontSize: "10px", fontFamily: "Montserrat rev=1" }}
                                        >

                                            <tbody>
                                                <tr >
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px' }}>10.1</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px' }}>Signature </td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px' }}></td>
                                                </tr>
                                                <tr >
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px' }}>10.2</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px' }}>Name of Signatories</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px' }}></td>
                                                </tr>
                                                <tr >
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px' }}>10.3</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px' }}>Designation</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px' }}>Chief Executive Officer</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px' }}>Director</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px' }}>Chief Financial Officer (only in case of listed company)</td>
                                                </tr>
                                                
                                            </tbody>
                                        </table>
                                    </Wrapper>




                                </div>
                            </div>
                        </div>
                    </PDFExport>}
            </div>
            <button disabled={loading} className='btn btn-danger' onClick={(e) => {
                // if(viewPdf)
                //   SetViewPDf(true)
                if (pdfExportComponent.current) {
                    pdfExportComponent.current.save();
                }
            }}>Download PDF</button>
        </div>
    )
}
const PartsWrapper = styled.div`
color: #121212;
text-decoration: underline;
justify-content: center;
display: flex;
margin-right: 15px;
font-size: 17px;
font-weight: bold;
`;
const ScrollWrapper = styled.div`
max-height: 50px;
overflow-y: scroll;
overflow-x: hidden;
::-webkit-scrollbar{
    height: 5px;
    width: 3px;
  }
  
  ::-webkit-scrollbar-track{
    background: #F9F9FB;
  }
  ::-webkit-scrollbar-thumb{
    background: #4E515680;
    border-radius: 5px;
  
  }
`;
const HeadingWrapper = styled.div`
font-size: 17px;
font-weight: bold;
`;
const FormName = styled.div`
color: #121212;
text-decoration: underline;
justify-content: end;
display: flex;
margin-right: 15px;
font-size: 17px;
font-weight: bold;
`;
const Wrapper = styled.div`
table.table-bordered{
    border: 1px solid #121212
     border-color: #121212; 
    // border:1px solid blue;
    // margin-top:20px;
  }
// table.table-bordered > thead > tr > th{
//     // border:1px solid blue;
//     border-color: #121212; 
// }
// table.table-bordered > tbody > tr > td{
//     // border:1px solid blue;
//     borderC-color: #121212; 
// }
 tr td {
    // border:1px solid blue;.
    border-color: #121212; 
}

`;
const WrapperDashed = styled.div`

  td {
    
    // border: 1px dashed #dddcdc;
}

`;