import React, { useState, Fragment, useEffect, useCallback, useRef } from "react";
import { MENUITEMS } from "../../../components/common/sidebar-component/menu";
import { Link } from "react-router-dom";
import { Search } from "react-feather";
import { useSelector } from "react-redux";
import styled from "styled-components";

const SearchHeader = () => {
  const menuFeature = JSON.parse(sessionStorage.getItem("features" || ""))[0]
    .children;
  const [mainmenu, setMainMenu] = useState(MENUITEMS);
  const [IssuerFeature, setIssuerFeature] = useState();
  const [searchValue, setsearchValue] = useState("");
  const [searchOpen, setsearchOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState("");
  const mixLayout = useSelector((state) => state?.Customizer?.mix_layout);

  useEffect(() => {
    let activeMenu = [...MENUITEMS];

    activeMenu.map((item, index) => {
      if (item?.title != "Dashboard") {
        let allChild = [];

        for (let i = 0; i < menuFeature?.length; i++) {
          let children = item?.children?.filter((ch) => {
            return menuFeature[i]?.children?.some((ch2) => {
              return ch?.title == "Shareholding" && "Shareholders" == ch2?.label
                ? true
                : ch?.title == "Categories of Holders" &&
                  "Category of Shareholding" == ch2?.label
                  ? true
                  : ch?.title == "List of Shareholding" &&
                    ch2?.label == "List of Shareholders"
                    ? true
                    : ch?.title == "List of Shareholding" &&
                      ch2?.label == "List of Shareholders"
                      ? true
                      : // : ch.title == "Comany Data" && ch2.label == "Companies"
                      ch?.title == "Company Info" && ch2?.label == "Companies"
                        ? true // added company data
                        : ch2?.label.toLowerCase() ==
                        ch?.title?.replace(/s$/, "")?.toLowerCase() ||
                        ch2?.label?.toLowerCase() == ch?.title?.toLowerCase() ||
                        (item?.title != "Shareholdings" &&
                          item?.title != "Governance" &&
                          ch2?.label
                            ?.toLowerCase()
                            ?.includes(ch?.title?.replace(/s$/, "")?.toLowerCase()));
            });
          });

          if (children.length > 0) {
            allChild = allChild?.concat(children);
          }
        }
        activeMenu[index].children = allChild;
      }
    });
    activeMenu = activeMenu.filter(
      (item) => item.children?.length > 0 || item.title == "Dashboard"
    );
    activeMenu = activeMenu.map((item) => {
      if (item.title === "Governance") {
        item.children = item.children.map((ch) => {
          if (ch.title === "Dashboard Governance") {
            ch.title = "Dashboard";
          }
          return ch;
        });
      }
      if (item.title === "Corporate Actions") {
        let temp = item.children.shift();
        item.children.push(temp);
      }
      if (item.title === "Transactions") {
        let temp = item.children.pop();
        item.children.unshift(temp);
      }
      return item;
    });
    setMainMenu([...activeMenu]);
  }, [])


  const escFunction = useCallback((event) => {
    if (event.keyCode === 27) {
      //Do whatever when esc is pressed
      setsearchOpen(false);
      setsearchValue("");
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  });

  const handleSearchKeyword = (keyword) => {
    keyword ? addFix() : removeFix();
    const items = [];
    setsearchValue(keyword);
    mainmenu.filter((menuItems) => {
      if (
        menuItems.title.toLowerCase().includes(keyword) &&
        (menuItems?.children?.length === 0 || !menuItems?.children)
      ) {
        items.push(menuItems);
      }
      if (!menuItems.children) return false;
      menuItems.children.filter((subItems) => {
        if (
          subItems.title.toLowerCase().includes(keyword) &&
          (subItems.children?.length === 0 || !subItems.children)
        ) {
          subItems.icon = menuItems.classname;
          items.push(subItems);
        }
        if (!subItems.children) return false;
        subItems.children.filter((suSubItems) => {
          if (suSubItems.title.toLowerCase().includes(keyword)) {
            suSubItems.icon = menuItems.classname;
            items.push(suSubItems);
          }
        });
      });
      console.log("itemm---", items)
      checkSearchResultEmpty(items);
      setsearchValue(items);
    });
  };

  const checkSearchResultEmpty = (items) => {
    if (!items.length) {
      document.querySelector(".empty-menu").classList.add("is-open");
    } else {
      document.querySelector(".empty-menu").classList.remove("is-open");
    }
  };

  const addFix = () => {
    document.querySelector(".Typeahead-menu").classList.add("is-open");
    // document.body.classList.add("offcanvas");
  };

  const removeFix = () => {
    setsearchValue("");
    document.querySelector(".Typeahead-menu").classList.remove("is-open");
    // document.body.classList.remove("offcanvas");
  };

  const toggleBtn = () => {
    if (searchOpen) {
      setsearchOpen(!searchOpen);
      document.querySelector(".searchIcon").classList.add("open");
    } else {
      setsearchOpen(!searchOpen);
      document.querySelector(".searchIcon").classList.remove("open");
    }
  };

  const [screen, setScreen] = useState();
  useEffect(() => {
    const handleResize = () => {
      setScreen(window?.outerWidth);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const dropdownRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        inputRef.current &&
        !inputRef.current.contains(event.target)
      ) {
        document.querySelector(".Typeahead-menu").classList.remove("is-open");
        document.querySelector(".empty-menu").classList.remove("is-open");
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const dividerStyle =
    mixLayout == "light-only" || mixLayout == "dark-body-only"
      ? "#eff0f1"
      : "#374558";
  const searchStyle =
    mixLayout == "light-only" || mixLayout == "dark-body-only"
      ? "#f6f7fb"
      : "#293240";
  const searchStyleBorder =
    mixLayout == "light-only" || mixLayout == "dark-body-only"
      ? ""
      : "#374558";
  const searchStyleText =
    mixLayout == "light-only" || mixLayout == "dark-body-only"
      ? ""
      : "hsla(0,0%,100%,.85)";
  const aciveClassIs =
    mixLayout == "light-only" || mixLayout == "dark-body-only"
      ? ""
      : "MenuStyleDark";
  const searchResultBorder =
    mixLayout == "light-only" || mixLayout == "dark-body-only"
      ? "1px solid Black"
      : "2px solid hsla(0,0%,100%,.85)";

  return (
    <Fragment>
      <div className={screen <= 500 || localStorage.getItem("wrapper") === "horizontal_sidebar" ? "col-12 px-0 mt-3" : "col-12 px-0"}>
        <form className="col-12 form-inline search-form">
          <div style={{ width: "inherit" }} className="position-relative">
            <input
              ref={inputRef}
              style={{
                width: localStorage.getItem("wrapper") === "horizontal_sidebar" ? "99%" : "inherit",
                backgroundColor: searchStyle,
                borderColor: searchStyleBorder,
                color: searchStyleText,
                padding: "10px 10px 10px 54px"
              }}
              className="form-control-plaintext searchIcon"
              type="text"
              placeholder="search"
              // defaultValue={searchValue}
              value={selectedItem}
              // onBlur={() => {
              //   document.querySelector(".Typeahead-menu").classList.remove("is-open");
              //   document.querySelector(".empty-menu").classList.remove("is-open");
              // }}
              onClick={() => {
                if (selectedItem && !searchValue?.length) {
                  document.querySelector(".empty-menu").classList.add("is-open");
                }
                selectedItem && addFix()
              }}
              onChange={(e) => {
                handleSearchKeyword(e.target.value?.toLowerCase());
                setSelectedItem(e.target.value);
              }}
            />
            <div style={{ top: "12px", left: "12px" }} className="position-absolute">
              <Search width={18} height={18} color="#8e8e8e" />
              <hr style={{
                position: "absolute",
                top: "-15px",
                left: "30px",
                border: "none",
                height: "20px",
                borderRight: "1px solid",
                borderColor: dividerStyle
              }}></hr>
            </div>
            {/* <span className="d-sm-none mobile-search" onClick={toggleBtn}>
              <Search />
            </span> */}

            {searchValue
              ? <div
                ref={dropdownRef}
                style={{
                  border: searchResultBorder,
                  width: "98%", top: screen <= 575 ? "115%" : "",
                  backgroundColor: searchStyle, color: searchStyleText
                }}
                className="Typeahead-menu custom-scrollbar" id="search-outer">
                {searchValue.map((data, index) => {
                  return (
                    <SearchMenuStyle>
                      <div id={aciveClassIs} className="ProfileCard u-cf" key={index}>
                        <div className="ProfileCard-avatar">
                          {/* <data.icon /> */}
                          <i className={data?.classname ? data?.classname : data?.icon}></i>
                        </div>
                        <div className="ProfileCard-details">
                          <div className="ProfileCard-realName">
                            <Link
                              style={{ color: searchStyleText }}
                              to={`${process.env.PUBLIC_URL}${data?.path}`}
                              className="realname"
                              onClick={(e) => {
                                handleSearchKeyword(data?.title?.toLowerCase());
                                setSelectedItem(data?.title);
                                document.querySelector(".Typeahead-menu").classList.remove("is-open");
                              }}
                            >
                              {data?.title}
                            </Link>
                          </div>
                        </div>
                      </div>
                    </SearchMenuStyle>
                  );
                })}

              </div>
              : ""}
            <div style={{ border: searchResultBorder, backgroundColor: searchStyle, color: searchStyleText }} className="Typeahead-menu empty-menu">
              <div className="tt-dataset tt-dataset-0">
                <div className="EmptyMessage">
                  {"No result found."}
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </Fragment>
  );
};

export default SearchHeader;
const SearchMenuStyle = styled.div`
#MenuStyleDark:hover{
background-color: #374558;
color:hsla(0,0%,100%,.85)
}
`