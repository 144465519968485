import React, { useState, useEffect } from 'react'
import { PDFExport } from "@progress/kendo-react-pdf";
import PageTemplate from "./page-template";
import {
    getCompanies,
} from "../../../store/services/company.service";
import styled from 'styled-components';
import { TiTick } from "react-icons/ti";
import { numberWithCommas } from 'utilities/utilityFunctions';
export const StatutoryForm22 = ({ data, formTemplate = false }) => {
    const pdfExportComponent = React.useRef(null);
    const [CompanyData, setCompanyData] = useState(null)
    const [loading, setLoading] = useState(false);
    const [fontFamilyForStaticData, setFontFamilyForStaticData] = useState('Montserrat, sans-serif')
    const [fontSizeForStaticData, setFontSizeForStaticData] = useState('14px')
    const [fontfamilyforDynimicData, setFontFamilyForDynmicData] = useState('Segoe Print')
    const [fontColor, setFontColor] = useState('')
    const baseEmail = sessionStorage.getItem("email") || "";
    const [governance, setGovernance] = useState({})
    const getAllCompanies = async () => {
        setLoading(true)
        try {
            const response = await getCompanies(baseEmail);
            if (response.status === 200) {
                response.data.data = response.data.data.filter((item) => item?.active === 'Y')
                const parents = response.data.data;
                const company = parents?.find(ite => ite?.code == data?.company_code)
                let Allperson = company.governance && company.governance != "" ? JSON.parse(company.governance) : []
                let sec = Allperson.find(x => x.role == "Company Secretary" && x?.active?.toLowerCase() != 'n')
                let ceo = Allperson.find(x => x.role == "CEO" && x?.active?.toLowerCase() != 'n')


                if (sec) {
                    setGovernance(sec)
                } else if (ceo) {
                    setGovernance(ceo)
                }
                setCompanyData(company)
                setTimeout(() => {
                    setLoading(false)
                }, 1000);
            }
        } catch (error) {
            setLoading(false)
        }
    };
    useEffect(() => {
        getAllCompanies()
    }, [])
    return (
        <div>
            <div
                style={{ fontFamily: "sans-serif " }}
            >

                {formTemplate ?
                    <PDFExport
                        paperSize="A4"
                        margin="1cm"
                        scale={0.6}
                        fileName={`Form 22`}
                        pageTemplate={PageTemplate}
                        ref={pdfExportComponent}
                    >
                        <div style={{ fontFamily: `${fontFamilyForStaticData}`, fontSize: `${fontSizeForStaticData}`, fontWeight: 500 }}>
                            <FormName>
                                Form-22
                            </FormName>
                            <div className='row'>
                                <div className='col-md-12' >
                                    <HeadingWrapper className='d-flex justify-content-center'>
                                        THE COMPANIES ACT, 2017
                                    </HeadingWrapper>

                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <HeadingWrapper className='d-flex justify-content-center'>
                                        THE COMPANIES REGULATIONS, 2024
                                    </HeadingWrapper>

                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <div style={{ fontSize: '15px', fontWeight: 'bold' }} className='d-flex justify-content-center'>
                                        [Section 19 & Regulation 30]
                                    </div>
                                </div>
                            </div>

                            <div className='row mt-4'>
                                <div className='col-md-12'>
                                    <HeadingWrapper className='text-center d-flex justify-content-center'>
                                    DECLARATION BY A PUBLIC COMPANY BEFORE COMMENCING BUSINESS
                                    </HeadingWrapper>
                                </div>
                            </div>
                            <PartsWrapper className='mt-4 mb-3'>
                                PART-I
                            </PartsWrapper>
                            <div className='row'>
                                <div className='col-md-12' style={{ padding: '25px 50px', wordWrap: 'break-word' }}>
                                    <div> <i>(Please complete in bold capital letters)</i></div>


                                    <div className='d-flex' >
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>1.1</div>
                                        <div className='align-items-center' style={{ width: '28%', padding: '0px 10px 10px 10px' }}>CUIN (Registration Number)  </div>
                                        <div className='align-items-center  d-flex'  >{
                                            '1234 552'.split("")?.map((item, idx) => {
                                                if (item == ' ') return
                                                return <div style={{ minWidth: '30px', minHeight: '34px', border: '1px solid #121212', borderLeft: idx != 0 ? "none" : "1px solid #121212", padding: '6px 9px' }}></div>
                                            })
                                        }
                                        </div>
                                    </div>



                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>1.2</div>
                                        <div className='align-items-center' style={{ width: '29%', padding: '0px 10px 10px 10px' }}>Name of the Company  </div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '68%', padding: '0px 10px 10px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                    </div>




                                    <div className='d-flex  mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>1.3</div>
                                        <div className='align-items-center' style={{ width: '20.20%', padding: '0px 10px 10px 10px' }}>Fee payment Details*</div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '5%' }}>1.3.1</div>
                                        <div className='align-items-center' style={{ width: '13.20%', paddingLeft: '20px' }}>Challan No.</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '17.20%', paddingLeft: '20px', minHeight: '34px' }}></ScrollWrapper>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '5%' }}>1.3.2</div>
                                        <div className='align-items-center' style={{ width: '13.20%', paddingLeft: '20px' }}>Amount</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '23.20%', paddingLeft: '20px', minHeight: '34px' }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '85%', borderBottom: "none", paddingLeft: '20px', minHeight: '70px', fontWeight: "bold" }}>1.4 Declaration before commencing business —</ScrollWrapper>
                                        <ScrollWrapper className='align-items-center text-center' style={{ border: '1px solid #121212', borderLeft: "none", borderBottom: "none", width: '15%', padding: '10px', minHeight: '70px' }}>Please tick the relevant box</ScrollWrapper>
                                    </div>
                                    <div className='d-flex'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '21%', paddingLeft: '20px', borderBottom: "none", minHeight: '34px', fontWeight: "bold" }}>Part-II</ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '64%', borderLeft: "none", borderBottom: "none", paddingLeft: '20px', minHeight: '34px' }}>In case of a company issuing a prospectus</ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '15%', borderLeft: "none", borderBottom: "none", paddingLeft: '20px', minHeight: '34px' }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '21%', paddingLeft: '20px', minHeight: '34px', fontWeight: "bold" }}>Part-III</ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '64%', borderLeft: "none", paddingLeft: '20px', minHeight: '34px' }}>In case of a company filing statement in lieu of prospectus</ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '15%', borderLeft: "none", paddingLeft: '20px', minHeight: '34px' }}></ScrollWrapper>
                                    </div>
                                    
                    

                                    <PartsWrapper className='mt-4 mb-3'>
                                        PART-II
                                    </PartsWrapper>
                                    <div className='row mt-4'>
                                        <div className='col-md-12'>
                                            <HeadingWrapper className='d-flex text-center justify-content-center'>
                                            (DECLARATION BY A COMPANY IN CASE OF ISSUING A PROSPECTUS)
                                            </HeadingWrapper>
                                        </div>
                                    </div>

                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>2.1</div>
                                        <div className='align-items-center' style={{ width: '26%', padding: '0px 10px 10px 10px',}}>Name(s) of declarant(s)</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '71%', padding: '0px 10px 10px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>2.2</div>
                                        <div className='align-items-center' style={{  padding: '0px 10px 10px 10px',}}> I/We, the above-named person(s), do hereby solemnly and sincerely declare as follows:</div>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '4%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>2.2.1</div>
                                        <div className='align-items-center' style={{ width: '39%', padding: '0px 10px 10px 10px'}}> That the amount of the share capital of the company offered to the public for subscription is</div>
                                        <div className='align-items-center' style={{ width: '18%', padding: '0px 10px 10px 10px'}}></div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '36%', padding: '0px 10px 10px 10px',  }}>Rs.</ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '4%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>2.2.2</div>
                                        <div className='align-items-center' style={{ width: '39%', padding: '0px 10px 10px 10px'}}> That the amount stated in the prospectus as the minimum amount which, in the opinion of the Directors, must be raised by the issue of share capital in order to provide for the matters given in the prospectus, is</div>
                                        <div className='align-items-center' style={{ width: '18%', padding: '0px 10px 10px 10px'}}></div>
                                        <ScrollWrapper className='align-items-center' style={{minHeight:"89px", border: '1px solid #121212', width: '36%', padding: '0px 10px 10px 10px', }}>Rs.</ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '4%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>2.2.3</div>
                                        <div className='align-items-center' style={{ width: '39%', padding: '0px 10px 10px 10px'}}> That shares held subject to the payment of the whole amount thereof in cash have been allotted to the amount of and the money has been received by the company </div>
                                        <div className='align-items-center' style={{ width: '18%', padding: '0px 10px 10px 10px'}}></div>
                                        <ScrollWrapper className='align-items-center' style={{minHeight:"70px", border: '1px solid #121212', width: '36%', padding: '0px 10px 10px 10px', }}>Rs.</ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '4%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>2.2.4</div>
                                        <div className='align-items-center' style={{ width: '39%', padding: '0px 10px 10px 10px'}}> That every Director of the Company has paid to the Company full amount on each of the shares taken or contracted to be taken by him and for which he is liable to pay in cash.</div>
                                        <div className='align-items-center' style={{ width: '18%', padding: '0px 10px 10px 10px'}}></div>
                                        <ScrollWrapper className='align-items-center' style={{minHeight:"90px", border: '1px solid #121212', width: '36%', padding: '0px 10px 10px 10px', }}>Rs.</ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '4%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>2.2.5</div>
                                        <div className='align-items-center' style={{ width: '39%', padding: '0px 10px 10px 10px'}}> That no money is, or may become, liable to be repaid to the applicants for any shares or participation term certificate/terms finance certificate/debentures which have been offered for public subscription by reason of any short subscription, failure to apply for, or to obtain permission for the shares or participation term certificates/term finance certificates/debentures to be dealt on any stock exchange.</div>
                                        <div className='align-items-center' style={{ width: '18%', padding: '0px 10px 10px 10px'}}></div>
                                        <ScrollWrapper className='align-items-center' style={{minHeight:"157px", border: '1px solid #121212', width: '36%', padding: '0px 10px 10px 10px', }}>Rs.</ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        {/* <div className='align-items-center' style={{ paddingLeft: '2px', width: '1%' }}></div> */}
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>2.3</div>
                                        <div className='align-items-center' style={{padding: '0px 10px 10px 10px'}}>Verified that the foregoing statements are true to the best of my/our knowledge and belief and that nothing has been concealed.</div>
                                    </div>

                                    <PartsWrapper className='mt-4 mb-3'>
                                        PART-III
                                    </PartsWrapper>
                                    <div className='row mt-4'>
                                        <div className='col-md-12'>
                                            <HeadingWrapper className='d-flex text-center justify-content-center'>
                                            (DECLARATION BY A COMPANY IN CASE OF FILING OF STATEMENT IN LIEU OF PROSPECTUS)
                                            </HeadingWrapper>
                                        </div>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        {/* <div className='align-items-center' style={{ paddingLeft: '2px', width: '1%' }}></div> */}
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>3.1</div>
                                        <div className='align-items-center' style={{width:"34%",padding: '0px 10px 10px 10px'}}>Name(s) of declarant(s)</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '32%', padding: '0px 10px 10px 10px', }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212',borderLeft:"none", width: '32%', padding: '0px 10px 10px 10px', }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        {/* <div className='align-items-center' style={{ paddingLeft: '2px', width: '1%' }}></div> */}
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>3.2</div>
                                        <div className='align-items-center' style={{padding: '0px 10px 10px 10px'}}>I/We, the above-named person(s), do hereby solemnly and sincerely declare as follows:</div>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '4%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>3.2.1</div>
                                        <div className='align-items-center' style={{ width: '39%', padding: '0px 10px 10px 10px'}}>That the amount of the share capital of the company subject to the payment of the whole amount thereof in cash is</div>
                                        <div className='align-items-center' style={{ width: '20%', padding: '0px 10px 10px 10px'}}></div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '34%', padding: '0px 10px 10px 10px', }}>Rs.</ScrollWrapper>
                                    </div>

                                    <div className='d-flex '>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '4%', }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%', }}>3.2.2</div>
                                        <div className='align-items-center' style={{ width: '30%', padding: ' 0px 10px 10px 10px', }}> That the company has not issued a prospectus inviting the public to subscribe for its shares, and that it has filed with the Registrar a statement in lieu of prospectus dated</div>
                                        <div className='align-items-center' style={{ width: '21%', padding: ' 0px 10px 10px 10px', }}></div>
                                        <div className='d-flex flex-column' style={{ width: '10%',}}>
                                            <div className='align-items-center text-center' style={{ width: '100%', }}>Day</div>
                                            <div className='d-flex'>
                                                <div className='align-items-center' style={{ width: '50%',minHeight:"25px", padding: ' 5px 10px', border: '1px solid #121212' }}> </div>
                                                <div className='align-items-center' style={{ width: '50%',minHeight:"25px", padding: ' 5px 10px', border: '1px solid #121212', borderLeft: 'none' }}> </div>
                                            </div>
                                        </div>
                                        <div className='align-items-center text-center' style={{ paddingLeft: '2px', width: '3%', }}></div>
                                        <div className='d-flex flex-column' style={{ width: '10%',}}>
                                            <div className='align-items-center text-center' style={{ width: '100%', }}>Month</div>
                                            <div className='d-flex'>
                                                <div className='align-items-center' style={{ width: '50%',minHeight:"25px", padding: ' 5px 10px', border: '1px solid #121212' }}> </div>
                                                <div className='align-items-center' style={{ width: '50%',minHeight:"25px", padding: ' 5px 10px', border: '1px solid #121212', borderLeft: 'none' }}> </div>
                                            </div>
                                        </div>
                                        <div className='align-items-center text-center' style={{ paddingLeft: '2px', width: '3%', }}></div>
                                        <div className='d-flex flex-column' style={{ width: '16%',}}>
                                            <div className='align-items-center text-center' style={{ width: '100%', }}>Year</div>
                                            <div className='d-flex'>
                                                <div className='align-items-center' style={{ width: '50%',minHeight:"25px", padding: ' 5px 10px', border: '1px solid #121212' }}> </div>
                                                <div className='align-items-center' style={{ width: '50%',minHeight:"25px", padding: ' 5px 10px', border: '1px solid #121212', borderLeft: 'none' }}> </div>
                                                <div className='align-items-center' style={{ width: '50%',minHeight:"25px", padding: ' 5px 10px', border: '1px solid #121212', borderLeft: 'none' }}> </div>
                                                <div className='align-items-center' style={{ width: '50%',minHeight:"25px", padding: ' 5px 10px', border: '1px solid #121212', borderLeft: 'none' }}> </div>
                                            </div>
                                        </div>
                                      
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '4%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>3.2.3</div>
                                        <div className='align-items-center' style={{ width: '39%', padding: '0px 10px 10px 10px'}}> That the amount fixed by the Memorandum or Articles and specified in the statement in lieu of prospectus as the minimum subscription upon which the directors may proceed to allotment is</div>
                                        <div className='align-items-center' style={{ width: '20%', padding: '0px 10px 10px 10px'}}></div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '34%', padding: '0px 10px 10px 10px', }}>Rs.</ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '4%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>3.2.4</div>
                                        <div className='align-items-center' style={{ width: '39%', padding: '0px 10px 10px 10px'}}> That shares held subject to the payment of the whole amount thereof in cash have been allotted to the amount of</div>
                                        <div className='align-items-center' style={{ width: '20%', padding: '0px 10px 10px 10px'}}></div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '34%', padding: '0px 10px 10px 10px', }}>Rs.</ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '4.5%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>3.2.5</div>
                                        <div className='align-items-center' style={{ padding: '0px 10px 10px 10px'}}> That every Director of the Company has paid to the Company full amount on each of the shares taken or contracted to be taken by him and for which he is liable to pay in cash.</div>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '1%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>4.1</div>
                                        <div className='align-items-center' style={{ padding: '5px 10px 0px 10px',fontWeight:"bold" }}>Declaration</div>
                                    </div>
                                    <div className='d-flex'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '1%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ padding: ' 0px 10px 10px 10px' }}>I do hereby solemnly, and sincerely declare that the information provided in the form is:</div>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '1%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '0%' }}></div>
                                        <div className='align-items-start d-flex' style={{ padding: ' 5px 10px', gap: "12px" }}><div>
                                            (i)</div>
                                            <div style={{ marginLeft: '14px' }}>true and correct to the best of my knowledge, in consonance with the record as maintained by the Company and nothing has been concealed; and </div>
                                        </div>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '1%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '1%' }}></div>
                                        <div className='align-items-start d-flex' style={{ padding: ' 5px 10px', gap: "12px" }}><div>
                                            (ii)</div>
                                            <div style={{ marginLeft: '11px' }}>hereby reported after complying with and fulfilling all requirements under the relevant provisions of law, rules, regulations, directives, circulars and notifications whichever is applicable.</div>
                                        </div>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '1%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>4.2</div>
                                        <div className='align-items-center' style={{width:"30%", padding: '5px 10px 0px 10px',fontWeight:"bold" }}> Details of declarant(s)</div>
                                        <Wrapper style={{ width: "66%" }} className="table-responsive mt-0" >
                                            <table
                                                className="table table-bordered"
                                                style={{ fontFamily: "Montserrat rev=1 !important" }}
                                            >
                                                <thead>
                                                    <th  style={{ verticalAlign: 'sub', border: '1px solid #121212', textAlign: "center" }}>
                                                    Name
                                                    </th>
                                                    <th  style={{ verticalAlign: 'sub', border: '1px solid #121212', textAlign: "center" }}>
                                                    Designation(CEO/Director/Company Secretary)
                                                    </th>
                                                    <th  style={{ verticalAlign: 'sub', border: '1px solid #121212', textAlign: "center" }}>
                                                    Signature
                                                    </th>

                                                </thead>

                                                <tbody>
                                                    <tr>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}></td>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}></td>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}></td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}></td>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}></td>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}></td>
                                                    </tr>


                                                </tbody>
                                            </table>
                                        </Wrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '1%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{width:"30%", padding: '5px 10px 0px 10px',fontWeight:"bold" }}></div>
                                        <div>
                                            <i>Note: The declaration shall be signed by Chief Executive or one of the 
                                            directors and company secretary</i>
                                        </div>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '1%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ width: '55%', padding: ' 5px 10px' }}> </div>
                                        <div className='align-items-center text-center' style={{ width: '8%', padding: ' 5px' }}> Day</div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center text-center' style={{ width: '8%', padding: ' 5px' }}> Month</div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center text-center' style={{ width: '16%', padding: ' 5px' }}>Year </div>
                                        <div></div>
                                    </div>
                                    <div className='d-flex '>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '1%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>6.5</div>
                                        <div className='align-items-center' style={{ width: '55%', padding: ' 0px 10px 10px 10px' }}>Date</div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ border: '1px solid #121212', width: '4%', padding: ' 5px 10px' }}> </div>
                                        <div className='align-items-center' style={{ paddingLeft: '8px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ border: '1px solid #121212', width: '4%', padding: ' 5px 10px', borderRight: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ paddingLeft: '8px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', border: '1px solid #121212', }}> </div>
                                    </div>
                                    <div className='d-flex my-2' style={{ fontWeight: "bold" }}>
                                        Enclosure:
                                    </div>
                                    <div className='d-flex align-items-baseline'>
                                        <div style={{ marginLeft: "5px" }}>1.</div><div style={{ marginLeft: '23px' }}>Evidence of receipt of amount of minimum subscription by the Company.</div>
                                    </div>
                                    <div className='d-flex'>
                                        <div style={{ marginLeft: "5px" }}>2.</div><div style={{ marginLeft: '20px' }}>Copy of Statement in Lieu of Prospectus</div>
                                    </div>
                                    <div className='d-flex'>
                                        <div style={{ marginLeft: "5px" }}>3.</div><div style={{ marginLeft: '20px' }}>Scanned copy of Form-22 duly signed by both the declarants (applicable in case of online filing)</div>
                                    </div>
                                    <div className='d-flex'>
                                        <div style={{ marginLeft: "5px" }}>4.</div><div style={{ marginLeft: '19px' }}>Original challan or other evidence of payment of fee specified in Seventh Schedule of the Act (not applicable in case of online filing)</div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </PDFExport>
                    : <PDFExport
                        paperSize="A4"
                        margin="1cm"
                        scale={0.6}
                        fileName={`Form 22`}
                        pageTemplate={PageTemplate}
                        ref={pdfExportComponent}
                    >
                         <div style={{ fontFamily: `${fontFamilyForStaticData}`, fontSize: `${fontSizeForStaticData}`, fontWeight: 500 }}>
                            <FormName>
                                Form-22
                            </FormName>
                            <div className='row'>
                                <div className='col-md-12' >
                                    <HeadingWrapper className='d-flex justify-content-center'>
                                        THE COMPANIES ACT, 2017
                                    </HeadingWrapper>

                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <HeadingWrapper className='d-flex justify-content-center'>
                                        THE COMPANIES REGULATIONS, 2024
                                    </HeadingWrapper>

                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <div style={{ fontSize: '15px', fontWeight: 'bold' }} className='d-flex justify-content-center'>
                                        [Section 19 & Regulation 30]
                                    </div>
                                </div>
                            </div>

                            <div className='row mt-4'>
                                <div className='col-md-12'>
                                    <HeadingWrapper className='text-center d-flex justify-content-center'>
                                    DECLARATION BY A PUBLIC COMPANY BEFORE COMMENCING BUSINESS
                                    </HeadingWrapper>
                                </div>
                            </div>
                            <PartsWrapper className='mt-4 mb-3'>
                                PART-I
                            </PartsWrapper>
                            <div className='row'>
                                <div className='col-md-12' style={{ padding: '25px 50px', wordWrap: 'break-word' }}>
                                    <div> <i>(Please complete in bold capital letters)</i></div>


                                    <div className='d-flex' >
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>1.1</div>
                                        <div className='align-items-center' style={{ width: '28%', padding: '0px 10px 10px 10px' }}>CUIN (Registration Number)  </div>
                                        <div className='align-items-center  d-flex'  >{
                                            '1234 552'.split("")?.map((item, idx) => {
                                                if (item == ' ') return
                                                return <div style={{ minWidth: '30px', minHeight: '34px', border: '1px solid #121212', borderLeft: idx != 0 ? "none" : "1px solid #121212", padding: '6px 9px' }}></div>
                                            })
                                        }
                                        </div>
                                    </div>



                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>1.2</div>
                                        <div className='align-items-center' style={{ width: '29%', padding: '0px 10px 10px 10px' }}>Name of the Company  </div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '68%', padding: '0px 10px 10px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                    </div>




                                    <div className='d-flex  mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>1.3</div>
                                        <div className='align-items-center' style={{ width: '20.20%', padding: '0px 10px 10px 10px' }}>Fee payment Details*</div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '5%' }}>1.3.1</div>
                                        <div className='align-items-center' style={{ width: '13.20%', paddingLeft: '20px' }}>Challan No.</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '17.20%', paddingLeft: '20px', minHeight: '34px' }}></ScrollWrapper>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '5%' }}>1.3.2</div>
                                        <div className='align-items-center' style={{ width: '13.20%', paddingLeft: '20px' }}>Amount</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '23.20%', paddingLeft: '20px', minHeight: '34px' }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '85%', borderBottom: "none", paddingLeft: '20px', minHeight: '70px', fontWeight: "bold" }}>1.4 Declaration before commencing business —</ScrollWrapper>
                                        <ScrollWrapper className='align-items-center text-center' style={{ border: '1px solid #121212', borderLeft: "none", borderBottom: "none", width: '15%', padding: '10px', minHeight: '70px' }}>Please tick the relevant box</ScrollWrapper>
                                    </div>
                                    <div className='d-flex'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '21%', paddingLeft: '20px', borderBottom: "none", minHeight: '34px', fontWeight: "bold" }}>Part-II</ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '64%', borderLeft: "none", borderBottom: "none", paddingLeft: '20px', minHeight: '34px' }}>In case of a company issuing a prospectus</ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '15%', borderLeft: "none", borderBottom: "none", paddingLeft: '20px', minHeight: '34px' }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '21%', paddingLeft: '20px', minHeight: '34px', fontWeight: "bold" }}>Part-III</ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '64%', borderLeft: "none", paddingLeft: '20px', minHeight: '34px' }}>In case of a company filing statement in lieu of prospectus</ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '15%', borderLeft: "none", paddingLeft: '20px', minHeight: '34px' }}></ScrollWrapper>
                                    </div>
                                    
                    

                                    <PartsWrapper className='mt-4 mb-3'>
                                        PART-II
                                    </PartsWrapper>
                                    <div className='row mt-4'>
                                        <div className='col-md-12'>
                                            <HeadingWrapper className='d-flex text-center justify-content-center'>
                                            (DECLARATION BY A COMPANY IN CASE OF ISSUING A PROSPECTUS)
                                            </HeadingWrapper>
                                        </div>
                                    </div>

                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>2.1</div>
                                        <div className='align-items-center' style={{ width: '26%', padding: '0px 10px 10px 10px',}}>Name(s) of declarant(s)</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '71%', padding: '0px 10px 10px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>2.2</div>
                                        <div className='align-items-center' style={{  padding: '0px 10px 10px 10px',}}> I/We, the above-named person(s), do hereby solemnly and sincerely declare as follows:</div>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '4%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>2.2.1</div>
                                        <div className='align-items-center' style={{ width: '39%', padding: '0px 10px 10px 10px'}}> That the amount of the share capital of the company offered to the public for subscription is</div>
                                        <div className='align-items-center' style={{ width: '18%', padding: '0px 10px 10px 10px'}}></div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '36%', padding: '0px 10px 10px 10px',  }}>Rs.</ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '4%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>2.2.2</div>
                                        <div className='align-items-center' style={{ width: '39%', padding: '0px 10px 10px 10px'}}> That the amount stated in the prospectus as the minimum amount which, in the opinion of the Directors, must be raised by the issue of share capital in order to provide for the matters given in the prospectus, is</div>
                                        <div className='align-items-center' style={{ width: '18%', padding: '0px 10px 10px 10px'}}></div>
                                        <ScrollWrapper className='align-items-center' style={{minHeight:"89px", border: '1px solid #121212', width: '36%', padding: '0px 10px 10px 10px', }}>Rs.</ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '4%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>2.2.3</div>
                                        <div className='align-items-center' style={{ width: '39%', padding: '0px 10px 10px 10px'}}> That shares held subject to the payment of the whole amount thereof in cash have been allotted to the amount of and the money has been received by the company </div>
                                        <div className='align-items-center' style={{ width: '18%', padding: '0px 10px 10px 10px'}}></div>
                                        <ScrollWrapper className='align-items-center' style={{minHeight:"70px", border: '1px solid #121212', width: '36%', padding: '0px 10px 10px 10px', }}>Rs.</ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '4%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>2.2.4</div>
                                        <div className='align-items-center' style={{ width: '39%', padding: '0px 10px 10px 10px'}}> That every Director of the Company has paid to the Company full amount on each of the shares taken or contracted to be taken by him and for which he is liable to pay in cash.</div>
                                        <div className='align-items-center' style={{ width: '18%', padding: '0px 10px 10px 10px'}}></div>
                                        <ScrollWrapper className='align-items-center' style={{minHeight:"90px", border: '1px solid #121212', width: '36%', padding: '0px 10px 10px 10px', }}>Rs.</ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '4%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>2.2.5</div>
                                        <div className='align-items-center' style={{ width: '39%', padding: '0px 10px 10px 10px'}}> That no money is, or may become, liable to be repaid to the applicants for any shares or participation term certificate/terms finance certificate/debentures which have been offered for public subscription by reason of any short subscription, failure to apply for, or to obtain permission for the shares or participation term certificates/term finance certificates/debentures to be dealt on any stock exchange.</div>
                                        <div className='align-items-center' style={{ width: '18%', padding: '0px 10px 10px 10px'}}></div>
                                        <ScrollWrapper className='align-items-center' style={{minHeight:"157px", border: '1px solid #121212', width: '36%', padding: '0px 10px 10px 10px', }}>Rs.</ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        {/* <div className='align-items-center' style={{ paddingLeft: '2px', width: '1%' }}></div> */}
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>2.3</div>
                                        <div className='align-items-center' style={{padding: '0px 10px 10px 10px'}}>Verified that the foregoing statements are true to the best of my/our knowledge and belief and that nothing has been concealed.</div>
                                    </div>

                                    <PartsWrapper className='mt-4 mb-3'>
                                        PART-III
                                    </PartsWrapper>
                                    <div className='row mt-4'>
                                        <div className='col-md-12'>
                                            <HeadingWrapper className='d-flex text-center justify-content-center'>
                                            (DECLARATION BY A COMPANY IN CASE OF FILING OF STATEMENT IN LIEU OF PROSPECTUS)
                                            </HeadingWrapper>
                                        </div>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        {/* <div className='align-items-center' style={{ paddingLeft: '2px', width: '1%' }}></div> */}
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>3.1</div>
                                        <div className='align-items-center' style={{width:"34%",padding: '0px 10px 10px 10px'}}>Name(s) of declarant(s)</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '32%', padding: '0px 10px 10px 10px', }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212',borderLeft:"none", width: '32%', padding: '0px 10px 10px 10px', }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        {/* <div className='align-items-center' style={{ paddingLeft: '2px', width: '1%' }}></div> */}
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>3.2</div>
                                        <div className='align-items-center' style={{padding: '0px 10px 10px 10px'}}>I/We, the above-named person(s), do hereby solemnly and sincerely declare as follows:</div>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '4%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>3.2.1</div>
                                        <div className='align-items-center' style={{ width: '39%', padding: '0px 10px 10px 10px'}}>That the amount of the share capital of the company subject to the payment of the whole amount thereof in cash is</div>
                                        <div className='align-items-center' style={{ width: '20%', padding: '0px 10px 10px 10px'}}></div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '34%', padding: '0px 10px 10px 10px', }}>Rs.</ScrollWrapper>
                                    </div>

                                    <div className='d-flex '>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '4%', }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%', }}>3.2.2</div>
                                        <div className='align-items-center' style={{ width: '30%', padding: ' 0px 10px 10px 10px', }}> That the company has not issued a prospectus inviting the public to subscribe for its shares, and that it has filed with the Registrar a statement in lieu of prospectus dated</div>
                                        <div className='align-items-center' style={{ width: '21%', padding: ' 0px 10px 10px 10px', }}></div>
                                        <div className='d-flex flex-column' style={{ width: '10%',}}>
                                            <div className='align-items-center text-center' style={{ width: '100%', }}>Day</div>
                                            <div className='d-flex'>
                                                <div className='align-items-center' style={{ width: '50%',minHeight:"25px", padding: ' 5px 10px', border: '1px solid #121212' }}> </div>
                                                <div className='align-items-center' style={{ width: '50%',minHeight:"25px", padding: ' 5px 10px', border: '1px solid #121212', borderLeft: 'none' }}> </div>
                                            </div>
                                        </div>
                                        <div className='align-items-center text-center' style={{ paddingLeft: '2px', width: '3%', }}></div>
                                        <div className='d-flex flex-column' style={{ width: '10%',}}>
                                            <div className='align-items-center text-center' style={{ width: '100%', }}>Month</div>
                                            <div className='d-flex'>
                                                <div className='align-items-center' style={{ width: '50%',minHeight:"25px", padding: ' 5px 10px', border: '1px solid #121212' }}> </div>
                                                <div className='align-items-center' style={{ width: '50%',minHeight:"25px", padding: ' 5px 10px', border: '1px solid #121212', borderLeft: 'none' }}> </div>
                                            </div>
                                        </div>
                                        <div className='align-items-center text-center' style={{ paddingLeft: '2px', width: '3%', }}></div>
                                        <div className='d-flex flex-column' style={{ width: '16%',}}>
                                            <div className='align-items-center text-center' style={{ width: '100%', }}>Year</div>
                                            <div className='d-flex'>
                                                <div className='align-items-center' style={{ width: '50%',minHeight:"25px", padding: ' 5px 10px', border: '1px solid #121212' }}> </div>
                                                <div className='align-items-center' style={{ width: '50%',minHeight:"25px", padding: ' 5px 10px', border: '1px solid #121212', borderLeft: 'none' }}> </div>
                                                <div className='align-items-center' style={{ width: '50%',minHeight:"25px", padding: ' 5px 10px', border: '1px solid #121212', borderLeft: 'none' }}> </div>
                                                <div className='align-items-center' style={{ width: '50%',minHeight:"25px", padding: ' 5px 10px', border: '1px solid #121212', borderLeft: 'none' }}> </div>
                                            </div>
                                        </div>
                                      
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '4%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>3.2.3</div>
                                        <div className='align-items-center' style={{ width: '39%', padding: '0px 10px 10px 10px'}}> That the amount fixed by the Memorandum or Articles and specified in the statement in lieu of prospectus as the minimum subscription upon which the directors may proceed to allotment is</div>
                                        <div className='align-items-center' style={{ width: '20%', padding: '0px 10px 10px 10px'}}></div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '34%', padding: '0px 10px 10px 10px', }}>Rs.</ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '4%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>3.2.4</div>
                                        <div className='align-items-center' style={{ width: '39%', padding: '0px 10px 10px 10px'}}> That shares held subject to the payment of the whole amount thereof in cash have been allotted to the amount of</div>
                                        <div className='align-items-center' style={{ width: '20%', padding: '0px 10px 10px 10px'}}></div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '34%', padding: '0px 10px 10px 10px', }}>Rs.</ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '4.5%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>3.2.5</div>
                                        <div className='align-items-center' style={{ padding: '0px 10px 10px 10px'}}> That every Director of the Company has paid to the Company full amount on each of the shares taken or contracted to be taken by him and for which he is liable to pay in cash.</div>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '1%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>4.1</div>
                                        <div className='align-items-center' style={{ padding: '5px 10px 0px 10px',fontWeight:"bold" }}>Declaration</div>
                                    </div>
                                    <div className='d-flex'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '1%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ padding: ' 0px 10px 10px 10px' }}>I do hereby solemnly, and sincerely declare that the information provided in the form is:</div>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '1%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '0%' }}></div>
                                        <div className='align-items-start d-flex' style={{ padding: ' 5px 10px', gap: "12px" }}><div>
                                            (i)</div>
                                            <div style={{ marginLeft: '14px' }}>true and correct to the best of my knowledge, in consonance with the record as maintained by the Company and nothing has been concealed; and </div>
                                        </div>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '1%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '1%' }}></div>
                                        <div className='align-items-start d-flex' style={{ padding: ' 5px 10px', gap: "12px" }}><div>
                                            (ii)</div>
                                            <div style={{ marginLeft: '11px' }}>hereby reported after complying with and fulfilling all requirements under the relevant provisions of law, rules, regulations, directives, circulars and notifications whichever is applicable.</div>
                                        </div>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '1%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>4.2</div>
                                        <div className='align-items-center' style={{width:"30%", padding: '5px 10px 0px 10px',fontWeight:"bold" }}> Details of declarant(s)</div>
                                        <Wrapper style={{ width: "66%" }} className="table-responsive mt-0" >
                                            <table
                                                className="table table-bordered"
                                                style={{ fontFamily: "Montserrat rev=1 !important" }}
                                            >
                                                <thead>
                                                    <th  style={{ verticalAlign: 'sub', border: '1px solid #121212', textAlign: "center" }}>
                                                    Name
                                                    </th>
                                                    <th  style={{ verticalAlign: 'sub', border: '1px solid #121212', textAlign: "center" }}>
                                                    Designation(CEO/Director/Company Secretary)
                                                    </th>
                                                    <th  style={{ verticalAlign: 'sub', border: '1px solid #121212', textAlign: "center" }}>
                                                    Signature
                                                    </th>

                                                </thead>

                                                <tbody>
                                                    <tr>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}></td>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}></td>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}></td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}></td>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}></td>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}></td>
                                                    </tr>


                                                </tbody>
                                            </table>
                                        </Wrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '1%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{width:"30%", padding: '5px 10px 0px 10px',fontWeight:"bold" }}></div>
                                        <div>
                                            <i>Note: The declaration shall be signed by Chief Executive or one of the 
                                            directors and company secretary</i>
                                        </div>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '1%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ width: '55%', padding: ' 5px 10px' }}> </div>
                                        <div className='align-items-center text-center' style={{ width: '8%', padding: ' 5px' }}> Day</div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center text-center' style={{ width: '8%', padding: ' 5px' }}> Month</div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center text-center' style={{ width: '16%', padding: ' 5px' }}>Year </div>
                                        <div></div>
                                    </div>
                                    <div className='d-flex '>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '1%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>6.5</div>
                                        <div className='align-items-center' style={{ width: '55%', padding: ' 0px 10px 10px 10px' }}>Date</div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ border: '1px solid #121212', width: '4%', padding: ' 5px 10px' }}> </div>
                                        <div className='align-items-center' style={{ paddingLeft: '8px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ border: '1px solid #121212', width: '4%', padding: ' 5px 10px', borderRight: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ paddingLeft: '8px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', border: '1px solid #121212', }}> </div>
                                    </div>
                                    <div className='d-flex my-2' style={{ fontWeight: "bold" }}>
                                        Enclosure:
                                    </div>
                                    <div className='d-flex align-items-baseline'>
                                        <div style={{ marginLeft: "5px" }}>1.</div><div style={{ marginLeft: '23px' }}>Evidence of receipt of amount of minimum subscription by the Company.</div>
                                    </div>
                                    <div className='d-flex'>
                                        <div style={{ marginLeft: "5px" }}>2.</div><div style={{ marginLeft: '20px' }}>Copy of Statement in Lieu of Prospectus</div>
                                    </div>
                                    <div className='d-flex'>
                                        <div style={{ marginLeft: "5px" }}>3.</div><div style={{ marginLeft: '20px' }}>Scanned copy of Form-22 duly signed by both the declarants (applicable in case of online filing)</div>
                                    </div>
                                    <div className='d-flex'>
                                        <div style={{ marginLeft: "5px" }}>4.</div><div style={{ marginLeft: '19px' }}>Original challan or other evidence of payment of fee specified in Seventh Schedule of the Act (not applicable in case of online filing)</div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </PDFExport>}
            </div>
            <button disabled={loading} className='btn btn-danger' onClick={(e) => {
                // if(viewPdf)
                //   SetViewPDf(true)
                if (pdfExportComponent.current) {
                    pdfExportComponent.current.save();
                }
            }}>Download PDF</button>
        </div>
    )
}
const PartsWrapper = styled.div`
color: #121212;
text-decoration: underline;
justify-content: center;
display: flex;
margin-right: 15px;
font-size: 17px;
font-weight: bold;
`;
const ScrollWrapper = styled.div`
max-height: 50px;
overflow-y: scroll;
overflow-x: hidden;
::-webkit-scrollbar{
    height: 5px;
    width: 3px;
  }
  
  ::-webkit-scrollbar-track{
    background: #F9F9FB;
  }
  ::-webkit-scrollbar-thumb{
    background: #4E515680;
    border-radius: 5px;
  
  }
`;
const HeadingWrapper = styled.div`
font-size: 17px;
font-weight: bold;
`;
const FormName = styled.div`
color: #121212;
text-decoration: underline;
justify-content: end;
display: flex;
margin-right: 15px;
font-size: 17px;
font-weight: bold;
`;
const Wrapper = styled.div`
table.table-bordered{
    border: 1px solid #121212
     border-color: #121212; 
    // border:1px solid blue;
    // margin-top:20px;
  }
// table.table-bordered > thead > tr > th{
//     // border:1px solid blue;
//     border-color: #121212; 
// }
// table.table-bordered > tbody > tr > td{
//     // border:1px solid blue;
//     borderC-color: #121212; 
// }
 tr td {
    // border:1px solid blue;.
    border-color: #121212; 
}

`;
const WrapperDashed = styled.div`

  td {
    
    // border: 1px dashed #dddcdc;
}

`;