import React, { useState, useEffect } from 'react'
import { PDFExport } from "@progress/kendo-react-pdf";
import PageTemplate from "./page-template";
import {
    getCompanies,
} from "../../../store/services/company.service";
import styled from 'styled-components';
import { TiTick } from "react-icons/ti";
import { numberWithCommas } from 'utilities/utilityFunctions';
export const AppendixAtoApp6 = ({ data, formTemplate = false }) => {
    const pdfExportComponent = React.useRef(null);
    const [CompanyData, setCompanyData] = useState(null)
    const [loading, setLoading] = useState(false);
    const [fontFamilyForStaticData, setFontFamilyForStaticData] = useState('Montserrat, sans-serif')
    const [fontSizeForStaticData, setFontSizeForStaticData] = useState('14px')
    const [fontfamilyforDynimicData, setFontFamilyForDynmicData] = useState('Segoe Print')
    const [fontColor, setFontColor] = useState('')
    const baseEmail = sessionStorage.getItem("email") || "";
    const [governance, setGovernance] = useState({})
    const getAllCompanies = async () => {
        setLoading(true)
        try {
            const response = await getCompanies(baseEmail);
            if (response.status === 200) {
                response.data.data = response.data.data.filter((item) => item?.active === 'Y')
                const parents = response.data.data;
                const company = parents?.find(ite => ite?.code == data?.company_code)
                let Allperson = company.governance && company.governance != "" ? JSON.parse(company.governance) : []
                let sec = Allperson.find(x => x.role == "Company Secretary" && x?.active?.toLowerCase() != 'n')
                let ceo = Allperson.find(x => x.role == "CEO" && x?.active?.toLowerCase() != 'n')


                if (sec) {
                    setGovernance(sec)
                } else if (ceo) {
                    setGovernance(ceo)
                }
                setCompanyData(company)
                setTimeout(() => {
                    setLoading(false)
                }, 1000);
            }
        } catch (error) {
            setLoading(false)
        }
    };
    useEffect(() => {
        getAllCompanies()
    }, [])
    return (
        <div>
            <div
                style={{ fontFamily: "sans-serif " }}
            >

                {formTemplate ?
                    <PDFExport
                        paperSize="A4"
                        margin="1cm"
                        scale={0.6}
                        fileName={` Appendix-A to App-6`}
                        pageTemplate={PageTemplate}
                        ref={pdfExportComponent}
                    >
                        <div style={{ fontFamily: `${fontFamilyForStaticData}`, fontSize: `${fontSizeForStaticData}`, fontWeight: 500 }}>
                            <FormName>
                                Appendix-A to App-6
                            </FormName>
                            <div className='row'>
                                <div className='col-md-12' >
                                    <HeadingWrapper className='d-flex justify-content-center'>
                                        THE COMPANIES ACT, 2017 THE COMPANIES REGULATIONS, 2024
                                    </HeadingWrapper>

                                </div>
                            </div>

                            <div className='row'>
                                <div className='col-md-12'>
                                    <div style={{ fontSize: '15px', fontWeight: 'bold' }} className='d-flex justify-content-center'>
                                        [Regulation 139 & 140]
                                    </div>
                                </div>
                            </div>

                            <div className='row mt-4'>
                                <div className='col-md-12'>
                                    <HeadingWrapper className='text-center d-flex justify-content-center'>
                                        STATEMENT OF SHAREHOLDING AS ON _____________
                                    </HeadingWrapper>
                                </div>
                            </div>

                           
                            <div className='row'>
                                <div className='col-md-12' style={{ padding: '25px 50px', wordWrap: 'break-word' }}>
                                <div style={{ fontWeight: "bold", textDecoration: "underline" }}>
                                Particulars of the Holding company
                            </div>
                            <div className='d-flex mt-3'>
                                <div style={{ width: "10%", padding: "5px 10px" }}>
                                    Name
                                </div>
                                <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '30%', padding: ' 5px 10px', }}></ScrollWrapper>
                            </div>
                            <div className='d-flex mt-0'>
                                <div style={{ width: "10%", padding: "5px 10px" }}>
                                    CUIN
                                </div>
                                <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212',borderTop:"none", width: '30%', padding: ' 5px 10px', }}></ScrollWrapper>
                            </div>
                            <div className='d-flex mt-3'>
                                <div style={{ width: "18%", padding: "5px 10px" }}>

                                </div>
                                <div style={{ width: "18%", padding: "5px 10px" }}>

                                </div>
                                <div style={{ width: "20%", textDecoration: "underline", fontWeight: "bold", padding: "5px 10px" }}>
                                    No.of shares
                                </div>
                                <div style={{ width: "20%", textDecoration: "underline", fontWeight: "bold", padding: "5px 10px" }}>
                                    Par value
                                </div>
                                <div style={{ width: "20%", textDecoration: "underline", fontWeight: "bold", padding: "5px 10px" }}>
                                    Amount(Rs.)
                                </div>
                                <div style={{ width: "20%", textDecoration: "underline", fontWeight: "bold", padding: "5px 10px" }}>
                                    Held by
                                </div>
                                <div style={{ width: "20%", textDecoration: "underline", fontWeight: "bold", padding: "5px 10px" }}>
                                    Percentage of shareholding
                                </div>

                            </div>
                            <div className='d-flex mt-0'>
                                <div style={{ width: "18%", padding: "5px 10px" }}>

                                </div>
                                <div style={{ width: "18%", padding: "5px 10px" }}>

                                </div>
                                <ScrollWrapper style={{ border: '1px solid #121212', width: "20%", padding: "5px 10px" }}>

                                </ScrollWrapper>
                                <div style={{ width: "20%", padding: "5px 10px" }}>

                                </div>
                                <ScrollWrapper style={{ border: '1px solid #121212', width: "20%", padding: "5px 10px" }}>

                                </ScrollWrapper>
                                <div style={{width: "20%", padding: "5px 10px" }}>
                                    (Names of the sponsors/directors)*
                                </div>
                                <ScrollWrapper style={{ border: '1px solid #121212', width: "20%", padding: "5px 10px" }}>

                                </ScrollWrapper>

                            </div>
                            <div className='d-flex mt-0'>
                                <div style={{ width: "18%", padding: "5px 10px" }}>

                                </div>
                                <div style={{ width: "18%", padding: "5px 10px" }}>
                                    Total Paid-up Capital
                                </div>
                                <div style={{ borderBottom: '1px solid #121212', width: "20%", padding: "5px 10px" }}>

                                </div>
                                <div style={{ width: "20%", padding: "5px 10px" }}>

                                </div>
                                <div style={{ borderBottom: '1px solid #121212', width: "20%", padding: "5px 10px" }}>

                                </div>
                                <div style={{ width: "20%", padding: "5px 10px" }}>

                                </div>
                                <div style={{ borderBottom: '1px solid #121212', width: "20%", padding: "5px 10px" }}>

                                </div>

                            </div>
                            <div className='d-flex mt-3'>
                                <div style={{ width: "18%", padding: "5px 10px" }}>

                                </div>
                                <div style={{ width: "18%", padding: "5px 10px" }}>

                                </div>
                                <div style={{ width: "20%", textDecoration: "underline", fontWeight: "bold", padding: "5px 10px" }}>
                                    No.of shares
                                </div>
                                <div style={{ width: "20%", textDecoration: "underline", fontWeight: "bold", padding: "5px 10px" }}>
                                    Par value
                                </div>
                                <div style={{ width: "20%", textDecoration: "underline", fontWeight: "bold", padding: "5px 10px" }}>
                                    Amount(Rs.)
                                </div>
                                <div style={{ width: "20%", textDecoration: "underline", fontWeight: "bold", padding: "5px 10px" }}>
                                    Investment made in subsidiaries and associates
                                </div>
                                <div style={{ width: "20%", textDecoration: "underline", fontWeight: "bold", padding: "5px 10px" }}>
                                    Percentage of shareholding
                                </div>

                            </div>
                            <div className='d-flex mt-0'>
                                <div style={{ width: "18%", padding: "5px 10px" }}>

                                </div>
                                <div style={{ width: "18%", padding: "5px 10px" }}>

                                </div>
                                <ScrollWrapper style={{ border: '1px solid #121212', width: "20%", padding: "5px 10px" }}>

                                </ScrollWrapper>
                                <div style={{ width: "20%", padding: "5px 10px" }}>

                                </div>
                                <ScrollWrapper style={{ border: '1px solid #121212', width: "20%", padding: "5px 10px" }}>

                                </ScrollWrapper>
                                <div style={{ width: "20%", padding: "5px 10px" }}>
                                    (Names of the sponsors/directors)*
                                </div>
                                <ScrollWrapper style={{ border: '1px solid #121212', width: "20%", padding: "5px 10px" }}>

                                </ScrollWrapper>

                            </div>
                            <div className='d-flex mt-0'>
                                <div style={{ width: "18%", padding: "5px 10px" }}>

                                </div>
                                <div style={{ width: "18%", padding: "5px 10px" }}>
                                    Total Long-term Investments
                                </div>
                                <div style={{ borderBottom: '1px solid #121212', width: "20%", padding: "5px 10px" }}>

                                </div>
                                <div style={{ width: "20%", padding: "5px 10px" }}>

                                </div>
                                <div style={{ borderBottom: '1px solid #121212', width: "20%", padding: "5px 10px" }}>

                                </div>
                                <div style={{ width: "20%", padding: "5px 10px" }}>

                                </div>
                                <div style={{ borderBottom: '1px solid #121212', width: "20%", padding: "5px 10px" }}>

                                </div>

                            </div>
                            <div className='mt-4' style={{ fontWeight: "bold", textDecoration: "underline" }}>
                                Particulars of the Subsidiaries
                            </div>
                            <div className='d-flex mt-3'>
                                <div style={{ width: "10%", padding: "5px 10px" }}>
                                    i. Name
                                </div>
                                <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '30%', padding: ' 5px 10px', }}></ScrollWrapper>
                            </div>
                            <div className='d-flex mt-0'>
                                <div style={{ width: "10%", padding: "5px 10px" }}>
                                    CUIN
                                </div>
                                <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', borderTop:"none", width: '30%', padding: ' 5px 10px', }}></ScrollWrapper>
                            </div>
                            <div className='d-flex mt-3'>
                                <div style={{ width: "18%", padding: "5px 10px" }}>

                                </div>
                                <div style={{ width: "18%", padding: "5px 10px" }}>

                                </div>
                                <div style={{ width: "20%", textDecoration: "underline", fontWeight: "bold", padding: "5px 10px" }}>
                                    No.of shares
                                </div>
                                <div style={{ width: "20%", textDecoration: "underline", fontWeight: "bold", padding: "5px 10px" }}>
                                    Par value
                                </div>
                                <div style={{ width: "20%", textDecoration: "underline", fontWeight: "bold", padding: "5px 10px" }}>
                                    Amount(Rs.)
                                </div>
                                <div style={{ width: "20%", textDecoration: "underline", fontWeight: "bold", padding: "5px 10px" }}>
                                    Held by
                                </div>
                                <div style={{ width: "20%", textDecoration: "underline", fontWeight: "bold", padding: "5px 10px" }}>
                                    Percentage of shareholding
                                </div>

                            </div>
                            <div className='d-flex mt-0'>
                                <div style={{ width: "18%", padding: "5px 10px" }}>

                                </div>
                                <div style={{ width: "18%", padding: "5px 10px" }}>

                                </div>
                                <ScrollWrapper style={{ border: '1px solid #121212', width: "20%", padding: "5px 10px" }}>

                                </ScrollWrapper>
                                <div style={{ width: "20%", padding: "5px 10px" }}>

                                </div>
                                <ScrollWrapper style={{ border: '1px solid #121212', width: "20%", padding: "5px 10px" }}>

                                </ScrollWrapper>
                                <div style={{  width: "20%", padding: "5px 10px" }}>
                                    (Names of the holding company)
                                </div>
                                <ScrollWrapper style={{ border: '1px solid #121212', width: "20%", padding: "5px 10px" }}>

                                </ScrollWrapper>

                            </div>
                            <div className='d-flex mt-0'>
                                <div style={{ width: "18%", padding: "5px 10px" }}>

                                </div>
                                <div style={{ width: "18%", padding: "5px 10px" }}>
                                    Total Paid-up Capital
                                </div>
                                <div style={{ borderBottom: '1px solid #121212', width: "20%", padding: "5px 10px" }}>

                                </div>
                                <div style={{ width: "20%", padding: "5px 10px" }}>

                                </div>
                                <div style={{ borderBottom: '1px solid #121212', width: "20%", padding: "5px 10px" }}>

                                </div>
                                <div style={{ width: "20%", padding: "5px 10px" }}>

                                </div>
                                <div style={{ borderBottom: '1px solid #121212', width: "20%", padding: "5px 10px" }}>

                                </div>

                            </div>
                            <div className='d-flex mt-3'>
                                <div style={{ width: "18%", padding: "5px 10px" }}>

                                </div>
                                <div style={{ width: "18%", padding: "5px 10px" }}>

                                </div>
                                <div style={{ width: "20%", textDecoration: "underline", fontWeight: "bold", padding: "5px 10px" }}>
                                    No.of shares
                                </div>
                                <div style={{ width: "20%", textDecoration: "underline", fontWeight: "bold", padding: "5px 10px" }}>
                                    Par value
                                </div>
                                <div style={{ width: "20%", textDecoration: "underline", fontWeight: "bold", padding: "5px 10px" }}>
                                    Amount(Rs.)
                                </div>
                                <div style={{ width: "20%", textDecoration: "underline", fontWeight: "bold", padding: "5px 10px" }}>
                                    Investment in other companies
                                </div>
                                <div style={{ width: "20%", textDecoration: "underline", fontWeight: "bold", padding: "5px 10px" }}>
                                    Percentage of total investment
                                </div>

                            </div>
                            <div className='d-flex mt-0'>
                                <div style={{ width: "18%", padding: "5px 10px" }}>

                                </div>
                                <div style={{ width: "18%", padding: "5px 10px" }}>

                                </div>
                                <ScrollWrapper style={{ border: '1px solid #121212', width: "20%", padding: "5px 10px" }}>

                                </ScrollWrapper>
                                <div style={{ width: "20%", padding: "5px 10px" }}>

                                </div>
                                <ScrollWrapper style={{ border: '1px solid #121212', width: "20%", padding: "5px 10px" }}>

                                </ScrollWrapper>
                                <div style={{ width: "20%", padding: "5px 10px" }}>
                                    (Names of the sponsors/directors)*
                                </div>
                                <ScrollWrapper style={{ border: '1px solid #121212', width: "20%", padding: "5px 10px" }}>

                                </ScrollWrapper>

                            </div>
                            <div className='d-flex mt-0'>
                                <div style={{ width: "18%", padding: "5px 10px" }}>

                                </div>
                                <div style={{ width: "18%", padding: "5px 10px" }}>
                                    Total Long-term Investments
                                </div>
                                <div style={{ borderBottom: '1px solid #121212', width: "20%", padding: "5px 10px" }}>

                                </div>
                                <div style={{ width: "20%", padding: "5px 10px" }}>

                                </div>
                                <div style={{ borderBottom: '1px solid #121212', width: "20%", padding: "5px 10px" }}>

                                </div>
                                <div style={{ width: "20%", padding: "5px 10px" }}>

                                </div>
                                <div style={{ borderBottom: '1px solid #121212', width: "20%", padding: "5px 10px" }}>

                                </div>

                            </div>
                            <div className='d-flex mt-4'>
                                <div style={{ width: "10%", padding: "5px 10px" }}>
                                    ii. Name
                                </div>
                                <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '30%', padding: ' 5px 10px', }}></ScrollWrapper>
                            </div>
                            <div className='d-flex mt-0'>
                                <div style={{ width: "10%", padding: "5px 10px" }}>
                                    CUIN
                                </div>
                                <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212',borderTop:"none", width: '30%', padding: ' 5px 10px', }}></ScrollWrapper>
                            </div>
                            <div className='d-flex mt-3'>
                                <div style={{ width: "18%", padding: "5px 10px" }}>

                                </div>
                                <div style={{ width: "18%", padding: "5px 10px" }}>

                                </div>
                                <div style={{ width: "20%", textDecoration: "underline", fontWeight: "bold", padding: "5px 10px" }}>
                                    No.of shares
                                </div>
                                <div style={{ width: "20%", textDecoration: "underline", fontWeight: "bold", padding: "5px 10px" }}>
                                    Par value
                                </div>
                                <div style={{ width: "20%", textDecoration: "underline", fontWeight: "bold", padding: "5px 10px" }}>
                                    Amount(Rs.)
                                </div>
                                <div style={{ width: "20%", textDecoration: "underline", fontWeight: "bold", padding: "5px 10px" }}>
                                    Held by
                                </div>
                                <div style={{ width: "20%", textDecoration: "underline", fontWeight: "bold", padding: "5px 10px" }}>
                                    Percentage of shareholding
                                </div>

                            </div>
                            <div className='d-flex mt-0'>
                                <div style={{ width: "18%", padding: "5px 10px" }}>

                                </div>
                                <div style={{ width: "18%", padding: "5px 10px" }}>

                                </div>
                                <ScrollWrapper style={{ border: '1px solid #121212', width: "20%", padding: "5px 10px" }}>

                                </ScrollWrapper>
                                <div style={{ width: "20%", padding: "5px 10px" }}>

                                </div>
                                <ScrollWrapper style={{ border: '1px solid #121212', width: "20%", padding: "5px 10px" }}>

                                </ScrollWrapper>
                                <div style={{ width: "20%", padding: "5px 10px" }}>
                                    (Names of the holding company)
                                </div>
                                <ScrollWrapper style={{ border: '1px solid #121212', width: "20%", padding: "5px 10px" }}>

                                </ScrollWrapper>

                            </div>
                            <div className='d-flex mt-0'>
                                <div style={{ width: "18%", padding: "5px 10px" }}>

                                </div>
                                <div style={{ width: "18%", padding: "5px 10px" }}>
                                    Total Paid-up Capital
                                </div>
                                <div style={{ borderBottom: '1px solid #121212', width: "20%", padding: "5px 10px" }}>

                                </div>
                                <div style={{ width: "20%", padding: "5px 10px" }}>

                                </div>
                                <div style={{ borderBottom: '1px solid #121212', width: "20%", padding: "5px 10px" }}>

                                </div>
                                <div style={{ width: "20%", padding: "5px 10px" }}>

                                </div>
                                <div style={{ borderBottom: '1px solid #121212', width: "20%", padding: "5px 10px" }}>

                                </div>

                            </div>
                            <div className='d-flex mt-3'>
                                <div style={{ width: "18%", padding: "5px 10px" }}>

                                </div>
                                <div style={{ width: "18%", padding: "5px 10px" }}>

                                </div>
                                <div style={{ width: "20%", textDecoration: "underline", fontWeight: "bold", padding: "5px 10px" }}>
                                    No.&nbsp;&nbsp;of shares
                                </div>
                                <div style={{ width: "20%", textDecoration: "underline", fontWeight: "bold", padding: "5px 10px" }}>
                                    Par value
                                </div>
                                <div style={{ width: "20%", textDecoration: "underline", fontWeight: "bold", padding: "5px 10px" }}>
                                    Amount(Rs.)
                                </div>
                                <div style={{ width: "20%", textDecoration: "underline", fontWeight: "bold", padding: "5px 10px" }}>
                                    Investment in other companies
                                </div>
                                <div style={{ width: "20%", textDecoration: "underline", fontWeight: "bold", padding: "5px 10px" }}>
                                    Percentage of total investment
                                </div>

                            </div>
                            <div className='d-flex mt-0'>
                                <div style={{ width: "18%", padding: "5px 10px" }}>

                                </div>
                                <div style={{ width: "18%", padding: "5px 10px" }}>

                                </div>
                                <ScrollWrapper style={{ border: '1px solid #121212', width: "20%", padding: "5px 10px" }}>

                                </ScrollWrapper>
                                <div style={{ width: "20%", padding: "5px 10px" }}>

                                </div>
                                <ScrollWrapper style={{ border: '1px solid #121212', width: "20%", padding: "5px 10px" }}>

                                </ScrollWrapper>
                                <div style={{ width: "20%", padding: "5px 10px" }}>
                                    (Names of the sponsors/directors)*
                                </div>
                                <ScrollWrapper style={{ border: '1px solid #121212', width: "20%", padding: "5px 10px" }}>

                                </ScrollWrapper>

                            </div>
                            <div className='d-flex mt-0'>
                                <div style={{ width: "18%", padding: "5px 10px" }}>

                                </div>
                                <div style={{ width: "18%", padding: "5px 10px" }}>
                                    Total Long-term Investments
                                </div>
                                <div style={{ borderBottom: '1px solid #121212', width: "20%", padding: "5px 10px" }}>

                                </div>
                                <div style={{ width: "20%", padding: "5px 10px" }}>

                                </div>
                                <div style={{ borderBottom: '1px solid #121212', width: "20%", padding: "5px 10px" }}>

                                </div>
                                <div style={{ width: "20%", padding: "5px 10px" }}>

                                </div>
                                <div style={{ borderBottom: '1px solid #121212', width: "20%", padding: "5px 10px" }}>

                                </div>

                            </div>
                            <div className='mt-3'>
                                *&nbsp;&nbsp; If shares have been owned, held or controlled by the spouse or minor children of the
                                sponsor/shareholder, the fact shall be disclosed separately.
                            </div>
                            <div className='mt-5 d-flex justify-content-end'>
                            Signature of Chief Executive/Company Secretary of the holding company
                            </div>











                            
                                </div>
                            </div>
                        </div>
                    </PDFExport>
                    : <PDFExport
                        paperSize="A4"
                        margin="1cm"
                        scale={0.6}
                        fileName={`Appendix-A to App-6`}
                        pageTemplate={PageTemplate}
                        ref={pdfExportComponent}
                    >
                         <div style={{ fontFamily: `${fontFamilyForStaticData}`, fontSize: `${fontSizeForStaticData}`, fontWeight: 500 }}>
                            <FormName>
                                Appendix-A to App-6
                            </FormName>
                            <div className='row'>
                                <div className='col-md-12' >
                                    <HeadingWrapper className='d-flex justify-content-center'>
                                        THE COMPANIES ACT, 2017 THE COMPANIES REGULATIONS, 2024
                                    </HeadingWrapper>

                                </div>
                            </div>

                            <div className='row'>
                                <div className='col-md-12'>
                                    <div style={{ fontSize: '15px', fontWeight: 'bold' }} className='d-flex justify-content-center'>
                                        [Regulation 139 & 140]
                                    </div>
                                </div>
                            </div>

                            <div className='row mt-4'>
                                <div className='col-md-12'>
                                    <HeadingWrapper className='text-center d-flex justify-content-center'>
                                        STATEMENT OF SHAREHOLDING AS ON _____________
                                    </HeadingWrapper>
                                </div>
                            </div>

                           
                            <div className='row'>
                                <div className='col-md-12' style={{ padding: '25px 50px', wordWrap: 'break-word' }}>
                                <div style={{ fontWeight: "bold", textDecoration: "underline" }}>
                                Particulars of the Holding company
                            </div>
                            <div className='d-flex mt-3'>
                                <div style={{ width: "10%", padding: "5px 10px" }}>
                                    Name
                                </div>
                                <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '30%', padding: ' 5px 10px', }}></ScrollWrapper>
                            </div>
                            <div className='d-flex mt-0'>
                                <div style={{ width: "10%", padding: "5px 10px" }}>
                                    CUIN
                                </div>
                                <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212',borderTop:"none", width: '30%', padding: ' 5px 10px', }}></ScrollWrapper>
                            </div>
                            <div className='d-flex mt-3'>
                                <div style={{ width: "18%", padding: "5px 10px" }}>

                                </div>
                                <div style={{ width: "18%", padding: "5px 10px" }}>

                                </div>
                                <div style={{ width: "20%", textDecoration: "underline", fontWeight: "bold", padding: "5px 10px" }}>
                                    No.of shares
                                </div>
                                <div style={{ width: "20%", textDecoration: "underline", fontWeight: "bold", padding: "5px 10px" }}>
                                    Par value
                                </div>
                                <div style={{ width: "20%", textDecoration: "underline", fontWeight: "bold", padding: "5px 10px" }}>
                                    Amount(Rs.)
                                </div>
                                <div style={{ width: "20%", textDecoration: "underline", fontWeight: "bold", padding: "5px 10px" }}>
                                    Held by
                                </div>
                                <div style={{ width: "20%", textDecoration: "underline", fontWeight: "bold", padding: "5px 10px" }}>
                                    Percentage of shareholding
                                </div>

                            </div>
                            <div className='d-flex mt-0'>
                                <div style={{ width: "18%", padding: "5px 10px" }}>

                                </div>
                                <div style={{ width: "18%", padding: "5px 10px" }}>

                                </div>
                                <ScrollWrapper style={{ border: '1px solid #121212', width: "20%", padding: "5px 10px" }}>

                                </ScrollWrapper>
                                <div style={{ width: "20%", padding: "5px 10px" }}>

                                </div>
                                <ScrollWrapper style={{ border: '1px solid #121212', width: "20%", padding: "5px 10px" }}>

                                </ScrollWrapper>
                                <div style={{width: "20%", padding: "5px 10px" }}>
                                    (Names of the sponsors/directors)*
                                </div>
                                <ScrollWrapper style={{ border: '1px solid #121212', width: "20%", padding: "5px 10px" }}>

                                </ScrollWrapper>

                            </div>
                            <div className='d-flex mt-0'>
                                <div style={{ width: "18%", padding: "5px 10px" }}>

                                </div>
                                <div style={{ width: "18%", padding: "5px 10px" }}>
                                    Total Paid-up Capital
                                </div>
                                <div style={{ borderBottom: '1px solid #121212', width: "20%", padding: "5px 10px" }}>

                                </div>
                                <div style={{ width: "20%", padding: "5px 10px" }}>

                                </div>
                                <div style={{ borderBottom: '1px solid #121212', width: "20%", padding: "5px 10px" }}>

                                </div>
                                <div style={{ width: "20%", padding: "5px 10px" }}>

                                </div>
                                <div style={{ borderBottom: '1px solid #121212', width: "20%", padding: "5px 10px" }}>

                                </div>

                            </div>
                            <div className='d-flex mt-3'>
                                <div style={{ width: "18%", padding: "5px 10px" }}>

                                </div>
                                <div style={{ width: "18%", padding: "5px 10px" }}>

                                </div>
                                <div style={{ width: "20%", textDecoration: "underline", fontWeight: "bold", padding: "5px 10px" }}>
                                    No.of shares
                                </div>
                                <div style={{ width: "20%", textDecoration: "underline", fontWeight: "bold", padding: "5px 10px" }}>
                                    Par value
                                </div>
                                <div style={{ width: "20%", textDecoration: "underline", fontWeight: "bold", padding: "5px 10px" }}>
                                    Amount(Rs.)
                                </div>
                                <div style={{ width: "20%", textDecoration: "underline", fontWeight: "bold", padding: "5px 10px" }}>
                                    Investment made in subsidiaries and associates
                                </div>
                                <div style={{ width: "20%", textDecoration: "underline", fontWeight: "bold", padding: "5px 10px" }}>
                                    Percentage of shareholding
                                </div>

                            </div>
                            <div className='d-flex mt-0'>
                                <div style={{ width: "18%", padding: "5px 10px" }}>

                                </div>
                                <div style={{ width: "18%", padding: "5px 10px" }}>

                                </div>
                                <ScrollWrapper style={{ border: '1px solid #121212', width: "20%", padding: "5px 10px" }}>

                                </ScrollWrapper>
                                <div style={{ width: "20%", padding: "5px 10px" }}>

                                </div>
                                <ScrollWrapper style={{ border: '1px solid #121212', width: "20%", padding: "5px 10px" }}>

                                </ScrollWrapper>
                                <div style={{ width: "20%", padding: "5px 10px" }}>
                                    (Names of the sponsors/directors)*
                                </div>
                                <ScrollWrapper style={{ border: '1px solid #121212', width: "20%", padding: "5px 10px" }}>

                                </ScrollWrapper>

                            </div>
                            <div className='d-flex mt-0'>
                                <div style={{ width: "18%", padding: "5px 10px" }}>

                                </div>
                                <div style={{ width: "18%", padding: "5px 10px" }}>
                                    Total Long-term Investments
                                </div>
                                <div style={{ borderBottom: '1px solid #121212', width: "20%", padding: "5px 10px" }}>

                                </div>
                                <div style={{ width: "20%", padding: "5px 10px" }}>

                                </div>
                                <div style={{ borderBottom: '1px solid #121212', width: "20%", padding: "5px 10px" }}>

                                </div>
                                <div style={{ width: "20%", padding: "5px 10px" }}>

                                </div>
                                <div style={{ borderBottom: '1px solid #121212', width: "20%", padding: "5px 10px" }}>

                                </div>

                            </div>
                            <div className='mt-4' style={{ fontWeight: "bold", textDecoration: "underline" }}>
                                Particulars of the Subsidiaries
                            </div>
                            <div className='d-flex mt-3'>
                                <div style={{ width: "10%", padding: "5px 10px" }}>
                                    i. Name
                                </div>
                                <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '30%', padding: ' 5px 10px', }}></ScrollWrapper>
                            </div>
                            <div className='d-flex mt-0'>
                                <div style={{ width: "10%", padding: "5px 10px" }}>
                                    CUIN
                                </div>
                                <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', borderTop:"none", width: '30%', padding: ' 5px 10px', }}></ScrollWrapper>
                            </div>
                            <div className='d-flex mt-3'>
                                <div style={{ width: "18%", padding: "5px 10px" }}>

                                </div>
                                <div style={{ width: "18%", padding: "5px 10px" }}>

                                </div>
                                <div style={{ width: "20%", textDecoration: "underline", fontWeight: "bold", padding: "5px 10px" }}>
                                    No.of shares
                                </div>
                                <div style={{ width: "20%", textDecoration: "underline", fontWeight: "bold", padding: "5px 10px" }}>
                                    Par value
                                </div>
                                <div style={{ width: "20%", textDecoration: "underline", fontWeight: "bold", padding: "5px 10px" }}>
                                    Amount(Rs.)
                                </div>
                                <div style={{ width: "20%", textDecoration: "underline", fontWeight: "bold", padding: "5px 10px" }}>
                                    Held by
                                </div>
                                <div style={{ width: "20%", textDecoration: "underline", fontWeight: "bold", padding: "5px 10px" }}>
                                    Percentage of shareholding
                                </div>

                            </div>
                            <div className='d-flex mt-0'>
                                <div style={{ width: "18%", padding: "5px 10px" }}>

                                </div>
                                <div style={{ width: "18%", padding: "5px 10px" }}>

                                </div>
                                <ScrollWrapper style={{ border: '1px solid #121212', width: "20%", padding: "5px 10px" }}>

                                </ScrollWrapper>
                                <div style={{ width: "20%", padding: "5px 10px" }}>

                                </div>
                                <ScrollWrapper style={{ border: '1px solid #121212', width: "20%", padding: "5px 10px" }}>

                                </ScrollWrapper>
                                <div style={{  width: "20%", padding: "5px 10px" }}>
                                    (Names of the holding company)
                                </div>
                                <ScrollWrapper style={{ border: '1px solid #121212', width: "20%", padding: "5px 10px" }}>

                                </ScrollWrapper>

                            </div>
                            <div className='d-flex mt-0'>
                                <div style={{ width: "18%", padding: "5px 10px" }}>

                                </div>
                                <div style={{ width: "18%", padding: "5px 10px" }}>
                                    Total Paid-up Capital
                                </div>
                                <div style={{ borderBottom: '1px solid #121212', width: "20%", padding: "5px 10px" }}>

                                </div>
                                <div style={{ width: "20%", padding: "5px 10px" }}>

                                </div>
                                <div style={{ borderBottom: '1px solid #121212', width: "20%", padding: "5px 10px" }}>

                                </div>
                                <div style={{ width: "20%", padding: "5px 10px" }}>

                                </div>
                                <div style={{ borderBottom: '1px solid #121212', width: "20%", padding: "5px 10px" }}>

                                </div>

                            </div>
                            <div className='d-flex mt-3'>
                                <div style={{ width: "18%", padding: "5px 10px" }}>

                                </div>
                                <div style={{ width: "18%", padding: "5px 10px" }}>

                                </div>
                                <div style={{ width: "20%", textDecoration: "underline", fontWeight: "bold", padding: "5px 10px" }}>
                                    No.of shares
                                </div>
                                <div style={{ width: "20%", textDecoration: "underline", fontWeight: "bold", padding: "5px 10px" }}>
                                    Par value
                                </div>
                                <div style={{ width: "20%", textDecoration: "underline", fontWeight: "bold", padding: "5px 10px" }}>
                                    Amount(Rs.)
                                </div>
                                <div style={{ width: "20%", textDecoration: "underline", fontWeight: "bold", padding: "5px 10px" }}>
                                    Investment in other companies
                                </div>
                                <div style={{ width: "20%", textDecoration: "underline", fontWeight: "bold", padding: "5px 10px" }}>
                                    Percentage of total investment
                                </div>

                            </div>
                            <div className='d-flex mt-0'>
                                <div style={{ width: "18%", padding: "5px 10px" }}>

                                </div>
                                <div style={{ width: "18%", padding: "5px 10px" }}>

                                </div>
                                <ScrollWrapper style={{ border: '1px solid #121212', width: "20%", padding: "5px 10px" }}>

                                </ScrollWrapper>
                                <div style={{ width: "20%", padding: "5px 10px" }}>

                                </div>
                                <ScrollWrapper style={{ border: '1px solid #121212', width: "20%", padding: "5px 10px" }}>

                                </ScrollWrapper>
                                <div style={{ width: "20%", padding: "5px 10px" }}>
                                    (Names of the sponsors/directors)*
                                </div>
                                <ScrollWrapper style={{ border: '1px solid #121212', width: "20%", padding: "5px 10px" }}>

                                </ScrollWrapper>

                            </div>
                            <div className='d-flex mt-0'>
                                <div style={{ width: "18%", padding: "5px 10px" }}>

                                </div>
                                <div style={{ width: "18%", padding: "5px 10px" }}>
                                    Total Long-term Investments
                                </div>
                                <div style={{ borderBottom: '1px solid #121212', width: "20%", padding: "5px 10px" }}>

                                </div>
                                <div style={{ width: "20%", padding: "5px 10px" }}>

                                </div>
                                <div style={{ borderBottom: '1px solid #121212', width: "20%", padding: "5px 10px" }}>

                                </div>
                                <div style={{ width: "20%", padding: "5px 10px" }}>

                                </div>
                                <div style={{ borderBottom: '1px solid #121212', width: "20%", padding: "5px 10px" }}>

                                </div>

                            </div>
                            <div className='d-flex mt-4'>
                                <div style={{ width: "10%", padding: "5px 10px" }}>
                                    ii. Name
                                </div>
                                <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '30%', padding: ' 5px 10px', }}></ScrollWrapper>
                            </div>
                            <div className='d-flex mt-0'>
                                <div style={{ width: "10%", padding: "5px 10px" }}>
                                    CUIN
                                </div>
                                <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212',borderTop:"none", width: '30%', padding: ' 5px 10px', }}></ScrollWrapper>
                            </div>
                            <div className='d-flex mt-3'>
                                <div style={{ width: "18%", padding: "5px 10px" }}>

                                </div>
                                <div style={{ width: "18%", padding: "5px 10px" }}>

                                </div>
                                <div style={{ width: "20%", textDecoration: "underline", fontWeight: "bold", padding: "5px 10px" }}>
                                    No.of shares
                                </div>
                                <div style={{ width: "20%", textDecoration: "underline", fontWeight: "bold", padding: "5px 10px" }}>
                                    Par value
                                </div>
                                <div style={{ width: "20%", textDecoration: "underline", fontWeight: "bold", padding: "5px 10px" }}>
                                    Amount(Rs.)
                                </div>
                                <div style={{ width: "20%", textDecoration: "underline", fontWeight: "bold", padding: "5px 10px" }}>
                                    Held by
                                </div>
                                <div style={{ width: "20%", textDecoration: "underline", fontWeight: "bold", padding: "5px 10px" }}>
                                    Percentage of shareholding
                                </div>

                            </div>
                            <div className='d-flex mt-0'>
                                <div style={{ width: "18%", padding: "5px 10px" }}>

                                </div>
                                <div style={{ width: "18%", padding: "5px 10px" }}>

                                </div>
                                <ScrollWrapper style={{ border: '1px solid #121212', width: "20%", padding: "5px 10px" }}>

                                </ScrollWrapper>
                                <div style={{ width: "20%", padding: "5px 10px" }}>

                                </div>
                                <ScrollWrapper style={{ border: '1px solid #121212', width: "20%", padding: "5px 10px" }}>

                                </ScrollWrapper>
                                <div style={{ width: "20%", padding: "5px 10px" }}>
                                    (Names of the holding company)
                                </div>
                                <ScrollWrapper style={{ border: '1px solid #121212', width: "20%", padding: "5px 10px" }}>

                                </ScrollWrapper>

                            </div>
                            <div className='d-flex mt-0'>
                                <div style={{ width: "18%", padding: "5px 10px" }}>

                                </div>
                                <div style={{ width: "18%", padding: "5px 10px" }}>
                                    Total Paid-up Capital
                                </div>
                                <div style={{ borderBottom: '1px solid #121212', width: "20%", padding: "5px 10px" }}>

                                </div>
                                <div style={{ width: "20%", padding: "5px 10px" }}>

                                </div>
                                <div style={{ borderBottom: '1px solid #121212', width: "20%", padding: "5px 10px" }}>

                                </div>
                                <div style={{ width: "20%", padding: "5px 10px" }}>

                                </div>
                                <div style={{ borderBottom: '1px solid #121212', width: "20%", padding: "5px 10px" }}>

                                </div>

                            </div>
                            <div className='d-flex mt-3'>
                                <div style={{ width: "18%", padding: "5px 10px" }}>

                                </div>
                                <div style={{ width: "18%", padding: "5px 10px" }}>

                                </div>
                                <div style={{ width: "20%", textDecoration: "underline", fontWeight: "bold", padding: "5px 10px" }}>
                                    No.&nbsp;&nbsp;of shares
                                </div>
                                <div style={{ width: "20%", textDecoration: "underline", fontWeight: "bold", padding: "5px 10px" }}>
                                    Par value
                                </div>
                                <div style={{ width: "20%", textDecoration: "underline", fontWeight: "bold", padding: "5px 10px" }}>
                                    Amount(Rs.)
                                </div>
                                <div style={{ width: "20%", textDecoration: "underline", fontWeight: "bold", padding: "5px 10px" }}>
                                    Investment in other companies
                                </div>
                                <div style={{ width: "20%", textDecoration: "underline", fontWeight: "bold", padding: "5px 10px" }}>
                                    Percentage of total investment
                                </div>

                            </div>
                            <div className='d-flex mt-0'>
                                <div style={{ width: "18%", padding: "5px 10px" }}>

                                </div>
                                <div style={{ width: "18%", padding: "5px 10px" }}>

                                </div>
                                <ScrollWrapper style={{ border: '1px solid #121212', width: "20%", padding: "5px 10px" }}>

                                </ScrollWrapper>
                                <div style={{ width: "20%", padding: "5px 10px" }}>

                                </div>
                                <ScrollWrapper style={{ border: '1px solid #121212', width: "20%", padding: "5px 10px" }}>

                                </ScrollWrapper>
                                <div style={{ width: "20%", padding: "5px 10px" }}>
                                    (Names of the sponsors/directors)*
                                </div>
                                <ScrollWrapper style={{ border: '1px solid #121212', width: "20%", padding: "5px 10px" }}>

                                </ScrollWrapper>

                            </div>
                            <div className='d-flex mt-0'>
                                <div style={{ width: "18%", padding: "5px 10px" }}>

                                </div>
                                <div style={{ width: "18%", padding: "5px 10px" }}>
                                    Total Long-term Investments
                                </div>
                                <div style={{ borderBottom: '1px solid #121212', width: "20%", padding: "5px 10px" }}>

                                </div>
                                <div style={{ width: "20%", padding: "5px 10px" }}>

                                </div>
                                <div style={{ borderBottom: '1px solid #121212', width: "20%", padding: "5px 10px" }}>

                                </div>
                                <div style={{ width: "20%", padding: "5px 10px" }}>

                                </div>
                                <div style={{ borderBottom: '1px solid #121212', width: "20%", padding: "5px 10px" }}>

                                </div>

                            </div>
                            <div className='mt-3'>
                                *&nbsp;&nbsp; If shares have been owned, held or controlled by the spouse or minor children of the
                                sponsor/shareholder, the fact shall be disclosed separately.
                            </div>
                            <div className='mt-5 d-flex justify-content-end'>
                            Signature of Chief Executive/Company Secretary of the holding company
                            </div>











                            
                                </div>
                            </div>
                        </div>
                    </PDFExport>}
            </div>
            <button disabled={loading} className='btn btn-danger' onClick={(e) => {
                // if(viewPdf)
                //   SetViewPDf(true)
                if (pdfExportComponent.current) {
                    pdfExportComponent.current.save();
                }
            }}>Download PDF</button>
        </div>
    )
}
const PartsWrapper = styled.div`
color: #121212;
text-decoration: underline;
justify-content: center;
display: flex;
margin-right: 15px;
font-size: 17px;
font-weight: bold;
`;
const ScrollWrapper = styled.div`
max-height: 50px;
overflow-y: scroll;
overflow-x: hidden;
::-webkit-scrollbar{
    height: 5px;
    width: 3px;
  }
  
  ::-webkit-scrollbar-track{
    background: #F9F9FB;
  }
  ::-webkit-scrollbar-thumb{
    background: #4E515680;
    border-radius: 5px;
  
  }
`;
const HeadingWrapper = styled.div`
font-size: 17px;
font-weight: bold;
`;
const FormName = styled.div`
color: #121212;
text-decoration: underline;
justify-content: end;
display: flex;
margin-right: 15px;
font-size: 17px;
font-weight: bold;
`;
const Wrapper = styled.div`
table.table-bordered{
    border: 1px solid #121212
     border-color: #121212; 
    // border:1px solid blue;
    // margin-top:20px;
  }
// table.table-bordered > thead > tr > th{
//     // border:1px solid blue;
//     border-color: #121212; 
// }
// table.table-bordered > tbody > tr > td{
//     // border:1px solid blue;
//     borderC-color: #121212; 
// }
 tr td {
    // border:1px solid blue;.
    border-color: #121212; 
}

`;
const WrapperDashed = styled.div`

  td {
    
    // border: 1px dashed #dddcdc;
}

`;