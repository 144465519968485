import React, { useState, useEffect } from 'react'
import { PDFExport } from "@progress/kendo-react-pdf";
import PageTemplate from "./page-template";
import {
    getCompanies,
} from "../../../store/services/company.service";
import styled from 'styled-components';
import { TiTick } from "react-icons/ti";
import { numberWithCommas } from 'utilities/utilityFunctions';
export const StatutoryForm15 = ({ data, formTemplate = false }) => {
    const pdfExportComponent = React.useRef(null);
    const [CompanyData, setCompanyData] = useState(null)
    const [loading, setLoading] = useState(false);
    const [fontFamilyForStaticData, setFontFamilyForStaticData] = useState('Montserrat, sans-serif')
    const [fontSizeForStaticData, setFontSizeForStaticData] = useState('14px')
    const [fontfamilyforDynimicData, setFontFamilyForDynmicData] = useState('Segoe Print')
    const [fontColor, setFontColor] = useState('')
    const baseEmail = sessionStorage.getItem("email") || "";
    const [governance, setGovernance] = useState({})
    const getAllCompanies = async () => {
        setLoading(true)
        try {
            const response = await getCompanies(baseEmail);
            if (response.status === 200) {
                response.data.data = response.data.data.filter((item) => item?.active === 'Y')
                const parents = response.data.data;
                const company = parents?.find(ite => ite?.code == data?.company_code)
                let Allperson = company.governance && company.governance != "" ? JSON.parse(company.governance) : []
                let sec = Allperson.find(x => x.role == "Company Secretary" && x?.active?.toLowerCase() != 'n')
                let ceo = Allperson.find(x => x.role == "CEO" && x?.active?.toLowerCase() != 'n')


                if (sec) {
                    setGovernance(sec)
                } else if (ceo) {
                    setGovernance(ceo)
                }
                setCompanyData(company)
                setTimeout(() => {
                    setLoading(false)
                }, 1000);
            }
        } catch (error) {
            setLoading(false)
        }
    };
    useEffect(() => {
        getAllCompanies()
    }, [])
    return (
        <div>
            <div
                style={{ fontFamily: "sans-serif " }}
            >

                {formTemplate ?
                    <PDFExport
                        paperSize="A4"
                        margin="1cm"
                        scale={0.6}
                        fileName={`Form 15`}
                        pageTemplate={PageTemplate}
                        ref={pdfExportComponent}
                    >
                        <div style={{ fontFamily: `${fontFamilyForStaticData}`, fontSize: `${fontSizeForStaticData}`, fontWeight: 500 }}>
                            <FormName>
                                Form-15
                            </FormName>
                            <div className='row'>
                                <div className='col-md-12' >
                                    <HeadingWrapper className='d-flex justify-content-center'>
                                        THE COMPANIES ACT, 2017
                                    </HeadingWrapper>

                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <HeadingWrapper className='d-flex justify-content-center'>
                                        THE COMPANIES REGULATIONS, 2024
                                    </HeadingWrapper>

                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <div style={{
                                        fontSize: '15px',
                                        // fontWeight: 'bold' 
                                    }} className='d-flex justify-content-center'>
                                        [Section 60A and Regulations 43(4), 43(6) & 30]
                                    </div>

                                </div>
                            </div>

                            <div className='row mt-4'>
                                <div className='col-md-12'>
                                    <HeadingWrapper className='text-center d-flex justify-content-center'>
                                        PUBLIC NOTICE TO HOLDERS OF SECURITIES OF BEARER NATURE ISSUED BY A COMPANY
                                    </HeadingWrapper>

                                </div>
                            </div>
                            <div className='row mt-4'>
                                <div className='col-md-12'>
                                    <HeadingWrapper style={{textDecoration:"underline"}} className='text-center d-flex justify-content-center'>
                                    REGISTER CONTAINING PARTICULARS OF HOLDERS OF SECURITIES OF 
BEARER NATURE AND PARTICULARS OF SUCH SECURITIES SURRENDERED OR 
CANCELLED
                                    </HeadingWrapper>
                                </div>
                            </div>
                            <div className='row mt-4'>
                                <div className='col-md-12'>
                                    <HeadingWrapper style={{textDecoration:"underline"}} className='text-center d-flex justify-content-center'>
                                        (Name of Company)
                                    </HeadingWrapper>
                                </div>
                            </div>

                            <div className='row'>
                                <div className='col-md-12' style={{ padding: '25px 50px', wordWrap: 'break-word' }}>
                                <Wrapper className="table-responsive mt-0" >
                                            <table
                                                className="table table-bordered"
                                                style={{ fontFamily: "Montserrat rev=1 !important" }}
                                            >
                                                <thead>
                                                    <th colSpan={5} style={{ verticalAlign: 'sub', border: '1px solid #121212', textAlign: "center" }}>
                                                    Names, addresses and particulars of holder of securities 
                                                    of a bearer nature
                                                    </th>
                                                    <th colSpan={5} style={{ verticalAlign: 'sub', border: '1px solid #121212', textAlign: "center" }}>
                                                    Particulars of securities surrendered and cancelled
                                                    </th>

                                                </thead>

                                                <tbody>
                                                    <tr>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}>Given Name and Surname/ Company Name</td>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}>Address/ registered office address</td>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}>Occupation</td>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}>Nationality/ Country of incorporation/ formation</td>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}>CNIC/ Passport No./ Registration No. </td>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}>Date of Issue</td>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}>Class of Shares/ securities</td>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}>Total number of securities</td>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}>Serial no. of security</td>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}>Date of surrender</td>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}>Date of cancellation</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}></td>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}></td>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}></td>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}></td>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}></td>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}></td>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}></td>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}></td>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}></td>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}></td>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}></td>
                                                    </tr>


                                                </tbody>
                                            </table>
                                        </Wrapper>
                                        <div style={{}}>
                                        Note: Please give particulars depending upon whether the holder is an individual or body corporate
                                        </div>
                                    
                                </div>
                            </div>
                        </div>
                    </PDFExport>
                    : <PDFExport
                        paperSize="A4"
                        margin="1cm"
                        scale={0.6}
                        fileName={`Form 15`}
                        pageTemplate={PageTemplate}
                        ref={pdfExportComponent}
                    >
                     <div style={{ fontFamily: `${fontFamilyForStaticData}`, fontSize: `${fontSizeForStaticData}`, fontWeight: 500 }}>
                            <FormName>
                                Form-15
                            </FormName>
                            <div className='row'>
                                <div className='col-md-12' >
                                    <HeadingWrapper className='d-flex justify-content-center'>
                                        THE COMPANIES ACT, 2017
                                    </HeadingWrapper>

                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <HeadingWrapper className='d-flex justify-content-center'>
                                        THE COMPANIES REGULATIONS, 2024
                                    </HeadingWrapper>

                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <div style={{
                                        fontSize: '15px',
                                        // fontWeight: 'bold' 
                                    }} className='d-flex justify-content-center'>
                                        [Section 60A and Regulations 43(4), 43(6) & 30]
                                    </div>

                                </div>
                            </div>

                            <div className='row mt-4'>
                                <div className='col-md-12'>
                                    <HeadingWrapper className='text-center d-flex justify-content-center'>
                                        PUBLIC NOTICE TO HOLDERS OF SECURITIES OF BEARER NATURE ISSUED BY A COMPANY
                                    </HeadingWrapper>

                                </div>
                            </div>
                            <div className='row mt-4'>
                                <div className='col-md-12'>
                                    <HeadingWrapper style={{textDecoration:"underline"}} className='text-center d-flex justify-content-center'>
                                    REGISTER CONTAINING PARTICULARS OF HOLDERS OF SECURITIES OF 
BEARER NATURE AND PARTICULARS OF SUCH SECURITIES SURRENDERED OR 
CANCELLED
                                    </HeadingWrapper>
                                </div>
                            </div>
                            <div className='row mt-4'>
                                <div className='col-md-12'>
                                    <HeadingWrapper style={{textDecoration:"underline"}} className='text-center d-flex justify-content-center'>
                                        (Name of Company)
                                    </HeadingWrapper>
                                </div>
                            </div>

                            <div className='row'>
                                <div className='col-md-12' style={{ padding: '25px 50px', wordWrap: 'break-word' }}>
                                <Wrapper className="table-responsive mt-0" >
                                            <table
                                                className="table table-bordered"
                                                style={{ fontFamily: "Montserrat rev=1 !important" }}
                                            >
                                                <thead>
                                                    <th colSpan={5} style={{ verticalAlign: 'sub', border: '1px solid #121212', textAlign: "center" }}>
                                                    Names, addresses and particulars of holder of securities 
                                                    of a bearer nature
                                                    </th>
                                                    <th colSpan={5} style={{ verticalAlign: 'sub', border: '1px solid #121212', textAlign: "center" }}>
                                                    Particulars of securities surrendered and cancelled
                                                    </th>

                                                </thead>

                                                <tbody>
                                                    <tr>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}>Given Name and Surname/ Company Name</td>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}>Address/ registered office address</td>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}>Occupation</td>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}>Nationality/ Country of incorporation/ formation</td>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}>CNIC/ Passport No./ Registration No. </td>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}>Date of Issue</td>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}>Class of Shares/ securities</td>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}>Total number of securities</td>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}>Serial no. of security</td>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}>Date of surrender</td>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}>Date of cancellation</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}></td>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}></td>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}></td>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}></td>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}></td>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}></td>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}></td>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}></td>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}></td>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}></td>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}></td>
                                                    </tr>


                                                </tbody>
                                            </table>
                                        </Wrapper>
                                        <div style={{}}>
                                        Note: Please give particulars depending upon whether the holder is an individual or body corporate
                                        </div>
                                    
                                </div>
                            </div>
                        </div>
                    </PDFExport>}
            </div>
            <button disabled={loading} className='btn btn-danger' onClick={(e) => {
                // if(viewPdf)
                //   SetViewPDf(true)
                if (pdfExportComponent.current) {
                    pdfExportComponent.current.save();
                }
            }}>Download PDF</button>
        </div>
    )
}
const PartsWrapper = styled.div`
color: #121212;
text-decoration: underline;
justify-content: center;
display: flex;
margin-right: 15px;
font-size: 17px;
font-weight: bold;
`;
const ScrollWrapper = styled.div`
max-height: 50px;
overflow-y: scroll;
overflow-x: hidden;
::-webkit-scrollbar{
    height: 5px;
    width: 3px;
  }
  
  ::-webkit-scrollbar-track{
    background: #F9F9FB;
  }
  ::-webkit-scrollbar-thumb{
    background: #4E515680;
    border-radius: 5px;
  
  }
`;
const HeadingWrapper = styled.div`
font-size: 17px;
font-weight: bold;
`;
const FormName = styled.div`
color: #121212;
text-decoration: underline;
justify-content: end;
display: flex;
margin-right: 15px;
font-size: 17px;
font-weight: bold;
`;
const Wrapper = styled.div`
table.table-bordered{
    border: 1px solid #121212
     border-color: #121212; 
    // border:1px solid blue;
    // margin-top:20px;
  }
// table.table-bordered > thead > tr > th{
//     // border:1px solid blue;
//     border-color: #121212; 
// }
// table.table-bordered > tbody > tr > td{
//     // border:1px solid blue;
//     borderC-color: #121212; 
// }
 tr td {
    // border:1px solid blue;.
    border-color: #121212; 
}

`;
const WrapperDashed = styled.div`

  td {
    
    border: 1px dashed #dddcdc;
}

`;