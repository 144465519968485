import React, { useState, useEffect } from 'react'
import { PDFExport } from "@progress/kendo-react-pdf";
import PageTemplate from "./page-template";
import {
    getCompanies,
} from "../../../store/services/company.service";
import styled from 'styled-components';
import { TiTick } from "react-icons/ti";
import { numberWithCommas } from 'utilities/utilityFunctions';
import "./CommonForm.css"
export const AppendixAtoApp4 = ({ data, formTemplate = false }) => {
    const pdfExportComponent = React.useRef(null);
    const [CompanyData, setCompanyData] = useState(null)
    const [loading, setLoading] = useState(false);
    const [fontFamilyForStaticData, setFontFamilyForStaticData] = useState('Montserrat, sans-serif')
    const [fontSizeForStaticData, setFontSizeForStaticData] = useState('14px')
    const [fontfamilyforDynimicData, setFontFamilyForDynmicData] = useState('Segoe Print')
    const [fontColor, setFontColor] = useState('')
    const baseEmail = sessionStorage.getItem("email") || "";
    const [governance, setGovernance] = useState({})
    const getAllCompanies = async () => {
        setLoading(true)
        try {
            const response = await getCompanies(baseEmail);
            if (response.status === 200) {
                response.data.data = response.data.data.filter((item) => item?.active === 'Y')
                const parents = response.data.data;
                const company = parents?.find(ite => ite?.code == data?.company_code)
                let Allperson = company.governance && company.governance != "" ? JSON.parse(company.governance) : []
                let sec = Allperson.find(x => x.role == "Company Secretary" && x?.active?.toLowerCase() != 'n')
                let ceo = Allperson.find(x => x.role == "CEO" && x?.active?.toLowerCase() != 'n')


                if (sec) {
                    setGovernance(sec)
                } else if (ceo) {
                    setGovernance(ceo)
                }
                setCompanyData(company)
                setTimeout(() => {
                    setLoading(false)
                }, 1000);
            }
        } catch (error) {
            setLoading(false)
        }
    };
    useEffect(() => {
        getAllCompanies()
    }, [])
    return (
        <div>
            <div
                style={{ fontFamily: "sans-serif " }}
            >

                {formTemplate ?
                    <PDFExport
                        paperSize="A4"
                        margin="1cm"
                        scale={0.6}
                        fileName={` Appendix-A to App-4`}
                        pageTemplate={PageTemplate}
                        ref={pdfExportComponent}
                    >
                        <div style={{ fontFamily: `${fontFamilyForStaticData}`, fontSize: `${fontSizeForStaticData}`, fontWeight: 500 }}>
                            <FormName>
                            Appendix-A to App-4
                            </FormName>
                            <div className='row'>
                                <div className='col-md-12' >
                                    <HeadingWrapper className='d-flex justify-content-center'>
                                    THE COMPANIES ACT, 2017
                                    </HeadingWrapper>
                                    <HeadingWrapper className='d-flex justify-content-center'>
                                    THE COMPANIES REGULATIONS, 2024
                                    </HeadingWrapper>

                                </div>
                            </div>

                            <div className='row'>
                                <div className='col-md-12'>
                                    <div style={{ fontSize: '15px', fontWeight: 'bold' }} className='d-flex justify-content-center'>
                                    [Regulations 145]
                                    </div>
                                </div>
                            </div>

                            <div className='row mt-4'>
                                <div className='col-md-12'>
                                    <HeadingWrapper className='text-center d-flex justify-content-center'>
                                    MEMBERS' RESOLUTION
                                    </HeadingWrapper>
                                </div>
                            </div>


                            <div className='row'>
                                <div className='col-md-12' style={{ padding: '25px 50px', wordWrap: 'break-word' }}>
                                        
                                        <p style={{fontSize:"14px"}} className='font-large mb-4'>In General Meeting of M/s.
                                            <span className='inline-input-200 ml-2'>
                                                <div className='border-none border-bottom border-dark inline-input-h' ></div>
                                            </span>
                                            (the ‘Company’) held on
                                            <span className='inline-input-100'>
                                                <div className='border-none border-bottom border-dark inline-input-h' ></div>
                                            </span>
                                            at
                                            <span className='inline-input-300'>
                                                <div className='border-none border-bottom border-dark inline-input-h' ></div>
                                            </span>
                                            the following member were present:
                                        </p>
                                        <ol style={{display:"block"}} className='app4-appendix-a-list1-ol'>
                                            <li>
                                                <span className='inline-input-240'>
                                                    <div className='border-none border-bottom border-dark inline-input-h' ></div>
                                                </span>
                                            </li>
                                            <li>
                                                <span className='inline-input-240'>
                                                    <div className='border-none border-bottom border-dark inline-input-h' ></div>
                                                </span>
                                            </li>
                                            <li>
                                                <span className='inline-input-240'>
                                                    <div className='border-none border-bottom border-dark inline-input-h' ></div>
                                                </span>
                                            </li>
                                            <li>
                                                <span className='inline-input-240'>
                                                    <div className='border-none border-bottom border-dark inline-input-h' ></div>
                                                </span>
                                            </li>
                                        </ol>
                                        <span className='aaa4-member-note'>(the number of members may vary from case to case and if the number of member is large, a separate list of members present in the meeting shall be attached)</span>
                                        <p style={{fontSize:"14px"}} className='font-large'>The members of the Company considered the fact that the Company;</p>
                                        <ol style={{display:"block"}} className='app4-appendix-a-list2-ol'>
                                            <li>has no known assets and liabilites;</li>
                                            <li>is not carrying on any business or any operation;</li>
                                            <li>has no liabilities outstanding in relation to any loan(s) obtained from the banks, finacial institutions, taxes, utility charges, or any obligations towards government departments or private parties;</li>
                                            <li>has no case pending against the company before any court of law;</li>
                                            <li>has no investigation, enquiry or prosecution pending against the company before any competent authority;</li>
                                        </ol>
                                        <p style={{fontSize:"14px"}} className='font-large'>and accordingly, after carefully considering all aspects, have duly resolved by three-fourth majority, the following:</p>
                                        <ol style={{display:"block"}} className='app4-appendix-a-list3-ol'>
                                            <li>That an application under regulation 145 of the Companies Regulations, 2024 seeking striking the name of our Company off the register of companies under section 426 of the Companies Act, 2017 may be filed with the Commission; and</li>
                                            <li>That Mr.
                                                <span className='inline-input-200 mx-2'>
                                                    <input className='border-none border-bottom border-dark inline-input-h' />
                                                </span>
                                                Cheif Executive/ Director of the Company is hereby authorized to file the application under the Companies Regulations, 2024 in this behalf.
                                            </li>
                                        </ol>
                                        <div className='text-right'>
                                            <span className='font-large'>Cheif Executive</span>
                                        </div>











                                </div>
                            </div>
                        </div>
                    </PDFExport>
                    : <PDFExport
                        paperSize="A4"
                        margin="1cm"
                        scale={0.6}
                        fileName={`Appendix-A to App-4`}
                        pageTemplate={PageTemplate}
                        ref={pdfExportComponent}
                    >
                         <div style={{ fontFamily: `${fontFamilyForStaticData}`, fontSize: `${fontSizeForStaticData}`, fontWeight: 500 }}>
                            <FormName>
                            Appendix-A to App-4
                            </FormName>
                            <div className='row'>
                                <div className='col-md-12' >
                                    <HeadingWrapper className='d-flex justify-content-center'>
                                    THE COMPANIES ACT, 2017
                                    </HeadingWrapper>
                                    <HeadingWrapper className='d-flex justify-content-center'>
                                    THE COMPANIES REGULATIONS, 2024
                                    </HeadingWrapper>

                                </div>
                            </div>

                            <div className='row'>
                                <div className='col-md-12'>
                                    <div style={{ fontSize: '15px', fontWeight: 'bold' }} className='d-flex justify-content-center'>
                                    [Regulations 145]
                                    </div>
                                </div>
                            </div>

                            <div className='row mt-4'>
                                <div className='col-md-12'>
                                    <HeadingWrapper className='text-center d-flex justify-content-center'>
                                    MEMBERS' RESOLUTION
                                    </HeadingWrapper>
                                </div>
                            </div>


                            <div className='row'>
                                <div className='col-md-12' style={{ padding: '25px 50px', wordWrap: 'break-word' }}>
                                        
                                        <p style={{fontSize:"14px"}} className='font-large mb-4'>In General Meeting of M/s.
                                            <span className='inline-input-200 ml-2'>
                                                <div className='border-none border-bottom border-dark inline-input-h' ></div>
                                            </span>
                                            (the ‘Company’) held on
                                            <span className='inline-input-100'>
                                                <div className='border-none border-bottom border-dark inline-input-h' ></div>
                                            </span>
                                            at
                                            <span className='inline-input-300'>
                                                <div className='border-none border-bottom border-dark inline-input-h' ></div>
                                            </span>
                                            the following member were present:
                                        </p>
                                        <ol style={{display:"block"}} className='app4-appendix-a-list1-ol'>
                                            <li>
                                                <span className='inline-input-240'>
                                                    <div className='border-none border-bottom border-dark inline-input-h' ></div>
                                                </span>
                                            </li>
                                            <li>
                                                <span className='inline-input-240'>
                                                    <div className='border-none border-bottom border-dark inline-input-h' ></div>
                                                </span>
                                            </li>
                                            <li>
                                                <span className='inline-input-240'>
                                                    <div className='border-none border-bottom border-dark inline-input-h' ></div>
                                                </span>
                                            </li>
                                            <li>
                                                <span className='inline-input-240'>
                                                    <div className='border-none border-bottom border-dark inline-input-h' ></div>
                                                </span>
                                            </li>
                                        </ol>
                                        <span className='aaa4-member-note'>(the number of members may vary from case to case and if the number of member is large, a separate list of members present in the meeting shall be attached)</span>
                                        <p style={{fontSize:"14px"}} className='font-large'>The members of the Company considered the fact that the Company;</p>
                                        <ol style={{display:"block"}} className='app4-appendix-a-list2-ol'>
                                            <li>has no known assets and liabilites;</li>
                                            <li>is not carrying on any business or any operation;</li>
                                            <li>has no liabilities outstanding in relation to any loan(s) obtained from the banks, finacial institutions, taxes, utility charges, or any obligations towards government departments or private parties;</li>
                                            <li>has no case pending against the company before any court of law;</li>
                                            <li>has no investigation, enquiry or prosecution pending against the company before any competent authority;</li>
                                        </ol>
                                        <p style={{fontSize:"14px"}} className='font-large'>and accordingly, after carefully considering all aspects, have duly resolved by three-fourth majority, the following:</p>
                                        <ol style={{display:"block"}} className='app4-appendix-a-list3-ol'>
                                            <li>That an application under regulation 145 of the Companies Regulations, 2024 seeking striking the name of our Company off the register of companies under section 426 of the Companies Act, 2017 may be filed with the Commission; and</li>
                                            <li>That Mr.
                                                <span className='inline-input-200 mx-2'>
                                                    <input className='border-none border-bottom border-dark inline-input-h' />
                                                </span>
                                                Cheif Executive/ Director of the Company is hereby authorized to file the application under the Companies Regulations, 2024 in this behalf.
                                            </li>
                                        </ol>
                                        <div className='text-right'>
                                            <span className='font-large'>Cheif Executive</span>
                                        </div>











                                </div>
                            </div>
                        </div>
                    </PDFExport>}
            </div>
            <button disabled={loading} className='btn btn-danger' onClick={(e) => {
                // if(viewPdf)
                //   SetViewPDf(true)
                if (pdfExportComponent.current) {
                    pdfExportComponent.current.save();
                }
            }}>Download PDF</button>
        </div>
    )
}
const PartsWrapper = styled.div`
color: #121212;
text-decoration: underline;
justify-content: center;
display: flex;
margin-right: 15px;
font-size: 17px;
font-weight: bold;
`;
const ScrollWrapper = styled.div`
max-height: 50px;
overflow-y: scroll;
overflow-x: hidden;
::-webkit-scrollbar{
    height: 5px;
    width: 3px;
  }
  
  ::-webkit-scrollbar-track{
    background: #F9F9FB;
  }
  ::-webkit-scrollbar-thumb{
    background: #4E515680;
    border-radius: 5px;
  
  }
`;
const HeadingWrapper = styled.div`
font-size: 17px;
font-weight: bold;
`;
const FormName = styled.div`
color: #121212;
text-decoration: underline;
justify-content: end;
display: flex;
margin-right: 15px;
font-size: 17px;
font-weight: bold;
`;
const Wrapper = styled.div`
table.table-bordered{
    border: 1px solid #121212
     border-color: #121212; 
    // border:1px solid blue;
    // margin-top:20px;
  }
// table.table-bordered > thead > tr > th{
//     // border:1px solid blue;
//     border-color: #121212; 
// }
// table.table-bordered > tbody > tr > td{
//     // border:1px solid blue;
//     borderC-color: #121212; 
// }
 tr td {
    // border:1px solid blue;.
    border-color: #121212; 
}

`;
const WrapperDashed = styled.div`

  td {
    
    // border: 1px dashed #dddcdc;
}

`;