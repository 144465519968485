import React, { useState, useEffect } from 'react'
import { PDFExport } from "@progress/kendo-react-pdf";
import PageTemplate from "./page-template";
import {
    getCompanies,
} from "../../../store/services/company.service";
import styled from 'styled-components';
import { TiTick } from "react-icons/ti";
import { numberWithCommas } from 'utilities/utilityFunctions';
export const StatutoryForm17 = ({ data, formTemplate = false }) => {
    const pdfExportComponent = React.useRef(null);
    const [CompanyData, setCompanyData] = useState(null)
    const [loading, setLoading] = useState(false);
    const [fontFamilyForStaticData, setFontFamilyForStaticData] = useState('Montserrat, sans-serif')
    const [fontSizeForStaticData, setFontSizeForStaticData] = useState('14px')
    const [fontfamilyforDynimicData, setFontFamilyForDynmicData] = useState('Segoe Print')
    const [fontColor, setFontColor] = useState('')
    const baseEmail = sessionStorage.getItem("email") || "";
    const [governance, setGovernance] = useState({})
    const getAllCompanies = async () => {
        setLoading(true)
        try {
            const response = await getCompanies(baseEmail);
            if (response.status === 200) {
                response.data.data = response.data.data.filter((item) => item?.active === 'Y')
                const parents = response.data.data;
                const company = parents?.find(ite => ite?.code == data?.company_code)
                let Allperson = company.governance && company.governance != "" ? JSON.parse(company.governance) : []
                let sec = Allperson.find(x => x.role == "Company Secretary" && x?.active?.toLowerCase() != 'n')
                let ceo = Allperson.find(x => x.role == "CEO" && x?.active?.toLowerCase() != 'n')


                if (sec) {
                    setGovernance(sec)
                } else if (ceo) {
                    setGovernance(ceo)
                }
                setCompanyData(company)
                setTimeout(() => {
                    setLoading(false)
                }, 1000);
            }
        } catch (error) {
            setLoading(false)
        }
    };
    useEffect(() => {
        getAllCompanies()
    }, [])
    return (
        <div>
            <div
                style={{ fontFamily: "sans-serif " }}
            >

                {formTemplate ?
                    <PDFExport
                        paperSize="A4"
                        margin="1cm"
                        scale={0.6}
                        fileName={`Form 17`}
                        pageTemplate={PageTemplate}
                        ref={pdfExportComponent}
                    >
                        <div style={{ fontFamily: `${fontFamilyForStaticData}`, fontSize: `${fontSizeForStaticData}`, fontWeight: 500 }}>
                            <FormName>
                                Form-17
                            </FormName>
                            <div className='row'>
                                <div className='col-md-12' >
                                    <HeadingWrapper className='d-flex justify-content-center'>
                                        THE COMPANIES ACT, 2017
                                    </HeadingWrapper>

                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <HeadingWrapper className='d-flex justify-content-center'>
                                        THE COMPANIES REGULATIONS, 2024
                                    </HeadingWrapper>

                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <div style={{
                                        fontSize: '15px',
                                        // fontWeight: 'bold' 
                                    }} className='d-flex justify-content-center'>
                                        [Section 123A(1) and Regulation 48(2) & 30]
                                    </div>

                                </div>
                            </div>

                            <div className='row mt-4'>
                                <div className='col-md-12'>
                                    <HeadingWrapper style={{ textDecoration: "underline" }} className='text-center d-flex justify-content-center'>
                                        DECLARATION BY MEMBER ABOUT ULTIMATE BENEFICIAL OWNERS
                                    </HeadingWrapper>

                                </div>
                            </div>
                            <div className='row mt-4'>
                                <div className='col-md-12'>
                                    <i className='text-center d-flex justify-content-center'>
                                        (To be submitted to the company by every member as specified under regulation 48(1) or regulation 48(2))
                                    </i>

                                </div>
                            </div>


                            <div className='row'>
                                <div className='col-md-12' style={{ padding: '25px 50px', wordWrap: 'break-word' }}>
                                    <div className='mt-1'>
                                        Name of Company _____________________________
                                    </div>
                                    <div className=''>
                                        CUIN _____________________________
                                    </div>
                                    <div className=''>
                                        Presented by _____________________________
                                    </div>
                                    <div className=''>
                                        I ____________________ do solemnly declare as follows;
                                    </div>
                                    <div className='mt-2'>
                                        1.&nbsp;&nbsp;&nbsp;&nbsp;{`I ____________________ having CNIC/Passport No.*_______________________and having address at _________________ am a person whose name is entered in the register of members of < name of the company>) as the holder of (state the percentage of shares or controlling interest) in the company. `}
                                    </div>
                                    <div className='mt-2'>
                                        2.&nbsp;&nbsp;&nbsp;&nbsp;{`I (became the holder of the shares)/(acquired control/interest)* in the company on the following date(s) and continue as such at the date of this declaration:`}
                                    </div>
                                    <div className='d-flex justify-content-between mt-4'>
                                        <div style={{ textDecoration: "overline" }}>
                                            Number and classes of shares/nature of control/interest in the company
                                        </div>
                                        <div style={{ textDecoration: "overline" }}>
                                            Date of becoming a member of the company
                                        </div>
                                    </div>
                                    <div className='mt-2'>
                                        3.&nbsp;&nbsp;&nbsp;&nbsp;{`I (myself hold/do not hold) [__] %percentage in the said share(s) or controlling interest in the company. `}
                                    </div>
                                    <div className='mt-2'>
                                        4.&nbsp;&nbsp;&nbsp;&nbsp;{`The particulars of the ultimate beneficial owner(s), as defined1 in the Companies Regulations 2024, in respect of the aforesaid number of shares/control/ interests, is/are:**`}
                                    </div>
                                    <Wrapper className="table-responsive mt-0" >
                                        <table
                                            className="table table-bordered"
                                            style={{ fontFamily: "Montserrat rev=1 !important" }}
                                        >
                                            <tbody>
                                                <tr>

                                                    <tr>
                                                        <td style={{ verticalAlign: 'sub', border: '1px solid #121212', textAlign: "center" }}>
                                                            1. Name
                                                        </td>
                                                        <td style={{ verticalAlign: 'sub', border: '1px solid #121212', textAlign: "center" }}>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ verticalAlign: 'sub', border: '1px solid #121212', textAlign: "center" }}>
                                                            2. Father’s Name/Spouse’s Name
                                                        </td>
                                                        <td style={{ verticalAlign: 'sub', border: '1px solid #121212', textAlign: "center" }}>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ verticalAlign: 'sub', border: '1px solid #121212', textAlign: "center" }}>
                                                            3. CNIC/NICOP/Passport no. alongwith date of issue (copy attached)
                                                        </td>
                                                        <td style={{ verticalAlign: 'sub', border: '1px solid #121212', textAlign: "center" }}>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ verticalAlign: 'sub', border: '1px solid #121212', textAlign: "center" }}>
                                                            4. Nationality
                                                        </td>
                                                        <td style={{ verticalAlign: 'sub', border: '1px solid #121212', textAlign: "center" }}>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ verticalAlign: 'sub', border: '1px solid #121212', textAlign: "center" }}>
                                                            5. Country of origin (in case of foreign national or dual national)
                                                        </td>
                                                        <td style={{ verticalAlign: 'sub', border: '1px solid #121212', textAlign: "center" }}>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ verticalAlign: 'sub', border: '1px solid #121212', textAlign: "center" }}>
                                                            6. Usual residential address
                                                        </td>
                                                        <td style={{ verticalAlign: 'sub', border: '1px solid #121212', textAlign: "center" }}>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ verticalAlign: 'sub', border: '1px solid #121212', textAlign: "center" }}>
                                                            7. Email address
                                                        </td>
                                                        <td style={{ verticalAlign: 'sub', border: '1px solid #121212', textAlign: "center" }}>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ verticalAlign: 'sub', border: '1px solid #121212', textAlign: "center" }}>
                                                            8. Date on which shareholding, control or interest acquired in the company
                                                        </td>
                                                        <td style={{ verticalAlign: 'sub', border: '1px solid #121212', textAlign: "center" }}>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ verticalAlign: 'sub', border: '1px solid #121212', textAlign: "center" }}>
                                                            9. Date on which shareholding, control or interest acquired in the company from former ultimate beneficial owner
                                                        </td>
                                                        <td style={{ verticalAlign: 'sub', border: '1px solid #121212', textAlign: "center" }}>

                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan={2} style={{ verticalAlign: 'sub', border: '1px solid #121212', textAlign: "center" }}>
                                                            10. In case of indirect shareholding, control or interest being exercised through intermediary companies, entities or other legal persons or legal arrangements in the chain of ownership or control, names and particulars are as follows:
                                                        </td>
                                                    </tr>

                                                </tr>

                                            </tbody>
                                        </table>
                                    </Wrapper>
                                    <Wrapper className="table-responsive mt-0" >
                                        <table
                                            className="table table-bordered"
                                            style={{ fontFamily: "Montserrat rev=1 !important" }}
                                        >
                                            <thead>
                                                <th style={{ verticalAlign: 'sub', border: '1px solid #121212', textAlign: "center" }}>
                                                    Name
                                                </th>
                                                <th style={{ verticalAlign: 'sub', border: '1px solid #121212', textAlign: "center" }}>
                                                    Legal form (Company/LLP/ Partnership Firm/ Trust/Any other body corporate (to be specified))
                                                </th>
                                                <th style={{ verticalAlign: 'sub', border: '1px solid #121212', textAlign: "center" }}>
                                                    Date of incorporation/ registration
                                                </th>
                                                <th style={{ verticalAlign: 'sub', border: '1px solid #121212', textAlign: "center" }}>
                                                    Name of registration authority
                                                </th>
                                                <th style={{ verticalAlign: 'sub', border: '1px solid #121212', textAlign: "center" }}>
                                                    Business Address
                                                </th>
                                                <th style={{ verticalAlign: 'sub', border: '1px solid #121212', textAlign: "center" }}>
                                                    Country
                                                </th>
                                                <th style={{ verticalAlign: 'sub', border: '1px solid #121212', textAlign: "center" }}>
                                                    Email address
                                                </th>
                                                <th style={{ verticalAlign: 'sub', border: '1px solid #121212', textAlign: "center" }}>
                                                    Percentage of shareholding, control or interest of UBO in the legal person or legal arrangement
                                                </th>
                                                <th style={{ verticalAlign: 'sub', border: '1px solid #121212', textAlign: "center" }}>
                                                    Percentage of shareholding, control or interest of legal person or legal arrangement in the Company
                                                </th>
                                                <th style={{ verticalAlign: 'sub', border: '1px solid #121212', textAlign: "center" }}>
                                                    Identity of Natural Person who ultimately owns or controls the legal person or arrangement
                                                </th>

                                            </thead>

                                            <tbody>
                                                <tr>
                                                    <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}>(a)</td>
                                                    <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}>(b)</td>
                                                    <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}>(c)</td>
                                                    <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}>(d)</td>
                                                    <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}>(e)</td>
                                                    <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}>(f)</td>
                                                    <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}>(g)</td>
                                                    <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}>(h)</td>
                                                    <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}>(i)</td>
                                                    <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}>(j)</td>
                                                    <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}>(k)</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}></td>
                                                    <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}></td>
                                                    <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}></td>
                                                    <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}></td>
                                                    <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}></td>
                                                    <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}></td>
                                                    <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}></td>
                                                    <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}></td>
                                                    <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}></td>
                                                    <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}></td>
                                                    <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}></td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={10} style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}>11. Any other information incidental to or relevant to enable the company to evaluate this matter</td>
                                                </tr>


                                            </tbody>
                                        </table>
                                    </Wrapper>
                                    <div className='mt-5'>
                                        And I make this solemn declaration conscientiously believing the same to be true.
                                    </div>
                                    <div >
                                        This _______ day of ____________, 20____
                                    </div>
                                    <div style={{ textDecoration: "overline" }} className='mt-4'>
                                        Name & signature
                                    </div>
                                    <div className='mt-4'>
                                        * Delete whichever is inapplicable
                                    </div>
                                    <div className='mt-0'>
                                        ** Please provide information required under para 4 if you are a:
                                    </div>
                                    <div style={{ marginLeft: "3rem" }} className='mt-0'>
                                        (i) Legal person, i.e. not an individual or natural person; or
                                    </div>
                                    <div style={{ marginLeft: "3rem" }} className='mt-0'>
                                        (ii) Natural person, but do not yourself hold [ ] % of shares or interest in the company.
                                    </div>
                                    <div className='mt-3'>
                                        Note: 1. The form of declaration may be modified or adapted to the circumstances in which the non-beneficial owner is a body corporate or in which there is more than one non-beneficial owner in respect of a particular share.
                                    </div>
                                    <div className='mt-3'>
                                        2. Please add further columns to the table in para 4 above depending upon the number of ultimate beneficial owners of the company
                                    </div>
                                    <div style={{ fontSize: "10px" }} className='mt-3'>
                                        <i>1 Sub-regulation (7) of regulation 48 defines an ultimate beneficial owner as a natural person who ultimately owns or controls a
                                            company, whether directly or indirectly, through at least twenty five percent shares or voting rights or by exercising effective control
                                            in that company through other means. ‘Control through other means’ may be exercised through a chain of ownership or through close
                                            relatives or associates having significant influence or control over the finances or decisions of the company.</i>
                                    </div>




                                </div>
                            </div>
                        </div>
                    </PDFExport>
                    : <PDFExport
                        paperSize="A4"
                        margin="1cm"
                        scale={0.6}
                        fileName={`Form 17`}
                        pageTemplate={PageTemplate}
                        ref={pdfExportComponent}
                    >
                       <div style={{ fontFamily: `${fontFamilyForStaticData}`, fontSize: `${fontSizeForStaticData}`, fontWeight: 500 }}>
                            <FormName>
                                Form-17
                            </FormName>
                            <div className='row'>
                                <div className='col-md-12' >
                                    <HeadingWrapper className='d-flex justify-content-center'>
                                        THE COMPANIES ACT, 2017
                                    </HeadingWrapper>

                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <HeadingWrapper className='d-flex justify-content-center'>
                                        THE COMPANIES REGULATIONS, 2024
                                    </HeadingWrapper>

                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <div style={{
                                        fontSize: '15px',
                                        // fontWeight: 'bold' 
                                    }} className='d-flex justify-content-center'>
                                        [Section 123A(1) and Regulation 48(2) & 30]
                                    </div>

                                </div>
                            </div>

                            <div className='row mt-4'>
                                <div className='col-md-12'>
                                    <HeadingWrapper style={{ textDecoration: "underline" }} className='text-center d-flex justify-content-center'>
                                        DECLARATION BY MEMBER ABOUT ULTIMATE BENEFICIAL OWNERS
                                    </HeadingWrapper>

                                </div>
                            </div>
                            <div className='row mt-4'>
                                <div className='col-md-12'>
                                    <i className='text-center d-flex justify-content-center'>
                                        (To be submitted to the company by every member as specified under regulation 48(1) or regulation 48(2))
                                    </i>

                                </div>
                            </div>


                            <div className='row'>
                                <div className='col-md-12' style={{ padding: '25px 50px', wordWrap: 'break-word' }}>
                                    <div className='mt-1'>
                                        Name of Company _____________________________
                                    </div>
                                    <div className=''>
                                        CUIN _____________________________
                                    </div>
                                    <div className=''>
                                        Presented by _____________________________
                                    </div>
                                    <div className=''>
                                        I ____________________ do solemnly declare as follows;
                                    </div>
                                    <div className='mt-2'>
                                        1.&nbsp;&nbsp;&nbsp;&nbsp;{`I ____________________ having CNIC/Passport No.*_______________________and having address at _________________ am a person whose name is entered in the register of members of < name of the company>) as the holder of (state the percentage of shares or controlling interest) in the company. `}
                                    </div>
                                    <div className='mt-2'>
                                        2.&nbsp;&nbsp;&nbsp;&nbsp;{`I (became the holder of the shares)/(acquired control/interest)* in the company on the following date(s) and continue as such at the date of this declaration:`}
                                    </div>
                                    <div className='d-flex justify-content-between mt-4'>
                                        <div style={{ textDecoration: "overline" }}>
                                            Number and classes of shares/nature of control/interest in the company
                                        </div>
                                        <div style={{ textDecoration: "overline" }}>
                                            Date of becoming a member of the company
                                        </div>
                                    </div>
                                    <div className='mt-2'>
                                        3.&nbsp;&nbsp;&nbsp;&nbsp;{`I (myself hold/do not hold) [__] %percentage in the said share(s) or controlling interest in the company. `}
                                    </div>
                                    <div className='mt-2'>
                                        4.&nbsp;&nbsp;&nbsp;&nbsp;{`The particulars of the ultimate beneficial owner(s), as defined1 in the Companies Regulations 2024, in respect of the aforesaid number of shares/control/ interests, is/are:**`}
                                    </div>
                                    <Wrapper className="table-responsive mt-0" >
                                        <table
                                            className="table table-bordered"
                                            style={{ fontFamily: "Montserrat rev=1 !important" }}
                                        >
                                            <tbody>
                                                <tr>

                                                    <tr>
                                                        <td style={{ verticalAlign: 'sub', border: '1px solid #121212', textAlign: "center" }}>
                                                            1. Name
                                                        </td>
                                                        <td style={{ verticalAlign: 'sub', border: '1px solid #121212', textAlign: "center" }}>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ verticalAlign: 'sub', border: '1px solid #121212', textAlign: "center" }}>
                                                            2. Father’s Name/Spouse’s Name
                                                        </td>
                                                        <td style={{ verticalAlign: 'sub', border: '1px solid #121212', textAlign: "center" }}>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ verticalAlign: 'sub', border: '1px solid #121212', textAlign: "center" }}>
                                                            3. CNIC/NICOP/Passport no. alongwith date of issue (copy attached)
                                                        </td>
                                                        <td style={{ verticalAlign: 'sub', border: '1px solid #121212', textAlign: "center" }}>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ verticalAlign: 'sub', border: '1px solid #121212', textAlign: "center" }}>
                                                            4. Nationality
                                                        </td>
                                                        <td style={{ verticalAlign: 'sub', border: '1px solid #121212', textAlign: "center" }}>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ verticalAlign: 'sub', border: '1px solid #121212', textAlign: "center" }}>
                                                            5. Country of origin (in case of foreign national or dual national)
                                                        </td>
                                                        <td style={{ verticalAlign: 'sub', border: '1px solid #121212', textAlign: "center" }}>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ verticalAlign: 'sub', border: '1px solid #121212', textAlign: "center" }}>
                                                            6. Usual residential address
                                                        </td>
                                                        <td style={{ verticalAlign: 'sub', border: '1px solid #121212', textAlign: "center" }}>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ verticalAlign: 'sub', border: '1px solid #121212', textAlign: "center" }}>
                                                            7. Email address
                                                        </td>
                                                        <td style={{ verticalAlign: 'sub', border: '1px solid #121212', textAlign: "center" }}>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ verticalAlign: 'sub', border: '1px solid #121212', textAlign: "center" }}>
                                                            8. Date on which shareholding, control or interest acquired in the company
                                                        </td>
                                                        <td style={{ verticalAlign: 'sub', border: '1px solid #121212', textAlign: "center" }}>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ verticalAlign: 'sub', border: '1px solid #121212', textAlign: "center" }}>
                                                            9. Date on which shareholding, control or interest acquired in the company from former ultimate beneficial owner
                                                        </td>
                                                        <td style={{ verticalAlign: 'sub', border: '1px solid #121212', textAlign: "center" }}>

                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan={2} style={{ verticalAlign: 'sub', border: '1px solid #121212', textAlign: "center" }}>
                                                            10. In case of indirect shareholding, control or interest being exercised through intermediary companies, entities or other legal persons or legal arrangements in the chain of ownership or control, names and particulars are as follows:
                                                        </td>
                                                    </tr>

                                                </tr>

                                            </tbody>
                                        </table>
                                    </Wrapper>
                                    <Wrapper className="table-responsive mt-0" >
                                        <table
                                            className="table table-bordered"
                                            style={{ fontFamily: "Montserrat rev=1 !important" }}
                                        >
                                            <thead>
                                                <th style={{ verticalAlign: 'sub', border: '1px solid #121212', textAlign: "center" }}>
                                                    Name
                                                </th>
                                                <th style={{ verticalAlign: 'sub', border: '1px solid #121212', textAlign: "center" }}>
                                                    Legal form (Company/LLP/ Partnership Firm/ Trust/Any other body corporate (to be specified))
                                                </th>
                                                <th style={{ verticalAlign: 'sub', border: '1px solid #121212', textAlign: "center" }}>
                                                    Date of incorporation/ registration
                                                </th>
                                                <th style={{ verticalAlign: 'sub', border: '1px solid #121212', textAlign: "center" }}>
                                                    Name of registration authority
                                                </th>
                                                <th style={{ verticalAlign: 'sub', border: '1px solid #121212', textAlign: "center" }}>
                                                    Business Address
                                                </th>
                                                <th style={{ verticalAlign: 'sub', border: '1px solid #121212', textAlign: "center" }}>
                                                    Country
                                                </th>
                                                <th style={{ verticalAlign: 'sub', border: '1px solid #121212', textAlign: "center" }}>
                                                    Email address
                                                </th>
                                                <th style={{ verticalAlign: 'sub', border: '1px solid #121212', textAlign: "center" }}>
                                                    Percentage of shareholding, control or interest of UBO in the legal person or legal arrangement
                                                </th>
                                                <th style={{ verticalAlign: 'sub', border: '1px solid #121212', textAlign: "center" }}>
                                                    Percentage of shareholding, control or interest of legal person or legal arrangement in the Company
                                                </th>
                                                <th style={{ verticalAlign: 'sub', border: '1px solid #121212', textAlign: "center" }}>
                                                    Identity of Natural Person who ultimately owns or controls the legal person or arrangement
                                                </th>

                                            </thead>

                                            <tbody>
                                                <tr>
                                                    <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}>(a)</td>
                                                    <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}>(b)</td>
                                                    <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}>(c)</td>
                                                    <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}>(d)</td>
                                                    <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}>(e)</td>
                                                    <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}>(f)</td>
                                                    <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}>(g)</td>
                                                    <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}>(h)</td>
                                                    <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}>(i)</td>
                                                    <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}>(j)</td>
                                                    <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}>(k)</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}></td>
                                                    <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}></td>
                                                    <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}></td>
                                                    <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}></td>
                                                    <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}></td>
                                                    <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}></td>
                                                    <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}></td>
                                                    <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}></td>
                                                    <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}></td>
                                                    <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}></td>
                                                    <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}></td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={10} style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}>11. Any other information incidental to or relevant to enable the company to evaluate this matter</td>
                                                </tr>


                                            </tbody>
                                        </table>
                                    </Wrapper>
                                    <div className='mt-5'>
                                        And I make this solemn declaration conscientiously believing the same to be true.
                                    </div>
                                    <div >
                                        This _______ day of ____________, 20____
                                    </div>
                                    <div style={{ textDecoration: "overline" }} className='mt-4'>
                                        Name & signature
                                    </div>
                                    <div className='mt-4'>
                                        * Delete whichever is inapplicable
                                    </div>
                                    <div className='mt-0'>
                                        ** Please provide information required under para 4 if you are a:
                                    </div>
                                    <div style={{ marginLeft: "3rem" }} className='mt-0'>
                                        (i) Legal person, i.e. not an individual or natural person; or
                                    </div>
                                    <div style={{ marginLeft: "3rem" }} className='mt-0'>
                                        (ii) Natural person, but do not yourself hold [ ] % of shares or interest in the company.
                                    </div>
                                    <div className='mt-3'>
                                        Note: 1. The form of declaration may be modified or adapted to the circumstances in which the non-beneficial owner is a body corporate or in which there is more than one non-beneficial owner in respect of a particular share.
                                    </div>
                                    <div className='mt-3'>
                                        2. Please add further columns to the table in para 4 above depending upon the number of ultimate beneficial owners of the company
                                    </div>
                                    <div style={{ fontSize: "10px" }} className='mt-3'>
                                        <i>1 Sub-regulation (7) of regulation 48 defines an ultimate beneficial owner as a natural person who ultimately owns or controls a
                                            company, whether directly or indirectly, through at least twenty five percent shares or voting rights or by exercising effective control
                                            in that company through other means. ‘Control through other means’ may be exercised through a chain of ownership or through close
                                            relatives or associates having significant influence or control over the finances or decisions of the company.</i>
                                    </div>




                                </div>
                            </div>
                        </div>
                    </PDFExport>}
            </div>
            <button disabled={loading} className='btn btn-danger' onClick={(e) => {
                // if(viewPdf)
                //   SetViewPDf(true)
                if (pdfExportComponent.current) {
                    pdfExportComponent.current.save();
                }
            }}>Download PDF</button>
        </div>
    )
}
const PartsWrapper = styled.div`
color: #121212;
text-decoration: underline;
justify-content: center;
display: flex;
margin-right: 15px;
font-size: 17px;
font-weight: bold;
`;
const ScrollWrapper = styled.div`
max-height: 50px;
overflow-y: scroll;
overflow-x: hidden;
::-webkit-scrollbar{
    height: 5px;
    width: 3px;
  }
  
  ::-webkit-scrollbar-track{
    background: #F9F9FB;
  }
  ::-webkit-scrollbar-thumb{
    background: #4E515680;
    border-radius: 5px;
  
  }
`;
const HeadingWrapper = styled.div`
font-size: 17px;
font-weight: bold;
`;
const FormName = styled.div`
color: #121212;
text-decoration: underline;
justify-content: end;
display: flex;
margin-right: 15px;
font-size: 17px;
font-weight: bold;
`;
const Wrapper = styled.div`
table.table-bordered{
    border: 1px solid #121212
     border-color: #121212; 
    // border:1px solid blue;
    // margin-top:20px;
  }
// table.table-bordered > thead > tr > th{
//     // border:1px solid blue;
//     border-color: #121212; 
// }
// table.table-bordered > tbody > tr > td{
//     // border:1px solid blue;
//     borderC-color: #121212; 
// }
 tr td {
    // border:1px solid blue;.
    border-color: #121212; 
}

`;
const WrapperDashed = styled.div`

  td {
    
    border: 1px dashed #dddcdc;
}

`;