import React, { useState, useEffect } from 'react'
import { PDFExport } from "@progress/kendo-react-pdf";
import PageTemplate from "./page-template";
import {
    getCompanies,
} from "../../../store/services/company.service";
import styled from 'styled-components';
import { TiTick } from "react-icons/ti";
import { numberWithCommas } from 'utilities/utilityFunctions';
export const StatutoryForm6 = ({ data, formTemplate = false }) => {
    const pdfExportComponent = React.useRef(null);
    const [CompanyData, setCompanyData] = useState(null)
    const [loading, setLoading] = useState(false);
    const [fontFamilyForStaticData, setFontFamilyForStaticData] = useState('Montserrat, sans-serif')
    const [fontSizeForStaticData, setFontSizeForStaticData] = useState('14px')
    const [fontfamilyforDynimicData, setFontFamilyForDynmicData] = useState('Segoe Print')
    const [fontColor, setFontColor] = useState('')
    const baseEmail = sessionStorage.getItem("email") || "";
    const [governance, setGovernance] = useState({})
    const getAllCompanies = async () => {
        setLoading(true)
        try {
            const response = await getCompanies(baseEmail);
            if (response.status === 200) {
                response.data.data = response.data.data.filter((item) => item?.active === 'Y')
                const parents = response.data.data;
                const company = parents?.find(ite => ite?.code == data?.company_code)
                let Allperson = company.governance && company.governance != "" ? JSON.parse(company.governance) : []
                let sec = Allperson.find(x => x.role == "Company Secretary" && x?.active?.toLowerCase() != 'n')
                let ceo = Allperson.find(x => x.role == "CEO" && x?.active?.toLowerCase() != 'n')


                if (sec) {
                    setGovernance(sec)
                } else if (ceo) {
                    setGovernance(ceo)
                }
                setCompanyData(company)
                setTimeout(() => {
                    setLoading(false)
                }, 1000);
            }
        } catch (error) {
            setLoading(false)
        }
    };
    useEffect(() => {
        getAllCompanies()
    }, [])
    return (
        <div>
            <div
                style={{ fontFamily: "sans-serif " }}
            >

                {formTemplate ?
                    <PDFExport
                        paperSize="A4"
                        margin="1cm"
                        scale={0.6}
                        fileName={`Statutory Form 6`}
                        pageTemplate={PageTemplate}
                        ref={pdfExportComponent}
                    >
                        <div style={{ fontFamily: `${fontFamilyForStaticData}`, fontSize: `${fontSizeForStaticData}`, fontWeight: 500 }}>
                            <FormName>
                                Form 6
                            </FormName>
                            <div className='row'>
                                <div className='col-md-12' >
                                    <HeadingWrapper className='d-flex justify-content-center'>
                                        THE COMPANIES ACT, 2017
                                    </HeadingWrapper>

                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <HeadingWrapper className='d-flex justify-content-center'>
                                        THE COMPANIES REGULATIONS, 2024
                                    </HeadingWrapper>

                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <div style={{ fontSize: '15px', fontWeight: 'bold' }} className='d-flex justify-content-center'>
                                        [Section 443 and Regulations 26 & 30]
                                    </div>

                                </div>
                            </div>

                            <div className='row mt-4'>
                                <div className='col-md-12'>
                                    <HeadingWrapper className='d-flex justify-content-center'>
                                        NOTICE BY A FOREIGN COMPANY ON CEASING TO HAVE ANY PLACE OF BUSINESS IN PAKISTAN
                                    </HeadingWrapper>

                                </div>
                            </div>
                            <PartsWrapper className='mt-4 mb-3'>
                                PART-I
                            </PartsWrapper>
                            <div className='row'>
                                <div className='col-md-12' style={{ padding: '25px 50px', wordWrap: 'break-word' }}>
                                    <div> <i>(Please complete in bold capital letters)</i></div>


                                    <div className='d-flex' >
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>1.1</div>
                                        <div className='align-items-center' style={{ width: '28%', padding: '0px 10px 10px 10px' }}>CUIN (Incorporation Number)</div>
                                        <div className='align-items-center  d-flex'  >{
                                            '1234 552'.split("")?.map((item, idx) => {
                                                if (item == ' ') return
                                                return <div style={{ minWidth: '30px', minHeight: '34px', border: '1px solid #121212', borderLeft: idx != 0 ? "none" : "1px solid #121212", padding: '6px 9px' }}></div>
                                            })
                                        }
                                        </div>
                                    </div>


                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>1.2</div>
                                        <div className='align-items-center' style={{ width: '28%', padding: '0px 10px 10px 10px' }}>Name of the Foreign Company</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '65%', padding: ' 5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                    </div>




                                    <div className='d-flex  mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>1.3</div>
                                        <div className='align-items-center' style={{ width: '20.20%', padding: '0px 20px 10px 10px' }}>Fee Payment Details</div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '5%' }}>1.3.1</div>
                                        <div className='align-items-center' style={{ width: '13.20%', paddingLeft: '20px' }}>Challan Number.</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '17.20%', paddingLeft: '20px', minHeight: '34px' }}></ScrollWrapper>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '5%' }}>1.3.3</div>
                                        <div className='align-items-center' style={{ width: '13.20%', paddingLeft: '20px' }}>Amount (Rs.)</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '19.20%', paddingLeft: '20px', minHeight: '34px' }}></ScrollWrapper>
                                    </div>
                                    <PartsWrapper className='mt-4 mb-3'>
                                        PART-II
                                    </PartsWrapper>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>2.1</div>
                                        <div className='align-items-center' style={{ padding: '0px 10px 10px 10px' }}> The above named company hereby gives you notice, in pursuance of section 443 of the Act, that it intends to cease to have the following place(s) of business in Pakistan</div>
                                    </div>

                                    <WrapperDashed className="table-responsive mt-2" >
                                        <table
                                            className="table table-bordered"
                                        >
                                            <thead>
                                                <th style={{ verticalAlign: 'sub',width:'32%', border: '1px solid #121212' }}>
                                                    Address of the Place
                                                </th>
                                                <th style={{ verticalAlign: 'sub', border: '1px solid #121212' }}>
                                                Status of the place (Principal/Liaison office or other place of business
                                                </th>
                                                <th style={{ verticalAlign: 'sub', border: '1px solid #121212' }}>
                                                Date of cessation* 
                                                </th>
                                            </thead>
                                            <tbody>
                                                <tr >
                                                    <td style={{ minHeight: '24px',border: '1px solid #121212', width: '7%' }}></td>
                                                    <td style={{ minHeight: '24px',border: '1px solid #121212', width: '7%' }}></td>
                                                    <td style={{ minHeight: '24px',border: '1px solid #121212', width: '7%' }}></td>
                                                   
                                                </tr>
                                                <tr >
                                                    <td style={{ minHeight: '24px',border: '1px solid #121212', width: '7%' }}></td>
                                                    <td style={{ minHeight: '24px',border: '1px solid #121212', width: '7%' }}></td>
                                                    <td style={{ minHeight: '24px',border: '1px solid #121212', width: '7%' }}></td>
                                                   
                                                </tr>
                                            </tbody>
                                        </table>
                                    </WrapperDashed>
                                    <div className='d-flex align-items-baseline mt-1'>
                                   <div style={{marginLeft:"8px"}}> *</div><i style={{marginLeft:"76px"}}> (Notice is to be given at least 30 days before the company intends to cease to have its place of business in Pakistan) </i>
                                    </div>

                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}></div>
                                        <div className='align-items-center' style={{ width: '28%', padding: '0px 10px 10px 10px' }}></div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212',borderBottom:'none', width: '8.66%', padding: ' 5px 10px' }}>S. No</ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212',borderLeft:"none",borderBottom:"none", width: '27.66%', padding: ' 5px 10px' }}>Name of Newspaper</ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', borderLeft:"none",borderBottom:"none",width: '27.66%', padding: ' 5px 10px' }}>Date of Advertisement</ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-0'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>2.2</div>
                                        <div className='align-items-center' style={{ width: '28%', padding: '0px 10px 10px 10px' }}> Advertisement made in</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', borderBottom:'none',width: '8.66%', padding: ' 5px 10px' }}>1.</ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212',borderLeft:"none",borderBottom:"none", width: '27.66%', padding: ' 5px 10px' }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', borderLeft:"none",borderBottom:"none",width: '27.66%', padding: ' 5px 10px' }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-0'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}></div>
                                        <div className='align-items-center' style={{ width: '28%', padding: '0px 10px 10px 10px' }}></div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '8.66%', padding: ' 5px 10px' }}>2.</ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212',borderLeft:'none', width: '27.66%', padding: ' 5px 10px' }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212',borderLeft:'none', width: '27.66%', padding: ' 5px 10px' }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-0'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}></div>
                                        <div className='align-items-center' style={{ padding: ' 5px 10px' }}><i>(At least in two daily newspapers circulating in the Province or Provinces in which such place or places of business are situate)</i></div>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}></div>
                                        <div className='align-items-center' style={{ width: '55%', padding: ' 5px 10px' }}> </div>
                                        <div className='align-items-center text-center' style={{ width: '8%', padding: ' 5px 10px' }}>dd</div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center text-center' style={{ width: '8%', padding: ' 5px 10px' }}>mm</div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center text-center' style={{ width: '16%', padding: ' 5px 10px' }}>yyyy</div>
                                        <div></div>
                                    </div>
                                    <div className='d-flex '>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>2.3</div>
                                        <div className='align-items-center' style={{ width: '55%', padding: '0px 10px 10px 10px' }}>Date of advertisement</div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ border: '1px solid #121212', width: '4%', padding: ' 5px 10px' }}> </div>
                                        <div className='align-items-center' style={{ paddingLeft: '8px', width: '3%' }}>-</div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ border: '1px solid #121212', width: '4%', padding: ' 5px 10px', borderRight: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ paddingLeft: '8px', width: '3%' }}>-</div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', border: '1px solid #121212', }}> </div>
                                    </div>
                                    <PartsWrapper className='mt-4 mb-4'>
                                        PART-III
                                    </PartsWrapper>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>3.1</div>
                                        <div className='align-items-center' style={{ width: '28%', padding: '0px 10px 10px 10px' }}> Signature </div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '65%', padding: ' 5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>3.2</div>
                                        <div className='align-items-center' style={{ width: '28%', padding: '0px 10px 10px 10px' }}> Name of Authorized Officer/ Authorized Intermediary</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '65%', padding: ' 5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>3.3</div>
                                        <div className='align-items-center' style={{ width: '28%', padding: '0px 10px 10px 10px' }}>Contact Number of Authorized Officer</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '65%', padding: ' 5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>3.4</div>
                                        <div className='align-items-center' style={{ width: '28%', padding: '0px 10px 10px 10px' }}>Registration Number of Authorized Intermediary, if applicable</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '65%', padding: ' 5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}></div>
                                        <div className='align-items-center' style={{ width: '55%', padding: ' 5px 10px' }}> </div>
                                        <div className='align-items-center text-center' style={{ width: '8%', padding: ' 5px 10px' }}>dd</div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center text-center' style={{ width: '8%', padding: ' 5px 10px' }}>mm</div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center text-center' style={{ width: '16%', padding: ' 5px 10px' }}>yyyy</div>
                                        <div></div>
                                    </div>
                                    <div className='d-flex '>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>3.5</div>
                                        <div className='align-items-center' style={{ width: '55%', padding: '0px 10px 10px 10px' }}>Date</div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ border: '1px solid #121212', width: '4%', padding: ' 5px 10px' }}> </div>
                                        <div className='align-items-center' style={{ paddingLeft: '8px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ border: '1px solid #121212', width: '4%', padding: ' 5px 10px', borderRight: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ paddingLeft: '8px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', border: '1px solid #121212', }}> </div>
                                    </div>

                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px',fontWeight:"bold"}}>Enclosure:</div>
                                    </div>
                                        <div className='d-flex align-items-baseline mt-2' style={{ paddingLeft: '2px' }}><div style={{marginLeft:"8px"}}>1.</div> <div style={{marginLeft:"25px"}}>Copy of advertisement as published in two newspapers (one in Urdu and one in English language).</div> </div>
                                        <div className='d-flex align-items-baseline' style={{ paddingLeft: '2px' }}><div style={{marginLeft:"8px"}}>2.</div> <div style={{marginLeft:"22px"}}>Copy of letter from BOI regarding close of Business, if available.</div></div>
                                        <div className='d-flex align-items-baseline' style={{ paddingLeft: '2px' }}><div style={{marginLeft:"8px"}}>3.</div> <div style={{marginLeft:"22px"}}>Original challan or other evidence of payment of fee specified in Seventh Schedule of the Act (not applicable in case of online filing)</div></div>







                        </div>
                        </div></div>
                        
                    </PDFExport>
                    : <PDFExport
                        paperSize="A4"
                        margin="1cm"
                        scale={0.6}
                        fileName={`Statutory Form 6`}
                        pageTemplate={PageTemplate}
                        ref={pdfExportComponent}
                    >
                     <div style={{ fontFamily: `${fontFamilyForStaticData}`, fontSize: `${fontSizeForStaticData}`, fontWeight: 500 }}>
                            <FormName>
                                Form 6
                            </FormName>
                            <div className='row'>
                                <div className='col-md-12' >
                                    <HeadingWrapper className='d-flex justify-content-center'>
                                        THE COMPANIES ACT, 2017
                                    </HeadingWrapper>

                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <HeadingWrapper className='d-flex justify-content-center'>
                                        THE COMPANIES REGULATIONS, 2024
                                    </HeadingWrapper>

                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <div style={{ fontSize: '15px', fontWeight: 'bold' }} className='d-flex justify-content-center'>
                                        [Section 443 and Regulations 26 & 30]
                                    </div>

                                </div>
                            </div>

                            <div className='row mt-4'>
                                <div className='col-md-12'>
                                    <HeadingWrapper className='d-flex justify-content-center'>
                                        NOTICE BY A FOREIGN COMPANY ON CEASING TO HAVE ANY PLACE OF BUSINESS IN PAKISTAN
                                    </HeadingWrapper>

                                </div>
                            </div>
                            <PartsWrapper className='mt-4 mb-3'>
                                PART-I
                            </PartsWrapper>
                            <div className='row'>
                                <div className='col-md-12' style={{ padding: '25px 50px', wordWrap: 'break-word' }}>
                                    <div> <i>(Please complete in bold capital letters)</i></div>


                                    <div className='d-flex' >
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>1.1</div>
                                        <div className='align-items-center' style={{ width: '28%', padding: '0px 10px 10px 10px' }}>CUIN (Incorporation Number)</div>
                                        <div className='align-items-center  d-flex'  >{
                                            '1234 552'.split("")?.map((item, idx) => {
                                                if (item == ' ') return
                                                return <div style={{ minWidth: '30px', minHeight: '34px', border: '1px solid #121212', borderLeft: idx != 0 ? "none" : "1px solid #121212", padding: '6px 9px' }}></div>
                                            })
                                        }
                                        </div>
                                    </div>


                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>1.2</div>
                                        <div className='align-items-center' style={{ width: '28%', padding: '0px 10px 10px 10px' }}>Name of the Foreign Company</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '65%', padding: ' 5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                    </div>




                                    <div className='d-flex  mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>1.3</div>
                                        <div className='align-items-center' style={{ width: '20.20%', padding: '0px 20px 10px 10px' }}>Fee Payment Details</div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '5%' }}>1.3.1</div>
                                        <div className='align-items-center' style={{ width: '13.20%', paddingLeft: '20px' }}>Challan Number.</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '17.20%', paddingLeft: '20px', minHeight: '34px' }}></ScrollWrapper>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '5%' }}>1.3.3</div>
                                        <div className='align-items-center' style={{ width: '13.20%', paddingLeft: '20px' }}>Amount (Rs.)</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '19.20%', paddingLeft: '20px', minHeight: '34px' }}></ScrollWrapper>
                                    </div>
                                    <PartsWrapper className='mt-4 mb-3'>
                                        PART-II
                                    </PartsWrapper>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>2.1</div>
                                        <div className='align-items-center' style={{ padding: '0px 10px 10px 10px' }}> The above named company hereby gives you notice, in pursuance of section 443 of the Act, that it intends to cease to have the following place(s) of business in Pakistan</div>
                                    </div>

                                    <WrapperDashed className="table-responsive mt-2" >
                                        <table
                                            className="table table-bordered"
                                        >
                                            <thead>
                                                <th style={{ verticalAlign: 'sub',width:'32%', border: '1px solid #121212' }}>
                                                    Address of the Place
                                                </th>
                                                <th style={{ verticalAlign: 'sub', border: '1px solid #121212' }}>
                                                Status of the place (Principal/Liaison office or other place of business
                                                </th>
                                                <th style={{ verticalAlign: 'sub', border: '1px solid #121212' }}>
                                                Date of cessation* 
                                                </th>
                                            </thead>
                                            <tbody>
                                                <tr >
                                                    <td style={{ minHeight: '24px',border: '1px solid #121212', width: '7%' }}></td>
                                                    <td style={{ minHeight: '24px',border: '1px solid #121212', width: '7%' }}></td>
                                                    <td style={{ minHeight: '24px',border: '1px solid #121212', width: '7%' }}></td>
                                                   
                                                </tr>
                                                <tr >
                                                    <td style={{ minHeight: '24px',border: '1px solid #121212', width: '7%' }}></td>
                                                    <td style={{ minHeight: '24px',border: '1px solid #121212', width: '7%' }}></td>
                                                    <td style={{ minHeight: '24px',border: '1px solid #121212', width: '7%' }}></td>
                                                   
                                                </tr>
                                            </tbody>
                                        </table>
                                    </WrapperDashed>
                                    <div className='d-flex align-items-baseline mt-1'>
                                   <div style={{marginLeft:"8px"}}> *</div><i style={{marginLeft:"76px"}}> (Notice is to be given at least 30 days before the company intends to cease to have its place of business in Pakistan) </i>
                                    </div>

                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}></div>
                                        <div className='align-items-center' style={{ width: '28%', padding: '0px 10px 10px 10px' }}></div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212',borderBottom:'none', width: '8.66%', padding: ' 5px 10px' }}>S. No</ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212',borderLeft:"none",borderBottom:"none", width: '27.66%', padding: ' 5px 10px' }}>Name of Newspaper</ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', borderLeft:"none",borderBottom:"none",width: '27.66%', padding: ' 5px 10px' }}>Date of Advertisement</ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-0'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>2.2</div>
                                        <div className='align-items-center' style={{ width: '28%', padding: '0px 10px 10px 10px' }}> Advertisement made in</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', borderBottom:'none',width: '8.66%', padding: ' 5px 10px' }}>1.</ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212',borderLeft:"none",borderBottom:"none", width: '27.66%', padding: ' 5px 10px' }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', borderLeft:"none",borderBottom:"none",width: '27.66%', padding: ' 5px 10px' }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-0'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}></div>
                                        <div className='align-items-center' style={{ width: '28%', padding: '0px 10px 10px 10px' }}></div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '8.66%', padding: ' 5px 10px' }}>2.</ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212',borderLeft:'none', width: '27.66%', padding: ' 5px 10px' }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212',borderLeft:'none', width: '27.66%', padding: ' 5px 10px' }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-0'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}></div>
                                        <div className='align-items-center' style={{ padding: ' 5px 10px' }}><i>(At least in two daily newspapers circulating in the Province or Provinces in which such place or places of business are situate)</i></div>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}></div>
                                        <div className='align-items-center' style={{ width: '55%', padding: ' 5px 10px' }}> </div>
                                        <div className='align-items-center text-center' style={{ width: '8%', padding: ' 5px 10px' }}>dd</div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center text-center' style={{ width: '8%', padding: ' 5px 10px' }}>mm</div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center text-center' style={{ width: '16%', padding: ' 5px 10px' }}>yyyy</div>
                                        <div></div>
                                    </div>
                                    <div className='d-flex '>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>2.3</div>
                                        <div className='align-items-center' style={{ width: '55%', padding: '0px 10px 10px 10px' }}>Date of advertisement</div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ border: '1px solid #121212', width: '4%', padding: ' 5px 10px' }}> </div>
                                        <div className='align-items-center' style={{ paddingLeft: '8px', width: '3%' }}>-</div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ border: '1px solid #121212', width: '4%', padding: ' 5px 10px', borderRight: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ paddingLeft: '8px', width: '3%' }}>-</div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', border: '1px solid #121212', }}> </div>
                                    </div>
                                    <PartsWrapper className='mt-4 mb-4'>
                                        PART-III
                                    </PartsWrapper>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>3.1</div>
                                        <div className='align-items-center' style={{ width: '28%', padding: '0px 10px 10px 10px' }}> Signature </div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '65%', padding: ' 5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>3.2</div>
                                        <div className='align-items-center' style={{ width: '28%', padding: '0px 10px 10px 10px' }}> Name of Authorized Officer/ Authorized Intermediary</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '65%', padding: ' 5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>3.3</div>
                                        <div className='align-items-center' style={{ width: '28%', padding: '0px 10px 10px 10px' }}>Contact Number of Authorized Officer</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '65%', padding: ' 5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>3.4</div>
                                        <div className='align-items-center' style={{ width: '28%', padding: '0px 10px 10px 10px' }}>Registration Number of Authorized Intermediary, if applicable</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '65%', padding: ' 5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}></div>
                                        <div className='align-items-center' style={{ width: '55%', padding: ' 5px 10px' }}> </div>
                                        <div className='align-items-center text-center' style={{ width: '8%', padding: ' 5px 10px' }}>dd</div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center text-center' style={{ width: '8%', padding: ' 5px 10px' }}>mm</div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center text-center' style={{ width: '16%', padding: ' 5px 10px' }}>yyyy</div>
                                        <div></div>
                                    </div>
                                    <div className='d-flex '>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>3.5</div>
                                        <div className='align-items-center' style={{ width: '55%', padding: '0px 10px 10px 10px' }}>Date</div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ border: '1px solid #121212', width: '4%', padding: ' 5px 10px' }}> </div>
                                        <div className='align-items-center' style={{ paddingLeft: '8px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ border: '1px solid #121212', width: '4%', padding: ' 5px 10px', borderRight: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ paddingLeft: '8px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', border: '1px solid #121212', }}> </div>
                                    </div>

                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px',fontWeight:"bold"}}>Enclosure:</div>
                                    </div>
                                        <div className='d-flex align-items-baseline mt-2' style={{ paddingLeft: '2px' }}><div style={{marginLeft:"8px"}}>1.</div> <div style={{marginLeft:"25px"}}>Copy of advertisement as published in two newspapers (one in Urdu and one in English language).</div> </div>
                                        <div className='d-flex align-items-baseline' style={{ paddingLeft: '2px' }}><div style={{marginLeft:"8px"}}>2.</div> <div style={{marginLeft:"22px"}}>Copy of letter from BOI regarding close of Business, if available.</div></div>
                                        <div className='d-flex align-items-baseline' style={{ paddingLeft: '2px' }}><div style={{marginLeft:"8px"}}>3.</div> <div style={{marginLeft:"22px"}}>Original challan or other evidence of payment of fee specified in Seventh Schedule of the Act (not applicable in case of online filing)</div></div>







                        </div>
                        </div></div>
                    </PDFExport>}
            </div>
            <button disabled={loading} className='btn btn-danger' onClick={(e) => {
                // if(viewPdf)
                //   SetViewPDf(true)
                if (pdfExportComponent.current) {
                    pdfExportComponent.current.save();
                }
            }}>Download PDF</button>
        </div>
    )
}
const PartsWrapper = styled.div`
color: #121212;
text-decoration: underline;
justify-content: center;
display: flex;
margin-right: 15px;
font-size: 17px;
font-weight: bold;
`;
const ScrollWrapper = styled.div`
max-height: 50px;
overflow-y: scroll;
overflow-x: hidden;
::-webkit-scrollbar{
    height: 5px;
    width: 3px;
  }
  
  ::-webkit-scrollbar-track{
    background: #F9F9FB;
  }
  ::-webkit-scrollbar-thumb{
    background: #4E515680;
    border-radius: 5px;
  
  }
`;
const HeadingWrapper = styled.div`
font-size: 17px;
font-weight: bold;
`;
const FormName = styled.div`
color: #121212;
text-decoration: underline;
justify-content: end;
display: flex;
margin-right: 15px;
font-size: 17px;
font-weight: bold;
`;
const Wrapper = styled.div`
table.table-bordered{
    border: 1px solid #121212
     border-color: #121212; 
    // border:1px solid blue;
    // margin-top:20px;
  }
// table.table-bordered > thead > tr > th{
//     // border:1px solid blue;
//     border-color: #121212; 
// }
// table.table-bordered > tbody > tr > td{
//     // border:1px solid blue;
//     borderC-color: #121212; 
// }
 tr td {
    // border:1px solid blue;.
    border-color: #121212; 
}

`;
const WrapperDashed = styled.div`

  td {
    
    // border: 1px dashed #dddcdc;
}

`;