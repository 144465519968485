import React, { useState, useEffect } from 'react'
import { PDFExport } from "@progress/kendo-react-pdf";
import PageTemplate from "./page-template";
import {
    getCompanies,
} from "../../../store/services/company.service";
import styled from 'styled-components';
import { TiTick } from "react-icons/ti";
import { numberWithCommas } from 'utilities/utilityFunctions';
export const App6 = ({ data, formTemplate = false }) => {
    const pdfExportComponent = React.useRef(null);
    const [CompanyData, setCompanyData] = useState(null)
    const [loading, setLoading] = useState(false);
    const [fontFamilyForStaticData, setFontFamilyForStaticData] = useState('Montserrat, sans-serif')
    const [fontSizeForStaticData, setFontSizeForStaticData] = useState('14px')
    const [fontfamilyforDynimicData, setFontFamilyForDynmicData] = useState('Segoe Print')
    const [fontColor, setFontColor] = useState('')
    const baseEmail = sessionStorage.getItem("email") || "";
    const [governance, setGovernance] = useState({})
    const getAllCompanies = async () => {
        setLoading(true)
        try {
            const response = await getCompanies(baseEmail);
            if (response.status === 200) {
                response.data.data = response.data.data.filter((item) => item?.active === 'Y')
                const parents = response.data.data;
                const company = parents?.find(ite => ite?.code == data?.company_code)
                let Allperson = company.governance && company.governance != "" ? JSON.parse(company.governance) : []
                let sec = Allperson.find(x => x.role == "Company Secretary" && x?.active?.toLowerCase() != 'n')
                let ceo = Allperson.find(x => x.role == "CEO" && x?.active?.toLowerCase() != 'n')


                if (sec) {
                    setGovernance(sec)
                } else if (ceo) {
                    setGovernance(ceo)
                }
                setCompanyData(company)
                setTimeout(() => {
                    setLoading(false)
                }, 1000);
            }
        } catch (error) {
            setLoading(false)
        }
    };
    useEffect(() => {
        getAllCompanies()
    }, [])
    return (
        <div>
            <div
                style={{ fontFamily: "sans-serif " }}
            >

                {formTemplate ?
                    <PDFExport
                        paperSize="A4"
                        margin="1cm"
                        scale={0.6}
                        fileName={`Statutory Requirment Form 7`}
                        pageTemplate={PageTemplate}
                        ref={pdfExportComponent}
                    >
                        <div style={{ fontFamily: `${fontFamilyForStaticData}`, fontSize: `${fontSizeForStaticData}`, fontWeight: 500 }}>
                            <FormName>
                                App-6
                            </FormName>
                            <div className='row'>
                                <div className='col-md-12' >
                                    <HeadingWrapper className='d-flex justify-content-center'>
                                        THE COMPANIES ACT, 2017 THE COMPANIES REGULATIONS, 2024
                                    </HeadingWrapper>

                                </div>
                            </div>

                            <div className='row'>
                                <div className='col-md-12'>
                                    <div style={{ fontSize: '15px', fontWeight: 'bold' }} className='d-flex justify-content-center'>
                                        [Regulations 138, 140, 143 & 30]
                                    </div>
                                </div>
                            </div>

                            <div className='row mt-4'>
                                <div className='col-md-12'>
                                    <HeadingWrapper className='text-center d-flex justify-content-center'>
                                        APPLICATION FOR REGISTRATION AS A GROUP, ALTERATION THEREIN OR DESIGNATION AS A GROUP FOR TAXATION
                                    </HeadingWrapper>
                                </div>
                            </div>
                            <PartsWrapper className='mt-4 mb-3'>
                                PART-I
                            </PartsWrapper>
                            <div className='row'>
                                <div className='col-md-12' style={{ padding: '25px 50px', wordWrap: 'break-word' }}>
                                    <div> <i>(Please complete in bold capital letters)</i></div>


                                    <div className='d-flex' >
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>1.1</div>
                                        <div className='align-items-center' style={{ width: '28%', padding: '0px 10px 10px 10px' }}>CUIN (Incorporation Number)</div>
                                        <div className='align-items-center  d-flex'  >{
                                            '1234 552'.split("")?.map((item, idx) => {
                                                if (item == ' ') return
                                                return <div style={{ minWidth: '30px', minHeight: '34px', border: '1px solid #121212', borderLeft: idx != 0 ? "none" : "1px solid #121212", padding: '6px 9px' }}></div>
                                            })
                                        }
                                        </div>
                                    </div>



                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>1.2</div>
                                        <div className='align-items-center' style={{ width: '29%', padding: '0px 10px 10px 10px' }}> Name of holding company</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '68%', padding: '0px 10px 10px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                    </div>




                                    <div className='d-flex  mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>1.3</div>
                                        <div className='align-items-center' style={{ width: '20.20%', padding: '0px 10px 10px 10px' }}>Fee Payment Details</div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '5%' }}>1.3.1</div>
                                        <div className='align-items-center' style={{ width: '13.20%', paddingLeft: '20px' }}>Challan No.</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '17.20%', paddingLeft: '20px', minHeight: '34px' }}></ScrollWrapper>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '5%' }}>1.3.2</div>
                                        <div className='align-items-center' style={{ width: '13.20%', paddingLeft: '20px' }}>Amount(Rs.)</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '23.20%', paddingLeft: '20px', minHeight: '34px' }}></ScrollWrapper>
                                    </div>
                                    <PartsWrapper className='mt-4 mb-0'>
                                        PART-II
                                    </PartsWrapper>
                                    <div className='row mt-0'>
                                        <div className='col-md-12'>
                                            <HeadingWrapper style={{fontSize:"15px"}} className='text-center d-flex justify-content-center'>
                                                <i>(This part is applicable for registration as a Group)</i>
                                            </HeadingWrapper>
                                        </div>
                                    </div>
                                    <div className='mt-2'>
                                        M/s. <span style={{ textDecoration: "underline" }}>(Name of holding company along-with its group registration number)</span>, hold shares in the
                                        subsidiary companies (as per the statement of shareholding attached as Appendix-A), all locally
                                        incorporated under the Companies Act, 2017, with the same accounting year end and intend to
                                        constitute a Group comprising of following companies:
                                    </div>
                                    <div style={{marginLeft:"3rem"}} className='mt-1 d-flex'>
                                        <div style={{marginRight:"1rem"}}>i.</div><div style={{ marginLeft: "10px" }}>__________________________(holding company)</div>
                                    </div>
                                    <div style={{marginLeft:"3rem"}} className='mt-1 d-flex'>
                                        <div style={{marginRight:"0.8rem"}}>ii.</div><div style={{ marginLeft: "10px" }}>__________________________</div>
                                    </div>
                                    <div style={{marginLeft:"3rem"}} className='mt-1 d-flex'>
                                        <div style={{marginRight:"0.6rem"}}>iii.</div><div style={{ marginLeft: "10px" }}>__________________________</div>
                                    </div>
                                    <PartsWrapper className='mt-4 mb-0'>
                                        PART-III
                                    </PartsWrapper>
                                    <div className='row mt-0'>
                                        <div className='col-md-12'>
                                            <HeadingWrapper style={{fontSize:"15px"}} className='text-center d-flex justify-content-center'>
                                                <i>(This part is applicable for alteration in a Group)</i>
                                            </HeadingWrapper>
                                        </div>
                                    </div>
                                    <div className='d-flex'>
                                        <div >1.</div><div style={{ marginLeft: "10px" }}>It is hereby informed that the composition of the Group registered under Sr. No.
                                            ________________ dated ___________ has been changed as under:</div>
                                    </div>
                                    <div className='d-flex' style={{marginLeft:"3rem"}}>
                                        <div style={{marginRight:"1rem"}}>i.</div><div style={{ marginLeft: "10px" }}>_________________________(holding company)</div>
                                    </div>
                                    <div className='d-flex' style={{marginLeft:"3rem"}}>
                                        <div style={{marginRight:"0.8rem"}} >ii.</div><div style={{ marginLeft: "10px" }}>_________________________</div>
                                    </div>
                                    <div className='d-flex' style={{marginLeft:"3rem"}}>
                                        <div style={{marginRight:"0.6rem"}} >iii.</div><div style={{ marginLeft: "10px" }}>_________________________</div>
                                    </div>
                                    <div className='d-flex'>
                                        <div>2.</div><div style={{ marginLeft: "10px" }}>
                                            Appendix-A of revised statement of shareholding and original certificate of registration of a
                                            Group/ original certificate of change in the composition of a Group are attached herewith.</div>
                                    </div>

                                    <PartsWrapper className='mt-4 mb-0'>
                                        PART-IV
                                    </PartsWrapper>
                                    <div className='row mt-0'>
                                        <div className='col-md-12'>
                                            <HeadingWrapper style={{fontSize:"15px"}} className='text-center d-flex justify-content-center'>
                                                <i>(This part is applicable for certification of group taxation)</i>
                                            </HeadingWrapper>
                                        </div>
                                    </div>
                                    <div className='mt-2'>
                                    M/s <span style={{textDecoration:"underline"}}>(Name of holding company)</span> hold 100% shares in the following subsidiary companies, all 
                                    locally incorporated under the Companies Act 2017, having similar year end, constitute a Group
                                    </div>
                                    <div className='mt-2'>
                                        <div style={{marginLeft:"3rem"}} className='d-flex '>
                                        <div style={{marginRight:"1rem"}}>i.</div><div style={{ marginLeft: "10px" }}>_________________________</div>
                                        </div>
                                        <div style={{marginLeft:"3rem"}} className='d-flex '>
                                        <div style={{marginRight:"0.8rem"}}>ii.</div><div style={{ marginLeft: "10px" }}>_________________________</div>
                                        </div>
                                        <div style={{marginLeft:"3rem"}} className='d-flex '>
                                        <div style={{marginRight:"0.6rem"}}>iii.</div><div style={{ marginLeft: "10px" }}>_________________________</div>
                                        </div>
                                    </div>
                                    <div className='mt-2'>
                                    and intend to opt to be taxed as one fiscal unit for the purpose of Group Taxation under section 59AA 
                                    of the Income Tax Ordinance, 2001. The details of investment of the holding company in the share 
                                    capital of the subsidiary companies is given hereunder:
                                    </div>
                                    <Wrapper className="table-responsive mt-0" >
                                        <table
                                            className="table table-bordered"
                                            style={{ fontFamily: "Montserrat rev=1 !important" }}
                                        >
                                            <tbody>
                                                <tr>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}>S. No.</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}>Name of subsidiary(ies) and CUIN</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}>No. of shares issued by the company</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}>Par value</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}>Amount of total Paid-up Capital(Rs.)</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}>No. of shares held by the above-named holding company</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}>% of shareholding of above-named holding company in the subsidiary</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}>Date(s) of acquisition of shares </td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}>Relevant evidence (Form A or any other document) </td>
                                                </tr>
                                                <tr>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}></td>
                                                </tr>
                                               
                                            </tbody>
                                        </table>
                                    </Wrapper>
                                    <PartsWrapper className='mt-4 mb-0'>
                                        PART-V
                                    </PartsWrapper>
                                    <div className='row mt-0'>
                                        <div className='col-md-12'>
                                            <HeadingWrapper style={{fontSize:"15px"}} className='text-center d-flex justify-content-center'>
                                                <i>(This part is applicable for certification of group relief)</i>
                                            </HeadingWrapper>
                                        </div>
                                    </div>
                                    <div className='mt-3'>
                                    M/s ______<span style={{textDecoration:"underline"}}>(Name of holding company)</span>_______ along with the following subsidiary companies

                                    </div>
                                    <div style={{ marginLeft: "15px" }} className='mt-2'>
                                        <div className='d-flex' style={{marginLeft:"3rem"}}>
                                        <div style={{marginRight:"1rem"}}>i.</div><div style={{ marginLeft: "10px" }}>_________________________</div>
                                        </div>
                                        <div className='d-flex' style={{marginLeft:"3rem"}}>
                                        <div style={{marginRight:"0.8rem"}}>ii.</div><div style={{ marginLeft: "10px" }}>_________________________</div>
                                        </div>
                                        <div className='d-flex' style={{marginLeft:"3rem"}}>
                                        <div style={{marginRight:"0.6rem"}}>iii.</div><div style={{ marginLeft: "10px" }}>_________________________</div>
                                        </div>
                                    </div>
                                    <div>
                                    locally incorporated under the Companies Act 2017, comprise a group of companies and intends to 
                                    avail Group Relief under section 59B of the Income Tax Ordinance, 2001 on the ground that:
                                    </div>
                                    <div style={{ marginLeft: "15px" }} className='mt-2'>
                                        <div className='d-flex' style={{marginLeft:"3rem"}}>
                                        <div>a.</div><div style={{ marginLeft: "10px" }}> that the holding company namely; M/s.__________________________________ is listed on stock exchange in Pakistan and hold ______% (55% or more) shares in _______________________ (subsidiary company(ies)); or</div>
                                        </div>
                                        <div className='d-flex' style={{marginLeft:"3rem"}}>
                                        <div>b.</div><div style={{ marginLeft: "10px" }}> that the holding company namely; M/s._______________________________________ directly hold _______% (75% or more) of the share capital of the subsidiary companies namely:</div>
                                        </div>
                                    </div>
                                    <div style={{ marginLeft: "3rem" }} className='mt-2'>
                                        <div className='d-flex' style={{marginLeft:"3rem"}}>
                                        <div style={{marginRight:"1rem"}}>i.</div><div style={{ marginLeft: "10px" }}>______________________</div>
                                        </div>
                                        <div className='d-flex' style={{marginLeft:"3rem"}}>
                                        <div style={{marginRight:"0.8rem"}}>ii.</div><div style={{ marginLeft: "10px" }}>______________________</div>
                                        </div>
                                        <div className='d-flex' style={{marginLeft:"3rem"}}>
                                        <div style={{marginRight:"0.6rem"}}>iii.</div><div style={{ marginLeft: "10px" }}>______________________</div>
                                        </div>
                                    </div>

                                    <PartsWrapper className='mt-4 mb-3'>
                                        PART-VI
                                    </PartsWrapper>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ padding: '5px 10px', width: '3%' }}>2.1</div>
                                        <div className='align-items-center' style={{ padding: '5px 10px', fontWeight: "bold" }}>Declaration</div>
                                    </div>
                                    <div className='d-flex'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '1%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ padding: ' 0px 10px 10px 10px' }}>I do hereby solemnly, and sincerely declare that the information provided in the form is:</div>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '1%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '0%' }}></div>
                                        <div className='align-items-start d-flex' style={{ padding: ' 5px 10px', gap: "12px" }}><div>
                                            (i)</div>
                                            <div style={{ marginLeft: '14px' }}>true and correct to the best of my knowledge, in consonance with the record as maintained by the Company and nothing has been concealed; and </div>
                                        </div>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '1%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '1%' }}></div>
                                        <div className='align-items-start d-flex' style={{ padding: ' 5px 10px', gap: "12px" }}><div>
                                            (ii)</div>
                                            <div style={{ marginLeft: '11px' }}>hereby reported after complying with and fulfilling all requirements under the relevant provisions of law, rules, regulations, directives, circulars and notifications whichever is applicable.</div>
                                        </div>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>2.2</div>
                                        <div className='align-items-center' style={{ width: '28%', padding: '0px 10px 10px 10px' }}> Name of applicant / authorized person</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '32.5%', padding: ' 5px 10px', }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', borderLeft: "none", width: '32.5%', padding: ' 5px 10px', }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>2.3</div>
                                        <div className='align-items-center' style={{ width: '28%', padding: '0px 10px 10px 10px' }}>Signatures </div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '65%', padding: ' 5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ width: '55%', padding: ' 5px 10px' }}> </div>
                                        <div className='align-items-center text-center' style={{ width: '8%', padding: ' 5px' }}> Day</div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center text-center' style={{ width: '8%', padding: ' 5px' }}> Month</div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center text-center' style={{ width: '16%', padding: ' 5px' }}>Year </div>
                                        <div></div>
                                    </div>
                                    <div className='d-flex '>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>2.4</div>
                                        <div className='align-items-center' style={{ width: '55%', padding: ' 0px 10px 10px 10px' }}>Date</div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ border: '1px solid #121212', width: '4%', padding: ' 5px 10px' }}> </div>
                                        <div className='align-items-center' style={{ paddingLeft: '8px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ border: '1px solid #121212', width: '4%', padding: ' 5px 10px', borderRight: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ paddingLeft: '8px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', border: '1px solid #121212', }}> </div>
                                    </div>

                                    <div className='d-flex my-2' style={{ fontWeight: "bold" }}>
                                        Enclosure: <i>As per Regulations 134 to 139, as applicable.</i>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </PDFExport>
                    : <PDFExport
                        paperSize="A4"
                        margin="1cm"
                        scale={0.6}
                        fileName={`Statutory Requirment Form 7`}
                        pageTemplate={PageTemplate}
                        ref={pdfExportComponent}
                    >
                        <div style={{ fontFamily: `${fontFamilyForStaticData}`, fontSize: `${fontSizeForStaticData}`, fontWeight: 500 }}>
                            <FormName>
                                Form - 7
                            </FormName>
                            <div className='row'>
                                <div className='col-md-12' >
                                    <HeadingWrapper className='d-flex justify-content-center'>
                                        THE COMPANIES ACT, 2017
                                    </HeadingWrapper>

                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <HeadingWrapper className='d-flex justify-content-center'>
                                        THE COMPANIES REGULATIONS, 2024
                                    </HeadingWrapper>

                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <div style={{ fontSize: '15px', fontWeight: 'bold' }} className='d-flex justify-content-center'>
                                        [Section 85 and Regulation 30]
                                    </div>
                                </div>
                            </div>

                            <div className='row mt-4'>
                                <div className='col-md-12'>
                                    <HeadingWrapper className='d-flex justify-content-center'>
                                        NOTICE OF ALTERATION IN SHARE CAPITAL
                                    </HeadingWrapper>
                                </div>
                            </div>
                            <PartsWrapper className='mt-4 mb-3'>
                                Part - I
                            </PartsWrapper>
                            <div className='row'>
                                <div className='col-md-12' style={{ padding: '25px 50px', wordWrap: 'break-word' }}>
                                    <div style={{ paddingLeft: '10px' }}> (Please complete in bold capital letters)</div>


                                    <div className='d-flex' >
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>1.1</div>
                                        <div className='align-items-center' style={{ width: '28%', padding: ' 5px 10px' }}>CUIN (Registration Number)  </div>
                                        <div className='align-items-center  d-flex'  >{
                                            '1234 552'.split("")?.map((item, idx) => {
                                                if (item == ' ') return
                                                return <div style={{ minWidth: '30px', minHeight: '34px', border: '1px solid #121212', borderLeft: idx != 0 ? "none" : "1px solid #121212", padding: '6px 9px' }}></div>
                                            })
                                        }
                                        </div>
                                    </div>


                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>1.2</div>
                                        <div className='align-items-center' style={{ width: '28%', padding: ' 5px 10px' }}>Name of the Company  </div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '65%', padding: ' 5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                    </div>




                                    <div className='d-flex  mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>1.3</div>
                                        <div className='align-items-center' style={{ width: '20.20%', paddingLeft: '20px' }}>Filing Fee payment Details*</div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '5%' }}>1.3.1</div>
                                        <div className='align-items-center' style={{ width: '13.20%', paddingLeft: '20px' }}>Challan No.</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '17.20%', paddingLeft: '20px', minHeight: '34px' }}></ScrollWrapper>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '5%' }}>1.3.2</div>
                                        <div className='align-items-center' style={{ width: '13.20%', paddingLeft: '20px' }}>Amount</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '19.20%', paddingLeft: '20px', minHeight: '34px' }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex  mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', }}>
                                            <i>* fee for increase in authorized capital plus filing fee of this form. Fee for increase in authorized capital can be calculated through <strong>authorized share capital fee calculator</strong> available on SECP website (www.secp.gov.pk)</i>
                                        </div>
                                    </div>
                                    <div className='d-flex  mt-2'>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '69%', borderBottom: "none", paddingLeft: '20px', minHeight: '34px', fontWeight: "bold" }}>1.4 Notice of —</ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', borderLeft: "none", borderBottom: "none", width: '21%', paddingLeft: '20px', minHeight: '34px' }}>Please tick the relevant box</ScrollWrapper>
                                    </div>
                                    <div className='d-flex'>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '21%', paddingLeft: '20px', borderBottom: "none", minHeight: '34px', fontWeight: "bold" }}>Part II</ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '34.5%', borderLeft: "none", borderBottom: "none", paddingLeft: '20px', minHeight: '34px' }}>increase in authorized capital</ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '34.5%', borderLeft: "none", borderBottom: "none", paddingLeft: '20px', minHeight: '34px' }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex'>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '21%', paddingLeft: '20px', borderBottom: "none", minHeight: '34px', fontWeight: "bold" }}>Part III </ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '34.5%', borderLeft: "none", borderBottom: "none", paddingLeft: '20px', minHeight: '34px' }}>consolidation, division or sub-division of shares</ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '34.5%', borderLeft: "none", borderBottom: "none", paddingLeft: '20px', minHeight: '34px' }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex'>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '21%', paddingLeft: '20px', minHeight: '34px', fontWeight: "bold" }}>Part IV</ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '34.5%', borderLeft: "none", paddingLeft: '20px', minHeight: '34px' }}>cancellation of shares other than as provided in Section 89 of the Act</ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '34.5%', borderLeft: "none", paddingLeft: '20px', minHeight: '34px' }}></ScrollWrapper>
                                    </div>

                                    <PartsWrapper className='mt-4 mb-3'>
                                        Part - II
                                    </PartsWrapper>

                                    <div className='row mt-4'>
                                        <div className='col-md-12'>
                                            <HeadingWrapper className='d-flex justify-content-center'>
                                                INCREASE IN AUTHORIZED SHARE CAPITAL
                                            </HeadingWrapper>

                                        </div>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>2.1</div>
                                        <div className='align-items-center' style={{ width: '28%', padding: ' 5px 10px' }}> Date of Passing of Special Resolution for</div>
                                    </div>
                                    <div className='d-flex '>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}></div>
                                        <div className='align-items-center' style={{ width: '55%', padding: ' 5px 10px' }}>increase in authorized share capital:</div>
                                        <div className='align-items-center text-center' style={{ width: '8%', padding: ' 5px 10px' }}> Day</div>
                                        <div className='align-items-center text-center' style={{ width: '8%', padding: ' 5px 10px' }}> Month</div>
                                        <div className='align-items-center text-center' style={{ width: '16%', padding: ' 5px 10px' }}>Year </div>
                                        <div></div>
                                    </div>
                                    <div className='d-flex '>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}></div>
                                        <div className='align-items-center text-center' style={{ width: '55%', padding: ' 5px 10px', fontWeight: "bold" }}>OR</div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ border: '1px solid #121212', width: '4%', padding: ' 5px 10px' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ border: '1px solid #121212', width: '4%', padding: ' 5px 10px', borderRight: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', border: '1px solid #121212', }}> </div>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>2.2</div>
                                        <div className='align-items-center' style={{ width: '28%', padding: ' 5px 10px' }}> Share capital increased in pursuance of an</div>
                                    </div>
                                    <div className='d-flex'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}></div>
                                        <div className='align-items-center' style={{ width: '55%', padding: ' 5px 10px' }}>obligation of the company under an agreement on</div>
                                        <div className='align-items-center text-center' style={{ width: '8%', padding: ' 5px 10px' }}> Day</div>
                                        <div className='align-items-center text-center' style={{ width: '8%', padding: ' 5px 10px' }}> Month</div>
                                        <div className='align-items-center text-center' style={{ width: '16%', padding: ' 5px 10px' }}>Year </div>
                                        <div></div>
                                    </div>
                                    <div className='d-flex '>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}></div>
                                        <div className='align-items-center text-center' style={{ width: '55%', padding: ' 5px 10px', fontWeight: "bold" }}></div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ border: '1px solid #121212', width: '4%', padding: ' 5px 10px' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ border: '1px solid #121212', width: '4%', padding: ' 5px 10px', borderRight: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', border: '1px solid #121212', }}> </div>
                                    </div>
                                    <div className='d-flex '>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}></div>
                                        <div className='align-items-center text-center' style={{ width: '55%', padding: ' 5px 10px', fontWeight: "bold" }}>(date of agreement with party to the agreement)</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '32%', padding: ' 5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>2.3</div>
                                        <div className='align-items-center' style={{ padding: ' 5px 10px', fontWeight: "bold" }}>Increase in authorized share capital</div>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '25%', }}></div>
                                        <div className='align-items-center' style={{ width: '21.66%', padding: ' 5px 10px', }}>Amount (Rs)</div>
                                        <div className='align-items-center' style={{ width: '21.66%', padding: ' 5px 10px', }}>Divided into (no of shares)</div>
                                        <div className='align-items-center' style={{ width: '21.66%', padding: ' 5px 10px', }}>of Rs. per Share</div>
                                    </div>
                                    <div className='d-flex'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>2.3.1</div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '25%', }}> Existing authorized share capital</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '21.66%', minHeight: "41px", padding: ' 5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', borderLeft: "none", minHeight: "41px", width: '21.66%', padding: ' 5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', borderLeft: "none", minHeight: "41px", width: '21.66%', padding: ' 5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>2.3.2</div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '25%', }}>Addition</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '21.66%', minHeight: "41px", padding: ' 5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', borderLeft: "none", minHeight: "41px", width: '21.66%', padding: ' 5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', borderLeft: "none", minHeight: "41px", width: '21.66%', padding: ' 5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>2.3.3</div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '25%', }}> New Authorized share capital</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '21.66%', minHeight: "41px", padding: ' 5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', borderLeft: "none", minHeight: "41px", width: '21.66%', padding: ' 5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', borderLeft: "none", minHeight: "41px", width: '21.66%', padding: ' 5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                    </div>

                                    <PartsWrapper className='mt-4 mb-3'>
                                        Part - III
                                    </PartsWrapper>

                                    <div className='row mt-4'>
                                        <div className='col-md-12'>
                                            <HeadingWrapper className='d-flex justify-content-center'>
                                                CONSOLIDATION, DIVISION OR SUB-DIVISION OF SHARES
                                            </HeadingWrapper>
                                        </div>
                                    </div>

                                    <div className='d-flex '>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>3.1</div>
                                        <div className='align-items-center' style={{ width: '55%', padding: ' 5px 10px' }}> Date of Passing of Special Resolution</div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ border: '1px solid #121212', width: '4%', padding: ' 5px 10px' }}> </div>
                                        <div className='align-items-center' style={{ paddingLeft: '8px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ border: '1px solid #121212', width: '4%', padding: ' 5px 10px', borderRight: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ paddingLeft: '8px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', border: '1px solid #121212', }}> </div>
                                    </div>

                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>3.2</div>
                                        <div className='align-items-center' style={{ padding: ' 5px 10px' }}> Notice is hereby given, in accordance with section 85 of the Companies Act, 2017 for the purpose of:-</div>
                                    </div>

                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}></div>
                                        <div className='align-items-center' style={{ width: '20%', padding: ' 5px 10px' }}></div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '20%', padding: ' 5px 10px', textAlign: "center" }}><i>Tick the relevant field</i></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>3.2.1</div>
                                        <div className='align-items-center' style={{ width: '20%', padding: ' 5px 10px' }}> Consolidation and division of shares</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '20%', padding: ' 5px 10px', textAlign: "center" }}><i>Tick the relevant field</i></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>3.2.2</div>
                                        <div className='align-items-center' style={{ width: '20%', padding: ' 5px 10px' }}> Sub-division of shares</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '20%', padding: ' 5px 10px', textAlign: "center" }}><i>Tick the relevant field</i></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}></div>
                                        <div className='align-items-center' style={{ width: '10%', padding: ' 5px 10px' }}></div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '20%', padding: ' 5px 10px', textAlign: "center" }}>Number of shares</ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '20%', marginLeft: "1.2rem", padding: ' 5px 10px', textAlign: "center" }}>Rs. Per share</ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '20%', marginLeft: "1.2rem", padding: ' 5px 10px', textAlign: "center" }}>Amount (Rs)</ScrollWrapper>
                                    </div>
                                    <div className='d-flex'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>3.2.4</div>
                                        <div className='align-items-center' style={{ width: '10%', padding: '0px 10px 10px 10px' }}> Existing share capital</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '20%', padding: ' 5px 10px', textAlign: "center" }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '20%', marginLeft: "1.2rem", padding: ' 5px 10px', textAlign: "center" }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '20%', marginLeft: "1.2rem", padding: ' 5px 10px', textAlign: "center" }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>3.2.5</div>
                                        <div className='align-items-center' style={{ width: '10%', padding: '0px 10px 10px 10px' }}>Consolidated/divided</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '20%', padding: ' 5px 10px', textAlign: "center" }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '20%', marginLeft: "1.2rem", padding: ' 5px 10px', textAlign: "center" }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '20%', marginLeft: "1.2rem", padding: ' 5px 10px', textAlign: "center" }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>3.2.6</div>
                                        <div className='align-items-center' style={{ width: '10%', padding: '0px 10px 10px 10px' }}> Sub-division of shares</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '20%', padding: ' 5px 10px', textAlign: "center" }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '20%', marginLeft: "1.2rem", padding: ' 5px 10px', textAlign: "center" }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '20%', marginLeft: "1.2rem", padding: ' 5px 10px', textAlign: "center" }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>3.2.7</div>
                                        <div className='align-items-center' style={{ width: '10%', padding: '0px 10px 10px 10px' }}>New share capital</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '20%', padding: ' 5px 10px', textAlign: "center" }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '20%', marginLeft: "1.2rem", padding: ' 5px 10px', textAlign: "center" }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '20%', marginLeft: "1.2rem", padding: ' 5px 10px', textAlign: "center" }}></ScrollWrapper>
                                    </div>


                                    <PartsWrapper className='mt-4 mb-3'>
                                        Part - IV
                                    </PartsWrapper>

                                    <div className='row mt-4 mb-2'>
                                        <div className='col-md-12'>
                                            <HeadingWrapper className='d-flex justify-content-center'>
                                                CANCELLATION OF SHARES
                                            </HeadingWrapper>
                                        </div>
                                    </div>

                                    <div className='d-flex '>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>4.1</div>
                                        <div className='align-items-center' style={{ width: '55%', padding: ' 5px 10px' }}> Date of Passing of Special Resolution</div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ border: '1px solid #121212', width: '4%', padding: ' 5px 10px' }}> </div>
                                        <div className='align-items-center' style={{ paddingLeft: '8px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ border: '1px solid #121212', width: '4%', padding: ' 5px 10px', borderRight: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ paddingLeft: '8px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', border: '1px solid #121212', }}> </div>
                                    </div>

                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>4.2</div>
                                        <div className='align-items-center' style={{ padding: ' 5px 10px' }}> Notice is hereby given, in accordance with section 85 of the Companies Act, 2017 for the purpose of cancellation of shares:-</div>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}></div>
                                        <div className='align-items-center' style={{ width: '10%', padding: ' 5px 10px' }}></div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '20%', padding: ' 5px 10px', textAlign: "center" }}>Number of shares</ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '20%', marginLeft: "1.2rem", padding: ' 5px 10px', textAlign: "center" }}>Rs. Per share</ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '20%', marginLeft: "1.2rem", padding: ' 5px 10px', textAlign: "center" }}>Amount (Rs)</ScrollWrapper>
                                    </div>
                                    <div className='d-flex'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>4.2.1</div>
                                        <div className='align-items-center' style={{ width: '10%', padding: ' 5px 10px' }}> Existing share capital</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '20%', padding: ' 5px 10px', textAlign: "center" }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '20%', marginLeft: "1.2rem", padding: ' 5px 10px', textAlign: "center" }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '20%', marginLeft: "1.2rem", padding: ' 5px 10px', textAlign: "center" }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>4.2.2</div>
                                        <div className='align-items-center' style={{ width: '10%', padding: ' 5px 10px' }}>Cancelled shares</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '20%', padding: ' 5px 10px', textAlign: "center" }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '20%', marginLeft: "1.2rem", padding: ' 5px 10px', textAlign: "center" }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '20%', marginLeft: "1.2rem", padding: ' 5px 10px', textAlign: "center" }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>4.2.3</div>
                                        <div className='align-items-center' style={{ width: '10%', padding: ' 5px 10px' }}>New share capital</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '20%', padding: ' 5px 10px', textAlign: "center" }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '20%', marginLeft: "1.2rem", padding: ' 5px 10px', textAlign: "center" }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '20%', marginLeft: "1.2rem", padding: ' 5px 10px', textAlign: "center" }}></ScrollWrapper>
                                    </div>

                                    <PartsWrapper className='mt-4 mb-3'>
                                        Part - II
                                    </PartsWrapper>

                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>5.1</div>
                                        <div className='align-items-center' style={{ padding: ' 5px 10px' }}>Declaration</div>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}></div>
                                        <div className='align-items-center' style={{ padding: ' 5px 10px' }}>I do hereby solemnly and sincerely declare that the information provided in the form is:</div>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '2%' }}></div>
                                        <div className='align-items-center d-flex' style={{ padding: ' 5px 10px', gap: "12px" }}><div>
                                            (i)</div>
                                            <div>true and correct to the best of my knowledge, in consonance with the record as maintained by the Company and nothing has been concealed; and</div>
                                        </div>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '2%' }}></div>
                                        <div className='align-items-center d-flex' style={{ padding: ' 5px 10px', gap: "12px" }}><div>
                                            (ii)</div>
                                            <div>hereby reported after complying with and fulfilling all requirements under the relevant provisions of law, rules, regulations, directives, circulars and notifications whichever is applicable. </div>
                                        </div>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '2%' }}></div>
                                        <div className='align-items-center d-flex' style={{ padding: ' 5px 10px', gap: "12px" }}><div>
                                            (iii)</div>
                                            <div>The special resolution has been filed with the registrar on the prescribed form</div>
                                        </div>
                                    </div>

                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>5.2</div>
                                        <div className='align-items-center' style={{ width: '28%', padding: ' 5px 10px' }}> Name of Authorized Officer with designation/ Authorized Intermediary, if any</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '32.5%', padding: ' 5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', borderLeft: "none", width: '32.5%', padding: ' 5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>5.3</div>
                                        <div className='align-items-center' style={{ width: '28%', padding: ' 5px 10px' }}>Signatures </div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '65%', padding: ' 5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>5.4</div>
                                        <div className='align-items-center' style={{ width: '40%', padding: ' 5px 10px' }}>Registration No of Authorized Intermediary, if applicable</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '53%', padding: ' 5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}></div>
                                        <div className='align-items-center' style={{ width: '55%', padding: ' 5px 10px' }}> </div>
                                        <div className='align-items-center text-center' style={{ width: '8%', padding: ' 5px 10px' }}> Day</div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center text-center' style={{ width: '8%', padding: ' 5px 10px' }}> Month</div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center text-center' style={{ width: '16%', padding: ' 5px 10px' }}>Year </div>
                                        <div></div>
                                    </div>
                                    <div className='d-flex '>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>5.5</div>
                                        <div className='align-items-center' style={{ width: '55%', padding: ' 5px 10px' }}>Date</div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ border: '1px solid #121212', width: '4%', padding: ' 5px 10px' }}> </div>
                                        <div className='align-items-center' style={{ paddingLeft: '8px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ border: '1px solid #121212', width: '4%', padding: ' 5px 10px', borderRight: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ paddingLeft: '8px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', border: '1px solid #121212', }}> </div>
                                    </div>
                                    <div className='d-flex mt-2' style={{ fontWeight: "bold" }}>
                                        Enclosure
                                    </div>
                                    <div className='d-flex'>
                                        1. Amended Memorandum and Articles of association.
                                    </div>
                                    <div className='d-flex'>
                                        2. Original challan or other evidence of payment of fee specified in Seventh Schedule of the Act (not applicable in case of online filing)
                                    </div>

                                </div>
                            </div>
                        </div>
                    </PDFExport>}
            </div>
            <button disabled={loading} className='btn btn-danger' onClick={(e) => {
                // if(viewPdf)
                //   SetViewPDf(true)
                if (pdfExportComponent.current) {
                    pdfExportComponent.current.save();
                }
            }}>Download PDF</button>
        </div>
    )
}
const PartsWrapper = styled.div`
color: #121212;
text-decoration: underline;
justify-content: center;
display: flex;
margin-right: 15px;
font-size: 17px;
font-weight: bold;
`;
const ScrollWrapper = styled.div`
max-height: 50px;
overflow-y: scroll;
overflow-x: hidden;
::-webkit-scrollbar{
    height: 5px;
    width: 3px;
  }
  
  ::-webkit-scrollbar-track{
    background: #F9F9FB;
  }
  ::-webkit-scrollbar-thumb{
    background: #4E515680;
    border-radius: 5px;
  
  }
`;
const HeadingWrapper = styled.div`
font-size: 17px;
font-weight: bold;
`;
const FormName = styled.div`
color: #121212;
text-decoration: underline;
justify-content: end;
display: flex;
margin-right: 15px;
font-size: 17px;
font-weight: bold;
`;
const Wrapper = styled.div`
table.table-bordered{
    border: 1px solid #121212
     border-color: #121212; 
    // border:1px solid blue;
    // margin-top:20px;
  }
// table.table-bordered > thead > tr > th{
//     // border:1px solid blue;
//     border-color: #121212; 
// }
// table.table-bordered > tbody > tr > td{
//     // border:1px solid blue;
//     borderC-color: #121212; 
// }
 tr td {
    // border:1px solid blue;.
    border-color: #121212; 
}

`;
const WrapperDashed = styled.div`

  td {
    
    // border: 1px dashed #dddcdc;
}

`;