import React, { useState, useEffect } from 'react'
import { PDFExport } from "@progress/kendo-react-pdf";
import PageTemplate from "./page-template";
import {
    getCompanies,
} from "../../../store/services/company.service";
import styled from 'styled-components';
import { TiTick } from "react-icons/ti";
import { numberWithCommas } from 'utilities/utilityFunctions';
import "./CommonForm.css"
export const AppendixCToApp4 = ({ data, formTemplate = false }) => {
    const pdfExportComponent = React.useRef(null);
    const [CompanyData, setCompanyData] = useState(null)
    const [loading, setLoading] = useState(false);
    const [fontFamilyForStaticData, setFontFamilyForStaticData] = useState('Montserrat, sans-serif')
    const [fontSizeForStaticData, setFontSizeForStaticData] = useState('14px')
    const [fontfamilyforDynimicData, setFontFamilyForDynmicData] = useState('Segoe Print')
    const [fontColor, setFontColor] = useState('')
    const baseEmail = sessionStorage.getItem("email") || "";
    const [governance, setGovernance] = useState({})
    const getAllCompanies = async () => {
        setLoading(true)
        try {
            const response = await getCompanies(baseEmail);
            if (response.status === 200) {
                response.data.data = response.data.data.filter((item) => item?.active === 'Y')
                const parents = response.data.data;
                const company = parents?.find(ite => ite?.code == data?.company_code)
                let Allperson = company.governance && company.governance != "" ? JSON.parse(company.governance) : []
                let sec = Allperson.find(x => x.role == "Company Secretary" && x?.active?.toLowerCase() != 'n')
                let ceo = Allperson.find(x => x.role == "CEO" && x?.active?.toLowerCase() != 'n')


                if (sec) {
                    setGovernance(sec)
                } else if (ceo) {
                    setGovernance(ceo)
                }
                setCompanyData(company)
                setTimeout(() => {
                    setLoading(false)
                }, 1000);
            }
        } catch (error) {
            setLoading(false)
        }
    };
    useEffect(() => {
        getAllCompanies()
    }, [])
    return (
        <div>
            <div
                style={{ fontFamily: "sans-serif " }}
            >

                {formTemplate ?
                    <PDFExport
                        paperSize="A4"
                        margin="1cm"
                        scale={0.6}
                        fileName={`Appendix-C to App-4`}
                        pageTemplate={PageTemplate}
                        ref={pdfExportComponent}
                    >
                        <div style={{ fontFamily: `${fontFamilyForStaticData}`, fontSize: `${fontSizeForStaticData}`, fontWeight: 500 }}>
                            <FormName>
                            Appendix-C to App-4
                            </FormName>
                            <div className='row'>
                                <div className='col-md-12' >
                                    <HeadingWrapper className='d-flex justify-content-center'>
                                    THE COMPANIES ACT, 2017
                                    </HeadingWrapper>
                                    <HeadingWrapper className='d-flex justify-content-center'>
                                    THE COMPANIES REGULATIONS, 2024
                                    </HeadingWrapper>

                                </div>
                            </div>

                            <div className='row'>
                                <div className='col-md-12'>
                                    <div style={{
                                        fontSize: '15px',
                                        // fontWeight: 'bold' 
                                    }} className='d-flex justify-content-center'>
                                       [Regulations 145]
                                    </div>

                                </div>
                            </div>


                            <div className='row mt-4'>
                                <div className='col-md-12'>
                                    <HeadingWrapper style={{ textDecoration: "underline" }} className='text-center d-flex justify-content-center'>
                                    Auditors’ Certificate
                                    </HeadingWrapper>

                                </div>
                            </div>

                            <div className='row'>
                                <div className='col-md-12' style={{ padding: '25px 50px', wordWrap: 'break-word' }}>
                                        <div className='row'>
                                            
                                            <p style={{fontSize:"14px"}} className='aca4-para'>We, have examined the books and record of M/s
                                                <span className='inline-input-300 mx-2'>
                                                    <div className='border-none border-bottom border-dark inline-input-h' ></div>
                                                </span>
                                                <strong>(the ‘Company’)</strong> and have obtained all the information and explanation which to the best of our knowledge and belief were necessary for the purpose of this certificate and after due verification thereof, we certify that:
                                            </p>
                                        </div>
                                        <ol style={{display:"block"}} className='app4-appendix-c-list1-ol'>
                                            <li>
                                                The Company has no known assets or is not carrying on any business or any operation;
                                            </li>
                                            <li>
                                                The Company has no liabilites outstanding in relation to any loan(s) obtained from the banks / financial institutions, taxes, utility charges, or any obligations towards government department or private parties;
                                            </li>
                                            <li>
                                                the Comapny has obtained necessary No Objection Certificate (NOC) from all concerned licensing/permitting authorities (Applicable only in case of company formed under licensing regime/special permission); and
                                            </li>
                                            <li>
                                                the Company has not undertaken any business of housing, real estate development or real estate marketing since its incorporation
                                            </li>
                                        </ol>
                                        <span className='d-block font-large'>Place:
                                            <span className='inline-input-200'>
                                                <div className='border-none border-bottom border-dark inline-input-h' ></div>
                                            </span>
                                        </span>
                                        <span className='d-block font-large mb-3'>Dated:
                                            <span className='inline-input-200'>
                                                <div className='border-none border-bottom border-dark inline-input-h' ></div>
                                            </span>
                                        </span>
                                        <div className='text-right pr-5'>
                                            <span className='font-large mr-5'>(Signature with name of Auditors)</span>
                                        </div>
                                </div>
                            </div>
                        </div>
                    </PDFExport>
                    : <PDFExport
                        paperSize="A4"
                        margin="1cm"
                        scale={0.6}
                        fileName={`Appendix-C to App-4`}
                        pageTemplate={PageTemplate}
                        ref={pdfExportComponent}
                    >
                         <div style={{ fontFamily: `${fontFamilyForStaticData}`, fontSize: `${fontSizeForStaticData}`, fontWeight: 500 }}>
                            <FormName>
                            Appendix-C to App-4
                            </FormName>
                            <div className='row'>
                                <div className='col-md-12' >
                                    <HeadingWrapper className='d-flex justify-content-center'>
                                    THE COMPANIES ACT, 2017
                                    </HeadingWrapper>
                                    <HeadingWrapper className='d-flex justify-content-center'>
                                    THE COMPANIES REGULATIONS, 2024
                                    </HeadingWrapper>

                                </div>
                            </div>

                            <div className='row'>
                                <div className='col-md-12'>
                                    <div style={{
                                        fontSize: '15px',
                                        // fontWeight: 'bold' 
                                    }} className='d-flex justify-content-center'>
                                       [Regulations 145]
                                    </div>

                                </div>
                            </div>


                            <div className='row mt-4'>
                                <div className='col-md-12'>
                                    <HeadingWrapper style={{ textDecoration: "underline" }} className='text-center d-flex justify-content-center'>
                                    Auditors’ Certificate
                                    </HeadingWrapper>

                                </div>
                            </div>

                            <div className='row'>
                                <div className='col-md-12' style={{ padding: '25px 50px', wordWrap: 'break-word' }}>
                                        <div className='row'>
                                            
                                            <p style={{fontSize:"14px"}} className='aca4-para'>We, have examined the books and record of M/s
                                                <span className='inline-input-300 mx-2'>
                                                    <div className='border-none border-bottom border-dark inline-input-h' ></div>
                                                </span>
                                                <strong>(the ‘Company’)</strong> and have obtained all the information and explanation which to the best of our knowledge and belief were necessary for the purpose of this certificate and after due verification thereof, we certify that:
                                            </p>
                                        </div>
                                        <ol style={{display:"block"}} className='app4-appendix-c-list1-ol'>
                                            <li>
                                                The Company has no known assets or is not carrying on any business or any operation;
                                            </li>
                                            <li>
                                                The Company has no liabilites outstanding in relation to any loan(s) obtained from the banks / financial institutions, taxes, utility charges, or any obligations towards government department or private parties;
                                            </li>
                                            <li>
                                                the Comapny has obtained necessary No Objection Certificate (NOC) from all concerned licensing/permitting authorities (Applicable only in case of company formed under licensing regime/special permission); and
                                            </li>
                                            <li>
                                                the Company has not undertaken any business of housing, real estate development or real estate marketing since its incorporation
                                            </li>
                                        </ol>
                                        <span className='d-block font-large'>Place:
                                            <span className='inline-input-200'>
                                                <div className='border-none border-bottom border-dark inline-input-h' ></div>
                                            </span>
                                        </span>
                                        <span className='d-block font-large mb-3'>Dated:
                                            <span className='inline-input-200'>
                                                <div className='border-none border-bottom border-dark inline-input-h' ></div>
                                            </span>
                                        </span>
                                        <div className='text-right pr-5'>
                                            <span className='font-large mr-5'>(Signature with name of Auditors)</span>
                                        </div>
                                </div>
                            </div>
                        </div>
                        {/* <div style={{ fontFamily: `${fontFamilyForStaticData}`, fontSize: `${fontSizeForStaticData}`, fontWeight: 500 }}>
                            <FormName>
                                Appendix to Form-3
                            </FormName>
                            <div className='row'>
                                <div className='col-md-12' >
                                    <HeadingWrapper className='d-flex justify-content-center'>
                                        THE COMPANIES ACT, 2017
                                    </HeadingWrapper>

                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <HeadingWrapper className='d-flex justify-content-center'>
                                        THE COMPANIES REGULATIONS, 2024
                                    </HeadingWrapper>

                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <div style={{
                                        fontSize: '15px',
                                        // fontWeight: 'bold' 
                                    }} className='d-flex justify-content-center'>
                                        [Section 70(1)]
                                    </div>

                                </div>
                            </div>

                            <div className='row mt-4'>
                                <div className='col-md-12'>
                                    <div className='d-flex justify-content-center'>
                                        (On the letterhead of Auditors of the company/practicing Chartered Accountant/ Cost and Management Accountant)
                                    </div>

                                </div>
                            </div>
                            <div className='row mt-4'>
                                <div className='col-md-12'>
                                    <HeadingWrapper className='d-flex justify-content-center'>
                                        AUDITORS’ CERTIFICATE VERIFYING RECEIPT OF CONSIDERATION MONEY
                                    </HeadingWrapper>

                                </div>
                            </div>
                            <PartsWrapper className='mt-4 mb-3'>
                                PART-I
                            </PartsWrapper>
                            <div className='row'>
                                <div className='col-md-12' style={{ padding: '25px 50px', wordWrap: 'break-word' }}>
                                    <div>
                                        {`I / We have examined the record of M/S <Name of company>, and it is, hereby, reported that a total amount of Rs. < amount> has been received on account of consideration money against allotment of <# Number of shares > each of <Face value > by the company and shares have been issued to each allottee.`}
                                    </div>
                                    <div className='mt-2'>
                                        Signature:_____________________
                                    </div>
                                    <div className='mt-2'>
                                        Name:__________________________
                                    </div>
                                    <div className='mt-2'>
                                        Date:__________________________
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </PDFExport>}
            </div>
            <button disabled={loading} className='btn btn-danger' onClick={(e) => {
                // if(viewPdf)
                //   SetViewPDf(true)
                if (pdfExportComponent.current) {
                    pdfExportComponent.current.save();
                }
            }}>Download PDF</button>
        </div>
    )
}
const PartsWrapper = styled.div`
color: #121212;
text-decoration: underline;
justify-content: center;
display: flex;
margin-right: 15px;
font-size: 17px;
font-weight: bold;
`;
const ScrollWrapper = styled.div`
max-height: 50px;
overflow-y: scroll;
overflow-x: hidden;
::-webkit-scrollbar{
    height: 5px;
    width: 3px;
  }
  
  ::-webkit-scrollbar-track{
    background: #F9F9FB;
  }
  ::-webkit-scrollbar-thumb{
    background: #4E515680;
    border-radius: 5px;
  
  }
`;
const HeadingWrapper = styled.div`
font-size: 17px;
font-weight: bold;
`;
const FormName = styled.div`
color: #121212;
text-decoration: underline;
justify-content: end;
display: flex;
margin-right: 15px;
font-size: 17px;
font-weight: bold;
`;
const Wrapper = styled.div`
table.table-bordered{
    border: 1px solid #121212
     border-color: #121212; 
    // border:1px solid blue;
    // margin-top:20px;
  }
// table.table-bordered > thead > tr > th{
//     // border:1px solid blue;
//     border-color: #121212; 
// }
// table.table-bordered > tbody > tr > td{
//     // border:1px solid blue;
//     borderC-color: #121212; 
// }
 tr td {
    // border:1px solid blue;.
    border-color: #121212; 
}

`;
const WrapperDashed = styled.div`

  td {
    
    border: 1px dashed #dddcdc;
}

`;