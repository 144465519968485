import React, { Fragment, useEffect, useState } from "react";
import Header from "./common/header-component/header";
import Sidebar from "./common/sidebar-component/sidebar";
import RightSidebar from "./common/right-sidebar";
import Footer from "./common/footer";
import ThemeCustomizer from "./common/theme-customizer";
import { ToastContainer } from "react-toastify";
import Loader from "./common/loader";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import {
  WATCH_COMPANIES,
  WATCH_COMPANIES_DROPDOWN,
  WATCH_SHAREHOLDERS,
  WATCH_SHAREHOLDERS_DROPDOWN,
  WATCH_INVESTORS,
  WATCH_INVESTORS_DROPDOWN,
  WATCH_INVESTORS_REQUEST_TYPES,
  WATCH_INVESTORS_REQUEST,
  GET_FEATURES,
  WATCH_TRANSACTION_REQUEST,
  WATCH_TRANSACTION_REQUEST_TYPES,
  WATCH_SHARE_CERTIFICATES,
  WATCH_SHARE_CERTIFICATES_DROPDOWN,
  WATCH_ENTITLEMENTS_DROPDOWN,
  WATCH_ENTITLEMENTS,
  WATCH_DIVIDEND,
  WATCH_ANNOUNCEMENTS,
  WATCH_ANNOUNCEMENTS_DROPDOWN,
  WATCH_COMPANIES_SYMBOLS,
  WATCH_INACTIVE_SHAREHOLDERS,
  WATCH_DASHBOARD_DATA,
  WATCH_COMPANY,
  WATCH_TRANSACTION_LISTING,
} from "../redux/actionTypes";
import { DashboardProvider } from "../context/context";
import Config from "../config/index";

const AppLayout = (props) => {
  const dispatch = useDispatch();
  // const token = sessionStorage.getItem("token");

  useEffect(() => {
    if (window.location.pathname == "/login" && sessionStorage.getItem('email') !== null) {
      sessionStorage.clear();
      // window.location.replace(`${process.env.PUBLIC_URL}/login`);
      // window.location.href=`${process.env.PUBLIC_URL}/login`;
    }
    document.title = Config.appTitle + ' ' + '(' + Config.appVersion + ')';
  }, [])

  useEffect(() => {
    if (sessionStorage.getItem("token") !== null) {
      // dispatch({ type: WATCH_DASHBOARD_DATA });
      // dispatch({ type: WATCH_COMPANY });
      // dispatch({ type: WATCH_SHAREHOLDERS });
      // dispatch({ type: WATCH_INACTIVE_SHAREHOLDERS });
      // dispatch({ type: WATCH_SHAREHOLDERS_DROPDOWN });
      // dispatch({ type: WATCH_INVESTORS });
      // dispatch({ type: WATCH_INVESTORS_DROPDOWN });
      // dispatch({ type: WATCH_INVESTORS_REQUEST_TYPES });
      // dispatch({ type: WATCH_INVESTORS_REQUEST });
      // dispatch({ type: WATCH_TRANSACTION_LISTING });
      // dispatch({ type: WATCH_TRANSACTION_REQUEST });
      // dispatch({ type: WATCH_TRANSACTION_REQUEST_TYPES });
      // dispatch({ type: WATCH_SHARE_CERTIFICATES });
      // dispatch({ type: WATCH_SHARE_CERTIFICATES_DROPDOWN });
      // dispatch({ type: WATCH_ENTITLEMENTS_DROPDOWN });
      // dispatch({ type: WATCH_ENTITLEMENTS });
      // dispatch({ type: WATCH_ANNOUNCEMENTS });
      // dispatch({ type: WATCH_ANNOUNCEMENTS_DROPDOWN });
      // dispatch({ type: WATCH_DIVIDEND });
      dispatch({
        type: GET_FEATURES,
        payload: JSON.parse(sessionStorage.getItem("features")),
      });
    }
  }, [sessionStorage.getItem("token")]);
  useEffect(() => {
    localStorage.setItem("layout_version", "dark-only");
  }, []);

  const [header, setHeader] = useState(true);

  const handleComponentClick = () => {
    setHeader(!header);
  };
  const bodyStyleOnHorizontalHeader = {
    marginTop: localStorage.getItem("wrapper") === "horizontal_sidebar" ? "252px" : ""
  }
  return (
    <Fragment>
      {sessionStorage.getItem("token") != null && window.location.pathname != "/login" ? (
        <div className="page-wrapper">
          <div className="page-body-wrapper">
            {/* {token ? ( */}
            <>
              <Header header={header} />
              <Sidebar onClick={handleComponentClick} />
              <RightSidebar onClick={handleComponentClick} />

              <div style={bodyStyleOnHorizontalHeader} className="page-body" onClick={handleComponentClick}>{props.children} </div>
              <Footer />
              <ThemeCustomizer />
            </>
          </div>
        </div>
      ) : (
        <Redirect to={"/login"} />
      )}
      <ToastContainer />
    </Fragment>
  );
};

export default AppLayout;
