import React, { Fragment, useState, useEffect, useContext } from "react";
import Breadcrumb from "../../common/breadcrumb";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import ReactPaginate from "react-paginate";
import styled from "styled-components";
import {
  IsJsonString,
  listCrud,
  numberWithCommas,
} from "../../../../src/utilities/utilityFunctions";
import { Modal, ModalHeader, ModalBody, UncontrolledTooltip } from "reactstrap";
// import AddShareCertificateIssuance from "./addShareCertificateIssuance";
// import EditShareCertificateIssuance from "./editShareCertificateIssuance";
// import ViewShareCertificateIssuance from "./viewShareCertificateIssuance";
import {
  getShareCertificates,
  getShareCertificatesByCompany,
  getShareCertificatesByFolio,
} from "../../../store/services/shareCertificate.service";
import Select from "react-select";
import { getShareholders } from "../../../store/services/shareholder.service";
import { createSvg } from "chartist";
import { generateRegex } from "../../../utilities/utilityFunctions";
import { darkStyle } from "../../defaultStyles";
import ViewCertificateHistory from "../../../components/shareRegistrar/share-certificate/viewCertificateHistory";
import sixteen from "../../../assets/images/user/16.png";
import Spinner from "../../common/spinner";
import Collapse from "react-bootstrap/Collapse";

const ShareCertificate = (props) => {
  const baseEmail = sessionStorage.getItem("email") || "";
  const company_code = sessionStorage.getItem("company_code");
  const [data, setData] = useState([]);
  // const features = useSelector((data) => data.Features).features;
  const certificate_status = [
    { label: "Active", value: "ACTIVE" },
    { label: "Inactive", value: "INACTIVE" },
    { label: "Transfered To Electronic", value: "TTE" },
  ];
  const [collapse, setCollapse] = useState(false);
  const [status, setStatus] = useState("");
  const [crudFeatures, setCrudFeatures] = useState([true, true, true, true]);
  const [viewAddPage, setViewAddPage] = useState(false);
  const [viewEditPage, setViewEditPage] = useState(false);
  const [viewFlag, setViewFlag] = useState(false);
  const [shareHoldings, setShareHoldings] = useState([]);
  const [shareHoldingsOptions, setShareHoldingsOptions] = useState([]);
  const [selectedHolding, setSelectedHolding] = useState("");
  const [selectedCompany, setSelectedCompany] = useState("");
  const [search, setSearch] = useState("");
  const [underSearch, setUnderSearch] = useState("");
  const [searchedCertificates, setSearchedCertificates] = useState([]);
  // Selector STARTS
  const { company_data, company_data_loading } = useSelector(
    (data) => data.Company
  );
  // const share_certificates = useSelector((data) => data.ShareCertificates);
  // const share_holders = useSelector((data) => data.Shareholders);
  const [share_certificates, setShare_Certificates] = useState([]);
  const [share_holders, setShareholders] = useState([]);

  const [share_certificates_loading, setShare_Certificates_Loading] =
    useState(false);

  const getShareholdersByCompany = async () => {
    try {
      // setCompany_Data_Loading(true);
      const response = await getShareholders(baseEmail, company_code);
      if (response.status == 200) {
        const shareholders_dropdown = response.data.data.map((item) => {
          let label = `${item.folio_number} (${item.shareholder_name}) `;
          return { label: label, value: item.folio_number };
        });
        // setShareholders_dropdown(shareholders_dropdown);
        setShareholders(response.data.data);
      } else {
        setShareholders([]);
      }
      // setCompany_Data_Loading(false);
    } catch (error) {
      // setCompany_Data_Loading(false);
      if (error.response != undefined) {
        toast.error(error.response.data.message);
      } else {
        toast.error(error.message);
      }
    }
    // setCompany_Data_Loading(false);
  };

  //   const getShareCertificatesByCompany = async() =>{
  //     try{
  //       setShare_Certificates_Loading(true);
  //       const response = await getShareCertificates(baseEmail,company_code);
  //       if(response.status==200){
  //         const shareholders_dropdown = response.data.data.map((item) => {
  //           let label = `${item.folio_number} (${item.shareholder_name}) `;
  //           return { label: label, value: item.folio_number };
  //         });
  //         // setShareholders_dropdown(shareholders_dropdown);
  //         setShare_Certificates(response.data.data);
  //       }else{
  //         setShareholders([]);
  //       }
  //       setShare_Certificates_Loading(false);
  //     }catch(error){
  //       setShare_Certificates_Loading(false);
  //       if(error.response!=undefined){
  //         toast.error(error.response.data.message);
  //       }else{
  //         toast.error(error.message);
  //       }
  //     }
  //     setShare_Certificates_Loading(false);
  //   }

  const getShareCertificates = async () => {
    try {
      setShare_Certificates_Loading(true);
      const response = await getShareCertificatesByFolio(
        baseEmail,
        props.folio_number
      );
      if (response.status == 200) {
        // const shareholders_dropdown = response.data.data.map((item) => {
        //   let label = `${item.folio_number} (${item.shareholder_name}) `;
        //   return { label: label, value: item.folio_number };
        // });
        // setShareholders_dropdown(shareholders_dropdown);
        setShare_Certificates(response.data.data);
      } else {
        setShareholders([]);
      }
      setShare_Certificates_Loading(false);
    } catch (error) {
      setShare_Certificates_Loading(false);
      if (error.response != undefined) {
        toast.error(error.response.data.message);
      } else {
        toast.error(error.message);
      }
    }
    setShare_Certificates_Loading(false);
  };

  useEffect(() => {
    getShareholdersByCompany();
    // getShareCertificatesByCompany();
    getShareCertificates();
  }, []);
  // Selector ENDS
  // useEffect(() => {
  //   if (features.length !== 0) setCrudFeatures(listCrud(features));
  // }, [features]);
  // useEffect(() => {
  //   const getAllShareCertificatesAndShareHolders = async () => {
  //     try {
  //       const shareHoldersResponse = await getShareholders(baseEmail);
  //       const shareCertificateResponse = await getShareCertificates(baseEmail);
  //       if (shareHoldersResponse.status === 200) {
  //         if (shareCertificateResponse.status === 200) {
  //           let options = shareHoldersResponse.data.data.map((item) => {
  //             let label = `${item.folio_number} (${item.shareholder_name}) `;
  //             return { label: label, value: item.folio_number };
  //           });
  //           setShareHoldingsOptions(options);
  //           setShareHoldings(shareHoldersResponse.data.data);
  //           setCertificates(
  //             shareCertificateResponse.data.data.map((cert) => ({
  //               ...cert,
  //               folio_number: cert.allotted_to,
  //               allotted_to: shareHoldersResponse.data.data.find(
  //                 (holding) => holding.folio_number === cert.allotted_to
  //               )?.shareholder_name,
  //               company_code: companies.companies_data.find(
  //                 (comp) => comp.code === cert.company_code
  //               )?.company_name,
  //             }))
  //           );
  //         }
  //       }
  //     } catch (error) {
  //       !!error?.response?.data?.message
  //         ? toast.error(error?.response?.data?.message)
  //         : toast.error("Certificates Not Found");
  //     }
  //   };

  //   getAllShareCertificatesAndShareHolders();
  // }, [companies.companies_data]);
  // useEffect(() => {
  //   const getAllShareCertificatesAndShareHolders = async () => {
  //     try {
  //       const shareCertificateResponse = await getShareCertificatesByCompany(
  //         baseEmail,
  //         selectedCompany
  //       );
  //       if (shareCertificateResponse.status === 200) {
  //         if (selectedHolding !== "") {
  //           setCertificates(
  //             shareCertificateResponse.data.data
  //               .filter((cert) => cert.allotted_to === selectedHolding)
  //               .map((cert) => ({
  //                 ...cert,
  //                 folio_number: cert.allotted_to,
  //                 allotted_to: shareHoldings.find(
  //                   (holding) => holding.folio_number === cert.allotted_to
  //                 )?.shareholder_name,
  //                 company_code: companies.companies_data.find(
  //                   (comp) => comp.code === cert.company_code
  //                 )?.company_name,
  //               }))
  //           );
  //         } else {
  //           setCertificates(
  //             shareCertificateResponse.data.data.map((cert) => ({
  //               ...cert,
  //               folio_number: cert.allotted_to,
  //               allotted_to: shareHoldings.find(
  //                 (holding) => holding.folio_number === cert.allotted_to
  //               )?.shareholder_name,
  //               company_code: companies.companies_data.find(
  //                 (comp) => comp.code === cert.company_code
  //               )?.company_name,
  //             }))
  //           );
  //         }
  //       }
  //     } catch (error) {
  //       !!error?.response?.data?.message
  //         ? toast.error(error?.response?.data?.message)
  //         : toast.error("Certificates Not Found");
  //     }
  //   };
  //   if (selectedCompany !== "") getAllShareCertificatesAndShareHolders();
  // }, [selectedCompany]);

  // useEffect(() => {
  //   const getAllShareCertificatesAndShareHolders = async () => {
  //     try {
  //       const shareCertificateResponse = await getShareCertificatesByFolio(
  //         baseEmail,
  //         selectedHolding
  //       );
  //       if (shareCertificateResponse.status === 200) {
  //         if (selectedCompany !== "") {
  //           setCertificates(
  //             shareCertificateResponse.data.data
  //               .filter((cert) => cert.company_code === selectedCompany)
  //               .map((cert) => ({
  //                 ...cert,
  //                 folio_number: cert.allotted_to,
  //                 allotted_to: shareHoldings.find(
  //                   (holding) => holding.folio_number === cert.allotted_to
  //                 )?.shareholder_name,
  //                 company_code: companies.companies_data.find(
  //                   (comp) => comp.code === cert.company_code
  //                 )?.company_name,
  //               }))
  //           );
  //         } else {
  //           setCertificates(
  //             shareCertificateResponse.data.data.map((cert) => ({
  //               ...cert,
  //               folio_number: cert.allotted_to,
  //               allotted_to: shareHoldings.find(
  //                 (holding) => holding.folio_number === cert.allotted_to
  //               )?.shareholder_name,
  //               company_code: companies.companies_data.find(
  //                 (comp) => comp.code === cert.company_code
  //               )?.company_name,
  //             }))
  //           );
  //         }
  //       }
  //     } catch (error) {
  //       !!error?.response?.data?.message
  //         ? toast.error(error?.response?.data?.message)
  //         : toast.error("Certificates Not Found");
  //     }
  //   };
  //   if (selectedHolding !== "") getAllShareCertificatesAndShareHolders();
  // }, [selectedHolding]);
  const displayDistinctive = (distinctive_no) => {
    if (
      IsJsonString(distinctive_no) &&
      Array.isArray(JSON.parse(distinctive_no))
    ) {
      const distinctive_n = JSON.parse(distinctive_no);
      const distinctive_array = [];
      if (distinctive_n.length !== 0) {
        return [
          distinctive_n.map((item) => item.from).toString(),
          distinctive_n.map((item) => item.to).toString(),
        ];
      } else return "";
    } else return "";
  };
  /*  ---------------------  */
  /*  Pagination Code Start  */
  /*  ---------------------  */
  const [pageNumber, setPageNumber] = useState(0);
  const certificatesPerPage = 10;
  const pagesVisited = pageNumber * certificatesPerPage;
  const totalnumberofPages = 100;
  const displayCertificatesPerPage = !underSearch
    ? share_certificates
        .sort((a, b) => {
          if (
            new Date(b.issue_date).getTime() < new Date(a.issue_date).getTime()
          )
            return -1;
          if (
            new Date(b.issue_date).getTime() > new Date(a.issue_date).getTime()
          )
            return 1;
          return 0;
        })
        .slice(pagesVisited, pagesVisited + certificatesPerPage)
        .map((cert, i) => (
          <tr key={i}>
            <td scope="col">{i + 1}</td>
            <td>{cert.allotted_to.replace(`${company_code}-`, "")}</td>
            <td>
              {
                share_holders.find(
                  (holder) => holder.folio_number === cert.allotted_to
                )?.shareholder_name
              }
            </td>
            <td>
              {cert.certificate_no.startsWith(`${company_code}-`)
                ? cert.certificate_no.replace(`${company_code}-`, "")
                : cert.certificate_no}
            </td>
            {/* <td>
              {
                companies.companies_data.find(
                  (comp) => comp.code === cert.company_code
                )?.company_name
              }
            </td> */}
            <td>{displayDistinctive(cert.distinctive_no)[0]}</td>
            <td>{displayDistinctive(cert.distinctive_no)[1]}</td>
            <td className="text-right">
              {numberWithCommas(cert.shares_count)}
            </td>
            <td>{cert.status}</td>
            <td>{cert.txn_id}</td>
            <td>
              <>
                <i
                  className="icofont icofont-history"
                  id="viewCertificateHistory"
                  style={{
                    width: 35,
                    fontSize: 16,
                    padding: 11,
                    color: "rgb(68, 102, 242)",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    // for modal
                    setViewFlag(true);
                    sessionStorage.setItem(
                      "selectedCertificateHistory",
                      cert.certificate_history
                    );
                  }}
                ></i>
                <UncontrolledTooltip
                  placement="top"
                  target="viewCertificateHistory"
                >
                  {"View Certificate History"}
                </UncontrolledTooltip>
              </>
            </td>
          </tr>
        ))
    : searchedCertificates
        .sort((a, b) => {
          if (
            new Date(b.issue_date).getTime() < new Date(a.issue_date).getTime()
          )
            return -1;
          if (
            new Date(b.issue_date).getTime() > new Date(a.issue_date).getTime()
          )
            return 1;
          return 0;
        })
        .slice(pagesVisited, pagesVisited + certificatesPerPage)
        .map((cert, i) => (
          <tr key={i}>
            <td scope="col">{i + 1}</td>
            <td>{cert.allotted_to.replace(`${company_code}-`, "")}</td>
            <td>
              {
                share_holders.find(
                  (holder) => holder.folio_number === cert.allotted_to
                )?.shareholder_name
              }
            </td>
            <td>
              {" "}
              {cert.certificate_no.startsWith(`${company_code}-`)
                ? cert.certificate_no.replace(`${company_code}-`, "")
                : cert.certificate_no}
            </td>
            {/* <td>
              {
                companies.companies_data.find(
                  (comp) => comp.code === cert.company_code
                )?.company_name
              }
            </td> */}
            <td>
              {IsJsonString(cert?.distinctive_no) &&
                Array.isArray(JSON.parse(cert?.distinctive_no)) &&
                JSON.parse(cert?.distinctive_no)[0]?.from}
            </td>
            <td>
              {IsJsonString(cert?.distinctive_no) &&
                Array.isArray(JSON.parse(cert?.distinctive_no)) &&
                JSON.parse(cert?.distinctive_no)[0]?.to}
            </td>
            <td className="text-right">
              {numberWithCommas(cert.shares_count)}
            </td>
            <td>{cert.status}</td>
            <td>{cert.txn_id}</td>
            <td>
              <>
                <i
                  className="icofont icofont-history"
                  id="viewCertificateHistory"
                  style={{
                    width: 35,
                    fontSize: 16,
                    padding: 11,
                    color: "rgb(68, 102, 242)",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    // for modal
                    setViewFlag(true);
                    sessionStorage.setItem(
                      "selectedCertificateHistory",
                      cert.certificate_history
                    );
                  }}
                ></i>
                <UncontrolledTooltip
                  placement="top"
                  target="viewCertificateHistory"
                >
                  {"View Certificate History"}
                </UncontrolledTooltip>
              </>
            </td>
          </tr>
        ));
  const pageCount = !underSearch
    ? Math.ceil(share_certificates.length / certificatesPerPage)
    : Math.ceil(searchedCertificates.length / certificatesPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };
  /*  ---------------------  */
  /*  Pagination Code Ended  */
  /*  ---------------------  */
  const handleSearchChange = (
    e = "",
    company = "",
    folio_number = "",
    status = ""
  ) => {
    const filtered_data = [];
    !!e && setSearch(e.target.value);
    !!e && setUnderSearch(e.target.value);
    !!status && setUnderSearch(status);
    !!folio_number && setUnderSearch(folio_number);
    !!company && setUnderSearch(company);
    !e && !status && !folio_number && !company && setSearch("");
    if (!!e) {
      if (e.target.value.length > 0) {
        if (share_certificates.length > 0) {
          setSearchedCertificates(
            share_certificates.filter((cert) => {
              if (!!cert?.certificate_no)
                return cert.certificate_no.match(
                  generateRegex(`${company_code}-${e.target.value}`)
                );
              else return false;
            })
          );
        }
      }
    }
    if (!!company) {
      for (let index = 0; index < share_certificates.length; index++) {
        const obj = share_certificates[index];
        if (obj.company_code === company) filtered_data.push(obj);
      }
      setSearchedCertificates(filtered_data);
    }
    if (!!folio_number) {
      for (let index = 0; index < share_certificates.length; index++) {
        const obj = share_certificates[index];
        if (obj.allotted_to === folio_number) filtered_data.push(obj);
      }
      setSearchedCertificates(filtered_data);
    }
    if (!!status) {
      for (let index = 0; index < share_certificates.length; index++) {
        const obj = share_certificates[index];
        if (obj.status === status) filtered_data.push(obj);
      }
      setSearchedCertificates(filtered_data);
    }
  };

  return (
    <Fragment>
      {/* bread crumb at the top of the page */}
      {/* <div className="d-flex justify-content-between">
        <div>
          <Breadcrumb
            title="Share Certificate"
            parent="Shareholdings"
            hideBookmark
          />
        </div>
      </div> */}

      {/* Add Modal */}
      {/* <Modal isOpen={viewAddPage} show={viewAddPage.toString()} size="xl">
        <ModalHeader
          toggle={() => {
            setViewAddPage(false);
          }}
        >
          Add Share Certificate Issuance
        </ModalHeader>
        <ModalBody>
          <AddShareCertificateIssuance setViewAddPage={setViewAddPage} />
        </ModalBody>
      </Modal> */}
      {/* Edit Modal */}
      {/* <Modal isOpen={viewEditPage} show={viewEditPage.toString()} size="xl">
        <ModalHeader
          toggle={() => {
            setViewEditPage(false);
          }}
        >
          Share Certificate Issuance Edit
        </ModalHeader>
        <ModalBody>
          <EditShareCertificateIssuance setViewEditPage={setViewEditPage} />
        </ModalBody>
      </Modal> */}
      {/* View Modal */}
      <Modal isOpen={viewFlag} show={viewFlag.toString()} size="xl">
        <ModalHeader
          toggle={() => {
            setViewFlag(false);
          }}
        >
          View Certificate History
        </ModalHeader>
        <ModalBody>
          <ViewCertificateHistory />
        </ModalBody>
      </Modal>

      <div className="container-fluid ">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header ">
                <WrapperForResponsive>
                  <div className="d-flex justify-content-between">
                    <div>
                      <h5>Shareholder Certificates </h5>
                    </div>
                    {crudFeatures[1] && (
                      <div>
                        <button
                          className="btn btn-info btn-sm float-right d-flex align-items-center"
                          onClick={() => {
                            setCollapse(!collapse);
                          }}
                        >
                          Filter &nbsp;
                          <i
                            class={
                              collapse === false
                                ? "fa fa-angle-down float-right"
                                : "fa fa-angle-up float-right"
                            }
                            aria-controls="collapse-search"
                            aria-expanded={collapse}
                            title={
                              collapse === false
                                ? "View Search"
                                : "Close Search"
                            }
                          ></i>
                        </button>
                      </div>
                    )}
                  </div>
                </WrapperForResponsive>
              </div>
              <div className="card-header">
                <div className=" d-flex justify-content-between">
                  {/* {crudFeatures[0] && (
                    <button
                      className="btn btn-primary btn-sm"
                      onClick={() => {
                        // for modal
                        setViewAddPage(true);
                      }}
                    >
                      <i className="fa fa-plus mr-1"></i> Issue Share
                      Certificate
                    </button>
                  )} */}
                </div>

                {/* <button type="button" class="btn btn-primary btn-sm float-right"
                 onClick={() => {
                  setOpen(!open)
                 }}
                 aria-controls="example-collapse-text"
                 aria-expanded={open}>Search</button> */}
                {/* <i class="fa fa-angle-down float-right" */}

                <Collapse in={collapse}>
                  <div id="collapse-search">
                    <div className="row mt-2 ml-1">
                      <div className="col-sm-12 col-lg-6 col-md-6">
                        <div className="form-group">
                          <label htmlFor="searchTransaction">
                            Search Certificate
                          </label>
                          <input
                            id="searchTransaction"
                            className="form-control"
                            type="text"
                            placeholder={"Enter Certifiacte No"}
                            value={search}
                            onChange={(e) => {
                              handleSearchChange(e, "", "", "");
                            }}
                            isClearable={true}
                          />
                        </div>
                      </div>
                      {/* Company name */}
                      {/* <div className="col-sm-12 col-lg-3 col-md-3">
                    <div className="form-group">
                      <label htmlFor="searchTransaction">Select Company</label>
                      <Select
                        options={companies.companies_dropdown}
                        isLoading={
                          companies.companies_dropdown_loading === true
                        }
                        onChange={(selected) => {
                          selected &&
                            handleSearchChange("", selected.value, "", "");
                          !selected && setUnderSearch("");
                          selected && setSelectedCompany(selected.value);
                        }}
                        isClearable={true}
                        styles={darkStyle}
                      />
                    </div>
                  </div> */}
                      {/* Folio Number */}
                      {/* <div className="col-sm-12 col-lg-3 col-md-3">
                    <label htmlFor="email">Folio Number </label>
                    <Select
                      options={share_holders.shareholders_dropdown}
                      isLoading={
                        share_holders.shareholders_dropdown_loading === true
                      }
                      onChange={(selected) => {
                        selected &&
                          handleSearchChange("", "", selected.value, "");
                        !selected && setUnderSearch("");
                        selected && setSelectedHolding(selected.value);
                      }}
                      styles={darkStyle}
                      isClearable={true}
                    />
                  </div> */}
                      <div className="col-sm-12 col-lg-6 col-md-6">
                        <label htmlFor="email">Status </label>
                        <Select
                          options={certificate_status}
                          isLoading={certificate_status.length === 0}
                          onChange={(selected) => {
                            selected &&
                              handleSearchChange("", "", "", selected.value);
                            !selected && setUnderSearch("");

                            selected && setStatus(selected.value);
                          }}
                          isClearable={true}
                          styles={darkStyle}
                        />
                      </div>
                    </div>
                  </div>
                </Collapse>
              </div>

              {share_certificates_loading === true && <Spinner />}
              {share_certificates.length !== 0 &&
                share_certificates_loading === false && (
                  <div className="table-responsive px-3">
                    <table className="table  ">
                      <thead>
                        <tr>
                          <th>S # </th>
                          <th>Folio #</th>
                          <th>Alloted To</th>
                          <th>Certificate #</th>
                          {/* <th>Company</th> */}
                          <th>From</th>
                          <th>To</th>
                          <th className="text-right">Shares Count</th>
                          <th>Status</th>
                          <th>Transaction ID</th>
                          <th>History</th>
                        </tr>
                      </thead>

                      <tbody>{displayCertificatesPerPage}</tbody>
                    </table>
                    <center className="d-flex justify-content-center py-3">
                      <nav className="pagination">
                        <ReactPaginate
                          previousLabel="Previous"
                          nextLabel="Next"
                          pageCount={pageCount}
                          onPageChange={changePage}
                          marginPagesDisplayed={1}
                          pageRangeDisplayed={3}
                          containerClassName={"pagination"}
                          previousClassName={"page-item"}
                          previousLinkClassName={"page-link"}
                          nextClassName={"page-item"}
                          nextLinkClassName={"page-link"}
                          disabledClassName={"disabled"}
                          pageLinkClassName={"page-link"}
                          pageClassName={"page-item"}
                          activeClassName={"page-item active"}
                          activeLinkClassName={"page-link"}
                        />
                      </nav>
                    </center>
                  </div>
                )}
              {share_certificates_loading === false &&
                share_certificates.length === 0 && (
                  <p className="text-center">
                    <b>Share Certificates Data not available</b>
                  </p>
                )}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ShareCertificate;

const WrapperForResponsive = styled.div`
  @media (max-width: 576px) {
    .shareholders-title {
      font-size: 14px;
      margin-bottom: 5px;
    }

    .btn-sm {
      font-size: 12px;
      padding: 0.25rem 0.5rem;
    }
  }
`;
