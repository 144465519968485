import React, { useState, useEffect } from 'react'
import { PDFExport } from "@progress/kendo-react-pdf";
import PageTemplate from "./page-template";
import {
    getCompanies,
} from "../../../store/services/company.service";
import styled from 'styled-components';
import { TiTick } from "react-icons/ti";
import { numberWithCommas } from 'utilities/utilityFunctions';
export const Appendix1 = ({ data, formTemplate = false }) => {
    const pdfExportComponent = React.useRef(null);
    const [CompanyData, setCompanyData] = useState(null)
    const [loading, setLoading] = useState(false);
    const [fontFamilyForStaticData, setFontFamilyForStaticData] = useState('Montserrat, sans-serif')
    const [fontSizeForStaticData, setFontSizeForStaticData] = useState('14px')
    const [fontfamilyforDynimicData, setFontFamilyForDynmicData] = useState('Segoe Print')
    const [fontColor, setFontColor] = useState('')
    const baseEmail = sessionStorage.getItem("email") || "";
    const [governance, setGovernance] = useState({})
    const getAllCompanies = async () => {
        setLoading(true)
        try {
            const response = await getCompanies(baseEmail);
            if (response.status === 200) {
                response.data.data = response.data.data.filter((item) => item?.active === 'Y')
                const parents = response.data.data;
                const company = parents?.find(ite => ite?.code == data?.company_code)
                let Allperson = company.governance && company.governance != "" ? JSON.parse(company.governance) : []
                let sec = Allperson.find(x => x.role == "Company Secretary" && x?.active?.toLowerCase() != 'n')
                let ceo = Allperson.find(x => x.role == "CEO" && x?.active?.toLowerCase() != 'n')


                if (sec) {
                    setGovernance(sec)
                } else if (ceo) {
                    setGovernance(ceo)
                }
                setCompanyData(company)
                setTimeout(() => {
                    setLoading(false)
                }, 1000);
            }
        } catch (error) {
            setLoading(false)
        }
    };
    useEffect(() => {
        getAllCompanies()
    }, [])
    return (
        <div>
            <div
                style={{ fontFamily: "sans-serif " }}
            >

                {formTemplate ?
                    <PDFExport
                        paperSize="A4"
                        margin="1cm"
                        scale={0.6}
                        fileName={`Appendix-1`}
                        pageTemplate={PageTemplate}
                        ref={pdfExportComponent}
                    >
                        <div style={{ fontFamily: `${fontFamilyForStaticData}`, fontSize: `${fontSizeForStaticData}`, fontWeight: 500 }}>
                            <FormName>
                                Appendix-1
                            </FormName>
                            <FormName>
                            [Regulation 121(1)(f)]
                            </FormName>
                            
                           


                            <div className='row mt-4'>
                                <div className='col-md-12'>
                                   
                                    <HeadingWrapper className='text-center d-flex justify-content-center'>
                                    STATEMENT OF COMPLIANCE BY ALL DIRECTORS & CEO
                                    </HeadingWrapper>

                                </div>
                            </div>

                            

                            <div className='row'>
                                <div className='col-md-12' style={{ padding: '25px 50px', wordWrap: 'break-word' }}>
                                    <div>
                                    We, being the directors and the CEO of ................. (name of the purchasing company), do hereby solemnly and sincerely state the:
                                    </div>
                                    <div className='d-flex'>
                                        <div>(I)</div><div style={{marginLeft:"29px"}}> The Company is fully compliant with all applicable laws, rules and regulations;</div>
                                    </div>
                                    <div className='d-flex'>
                                        <div>(II)</div><div style={{marginLeft:"26px"}}>In case the company is engaged in any business activity which is subject to license or approval, it is compliant with the applicable laws;</div>
                                    </div>
                                    <div className='d-flex'>
                                        <div>(III)</div><div style={{marginLeft:"22px"}}>The Company has complied with the provisions of the Companies Act, 2017 as well as the Companies Regulations, 2024 during the process of buy-back of shares of the Company.</div>
                                    </div>
                                    
                                
                                        <div style={{ fontWeight: "bold" }} className='mt-2'>
                                        That whatever has been stated above is true and correct to the best of our knowledge and belief.
                                        </div>
                                    <div style={{width:"38%"}} className='mt-4'>
                                    (Signed by all the directors including CEO with names, Designations,residential addresses and CNIC numbers)

                                    </div>
                                    <div  className='mt-2 d-flex '>
                                        Date:_______________
                                    </div>
                                    <div  className='mt-2 d-flex '>
                                        Place:_______________
                                    </div>
                                </div>
                            </div>
                        </div>
                    </PDFExport>
                    : <PDFExport
                        paperSize="A4"
                        margin="1cm"
                        scale={0.6}
                        fileName={`Appendix-1`}
                        pageTemplate={PageTemplate}
                        ref={pdfExportComponent}
                    >
                         <div style={{ fontFamily: `${fontFamilyForStaticData}`, fontSize: `${fontSizeForStaticData}`, fontWeight: 500 }}>
                            <FormName>
                                Appendix-1
                            </FormName>
                            <FormName>
                            [Regulation 121(1)(f)]
                            </FormName>
                            
                           


                            <div className='row mt-4'>
                                <div className='col-md-12'>
                                   
                                    <HeadingWrapper className='text-center d-flex justify-content-center'>
                                    STATEMENT OF COMPLIANCE BY ALL DIRECTORS & CEO
                                    </HeadingWrapper>

                                </div>
                            </div>

                            

                            <div className='row'>
                                <div className='col-md-12' style={{ padding: '25px 50px', wordWrap: 'break-word' }}>
                                    <div>
                                    We, being the directors and the CEO of ................. (name of the purchasing company), do hereby solemnly and sincerely state the:
                                    </div>
                                    <div className='d-flex'>
                                        <div>(I)</div><div style={{marginLeft:"29px"}}> The Company is fully compliant with all applicable laws, rules and regulations;</div>
                                    </div>
                                    <div className='d-flex'>
                                        <div>(II)</div><div style={{marginLeft:"26px"}}>In case the company is engaged in any business activity which is subject to license or approval, it is compliant with the applicable laws;</div>
                                    </div>
                                    <div className='d-flex'>
                                        <div>(III)</div><div style={{marginLeft:"22px"}}>The Company has complied with the provisions of the Companies Act, 2017 as well as the Companies Regulations, 2024 during the process of buy-back of shares of the Company.</div>
                                    </div>
                                    
                                
                                        <div style={{ fontWeight: "bold" }} className='mt-2'>
                                        That whatever has been stated above is true and correct to the best of our knowledge and belief.
                                        </div>
                                    <div style={{width:"38%"}} className='mt-4'>
                                    (Signed by all the directors including CEO with names, Designations,residential addresses and CNIC numbers)

                                    </div>
                                    <div  className='mt-2 d-flex '>
                                        Date:_______________
                                    </div>
                                    <div  className='mt-2 d-flex '>
                                        Place:_______________
                                    </div>
                                </div>
                            </div>
                        </div>
                    </PDFExport>}
            </div>
            <button disabled={loading} className='btn btn-danger' onClick={(e) => {
                // if(viewPdf)
                //   SetViewPDf(true)
                if (pdfExportComponent.current) {
                    pdfExportComponent.current.save();
                }
            }}>Download PDF</button>
        </div>
    )
}
const PartsWrapper = styled.div`
color: #121212;
text-decoration: underline;
justify-content: center;
display: flex;
margin-right: 15px;
font-size: 17px;
font-weight: bold;
`;
const ScrollWrapper = styled.div`
max-height: 50px;
overflow-y: scroll;
overflow-x: hidden;
::-webkit-scrollbar{
    height: 5px;
    width: 3px;
  }
  
  ::-webkit-scrollbar-track{
    background: #F9F9FB;
  }
  ::-webkit-scrollbar-thumb{
    background: #4E515680;
    border-radius: 5px;
  
  }
`;
const HeadingWrapper = styled.div`
font-size: 17px;
font-weight: bold;
`;
const FormName = styled.div`
color: #121212;
text-decoration: underline;
justify-content: end;
display: flex;
margin-right: 15px;
font-size: 17px;
font-weight: bold;
`;
const Wrapper = styled.div`
table.table-bordered{
    border: 1px solid #121212
     border-color: #121212; 
    // border:1px solid blue;
    // margin-top:20px;
  }
// table.table-bordered > thead > tr > th{
//     // border:1px solid blue;
//     border-color: #121212; 
// }
// table.table-bordered > tbody > tr > td{
//     // border:1px solid blue;
//     borderC-color: #121212; 
// }
 tr td {
    // border:1px solid blue;.
    border-color: #121212; 
}

`;
const WrapperDashed = styled.div`

  td {
    
    border: 1px dashed #dddcdc;
}

`;