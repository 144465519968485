import React, { Fragment, useEffect, useState } from "react";
import man from "../../../assets/images/dashboard/user.png";
import { Link } from "react-router-dom";
import { Edit } from "react-feather";
import { ELANA, GeneralManager } from "../../../constant";
import logo from "../../../assets/IssuersApp-logo-wide.svg";

const UserPanel = () => {
  const url = "";
  let Wrapper = localStorage.getItem("wrapper")
 
  return (
    <Fragment>
      <div className="sidebar-user text-center">
        <div 
        >
          <img src={logo} alt="" width= {Wrapper === "compact-wrapper" ? "120" : "220"} />
          {/* <img
            className="img-60 rounded-circle lazyloaded blur-up"
            src={url ? url : man}
            alt="#"
          /> */}
          <div className="profile-edit">
            {/* <Link to={`/users/userEdit`}>
              <Edit />
            </Link> */}
          </div>
        </div>
        {/* <h6 className="mt-3 f-14">{sessionStorage.getItem("name")}</h6>
        <p>{sessionStorage.getItem("role")}</p> */}
      </div>
    </Fragment>
  );
};

export default UserPanel;
