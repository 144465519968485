import React, { useState, useEffect } from 'react'
import { PDFExport } from "@progress/kendo-react-pdf";
import PageTemplate from "./page-template";
import {
    getCompanies,
} from "../../../store/services/company.service";
import styled from 'styled-components';
import { TiTick } from "react-icons/ti";
import "./CommonForm.css"
import { numberWithCommas } from 'utilities/utilityFunctions';
export const StatutoryForm11 = ({ data, formTemplate = false }) => {
    const pdfExportComponent = React.useRef(null);
    const [CompanyData, setCompanyData] = useState(null)
    const [loading, setLoading] = useState(false);
    const [fontFamilyForStaticData, setFontFamilyForStaticData] = useState('Montserrat, sans-serif')
    const [fontSizeForStaticData, setFontSizeForStaticData] = useState('14px')
    const [fontfamilyforDynimicData, setFontFamilyForDynmicData] = useState('Segoe Print')
    const [fontColor, setFontColor] = useState('')
    const baseEmail = sessionStorage.getItem("email") || "";
    const [governance, setGovernance] = useState({})
    const getAllCompanies = async () => {
        setLoading(true)
        try {
            const response = await getCompanies(baseEmail);
            if (response.status === 200) {
                response.data.data = response.data.data.filter((item) => item?.active === 'Y')
                const parents = response.data.data;
                const company = parents?.find(ite => ite?.code == data?.company_code)
                let Allperson = company.governance && company.governance != "" ? JSON.parse(company.governance) : []
                let sec = Allperson.find(x => x.role == "Company Secretary" && x?.active?.toLowerCase() != 'n')
                let ceo = Allperson.find(x => x.role == "CEO" && x?.active?.toLowerCase() != 'n')


                if (sec) {
                    setGovernance(sec)
                } else if (ceo) {
                    setGovernance(ceo)
                }
                setCompanyData(company)
                setTimeout(() => {
                    setLoading(false)
                }, 1000);
            }
        } catch (error) {
            setLoading(false)
        }
    };
    useEffect(() => {
        getAllCompanies()
    }, [])
    return (
        <div>
            <div
                style={{ fontFamily: "sans-serif " }}
            >

                {formTemplate ?
                    <PDFExport
                        paperSize="A4"
                        margin="1cm"
                        scale={0.6}
                        fileName={`Statutory Form 11`}
                        pageTemplate={PageTemplate}
                        ref={pdfExportComponent}
                    >
                        <div style={{ fontFamily: `${fontFamilyForStaticData}`, fontSize: `${fontSizeForStaticData}`, fontWeight: 500 }}>
                            <FormName>
                                Form-11
                            </FormName>
                            <div className='row'>
                                <div className='col-md-12' >
                                    <HeadingWrapper className='d-flex justify-content-center'>
                                        THE COMPANIES ACT, 2017
                                    </HeadingWrapper>
                                    <HeadingWrapper className='d-flex justify-content-center'>
                                        THE COMPANIES REGULATIONS, 2024
                                    </HeadingWrapper>

                                </div>
                            </div>

                            <div className='row'>
                                <div className='col-md-12'>
                                    <div style={{ fontSize: '15px', fontWeight: 'bold' }} className='d-flex justify-content-center'>
                                        [Section 452(2), 452(3) & 452(4) and Regulations 63 & 30]
                                    </div>
                                </div>
                            </div>

                            <div className='row mt-4'>
                                <div className='col-md-12'>
                                    <HeadingWrapper className='text-center d-flex justify-content-center'>
                                        RETURN CONTAINING PARTICULARS OF SUBSTANTIAL SHAREHOLDERS
                                    </HeadingWrapper>
                                    <HeadingWrapper className='text-center d-flex justify-content-center'>
                                        /OFFICERS AND COMPANIES FOR COMPANIES’ GLOBAL REGISTER OF
                                    </HeadingWrapper>
                                    <HeadingWrapper className='text-center d-flex justify-content-center'>
                                        BENEFICIAL OWNERSHIP
                                    </HeadingWrapper>
                                </div>
                            </div>
                            <PartsWrapper className='mt-4 mb-3'>
                                PART-I
                            </PartsWrapper>
                            <div className=''>
                                <div className='col-md-12' style={{ padding: '25px 50px', wordWrap: 'break-word' }}>
                                    <div> <i>(Please complete in bold capital letters)</i></div>

                                    <div className='d-flex' >
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>1.1</div>
                                        <div className='align-items-center' style={{ width: '28%', padding: '0px 10px 10px 10px' }}>CUIN (Registration Number)  </div>
                                        <div className='align-items-center  d-flex'  >{
                                            '1234 552'.split("")?.map((item, idx) => {
                                                if (item == ' ') return
                                                return <div style={{ minWidth: '30px', minHeight: '34px', border: '1px solid #121212', borderLeft: idx != 0 ? "none" : "1px solid #121212", padding: '6px 9px' }}></div>
                                            })
                                        }
                                        </div>
                                    </div>

                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>1.2</div>
                                        <div className='align-items-center' style={{ width: '29%', padding: '0px 10px 10px 10px' }}>Name of the Company  </div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '68%', padding: '0px 10px 10px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                    </div>

                                    <div className='d-flex  mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>1.3</div>
                                        <div className='align-items-center' style={{ width: '20.20%', padding: '0px 10px 10px 10px' }}>Fee payment Details*</div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '5%' }}>1.3.1</div>
                                        <div className='align-items-center' style={{ width: '13.20%', paddingLeft: '20px' }}>Challan No.</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '17.20%', paddingLeft: '20px', minHeight: '34px' }}></ScrollWrapper>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '5%' }}>1.3.2</div>
                                        <div className='align-items-center' style={{ width: '13.20%', paddingLeft: '20px' }}>Amount</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '23.20%', paddingLeft: '20px', minHeight: '34px' }}></ScrollWrapper>
                                    </div>
                                    <PartsWrapper className='mt-4 mb-3'>
                                        PART-II
                                    </PartsWrapper>
                                    <div className='row mt-4'>
                                        <div className='col-md-12'>
                                            <HeadingWrapper className='d-flex '>
                                                (Applicable in case of particulars of substantial shareholders /officers for companies’ global register of beneficial ownership)
                                            </HeadingWrapper>
                                            <HeadingWrapper className='d-flex  mt-3'>
                                                Particulars of Beneficial Ownership*
                                            </HeadingWrapper>

                                        </div>
                                    </div>
                                    {/* <div className='offset-1 col-11 mb-3'>
                                        <h6 className='mb-3'>(Applicable in case of particulars of substantial shareholders /officers for companies’ global register of beneficial ownership)</h6>
                                        <h6>Particulars of Beneficial Ownership*</h6>
                                    </div> */}
                                
                                <Wrapper className="table-responsive mt-3" >
                                    <table className="table table-bordered"
                                        style={{ fontFamily: "Montserrat rev=1 !important" }}>
                                        <tbody>
                                            <tr>
                                                <td className='border-all vertical-rl'>Sr. No.</td>
                                                <td className='border-all'>(i)</td>
                                                <td className='border-all'>(ii)</td>
                                                <td className='border-all'>(iii)</td>
                                                <td className='border-all'>(iv)</td>
                                                <td className='border-all'>(v)</td>
                                                <td className='border-all'>(vi)</td>
                                                <td className='border-all'>(vii)</td>
                                                <td className='border-all'>(viii)</td>
                                                <td className='border-all'>(ix)</td>
                                                <td className='border-all'>(x)</td>
                                                <td className='border-all'>(xi)</td>
                                                <td className='border-all'>(xii)</td>
                                                <td className='border-all'>(xiii)</td>
                                                <td className='border-all'>(xiv)</td>
                                            </tr>
                                            <tr>
                                                <td className='border-all'></td>
                                                <td className='border-all vertical-rl'>Name of Substantial Shareholder /Officer of the Company</td>
                                                <td className='border-all vertical-rl'>Designation(if officer of the Company)</td>
                                                <td className='border-all vertical-rl'>NIC/NICOP</td>
                                                <td className='border-all'>In case shares are owned, held or controlled indirectly, name and relations hip with officer/ substantial shareholder</td>
                                                <td className='border-all vertical-rl'>Name of foregin company or body corporate in which ownership is held</td>
                                                <td className='border-all'>No. of shares held in foreign company or body corporate</td>
                                                <td className='border-all'>Cost of investment</td>
                                                <td className='border-all vertical-rl'>Currency</td>
                                                <td className='border-all'>Any interest other than shareholding in foreign company or body corporate</td>
                                                <td className='border-all vertical-rl'>Percentage of shareholding or other interest in foreign company or body corporate</td>
                                                <td className='border-all'>Date of shareholding/investment</td>
                                                <td className='border-all'>Business address of foreign company or body corporate</td>
                                                <td className='border-all'>Name of registation auth ority of foreign company or body corporate</td>
                                                <td className='border-all'>County of incorporation of foreign company or body corporate</td>
                                            </tr>
                                            <tr>
                                                <td className='border-all p-0'></td>
                                                <td className='border-all p-0'></td>
                                                <td className='border-all'></td>
                                                <td className='border-all'></td>
                                                <td className='border-all'></td>
                                                <td className='border-all'></td>
                                                <td className='border-all'></td>
                                                <td className='border-all'></td>
                                                <td className='border-all'></td>
                                                <td className='border-all'></td>
                                                <td className='border-all'></td>
                                                <td className='border-all'></td>
                                                <td className='border-all'></td>
                                                <td className='border-all'></td>
                                                <td className='border-all'></td>
                                            </tr>
                                            <tr>
                                                <td className='border-all p-0'></td>
                                                <td className='border-all p-0'></td>
                                                <td className='border-all'></td>
                                                <td className='border-all'></td>
                                                <td className='border-all'></td>
                                                <td className='border-all'></td>
                                                <td className='border-all'></td>
                                                <td className='border-all'></td>
                                                <td className='border-all'></td>
                                                <td className='border-all'></td>
                                                <td className='border-all'></td>
                                                <td className='border-all'></td>
                                                <td className='border-all'></td>
                                                <td className='border-all'></td>
                                                <td className='border-all'></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <i className=' pl-5'>* Add additional rows for more shareholders/officers, if needed.</i>
                                </Wrapper>
                                <PartsWrapper className='mt-4 mb-3'>
                                        PART-III
                                    </PartsWrapper>
                                    <div className='row mt-4'>
                                        <div className='col-md-12'>
                                            <HeadingWrapper className='d-flex justify-content-center'>
                                                (Applicable in case of particulars of Companies for companies’ global register of beneficial ownership)
                                            </HeadingWrapper>
                                        </div>
                                    </div>
                                    <Wrapper className="table-responsive mt-5" >
                                    <table className="table table-bordered"
                                        style={{ fontFamily: "Montserrat rev=1 !important" }}>
                                        <tbody>
                                            <tr>
                                                <td className='border-all vertical-rl'>Sr. No.</td>
                                                <td className='border-all'>(i)</td>
                                                <td className='border-all'>(ii)</td>
                                                <td className='border-all'>(iii)</td>
                                                <td className='border-all'>(iv)</td>
                                                <td className='border-all'>(v)</td>
                                                <td className='border-all'>(vi)</td>
                                                <td className='border-all'>(vii)</td>
                                                <td className='border-all'>(viii)</td>
                                                <td className='border-all'>(ix)</td>
                                                <td className='border-all'>(x)</td>
                                            </tr>
                                            <tr>
                                                <td className='border-all'></td>
                                                <td className='border-all '>Name of foreign company or body corporate in which ownership is held</td>
                                                <td className='border-all '>No. of shares held in foreign company or body corporate</td>
                                                <td className='border-all vertical-rl'>Cost of investment</td>
                                                <td className='border-all vertical-rl'>Currency</td>
                                                <td className='border-all'>Any interest other than shareholding in foreign company or body corporate</td>
                                                <td className='border-all'>Percentage of shareholding or other interest in foreign company or body corporate</td>
                                                <td className='border-all'>Date of shareholding /investment</td>
                                                <td className='border-all'>Business address of foreign company or body corporate</td>
                                                <td className='border-all'>Name of registration authority of foreign company or bodycorporate</td>
                                                <td className='border-all'>Country of incorporation of foreign company or body corporate</td>
                                                
                                            </tr>
                                            <tr>
                                                <td className='border-all p-0'></td>
                                                <td className='border-all p-0'></td>
                                                <td className='border-all'></td>
                                                <td className='border-all'></td>
                                                <td className='border-all'></td>
                                                <td className='border-all'></td>
                                                <td className='border-all'></td>
                                                <td className='border-all'></td>
                                                <td className='border-all'></td>
                                                <td className='border-all'></td>
                                                <td className='border-all'></td>
                                               
                                            </tr>
                                            <tr>
                                                <td className='border-all p-0'></td>
                                                <td className='border-all p-0'></td>
                                                <td className='border-all'></td>
                                                <td className='border-all'></td>
                                                <td className='border-all'></td>
                                                <td className='border-all'></td>
                                                <td className='border-all'></td>
                                                <td className='border-all'></td>
                                                <td className='border-all'></td>
                                                <td className='border-all'></td>
                                                <td className='border-all'></td>
                                               
                                            </tr>
                                           
                                        </tbody>
                                    </table>
                                    
                                </Wrapper>
                                <PartsWrapper className='mt-4 mb-3'>
                                        PART-IV
                                    </PartsWrapper>
                                <div className='d-flex mt-2'>
                                    <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>4.1</div>
                                    <div className='align-items-center' style={{ padding: ' 5px 10px' }}>Declaration</div>
                                </div>
                                <div className='d-flex mt-2'>
                                    <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                    <div className='align-items-center' style={{ padding: ' 5px 10px' }}>I do hereby solemnly and sincerely declare that the information provided in the form is:</div>
                                </div>
                                <div className='d-flex mt-2'>
                                    <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                    <div className='align-items-start d-flex' style={{ padding: ' 5px 10px', gap: "12px" }}><div>
                                        (i)</div>
                                        <div style={{marginLeft:"2px"}}>true and correct to the best of my knowledge, in consonance with the record as maintained by the Company and nothing has been concealed; and</div>
                                    </div>
                                </div>
                                <div className='d-flex mt-2'>
                                    <div className='align-items-center' style={{ paddingLeft: '2px', width: '3.4%' }}></div>
                                    <div className='align-items-start d-flex' style={{ padding: ' 5px 10px', gap: "12px" }}><div>
                                        (ii)</div>
                                        <div>hereby reported after complying with and fulfilling all requirements under the relevant provisions of law, rules, regulations, directives, circulars and notifications whichever is applicable. </div>
                                    </div>
                                </div>
                               

                                <div className='d-flex mt-2'>
                                    <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>4.2</div>
                                    <div className='align-items-center' style={{ width: '28%', padding: ' 0px 10px 10px 10px' }}> Name of Authorized Officer with designation/ Authorized Intermediary, if any</div>
                                    <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '32.5%', padding: ' 5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                    <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', borderLeft: "none", width: '32.5%', padding: ' 5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                </div>
                                <div className='d-flex mt-2'>
                                    <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>4.3</div>
                                    <div className='align-items-center' style={{ width: '28%', padding: ' 0px 10px 10px 10px' }}>Signatures </div>
                                    <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '65%', padding: ' 5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                </div>
                                <div className='d-flex mt-2'>
                                    <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>4.4</div>
                                    <div className='align-items-center' style={{ width: '40%', padding: '0px 10px 10px 10px' }}>Registration No of Authorized Intermediary, if applicable</div>
                                    <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '53%', padding: ' 5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                </div>
                                <div className='d-flex mt-2'>
                                    <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                    <div className='align-items-center' style={{ width: '55%', padding: '0px 10px 10px 10px' }}> </div>
                                    <div className='align-items-center text-center' style={{ width: '8%', padding: ' 5px 10px' }}> Day</div>
                                    <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                    <div className='align-items-center text-center' style={{ width: '8%', padding: ' 5px 10px' }}> Month</div>
                                    <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                    <div className='align-items-center text-center' style={{ width: '16%', padding: ' 5px 10px' }}>Year </div>
                                    <div></div>
                                </div>
                                <div className='d-flex '>
                                    <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>4.5</div>
                                    <div className='align-items-center' style={{ width: '55%', padding: '0px 10px 10px 10px' }}>Date</div>
                                    <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                    <div className='align-items-center' style={{ border: '1px solid #121212', width: '4%', padding: ' 5px 10px' }}> </div>
                                    <div className='align-items-center' style={{ paddingLeft: '8px', width: '3%' }}></div>
                                    <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                    <div className='align-items-center' style={{ border: '1px solid #121212', width: '4%', padding: ' 5px 10px', borderRight: '1px solid #121212' }}> </div>
                                    <div className='align-items-center' style={{ paddingLeft: '8px', width: '3%' }}></div>
                                    <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                    <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                    <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                    <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', border: '1px solid #121212', }}> </div>
                                </div>
                               
                               
                               
                                </div>
                            </div>
                        </div>
                    </PDFExport>
                    : <PDFExport
                        paperSize="A4"
                        margin="1cm"
                        scale={0.6}
                        fileName={`Statutory Form 11`}
                        pageTemplate={PageTemplate}
                        ref={pdfExportComponent}
                    >
                        <div style={{ fontFamily: `${fontFamilyForStaticData}`, fontSize: `${fontSizeForStaticData}`, fontWeight: 500 }}>
                            <FormName>
                                Form-11
                            </FormName>
                            <div className='row'>
                                <div className='col-md-12' >
                                    <HeadingWrapper className='d-flex justify-content-center'>
                                        THE COMPANIES ACT, 2017
                                    </HeadingWrapper>
                                    <HeadingWrapper className='d-flex justify-content-center'>
                                        THE COMPANIES REGULATIONS, 2024
                                    </HeadingWrapper>

                                </div>
                            </div>

                            <div className='row'>
                                <div className='col-md-12'>
                                    <div style={{ fontSize: '15px', fontWeight: 'bold' }} className='d-flex justify-content-center'>
                                        [Section 452(2), 452(3) & 452(4) and Regulations 63 & 30]
                                    </div>
                                </div>
                            </div>

                            <div className='row mt-4'>
                                <div className='col-md-12'>
                                    <HeadingWrapper className='text-center d-flex justify-content-center'>
                                        RETURN CONTAINING PARTICULARS OF SUBSTANTIAL SHAREHOLDERS
                                    </HeadingWrapper>
                                    <HeadingWrapper className='text-center d-flex justify-content-center'>
                                        /OFFICERS AND COMPANIES FOR COMPANIES’ GLOBAL REGISTER OF
                                    </HeadingWrapper>
                                    <HeadingWrapper className='text-center d-flex justify-content-center'>
                                        BENEFICIAL OWNERSHIP
                                    </HeadingWrapper>
                                </div>
                            </div>
                            <PartsWrapper className='mt-4 mb-3'>
                                PART-I
                            </PartsWrapper>
                            <div className=''>
                                <div className='col-md-12' style={{ padding: '25px 50px', wordWrap: 'break-word' }}>
                                    <div> <i>(Please complete in bold capital letters)</i></div>

                                    <div className='d-flex' >
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>1.1</div>
                                        <div className='align-items-center' style={{ width: '28%', padding: '0px 10px 10px 10px' }}>CUIN (Registration Number)  </div>
                                        <div className='align-items-center  d-flex'  >{
                                            '1234 552'.split("")?.map((item, idx) => {
                                                if (item == ' ') return
                                                return <div style={{ minWidth: '30px', minHeight: '34px', border: '1px solid #121212', borderLeft: idx != 0 ? "none" : "1px solid #121212", padding: '6px 9px' }}></div>
                                            })
                                        }
                                        </div>
                                    </div>

                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>1.2</div>
                                        <div className='align-items-center' style={{ width: '29%', padding: '0px 10px 10px 10px' }}>Name of the Company  </div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '68%', padding: '0px 10px 10px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                    </div>

                                    <div className='d-flex  mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>1.3</div>
                                        <div className='align-items-center' style={{ width: '20.20%', padding: '0px 10px 10px 10px' }}>Fee payment Details*</div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '5%' }}>1.3.1</div>
                                        <div className='align-items-center' style={{ width: '13.20%', paddingLeft: '20px' }}>Challan No.</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '17.20%', paddingLeft: '20px', minHeight: '34px' }}></ScrollWrapper>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '5%' }}>1.3.2</div>
                                        <div className='align-items-center' style={{ width: '13.20%', paddingLeft: '20px' }}>Amount</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '23.20%', paddingLeft: '20px', minHeight: '34px' }}></ScrollWrapper>
                                    </div>
                                    <PartsWrapper className='mt-4 mb-3'>
                                        PART-II
                                    </PartsWrapper>
                                    <div className='row mt-4'>
                                        <div className='col-md-12'>
                                            <HeadingWrapper className='d-flex '>
                                                (Applicable in case of particulars of substantial shareholders /officers for companies’ global register of beneficial ownership)
                                            </HeadingWrapper>
                                            <HeadingWrapper className='d-flex  mt-3'>
                                                Particulars of Beneficial Ownership*
                                            </HeadingWrapper>

                                        </div>
                                    </div>
                                    {/* <div className='offset-1 col-11 mb-3'>
                                        <h6 className='mb-3'>(Applicable in case of particulars of substantial shareholders /officers for companies’ global register of beneficial ownership)</h6>
                                        <h6>Particulars of Beneficial Ownership*</h6>
                                    </div> */}
                                
                                <Wrapper className="table-responsive mt-3" >
                                    <table className="table table-bordered"
                                        style={{ fontFamily: "Montserrat rev=1 !important" }}>
                                        <tbody>
                                            <tr>
                                                <td className='border-all vertical-rl'>Sr. No.</td>
                                                <td className='border-all'>(i)</td>
                                                <td className='border-all'>(ii)</td>
                                                <td className='border-all'>(iii)</td>
                                                <td className='border-all'>(iv)</td>
                                                <td className='border-all'>(v)</td>
                                                <td className='border-all'>(vi)</td>
                                                <td className='border-all'>(vii)</td>
                                                <td className='border-all'>(viii)</td>
                                                <td className='border-all'>(ix)</td>
                                                <td className='border-all'>(x)</td>
                                                <td className='border-all'>(xi)</td>
                                                <td className='border-all'>(xii)</td>
                                                <td className='border-all'>(xiii)</td>
                                                <td className='border-all'>(xiv)</td>
                                            </tr>
                                            <tr>
                                                <td className='border-all'></td>
                                                <td className='border-all vertical-rl'>Name of Substantial Shareholder /Officer of the Company</td>
                                                <td className='border-all vertical-rl'>Designation(if officer of the Company)</td>
                                                <td className='border-all vertical-rl'>NIC/NICOP</td>
                                                <td className='border-all'>In case shares are owned, held or controlled indirectly, name and relations hip with officer/ substantial shareholder</td>
                                                <td className='border-all vertical-rl'>Name of foregin company or body corporate in which ownership is held</td>
                                                <td className='border-all'>No. of shares held in foreign company or body corporate</td>
                                                <td className='border-all'>Cost of investment</td>
                                                <td className='border-all vertical-rl'>Currency</td>
                                                <td className='border-all'>Any interest other than shareholding in foreign company or body corporate</td>
                                                <td className='border-all vertical-rl'>Percentage of shareholding or other interest in foreign company or body corporate</td>
                                                <td className='border-all'>Date of shareholding/investment</td>
                                                <td className='border-all'>Business address of foreign company or body corporate</td>
                                                <td className='border-all'>Name of registation auth ority of foreign company or body corporate</td>
                                                <td className='border-all'>County of incorporation of foreign company or body corporate</td>
                                            </tr>
                                            <tr>
                                                <td className='border-all p-0'></td>
                                                <td className='border-all p-0'></td>
                                                <td className='border-all'></td>
                                                <td className='border-all'></td>
                                                <td className='border-all'></td>
                                                <td className='border-all'></td>
                                                <td className='border-all'></td>
                                                <td className='border-all'></td>
                                                <td className='border-all'></td>
                                                <td className='border-all'></td>
                                                <td className='border-all'></td>
                                                <td className='border-all'></td>
                                                <td className='border-all'></td>
                                                <td className='border-all'></td>
                                                <td className='border-all'></td>
                                            </tr>
                                            <tr>
                                                <td className='border-all p-0'></td>
                                                <td className='border-all p-0'></td>
                                                <td className='border-all'></td>
                                                <td className='border-all'></td>
                                                <td className='border-all'></td>
                                                <td className='border-all'></td>
                                                <td className='border-all'></td>
                                                <td className='border-all'></td>
                                                <td className='border-all'></td>
                                                <td className='border-all'></td>
                                                <td className='border-all'></td>
                                                <td className='border-all'></td>
                                                <td className='border-all'></td>
                                                <td className='border-all'></td>
                                                <td className='border-all'></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <i className=' pl-5'>* Add additional rows for more shareholders/officers, if needed.</i>
                                </Wrapper>
                                <PartsWrapper className='mt-4 mb-3'>
                                        PART-III
                                    </PartsWrapper>
                                    <div className='row mt-4'>
                                        <div className='col-md-12'>
                                            <HeadingWrapper className='d-flex justify-content-center'>
                                                (Applicable in case of particulars of Companies for companies’ global register of beneficial ownership)
                                            </HeadingWrapper>
                                        </div>
                                    </div>
                                    <Wrapper className="table-responsive mt-5" >
                                    <table className="table table-bordered"
                                        style={{ fontFamily: "Montserrat rev=1 !important" }}>
                                        <tbody>
                                            <tr>
                                                <td className='border-all vertical-rl'>Sr. No.</td>
                                                <td className='border-all'>(i)</td>
                                                <td className='border-all'>(ii)</td>
                                                <td className='border-all'>(iii)</td>
                                                <td className='border-all'>(iv)</td>
                                                <td className='border-all'>(v)</td>
                                                <td className='border-all'>(vi)</td>
                                                <td className='border-all'>(vii)</td>
                                                <td className='border-all'>(viii)</td>
                                                <td className='border-all'>(ix)</td>
                                                <td className='border-all'>(x)</td>
                                            </tr>
                                            <tr>
                                                <td className='border-all'></td>
                                                <td className='border-all '>Name of foreign company or body corporate in which ownership is held</td>
                                                <td className='border-all '>No. of shares held in foreign company or body corporate</td>
                                                <td className='border-all vertical-rl'>Cost of investment</td>
                                                <td className='border-all vertical-rl'>Currency</td>
                                                <td className='border-all'>Any interest other than shareholding in foreign company or body corporate</td>
                                                <td className='border-all'>Percentage of shareholding or other interest in foreign company or body corporate</td>
                                                <td className='border-all'>Date of shareholding /investment</td>
                                                <td className='border-all'>Business address of foreign company or body corporate</td>
                                                <td className='border-all'>Name of registration authority of foreign company or bodycorporate</td>
                                                <td className='border-all'>Country of incorporation of foreign company or body corporate</td>
                                                
                                            </tr>
                                            <tr>
                                                <td className='border-all p-0'></td>
                                                <td className='border-all p-0'></td>
                                                <td className='border-all'></td>
                                                <td className='border-all'></td>
                                                <td className='border-all'></td>
                                                <td className='border-all'></td>
                                                <td className='border-all'></td>
                                                <td className='border-all'></td>
                                                <td className='border-all'></td>
                                                <td className='border-all'></td>
                                                <td className='border-all'></td>
                                               
                                            </tr>
                                            <tr>
                                                <td className='border-all p-0'></td>
                                                <td className='border-all p-0'></td>
                                                <td className='border-all'></td>
                                                <td className='border-all'></td>
                                                <td className='border-all'></td>
                                                <td className='border-all'></td>
                                                <td className='border-all'></td>
                                                <td className='border-all'></td>
                                                <td className='border-all'></td>
                                                <td className='border-all'></td>
                                                <td className='border-all'></td>
                                               
                                            </tr>
                                           
                                        </tbody>
                                    </table>
                                    
                                </Wrapper>
                                <PartsWrapper className='mt-4 mb-3'>
                                        PART-IV
                                    </PartsWrapper>
                                <div className='d-flex mt-2'>
                                    <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>4.1</div>
                                    <div className='align-items-center' style={{ padding: ' 5px 10px' }}>Declaration</div>
                                </div>
                                <div className='d-flex mt-2'>
                                    <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                    <div className='align-items-center' style={{ padding: ' 5px 10px' }}>I do hereby solemnly and sincerely declare that the information provided in the form is:</div>
                                </div>
                                <div className='d-flex mt-2'>
                                    <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                    <div className='align-items-start d-flex' style={{ padding: ' 5px 10px', gap: "12px" }}><div>
                                        (i)</div>
                                        <div style={{marginLeft:"2px"}}>true and correct to the best of my knowledge, in consonance with the record as maintained by the Company and nothing has been concealed; and</div>
                                    </div>
                                </div>
                                <div className='d-flex mt-2'>
                                    <div className='align-items-center' style={{ paddingLeft: '2px', width: '3.4%' }}></div>
                                    <div className='align-items-start d-flex' style={{ padding: ' 5px 10px', gap: "12px" }}><div>
                                        (ii)</div>
                                        <div>hereby reported after complying with and fulfilling all requirements under the relevant provisions of law, rules, regulations, directives, circulars and notifications whichever is applicable. </div>
                                    </div>
                                </div>
                               

                                <div className='d-flex mt-2'>
                                    <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>4.2</div>
                                    <div className='align-items-center' style={{ width: '28%', padding: ' 0px 10px 10px 10px' }}> Name of Authorized Officer with designation/ Authorized Intermediary, if any</div>
                                    <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '32.5%', padding: ' 5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                    <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', borderLeft: "none", width: '32.5%', padding: ' 5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                </div>
                                <div className='d-flex mt-2'>
                                    <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>4.3</div>
                                    <div className='align-items-center' style={{ width: '28%', padding: ' 0px 10px 10px 10px' }}>Signatures </div>
                                    <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '65%', padding: ' 5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                </div>
                                <div className='d-flex mt-2'>
                                    <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>4.4</div>
                                    <div className='align-items-center' style={{ width: '40%', padding: '0px 10px 10px 10px' }}>Registration No of Authorized Intermediary, if applicable</div>
                                    <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '53%', padding: ' 5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                </div>
                                <div className='d-flex mt-2'>
                                    <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                    <div className='align-items-center' style={{ width: '55%', padding: '0px 10px 10px 10px' }}> </div>
                                    <div className='align-items-center text-center' style={{ width: '8%', padding: ' 5px 10px' }}> Day</div>
                                    <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                    <div className='align-items-center text-center' style={{ width: '8%', padding: ' 5px 10px' }}> Month</div>
                                    <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                    <div className='align-items-center text-center' style={{ width: '16%', padding: ' 5px 10px' }}>Year </div>
                                    <div></div>
                                </div>
                                <div className='d-flex '>
                                    <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>4.5</div>
                                    <div className='align-items-center' style={{ width: '55%', padding: '0px 10px 10px 10px' }}>Date</div>
                                    <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                    <div className='align-items-center' style={{ border: '1px solid #121212', width: '4%', padding: ' 5px 10px' }}> </div>
                                    <div className='align-items-center' style={{ paddingLeft: '8px', width: '3%' }}></div>
                                    <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                    <div className='align-items-center' style={{ border: '1px solid #121212', width: '4%', padding: ' 5px 10px', borderRight: '1px solid #121212' }}> </div>
                                    <div className='align-items-center' style={{ paddingLeft: '8px', width: '3%' }}></div>
                                    <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                    <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                    <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                    <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', border: '1px solid #121212', }}> </div>
                                </div>
                               
                               
                               
                                </div>
                            </div>
                        </div>
                    </PDFExport>}
            </div>
            <button disabled={loading} className='btn btn-danger' onClick={(e) => {
                // if(viewPdf)
                //   SetViewPDf(true)
                if (pdfExportComponent.current) {
                    pdfExportComponent.current.save();
                }
            }}>Download PDF</button>
        </div>
    )
}
const PartsWrapper = styled.div`
color: #121212;
text-decoration: underline;
justify-content: center;
display: flex;
margin-right: 15px;
font-size: 17px;
font-weight: bold;
`;
const ScrollWrapper = styled.div`
max-height: 50px;
overflow-y: scroll;
overflow-x: hidden;
::-webkit-scrollbar{
    height: 5px;
    width: 3px;
  }
  
  ::-webkit-scrollbar-track{
    background: #F9F9FB;
  }
  ::-webkit-scrollbar-thumb{
    background: #4E515680;
    border-radius: 5px;
  
  }
`;
const HeadingWrapper = styled.div`
font-size: 17px;
font-weight: bold;
`;
const FormName = styled.div`
color: #121212;
text-decoration: underline;
justify-content: end;
display: flex;
margin-right: 15px;
font-size: 17px;
font-weight: bold;
`;
const Wrapper = styled.div`
table.table-bordered{
    border: 1px solid #121212
     border-color: #121212; 
    // border:1px solid blue;
    // margin-top:20px;
  }
// table.table-bordered > thead > tr > th{
//     // border:1px solid blue;
//     border-color: #121212; 
// }
// table.table-bordered > tbody > tr > td{
//     // border:1px solid blue;
//     borderC-color: #121212; 
// }
 tr td {
    // border:1px solid blue;.
    border-color: #121212; 
}

`;
const WrapperDashed = styled.div`

  td {
    
    // border: 1px dashed #dddcdc;
}

`;