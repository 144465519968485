import React, { useState, useEffect } from 'react'
import { PDFExport } from "@progress/kendo-react-pdf";
import PageTemplate from "./page-template";
import {
    getCompanies,
} from "../../../store/services/company.service";
import styled from 'styled-components';
import { TiTick } from "react-icons/ti";
import { numberWithCommas } from 'utilities/utilityFunctions';
import "./CommonForm.css"
export const StatutoryForm21 = ({ data, formTemplate = false }) => {
    const pdfExportComponent = React.useRef(null);
    const [CompanyData, setCompanyData] = useState(null)
    const [loading, setLoading] = useState(false);
    const [fontFamilyForStaticData, setFontFamilyForStaticData] = useState('Montserrat, sans-serif')
    const [fontSizeForStaticData, setFontSizeForStaticData] = useState('14px')
    const [fontfamilyforDynimicData, setFontFamilyForDynmicData] = useState('Segoe Print')
    const [fontColor, setFontColor] = useState('')
    const baseEmail = sessionStorage.getItem("email") || "";
    const [governance, setGovernance] = useState({})
    const getAllCompanies = async () => {
        setLoading(true)
        try {
            const response = await getCompanies(baseEmail);
            if (response.status === 200) {
                response.data.data = response.data.data.filter((item) => item?.active === 'Y')
                const parents = response.data.data;
                const company = parents?.find(ite => ite?.code == data?.company_code)
                let Allperson = company.governance && company.governance != "" ? JSON.parse(company.governance) : []
                let sec = Allperson.find(x => x.role == "Company Secretary" && x?.active?.toLowerCase() != 'n')
                let ceo = Allperson.find(x => x.role == "CEO" && x?.active?.toLowerCase() != 'n')


                if (sec) {
                    setGovernance(sec)
                } else if (ceo) {
                    setGovernance(ceo)
                }
                setCompanyData(company)
                setTimeout(() => {
                    setLoading(false)
                }, 1000);
            }
        } catch (error) {
            setLoading(false)
        }
    };
    useEffect(() => {
        getAllCompanies()
    }, [])
    return (
        <div>
            <div
                style={{ fontFamily: "sans-serif " }}
            >

                {formTemplate ?
                    <PDFExport
                        paperSize="A4"
                        margin="1cm"
                        scale={0.6}
                        fileName={`Form 21`}
                        pageTemplate={PageTemplate}
                        ref={pdfExportComponent}
                    >
                        <div style={{ fontFamily: `${fontFamilyForStaticData}`, fontSize: `${fontSizeForStaticData}`, fontWeight: 500 }}>
                            <FormName>
                                Form-21
                            </FormName>
                            <div className='row'>
                                <div className='col-md-12' >
                                    <HeadingWrapper className='d-flex justify-content-center'>
                                        THE COMPANIES ACT, 2017
                                    </HeadingWrapper>

                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <HeadingWrapper className='d-flex justify-content-center'>
                                        THE COMPANIES REGULATIONS, 2024
                                    </HeadingWrapper>

                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <div style={{
                                        fontSize: '15px',
                                        // fontWeight: 'bold' 
                                    }} className='d-flex justify-content-center'>
                                        [Section 21, 220 & 449 and Regulation 30]
                                    </div>

                                </div>
                            </div>

                            <div className='row mt-4'>
                                <div className='col-md-12'>
                                    <HeadingWrapper className='text-center d-flex justify-content-center'>
                                        NOTICE OF SITUATION OF REGISTERED OFFICE ADDRESS OR ANY
                                    </HeadingWrapper>
                                    <HeadingWrapper className='text-center d-flex justify-content-center'>
                                        CHANGE THEREIN OR NOTICE OF ADDRESS AT WHICH BOOKS OF
                                    </HeadingWrapper>
                                    <HeadingWrapper className='text-center d-flex justify-content-center'>
                                        ACCOUNTS ARE MAINTAINED
                                    </HeadingWrapper>

                                </div>
                            </div>

                            <PartsWrapper className='mt-4 mb-3'>
                                PART-I
                            </PartsWrapper>

                            <div className='row'>
                                <div className='col-md-12' style={{ padding: '25px 50px', wordWrap: 'break-word' }}>

                                    <div className='col-12'>
                                        <em>(Please complete in bold capital letters)</em>
                                    </div>
                                    {/* section 1.1 */}
                                    <div className='col-12 mb-3'>
                                        <div className='row'>
                                            <div className='col-5 d-flex '>
                                                <span className='form-sr-no'>1.1 </span>CUIN (Registration Number)</div>
                                            <div className='col-7 reg-cells'>
                                                <div style={{ minHeight: "30px" }} className=" reg-no reg-cell" ></div>
                                                <div style={{ minHeight: "30px" }} className=" reg-no reg-cell" ></div>
                                                <div style={{ minHeight: "30px" }} className=" reg-no reg-cell" ></div>
                                                <div style={{ minHeight: "30px" }} className=" reg-no reg-cell" ></div>
                                                <div style={{ minHeight: "30px" }} className=" reg-no reg-cell" ></div>
                                                <div style={{ minHeight: "30px" }} className=" reg-no reg-cell" ></div>
                                                <div style={{ minHeight: "30px" }} className=" reg-no border-all" ></div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* section 1.2 */}
                                    <div className='col-12 mb-3'>
                                        <div className='row'>
                                            <div className='col-5 d-flex '><span className='form-sr-no mt-2'>1.2 </span>
                                                <span className='mt-2'>Name of the Company</span>
                                            </div>
                                            <div className='col-7'>
                                                <div style={{ minHeight: "30px" }} className=" company-name border-all ml-1" />
                                            </div>
                                        </div>
                                    </div>
                                    {/* section 1.3 */}
                                    <div className='col-12 mb-5'>
                                        <div className='row mt-2'>
                                            <div className='col-3 d-flex '><span className='form-sr-no'>1.3 </span>Fee Payment Details</div>
                                            {/* section 1.3.1 */}
                                            <div className='col-*'>
                                                <div className="d-flex align-items-middle ">
                                                    <span className='mx-3'>1.3.1 </span><span className='ml-3'>Challan No</span>
                                                    <div className=" challan-no border-all ml-3" ></div>
                                                </div>
                                            </div>
                                            {/* section 1.3.2 */}
                                            <div className='col-*'>
                                                <div className="d-flex align-items-middle ">
                                                    <span className='mx-3'>1.3.2 </span><span className='ml-1'>Amount</span>
                                                    <div className=" ml-3 amount border-all" ></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* seciton 1.4 */}
                                    <div className='col-12 d-flex form-21-particulars mb-3'>
                                        <table className="table">
                                            <tbody>
                                                <tr>
                                                    <td colSpan={2} className="border border-dark particulars-col-1">
                                                        <div className="d-flex">
                                                            <strong className=" ml-2">1.4 Notice of </strong>
                                                            <span className="particulars-line"></span>
                                                        </div>
                                                    </td>
                                                    <td className="border border-dark particulars-col-2">
                                                        <div className="font-size-medium text-center">
                                                            <strong className='d-block word-spacing-10'>Please tick the</strong>
                                                            <strong>relevent box</strong>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="border border-dark vertical-align-top ">
                                                        Part-II
                                                    </td>
                                                    <td className="border border-dark vertical-align-top ">
                                                        <div className='d-flex h-100'>
                                                            {/* <span className='notices-part'>Part-II</span> */}
                                                            <span className='notices-part-heading'>Situation of registered office or any change therein.</span>
                                                        </div>
                                                    </td>
                                                    <td className="border border-dark text-center">
                                                        {/* <input type="checkbox" className=' notices-checkbox' /> */}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="border border-dark vertical-align-top ">
                                                        Part-III
                                                        {/* <span className='notices-part-heading'>Address at which books of accounts are maintained</span> */}

                                                    </td>
                                                    <td className="border border-dark vertical-align-top ">
                                                        <div className='d-flex h-100'>
                                                            {/* <span className='notices-part'>Part-III</span> */}
                                                            <span className='notices-part-heading'>Address at which books of accounts are maintained</span>
                                                        </div>
                                                    </td>
                                                    <td className="border border-dark text-center">
                                                        {/* <input type="checkbox" className=' notices-checkbox' /> */}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    {/* part II */}
                                    <PartsWrapper className='mt-4 mb-3'>
                                        PART-II
                                    </PartsWrapper>
                                    {/* <div className='col-12 mb-1'>
                    <h6 className='text-center'><u>PART-II</u></h6>
                </div> */}
                                    <div className='col-12 mb-3'>
                                        <h6>(Applicable in case of first time reporting of registered office address or any change therein)</h6>
                                    </div>
                                    {/* section 2.1 */}
                                    <div className='col-12 mb-3'>
                                        <div className='row'>
                                            <div className='col-5 d-flex '><span className='form-sr-no'>2.1 </span>
                                                <p>The situation of registered office of the company was changed from</p>
                                            </div>
                                            <div className='col-7'>
                                                <div className=' border-all py-1 h-100'></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-12 mb-3'>
                                        <div className='row'>
                                            <div className='col-5 pl-5 '>
                                                <i className='pl-2'>(state previous address)</i>
                                            </div>
                                            <div className='col-7'>
                                                <div className=' border-all px-2 py-0 h-100' ></div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* section 2.2 */}
                                    <div className='col-12'>
                                        <div className='row'>
                                            <div className='col-5 d-flex '><span className='form-sr-no'>2.2 </span>
                                                <span className='text-align-justify'>The registered office of the Company is now situated at <i>(first time reporting or change in address to be mentioned here)</i></span>
                                            </div>
                                            <div className='col-7'>
                                                <div className=' border-all py-1 h-100'></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='offset-5 col-7 mb-2'>
                                        <div className=''>
                                            <span className='text-align-justify'><i>(State full address with identifiable number / name of the premises or building and street, road and locality besides the name of the town and postal area, where applicable)</i></span>
                                        </div>
                                    </div>
                                    <div className='col-12 mb-2'>
                                        <div className='row'>
                                            <div className='col-5 pl-5  text-right'>
                                                <span>Telephone Number</span>
                                            </div>
                                            <div className='col-7 '>
                                                <div className=' border-all inline-input-200 px-1 py-1 h-100' ></div>
                                                <span className='mx-2'>Fax Number, if any</span>
                                                <div className=' border-all inline-input-190 px-1 py-1 h-100' ></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-12 mb-2'>
                                        <div className='row'>
                                            <div className='col-5 pl-5  text-right'>
                                                <span>Email Address</span>
                                            </div>
                                            <div className='col-7'>
                                                <div className=' border-all px-1 py-1 h-100' ></div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* seciton style={{minHeight:"30px"}}style={{minHeight:"30px"}}style={{minHeight:"30px"}} */}
                                    <div className='offset-5 col-7'>
                                        <div className="row">
                                            <div className="col-3 text-center">Day</div>
                                            <div className="col-3 text-center">Month</div>
                                            <div className="col-6 text-center">Year</div>
                                        </div>
                                    </div>
                                    <div className='col-12 mb-3'>
                                        <div className='row'>
                                            <div className='col-5 d-flex '>
                                                <span className='form-sr-no mt-2'>2.3 </span>
                                                <span className='mt-2'>With effect from</span>
                                            </div>
                                            <div className='col-7'>
                                                <div className="row">
                                                    <div className="col-3">
                                                        <table className="table">
                                                            <tbody>
                                                                <tr>
                                                                    <td className='d-flex p-0'>
                                                                        <div style={{ minHeight: "30px", width: "50%" }} className=" text-center border-all" ></div>
                                                                        <div style={{ minHeight: "30px", width: "50%" }} className=" text-center border-all border-left-0" ></div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div className="col-3">
                                                        <table className="table">
                                                            <tbody>
                                                                <tr>
                                                                    <td className='d-flex p-0'>
                                                                        <div style={{ minHeight: "30px", width: "50%" }} className=" text-center border-all" ></div>
                                                                        <div style={{ minHeight: "30px", width: "50%" }} className=" text-center border-all border-left-0" ></div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div className="col-6">
                                                        <table className="table">
                                                            <tbody>
                                                                <tr>
                                                                    <td className='d-flex p-0'>
                                                                        <div style={{ minHeight: "30px", width: "25%" }} className=" text-center border-all" ></div>
                                                                        <div style={{ minHeight: "30px", width: "25%" }} className=" text-center border-all border-left-0" ></div>
                                                                        <div style={{ minHeight: "30px", width: "25%" }} className=" text-center border-all border-left-0" ></div>
                                                                        <div style={{ minHeight: "30px", width: "25%" }} className=" text-center border-all border-left-0" ></div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* part III */}
                                    <PartsWrapper className='mt-4 mb-3'>
                                        PART-III
                                    </PartsWrapper>
                                    <div className='row mb-5'>
                                        <div className='col-md-12'>
                                            <HeadingWrapper className='d-flex justify-content-center'>
                                                (Applicable in case of notice of address at which the books of Accounts are to be kept other than registered office)
                                            </HeadingWrapper>

                                        </div>
                                    </div>
                                    {/* <div className='col-12 mb-2'>
                    <h6 className='text-center'><u>PART-III</u></h6>
                </div> */}
                                    {/* <div className='col-12 mb-5'>
                    <h6>(Applicable in case of notice of address at which the books of Accounts are to be kept other than registered office)</h6>
                </div> */}
                                    {/* section 3.1 */}
                                    <div className='col-12 mb-3'>
                                        <div className='d-flex '>
                                            <span className='form-sr-no'>3.1 </span>
                                            <p>The above-named company hereby gives you notice pursuant to the second proviso to sub-section (1) of section 220 of the Companies Act, 2017 that the Board of Directors of the Company have decided to keep the books of account of the company at the place other than registered office.</p>
                                        </div>
                                    </div>
                                    {/* section 3.2 */}
                                    <div className='offset-5 col-7'>
                                        <div className="row">
                                            <div className="col-3 text-center">Day</div>
                                            <div className="col-3 text-center">Month</div>
                                            <div className="col-6 text-center">Year</div>
                                        </div>
                                    </div>
                                    <div className='col-12 mb-2'>
                                        <div className='row'>
                                            <div className='col-5 d-flex '>
                                                <span className='form-sr-no'>3.2 </span>
                                                <span>Date of resolution of Board of directors</span>
                                            </div>
                                            <div className='col-7'>
                                                <div className="row">
                                                    <div className="col-3">
                                                        <table style={{ width: "100%" }}>
                                                            <tbody>
                                                                <tr>
                                                                    <td className='d-flex p-0'>
                                                                        <div style={{ minHeight: "30px", width: "50%" }} className=" text-center border-all" ></div>
                                                                        <div style={{ minHeight: "30px", width: "50%" }} className=" text-center border-all border-left-0" ></div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div className="col-3">
                                                        <table style={{ width: "100%" }}>
                                                            <tbody>
                                                                <tr>
                                                                    <td className='d-flex p-0'>
                                                                        <div style={{ minHeight: "30px", width: "50%" }} className=" text-center border-all" ></div>
                                                                        <div style={{ minHeight: "30px", width: "50%" }} className=" text-center border-all border-left-0" ></div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div className="col-6">
                                                        <table style={{ width: "100%" }}>
                                                            <tbody>
                                                                <tr>
                                                                    <td className='d-flex p-0'>
                                                                        <div style={{ minHeight: "30px", width: "25%" }} className=" text-center border-all" ></div>
                                                                        <div style={{ minHeight: "30px", width: "25%" }} className=" text-center border-all border-left-0" ></div>
                                                                        <div style={{ minHeight: "30px", width: "25%" }} className=" text-center border-all border-left-0" ></div>
                                                                        <div style={{ minHeight: "30px", width: "25%" }} className=" text-center border-all border-left-0" ></div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* section 3.3 */}
                                    <div className='col-12 mb-3'>
                                        <div className='row'>
                                            <div className='col-5 d-flex '><span className='form-sr-no'>3.3 </span>
                                                <span className='text-align-justify'>Address of place at which books of accounts to be kept</span>
                                            </div>
                                            <div className='col-7'>
                                                <div className=' border-all py-1 h-100'></div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* seciton 3.4 */}
                                    <div className='offset-5 col-7'>
                                        <div className="row">
                                            <div className="col-3 text-center">Day</div>
                                            <div className="col-3 text-center">Month</div>
                                            <div className="col-6 text-center">Year</div>
                                        </div>
                                    </div>
                                    <div className='col-12 mb-1'>
                                        <div className='row'>
                                            <div className='col-5 d-flex '>
                                                <span className='form-sr-no'>3.4 </span>
                                                <span className='text-align-justify'>Date of shifting / maintaining of books of account at above said address</span>
                                            </div>
                                            <div className='col-7'>
                                                <div className="row">
                                                    <div className="col-3">
                                                        <table className="table">
                                                            <tbody>
                                                                <tr>
                                                                    <td className='d-flex p-0'>
                                                                        <div style={{ minHeight: "30px", width: "50%" }} className=" text-center border-all" ></div>
                                                                        <div style={{ minHeight: "30px", width: "50%" }} className=" text-center border-all border-left-0" ></div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div className="col-3">
                                                        <table className="table">
                                                            <tbody>
                                                                <tr>
                                                                    <td className='d-flex p-0'>
                                                                        <div style={{ minHeight: "30px", width: "50%" }} className=" text-center border-all" ></div>
                                                                        <div style={{ minHeight: "30px", width: "50%" }} className=" text-center border-all border-left-0" ></div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div className="col-6">
                                                        <table className="table">
                                                            <tbody>
                                                                <tr>
                                                                    <td className='d-flex p-0'>
                                                                        <div style={{ minHeight: "30px", width: "25%" }} className=" text-center border-all" ></div>
                                                                        <div style={{ minHeight: "30px", width: "25%" }} className=" text-center border-all border-left-0" ></div>
                                                                        <div style={{ minHeight: "30px", width: "25%" }} className=" text-center border-all border-left-0" ></div>
                                                                        <div style={{ minHeight: "30px", width: "25%" }} className=" text-center border-all border-left-0" ></div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* part IV */}
                                        <PartsWrapper className='mt-4 mb-3'>
                                            PART-IV
                                        </PartsWrapper>
                                        {/* <div className='col-12 mb-1'>
                    <h6 className='text-center'><u>PART-IV</u></h6>
                </div> */}
                                        {/* declaration */}
                                        {/* section 4.1 */}
                                        <div className='col-12 mb-3'>
                                            <div className='d-flex '>
                                                <span className='form-sr-no'>4.1</span><strong className='ml-2'>Declaration:</strong>
                                            </div>
                                        </div>
                                        <div className='col-12'>
                                            <div className='col-12  mb-3'>
                                                <div className='declaration my-2'>
                                                    I do hereby solemnly and sincerely declare that the information provided in the form is:
                                                </div>
                                                <div className='pl-4'>
                                                    <ol style={{}} className="declaration-ol">
                                                        <li>
                                                            <p>true and correct to the best of my knowlege, in consonance with the record as maintained by the company and nothing has been concealed; and
                                                            </p>
                                                        </li>
                                                        <li>
                                                            <p>hereby reported after complying with and fulfilling all requirements under the relevant provisions of law, rules, regulations, directives, circulars and notifications whichever is applicable.
                                                            </p>
                                                        </li>
                                                    </ol>
                                                </div>
                                            </div>
                                        </div>
                                        {/* section 4.2 */}
                                        <div className='col-12  mb-3'>
                                            <div className='row'>
                                                <div className='col-6 d-flex'><span className='form-sr-no'>4.2</span>
                                                    <span className='text-align-justify'>Name of Authorized Officer with designation/ Authorized Intermediary</span>
                                                </div>
                                                <div className='col-6 d-flex'>

                                                    <div style={{ width: "50%" }} className=" border-all px-1 py-0 h-100"></div>
                                                    <div style={{ width: "50%" }} className=" border-all border-left-0 px-1 py-0 h-100"></div>

                                                </div>
                                            </div>
                                        </div>
                                        {/* section 4.3 */}
                                        <div className='col-12  mb-3'>
                                            <div className='row'>
                                                <div className='col-6 d-flex'><span className='form-sr-no'>4.3</span>
                                                    Signatures
                                                </div>
                                                <div className='col-6 d-flex'>
                                                    <div className="signatures border-all"></div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* section 4.4 */}
                                        <div className='col-12  mb-3'>
                                            <div className='row'>
                                                <div className='col-8 d-flex'>
                                                    <span className='form-sr-no mt-3'>4.4</span>
                                                    <span className='mt-3'>Registration No of Authorized Intermediary, if applicable</span>
                                                </div>
                                                <div className='col-4'>
                                                    <div style={{ minHeight: "40px" }} className=' border-all px-2' ></div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* section 4.5 */}
                                        <div className='col-12 '>
                                            <div className='row'>
                                                <div className='offset-6 col-6 text-center'>
                                                    <div className="row">
                                                        <div className="col-3">Day</div>
                                                        <div className="col-3">Month</div>
                                                        <div className="col-6">Year</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-12  mb-5'>
                                            <div className='row'>
                                                <div className='col-6 d-flex'><span className='form-sr-no mt-2'>4.5</span><span className='mt-2'>Date</span></div>
                                                <div className='col-6'>
                                                    <div className="row">
                                                        <div className="col-3">
                                                            <table className="w-100">
                                                                <tbody>
                                                                    <tr>
                                                                        <td className='d-flex justify-content-center'>
                                                                            <div style={{ height: "25px", width: "50%" }} className=" date-day text-center border-all" ></div>
                                                                            <div style={{ height: "25px", width: "50%" }} className=" date-day text-center border-all border-left-0" ></div>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        <div className="col-3">
                                                            <table className="w-100">
                                                                <tbody>
                                                                    <tr>
                                                                        <td className='d-flex justify-content-center'>
                                                                            <div style={{ height: "25px", width: "50%" }} className=" date-month text-center border-all" ></div>
                                                                            <div style={{ height: "25px", width: "50%" }} className=" date-month text-center border-all border-left-0" ></div>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        <div className="col-6">
                                                            <table className="w-100">
                                                                <tbody>
                                                                    <tr>
                                                                        <td className='d-flex justify-content-center'>
                                                                            <div style={{ height: "25px", width: "25%" }} className=" date-year text-center border-all" ></div>
                                                                            <div style={{ height: "25px", width: "25%" }} className=" date-year text-center border-all border-left-0" ></div>
                                                                            <div style={{ height: "25px", width: "25%" }} className=" date-year text-center border-all border-left-0" ></div>
                                                                            <div style={{ height: "25px", width: "25%" }} className=" date-year text-center border-all border-left-0" ></div>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* enclosures */}
                                        <div className='col-12'>
                                            <div style={{ fontWeight: "bold" }}>Enclosures:</div>
                                            <ol style={{ display: "block" }} className='enclosure-ol '>
                                                <li>
                                                    <p>Evidence of filing of Form-26 in case of change in registered office address from one city in aprovince to another.
                                                    </p>
                                                </li>
                                                <li>
                                                    <p>Original challan or other evidence of payment of fee specified in Seventh Schedule of the Act (not applicable in case of online filing)</p>
                                                </li>
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </PDFExport>
                    : <PDFExport
                        paperSize="A4"
                        margin="1cm"
                        scale={0.6}
                        fileName={`Form 21`}
                        pageTemplate={PageTemplate}
                        ref={pdfExportComponent}
                    >
                       <div style={{ fontFamily: `${fontFamilyForStaticData}`, fontSize: `${fontSizeForStaticData}`, fontWeight: 500 }}>
                            <FormName>
                                Form-21
                            </FormName>
                            <div className='row'>
                                <div className='col-md-12' >
                                    <HeadingWrapper className='d-flex justify-content-center'>
                                        THE COMPANIES ACT, 2017
                                    </HeadingWrapper>

                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <HeadingWrapper className='d-flex justify-content-center'>
                                        THE COMPANIES REGULATIONS, 2024
                                    </HeadingWrapper>

                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <div style={{
                                        fontSize: '15px',
                                        // fontWeight: 'bold' 
                                    }} className='d-flex justify-content-center'>
                                        [Section 21, 220 & 449 and Regulation 30]
                                    </div>

                                </div>
                            </div>

                            <div className='row mt-4'>
                                <div className='col-md-12'>
                                    <HeadingWrapper className='text-center d-flex justify-content-center'>
                                        NOTICE OF SITUATION OF REGISTERED OFFICE ADDRESS OR ANY
                                    </HeadingWrapper>
                                    <HeadingWrapper className='text-center d-flex justify-content-center'>
                                        CHANGE THEREIN OR NOTICE OF ADDRESS AT WHICH BOOKS OF
                                    </HeadingWrapper>
                                    <HeadingWrapper className='text-center d-flex justify-content-center'>
                                        ACCOUNTS ARE MAINTAINED
                                    </HeadingWrapper>

                                </div>
                            </div>

                            <PartsWrapper className='mt-4 mb-3'>
                                PART-I
                            </PartsWrapper>

                            <div className='row'>
                                <div className='col-md-12' style={{ padding: '25px 50px', wordWrap: 'break-word' }}>

                                    <div className='col-12'>
                                        <em>(Please complete in bold capital letters)</em>
                                    </div>
                                    {/* section 1.1 */}
                                    <div className='col-12 mb-3'>
                                        <div className='row'>
                                            <div className='col-5 d-flex '>
                                                <span className='form-sr-no'>1.1 </span>CUIN (Registration Number)</div>
                                            <div className='col-7 reg-cells'>
                                                <div style={{ minHeight: "30px" }} className=" reg-no reg-cell" ></div>
                                                <div style={{ minHeight: "30px" }} className=" reg-no reg-cell" ></div>
                                                <div style={{ minHeight: "30px" }} className=" reg-no reg-cell" ></div>
                                                <div style={{ minHeight: "30px" }} className=" reg-no reg-cell" ></div>
                                                <div style={{ minHeight: "30px" }} className=" reg-no reg-cell" ></div>
                                                <div style={{ minHeight: "30px" }} className=" reg-no reg-cell" ></div>
                                                <div style={{ minHeight: "30px" }} className=" reg-no border-all" ></div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* section 1.2 */}
                                    <div className='col-12 mb-3'>
                                        <div className='row'>
                                            <div className='col-5 d-flex '><span className='form-sr-no mt-2'>1.2 </span>
                                                <span className='mt-2'>Name of the Company</span>
                                            </div>
                                            <div className='col-7'>
                                                <div style={{ minHeight: "30px" }} className=" company-name border-all ml-1" />
                                            </div>
                                        </div>
                                    </div>
                                    {/* section 1.3 */}
                                    <div className='col-12 mb-5'>
                                        <div className='row mt-2'>
                                            <div className='col-3 d-flex '><span className='form-sr-no'>1.3 </span>Fee Payment Details</div>
                                            {/* section 1.3.1 */}
                                            <div className='col-*'>
                                                <div className="d-flex align-items-middle ">
                                                    <span className='mx-3'>1.3.1 </span><span className='ml-3'>Challan No</span>
                                                    <div className=" challan-no border-all ml-3" ></div>
                                                </div>
                                            </div>
                                            {/* section 1.3.2 */}
                                            <div className='col-*'>
                                                <div className="d-flex align-items-middle ">
                                                    <span className='mx-3'>1.3.2 </span><span className='ml-1'>Amount</span>
                                                    <div className=" ml-3 amount border-all" ></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* seciton 1.4 */}
                                    <div className='col-12 d-flex form-21-particulars mb-3'>
                                        <table className="table">
                                            <tbody>
                                                <tr>
                                                    <td colSpan={2} className="border border-dark particulars-col-1">
                                                        <div className="d-flex">
                                                            <strong className=" ml-2">1.4 Notice of </strong>
                                                            <span className="particulars-line"></span>
                                                        </div>
                                                    </td>
                                                    <td className="border border-dark particulars-col-2">
                                                        <div className="font-size-medium text-center">
                                                            <strong className='d-block word-spacing-10'>Please tick the</strong>
                                                            <strong>relevent box</strong>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="border border-dark vertical-align-top ">
                                                        Part-II
                                                    </td>
                                                    <td className="border border-dark vertical-align-top ">
                                                        <div className='d-flex h-100'>
                                                            {/* <span className='notices-part'>Part-II</span> */}
                                                            <span className='notices-part-heading'>Situation of registered office or any change therein.</span>
                                                        </div>
                                                    </td>
                                                    <td className="border border-dark text-center">
                                                        {/* <input type="checkbox" className=' notices-checkbox' /> */}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="border border-dark vertical-align-top ">
                                                        Part-III
                                                        {/* <span className='notices-part-heading'>Address at which books of accounts are maintained</span> */}

                                                    </td>
                                                    <td className="border border-dark vertical-align-top ">
                                                        <div className='d-flex h-100'>
                                                            {/* <span className='notices-part'>Part-III</span> */}
                                                            <span className='notices-part-heading'>Address at which books of accounts are maintained</span>
                                                        </div>
                                                    </td>
                                                    <td className="border border-dark text-center">
                                                        {/* <input type="checkbox" className=' notices-checkbox' /> */}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    {/* part II */}
                                    <PartsWrapper className='mt-4 mb-3'>
                                        PART-II
                                    </PartsWrapper>
                                    {/* <div className='col-12 mb-1'>
                    <h6 className='text-center'><u>PART-II</u></h6>
                </div> */}
                                    <div className='col-12 mb-3'>
                                        <h6>(Applicable in case of first time reporting of registered office address or any change therein)</h6>
                                    </div>
                                    {/* section 2.1 */}
                                    <div className='col-12 mb-3'>
                                        <div className='row'>
                                            <div className='col-5 d-flex '><span className='form-sr-no'>2.1 </span>
                                                <p>The situation of registered office of the company was changed from</p>
                                            </div>
                                            <div className='col-7'>
                                                <div className=' border-all py-1 h-100'></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-12 mb-3'>
                                        <div className='row'>
                                            <div className='col-5 pl-5 '>
                                                <i className='pl-2'>(state previous address)</i>
                                            </div>
                                            <div className='col-7'>
                                                <div className=' border-all px-2 py-0 h-100' ></div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* section 2.2 */}
                                    <div className='col-12'>
                                        <div className='row'>
                                            <div className='col-5 d-flex '><span className='form-sr-no'>2.2 </span>
                                                <span className='text-align-justify'>The registered office of the Company is now situated at <i>(first time reporting or change in address to be mentioned here)</i></span>
                                            </div>
                                            <div className='col-7'>
                                                <div className=' border-all py-1 h-100'></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='offset-5 col-7 mb-2'>
                                        <div className=''>
                                            <span className='text-align-justify'><i>(State full address with identifiable number / name of the premises or building and street, road and locality besides the name of the town and postal area, where applicable)</i></span>
                                        </div>
                                    </div>
                                    <div className='col-12 mb-2'>
                                        <div className='row'>
                                            <div className='col-5 pl-5  text-right'>
                                                <span>Telephone Number</span>
                                            </div>
                                            <div className='col-7 '>
                                                <div className=' border-all inline-input-200 px-1 py-1 h-100' ></div>
                                                <span className='mx-2'>Fax Number, if any</span>
                                                <div className=' border-all inline-input-190 px-1 py-1 h-100' ></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-12 mb-2'>
                                        <div className='row'>
                                            <div className='col-5 pl-5  text-right'>
                                                <span>Email Address</span>
                                            </div>
                                            <div className='col-7'>
                                                <div className=' border-all px-1 py-1 h-100' ></div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* seciton style={{minHeight:"30px"}}style={{minHeight:"30px"}}style={{minHeight:"30px"}} */}
                                    <div className='offset-5 col-7'>
                                        <div className="row">
                                            <div className="col-3 text-center">Day</div>
                                            <div className="col-3 text-center">Month</div>
                                            <div className="col-6 text-center">Year</div>
                                        </div>
                                    </div>
                                    <div className='col-12 mb-3'>
                                        <div className='row'>
                                            <div className='col-5 d-flex '>
                                                <span className='form-sr-no mt-2'>2.3 </span>
                                                <span className='mt-2'>With effect from</span>
                                            </div>
                                            <div className='col-7'>
                                                <div className="row">
                                                    <div className="col-3">
                                                        <table className="table">
                                                            <tbody>
                                                                <tr>
                                                                    <td className='d-flex p-0'>
                                                                        <div style={{ minHeight: "30px", width: "50%" }} className=" text-center border-all" ></div>
                                                                        <div style={{ minHeight: "30px", width: "50%" }} className=" text-center border-all border-left-0" ></div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div className="col-3">
                                                        <table className="table">
                                                            <tbody>
                                                                <tr>
                                                                    <td className='d-flex p-0'>
                                                                        <div style={{ minHeight: "30px", width: "50%" }} className=" text-center border-all" ></div>
                                                                        <div style={{ minHeight: "30px", width: "50%" }} className=" text-center border-all border-left-0" ></div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div className="col-6">
                                                        <table className="table">
                                                            <tbody>
                                                                <tr>
                                                                    <td className='d-flex p-0'>
                                                                        <div style={{ minHeight: "30px", width: "25%" }} className=" text-center border-all" ></div>
                                                                        <div style={{ minHeight: "30px", width: "25%" }} className=" text-center border-all border-left-0" ></div>
                                                                        <div style={{ minHeight: "30px", width: "25%" }} className=" text-center border-all border-left-0" ></div>
                                                                        <div style={{ minHeight: "30px", width: "25%" }} className=" text-center border-all border-left-0" ></div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* part III */}
                                    <PartsWrapper className='mt-4 mb-3'>
                                        PART-III
                                    </PartsWrapper>
                                    <div className='row mb-5'>
                                        <div className='col-md-12'>
                                            <HeadingWrapper className='d-flex justify-content-center'>
                                                (Applicable in case of notice of address at which the books of Accounts are to be kept other than registered office)
                                            </HeadingWrapper>

                                        </div>
                                    </div>
                                    {/* <div className='col-12 mb-2'>
                    <h6 className='text-center'><u>PART-III</u></h6>
                </div> */}
                                    {/* <div className='col-12 mb-5'>
                    <h6>(Applicable in case of notice of address at which the books of Accounts are to be kept other than registered office)</h6>
                </div> */}
                                    {/* section 3.1 */}
                                    <div className='col-12 mb-3'>
                                        <div className='d-flex '>
                                            <span className='form-sr-no'>3.1 </span>
                                            <p>The above-named company hereby gives you notice pursuant to the second proviso to sub-section (1) of section 220 of the Companies Act, 2017 that the Board of Directors of the Company have decided to keep the books of account of the company at the place other than registered office.</p>
                                        </div>
                                    </div>
                                    {/* section 3.2 */}
                                    <div className='offset-5 col-7'>
                                        <div className="row">
                                            <div className="col-3 text-center">Day</div>
                                            <div className="col-3 text-center">Month</div>
                                            <div className="col-6 text-center">Year</div>
                                        </div>
                                    </div>
                                    <div className='col-12 mb-2'>
                                        <div className='row'>
                                            <div className='col-5 d-flex '>
                                                <span className='form-sr-no'>3.2 </span>
                                                <span>Date of resolution of Board of directors</span>
                                            </div>
                                            <div className='col-7'>
                                                <div className="row">
                                                    <div className="col-3">
                                                        <table style={{ width: "100%" }}>
                                                            <tbody>
                                                                <tr>
                                                                    <td className='d-flex p-0'>
                                                                        <div style={{ minHeight: "30px", width: "50%" }} className=" text-center border-all" ></div>
                                                                        <div style={{ minHeight: "30px", width: "50%" }} className=" text-center border-all border-left-0" ></div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div className="col-3">
                                                        <table style={{ width: "100%" }}>
                                                            <tbody>
                                                                <tr>
                                                                    <td className='d-flex p-0'>
                                                                        <div style={{ minHeight: "30px", width: "50%" }} className=" text-center border-all" ></div>
                                                                        <div style={{ minHeight: "30px", width: "50%" }} className=" text-center border-all border-left-0" ></div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div className="col-6">
                                                        <table style={{ width: "100%" }}>
                                                            <tbody>
                                                                <tr>
                                                                    <td className='d-flex p-0'>
                                                                        <div style={{ minHeight: "30px", width: "25%" }} className=" text-center border-all" ></div>
                                                                        <div style={{ minHeight: "30px", width: "25%" }} className=" text-center border-all border-left-0" ></div>
                                                                        <div style={{ minHeight: "30px", width: "25%" }} className=" text-center border-all border-left-0" ></div>
                                                                        <div style={{ minHeight: "30px", width: "25%" }} className=" text-center border-all border-left-0" ></div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* section 3.3 */}
                                    <div className='col-12 mb-3'>
                                        <div className='row'>
                                            <div className='col-5 d-flex '><span className='form-sr-no'>3.3 </span>
                                                <span className='text-align-justify'>Address of place at which books of accounts to be kept</span>
                                            </div>
                                            <div className='col-7'>
                                                <div className=' border-all py-1 h-100'></div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* seciton 3.4 */}
                                    <div className='offset-5 col-7'>
                                        <div className="row">
                                            <div className="col-3 text-center">Day</div>
                                            <div className="col-3 text-center">Month</div>
                                            <div className="col-6 text-center">Year</div>
                                        </div>
                                    </div>
                                    <div className='col-12 mb-1'>
                                        <div className='row'>
                                            <div className='col-5 d-flex '>
                                                <span className='form-sr-no'>3.4 </span>
                                                <span className='text-align-justify'>Date of shifting / maintaining of books of account at above said address</span>
                                            </div>
                                            <div className='col-7'>
                                                <div className="row">
                                                    <div className="col-3">
                                                        <table className="table">
                                                            <tbody>
                                                                <tr>
                                                                    <td className='d-flex p-0'>
                                                                        <div style={{ minHeight: "30px", width: "50%" }} className=" text-center border-all" ></div>
                                                                        <div style={{ minHeight: "30px", width: "50%" }} className=" text-center border-all border-left-0" ></div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div className="col-3">
                                                        <table className="table">
                                                            <tbody>
                                                                <tr>
                                                                    <td className='d-flex p-0'>
                                                                        <div style={{ minHeight: "30px", width: "50%" }} className=" text-center border-all" ></div>
                                                                        <div style={{ minHeight: "30px", width: "50%" }} className=" text-center border-all border-left-0" ></div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div className="col-6">
                                                        <table className="table">
                                                            <tbody>
                                                                <tr>
                                                                    <td className='d-flex p-0'>
                                                                        <div style={{ minHeight: "30px", width: "25%" }} className=" text-center border-all" ></div>
                                                                        <div style={{ minHeight: "30px", width: "25%" }} className=" text-center border-all border-left-0" ></div>
                                                                        <div style={{ minHeight: "30px", width: "25%" }} className=" text-center border-all border-left-0" ></div>
                                                                        <div style={{ minHeight: "30px", width: "25%" }} className=" text-center border-all border-left-0" ></div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* part IV */}
                                        <PartsWrapper className='mt-4 mb-3'>
                                            PART-IV
                                        </PartsWrapper>
                                        {/* <div className='col-12 mb-1'>
                    <h6 className='text-center'><u>PART-IV</u></h6>
                </div> */}
                                        {/* declaration */}
                                        {/* section 4.1 */}
                                        <div className='col-12 mb-3'>
                                            <div className='d-flex '>
                                                <span className='form-sr-no'>4.1</span><strong className='ml-2'>Declaration:</strong>
                                            </div>
                                        </div>
                                        <div className='col-12'>
                                            <div className='col-12  mb-3'>
                                                <div className='declaration my-2'>
                                                    I do hereby solemnly and sincerely declare that the information provided in the form is:
                                                </div>
                                                <div className='pl-4'>
                                                    <ol style={{}} className="declaration-ol">
                                                        <li>
                                                            <p>true and correct to the best of my knowlege, in consonance with the record as maintained by the company and nothing has been concealed; and
                                                            </p>
                                                        </li>
                                                        <li>
                                                            <p>hereby reported after complying with and fulfilling all requirements under the relevant provisions of law, rules, regulations, directives, circulars and notifications whichever is applicable.
                                                            </p>
                                                        </li>
                                                    </ol>
                                                </div>
                                            </div>
                                        </div>
                                        {/* section 4.2 */}
                                        <div className='col-12  mb-3'>
                                            <div className='row'>
                                                <div className='col-6 d-flex'><span className='form-sr-no'>4.2</span>
                                                    <span className='text-align-justify'>Name of Authorized Officer with designation/ Authorized Intermediary</span>
                                                </div>
                                                <div className='col-6 d-flex'>

                                                    <div style={{ width: "50%" }} className=" border-all px-1 py-0 h-100"></div>
                                                    <div style={{ width: "50%" }} className=" border-all border-left-0 px-1 py-0 h-100"></div>

                                                </div>
                                            </div>
                                        </div>
                                        {/* section 4.3 */}
                                        <div className='col-12  mb-3'>
                                            <div className='row'>
                                                <div className='col-6 d-flex'><span className='form-sr-no'>4.3</span>
                                                    Signatures
                                                </div>
                                                <div className='col-6 d-flex'>
                                                    <div className="signatures border-all"></div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* section 4.4 */}
                                        <div className='col-12  mb-3'>
                                            <div className='row'>
                                                <div className='col-8 d-flex'>
                                                    <span className='form-sr-no mt-3'>4.4</span>
                                                    <span className='mt-3'>Registration No of Authorized Intermediary, if applicable</span>
                                                </div>
                                                <div className='col-4'>
                                                    <div style={{ minHeight: "40px" }} className=' border-all px-2' ></div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* section 4.5 */}
                                        <div className='col-12 '>
                                            <div className='row'>
                                                <div className='offset-6 col-6 text-center'>
                                                    <div className="row">
                                                        <div className="col-3">Day</div>
                                                        <div className="col-3">Month</div>
                                                        <div className="col-6">Year</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-12  mb-5'>
                                            <div className='row'>
                                                <div className='col-6 d-flex'><span className='form-sr-no mt-2'>4.5</span><span className='mt-2'>Date</span></div>
                                                <div className='col-6'>
                                                    <div className="row">
                                                        <div className="col-3">
                                                            <table className="w-100">
                                                                <tbody>
                                                                    <tr>
                                                                        <td className='d-flex justify-content-center'>
                                                                            <div style={{ height: "25px", width: "50%" }} className=" date-day text-center border-all" ></div>
                                                                            <div style={{ height: "25px", width: "50%" }} className=" date-day text-center border-all border-left-0" ></div>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        <div className="col-3">
                                                            <table className="w-100">
                                                                <tbody>
                                                                    <tr>
                                                                        <td className='d-flex justify-content-center'>
                                                                            <div style={{ height: "25px", width: "50%" }} className=" date-month text-center border-all" ></div>
                                                                            <div style={{ height: "25px", width: "50%" }} className=" date-month text-center border-all border-left-0" ></div>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        <div className="col-6">
                                                            <table className="w-100">
                                                                <tbody>
                                                                    <tr>
                                                                        <td className='d-flex justify-content-center'>
                                                                            <div style={{ height: "25px", width: "25%" }} className=" date-year text-center border-all" ></div>
                                                                            <div style={{ height: "25px", width: "25%" }} className=" date-year text-center border-all border-left-0" ></div>
                                                                            <div style={{ height: "25px", width: "25%" }} className=" date-year text-center border-all border-left-0" ></div>
                                                                            <div style={{ height: "25px", width: "25%" }} className=" date-year text-center border-all border-left-0" ></div>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* enclosures */}
                                        <div className='col-12'>
                                            <div style={{ fontWeight: "bold" }}>Enclosures:</div>
                                            <ol style={{ display: "block" }} className='enclosure-ol '>
                                                <li>
                                                    <p>Evidence of filing of Form-26 in case of change in registered office address from one city in aprovince to another.
                                                    </p>
                                                </li>
                                                <li>
                                                    <p>Original challan or other evidence of payment of fee specified in Seventh Schedule of the Act (not applicable in case of online filing)</p>
                                                </li>
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </PDFExport>}
            </div>
            <button disabled={loading} className='btn btn-danger' onClick={(e) => {
                // if(viewPdf)
                //   SetViewPDf(true)
                if (pdfExportComponent.current) {
                    pdfExportComponent.current.save();
                }
            }}>Download PDF</button>
        </div>
    )
}
const PartsWrapper = styled.div`
color: #121212;
text-decoration: underline;
justify-content: center;
display: flex;
margin-right: 15px;
font-size: 17px;
font-weight: bold;
`;
const ScrollWrapper = styled.div`
max-height: 50px;
overflow-y: scroll;
overflow-x: hidden;
::-webkit-scrollbar{
    height: 5px;
    width: 3px;
  }
  
  ::-webkit-scrollbar-track{
    background: #F9F9FB;
  }
  ::-webkit-scrollbar-thumb{
    background: #4E515680;
    border-radius: 5px;
  
  }
`;
const HeadingWrapper = styled.div`
font-size: 17px;
font-weight: bold;
`;
const FormName = styled.div`
color: #121212;
text-decoration: underline;
justify-content: end;
display: flex;
margin-right: 15px;
font-size: 17px;
font-weight: bold;
`;
const Wrapper = styled.div`
table.table-bordered{
    border: 1px solid #121212
     border-color: #121212; 
    // border:1px solid blue;
    // margin-top:20px;
  }
// table.table-bordered > thead > tr > th{
//     // border:1px solid blue;
//     border-color: #121212; 
// }
// table.table-bordered > tbody > tr > td{
//     // border:1px solid blue;
//     borderC-color: #121212; 
// }
 tr td {
    // border:1px solid blue;.
    border-color: #121212; 
}

`;
const WrapperDashed = styled.div`

  td {
    
    border: 1px dashed #dddcdc;
}

`;