import Spinner from "components/common/spinner";
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { numberWithCommas } from "utilities/utilityFunctions";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getShareHoldingByShareholderCNICNTN } from "store/services/shareholder.service";

const InvestorShareholding = ({
  selectedInvestor,
  setSelectedInvestor,
  shareholdingCNIC,
  setSharehloldingCNIC,
  shareholdingNTN,
  setSharehloldingNTN,
}) => {
  const mixLayout = useSelector((state) => state?.Customizer?.mix_layout);
  const baseEmail = sessionStorage.getItem("email") || "";
  const onHoverClass =
    mixLayout === "dark-body-only" ||
    mixLayout === "dark-only" ||
    mixLayout === "dark-sidebar-body-mix"
      ? "onHover-dark"
      : "onHover";
  const [loading, setLoading] = useState(false);
  const [shareholdingData, setShareholdingData] = useState([]);

  const fetchShareholding = async () => {
    try {
      setLoading(true);

      let companyCodes = JSON.parse(sessionStorage.getItem("all_companies") || "[]").toString()

      let response = await getShareHoldingByShareholderCNICNTN(
        baseEmail,
        companyCodes,
        shareholdingCNIC,
        shareholdingNTN
      );

      if (response && response.status === 200 && response.data.status === 200) {
        setShareholdingData(response.data.data);
        setLoading(false);
      } else if (
        response &&
        response.status === 200 &&
        response.data.status === 404
      ) {
        toast.error("No shareholding record!");
        setShareholdingData([]);
        setLoading(false);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error("Unable to fetch shareholding details");
    }
  };

  useState(() => {
    fetchShareholding();
  }, []);

  return (
    <div className="container">
      {loading && <Spinner />}
      {!loading && (!shareholdingData || shareholdingData.length === 0) && (
        <div className="card">
          <div className="card-header text-center">
            <b>No shareholding data found!</b>
          </div>
        </div>
      )}

      {!loading && shareholdingData && shareholdingData.length > 0 && (
        <div className="card">
          <div className="card-body">
            <TableStyle>
              <table className="table table-striped  ">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Shareholder Name</th>
                    <th>Shareholder CNIC/NTN</th>
                    <th>Company Name</th>
                    <th className="text-right">Electronic Shares</th>
                    <th className="text-right">Physical Shares</th>
                  </tr>
                </thead>

                <tbody>
                  {shareholdingData.map((shareholder, i) => {
                    return (
                      <tr className={onHoverClass} key={i}>
                        <td>
                          {shareholder?.folio_number?.startsWith(
                            `${shareholder?.company_code}-`
                          )
                            ? shareholder?.folio_number.replace(
                                `${shareholder?.company_code}-`,
                                ""
                              )
                            : shareholder.folio_number}
                        </td>
                        <td>{shareholder.shareholder_name}</td>

                        <td>
                          {shareholder?.cnic ||
                            shareholder?.ntn ||
                            shareholder?.shareholder_id ||
                            ""}
                        </td>
                        <td>{shareholder.company_name}</td>
                        <td className="text-right">
                          {numberWithCommas(shareholder.electronic_shares)}
                        </td>
                        <td className="text-right">
                          {numberWithCommas(shareholder.physical_shares)}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </TableStyle>
          </div>
        </div>
      )}
    </div>
  );
};

export default InvestorShareholding;

const TableStyle = styled.div`
  .onHover:hover {
    background-color: #ecf3f8 !important;
  }
  .onHover-dark:hover {
    background-color: #313131 !important;
  }
`;
