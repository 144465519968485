import React, { useState, useEffect } from 'react'
import { PDFExport } from "@progress/kendo-react-pdf";
import PageTemplate from "./page-template";
import {
    getCompanies,
} from "../../../store/services/company.service";
import styled from 'styled-components';
import { TiTick } from "react-icons/ti";
import { numberWithCommas } from 'utilities/utilityFunctions';
export const StatutoryForm8 = ({ data, formTemplate = false }) => {
    const pdfExportComponent = React.useRef(null);
    const [CompanyData, setCompanyData] = useState(null)
    const [loading, setLoading] = useState(false);
    const [fontFamilyForStaticData, setFontFamilyForStaticData] = useState('Montserrat, sans-serif')
    const [fontSizeForStaticData, setFontSizeForStaticData] = useState('14px')
    const [fontfamilyforDynimicData, setFontFamilyForDynmicData] = useState('Segoe Print')
    const [fontColor, setFontColor] = useState('')
    const baseEmail = sessionStorage.getItem("email") || "";
    const [governance, setGovernance] = useState({})
    const getAllCompanies = async () => {
        setLoading(true)
        try {
            const response = await getCompanies(baseEmail);
            if (response.status === 200) {
                response.data.data = response.data.data.filter((item) => item?.active === 'Y')
                const parents = response.data.data;
                const company = parents?.find(ite => ite?.code == data?.company_code)
                let Allperson = company.governance && company.governance != "" ? JSON.parse(company.governance) : []
                let sec = Allperson.find(x => x.role == "Company Secretary" && x?.active?.toLowerCase() != 'n')
                let ceo = Allperson.find(x => x.role == "CEO" && x?.active?.toLowerCase() != 'n')


                if (sec) {
                    setGovernance(sec)
                } else if (ceo) {
                    setGovernance(ceo)
                }
                setCompanyData(company)
                setTimeout(() => {
                    setLoading(false)
                }, 1000);
            }
        } catch (error) {
            setLoading(false)
        }
    };
    useEffect(() => {
        getAllCompanies()
    }, [])
    return (
        <div>
            <div
                style={{ fontFamily: "sans-serif " }}
            >

                {formTemplate ?
                    <PDFExport
                        paperSize="A4"
                        margin="1cm"
                        scale={0.6}
                        fileName={`Statutory Form 8`}
                        pageTemplate={PageTemplate}
                        ref={pdfExportComponent}
                    >
                        <div style={{ fontFamily: `${fontFamilyForStaticData}`, fontSize: `${fontSizeForStaticData}`, fontWeight: 500 }}>
                            <FormName>
                                Form-8
                            </FormName>
                            <div className='row'>
                                <div className='col-md-12' >
                                    <HeadingWrapper className='d-flex justify-content-center'>
                                        THE COMPANIES ACT, 2017
                                    </HeadingWrapper>

                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <HeadingWrapper className='d-flex justify-content-center'>
                                        THE COMPANIES REGULATIONS, 2024
                                    </HeadingWrapper>

                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <div style={{ fontSize: '15px', fontWeight: 'bold' }} className='d-flex justify-content-center'>
                                        [Section 284(5) and Regulations 60 & 30]
                                    </div>
                                </div>
                            </div>

                            <div className='row mt-4'>
                                <div className='col-md-12'>
                                    <HeadingWrapper className='d-flex justify-content-center'>
                                        FILING OF COPY OF SCHEME OF AMALGAMATION
                                    </HeadingWrapper>
                                </div>
                            </div>
                            <PartsWrapper className='mt-4 mb-3'>
                                PART-I
                            </PartsWrapper>
                            <div className='row'>
                                <div className='col-md-12' style={{ padding: '25px 50px', wordWrap: 'break-word' }}>
                                    <div> <i>(Please complete in bold capital letters)</i></div>


                                    <div className='d-flex' >
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>1.1</div>
                                        <div className='align-items-center' style={{ width: '28%', padding: '0px 10px 10px 10px' }}>CUIN (Registration Number)  </div>
                                        <div className='align-items-center  d-flex'  >{
                                            '1234 552'.split("")?.map((item, idx) => {
                                                if (item == ' ') return
                                                return <div style={{ minWidth: '30px', minHeight: '34px', border: '1px solid #121212', borderLeft: idx != 0 ? "none" : "1px solid #121212", padding: '6px 9px' }}></div>
                                            })
                                        }
                                        </div>
                                    </div>



                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>1.2</div>
                                        <div className='align-items-center' style={{ width: '22%', padding: '0px 10px 10px 10px' }}>Name of the Company  </div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '71%', padding: '0px 10px 10px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                    </div>




                                    <div className='d-flex  mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>1.3</div>
                                        <div className='align-items-center' style={{ width: '20.20%', padding: '0px 10px 10px 10px' }}>Filing Fee payment Details*</div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '5%' }}>1.3.1</div>
                                        <div className='align-items-center' style={{ width: '13.20%', paddingLeft: '20px' }}>Challan No.</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '17.20%', paddingLeft: '20px', minHeight: '34px' }}></ScrollWrapper>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '5%' }}>1.3.2</div>
                                        <div className='align-items-center' style={{ width: '13.20%', paddingLeft: '20px' }}>Amount</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '19.20%', paddingLeft: '20px', minHeight: '34px' }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '85%', borderBottom: "none", paddingLeft: '20px', minHeight: '70px', fontWeight: "bold" }}>Scheme of —</ScrollWrapper>
                                        <ScrollWrapper className='align-items-center text-center' style={{ border: '1px solid #121212', borderLeft: "none", borderBottom: "none", width: '15%', padding: '10px', minHeight: '70px' }}>Please tick the relevant box</ScrollWrapper>
                                    </div>
                                    <div className='d-flex'>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '21%', paddingLeft: '20px', borderBottom: "none", minHeight: '34px', fontWeight: "bold" }}>1.4</ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '64%', borderLeft: "none", borderBottom: "none", paddingLeft: '20px', minHeight: '34px' }}> amalgamation of wholly owned subsidiary(ies) into its holding company</ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '15%', borderLeft: "none", borderBottom: "none", paddingLeft: '20px', minHeight: '34px' }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex'>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '21%', paddingLeft: '20px', minHeight: '34px', fontWeight: "bold" }}>1.5</ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '64%', borderLeft: "none", paddingLeft: '20px', minHeight: '34px' }}>amalgamation of two or more companies wholly owned by a person</ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '15%', borderLeft: "none", paddingLeft: '20px', minHeight: '34px' }}></ScrollWrapper>
                                    </div>
                                    <PartsWrapper className='mt-4 mb-3'>
                                        PART-II
                                    </PartsWrapper>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>2.</div>
                                        <div className='align-items-center' style={{ padding: '0px 10px 10px 10px' }}>Capital structure of the Company, if applicable</div>
                                    </div>
                                    <div className='d-flex '>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '45%' }}></div>
                                        <Wrapper style={{ width: "26%" }} className="table-responsive mt-0" >
                                            <table
                                                className="table table-bordered"
                                                style={{ fontFamily: "Montserrat rev=1 !important" }}
                                            >
                                                <thead>
                                                    <th colSpan={2} style={{ verticalAlign: 'sub', border: '1px solid #121212', textAlign: "center" }}>
                                                        Share capital
                                                        prior to amalgamation
                                                    </th>

                                                </thead>

                                                <tbody>
                                                    <tr>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}>Number of Shares</td>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}>Amount</td>
                                                    </tr>


                                                </tbody>
                                            </table>
                                        </Wrapper>
                                        <Wrapper style={{ width: "26%" ,marginLeft:"2rem"}} className="table-responsive mt-0" >
                                            <table
                                                className="table table-bordered"
                                                style={{  fontFamily: "Montserrat rev=1 !important" }}
                                            >
                                                <thead>
                                                    <th colSpan={2} style={{ verticalAlign: 'sub', border: '1px solid #121212', textAlign: "center" }}>
                                                        Share capital after amalgamation
                                                    </th>

                                                </thead>

                                                <tbody>
                                                    <tr>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}>Number of Shares</td>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}>Amount</td>
                                                    </tr>


                                                </tbody>
                                            </table>
                                        </Wrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>2.1</div>
                                        <div className='align-items-center' style={{ width:"38%",padding: '0px 10px 10px 10px' }}>Authorized capital</div>
                                        <Wrapper style={{ width: "26%" }} className="table-responsive mt-0" >
                                            <table
                                                className="table table-bordered"
                                                style={{ fontFamily: "Montserrat rev=1 !important" }}
                                            >
                                                <tbody>
                                                    <tr>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}></td>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}></td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </Wrapper>
                                        <Wrapper style={{ width: "26%",marginLeft:"2rem" }} className="table-responsive mt-0" >
                                            <table
                                                className="table table-bordered"
                                                style={{ fontFamily: "Montserrat rev=1 !important" }}
                                            >
                                                <tbody>
                                                    <tr>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}></td>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}></td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </Wrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>2.2</div>
                                        <div className='align-items-center' style={{ width:"38%",padding: '0px 10px 10px 10px' }}> Paid up capital</div>
                                        <Wrapper style={{ width: "26%" }} className="table-responsive mt-0" >
                                            <table
                                                className="table table-bordered"
                                                style={{ fontFamily: "Montserrat rev=1 !important" }}
                                            >
                                                <tbody>
                                                    <tr>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}></td>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}></td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </Wrapper>
                                        <Wrapper style={{ width: "26%",marginLeft:"2rem" }} className="table-responsive mt-0" >
                                            <table
                                                className="table table-bordered"
                                                style={{ fontFamily: "Montserrat rev=1 !important" }}
                                            >
                                                <tbody>
                                                    <tr>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}></td>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}></td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </Wrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>a.</div>
                                        <div className='align-items-center' style={{padding: '0px 10px 10px 10px' }}>Particulars of Board resolutions passed by each amalgamating company</div>
                                    </div>
                                    <Wrapper className="table-responsive mt-0" >
                                            <table
                                                className="table table-bordered"
                                                style={{ fontFamily: "Montserrat rev=1 !important" }}
                                            >
                                                 <thead>
                                                    <th  style={{ verticalAlign: 'sub', border: '1px solid #121212', textAlign: "center" }}>
                                                       S#
                                                    </th>
                                                    <th style={{ verticalAlign: 'sub', border: '1px solid #121212', textAlign: "center" }}>
                                                    Name of Company
                                                    </th>
                                                    <th  style={{ verticalAlign: 'sub', border: '1px solid #121212', textAlign: "center" }}>
                                                    Date of Board resolution
                                                    </th>
                                                    <th style={{ verticalAlign: 'sub', border: '1px solid #121212', textAlign: "center" }}>
                                                    Gist of Board resolution (attach copy of Resolution)
                                                    </th>

                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}></td>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}></td>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}></td>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}></td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}></td>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}></td>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}></td>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}></td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}></td>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}></td>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}></td>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}></td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                    </Wrapper>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%',  }}></div>
                                        <div className='align-items-center' style={{ width: '51%', padding: ' 5px 10px',}}> </div>
                                        <div className='align-items-center text-center' style={{ width: '10%', padding: ' 5px', }}> dd</div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%', }}></div>
                                        <div className='align-items-center text-center' style={{ width: '10%', padding: ' 5px',  }}> mm</div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%', }}></div>
                                        <div className='align-items-center text-center' style={{  width: '16%', padding: ' 5px 10px' }}>yyyy</div>
                                        <div></div>
                                    </div>
                                    <div className='d-flex '>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%',  }}>2.3</div>
                                        <div className='align-items-center' style={{ width: '51%', padding: ' 0px 10px 10px 10px',  }}> Effective Date of amalgamation</div>
                                        <div className='align-items-center' style={{ width: '5%', padding: ' 5px 10px', border: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '5%', padding: ' 5px 10px', border: '1px solid #121212', borderLeft: 'none' }}> </div>
                                        <div className='align-items-center text-center' style={{ paddingLeft: '2px', width: '3%',  }}>-</div>
                                        <div className='align-items-center' style={{ width: '5%', padding: ' 5px 10px', border: '1px solid #121212',  }}> </div>
                                        <div className='align-items-center' style={{ width: '5%', padding: ' 5px 10px', border: '1px solid #121212', borderLeft: 'none' }}> </div>
                                        <div className='align-items-center text-center' style={{ paddingLeft: '2px', width: '3%',  }}>-</div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', border: '1px solid #121212', }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', border: '1px solid #121212', borderLeft: 'none' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', border: '1px solid #121212', borderLeft: 'none' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', border: '1px solid #121212', borderLeft: 'none', borderRight: '1px solid #121212' }}> </div>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>a.</div>
                                        <div className='align-items-center' style={{padding: '0px 10px 10px 10px' }}>Date of notice of amalgamation to secured creditors of the company</div>
                                    </div>
                                    <Wrapper className="table-responsive mt-0" >
                                            <table
                                                className="table table-bordered"
                                                style={{ fontFamily: "Montserrat rev=1 !important" }}
                                            >
                                                 <thead>
                                                    <th  style={{ verticalAlign: 'sub', border: '1px solid #121212', textAlign: "center" }}>
                                                       S#
                                                    </th>
                                                    <th style={{ verticalAlign: 'sub', border: '1px solid #121212', textAlign: "center" }}>
                                                    Name of secured creditors
                                                    </th>
                                                    <th  style={{ verticalAlign: 'sub', border: '1px solid #121212', textAlign: "center" }}>
                                                    Date of notice
                                                    </th>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}></td>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}></td>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}></td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}></td>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}></td>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}></td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}></td>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}></td>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}></td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}></td>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}></td>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}></td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                    </Wrapper>
                                    <PartsWrapper className='mt-4 mb-3'>
                                        PART-III
                                    </PartsWrapper>

                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>3.1</div>
                                        <div className='align-items-center' style={{ padding: '5px 10px 0px 10px' }}>Declaration</div>
                                    </div>
                                    <div className='d-flex'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}></div>
                                        <div className='align-items-center' style={{ padding: ' 0px 10px 10px 10px' }}>I do hereby solemnly and sincerely declare that the information provided in the form is:</div>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '2%' }}></div>
                                        <div className='align-items-center d-flex' style={{ padding: ' 5px 10px', gap: "12px" }}><div>
                                            (i)</div>
                                            <div style={{ marginLeft: '14px' }}> true and correct to the best of my knowledge, in consonance with the record as maintained by the Company and nothing has been concealed; and </div>
                                        </div>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '4%' }}></div>
                                        <div className='align-items-center d-flex' style={{ padding: ' 5px 10px', gap: "12px" }}><div>
                                            (ii)</div>
                                            <div style={{ marginLeft: '11px' }}>ereby reported after complying with and fulfilling all requirements under the relevant provisions of law, rules, regulations, directives, circulars and notifications whichever is applicable</div>
                                        </div>
                                    </div>
                                    

                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>3.2</div>
                                        <div className='align-items-center' style={{ width: '28%', padding: '0px 10px 10px 10px' }}> Name of Authorized Officer with designation/ Authorized Intermediary, if any</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '32.5%', padding: ' 5px 10px', }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', borderLeft: "none", width: '32.5%', padding: ' 5px 10px', }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>3.3</div>
                                        <div className='align-items-center' style={{ width: '28%', padding: '0px 10px 10px 10px' }}>Signatures </div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '65%', padding: ' 5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>3.4</div>
                                        <div className='align-items-center' style={{ width: '40%', padding: ' 0px 10px 10px 10px' }}>Registration No of Authorized Intermediary, if applicable</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '53%', padding: ' 5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}></div>
                                        <div className='align-items-center' style={{ width: '55%', padding: ' 5px 10px' }}> </div>
                                        <div className='align-items-center text-center' style={{ width: '8%', padding: ' 5px' }}> Day</div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center text-center' style={{ width: '8%', padding: ' 5px' }}> Month</div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center text-center' style={{ width: '16%', padding: ' 5px' }}>Year </div>
                                        <div></div>
                                    </div>
                                    <div className='d-flex '>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>3.5</div>
                                        <div className='align-items-center' style={{ width: '55%', padding: ' 0px 10px 10px 10px' }}>Date</div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ border: '1px solid #121212', width: '4%', padding: ' 5px 10px' }}> </div>
                                        <div className='align-items-center' style={{ paddingLeft: '8px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ border: '1px solid #121212', width: '4%', padding: ' 5px 10px', borderRight: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ paddingLeft: '8px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', border: '1px solid #121212', }}> </div>
                                    </div>
                                    <div className='d-flex my-2' style={{fontWeight:"bold"}}>
                                        Enclosure
                                    </div>
                                    <div className='d-flex align-items-baseline'>
                                    <div style={{marginLeft:"5px"}}>1.</div><div style={{marginLeft:'23px'}}> A copy of the approved scheme of amalgamation.</div>
                                    </div>
                                    <div className='d-flex'>
                                    <div style={{marginLeft:"5px"}}>2.</div><div style={{marginLeft:'20px'}}>A copy of board resolution of all companies.</div>
                                    </div>
                                    <div className='d-flex'>
                                    <div style={{marginLeft:"5px"}}>3.</div><div style={{marginLeft:'20px'}}>A declaration verified by an affidavit to the effect that the transferee company will be able to pay its debts as they fall due during the period of one year immediately after the date on which the amalgamation is to become effective (scanned image in case of online filing)</div>
                                    </div>
                                    <div className='d-flex'>
                                    <div style={{marginLeft:"5px"}}>4.</div><div style={{marginLeft:'19px'}}> Original challan or other evidence of payment of fee specified in Seventh Schedule of the Act (not applicable in case of online filing)</div>
                                    </div>
                                    <div className='d-flex my-2' style={{fontWeight:"bold"}}>
                                        Instruction:
                                    </div>
                                    <div className='d-flex align-items-baseline'>
                                    <div style={{marginLeft:"5px"}}>1.</div><div style={{marginLeft:'23px'}}> This Form should be filed by all the amalgamating companies with enclosures.</div>
                                    </div>
                                    <div className='d-flex'>
                                    <div style={{marginLeft:"5px"}}>2.</div><div style={{marginLeft:'20px'}}>All the facts stated in this Form should be verifiable from the scheme of amalgamation.</div>
                                    </div>
                                    

                                </div>
                            </div>
                        </div>
                    </PDFExport>
                    : <PDFExport
                        paperSize="A4"
                        margin="1cm"
                        scale={0.6}
                        fileName={`Statutory Form 8`}
                        pageTemplate={PageTemplate}
                        ref={pdfExportComponent}
                    >
                         <div style={{ fontFamily: `${fontFamilyForStaticData}`, fontSize: `${fontSizeForStaticData}`, fontWeight: 500 }}>
                            <FormName>
                                Form-8
                            </FormName>
                            <div className='row'>
                                <div className='col-md-12' >
                                    <HeadingWrapper className='d-flex justify-content-center'>
                                        THE COMPANIES ACT, 2017
                                    </HeadingWrapper>

                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <HeadingWrapper className='d-flex justify-content-center'>
                                        THE COMPANIES REGULATIONS, 2024
                                    </HeadingWrapper>

                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <div style={{ fontSize: '15px', fontWeight: 'bold' }} className='d-flex justify-content-center'>
                                        [Section 284(5) and Regulations 60 & 30]
                                    </div>
                                </div>
                            </div>

                            <div className='row mt-4'>
                                <div className='col-md-12'>
                                    <HeadingWrapper className='d-flex justify-content-center'>
                                        FILING OF COPY OF SCHEME OF AMALGAMATION
                                    </HeadingWrapper>
                                </div>
                            </div>
                            <PartsWrapper className='mt-4 mb-3'>
                                PART-I
                            </PartsWrapper>
                            <div className='row'>
                                <div className='col-md-12' style={{ padding: '25px 50px', wordWrap: 'break-word' }}>
                                    <div> <i>(Please complete in bold capital letters)</i></div>


                                    <div className='d-flex' >
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>1.1</div>
                                        <div className='align-items-center' style={{ width: '28%', padding: '0px 10px 10px 10px' }}>CUIN (Registration Number)  </div>
                                        <div className='align-items-center  d-flex'  >{
                                            '1234 552'.split("")?.map((item, idx) => {
                                                if (item == ' ') return
                                                return <div style={{ minWidth: '30px', minHeight: '34px', border: '1px solid #121212', borderLeft: idx != 0 ? "none" : "1px solid #121212", padding: '6px 9px' }}></div>
                                            })
                                        }
                                        </div>
                                    </div>



                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>1.2</div>
                                        <div className='align-items-center' style={{ width: '22%', padding: '0px 10px 10px 10px' }}>Name of the Company  </div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '71%', padding: '0px 10px 10px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                    </div>




                                    <div className='d-flex  mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>1.3</div>
                                        <div className='align-items-center' style={{ width: '20.20%', padding: '0px 10px 10px 10px' }}>Filing Fee payment Details*</div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '5%' }}>1.3.1</div>
                                        <div className='align-items-center' style={{ width: '13.20%', paddingLeft: '20px' }}>Challan No.</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '17.20%', paddingLeft: '20px', minHeight: '34px' }}></ScrollWrapper>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '5%' }}>1.3.2</div>
                                        <div className='align-items-center' style={{ width: '13.20%', paddingLeft: '20px' }}>Amount</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '19.20%', paddingLeft: '20px', minHeight: '34px' }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '85%', borderBottom: "none", paddingLeft: '20px', minHeight: '70px', fontWeight: "bold" }}>Scheme of —</ScrollWrapper>
                                        <ScrollWrapper className='align-items-center text-center' style={{ border: '1px solid #121212', borderLeft: "none", borderBottom: "none", width: '15%', padding: '10px', minHeight: '70px' }}>Please tick the relevant box</ScrollWrapper>
                                    </div>
                                    <div className='d-flex'>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '21%', paddingLeft: '20px', borderBottom: "none", minHeight: '34px', fontWeight: "bold" }}>1.4</ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '64%', borderLeft: "none", borderBottom: "none", paddingLeft: '20px', minHeight: '34px' }}> amalgamation of wholly owned subsidiary(ies) into its holding company</ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '15%', borderLeft: "none", borderBottom: "none", paddingLeft: '20px', minHeight: '34px' }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex'>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '21%', paddingLeft: '20px', minHeight: '34px', fontWeight: "bold" }}>1.5</ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '64%', borderLeft: "none", paddingLeft: '20px', minHeight: '34px' }}>amalgamation of two or more companies wholly owned by a person</ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '15%', borderLeft: "none", paddingLeft: '20px', minHeight: '34px' }}></ScrollWrapper>
                                    </div>
                                    <PartsWrapper className='mt-4 mb-3'>
                                        PART-II
                                    </PartsWrapper>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>2.</div>
                                        <div className='align-items-center' style={{ padding: '0px 10px 10px 10px' }}>Capital structure of the Company, if applicable</div>
                                    </div>
                                    <div className='d-flex '>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '45%' }}></div>
                                        <Wrapper style={{ width: "26%" }} className="table-responsive mt-0" >
                                            <table
                                                className="table table-bordered"
                                                style={{ fontFamily: "Montserrat rev=1 !important" }}
                                            >
                                                <thead>
                                                    <th colSpan={2} style={{ verticalAlign: 'sub', border: '1px solid #121212', textAlign: "center" }}>
                                                        Share capital
                                                        prior to amalgamation
                                                    </th>

                                                </thead>

                                                <tbody>
                                                    <tr>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}>Number of Shares</td>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}>Amount</td>
                                                    </tr>


                                                </tbody>
                                            </table>
                                        </Wrapper>
                                        <Wrapper style={{ width: "26%" ,marginLeft:"2rem"}} className="table-responsive mt-0" >
                                            <table
                                                className="table table-bordered"
                                                style={{  fontFamily: "Montserrat rev=1 !important" }}
                                            >
                                                <thead>
                                                    <th colSpan={2} style={{ verticalAlign: 'sub', border: '1px solid #121212', textAlign: "center" }}>
                                                        Share capital after amalgamation
                                                    </th>

                                                </thead>

                                                <tbody>
                                                    <tr>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}>Number of Shares</td>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}>Amount</td>
                                                    </tr>


                                                </tbody>
                                            </table>
                                        </Wrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>2.1</div>
                                        <div className='align-items-center' style={{ width:"38%",padding: '0px 10px 10px 10px' }}>Authorized capital</div>
                                        <Wrapper style={{ width: "26%" }} className="table-responsive mt-0" >
                                            <table
                                                className="table table-bordered"
                                                style={{ fontFamily: "Montserrat rev=1 !important" }}
                                            >
                                                <tbody>
                                                    <tr>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}></td>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}></td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </Wrapper>
                                        <Wrapper style={{ width: "26%",marginLeft:"2rem" }} className="table-responsive mt-0" >
                                            <table
                                                className="table table-bordered"
                                                style={{ fontFamily: "Montserrat rev=1 !important" }}
                                            >
                                                <tbody>
                                                    <tr>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}></td>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}></td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </Wrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>2.2</div>
                                        <div className='align-items-center' style={{ width:"38%",padding: '0px 10px 10px 10px' }}> Paid up capital</div>
                                        <Wrapper style={{ width: "26%" }} className="table-responsive mt-0" >
                                            <table
                                                className="table table-bordered"
                                                style={{ fontFamily: "Montserrat rev=1 !important" }}
                                            >
                                                <tbody>
                                                    <tr>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}></td>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}></td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </Wrapper>
                                        <Wrapper style={{ width: "26%",marginLeft:"2rem" }} className="table-responsive mt-0" >
                                            <table
                                                className="table table-bordered"
                                                style={{ fontFamily: "Montserrat rev=1 !important" }}
                                            >
                                                <tbody>
                                                    <tr>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}></td>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}></td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </Wrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>a.</div>
                                        <div className='align-items-center' style={{padding: '0px 10px 10px 10px' }}>Particulars of Board resolutions passed by each amalgamating company</div>
                                    </div>
                                    <Wrapper className="table-responsive mt-0" >
                                            <table
                                                className="table table-bordered"
                                                style={{ fontFamily: "Montserrat rev=1 !important" }}
                                            >
                                                 <thead>
                                                    <th  style={{ verticalAlign: 'sub', border: '1px solid #121212', textAlign: "center" }}>
                                                       S#
                                                    </th>
                                                    <th style={{ verticalAlign: 'sub', border: '1px solid #121212', textAlign: "center" }}>
                                                    Name of Company
                                                    </th>
                                                    <th  style={{ verticalAlign: 'sub', border: '1px solid #121212', textAlign: "center" }}>
                                                    Date of Board resolution
                                                    </th>
                                                    <th style={{ verticalAlign: 'sub', border: '1px solid #121212', textAlign: "center" }}>
                                                    Gist of Board resolution (attach copy of Resolution)
                                                    </th>

                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}></td>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}></td>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}></td>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}></td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}></td>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}></td>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}></td>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}></td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}></td>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}></td>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}></td>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}></td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                    </Wrapper>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%',  }}></div>
                                        <div className='align-items-center' style={{ width: '51%', padding: ' 5px 10px',}}> </div>
                                        <div className='align-items-center text-center' style={{ width: '10%', padding: ' 5px', }}> dd</div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%', }}></div>
                                        <div className='align-items-center text-center' style={{ width: '10%', padding: ' 5px',  }}> mm</div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%', }}></div>
                                        <div className='align-items-center text-center' style={{  width: '16%', padding: ' 5px 10px' }}>yyyy</div>
                                        <div></div>
                                    </div>
                                    <div className='d-flex '>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%',  }}>2.3</div>
                                        <div className='align-items-center' style={{ width: '51%', padding: ' 0px 10px 10px 10px',  }}> Effective Date of amalgamation</div>
                                        <div className='align-items-center' style={{ width: '5%', padding: ' 5px 10px', border: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '5%', padding: ' 5px 10px', border: '1px solid #121212', borderLeft: 'none' }}> </div>
                                        <div className='align-items-center text-center' style={{ paddingLeft: '2px', width: '3%',  }}>-</div>
                                        <div className='align-items-center' style={{ width: '5%', padding: ' 5px 10px', border: '1px solid #121212',  }}> </div>
                                        <div className='align-items-center' style={{ width: '5%', padding: ' 5px 10px', border: '1px solid #121212', borderLeft: 'none' }}> </div>
                                        <div className='align-items-center text-center' style={{ paddingLeft: '2px', width: '3%',  }}>-</div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', border: '1px solid #121212', }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', border: '1px solid #121212', borderLeft: 'none' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', border: '1px solid #121212', borderLeft: 'none' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', border: '1px solid #121212', borderLeft: 'none', borderRight: '1px solid #121212' }}> </div>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>a.</div>
                                        <div className='align-items-center' style={{padding: '0px 10px 10px 10px' }}>Date of notice of amalgamation to secured creditors of the company</div>
                                    </div>
                                    <Wrapper className="table-responsive mt-0" >
                                            <table
                                                className="table table-bordered"
                                                style={{ fontFamily: "Montserrat rev=1 !important" }}
                                            >
                                                 <thead>
                                                    <th  style={{ verticalAlign: 'sub', border: '1px solid #121212', textAlign: "center" }}>
                                                       S#
                                                    </th>
                                                    <th style={{ verticalAlign: 'sub', border: '1px solid #121212', textAlign: "center" }}>
                                                    Name of secured creditors
                                                    </th>
                                                    <th  style={{ verticalAlign: 'sub', border: '1px solid #121212', textAlign: "center" }}>
                                                    Date of notice
                                                    </th>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}></td>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}></td>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}></td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}></td>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}></td>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}></td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}></td>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}></td>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}></td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}></td>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}></td>
                                                        <td style={{ verticalAlign: 'sub', fontWeight: "bold", minHeight: '24px', width: '7%', textAlign: "center" }}></td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                    </Wrapper>
                                    <PartsWrapper className='mt-4 mb-3'>
                                        PART-III
                                    </PartsWrapper>

                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>3.1</div>
                                        <div className='align-items-center' style={{ padding: '5px 10px 0px 10px' }}>Declaration</div>
                                    </div>
                                    <div className='d-flex'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}></div>
                                        <div className='align-items-center' style={{ padding: ' 0px 10px 10px 10px' }}>I do hereby solemnly and sincerely declare that the information provided in the form is:</div>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '2%' }}></div>
                                        <div className='align-items-center d-flex' style={{ padding: ' 5px 10px', gap: "12px" }}><div>
                                            (i)</div>
                                            <div style={{ marginLeft: '14px' }}> true and correct to the best of my knowledge, in consonance with the record as maintained by the Company and nothing has been concealed; and </div>
                                        </div>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '4%' }}></div>
                                        <div className='align-items-center d-flex' style={{ padding: ' 5px 10px', gap: "12px" }}><div>
                                            (ii)</div>
                                            <div style={{ marginLeft: '11px' }}>ereby reported after complying with and fulfilling all requirements under the relevant provisions of law, rules, regulations, directives, circulars and notifications whichever is applicable</div>
                                        </div>
                                    </div>
                                    

                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>3.2</div>
                                        <div className='align-items-center' style={{ width: '28%', padding: '0px 10px 10px 10px' }}> Name of Authorized Officer with designation/ Authorized Intermediary, if any</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '32.5%', padding: ' 5px 10px', }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', borderLeft: "none", width: '32.5%', padding: ' 5px 10px', }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>3.3</div>
                                        <div className='align-items-center' style={{ width: '28%', padding: '0px 10px 10px 10px' }}>Signatures </div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '65%', padding: ' 5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>3.4</div>
                                        <div className='align-items-center' style={{ width: '40%', padding: ' 0px 10px 10px 10px' }}>Registration No of Authorized Intermediary, if applicable</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '53%', padding: ' 5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}></div>
                                        <div className='align-items-center' style={{ width: '55%', padding: ' 5px 10px' }}> </div>
                                        <div className='align-items-center text-center' style={{ width: '8%', padding: ' 5px' }}> Day</div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center text-center' style={{ width: '8%', padding: ' 5px' }}> Month</div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center text-center' style={{ width: '16%', padding: ' 5px' }}>Year </div>
                                        <div></div>
                                    </div>
                                    <div className='d-flex '>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>3.5</div>
                                        <div className='align-items-center' style={{ width: '55%', padding: ' 0px 10px 10px 10px' }}>Date</div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ border: '1px solid #121212', width: '4%', padding: ' 5px 10px' }}> </div>
                                        <div className='align-items-center' style={{ paddingLeft: '8px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ border: '1px solid #121212', width: '4%', padding: ' 5px 10px', borderRight: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ paddingLeft: '8px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', border: '1px solid #121212', }}> </div>
                                    </div>
                                    <div className='d-flex my-2' style={{fontWeight:"bold"}}>
                                        Enclosure
                                    </div>
                                    <div className='d-flex align-items-baseline'>
                                    <div style={{marginLeft:"5px"}}>1.</div><div style={{marginLeft:'23px'}}> A copy of the approved scheme of amalgamation.</div>
                                    </div>
                                    <div className='d-flex'>
                                    <div style={{marginLeft:"5px"}}>2.</div><div style={{marginLeft:'20px'}}>A copy of board resolution of all companies.</div>
                                    </div>
                                    <div className='d-flex'>
                                    <div style={{marginLeft:"5px"}}>3.</div><div style={{marginLeft:'20px'}}>A declaration verified by an affidavit to the effect that the transferee company will be able to pay its debts as they fall due during the period of one year immediately after the date on which the amalgamation is to become effective (scanned image in case of online filing)</div>
                                    </div>
                                    <div className='d-flex'>
                                    <div style={{marginLeft:"5px"}}>4.</div><div style={{marginLeft:'19px'}}> Original challan or other evidence of payment of fee specified in Seventh Schedule of the Act (not applicable in case of online filing)</div>
                                    </div>
                                    <div className='d-flex my-2' style={{fontWeight:"bold"}}>
                                        Instruction:
                                    </div>
                                    <div className='d-flex align-items-baseline'>
                                    <div style={{marginLeft:"5px"}}>1.</div><div style={{marginLeft:'23px'}}> This Form should be filed by all the amalgamating companies with enclosures.</div>
                                    </div>
                                    <div className='d-flex'>
                                    <div style={{marginLeft:"5px"}}>2.</div><div style={{marginLeft:'20px'}}>All the facts stated in this Form should be verifiable from the scheme of amalgamation.</div>
                                    </div>
                                    

                                </div>
                            </div>
                        </div>
                    </PDFExport>}
            </div>
            <button disabled={loading} className='btn btn-danger' onClick={(e) => {
                // if(viewPdf)
                //   SetViewPDf(true)
                if (pdfExportComponent.current) {
                    pdfExportComponent.current.save();
                }
            }}>Download PDF</button>
        </div>
    )
}
const PartsWrapper = styled.div`
color: #121212;
text-decoration: underline;
justify-content: center;
display: flex;
margin-right: 15px;
font-size: 17px;
font-weight: bold;
`;
const ScrollWrapper = styled.div`
max-height: 50px;
overflow-y: scroll;
overflow-x: hidden;
::-webkit-scrollbar{
    height: 5px;
    width: 3px;
  }
  
  ::-webkit-scrollbar-track{
    background: #F9F9FB;
  }
  ::-webkit-scrollbar-thumb{
    background: #4E515680;
    border-radius: 5px;
  
  }
`;
const HeadingWrapper = styled.div`
font-size: 17px;
font-weight: bold;
`;
const FormName = styled.div`
color: #121212;
text-decoration: underline;
justify-content: end;
display: flex;
margin-right: 15px;
font-size: 17px;
font-weight: bold;
`;
const Wrapper = styled.div`
table.table-bordered{
    border: 1px solid #121212
     border-color: #121212; 
    // border:1px solid blue;
    // margin-top:20px;
  }
// table.table-bordered > thead > tr > th{
//     // border:1px solid blue;
//     border-color: #121212; 
// }
// table.table-bordered > tbody > tr > td{
//     // border:1px solid blue;
//     borderC-color: #121212; 
// }
 tr td {
    // border:1px solid blue;.
    border-color: #121212; 
}

`;
const WrapperDashed = styled.div`

  td {
    
    // border: 1px dashed #dddcdc;
}

`;