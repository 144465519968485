import React, { useState, useEffect } from 'react'
import { PDFExport } from "@progress/kendo-react-pdf";
import PageTemplate from "./page-template";
import {
    getCompanies,
} from "../../../store/services/company.service";
import styled from 'styled-components';
import { TiTick } from "react-icons/ti";
import { numberWithCommas } from 'utilities/utilityFunctions';
export const App1 = ({ data, formTemplate = false }) => {
    const pdfExportComponent = React.useRef(null);
    const [CompanyData, setCompanyData] = useState(null)
    const [loading, setLoading] = useState(false);
    const [fontFamilyForStaticData, setFontFamilyForStaticData] = useState('Montserrat, sans-serif')
    const [fontSizeForStaticData, setFontSizeForStaticData] = useState('14px')
    const [fontfamilyforDynimicData, setFontFamilyForDynmicData] = useState('Segoe Print')
    const [fontColor, setFontColor] = useState('')
    const baseEmail = sessionStorage.getItem("email") || "";
    const [governance, setGovernance] = useState({})
    const getAllCompanies = async () => {
        setLoading(true)
        try {
            const response = await getCompanies(baseEmail);
            if (response.status === 200) {
                response.data.data = response.data.data.filter((item) => item?.active === 'Y')
                const parents = response.data.data;
                const company = parents?.find(ite => ite?.code == data?.company_code)
                let Allperson = company.governance && company.governance != "" ? JSON.parse(company.governance) : []
                let sec = Allperson.find(x => x.role == "Company Secretary" && x?.active?.toLowerCase() != 'n')
                let ceo = Allperson.find(x => x.role == "CEO" && x?.active?.toLowerCase() != 'n')


                if (sec) {
                    setGovernance(sec)
                } else if (ceo) {
                    setGovernance(ceo)
                }
                setCompanyData(company)
                setTimeout(() => {
                    setLoading(false)
                }, 1000);
            }
        } catch (error) {
            setLoading(false)
        }
    };
    useEffect(() => {
        getAllCompanies()
    }, [])
    return (
        <div>
            <div
                style={{ fontFamily: "sans-serif " }}
            >

                {formTemplate ?
                    <PDFExport
                        paperSize="A4"
                        margin="1cm"
                        scale={0.6}
                        fileName={`App-1`}
                        pageTemplate={PageTemplate}
                        ref={pdfExportComponent}
                    >
                        <div style={{ fontFamily: `${fontFamilyForStaticData}`, fontSize: `${fontSizeForStaticData}`, fontWeight: 500 }}>
                            <FormName>
                                App-1
                            </FormName>
                            <div className='row'>
                                <div className='col-md-12' >
                                    <HeadingWrapper className='d-flex justify-content-center'>
                                        THE COMPANIES ACT, 2017 THE COMPANIES REGULATIONS, 2024
                                    </HeadingWrapper>

                                </div>
                            </div>

                            <div className='row'>
                                <div className='col-md-12'>
                                    <div style={{ fontSize: '15px', fontWeight: 'bold' }} className='d-flex justify-content-center'>
                                        [Section 10(4), 12 & 442 and Regulations 3, 4 & 30]
                                    </div>
                                </div>
                            </div>

                            <div className='row mt-4'>
                                <div className='col-md-12'>
                                    <HeadingWrapper className='text-center d-flex justify-content-center'>
                                        APPLICATION FOR RESERVATION OF COMPANY NAME FOR NEW INCORPORATION / CHANGE OF NAME OF EXISTING COMPANY
                                    </HeadingWrapper>
                                </div>
                            </div>
                            <PartsWrapper className='mt-4 mb-3'>
                                PART I
                            </PartsWrapper>
                            <div className='row'>
                                <div className='col-md-12' style={{ padding: '25px 50px', wordWrap: 'break-word' }}>
                                    <div> <i>(Please complete in bold capital letters)</i></div>


                                    <div className='d-flex  mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>1.1</div>
                                        <div className='align-items-center' style={{ width: '20.20%', padding: '0px 10px 10px 10px' }}>Fee payment Details*</div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '5%' }}>1.2</div>
                                        <div className='align-items-center' style={{ width: '13.20%', paddingLeft: '20px' }}>Challan No.</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '17.20%', paddingLeft: '20px', minHeight: '34px' }}></ScrollWrapper>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '5%' }}>1.3</div>
                                        <div className='align-items-center' style={{ width: '13.20%', paddingLeft: '20px' }}>Amount(Rs.)</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '23.20%', paddingLeft: '20px', minHeight: '34px' }}></ScrollWrapper>
                                    </div>
                                    <PartsWrapper className='mt-4 mb-3'>
                                        PART II
                                    </PartsWrapper>
                                    <div className='row mt-4'>
                                        <div className='col-md-12'>
                                            <HeadingWrapper className='text-center d-flex justify-content-center'>
                                                (This part is to be filled for the incorporation/ change of name of a local company)
                                            </HeadingWrapper>
                                        </div>
                                    </div>
                                    <Wrapper className="table-responsive mt-0" >
                                        <table
                                            className="table table-bordered"
                                            style={{ fontFamily: "Montserrat rev=1 !important" }}
                                        >
                                            <thead>
                                                <th style={{ verticalAlign: 'sub', textAlign: "center", border: '1px solid #121212', textAlign: "center" }}>
                                                    <div>2.1</div><div>Application for:- </div>
                                                </th>
                                                <th style={{ verticalAlign: 'sub', textAlign: "center", border: '1px solid #121212', textAlign: "center" }}>
                                                    Please tick the relevant box
                                                </th>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}>Reservation of name for incorporation of a new company</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}>Reservation of name for change of name of existing company</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </Wrapper>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>2.2</div>
                                        <div className='align-items-center' style={{ width: '29%', padding: '0px 10px 10px 10px' }}> In case of change of name, please mention the complete name of existing company</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '68%', padding: '0px 10px 10px 10px', }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>2.3</div>
                                        <div className='align-items-center' style={{ width: '29%', padding: '0px 10px 10px 10px' }}> Propose three options for name reservation in the order of priority.</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '68%', padding: '0px 10px 10px 10px', }}>Option 1</ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ width: '29%', padding: '0px 10px 10px 10px' }}><i>(mandatory in case of combined application. Fee will be charged for one name only instead of three names.)</i></div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '68%', padding: '0px 10px 10px 10px', }}>Option 2</ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ width: '29%', padding: '0px 10px 10px 10px' }}></div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '68%', padding: '0px 10px 10px 10px', }}>Option 3</ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ padding: '0px 10px 10px 10px' }}><i>(Please enter the name without kind of company e.g. (Private) Limited, Limited etc.).</i></div>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ padding: '0px 10px 10px 10px' }}><i>(As required under proviso (i) to sub-section (1) of section 26 of the Act, the proposed name shall always commensurate with the principal line of business of the company as mentioned in memorandum of association)</i></div>
                                    </div>
                                    <div className='d-flex mt-0'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>2.4</div>
                                        <div className='align-items-center' style={{ width: '29%', padding: '0px 10px 10px 10px' }}> Kind of proposed/existing company <i>(Please tick the relevant box)</i></div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '68%', padding: '0px 10px 10px 10px', }}>Option 3</ScrollWrapper>
                                    </div>
                                    <Wrapper className="table-responsive mt-2" >
                                        <table
                                            className="table table-bordered"
                                            style={{ fontFamily: "Montserrat rev=1 !important" }}
                                        >
                                            <tbody>
                                                <tr>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}>a.</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}>Private Limited Company</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}>b.</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}>Single Member Company</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}>c.</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}>Public Limited Company</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}>d.</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}>Association Not for-profit licensed under section 42 of the Companies Act, 2017</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}>e.</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}> Trade Organization Act, 2013 must be mentioned separately</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}>f.</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}>Other (Please specify)</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </Wrapper>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>2.5</div>
                                        <div className='align-items-center' style={{ width: '29%', padding: '0px 10px 10px 10px' }}>Principal line of business for proposed/existing company (Brief object as per clause 3 (i) of the Memorandum may be mentioned)</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '68%', padding: '0px 10px 10px 10px', }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-0'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>2.6</div>
                                        <div className='align-items-center' style={{ width: '29%', padding: '0px 10px 10px 10px' }}> Meaning / Significance of proposed name (describe relevancy of proposed name with company’s principal line of business)</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '68%', padding: '0px 10px 10px 10px', }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-0'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>2.7</div>
                                        <div className='align-items-center' style={{ width: '29%', padding: '0px 10px 10px 10px' }}> If any company or entity exists with identical or similar name, please mention your relationship with such company or entity along with board resolution/ NOC showing no objection on registration of a company with similar name.</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '68%', padding: '0px 10px 10px 10px', }}></ScrollWrapper>
                                    </div>

                                    <PartsWrapper className='mt-4 mb-3'>
                                        PART III
                                    </PartsWrapper>

                                    <div className='row mt-4'>
                                        <div className='col-md-12'>
                                            <HeadingWrapper className='text-center d-flex justify-content-center'>
                                            (This part is to be filled for the registration/change of name of a foreign company)
                                            </HeadingWrapper>
                                        </div>
                                    </div>
                                    <Wrapper className="table-responsive mt-0" >
                                        <table
                                            className="table table-bordered"
                                            style={{ fontFamily: "Montserrat rev=1 !important" }}
                                        >
                                           
                                            <tbody>
                                                <tr>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%',fontWeight:"bold" }}>3.1 Application for:-</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}>Please tick the relevant box</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}>Reservation of name for registration of foreign company</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}>Reservation of name for change of name of existing foreign company</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}></td>
                                                </tr>
                                                
                                            </tbody>
                                        </table>
                                    </Wrapper>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>3.2</div>
                                        <div className='align-items-center' style={{ width: '29%', padding: '0px 10px 10px 10px' }}> In case of change of name, please mention the complete name of existing foreign company</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '68%', padding: '0px 10px 10px 10px', }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-0'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>3.3</div>
                                        <div className='align-items-center' style={{ width: '29%', padding: '0px 10px 10px 10px' }}>Incorporated in: <i>(state name of the country of origin)</i></div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '68%', padding: '0px 10px 10px 10px', }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%',  }}></div>
                                        <div className='align-items-center' style={{ width: '51%', padding: ' 5px 10px',}}> </div>
                                        <div className='align-items-center text-center' style={{ width: '10%', padding: ' 5px', }}> dd</div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%', }}></div>
                                        <div className='align-items-center text-center' style={{ width: '10%', padding: ' 5px',  }}> mm</div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%', }}></div>
                                        <div className='align-items-center text-center' style={{  width: '16%', padding: ' 5px 10px' }}>yyyy</div>
                                        <div></div>
                                    </div>
                                    <div className='d-flex '>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%',  }}>3.4</div>
                                        <div className='align-items-center' style={{ width: '51%', padding: ' 0px 10px 10px 10px',  }}>Date of incorporation: <i>(in the country of origin)</i></div>
                                        <div className='align-items-center' style={{ width: '5%', padding: ' 5px 10px', border: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '5%', padding: ' 5px 10px', border: '1px solid #121212', borderLeft: 'none' }}> </div>
                                        <div className='align-items-center text-center' style={{ paddingLeft: '2px', width: '3%',  }}></div>
                                        <div className='align-items-center' style={{ width: '5%', padding: ' 5px 10px', border: '1px solid #121212',  }}> </div>
                                        <div className='align-items-center' style={{ width: '5%', padding: ' 5px 10px', border: '1px solid #121212', borderLeft: 'none' }}> </div>
                                        <div className='align-items-center text-center' style={{ paddingLeft: '2px', width: '3%',  }}></div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', border: '1px solid #121212', }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', border: '1px solid #121212', borderLeft: 'none' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', border: '1px solid #121212', borderLeft: 'none' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', border: '1px solid #121212', borderLeft: 'none', borderRight: '1px solid #121212' }}> </div>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>3.5</div>
                                        <div className='align-items-center' style={{ width: '29%', padding: '0px 10px 10px 10px' }}> Kind of the company <i>(in the country of origin)</i></div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '68%', padding: '0px 10px 10px 10px', }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>3.6</div>
                                        <div className='align-items-center' style={{ width: '29%', padding: '0px 10px 10px 10px' }}> name of foreign company in the country of origin as proposed to be reserved</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '68%', padding: '0px 10px 10px 10px', }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>3.7</div>
                                        <div className='align-items-center' style={{ width: '29%', padding: '0px 10px 10px 10px' }}> Meaning / Significance of name to be reserved</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '68%', padding: '0px 10px 10px 10px', }}></ScrollWrapper>
                                    </div>
                                    <PartsWrapper className='mt-4 mb-3'>
                                        PART IV
                                    </PartsWrapper>

                                    <div className='row mt-4'>
                                        <div className='col-md-12'>
                                            <HeadingWrapper className='text-center d-flex justify-content-center'>
                                            (This part is applicable for local and foreign companies both)
                                            </HeadingWrapper>
                                        </div>
                                    </div>
                                    <div className='d-flex mt-0'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>4.1</div>
                                        <div className='align-items-center' style={{ width: '29%', padding: '0px 10px 10px 10px' }}>Declaration</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '68%', padding: '0px 10px 10px 10px', }}>I do hereby solemnly and sincerely declare that the information provided in the form is true and correct and nothing is concealed and that the proposed name, if reserved, shall be used only for the purpose of incorporation/ registration/ change of name of the company</ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>4.2</div>
                                        <div className='align-items-center' style={{ width: '29%', padding: '0px 10px 10px 10px' }}> Name of Proposed Subscriber in case of local company / Name of Authorized Officer in Pakistan in case of foreign company with his/her designation /Authorized Intermediary</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '68%', padding: '0px 10px 10px 10px', }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>4.3</div>
                                        <div className='align-items-center' style={{ width: '29%', padding: '0px 10px 10px 10px' }}>NIC/NICOP No. where the applicant is </div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '4%', padding: '0px 10px 10px 10px', }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '4%', padding: '0px 10px 10px 10px', }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '4%', padding: '0px 10px 10px 10px', }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '4%', padding: '0px 10px 10px 10px', }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '4%', padding: '0px 10px 10px 10px', }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '4%', padding: '0px 10px 10px 10px', }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '4%', padding: '0px 10px 10px 10px', }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '4%', padding: '0px 10px 10px 10px', }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '4%', padding: '0px 10px 10px 10px', }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '4%', padding: '0px 10px 10px 10px', }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '4%', padding: '0px 10px 10px 10px', }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '4%', padding: '0px 10px 10px 10px', }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '4%', padding: '0px 10px 10px 10px', }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '4%', padding: '0px 10px 10px 10px', }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '4%', padding: '0px 10px 10px 10px', }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-0'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ padding: '0px 10px 10px 10px' }}>Pakistani <i>/Passport No. in case of foreign national</i></div>
                                    </div>
                                    <div className='d-flex mt-0'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>4.4</div>
                                        <div className='align-items-center' style={{ width: '29%', padding: '0px 10px 10px 10px' }}>Signatures</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '68%', padding: '0px 10px 10px 10px', }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>4.5</div>
                                        <div className='align-items-center' style={{ width: '29%', padding: '0px 10px 10px 10px' }}> Registration No of Authorized Intermediary, if applicable</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '68%', padding: '0px 10px 10px 10px', }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>4.6</div>
                                        <div className='align-items-center' style={{ width: '29%', padding: '0px 10px 10px 10px' }}> Contact details of the applicant, i.e. address, email and cell No. etc.</div>
                                        <Wrapper className="table-responsive mt-0" >
                                        <table
                                            className="table table-bordered"
                                            style={{ fontFamily: "Montserrat rev=1 !important" }}
                                        >
                                            <tbody>
                                                <tr>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%',fontWeight:"bold" }}>Address</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}>Email:</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}>Cell No.</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}></td>
                                                </tr>   
                                            </tbody>
                                        </table>
                                    </Wrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ width: '55%', padding: ' 5px 10px' }}> </div>
                                        <div className='align-items-center text-center' style={{ width: '8%', padding: ' 5px' }}> Day</div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center text-center' style={{ width: '8%', padding: ' 5px' }}> Month</div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center text-center' style={{ width: '16%', padding: ' 5px' }}>Year </div>
                                        <div></div>
                                    </div>
                                    <div className='d-flex '>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>4.7</div>
                                        <div className='align-items-center' style={{ width: '55%', padding: ' 0px 10px 10px 10px' }}>Date</div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ border: '1px solid #121212', width: '4%', padding: ' 5px 10px' }}> </div>
                                        <div className='align-items-center' style={{ paddingLeft: '8px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ border: '1px solid #121212', width: '4%', padding: ' 5px 10px', borderRight: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ paddingLeft: '8px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', border: '1px solid #121212', }}> </div>
                                    </div>

                                    <div className='d-flex my-2' style={{fontWeight:"bold"}}>
                                    Enclosures (for local company):
                                    </div>
                                    <div className='d-flex align-items-baseline'>
                                    <div style={{marginLeft:"5px"}}>1.</div><div style={{marginLeft:'23px'}}>Original NOC/permission/letter of intent of competent authority (if applicable)</div>
                                    </div>
                                    <div className='d-flex'>
                                    <div style={{marginLeft:"5px"}}>2.</div><div style={{marginLeft:'20px'}}>Any other document deemed necessary.</div>
                                    </div>
                                    <div className='d-flex'>
                                    <div style={{marginLeft:"5px"}}>3.</div><div style={{marginLeft:'20px'}}>Original challan or other evidence of payment of fee specified in Seventh Schedule of the Act (not applicable in case of online filing)</div>
                                    </div>
                                    <div className='d-flex my-2' style={{fontWeight:"bold"}}>
                                    Enclosures (for foreign company):
                                    </div>
                                    <div className='d-flex align-items-baseline'>
                                    <div style={{marginLeft:"5px"}}>1.</div><div style={{marginLeft:'23px'}}>Copy of certificate of incorporation or any other document as an evidence of the registration of Foreign Company.</div>
                                    </div>
                                    <div className='d-flex'>
                                    <div style={{marginLeft:"5px"}}>2.</div><div style={{marginLeft:'20px'}}>Any other document deemed necessary. </div>
                                    </div>
                                    <div className='d-flex'>
                                    <div style={{marginLeft:"5px"}}>3.</div><div style={{marginLeft:'20px'}}>Original challan or other evidence of payment of fee specified in Seventh Schedule of the Act (not applicable in case of online filing)</div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </PDFExport>
                    : <PDFExport
                        paperSize="A4"
                        margin="1cm"
                        scale={0.6}
                        fileName={`App-1`}
                        pageTemplate={PageTemplate}
                        ref={pdfExportComponent}
                    >
                        {/* <div style={{ fontFamily: `${fontFamilyForStaticData}`, fontSize: `${fontSizeForStaticData}`, fontWeight: 500 }}>
                            <FormName>
                                Form - 7
                            </FormName>
                            <div className='row'>
                                <div className='col-md-12' >
                                    <HeadingWrapper className='d-flex justify-content-center'>
                                        THE COMPANIES ACT, 2017
                                    </HeadingWrapper>

                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <HeadingWrapper className='d-flex justify-content-center'>
                                        THE COMPANIES REGULATIONS, 2024
                                    </HeadingWrapper>

                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <div style={{ fontSize: '15px', fontWeight: 'bold' }} className='d-flex justify-content-center'>
                                        [Section 85 and Regulation 30]
                                    </div>
                                </div>
                            </div>

                            <div className='row mt-4'>
                                <div className='col-md-12'>
                                    <HeadingWrapper className='d-flex justify-content-center'>
                                        NOTICE OF ALTERATION IN SHARE CAPITAL
                                    </HeadingWrapper>
                                </div>
                            </div>
                            <PartsWrapper className='mt-4 mb-3'>
                                Part - I
                            </PartsWrapper>
                            <div className='row'>
                                <div className='col-md-12' style={{ padding: '25px 50px', wordWrap: 'break-word' }}>
                                    <div style={{ paddingLeft: '10px' }}> (Please complete in bold capital letters)</div>


                                    <div className='d-flex' >
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>1.1</div>
                                        <div className='align-items-center' style={{ width: '28%', padding: ' 5px 10px' }}>CUIN (Registration Number)  </div>
                                        <div className='align-items-center  d-flex'  >{
                                            '1234 552'.split("")?.map((item, idx) => {
                                                if (item == ' ') return
                                                return <div style={{ minWidth: '30px', minHeight: '34px', border: '1px solid #121212', borderLeft: idx != 0 ? "none" : "1px solid #121212", padding: '6px 9px' }}></div>
                                            })
                                        }
                                        </div>
                                    </div>


                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>1.2</div>
                                        <div className='align-items-center' style={{ width: '28%', padding: ' 5px 10px' }}>Name of the Company  </div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '65%', padding: ' 5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                    </div>




                                    <div className='d-flex  mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>1.3</div>
                                        <div className='align-items-center' style={{ width: '20.20%', paddingLeft: '20px' }}>Filing Fee payment Details*</div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '5%' }}>1.3.1</div>
                                        <div className='align-items-center' style={{ width: '13.20%', paddingLeft: '20px' }}>Challan No.</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '17.20%', paddingLeft: '20px', minHeight: '34px' }}></ScrollWrapper>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '5%' }}>1.3.2</div>
                                        <div className='align-items-center' style={{ width: '13.20%', paddingLeft: '20px' }}>Amount</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '19.20%', paddingLeft: '20px', minHeight: '34px' }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex  mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', }}>
                                            <i>* fee for increase in authorized capital plus filing fee of this form. Fee for increase in authorized capital can be calculated through <strong>authorized share capital fee calculator</strong> available on SECP website (www.secp.gov.pk)</i>
                                        </div>
                                    </div>
                                    <div className='d-flex  mt-2'>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '69%', borderBottom: "none", paddingLeft: '20px', minHeight: '34px', fontWeight: "bold" }}>1.4 Notice of —</ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', borderLeft: "none", borderBottom: "none", width: '21%', paddingLeft: '20px', minHeight: '34px' }}>Please tick the relevant box</ScrollWrapper>
                                    </div>
                                    <div className='d-flex'>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '21%', paddingLeft: '20px', borderBottom: "none", minHeight: '34px', fontWeight: "bold" }}>Part II</ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '34.5%', borderLeft: "none", borderBottom: "none", paddingLeft: '20px', minHeight: '34px' }}>increase in authorized capital</ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '34.5%', borderLeft: "none", borderBottom: "none", paddingLeft: '20px', minHeight: '34px' }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex'>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '21%', paddingLeft: '20px', borderBottom: "none", minHeight: '34px', fontWeight: "bold" }}>Part III </ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '34.5%', borderLeft: "none", borderBottom: "none", paddingLeft: '20px', minHeight: '34px' }}>consolidation, division or sub-division of shares</ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '34.5%', borderLeft: "none", borderBottom: "none", paddingLeft: '20px', minHeight: '34px' }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex'>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '21%', paddingLeft: '20px', minHeight: '34px', fontWeight: "bold" }}>Part IV</ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '34.5%', borderLeft: "none", paddingLeft: '20px', minHeight: '34px' }}>cancellation of shares other than as provided in Section 89 of the Act</ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '34.5%', borderLeft: "none", paddingLeft: '20px', minHeight: '34px' }}></ScrollWrapper>
                                    </div>

                                    <PartsWrapper className='mt-4 mb-3'>
                                        Part - II
                                    </PartsWrapper>

                                    <div className='row mt-4'>
                                        <div className='col-md-12'>
                                            <HeadingWrapper className='d-flex justify-content-center'>
                                                INCREASE IN AUTHORIZED SHARE CAPITAL
                                            </HeadingWrapper>

                                        </div>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>2.1</div>
                                        <div className='align-items-center' style={{ width: '28%', padding: ' 5px 10px' }}> Date of Passing of Special Resolution for</div>
                                    </div>
                                    <div className='d-flex '>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}></div>
                                        <div className='align-items-center' style={{ width: '55%', padding: ' 5px 10px' }}>increase in authorized share capital:</div>
                                        <div className='align-items-center text-center' style={{ width: '8%', padding: ' 5px 10px' }}> Day</div>
                                        <div className='align-items-center text-center' style={{ width: '8%', padding: ' 5px 10px' }}> Month</div>
                                        <div className='align-items-center text-center' style={{ width: '16%', padding: ' 5px 10px' }}>Year </div>
                                        <div></div>
                                    </div>
                                    <div className='d-flex '>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}></div>
                                        <div className='align-items-center text-center' style={{ width: '55%', padding: ' 5px 10px', fontWeight: "bold" }}>OR</div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ border: '1px solid #121212', width: '4%', padding: ' 5px 10px' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ border: '1px solid #121212', width: '4%', padding: ' 5px 10px', borderRight: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', border: '1px solid #121212', }}> </div>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>2.2</div>
                                        <div className='align-items-center' style={{ width: '28%', padding: ' 5px 10px' }}> Share capital increased in pursuance of an</div>
                                    </div>
                                    <div className='d-flex'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}></div>
                                        <div className='align-items-center' style={{ width: '55%', padding: ' 5px 10px' }}>obligation of the company under an agreement on</div>
                                        <div className='align-items-center text-center' style={{ width: '8%', padding: ' 5px 10px' }}> Day</div>
                                        <div className='align-items-center text-center' style={{ width: '8%', padding: ' 5px 10px' }}> Month</div>
                                        <div className='align-items-center text-center' style={{ width: '16%', padding: ' 5px 10px' }}>Year </div>
                                        <div></div>
                                    </div>
                                    <div className='d-flex '>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}></div>
                                        <div className='align-items-center text-center' style={{ width: '55%', padding: ' 5px 10px', fontWeight: "bold" }}></div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ border: '1px solid #121212', width: '4%', padding: ' 5px 10px' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ border: '1px solid #121212', width: '4%', padding: ' 5px 10px', borderRight: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', border: '1px solid #121212', }}> </div>
                                    </div>
                                    <div className='d-flex '>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}></div>
                                        <div className='align-items-center text-center' style={{ width: '55%', padding: ' 5px 10px', fontWeight: "bold" }}>(date of agreement with party to the agreement)</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '32%', padding: ' 5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>2.3</div>
                                        <div className='align-items-center' style={{ padding: ' 5px 10px', fontWeight: "bold" }}>Increase in authorized share capital</div>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '25%', }}></div>
                                        <div className='align-items-center' style={{ width: '21.66%', padding: ' 5px 10px', }}>Amount (Rs)</div>
                                        <div className='align-items-center' style={{ width: '21.66%', padding: ' 5px 10px', }}>Divided into (no of shares)</div>
                                        <div className='align-items-center' style={{ width: '21.66%', padding: ' 5px 10px', }}>of Rs. per Share</div>
                                    </div>
                                    <div className='d-flex'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>2.3.1</div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '25%', }}> Existing authorized share capital</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '21.66%', minHeight: "41px", padding: ' 5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', borderLeft: "none", minHeight: "41px", width: '21.66%', padding: ' 5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', borderLeft: "none", minHeight: "41px", width: '21.66%', padding: ' 5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>2.3.2</div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '25%', }}>Addition</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '21.66%', minHeight: "41px", padding: ' 5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', borderLeft: "none", minHeight: "41px", width: '21.66%', padding: ' 5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', borderLeft: "none", minHeight: "41px", width: '21.66%', padding: ' 5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>2.3.3</div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '25%', }}> New Authorized share capital</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '21.66%', minHeight: "41px", padding: ' 5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', borderLeft: "none", minHeight: "41px", width: '21.66%', padding: ' 5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', borderLeft: "none", minHeight: "41px", width: '21.66%', padding: ' 5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                    </div>

                                    <PartsWrapper className='mt-4 mb-3'>
                                        Part - III
                                    </PartsWrapper>

                                    <div className='row mt-4'>
                                        <div className='col-md-12'>
                                            <HeadingWrapper className='d-flex justify-content-center'>
                                                CONSOLIDATION, DIVISION OR SUB-DIVISION OF SHARES
                                            </HeadingWrapper>
                                        </div>
                                    </div>

                                    <div className='d-flex '>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>3.1</div>
                                        <div className='align-items-center' style={{ width: '55%', padding: ' 5px 10px' }}> Date of Passing of Special Resolution</div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ border: '1px solid #121212', width: '4%', padding: ' 5px 10px' }}> </div>
                                        <div className='align-items-center' style={{ paddingLeft: '8px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ border: '1px solid #121212', width: '4%', padding: ' 5px 10px', borderRight: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ paddingLeft: '8px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', border: '1px solid #121212', }}> </div>
                                    </div>

                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>3.2</div>
                                        <div className='align-items-center' style={{ padding: ' 5px 10px' }}> Notice is hereby given, in accordance with section 85 of the Companies Act, 2017 for the purpose of:-</div>
                                    </div>

                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}></div>
                                        <div className='align-items-center' style={{ width: '20%', padding: ' 5px 10px' }}></div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '20%', padding: ' 5px 10px', textAlign: "center" }}><i>Tick the relevant field</i></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>3.2.1</div>
                                        <div className='align-items-center' style={{ width: '20%', padding: ' 5px 10px' }}> Consolidation and division of shares</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '20%', padding: ' 5px 10px', textAlign: "center" }}><i>Tick the relevant field</i></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>3.2.2</div>
                                        <div className='align-items-center' style={{ width: '20%', padding: ' 5px 10px' }}> Sub-division of shares</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '20%', padding: ' 5px 10px', textAlign: "center" }}><i>Tick the relevant field</i></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}></div>
                                        <div className='align-items-center' style={{ width: '10%', padding: ' 5px 10px' }}></div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '20%', padding: ' 5px 10px', textAlign: "center" }}>Number of shares</ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '20%', marginLeft: "1.2rem", padding: ' 5px 10px', textAlign: "center" }}>Rs. Per share</ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '20%', marginLeft: "1.2rem", padding: ' 5px 10px', textAlign: "center" }}>Amount (Rs)</ScrollWrapper>
                                    </div>
                                    <div className='d-flex'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>3.2.4</div>
                                        <div className='align-items-center' style={{ width: '10%', padding: '0px 10px 10px 10px' }}> Existing share capital</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '20%', padding: ' 5px 10px', textAlign: "center" }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '20%', marginLeft: "1.2rem", padding: ' 5px 10px', textAlign: "center" }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '20%', marginLeft: "1.2rem", padding: ' 5px 10px', textAlign: "center" }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>3.2.5</div>
                                        <div className='align-items-center' style={{ width: '10%', padding: '0px 10px 10px 10px' }}>Consolidated/divided</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '20%', padding: ' 5px 10px', textAlign: "center" }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '20%', marginLeft: "1.2rem", padding: ' 5px 10px', textAlign: "center" }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '20%', marginLeft: "1.2rem", padding: ' 5px 10px', textAlign: "center" }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>3.2.6</div>
                                        <div className='align-items-center' style={{ width: '10%', padding: '0px 10px 10px 10px' }}> Sub-division of shares</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '20%', padding: ' 5px 10px', textAlign: "center" }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '20%', marginLeft: "1.2rem", padding: ' 5px 10px', textAlign: "center" }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '20%', marginLeft: "1.2rem", padding: ' 5px 10px', textAlign: "center" }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>3.2.7</div>
                                        <div className='align-items-center' style={{ width: '10%', padding: '0px 10px 10px 10px' }}>New share capital</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '20%', padding: ' 5px 10px', textAlign: "center" }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '20%', marginLeft: "1.2rem", padding: ' 5px 10px', textAlign: "center" }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '20%', marginLeft: "1.2rem", padding: ' 5px 10px', textAlign: "center" }}></ScrollWrapper>
                                    </div>


                                    <PartsWrapper className='mt-4 mb-3'>
                                        Part - IV
                                    </PartsWrapper>

                                    <div className='row mt-4 mb-2'>
                                        <div className='col-md-12'>
                                            <HeadingWrapper className='d-flex justify-content-center'>
                                                CANCELLATION OF SHARES
                                            </HeadingWrapper>
                                        </div>
                                    </div>

                                    <div className='d-flex '>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>4.1</div>
                                        <div className='align-items-center' style={{ width: '55%', padding: ' 5px 10px' }}> Date of Passing of Special Resolution</div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ border: '1px solid #121212', width: '4%', padding: ' 5px 10px' }}> </div>
                                        <div className='align-items-center' style={{ paddingLeft: '8px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ border: '1px solid #121212', width: '4%', padding: ' 5px 10px', borderRight: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ paddingLeft: '8px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', border: '1px solid #121212', }}> </div>
                                    </div>

                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>4.2</div>
                                        <div className='align-items-center' style={{ padding: ' 5px 10px' }}> Notice is hereby given, in accordance with section 85 of the Companies Act, 2017 for the purpose of cancellation of shares:-</div>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}></div>
                                        <div className='align-items-center' style={{ width: '10%', padding: ' 5px 10px' }}></div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '20%', padding: ' 5px 10px', textAlign: "center" }}>Number of shares</ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '20%', marginLeft: "1.2rem", padding: ' 5px 10px', textAlign: "center" }}>Rs. Per share</ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '20%', marginLeft: "1.2rem", padding: ' 5px 10px', textAlign: "center" }}>Amount (Rs)</ScrollWrapper>
                                    </div>
                                    <div className='d-flex'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>4.2.1</div>
                                        <div className='align-items-center' style={{ width: '10%', padding: ' 5px 10px' }}> Existing share capital</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '20%', padding: ' 5px 10px', textAlign: "center" }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '20%', marginLeft: "1.2rem", padding: ' 5px 10px', textAlign: "center" }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '20%', marginLeft: "1.2rem", padding: ' 5px 10px', textAlign: "center" }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>4.2.2</div>
                                        <div className='align-items-center' style={{ width: '10%', padding: ' 5px 10px' }}>Cancelled shares</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '20%', padding: ' 5px 10px', textAlign: "center" }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '20%', marginLeft: "1.2rem", padding: ' 5px 10px', textAlign: "center" }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '20%', marginLeft: "1.2rem", padding: ' 5px 10px', textAlign: "center" }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>4.2.3</div>
                                        <div className='align-items-center' style={{ width: '10%', padding: ' 5px 10px' }}>New share capital</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '20%', padding: ' 5px 10px', textAlign: "center" }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '20%', marginLeft: "1.2rem", padding: ' 5px 10px', textAlign: "center" }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '20%', marginLeft: "1.2rem", padding: ' 5px 10px', textAlign: "center" }}></ScrollWrapper>
                                    </div>

                                    <PartsWrapper className='mt-4 mb-3'>
                                        Part - II
                                    </PartsWrapper>

                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>5.1</div>
                                        <div className='align-items-center' style={{ padding: ' 5px 10px' }}>Declaration</div>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}></div>
                                        <div className='align-items-center' style={{ padding: ' 5px 10px' }}>I do hereby solemnly and sincerely declare that the information provided in the form is:</div>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '2%' }}></div>
                                        <div className='align-items-center d-flex' style={{ padding: ' 5px 10px', gap: "12px" }}><div>
                                            (i)</div>
                                            <div>true and correct to the best of my knowledge, in consonance with the record as maintained by the Company and nothing has been concealed; and</div>
                                        </div>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '2%' }}></div>
                                        <div className='align-items-center d-flex' style={{ padding: ' 5px 10px', gap: "12px" }}><div>
                                            (ii)</div>
                                            <div>hereby reported after complying with and fulfilling all requirements under the relevant provisions of law, rules, regulations, directives, circulars and notifications whichever is applicable. </div>
                                        </div>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '2%' }}></div>
                                        <div className='align-items-center d-flex' style={{ padding: ' 5px 10px', gap: "12px" }}><div>
                                            (iii)</div>
                                            <div>The special resolution has been filed with the registrar on the prescribed form</div>
                                        </div>
                                    </div>

                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>5.2</div>
                                        <div className='align-items-center' style={{ width: '28%', padding: ' 5px 10px' }}> Name of Authorized Officer with designation/ Authorized Intermediary, if any</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '32.5%', padding: ' 5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', borderLeft: "none", width: '32.5%', padding: ' 5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>5.3</div>
                                        <div className='align-items-center' style={{ width: '28%', padding: ' 5px 10px' }}>Signatures </div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '65%', padding: ' 5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>5.4</div>
                                        <div className='align-items-center' style={{ width: '40%', padding: ' 5px 10px' }}>Registration No of Authorized Intermediary, if applicable</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '53%', padding: ' 5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}></div>
                                        <div className='align-items-center' style={{ width: '55%', padding: ' 5px 10px' }}> </div>
                                        <div className='align-items-center text-center' style={{ width: '8%', padding: ' 5px 10px' }}> Day</div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center text-center' style={{ width: '8%', padding: ' 5px 10px' }}> Month</div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center text-center' style={{ width: '16%', padding: ' 5px 10px' }}>Year </div>
                                        <div></div>
                                    </div>
                                    <div className='d-flex '>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>5.5</div>
                                        <div className='align-items-center' style={{ width: '55%', padding: ' 5px 10px' }}>Date</div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ border: '1px solid #121212', width: '4%', padding: ' 5px 10px' }}> </div>
                                        <div className='align-items-center' style={{ paddingLeft: '8px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ border: '1px solid #121212', width: '4%', padding: ' 5px 10px', borderRight: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ paddingLeft: '8px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', border: '1px solid #121212', }}> </div>
                                    </div>
                                    <div className='d-flex mt-2' style={{ fontWeight: "bold" }}>
                                        Enclosure
                                    </div>
                                    <div className='d-flex'>
                                        1. Amended Memorandum and Articles of association.
                                    </div>
                                    <div className='d-flex'>
                                        2. Original challan or other evidence of payment of fee specified in Seventh Schedule of the Act (not applicable in case of online filing)
                                    </div>

                                </div>
                            </div>
                        </div> */}
                        <div style={{ fontFamily: `${fontFamilyForStaticData}`, fontSize: `${fontSizeForStaticData}`, fontWeight: 500 }}>
                            
                        <div className='d-flex  mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>1.1</div>
                                        <div className='align-items-center' style={{ width: '20.20%', padding: '0px 10px 10px 10px' }}>Fee payment Details*</div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '5%' }}>1.2</div>
                                        <div className='align-items-center' style={{ width: '13.20%', paddingLeft: '20px' }}>Challan No.</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '17.20%', paddingLeft: '20px', minHeight: '34px' }}></ScrollWrapper>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '5%' }}>1.3</div>
                                        <div className='align-items-center' style={{ width: '13.20%', paddingLeft: '20px' }}>Amount(Rs.)</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '23.20%', paddingLeft: '20px', minHeight: '34px' }}></ScrollWrapper>
                                    </div>
                                    <PartsWrapper className='mt-4 mb-3'>
                                        PART II
                                    </PartsWrapper>
                                    <div className='row mt-4'>
                                        <div className='col-md-12'>
                                            <HeadingWrapper className='text-center d-flex justify-content-center'>
                                                (This part is to be filled for the incorporation/ change of name of a local company)
                                            </HeadingWrapper>
                                        </div>
                                    </div>
                                    <Wrapper className="table-responsive mt-0" >
                                        <table
                                            className="table table-bordered"
                                            style={{ fontFamily: "Montserrat rev=1 !important" }}
                                        >
                                            <thead>
                                                <th style={{ verticalAlign: 'sub', textAlign: "center", border: '1px solid #121212', textAlign: "center" }}>
                                                    <div>2.1</div><div>Application for:- </div>
                                                </th>
                                                <th style={{ verticalAlign: 'sub', textAlign: "center", border: '1px solid #121212', textAlign: "center" }}>
                                                    Please tick the relevant box
                                                </th>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}>Reservation of name for incorporation of a new company</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}>Reservation of name for change of name of existing company</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </Wrapper>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>2.2</div>
                                        <div className='align-items-center' style={{ width: '29%', padding: '0px 10px 10px 10px' }}> In case of change of name, please mention the complete name of existing company</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '68%', padding: '0px 10px 10px 10px', }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>2.3</div>
                                        <div className='align-items-center' style={{ width: '29%', padding: '0px 10px 10px 10px' }}> Propose three options for name reservation in the order of priority.</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '68%', padding: '0px 10px 10px 10px', }}>Option 1</ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ width: '29%', padding: '0px 10px 10px 10px' }}><i>(mandatory in case of combined application. Fee will be charged for one name only instead of three names.)</i></div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '68%', padding: '0px 10px 10px 10px', }}>Option 2</ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ width: '29%', padding: '0px 10px 10px 10px' }}></div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '68%', padding: '0px 10px 10px 10px', }}>Option 3</ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ padding: '0px 10px 10px 10px' }}><i>(Please enter the name without kind of company e.g. (Private) Limited, Limited etc.).</i></div>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ padding: '0px 10px 10px 10px' }}><i>(As required under proviso (i) to sub-section (1) of section 26 of the Act, the proposed name shall always commensurate with the principal line of business of the company as mentioned in memorandum of association)</i></div>
                                    </div>
                                    <div className='d-flex mt-0'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>2.4</div>
                                        <div className='align-items-center' style={{ width: '29%', padding: '0px 10px 10px 10px' }}> Kind of proposed/existing company <i>(Please tick the relevant box)</i></div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '68%', padding: '0px 10px 10px 10px', }}>Option 3</ScrollWrapper>
                                    </div>
                                    <Wrapper className="table-responsive mt-2" >
                                        <table
                                            className="table table-bordered"
                                            style={{ fontFamily: "Montserrat rev=1 !important" }}
                                        >
                                            <tbody>
                                                <tr>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}>a.</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}>Private Limited Company</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}>b.</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}>Single Member Company</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}>c.</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}>Public Limited Company</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}>d.</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}>Association Not for-profit licensed under section 42 of the Companies Act, 2017</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}>e.</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}> Trade Organization Act, 2013 must be mentioned separately</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}>f.</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}>Other (Please specify)</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </Wrapper>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>2.5</div>
                                        <div className='align-items-center' style={{ width: '29%', padding: '0px 10px 10px 10px' }}>Principal line of business for proposed/existing company (Brief object as per clause 3 (i) of the Memorandum may be mentioned)</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '68%', padding: '0px 10px 10px 10px', }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-0'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>2.6</div>
                                        <div className='align-items-center' style={{ width: '29%', padding: '0px 10px 10px 10px' }}> Meaning / Significance of proposed name (describe relevancy of proposed name with company’s principal line of business)</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '68%', padding: '0px 10px 10px 10px', }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-0'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>2.7</div>
                                        <div className='align-items-center' style={{ width: '29%', padding: '0px 10px 10px 10px' }}> If any company or entity exists with identical or similar name, please mention your relationship with such company or entity along with board resolution/ NOC showing no objection on registration of a company with similar name.</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '68%', padding: '0px 10px 10px 10px', }}></ScrollWrapper>
                                    </div>

                                    <PartsWrapper className='mt-4 mb-3'>
                                        PART III
                                    </PartsWrapper>

                                    <div className='row mt-4'>
                                        <div className='col-md-12'>
                                            <HeadingWrapper className='text-center d-flex justify-content-center'>
                                            (This part is to be filled for the registration/change of name of a foreign company)
                                            </HeadingWrapper>
                                        </div>
                                    </div>
                                    <Wrapper className="table-responsive mt-0" >
                                        <table
                                            className="table table-bordered"
                                            style={{ fontFamily: "Montserrat rev=1 !important" }}
                                        >
                                           
                                            <tbody>
                                                <tr>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%',fontWeight:"bold" }}>3.1 Application for:-</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}>Please tick the relevant box</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}>Reservation of name for registration of foreign company</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}>Reservation of name for change of name of existing foreign company</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}></td>
                                                </tr>
                                                
                                            </tbody>
                                        </table>
                                    </Wrapper>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>3.2</div>
                                        <div className='align-items-center' style={{ width: '29%', padding: '0px 10px 10px 10px' }}> In case of change of name, please mention the complete name of existing foreign company</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '68%', padding: '0px 10px 10px 10px', }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-0'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>3.3</div>
                                        <div className='align-items-center' style={{ width: '29%', padding: '0px 10px 10px 10px' }}>Incorporated in: <i>(state name of the country of origin)</i></div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '68%', padding: '0px 10px 10px 10px', }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%',  }}></div>
                                        <div className='align-items-center' style={{ width: '51%', padding: ' 5px 10px',}}> </div>
                                        <div className='align-items-center text-center' style={{ width: '10%', padding: ' 5px', }}> dd</div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%', }}></div>
                                        <div className='align-items-center text-center' style={{ width: '10%', padding: ' 5px',  }}> mm</div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%', }}></div>
                                        <div className='align-items-center text-center' style={{  width: '16%', padding: ' 5px 10px' }}>yyyy</div>
                                        <div></div>
                                    </div>
                                    <div className='d-flex '>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%',  }}>3.4</div>
                                        <div className='align-items-center' style={{ width: '51%', padding: ' 0px 10px 10px 10px',  }}>Date of incorporation: <i>(in the country of origin)</i></div>
                                        <div className='align-items-center' style={{ width: '5%', padding: ' 5px 10px', border: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '5%', padding: ' 5px 10px', border: '1px solid #121212', borderLeft: 'none' }}> </div>
                                        <div className='align-items-center text-center' style={{ paddingLeft: '2px', width: '3%',  }}></div>
                                        <div className='align-items-center' style={{ width: '5%', padding: ' 5px 10px', border: '1px solid #121212',  }}> </div>
                                        <div className='align-items-center' style={{ width: '5%', padding: ' 5px 10px', border: '1px solid #121212', borderLeft: 'none' }}> </div>
                                        <div className='align-items-center text-center' style={{ paddingLeft: '2px', width: '3%',  }}></div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', border: '1px solid #121212', }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', border: '1px solid #121212', borderLeft: 'none' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', border: '1px solid #121212', borderLeft: 'none' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', border: '1px solid #121212', borderLeft: 'none', borderRight: '1px solid #121212' }}> </div>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>3.5</div>
                                        <div className='align-items-center' style={{ width: '29%', padding: '0px 10px 10px 10px' }}> Kind of the company <i>(in the country of origin)</i></div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '68%', padding: '0px 10px 10px 10px', }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>3.6</div>
                                        <div className='align-items-center' style={{ width: '29%', padding: '0px 10px 10px 10px' }}> name of foreign company in the country of origin as proposed to be reserved</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '68%', padding: '0px 10px 10px 10px', }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>3.7</div>
                                        <div className='align-items-center' style={{ width: '29%', padding: '0px 10px 10px 10px' }}> Meaning / Significance of name to be reserved</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '68%', padding: '0px 10px 10px 10px', }}></ScrollWrapper>
                                    </div>
                                    <PartsWrapper className='mt-4 mb-3'>
                                        PART IV
                                    </PartsWrapper>

                                    <div className='row mt-4'>
                                        <div className='col-md-12'>
                                            <HeadingWrapper className='text-center d-flex justify-content-center'>
                                            (This part is applicable for local and foreign companies both)
                                            </HeadingWrapper>
                                        </div>
                                    </div>
                                    <div className='d-flex mt-0'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>4.1</div>
                                        <div className='align-items-center' style={{ width: '29%', padding: '0px 10px 10px 10px' }}>Declaration</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '68%', padding: '0px 10px 10px 10px', }}>I do hereby solemnly and sincerely declare that the information provided in the form is true and correct and nothing is concealed and that the proposed name, if reserved, shall be used only for the purpose of incorporation/ registration/ change of name of the company</ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>4.2</div>
                                        <div className='align-items-center' style={{ width: '29%', padding: '0px 10px 10px 10px' }}> Name of Proposed Subscriber in case of local company / Name of Authorized Officer in Pakistan in case of foreign company with his/her designation /Authorized Intermediary</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '68%', padding: '0px 10px 10px 10px', }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>4.3</div>
                                        <div className='align-items-center' style={{ width: '29%', padding: '0px 10px 10px 10px' }}>NIC/NICOP No. where the applicant is </div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '4%', padding: '0px 10px 10px 10px', }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '4%', padding: '0px 10px 10px 10px', }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '4%', padding: '0px 10px 10px 10px', }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '4%', padding: '0px 10px 10px 10px', }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '4%', padding: '0px 10px 10px 10px', }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '4%', padding: '0px 10px 10px 10px', }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '4%', padding: '0px 10px 10px 10px', }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '4%', padding: '0px 10px 10px 10px', }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '4%', padding: '0px 10px 10px 10px', }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '4%', padding: '0px 10px 10px 10px', }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '4%', padding: '0px 10px 10px 10px', }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '4%', padding: '0px 10px 10px 10px', }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '4%', padding: '0px 10px 10px 10px', }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '4%', padding: '0px 10px 10px 10px', }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '4%', padding: '0px 10px 10px 10px', }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-0'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ padding: '0px 10px 10px 10px' }}>Pakistani <i>/Passport No. in case of foreign national</i></div>
                                    </div>
                                    <div className='d-flex mt-0'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>4.4</div>
                                        <div className='align-items-center' style={{ width: '29%', padding: '0px 10px 10px 10px' }}>Signatures</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '68%', padding: '0px 10px 10px 10px', }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>4.5</div>
                                        <div className='align-items-center' style={{ width: '29%', padding: '0px 10px 10px 10px' }}> Registration No of Authorized Intermediary, if applicable</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '68%', padding: '0px 10px 10px 10px', }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>4.6</div>
                                        <div className='align-items-center' style={{ width: '29%', padding: '0px 10px 10px 10px' }}> Contact details of the applicant, i.e. address, email and cell No. etc.</div>
                                        <Wrapper className="table-responsive mt-0" >
                                        <table
                                            className="table table-bordered"
                                            style={{ fontFamily: "Montserrat rev=1 !important" }}
                                        >
                                            <tbody>
                                                <tr>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%',fontWeight:"bold" }}>Address</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}>Email:</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}>Cell No.</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%', }}></td>
                                                </tr>   
                                            </tbody>
                                        </table>
                                    </Wrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ width: '55%', padding: ' 5px 10px' }}> </div>
                                        <div className='align-items-center text-center' style={{ width: '8%', padding: ' 5px' }}> Day</div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center text-center' style={{ width: '8%', padding: ' 5px' }}> Month</div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center text-center' style={{ width: '16%', padding: ' 5px' }}>Year </div>
                                        <div></div>
                                    </div>
                                    <div className='d-flex '>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>4.7</div>
                                        <div className='align-items-center' style={{ width: '55%', padding: ' 0px 10px 10px 10px' }}>Date</div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ border: '1px solid #121212', width: '4%', padding: ' 5px 10px' }}> </div>
                                        <div className='align-items-center' style={{ paddingLeft: '8px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ border: '1px solid #121212', width: '4%', padding: ' 5px 10px', borderRight: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ paddingLeft: '8px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', border: '1px solid #121212', }}> </div>
                                    </div>

                                    <div className='d-flex my-2' style={{fontWeight:"bold"}}>
                                    Enclosures (for local company):
                                    </div>
                                    <div className='d-flex align-items-baseline'>
                                    <div style={{marginLeft:"5px"}}>1.</div><div style={{marginLeft:'23px'}}>Original NOC/permission/letter of intent of competent authority (if applicable)</div>
                                    </div>
                                    <div className='d-flex'>
                                    <div style={{marginLeft:"5px"}}>2.</div><div style={{marginLeft:'20px'}}>Any other document deemed necessary.</div>
                                    </div>
                                    <div className='d-flex'>
                                    <div style={{marginLeft:"5px"}}>3.</div><div style={{marginLeft:'20px'}}>Original challan or other evidence of payment of fee specified in Seventh Schedule of the Act (not applicable in case of online filing)</div>
                                    </div>
                                    <div className='d-flex my-2' style={{fontWeight:"bold"}}>
                                    Enclosures (for foreign company):
                                    </div>
                                    <div className='d-flex align-items-baseline'>
                                    <div style={{marginLeft:"5px"}}>1.</div><div style={{marginLeft:'23px'}}>Copy of certificate of incorporation or any other document as an evidence of the registration of Foreign Company.</div>
                                    </div>
                                    <div className='d-flex'>
                                    <div style={{marginLeft:"5px"}}>2.</div><div style={{marginLeft:'20px'}}>Any other document deemed necessary. </div>
                                    </div>
                                    <div className='d-flex'>
                                    <div style={{marginLeft:"5px"}}>3.</div><div style={{marginLeft:'20px'}}>Original challan or other evidence of payment of fee specified in Seventh Schedule of the Act (not applicable in case of online filing)</div>
                                    </div>
                        </div>
                    </PDFExport>}
            </div>
            <button disabled={loading} className='btn btn-danger' onClick={(e) => {
                // if(viewPdf)
                //   SetViewPDf(true)
                if (pdfExportComponent.current) {
                    pdfExportComponent.current.save();
                }
            }}>Download PDF</button>
        </div>
    )
}
const PartsWrapper = styled.div`
color: #121212;
text-decoration: underline;
justify-content: center;
display: flex;
margin-right: 15px;
font-size: 17px;
font-weight: bold;
`;
const ScrollWrapper = styled.div`
max-height: 50px;
overflow-y: scroll;
overflow-x: hidden;
::-webkit-scrollbar{
    height: 5px;
    width: 3px;
  }
  
  ::-webkit-scrollbar-track{
    background: #F9F9FB;
  }
  ::-webkit-scrollbar-thumb{
    background: #4E515680;
    border-radius: 5px;
  
  }
`;
const HeadingWrapper = styled.div`
font-size: 17px;
font-weight: bold;
`;
const FormName = styled.div`
color: #121212;
text-decoration: underline;
justify-content: end;
display: flex;
margin-right: 15px;
font-size: 17px;
font-weight: bold;
`;
const Wrapper = styled.div`
table.table-bordered{
    border: 1px solid #121212
     border-color: #121212; 
    // border:1px solid blue;
    // margin-top:20px;
  }
// table.table-bordered > thead > tr > th{
//     // border:1px solid blue;
//     border-color: #121212; 
// }
// table.table-bordered > tbody > tr > td{
//     // border:1px solid blue;
//     borderC-color: #121212; 
// }
 tr td {
    // border:1px solid blue;.
    border-color: #121212; 
}

`;
const WrapperDashed = styled.div`

  td {
    
    // border: 1px dashed #dddcdc;
}

`;