import React, { useState, useEffect } from 'react'
import { PDFExport } from "@progress/kendo-react-pdf";
import PageTemplate from "./page-template";
import {
    getCompanies,
} from "../../../store/services/company.service";
import styled from 'styled-components';
import { TiTick } from "react-icons/ti";
import { numberWithCommas } from 'utilities/utilityFunctions';
export const StatutoryFormA = ({ data, formTemplate = false }) => {
    const pdfExportComponent = React.useRef(null);
    const [CompanyData, setCompanyData] = useState(null)
    const [loading, setLoading] = useState(false);
    const [fontFamilyForStaticData, setFontFamilyForStaticData] = useState('Montserrat, sans-serif')
    const [fontSizeForStaticData, setFontSizeForStaticData] = useState('14px')
    const [fontfamilyforDynimicData, setFontFamilyForDynmicData] = useState('Segoe Print')
    const [fontColor, setFontColor] = useState('')
    const baseEmail = sessionStorage.getItem("email") || "";
    const [governance, setGovernance] = useState({})
    const getAllCompanies = async () => {
        setLoading(true)
        try {
            const response = await getCompanies(baseEmail);
            if (response.status === 200) {
                response.data.data = response.data.data.filter((item) => item?.active === 'Y')
                const parents = response.data.data;
                const company = parents?.find(ite => ite?.code == data?.company_code)
                let Allperson = company.governance && company.governance != "" ? JSON.parse(company.governance) : []
                let sec = Allperson.find(x => x.role == "Company Secretary" && x?.active?.toLowerCase() != 'n')
                let ceo = Allperson.find(x => x.role == "CEO" && x?.active?.toLowerCase() != 'n')


                if (sec) {
                    setGovernance(sec)
                } else if (ceo) {
                    setGovernance(ceo)
                }
                setCompanyData(company)
                setTimeout(() => {
                    setLoading(false)
                }, 1000);
            }
        } catch (error) {
            setLoading(false)
        }
    };
    useEffect(() => {
        getAllCompanies()
    }, [])
    return (
        <div>
            <div
                style={{ fontFamily: "sans-serif " }}
            >

                {formTemplate ?
                    <PDFExport
                        paperSize="A4"
                        margin="1cm"
                        scale={0.6}
                        fileName={`Form A`}
                        pageTemplate={PageTemplate}
                        ref={pdfExportComponent}
                    >
                        <div style={{ fontFamily: `${fontFamilyForStaticData}`, fontSize: `${fontSizeForStaticData}`, fontWeight: 500 }}>
                            <FormName>
                                From-A
                            </FormName>
                            <div className='row'>
                                <div className='col-md-12' >
                                    <HeadingWrapper className='d-flex justify-content-center'>
                                        THE COMPANIES ACT, 2017
                                    </HeadingWrapper>

                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <HeadingWrapper className='d-flex justify-content-center'>
                                        THE COMPANIES REGULATIONS, 2024
                                    </HeadingWrapper>

                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <div style={{ fontSize: '15px', }} className='d-flex justify-content-center'>
                                        [Section 130(1), 130(2)1 425(5) read with Regulations 62 & 30]
                                    </div>

                                </div>
                            </div>
                            <div className='row mt-2'>
                                <div className='col-md-12' >
                                    <HeadingWrapper style={{ textDecoration: "underline" }} className='d-flex justify-content-center'>
                                    ANNUAL RETURN OF A COMPANY

                                    </HeadingWrapper>

                                </div>
                            </div>
                            <PartsWrapper className='mt-4 mb-3'>
                                PART-I
                            </PartsWrapper>
                            <div style={{ fontWeight: "bold" }} className='align-items-center text-center mt-0'>(To be filled by All Companies)</div>


                            <div className='row'>
                                <div className='col-md-12' style={{ padding: '25px 50px', wordWrap: 'break-word' }}>
                                    <div> <i>(Please complete in bold capital letters)</i></div>


                                    <div className='d-flex' >
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>1.1</div>
                                        <div className='align-items-center' style={{ width: '28%', padding: '0px 10px 10px 10px' }}>CUIN (Registration Number)</div>
                                        <div className='align-items-center  d-flex'  >{
                                            '1234 552'.split("")?.map((item, idx) => {
                                                if (item == ' ') return
                                                return <div style={{ minWidth: '30px', minHeight: '34px', border: '1px solid #121212', borderLeft: idx != 0 ? "none" : "1px solid #121212", padding: '6px 9px' }}></div>
                                            })
                                        }
                                        </div>
                                    </div>


                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>1.2</div>
                                        <div className='align-items-center' style={{ width: '28%', padding: '0px 10px 10px 10px' }}>Name of the Company</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '65%', padding: ' 5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                    </div>






                                    <div className='d-flex  mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>1.3</div>
                                        <div className='align-items-center' style={{ width: '20.20%', padding: '0px 20px 10px 10px' }}>Fee Payment Details</div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '5%' }}>1.3.1</div>
                                        <div className='align-items-center' style={{ width: '13.20%', paddingLeft: '20px' }}>Challan No</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '17.20%', paddingLeft: '20px', minHeight: '34px' }}></ScrollWrapper>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '5%' }}>1.3.3</div>
                                        <div className='align-items-center' style={{ width: '13.20%', paddingLeft: '20px' }}>Amount</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '19.20%', paddingLeft: '20px', minHeight: '34px' }}></ScrollWrapper>
                                    </div>

                                    <div className='d-flex justify-content-center  mt-2'>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '75%', borderBottom: "none", paddingLeft: '20px', minHeight: '70px', fontWeight: "bold" }}>1.4 Notice of —</ScrollWrapper>
                                        <ScrollWrapper className='align-items-center text-center' style={{ border: '1px solid #121212', borderLeft: "none", borderBottom: "none", width: '15%', padding: '10px', minHeight: '70px' }}>Please tick the relevant box</ScrollWrapper>
                                    </div>
                                    <div className='d-flex justify-content-center'>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '21%', paddingLeft: '20px', borderBottom: "none", minHeight: '34px', fontWeight: "bold" }}>Part II</ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '54%', borderLeft: "none", borderBottom: "none", paddingLeft: '20px', minHeight: '34px' }}> Annual Return of a company other than inactive company</ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '15%', borderLeft: "none", borderBottom: "none", paddingLeft: '20px', minHeight: '34px' }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex justify-content-center'>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '21%', paddingLeft: '20px', minHeight: '34px', fontWeight: "bold" }}>Part III </ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '54%', borderLeft: "none",  paddingLeft: '20px', minHeight: '34px' }}> Annual Return of Inactive Company</ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '15%', borderLeft: "none",  paddingLeft: '20px', minHeight: '34px' }}></ScrollWrapper>
                                    </div>
                                    


                                    <PartsWrapper className='mt-4 mb-3'>
                                        PART-II
                                    </PartsWrapper>
                                    <div style={{ fontWeight: "bold" }} className='align-items-center text-center mt-0'>(To be filled by All Companies)</div>


                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}></div>
                                        <div className='align-items-center' style={{ width: '55%', padding: ' 5px 10px' }}> </div>
                                        <div className='align-items-center text-center' style={{ width: '8%', padding: ' 5px 10px' }}>dd</div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center text-center' style={{ width: '8%', padding: ' 5px 10px' }}>mm</div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center text-center' style={{ width: '16%', padding: ' 5px 10px' }}>yyyy</div>
                                        <div></div>
                                    </div>
                                    <div className='d-flex '>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>2.1</div>
                                        <div className='align-items-center' style={{ width: '55%', padding: '0px 10px 10px 10px' }}>Annual General Meeting held on</div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ border: '1px solid #121212', width: '4%', padding: ' 5px 10px' }}> </div>
                                        <div className='align-items-center' style={{ paddingLeft: '8px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ border: '1px solid #121212', width: '4%', padding: ' 5px 10px', borderRight: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ paddingLeft: '8px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', border: '1px solid #121212', }}> </div>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>2.2</div>
                                        <div className='align-items-center' style={{ width: '55%', padding: '0px 10px 10px 10px' }}> Form-A made up to (applicable in case no AGM was held/concluded during the year)</div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ border: '1px solid #121212', width: '4%', padding: ' 5px 10px' }}> </div>
                                        <div className='align-items-center' style={{ paddingLeft: '8px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ border: '1px solid #121212', width: '4%', padding: ' 5px 10px', borderRight: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ paddingLeft: '8px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', border: '1px solid #121212', }}> </div>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>2.3</div>
                                        <div className='align-items-center' style={{ width: '28%', padding: '0px 10px 10px 10px' }}> Registered office address</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '65%', padding: ' 5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>2.4</div>
                                        <div className='align-items-center' style={{ width: '28%', padding: '0px 10px 10px 10px' }}>Email Address</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '65%', padding: ' 5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>2.5</div>
                                        <div className='align-items-center' style={{ width: '28%', padding: '0px 10px 10px 10px' }}>Office Tel. No.</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '65%', padding: ' 5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-0'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>2.6</div>
                                        <div className='align-items-center' style={{ width: '58%', padding: '0px 10px 10px 10px' }}>Mobile No. (Preferably WhatsApp enabled number) of authorized officer: (Chief Executive/ Director/ Company Secretary/ Chief Financial Officer)</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '35%', padding: ' 5px 10px', borderTop:"none" }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '6.6%' }}>2.7</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '94%', padding: ' 5px 10px', fontWeight: 'bold' }}> Authorized Share Capital (If applicable)</ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-0'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}></div>
                                        <Wrapper className="table-responsive mt-0" >
                                            <table
                                                className="table table-bordered"
                                                style={{ fontSize: "12px", fontFamily: "Montserrat rev=1 !important" }}
                                            >
                                                <thead>
                                                    <th style={{ verticalAlign: 'sub', border: '1px solid #121212' }}>
                                                        Classes and kinds of Shares
                                                    </th>
                                                    <th style={{ verticalAlign: 'sub', border: '1px solid #121212' }}>
                                                        No. of Shares
                                                    </th>
                                                    <th style={{ verticalAlign: 'sub', border: '1px solid #121212' }}>
                                                        Amount
                                                    </th>
                                                    <th style={{ verticalAlign: 'sub', border: '1px solid #121212' }}>
                                                        Face Value
                                                    </th>
                                                </thead>

                                                <tbody>
                                                    <tr >
                                                        <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}>Ordinary Shares</td>
                                                        <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                        <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                        <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>

                                                    </tr>
                                                    <tr >
                                                        <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                        <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                        <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                        <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>

                                                    </tr>

                                                </tbody>
                                            </table>
                                        </Wrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '6.6%' }}>2.8</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '94%', padding: ' 5px 10px', fontWeight: 'bold' }}>Paid up Share Capital (If applicable)</ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-0'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}></div>
                                        <Wrapper className="table-responsive mt-0" >
                                            <table
                                                className="table table-bordered"
                                                style={{ fontSize: "12px", fontFamily: "Montserrat rev=1 !important" }}
                                            >
                                                <thead>
                                                    <th style={{ verticalAlign: 'sub', border: '1px solid #121212' }}>
                                                        Classes and kinds of Shares
                                                    </th>
                                                    <th style={{ verticalAlign: 'sub', border: '1px solid #121212' }}>
                                                        No. of Shares
                                                    </th>
                                                    <th style={{ verticalAlign: 'sub', border: '1px solid #121212' }}>
                                                        Amount
                                                    </th>
                                                    <th style={{ verticalAlign: 'sub', border: '1px solid #121212' }}>
                                                        Face Value
                                                    </th>
                                                </thead>

                                                <tbody>
                                                    <tr >
                                                        <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}>Ordinary Shares</td>
                                                        <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                        <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                        <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>

                                                    </tr>
                                                    <tr >
                                                        <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                        <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                        <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                        <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>

                                                    </tr>

                                                </tbody>
                                            </table>
                                        </Wrapper>
                                    </div>

                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>2.9</div>
                                        <div className='align-items-center' style={{ padding: '0px 10px 10px 10px', fontWeight: "bold" }}>Particulars of Officer(s) including Share Registrar</div>
                                    </div>
                                    <Wrapper className="table-responsive mt-2" >
                                        <table
                                            className="table table-bordered"
                                            style={{ fontSize: "12px", fontFamily: "Montserrat rev=1 !important" }}
                                        >
                                            <thead>
                                                <th style={{ verticalAlign: 'sub', border: '1px solid #121212' }}>
                                                    S. No
                                                </th>
                                                <th style={{ verticalAlign: 'sub', border: '1px solid #121212' }}>
                                                    Name
                                                </th>
                                                <th style={{ verticalAlign: 'sub', border: '1px solid #121212' }}>
                                                    Designation
                                                </th>
                                                <th style={{ verticalAlign: 'sub', border: '1px solid #121212' }}>
                                                    CNIC No.
                                                </th>
                                                <th style={{ verticalAlign: 'sub', border: '1px solid #121212' }}>
                                                    Address
                                                </th>
                                            </thead>

                                            <tbody>
                                                <tr >
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}>1</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}>Chief Executive</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>

                                                </tr>
                                                <tr >
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}>2</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}>Company Secretary</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>

                                                </tr>
                                                <tr >
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}>3</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}>Chief Financial Officer</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>

                                                </tr>
                                                <tr >
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}>4</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}>Legal Advisor</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>

                                                </tr>
                                                <tr >
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}>5</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}>Auditor</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>

                                                </tr>
                                                <tr >
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}>6</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}>Any Other Officer</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>

                                                </tr>
                                                <tr >
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}>7</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}>Share Registrar (if applicable)</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>

                                                </tr>

                                            </tbody>
                                        </table>
                                    </Wrapper>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>2.10</div>
                                        <div className='align-items-center' style={{ padding: '0px 10px 10px 10px', fontWeight: "bold" }}> List of Directors as on the date up to which this Form is made.</div>
                                    </div>
                                    <Wrapper className="table-responsive mt-2" >
                                        <table
                                            className="table table-bordered"
                                            style={{ fontSize: "12px", fontFamily: "Montserrat rev=1 !important" }}
                                        >
                                            <thead>
                                                <th style={{ verticalAlign: 'sub', border: '1px solid #121212' }}>
                                                    S#
                                                </th>
                                                <th style={{ verticalAlign: 'sub', border: '1px solid #121212' }}>
                                                    Name
                                                </th>
                                                <th style={{ verticalAlign: 'sub', border: '1px solid #121212' }}>
                                                    Residential
                                                    Address
                                                </th>
                                                <th style={{ verticalAlign: 'sub', border: '1px solid #121212' }}>
                                                    Nationality
                                                </th>
                                                <th style={{ verticalAlign: 'sub', border: '1px solid #121212' }}>
                                                    CNIC No. for Pakistanis, or NICOP No. for Overseas Pakistanis, or Passport No. for foreigners
                                                </th>
                                                <th style={{ verticalAlign: 'sub', border: '1px solid #121212' }}>
                                                    Date of appointment or election
                                                </th>
                                                <th style={{ verticalAlign: 'sub', border: '1px solid #121212' }}>
                                                    Name of member or creditor nominating or appointing the director
                                                </th>
                                            </thead>

                                            <tbody>

                                                <tr >
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}>1</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>

                                                </tr>
                                                <tr >
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}>2</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>

                                                </tr>
                                                <tr >
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}>3</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>

                                                </tr>
                                            </tbody>
                                        </table>
                                    </Wrapper>


                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>2.11</div>
                                        <div className='align-items-center' style={{ padding: '0px 10px 10px 10px', fontWeight: "bold" }}> List of members/shareholders & debenture holders on the date up to which this Form is made.</div>
                                    </div>

                                    <Wrapper className="table-responsive mt-2" >
                                        <table
                                            className="table table-bordered"
                                            style={{ fontSize: "12px", fontFamily: "Montserrat rev=1 !important" }}
                                        >
                                            <thead>
                                                <th style={{ verticalAlign: 'sub', border: '1px solid #121212' }}>
                                                    #
                                                </th>
                                                <th style={{ verticalAlign: 'sub', border: '1px solid #121212' }}>
                                                    Folio #
                                                    (If any)
                                                </th>
                                                <th style={{ verticalAlign: 'sub', border: '1px solid #121212' }}>
                                                    Name*
                                                </th>
                                                <th style={{ verticalAlign: 'sub', border: '1px solid #121212' }}>
                                                    Address
                                                </th>
                                                <th style={{ border: '1px solid #121212',textAlign:'center' }}>
                                                    <span style={{ writingMode: "vertical-rl", transform: "rotate(-180deg)", textAlign: "center" ,height:'28%' }}>Nationality</span>
                                                </th>
                                                <th style={{ verticalAlign: 'sub', border: '1px solid #121212' }}>
                                                    No. of shares / debentures held (Applicable in case of companies having share capital)
                                                </th>
                                                <th style={{ verticalAlign: 'sub', border: '1px solid #121212' }}>
                                                    Percentage of shareholding of member having 25% or more shareholding (Applicable in case of companies having share capital)
                                                </th>
                                               
                                                <th style={{ verticalAlign: 'sub', border: '1px solid #121212' }}>
                                                    NIC No. for Pakistanis, or NICOP No. for Overseas Pakistanis, or Passport No. for foreigners, or CUIN No. for Pakistani company, or Registration No. for foreign company.
                                                </th>
                                            </thead>

                                            <tbody>

                                                <tr >
                                                    <td colSpan={3} style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}>Shareholders/members</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>

                                                </tr>
                                                <tr >
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>

                                                </tr>
                                                <tr >
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>

                                                </tr>
                                                <tr >
                                                    <td colSpan={3} style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}>Debenture holders</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>

                                                </tr>
                                                <tr >
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>

                                                </tr>
                                                <tr >
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>

                                                </tr>
                                            </tbody>
                                        </table>
                                    </Wrapper>

                                    <div className='d-flex mt-1'><i>* In case the member or debenture holder is holding shares or debentures on behalf of other
                                        person(s), the name of such other person(s) shall be mentioned in parentheses along with the name of
                                        the member or debenture holder.
                                    </i></div>
                                    <div className='d-flex mt-2'><i>* In case the member or debenture holder is holding interest or exercising voting or control rights in
                                        the company on behalf of other person(s), the name of such other person(s) shall be mentioned in
                                        parentheses along with the name of the member or debenture holder
                                    </i></div>
                                    <div className='d-flex mt-3'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>2.12</div>
                                        <div className='align-items-center' style={{ padding: '0px 10px 10px 10px', fontWeight: "bold" }}> Transfer of shares/ debentures since last Form-A was made (Applicable for companies having share capital)</div>
                                    </div>

                                    <Wrapper className="table-responsive mt-2" >
                                        <table
                                            className="table table-bordered"
                                            style={{ fontSize: "12px", fontFamily: "Montserrat rev=1 !important" }}
                                        >
                                            <thead>
                                                <th style={{ verticalAlign: 'sub', border: '1px solid #121212' }}>
                                                    S#
                                                </th>
                                                <th style={{ verticalAlign: 'sub', border: '1px solid #121212' }}>
                                                    Name of Transferor
                                                </th>
                                                <th style={{ verticalAlign: 'sub', border: '1px solid #121212' }}>
                                                    Name of Transferee
                                                </th>
                                                <th style={{ verticalAlign: 'sub', border: '1px solid #121212' }}>
                                                    Number of shares
                                                    transferred

                                                </th>
                                                <th style={{ verticalAlign: 'sub', border: '1px solid #121212' }}>
                                                    Date of registration of
                                                    transfer
                                                </th>

                                            </thead>

                                            <tbody>

                                                <tr >
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}>Shareholders</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>

                                                </tr>
                                                <tr >
                                                    <td  style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>

                                                </tr>
                                                <tr >
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}>Debenture holders</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>

                                                </tr>
                                                <tr >
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>

                                                </tr>

                                            </tbody>
                                        </table>
                                    </Wrapper>

                                    <PartsWrapper className='mt-4 mb-3'>
                                        PART-III
                                    </PartsWrapper>
                                    <div style={{ fontWeight:"bold" }} className='align-items-center text-center mt-2'>(To be filled by an Inactive Company)</div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>3.1</div>
                                        <div className='align-items-center' style={{ width: '28%', padding: '0px 10px 10px 10px' }}>Correspondence Address</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '65%', padding: ' 5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>3.2</div>
                                        <div className='align-items-center' style={{ width: '28%', padding: '0px 10px 10px 10px' }}>Contact Details</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '65%', padding: ' 5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>3.3</div>
                                        <div className='align-items-center' style={{ padding: '0px 10px 10px 10px', fontWeight: "bold" }}> List of Directors and members as on the date this Form is made.</div>
                                    </div>
                                    <Wrapper className="table-responsive mt-0" >
                                        <table
                                            className="table table-bordered"
                                            style={{ fontSize: "12px", fontFamily: "Montserrat rev=1 !important" }}
                                        >
                                            <thead>
                                                <th style={{ verticalAlign: 'sub', border: '1px solid #121212' }}>
                                                    S#
                                                </th>
                                                <th style={{ verticalAlign: 'sub', border: '1px solid #121212' }}>
                                                    Name
                                                </th>
                                                <th style={{ verticalAlign: 'sub', border: '1px solid #121212' }}>
                                                    Residential
                                                    Address
                                                </th>
                                                <th style={{ verticalAlign: 'sub', border: '1px solid #121212' }}>
                                                    Nationality
                                                </th>
                                                <th style={{ verticalAlign: 'sub', border: '1px solid #121212' }}>
                                                    No. of shares held (if any)
                                                </th>
                                                <th style={{ verticalAlign: 'sub', border: '1px solid #121212' }}>
                                                    CNIC # for Pakistanis, or NICOP # for Overseas Pakistanis, or Passport # for foreigners
                                                </th>
                                                <th style={{ verticalAlign: 'sub', border: '1px solid #121212' }}>
                                                    Date of becoming member/director
                                                </th>
                                                <th style={{ verticalAlign: 'sub', border: '1px solid #121212' }}>
                                                    Name of member or creditor nominating/ appointing the director
                                                </th>

                                            </thead>

                                            <tbody>

                                                <tr >
                                                    <td  style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}>1</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>

                                                </tr>
                                                <tr >
                                                    <td  style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}>2</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>

                                                </tr>
                                                <tr >
                                                    <td  style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}>3</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>

                                                </tr>
                                            </tbody>
                                        </table>
                                    </Wrapper>
                                    <div className='d-flex align-items-baseline mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>3.4</div>
                                        <div className='align-items-center' style={{ padding: '0px 10px 10px 10px',width:"14%" }}>Confirmation about inactive status of Company</div>
                                        <div style={{marginLeft:"3rem"}} className='d-flex flex-column'>
                                            <div>
                                                It is hereby stated and confirmed that the Company has:
                                            </div>
                                            <div className='d-flex align-items-baseline'>
                                                <div>(i)</div><div style={{ marginLeft: "24px" }}>not carried out any operation since grant of status as an inactive
                                                    company;</div>
                                            </div>
                                            <div className='d-flex align-items-baseline'>
                                                <div>(ii)</div><div style={{ marginLeft: "20px" }}> no substantial assets or Accounting transactions;</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ textDecoration: "underline",fontWeight:"bold" }} className='d-flex mt-2'>
                                        Declaration:
                                    </div>
                                    <div className='d-flex align-items-baseline mt-0'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>3.5</div>
                                        <div className='align-items-center' style={{ padding: '0px 10px 10px 10px', }}>I do hereby solemnly and sincerely declare that the information provided in the form and the enclosures is:</div>
                                    </div>
                                    <div style={{marginLeft:"5.5rem"}} className='d-flex flex-column mt-2'>
                                        <div className='d-flex align-items-baseline'>
                                        {/* <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}></div> */}
                                            <div>(i)</div><div style={{ marginLeft: "24px" }}>true and correct to the best of my knowledge, in consonance with the record as maintained by the company and nothing has been concealed; and</div>
                                        </div>
                                        <div className='d-flex align-items-baseline'>
                                        {/* <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}></div> */}
                                            <div>(ii)</div><div style={{ marginLeft: "20px" }}>hereby reported after complying with and fulfilling all requirements under the relevant provisions of law, rules, regulations, directives, circulars and notifications whichever is applicable.</div>
                                        </div>
                                    </div>

                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>3.6</div>
                                        <div className='align-items-center' style={{ width: '28%', padding: '0px 10px 10px 10px' }}> Name of Authorized Officer with designation/Authorized Intermediary (if appointed)</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '32.5%', padding: ' 5px 10px',  }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '32.5%', padding: ' 5px 10px', borderLeft:"none" }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>3.7</div>
                                        <div className='align-items-center' style={{ width: '28%', padding: '0px 10px 10px 10px' }}> Signatures</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '65%', padding: ' 5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>3.8</div>
                                        <div className='align-items-center' style={{ width: '58%', padding: '0px 10px 10px 10px' }}> Registration No of Authorized Intermediary, if applicable</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '35%', padding: ' 5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%',  }}></div>
                                        <div className='align-items-center' style={{ width: '51%', padding: ' 5px 10px',}}> </div>
                                        <div className='align-items-center text-center' style={{ width: '10%', padding: ' 5px', }}> Day</div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%', }}></div>
                                        <div className='align-items-center text-center' style={{ width: '10%', padding: ' 5px',  }}> Month</div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%', }}></div>
                                        <div className='align-items-center text-center' style={{  width: '16%', padding: ' 5px 10px' }}>Year </div>
                                        <div></div>
                                    </div>
                                    <div className='d-flex '>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%',  }}>3.9</div>
                                        <div className='align-items-center' style={{ width: '51%', padding: ' 0px 10px 10px 10px',  }}>Date</div>
                                        <div className='align-items-center' style={{ width: '5%', padding: ' 5px 10px', border: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '5%', padding: ' 5px 10px', border: '1px solid #121212', borderLeft: 'none' }}> </div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%',  }}></div>
                                        <div className='align-items-center' style={{ width: '5%', padding: ' 5px 10px', border: '1px solid #121212',  }}> </div>
                                        <div className='align-items-center' style={{ width: '5%', padding: ' 5px 10px', border: '1px solid #121212', borderLeft: 'none' }}> </div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%',  }}></div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', border: '1px solid #121212', }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', border: '1px solid #121212', borderLeft: 'none' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', border: '1px solid #121212', borderLeft: 'none' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', border: '1px solid #121212', borderLeft: 'none', borderRight: '1px solid #121212' }}> </div>
                                    </div>
                                    <div className='row mt-4 mb-3'>
                                        <div className='col-md-12'>
                                            <HeadingWrapper className='d-flex justify-content-center' style={{ textDecoration: 'underline' }}>
                                                INSTRUCTIONS FOR FILLING THIS FORM
                                            </HeadingWrapper>
                                        </div>
                                    </div>
                                    <div className='d-flex align-items-baseline'>
                                        <div style={{ marginLeft: "5px" }}>1.</div><div style={{ marginLeft: '15px' }}>This Form shall be made up to the date of last AGM of the Company or the last date of the calendar year where no AGM is held/concluded during the year. </div>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div style={{ marginLeft: "5px" }}>2.</div><div style={{ marginLeft: '12px' }}> If shares are of different classes the columns should be subdivided, so that the number of each 
                                        class held, is shown separately against S. No. 2.7 and 2.8</div>
                                    </div>
                                    <div className='d-flex'>
                                        <div style={{ marginLeft: "5px" }}>3.</div><div style={{ marginLeft: '12px' }}>  If space provided is insufficient, the required information should be listed in a separate sheet 
                                        attached to this return which should also be signed</div>
                                    </div>
                                    <div className='d-flex'>
                                        <div style={{ marginLeft: "5px" }}>4.</div><div style={{ marginLeft: '11px' }}> This form is to be filed within 30 days of the date indicated in Sr. No. 2.1 or 2.2 (as the case 
                                            may be). If the form is filed after 30 days, additional fee as per section 468 shall be applicable.</div>
                                    </div>
                                    <div className='d-flex'>
                                        <div style={{ marginLeft: "5px" }}>5.</div><div style={{ marginLeft: '12px' }}> An inactive company or a company which held its AGM but the same was not concluded shall 
                                        file Form-A within a period of 30 days from the close of calendar year.</div>
                                    </div>
                                    <div className='d-flex'>
                                        <div style={{ marginLeft: "5px" }}>6.</div><div style={{ marginLeft: '12px' }}>  This form is not applicable on single member companies & private companies having paid-up capital not exceeding 3.0 million in case there is no change of particulars since last annual return filed with the registrar.</div>
                                    </div>
                                    <div className='d-flex'>
                                        <div style={{ marginLeft: "5px" }}>7.</div><div style={{ marginLeft: '13px' }}> A company, other than a single member company or a private company having paid up capital 
of not more than three million rupees, shall inform the registrar on Form-24 that there is no 
change of particulars in the last annual return filed with the registrar</div>
                                    </div>
                                    <div className='d-flex'>
                                        <div style={{ marginLeft: "5px" }}>8.</div><div style={{ marginLeft: '11px' }}>Original challan or other evidence of payment of fee specified in Seventh Schedule of the Act 
                                        will be submitted with this form (not applicable in case of online filing)</div>
                                    </div>

                                </div>
                            </div></div>


                    </PDFExport>
                    : <PDFExport
                        paperSize="A4"
                        margin="1cm"
                        scale={0.6}
                        fileName={`Form A`}
                        pageTemplate={PageTemplate}
                        ref={pdfExportComponent}
                    >
                        <div style={{ fontFamily: `${fontFamilyForStaticData}`, fontSize: `${fontSizeForStaticData}`, fontWeight: 500 }}>
                            <FormName>
                                From-A
                            </FormName>
                            <div className='row'>
                                <div className='col-md-12' >
                                    <HeadingWrapper className='d-flex justify-content-center'>
                                        THE COMPANIES ACT, 2017
                                    </HeadingWrapper>

                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <HeadingWrapper className='d-flex justify-content-center'>
                                        THE COMPANIES REGULATIONS, 2024
                                    </HeadingWrapper>

                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <div style={{ fontSize: '15px', }} className='d-flex justify-content-center'>
                                        [Section 130(1), 130(2)1 425(5) read with Regulations 62 & 30]
                                    </div>

                                </div>
                            </div>
                            <div className='row mt-2'>
                                <div className='col-md-12' >
                                    <HeadingWrapper style={{ textDecoration: "underline" }} className='d-flex justify-content-center'>
                                    ANNUAL RETURN OF A COMPANY

                                    </HeadingWrapper>

                                </div>
                            </div>
                            <PartsWrapper className='mt-4 mb-3'>
                                PART-I
                            </PartsWrapper>
                            <div style={{ fontWeight: "bold" }} className='align-items-center text-center mt-0'>(To be filled by All Companies)</div>


                            <div className='row'>
                                <div className='col-md-12' style={{ padding: '25px 50px', wordWrap: 'break-word' }}>
                                    <div> <i>(Please complete in bold capital letters)</i></div>


                                    <div className='d-flex' >
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>1.1</div>
                                        <div className='align-items-center' style={{ width: '28%', padding: '0px 10px 10px 10px' }}>CUIN (Registration Number)</div>
                                        <div className='align-items-center  d-flex'  >{
                                            '1234 552'.split("")?.map((item, idx) => {
                                                if (item == ' ') return
                                                return <div style={{ minWidth: '30px', minHeight: '34px', border: '1px solid #121212', borderLeft: idx != 0 ? "none" : "1px solid #121212", padding: '6px 9px' }}></div>
                                            })
                                        }
                                        </div>
                                    </div>


                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>1.2</div>
                                        <div className='align-items-center' style={{ width: '28%', padding: '0px 10px 10px 10px' }}>Name of the Company</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '65%', padding: ' 5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                    </div>






                                    <div className='d-flex  mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>1.3</div>
                                        <div className='align-items-center' style={{ width: '20.20%', padding: '0px 20px 10px 10px' }}>Fee Payment Details</div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '5%' }}>1.3.1</div>
                                        <div className='align-items-center' style={{ width: '13.20%', paddingLeft: '20px' }}>Challan No</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '17.20%', paddingLeft: '20px', minHeight: '34px' }}></ScrollWrapper>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '5%' }}>1.3.3</div>
                                        <div className='align-items-center' style={{ width: '13.20%', paddingLeft: '20px' }}>Amount</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '19.20%', paddingLeft: '20px', minHeight: '34px' }}></ScrollWrapper>
                                    </div>

                                    <div className='d-flex justify-content-center  mt-2'>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '75%', borderBottom: "none", paddingLeft: '20px', minHeight: '70px', fontWeight: "bold" }}>1.4 Notice of —</ScrollWrapper>
                                        <ScrollWrapper className='align-items-center text-center' style={{ border: '1px solid #121212', borderLeft: "none", borderBottom: "none", width: '15%', padding: '10px', minHeight: '70px' }}>Please tick the relevant box</ScrollWrapper>
                                    </div>
                                    <div className='d-flex justify-content-center'>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '21%', paddingLeft: '20px', borderBottom: "none", minHeight: '34px', fontWeight: "bold" }}>Part II</ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '54%', borderLeft: "none", borderBottom: "none", paddingLeft: '20px', minHeight: '34px' }}> Annual Return of a company other than inactive company</ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '15%', borderLeft: "none", borderBottom: "none", paddingLeft: '20px', minHeight: '34px' }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex justify-content-center'>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '21%', paddingLeft: '20px', minHeight: '34px', fontWeight: "bold" }}>Part III </ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '54%', borderLeft: "none",  paddingLeft: '20px', minHeight: '34px' }}> Annual Return of Inactive Company</ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '15%', borderLeft: "none",  paddingLeft: '20px', minHeight: '34px' }}></ScrollWrapper>
                                    </div>
                                    


                                    <PartsWrapper className='mt-4 mb-3'>
                                        PART-II
                                    </PartsWrapper>
                                    <div style={{ fontWeight: "bold" }} className='align-items-center text-center mt-0'>(To be filled by All Companies)</div>


                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}></div>
                                        <div className='align-items-center' style={{ width: '55%', padding: ' 5px 10px' }}> </div>
                                        <div className='align-items-center text-center' style={{ width: '8%', padding: ' 5px 10px' }}>dd</div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center text-center' style={{ width: '8%', padding: ' 5px 10px' }}>mm</div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center text-center' style={{ width: '16%', padding: ' 5px 10px' }}>yyyy</div>
                                        <div></div>
                                    </div>
                                    <div className='d-flex '>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>2.1</div>
                                        <div className='align-items-center' style={{ width: '55%', padding: '0px 10px 10px 10px' }}>Annual General Meeting held on</div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ border: '1px solid #121212', width: '4%', padding: ' 5px 10px' }}> </div>
                                        <div className='align-items-center' style={{ paddingLeft: '8px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ border: '1px solid #121212', width: '4%', padding: ' 5px 10px', borderRight: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ paddingLeft: '8px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', border: '1px solid #121212', }}> </div>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>2.2</div>
                                        <div className='align-items-center' style={{ width: '55%', padding: '0px 10px 10px 10px' }}> Form-A made up to (applicable in case no AGM was held/concluded during the year)</div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ border: '1px solid #121212', width: '4%', padding: ' 5px 10px' }}> </div>
                                        <div className='align-items-center' style={{ paddingLeft: '8px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ border: '1px solid #121212', width: '4%', padding: ' 5px 10px', borderRight: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ paddingLeft: '8px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', border: '1px solid #121212', }}> </div>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>2.3</div>
                                        <div className='align-items-center' style={{ width: '28%', padding: '0px 10px 10px 10px' }}> Registered office address</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '65%', padding: ' 5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>2.4</div>
                                        <div className='align-items-center' style={{ width: '28%', padding: '0px 10px 10px 10px' }}>Email Address</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '65%', padding: ' 5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>2.5</div>
                                        <div className='align-items-center' style={{ width: '28%', padding: '0px 10px 10px 10px' }}>Office Tel. No.</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '65%', padding: ' 5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-0'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>2.6</div>
                                        <div className='align-items-center' style={{ width: '58%', padding: '0px 10px 10px 10px' }}>Mobile No. (Preferably WhatsApp enabled number) of authorized officer: (Chief Executive/ Director/ Company Secretary/ Chief Financial Officer)</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '35%', padding: ' 5px 10px', borderTop:"none" }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '6.6%' }}>2.7</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '94%', padding: ' 5px 10px', fontWeight: 'bold' }}> Authorized Share Capital (If applicable)</ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-0'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}></div>
                                        <Wrapper className="table-responsive mt-0" >
                                            <table
                                                className="table table-bordered"
                                                style={{ fontSize: "12px", fontFamily: "Montserrat rev=1 !important" }}
                                            >
                                                <thead>
                                                    <th style={{ verticalAlign: 'sub', border: '1px solid #121212' }}>
                                                        Classes and kinds of Shares
                                                    </th>
                                                    <th style={{ verticalAlign: 'sub', border: '1px solid #121212' }}>
                                                        No. of Shares
                                                    </th>
                                                    <th style={{ verticalAlign: 'sub', border: '1px solid #121212' }}>
                                                        Amount
                                                    </th>
                                                    <th style={{ verticalAlign: 'sub', border: '1px solid #121212' }}>
                                                        Face Value
                                                    </th>
                                                </thead>

                                                <tbody>
                                                    <tr >
                                                        <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}>Ordinary Shares</td>
                                                        <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                        <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                        <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>

                                                    </tr>
                                                    <tr >
                                                        <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                        <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                        <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                        <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>

                                                    </tr>

                                                </tbody>
                                            </table>
                                        </Wrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '6.6%' }}>2.8</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '94%', padding: ' 5px 10px', fontWeight: 'bold' }}>Paid up Share Capital (If applicable)</ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-0'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}></div>
                                        <Wrapper className="table-responsive mt-0" >
                                            <table
                                                className="table table-bordered"
                                                style={{ fontSize: "12px", fontFamily: "Montserrat rev=1 !important" }}
                                            >
                                                <thead>
                                                    <th style={{ verticalAlign: 'sub', border: '1px solid #121212' }}>
                                                        Classes and kinds of Shares
                                                    </th>
                                                    <th style={{ verticalAlign: 'sub', border: '1px solid #121212' }}>
                                                        No. of Shares
                                                    </th>
                                                    <th style={{ verticalAlign: 'sub', border: '1px solid #121212' }}>
                                                        Amount
                                                    </th>
                                                    <th style={{ verticalAlign: 'sub', border: '1px solid #121212' }}>
                                                        Face Value
                                                    </th>
                                                </thead>

                                                <tbody>
                                                    <tr >
                                                        <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}>Ordinary Shares</td>
                                                        <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                        <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                        <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>

                                                    </tr>
                                                    <tr >
                                                        <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                        <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                        <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                        <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>

                                                    </tr>

                                                </tbody>
                                            </table>
                                        </Wrapper>
                                    </div>

                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>2.9</div>
                                        <div className='align-items-center' style={{ padding: '0px 10px 10px 10px', fontWeight: "bold" }}>Particulars of Officer(s) including Share Registrar</div>
                                    </div>
                                    <Wrapper className="table-responsive mt-2" >
                                        <table
                                            className="table table-bordered"
                                            style={{ fontSize: "12px", fontFamily: "Montserrat rev=1 !important" }}
                                        >
                                            <thead>
                                                <th style={{ verticalAlign: 'sub', border: '1px solid #121212' }}>
                                                    S. No
                                                </th>
                                                <th style={{ verticalAlign: 'sub', border: '1px solid #121212' }}>
                                                    Name
                                                </th>
                                                <th style={{ verticalAlign: 'sub', border: '1px solid #121212' }}>
                                                    Designation
                                                </th>
                                                <th style={{ verticalAlign: 'sub', border: '1px solid #121212' }}>
                                                    CNIC No.
                                                </th>
                                                <th style={{ verticalAlign: 'sub', border: '1px solid #121212' }}>
                                                    Address
                                                </th>
                                            </thead>

                                            <tbody>
                                                <tr >
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}>1</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}>Chief Executive</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>

                                                </tr>
                                                <tr >
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}>2</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}>Company Secretary</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>

                                                </tr>
                                                <tr >
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}>3</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}>Chief Financial Officer</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>

                                                </tr>
                                                <tr >
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}>4</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}>Legal Advisor</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>

                                                </tr>
                                                <tr >
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}>5</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}>Auditor</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>

                                                </tr>
                                                <tr >
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}>6</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}>Any Other Officer</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>

                                                </tr>
                                                <tr >
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}>7</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}>Share Registrar (if applicable)</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>

                                                </tr>

                                            </tbody>
                                        </table>
                                    </Wrapper>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>2.10</div>
                                        <div className='align-items-center' style={{ padding: '0px 10px 10px 10px', fontWeight: "bold" }}> List of Directors as on the date up to which this Form is made.</div>
                                    </div>
                                    <Wrapper className="table-responsive mt-2" >
                                        <table
                                            className="table table-bordered"
                                            style={{ fontSize: "12px", fontFamily: "Montserrat rev=1 !important" }}
                                        >
                                            <thead>
                                                <th style={{ verticalAlign: 'sub', border: '1px solid #121212' }}>
                                                    S#
                                                </th>
                                                <th style={{ verticalAlign: 'sub', border: '1px solid #121212' }}>
                                                    Name
                                                </th>
                                                <th style={{ verticalAlign: 'sub', border: '1px solid #121212' }}>
                                                    Residential
                                                    Address
                                                </th>
                                                <th style={{ verticalAlign: 'sub', border: '1px solid #121212' }}>
                                                    Nationality
                                                </th>
                                                <th style={{ verticalAlign: 'sub', border: '1px solid #121212' }}>
                                                    CNIC No. for Pakistanis, or NICOP No. for Overseas Pakistanis, or Passport No. for foreigners
                                                </th>
                                                <th style={{ verticalAlign: 'sub', border: '1px solid #121212' }}>
                                                    Date of appointment or election
                                                </th>
                                                <th style={{ verticalAlign: 'sub', border: '1px solid #121212' }}>
                                                    Name of member or creditor nominating or appointing the director
                                                </th>
                                            </thead>

                                            <tbody>

                                                <tr >
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}>1</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>

                                                </tr>
                                                <tr >
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}>2</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>

                                                </tr>
                                                <tr >
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}>3</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>

                                                </tr>
                                            </tbody>
                                        </table>
                                    </Wrapper>


                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>2.11</div>
                                        <div className='align-items-center' style={{ padding: '0px 10px 10px 10px', fontWeight: "bold" }}> List of members/shareholders & debenture holders on the date up to which this Form is made.</div>
                                    </div>

                                    <Wrapper className="table-responsive mt-2" >
                                        <table
                                            className="table table-bordered"
                                            style={{ fontSize: "12px", fontFamily: "Montserrat rev=1 !important" }}
                                        >
                                            <thead>
                                                <th style={{ verticalAlign: 'sub', border: '1px solid #121212' }}>
                                                    #
                                                </th>
                                                <th style={{ verticalAlign: 'sub', border: '1px solid #121212' }}>
                                                    Folio #
                                                    (If any)
                                                </th>
                                                <th style={{ verticalAlign: 'sub', border: '1px solid #121212' }}>
                                                    Name*
                                                </th>
                                                <th style={{ verticalAlign: 'sub', border: '1px solid #121212' }}>
                                                    Address
                                                </th>
                                                <th style={{ border: '1px solid #121212',textAlign:'center' }}>
                                                    <span style={{ writingMode: "vertical-rl", transform: "rotate(-180deg)", textAlign: "center" ,height:'28%' }}>Nationality</span>
                                                </th>
                                                <th style={{ verticalAlign: 'sub', border: '1px solid #121212' }}>
                                                    No. of shares / debentures held (Applicable in case of companies having share capital)
                                                </th>
                                                <th style={{ verticalAlign: 'sub', border: '1px solid #121212' }}>
                                                    Percentage of shareholding of member having 25% or more shareholding (Applicable in case of companies having share capital)
                                                </th>
                                               
                                                <th style={{ verticalAlign: 'sub', border: '1px solid #121212' }}>
                                                    NIC No. for Pakistanis, or NICOP No. for Overseas Pakistanis, or Passport No. for foreigners, or CUIN No. for Pakistani company, or Registration No. for foreign company.
                                                </th>
                                            </thead>

                                            <tbody>

                                                <tr >
                                                    <td colSpan={3} style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}>Shareholders/members</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>

                                                </tr>
                                                <tr >
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>

                                                </tr>
                                                <tr >
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>

                                                </tr>
                                                <tr >
                                                    <td colSpan={3} style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}>Debenture holders</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>

                                                </tr>
                                                <tr >
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>

                                                </tr>
                                                <tr >
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>

                                                </tr>
                                            </tbody>
                                        </table>
                                    </Wrapper>

                                    <div className='d-flex mt-1'><i>* In case the member or debenture holder is holding shares or debentures on behalf of other
                                        person(s), the name of such other person(s) shall be mentioned in parentheses along with the name of
                                        the member or debenture holder.
                                    </i></div>
                                    <div className='d-flex mt-2'><i>* In case the member or debenture holder is holding interest or exercising voting or control rights in
                                        the company on behalf of other person(s), the name of such other person(s) shall be mentioned in
                                        parentheses along with the name of the member or debenture holder
                                    </i></div>
                                    <div className='d-flex mt-3'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>2.12</div>
                                        <div className='align-items-center' style={{ padding: '0px 10px 10px 10px', fontWeight: "bold" }}> Transfer of shares/ debentures since last Form-A was made (Applicable for companies having share capital)</div>
                                    </div>

                                    <Wrapper className="table-responsive mt-2" >
                                        <table
                                            className="table table-bordered"
                                            style={{ fontSize: "12px", fontFamily: "Montserrat rev=1 !important" }}
                                        >
                                            <thead>
                                                <th style={{ verticalAlign: 'sub', border: '1px solid #121212' }}>
                                                    S#
                                                </th>
                                                <th style={{ verticalAlign: 'sub', border: '1px solid #121212' }}>
                                                    Name of Transferor
                                                </th>
                                                <th style={{ verticalAlign: 'sub', border: '1px solid #121212' }}>
                                                    Name of Transferee
                                                </th>
                                                <th style={{ verticalAlign: 'sub', border: '1px solid #121212' }}>
                                                    Number of shares
                                                    transferred

                                                </th>
                                                <th style={{ verticalAlign: 'sub', border: '1px solid #121212' }}>
                                                    Date of registration of
                                                    transfer
                                                </th>

                                            </thead>

                                            <tbody>

                                                <tr >
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}>Shareholders</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>

                                                </tr>
                                                <tr >
                                                    <td  style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>

                                                </tr>
                                                <tr >
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}>Debenture holders</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>

                                                </tr>
                                                <tr >
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>

                                                </tr>

                                            </tbody>
                                        </table>
                                    </Wrapper>

                                    <PartsWrapper className='mt-4 mb-3'>
                                        PART-III
                                    </PartsWrapper>
                                    <div style={{ fontWeight:"bold" }} className='align-items-center text-center mt-2'>(To be filled by an Inactive Company)</div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>3.1</div>
                                        <div className='align-items-center' style={{ width: '28%', padding: '0px 10px 10px 10px' }}>Correspondence Address</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '65%', padding: ' 5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>3.2</div>
                                        <div className='align-items-center' style={{ width: '28%', padding: '0px 10px 10px 10px' }}>Contact Details</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '65%', padding: ' 5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>3.3</div>
                                        <div className='align-items-center' style={{ padding: '0px 10px 10px 10px', fontWeight: "bold" }}> List of Directors and members as on the date this Form is made.</div>
                                    </div>
                                    <Wrapper className="table-responsive mt-0" >
                                        <table
                                            className="table table-bordered"
                                            style={{ fontSize: "12px", fontFamily: "Montserrat rev=1 !important" }}
                                        >
                                            <thead>
                                                <th style={{ verticalAlign: 'sub', border: '1px solid #121212' }}>
                                                    S#
                                                </th>
                                                <th style={{ verticalAlign: 'sub', border: '1px solid #121212' }}>
                                                    Name
                                                </th>
                                                <th style={{ verticalAlign: 'sub', border: '1px solid #121212' }}>
                                                    Residential
                                                    Address
                                                </th>
                                                <th style={{ verticalAlign: 'sub', border: '1px solid #121212' }}>
                                                    Nationality
                                                </th>
                                                <th style={{ verticalAlign: 'sub', border: '1px solid #121212' }}>
                                                    No. of shares held (if any)
                                                </th>
                                                <th style={{ verticalAlign: 'sub', border: '1px solid #121212' }}>
                                                    CNIC # for Pakistanis, or NICOP # for Overseas Pakistanis, or Passport # for foreigners
                                                </th>
                                                <th style={{ verticalAlign: 'sub', border: '1px solid #121212' }}>
                                                    Date of becoming member/director
                                                </th>
                                                <th style={{ verticalAlign: 'sub', border: '1px solid #121212' }}>
                                                    Name of member or creditor nominating/ appointing the director
                                                </th>

                                            </thead>

                                            <tbody>

                                                <tr >
                                                    <td  style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}>1</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>

                                                </tr>
                                                <tr >
                                                    <td  style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}>2</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>

                                                </tr>
                                                <tr >
                                                    <td  style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}>3</td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>
                                                    <td style={{ verticalAlign: 'sub', minHeight: '24px', width: '7%' }}></td>

                                                </tr>
                                            </tbody>
                                        </table>
                                    </Wrapper>
                                    <div className='d-flex align-items-baseline mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>3.4</div>
                                        <div className='align-items-center' style={{ padding: '0px 10px 10px 10px',width:"14%" }}>Confirmation about inactive status of Company</div>
                                        <div style={{marginLeft:"3rem"}} className='d-flex flex-column'>
                                            <div>
                                                It is hereby stated and confirmed that the Company has:
                                            </div>
                                            <div className='d-flex align-items-baseline'>
                                                <div>(i)</div><div style={{ marginLeft: "24px" }}>not carried out any operation since grant of status as an inactive
                                                    company;</div>
                                            </div>
                                            <div className='d-flex align-items-baseline'>
                                                <div>(ii)</div><div style={{ marginLeft: "20px" }}> no substantial assets or Accounting transactions;</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ textDecoration: "underline",fontWeight:"bold" }} className='d-flex mt-2'>
                                        Declaration:
                                    </div>
                                    <div className='d-flex align-items-baseline mt-0'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>3.5</div>
                                        <div className='align-items-center' style={{ padding: '0px 10px 10px 10px', }}>I do hereby solemnly and sincerely declare that the information provided in the form and the enclosures is:</div>
                                    </div>
                                    <div style={{marginLeft:"5.5rem"}} className='d-flex flex-column mt-2'>
                                        <div className='d-flex align-items-baseline'>
                                        {/* <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}></div> */}
                                            <div>(i)</div><div style={{ marginLeft: "24px" }}>true and correct to the best of my knowledge, in consonance with the record as maintained by the company and nothing has been concealed; and</div>
                                        </div>
                                        <div className='d-flex align-items-baseline'>
                                        {/* <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}></div> */}
                                            <div>(ii)</div><div style={{ marginLeft: "20px" }}>hereby reported after complying with and fulfilling all requirements under the relevant provisions of law, rules, regulations, directives, circulars and notifications whichever is applicable.</div>
                                        </div>
                                    </div>

                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>3.6</div>
                                        <div className='align-items-center' style={{ width: '28%', padding: '0px 10px 10px 10px' }}> Name of Authorized Officer with designation/Authorized Intermediary (if appointed)</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '32.5%', padding: ' 5px 10px',  }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '32.5%', padding: ' 5px 10px', borderLeft:"none" }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>3.7</div>
                                        <div className='align-items-center' style={{ width: '28%', padding: '0px 10px 10px 10px' }}> Signatures</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '65%', padding: ' 5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>3.8</div>
                                        <div className='align-items-center' style={{ width: '58%', padding: '0px 10px 10px 10px' }}> Registration No of Authorized Intermediary, if applicable</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '35%', padding: ' 5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%',  }}></div>
                                        <div className='align-items-center' style={{ width: '51%', padding: ' 5px 10px',}}> </div>
                                        <div className='align-items-center text-center' style={{ width: '10%', padding: ' 5px', }}> Day</div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%', }}></div>
                                        <div className='align-items-center text-center' style={{ width: '10%', padding: ' 5px',  }}> Month</div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%', }}></div>
                                        <div className='align-items-center text-center' style={{  width: '16%', padding: ' 5px 10px' }}>Year </div>
                                        <div></div>
                                    </div>
                                    <div className='d-flex '>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%',  }}>3.9</div>
                                        <div className='align-items-center' style={{ width: '51%', padding: ' 0px 10px 10px 10px',  }}>Date</div>
                                        <div className='align-items-center' style={{ width: '5%', padding: ' 5px 10px', border: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '5%', padding: ' 5px 10px', border: '1px solid #121212', borderLeft: 'none' }}> </div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%',  }}></div>
                                        <div className='align-items-center' style={{ width: '5%', padding: ' 5px 10px', border: '1px solid #121212',  }}> </div>
                                        <div className='align-items-center' style={{ width: '5%', padding: ' 5px 10px', border: '1px solid #121212', borderLeft: 'none' }}> </div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%',  }}></div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', border: '1px solid #121212', }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', border: '1px solid #121212', borderLeft: 'none' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', border: '1px solid #121212', borderLeft: 'none' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', border: '1px solid #121212', borderLeft: 'none', borderRight: '1px solid #121212' }}> </div>
                                    </div>
                                    <div className='row mt-4 mb-3'>
                                        <div className='col-md-12'>
                                            <HeadingWrapper className='d-flex justify-content-center' style={{ textDecoration: 'underline' }}>
                                                INSTRUCTIONS FOR FILLING THIS FORM
                                            </HeadingWrapper>
                                        </div>
                                    </div>
                                    <div className='d-flex align-items-baseline'>
                                        <div style={{ marginLeft: "5px" }}>1.</div><div style={{ marginLeft: '15px' }}>This Form shall be made up to the date of last AGM of the Company or the last date of the calendar year where no AGM is held/concluded during the year. </div>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div style={{ marginLeft: "5px" }}>2.</div><div style={{ marginLeft: '12px' }}> If shares are of different classes the columns should be subdivided, so that the number of each 
                                        class held, is shown separately against S. No. 2.7 and 2.8</div>
                                    </div>
                                    <div className='d-flex'>
                                        <div style={{ marginLeft: "5px" }}>3.</div><div style={{ marginLeft: '12px' }}>  If space provided is insufficient, the required information should be listed in a separate sheet 
                                        attached to this return which should also be signed</div>
                                    </div>
                                    <div className='d-flex'>
                                        <div style={{ marginLeft: "5px" }}>4.</div><div style={{ marginLeft: '11px' }}> This form is to be filed within 30 days of the date indicated in Sr. No. 2.1 or 2.2 (as the case 
                                            may be). If the form is filed after 30 days, additional fee as per section 468 shall be applicable.</div>
                                    </div>
                                    <div className='d-flex'>
                                        <div style={{ marginLeft: "5px" }}>5.</div><div style={{ marginLeft: '12px' }}> An inactive company or a company which held its AGM but the same was not concluded shall 
                                        file Form-A within a period of 30 days from the close of calendar year.</div>
                                    </div>
                                    <div className='d-flex'>
                                        <div style={{ marginLeft: "5px" }}>6.</div><div style={{ marginLeft: '12px' }}>  This form is not applicable on single member companies & private companies having paid-up capital not exceeding 3.0 million in case there is no change of particulars since last annual return filed with the registrar.</div>
                                    </div>
                                    <div className='d-flex'>
                                        <div style={{ marginLeft: "5px" }}>7.</div><div style={{ marginLeft: '13px' }}> A company, other than a single member company or a private company having paid up capital 
of not more than three million rupees, shall inform the registrar on Form-24 that there is no 
change of particulars in the last annual return filed with the registrar</div>
                                    </div>
                                    <div className='d-flex'>
                                        <div style={{ marginLeft: "5px" }}>8.</div><div style={{ marginLeft: '11px' }}>Original challan or other evidence of payment of fee specified in Seventh Schedule of the Act 
                                        will be submitted with this form (not applicable in case of online filing)</div>
                                    </div>

                                </div>
                            </div></div>
                    </PDFExport>}
            </div>
            <button disabled={loading} className='btn btn-danger' onClick={(e) => {
                // if(viewPdf)
                //   SetViewPDf(true)
                if (pdfExportComponent.current) {
                    pdfExportComponent.current.save();
                }
            }}>Download PDF</button>
        </div>
    )
}

const PartsWrapper = styled.div`
color: #121212;
text-decoration: underline;
justify-content: center;
display: flex;
margin-right: 15px;
font-size: 17px;
font-weight: bold;
`;
const ScrollWrapper = styled.div`
max-height: 50px;
overflow-y: scroll;
overflow-x: hidden;
::-webkit-scrollbar{
    height: 5px;
    width: 3px;
  }
  
  ::-webkit-scrollbar-track{
    background: #F9F9FB;
  }
  ::-webkit-scrollbar-thumb{
    background: #4E515680;
    border-radius: 5px;
  
  }
`;
const HeadingWrapper = styled.div`
font-size: 17px;
font-weight: bold;
`;
const FormName = styled.div`
color: #121212;
text-decoration: underline;
justify-content: end;
display: flex;
margin-right: 15px;
font-size: 17px;
font-weight: bold;
`;
const Wrapper = styled.div`
table.table-bordered{
    border: 1px solid #121212
     border-color: #121212; 
    // border:1px solid blue;
    // margin-top:20px;
  }
// table.table-bordered > thead > tr > th{
//     // border:1px solid blue;
//     border-color: #121212; 
// }
// table.table-bordered > tbody > tr > td{
//     // border:1px solid blue;
//     borderC-color: #121212; 
// }
 tr td {
    // border:1px solid blue;.
    border-color: #121212; 
}

`;
const WrapperDashed = styled.div`

  td {
    
    // border: 1px dashed #dddcdc;
}

`;