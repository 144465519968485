import React, { Fragment, useState, useEffect, useContext } from "react";
import { useSelector } from "react-redux";
import man from "../../../assets/images/dashboard/user.png";
import { User, Mail, Lock, Settings, LogOut } from "react-feather";
import { firebase_app } from "../../../data/config";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import { useAuth0 } from "@auth0/auth0-react";
import { EditProfile, Inbox, LockScreen } from "../../../constant";
import { logoutCentral, logoutMain } from "store/services/auth.service";
import { getCompanyById } from "store/services/company.service";
import { toast } from "react-toastify";
import styled from "styled-components";

const UserMenu = ({ history }) => {
  const email = sessionStorage.getItem("email");
  const mixLayout = useSelector((state) => state?.Customizer?.mix_layout);
  const [screen, setScreen] = useState();
  const company_code = sessionStorage.getItem("company_code");
  const [profile, setProfile] = useState("");
  // const { company_data, company_data_loading } = useSelector(
  //   (data) => data.Company
  // );
  const [company_data, setCompany_Data] = useState({});
  // auth0 profile
  const { logout } = useAuth0();
  const authenticated = JSON.parse(localStorage.getItem("authenticated"));
  const auth0_profile = JSON.parse(localStorage.getItem("auth0_profile"));

  const getCompanyData = async () => {
    try {
      const response = await getCompanyById(email, company_code);
      if (response.status === 200) {
        setCompany_Data(response.data.data);
      } else {
        setCompany_Data({});
      }
    } catch (error) {
      if (error.response !== undefined) {
        toast.error(error.response.data.message);
      } else {
        toast.error(error.message);
      }
    }
  };

  useEffect(() => {
    getCompanyData();
    setProfile(localStorage.getItem("profileURL") || man);
  }, []);

  const logoutApp = async () => {
    try {
      const mainResponse = await logoutMain(email);
      if (mainResponse.status === 200) {
        const response = await logoutCentral(email);
        if (response.status === 200) {
          handleLogout();
        } else {
          toast.error(response.data.message);
        }
      } else {
        toast.error(mainResponse.data.message);
      }
    } catch (error) {
      if (error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error(error.message);
      }
    }
  };

  const handleLogout = () => {
    sessionStorage.clear();
    window.location.replace(`${process.env.PUBLIC_URL}/login`);
  };

  const UserProfile = styled.div`
  .popup{
 overflow-x: auto;
 ::-webkit-scrollbar {
   height: 5px;
   width: 3px;
 }

 ::-webkit-scrollbar-track {
   background: #f9f9fb;
 }
 ::-webkit-scrollbar-thumb {
   background: #4e515680;
   border-radius: 5px;
 }
}
 .email_text{
 text-wrap: wrap !important
 }
 
 #a_tag{
 color:black !important}
 #a_Tag:hover{
 color:black !important
 }
 #a_Tag_email{
 color:black !important
 display: flex !important
 }
 #a_Tag_email:hover{
 color:black !important
 }

 `;
  useEffect(() => {
    const handleResize = () => {
      setScreen(window?.outerWidth);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Fragment>
      <li className="onhover-dropdown">
        <div className="media align-items-center">
          {company_data?.logo ? (
            <img
              className="align-self-center pull-right img-50 rounded-circle blur-up lazyloaded mr-2"
              src={company_data?.logo}
              alt={company_data?.company_name || "Company Logo"}
            />
          ) : (
            <div className="align-self-center pull-right img-50 rounded-circle blur-up lazyloaded">
              <svg
                style={{ width: "100%", height: "25px" }}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 32 32"
              >
                <path
                  fill={localStorage.getItem("primary_color")}
                  d="M16 3C8.832 3 3 8.832 3 16s5.832 13 13 13 13-5.832 13-13S23.168 3 16 3zm0 2c6.086 0 11 4.914 11 11s-4.914 11-11 11S5 22.086 5 16 9.914 5 16 5zm0 3c-2.75 0-5 2.25-5 5 0 1.516.707 2.863 1.781 3.781A7.005 7.005 0 009 23h2c0-2.773 2.227-5 5-5s5 2.227 5 5h2c0-2.7-1.531-5.05-3.781-6.219C20.293 15.863 21 14.516 21 13c0-2.75-2.25-5-5-5zm0 2c1.668 0 3 1.332 3 3s-1.332 3-3 3-3-1.332-3-3 1.332-3 3-3z"
                ></path>
              </svg>
            </div>
          )}
          {/* <div className="dotted-animation">
            <span className="animate-circle"></span>
            <span className="main-circle"></span>
          </div> */}
        </div>
        <UserProfile>
          <ul
            style={{ cursor: "default", padding: "20px 20px 10px 20px" }}
            className="profile-dropdown onhover-show-div profile-dropdown-hover"
          >
            <div className="popup table-responsive">
              <li id="a_Tag" className="py-0">
                <Link
                  style={{
                    cursor: "default",
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                  id="a_Tag"
                  to="#"
                  onClick={(e) => {
                    // history.push(`${process.env.PUBLIC_URL}/company-info`);
                    // sessionStorage.setItem("selectedCompany", JSON.stringify(company_data));
                  }}
                >
                  {company_data?.company_name}
                </Link>
              </li>
              <li id="a_Tag" className="py-0">
                <Link
                  style={{ cursor: "default" }}
                  id="a_Tag"
                  to="#"
                >{`${sessionStorage.getItem("name")}`}</Link>
              </li>
              <li id="a_Tag" className="py-0">
                <Link
                  style={{ cursor: "default" }}
                  id="a_Tag"
                  to="#"
                >{`(${sessionStorage.getItem("email")})`}</Link>
              </li>

              <li style={{ paddingBottom: "10px" }} className="mt-2 ">
                <a
                  style={{ cursor: "pointer" }}
                  // onClick={authenticated ? Logout_From_Auth0 : Logout_From_Firebase}
                  onClick={logoutApp}
                  //   href="#javascript"
                >
                  <LogOut /> {"Log out"}
                </a>
              </li>
            </div>
          </ul>
        </UserProfile>
      </li>
    </Fragment>
  );
};

export default withRouter(UserMenu);
