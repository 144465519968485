import { Controller, useForm } from "react-hook-form";
import { ViewEventScema } from "store/validations/alertValidation";
import { yupResolver } from "@hookform/resolvers/yup";
import NumberFormat from "react-number-format";

import React, { useState, useEffect } from "react";
// import { addAlertValidationSchema } from 'store/validations/alertValidation';
import { addEventValidationSchema } from "store/validations/alertValidation";
import Select from "react-select";
import { getCompanies, getCompanyById } from "store/services/company.service";
import { getShareHolderByFolioNo } from "store/services/shareholder.service";
import moment from "moment";
export const ViewEvent = ({ showID }) => {
  const event = JSON.parse(sessionStorage.getItem("selectedEvent")) || "";

  const baseEmail = sessionStorage.getItem("email") || "";
  const [baseCompany, setBaseCompany] = useState(
    sessionStorage.getItem("company_code")
  );

  const [defaultCountry, setDefaultCountry] = useState("");
  const [defaultChairmain, setDefaultChairmain] = useState(
    event?.chairman || ""
  );
  const [companies_data_loading, setCompanies_data_loading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
    getValues,
    setValue,
    watch,
  } = useForm({
    defaultValues: ViewEventScema(event).cast(),
    resolver: yupResolver(ViewEventScema(event)),
  });
  useEffect(() => {
    const getAllCompanies = async () => {
      setCompanies_data_loading(true);
      try {
        // const response = await getCompanies(baseEmail);
        const response = await getCompanyById(baseEmail, baseCompany?.value);
        const res = await getShareHolderByFolioNo(baseEmail, event?.chairman);
        if (response.status === 200) {
          setAllCompaniesData(response.data.data);
          // response.data.data = response.data.data.filter(
          //   (item) => item?.active === "Y"
          // );
          const parents = response.data.data;
          // const findCompany = parents?.find(
          //   (item) => item?.code == event?.company_code
          // );
          // let findLabel = `${findCompany?.code} - ${findCompany?.company_name}`;
          setDefaultCountry(parents);
          // const companies_dropdowns = response.data.data.map((item) => {
          //   let label = `${item.code} - ${item.company_name}`;
          //   return { label: label, value: item.code };
          // });
          // setCompanies_dropdown(companies_dropdowns);
          setCompanies_data_loading(false);

          // let selectedCompany = response.data.data.find(
          //   (company) => company.code == watch("company_code")?.value
          // );
          let selectedCompany = response.data.data;
          if (selectedCompany?.governance) {
            selectedCompany = JSON.parse(selectedCompany.governance);
          }
          setChairmanUsersOptions(
            selectedCompany.map((item) => {
              return { label: item.name, value: item.name };
            })
          );
          let isChairman = selectedCompany.find(
            (item) => item.role === "Chairman"
          );
          if (isChairman) {
            setValue("chairman", isChairman);
          }
        }
      } catch (error) {
        setCompanies_data_loading(false);
      }
    };
    getAllCompanies();
  }, []);

  useEffect(() => {
    if (event.book_closing_from || event.book_closing_to) {
      setValue("book_closure", true);
    }

    if (event.chairman || event.event_location || event.online_conference) {
      setValue("meeting_required", true);
    }

    if (event?.req_code) {
      setValue("request_code", event.req_code);
    }
  }, [event]);

  const [allCompaniesData, setAllCompaniesData] = useState([]);
  const [chairmanUsersOptions, setChairmanUsersOptions] = useState([]);
  const handleAlertMessage = async (data) => {};
  return (
    <div>
      <form onSubmit={handleSubmit(handleAlertMessage)}>
        <div className="row">
          <div className="col-sm-12 col-md-6 col-lg-4">
            <div className="card ">
              <div className="card-header b-t-primary">
                <h5>Event</h5>
              </div>
              <div className="card-body">
                {/* <div className="form-group mt-3">
                  <label htmlFor="company_code">Company</label>
                  <input
                    name="company_code"
                    className={`form-control`}
                    type="text"
                    {...register("company_code")}
                    readOnly
                  />
                </div> */}

                <div className="form-group mt-3">
                  <label>Requirement</label>
                  <input
                    name="request_code"
                    className={`form-control`}
                    type="text"
                    {...register("request_code")}
                    readOnly
                  />
                </div>
                <div className="form-group mt-3">
                  <label>Title</label>
                  <input
                    name="title"
                    className={`form-control`}
                    type="text"
                    {...register("title")}
                    readOnly
                  />
                </div>

                <div className="form-group mt-3">
                  <label>Comment </label>
                  <input
                    name="comment"
                    className={`form-control`}
                    type="text"
                    {...register("comment")}
                    readOnly
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="col-sm-12 col-md-6 col-lg-4">
            <div className="card ">
              <div className="card-header b-t-primary">
                <h5>Dates</h5>
              </div>

              <div className="card-body">
                <div className="form-group mt-3">
                  {/* <label htmlFor="agm_date">AGM Date and Start Time</label> */}
                  {/* <label htmlFor="start_date">Meeting Duration</label> */}
                  <label htmlFor="start_date">Event Duration</label>

                  <div className="d-flex">
                    <div className="col-md-6 col-sm-12 p-0">
                      <input
                        name="start_date"
                        className={`form-control`}
                        value={
                          watch("start_date") !== "" &&
                          watch("start_date") !== undefined
                            ? moment(watch("start_date"))?.format("DD-MMM-YYYY")
                            : ""
                        }
                        type="text"
                        {...register("start_date")}
                        readOnly
                      />
                    </div>
                    <div className=" col-md-6 col-sm-12 p-0">
                      <input
                        name="deadline_date"
                        className={`form-control`}
                        value={
                          watch("deadline_date") !== "" &&
                          watch("deadline_date") !== undefined
                            ? moment(watch("deadline_date"))?.format(
                                "DD-MMM-YYYY"
                              )
                            : ""
                        }
                        type="text"
                        {...register("deadline_date")}
                        readOnly
                      />
                    </div>
                  </div>
                </div>

                <div className="form-group mt-3">
                  <label>Reminder Before</label>
                  <input
                    name="reminder_days"
                    className={`form-control`}
                    type="text"
                    {...register("reminder_days")}
                    readOnly
                  />
                </div>

                <div className="form-group mt-3">
                  <div className="d-flex justify-content-start">
                    <div>
                      <input
                        className={"form-check-input"}
                        type="checkbox"
                        id="book_closure"
                        name="book_closure"
                        checked={watch("book_closure")}
                        {...register("book_closure")}
                        readOnly
                        disabled
                      />
                      <label
                        className="form-check-label ml-2 "
                        for="book_closure"
                      >
                        Book Closure Period
                      </label>
                    </div>
                  </div>
                </div>

                {watch("book_closure") && (
                  <div className="form-group mt-3  ">
                    <div className="d-flex">
                      <div className="col-md-6 col-sm-12 p-0">
                        <input
                          name="book_closing_from"
                          className={`form-control`}
                          type="date"
                          {...register("book_closing_from")}
                          readOnly
                        />
                      </div>
                      <div className=" col-md-6 col-sm-12 p-0">
                        <input
                          name="book_closing_to"
                          className={`form-control`}
                          type="date"
                          {...register("book_closing_to")}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="col-sm-12 col-md-6 col-lg-4">
            <div className="card ">
              <div className="card-header b-t-primary">
                <div className="d-flex">
                  <input
                    className={"form-check-input"}
                    style={{ marginRight: "5px" }}
                    type="checkbox"
                    id="meeting_required"
                    name="meeting_required"
                    checked={watch("meeting_required")}
                    {...register("meeting_required")}
                    readOnly
                    disabled
                  />
                  <h5>Meeting</h5>
                </div>
              </div>

              {watch("meeting_required") && (
                <div className="card-body">
                  <div className="form-group mt-3">
                    <label>Meeting Chairman</label>
                    <input
                      name="chairman"
                      className={`form-control`}
                      type="text"
                      {...register("chairman")}
                      readOnly
                    />
                  </div>

                  <div className="form-group mt-3">
                    <label>Meeting Location</label>
                    <input
                      name="event_location"
                      className={`form-control`}
                      type="text"
                      {...register("event_location")}
                      readOnly
                    />
                  </div>

                  <div className="form-group mt-3">
                    <label htmlFor="term">Meeting Online link</label>
                    <input
                      name="online_conference"
                      className={`form-control`}
                      type="text"
                      {...register("online_conference")}
                      readOnly
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
