import React, { useState, useEffect } from 'react'
import { PDFExport } from "@progress/kendo-react-pdf";
import PageTemplate from "./page-template";
import {
    getCompanies,
} from "../../../store/services/company.service";
import styled from 'styled-components';
import { getShareHolderHistoryByCompanyandDate, getShareHoldersByCompany } from 'store/services/shareholder.service';
import moment from "moment";
import { getTransactionsByCompanyCode } from 'store/services/transaction.service';
import { getShareCertificatesByCompany } from 'store/services/shareCertificate.service';
import Spinner from "components/common/spinner";

export const StatutoryForm3A = ({ data, formTemplate = false }) => {
    const pdfExportComponent = React.useRef(null);
    const [CompanyData, setCompanyData] = useState(null)
    const [loading, setLoading] = useState(false);
    const [fontFamilyForStaticData, setFontFamilyForStaticData] = useState('Montserrat, sans-serif')
    const [fontSizeForStaticData, setFontSizeForStaticData] = useState('14px')
    const [fontfamilyforDynimicData, setFontFamilyForDynmicData] = useState('Segoe Print')
    const [fontColor, setFontColor] = useState('')
    const baseEmail = sessionStorage.getItem("email") || "";
    const [governance,setGovernance]=useState({})
    const [changeShareHolderData,setChangeShareHolderData]=useState([])
    const [allShareHolders,setAllShareHolders]=useState([])
    const [allTxn,setAllTxn]=useState([])
    const [changeHolding,setChangeHolding]=useState([])
    const getAllCompanies = async () => {
        setLoading(true)
        try {
            const response = await getCompanies(baseEmail);
            let shareHoldersData=[]
            let  shareHoldersDataPrevious=[]
            let changeHoldingData=[]
            let txns=[]
            let previous_date = new Date(data?.start_date);
            let TransfereeData=[]
            setAllShareHolders([])
            previous_date.setDate(previous_date.getDate() - 1)
            const response_history=await getShareHolderHistoryByCompanyandDate(baseEmail,data?.company_code,data?.start_date)
            const response_history_previous=await getShareHolderHistoryByCompanyandDate(baseEmail,data?.company_code,moment(previous_date).format("YYYY-MM-DD"))
            const response_shareHolder=await getShareHoldersByCompany(baseEmail,data?.company_code)
            const res_txn=await getTransactionsByCompanyCode(baseEmail,data?.company_code,data?.start_date)
            if(res_txn.data.status == 200)
            {
                txns=res_txn.data.data.filter(item=>item.folio_number != `${data?.company_code}-0` && (item.txn_type == "TOS" ||
                item.txn_type == "CPH" ||
                item.txn_type == "ETOS" ||
                item.txn_type == "ETRS" ||
                item.txn_type == "CEL" ||
                item.txn_type == "TRS" ||
                item.txn_type == "ISH"))
                setAllTxn(txns)
                
            }
            if(response_shareHolder.data.status)
            {
                setAllShareHolders(response_shareHolder.data.data)

            }
            if (response.status === 200) {
                response.data.data = response.data.data.filter((item)=>item?.active==='Y')
                const parents = response.data.data;
                const company = parents?.find(ite => ite?.code == data?.company_code)
                let Allperson =company.governance && company.governance!=""?JSON.parse(company.governance) :[]
                let sec=Allperson.find(x=> x.role == "Company Secretary" && x?.active?.toLowerCase() != 'n')
                let ceo= Allperson.find(x=>x.role == "CEO" && x?.active?.toLowerCase() != 'n')
                
                if(sec)
                {
                    setGovernance(sec)
                }else if(ceo)
                {
                    setGovernance(ceo)
                }
                
                setCompanyData(company)
                

            }
            if(response_history.data.status == 200)
            {
                shareHoldersData = JSON.parse(
                    response_history.data.data[response_history.data.data.length - 1].shareholders
                  );

                  
                  if(response_history_previous.data.status == 200)
                  {
                    shareHoldersDataPrevious=JSON.parse(
                        response_history_previous.data.data[response_history_previous.data.data.length - 1].shareholders
                      );

                  }
                  for(let i=0;i<shareHoldersData.length;i++)
                  {
                    let find=shareHoldersDataPrevious.find(item=>item.folio_number == shareHoldersData[i].folio_number)
                    if(find)
                    {
                        let sum=parseFloat(shareHoldersData[i].physical_shares || "0")+parseFloat(shareHoldersData[i].electronic_shares)
                        let sumPrevious=parseFloat(find.physical_shares || "0")+parseFloat(find.electronic_shares)
                        if(sumPrevious>sum)
                        {
                            let percentage=((sumPrevious-sum)/sumPrevious)*100
                            if(percentage>25)
                            {
                                changeHoldingData.push(shareHoldersData[i])
                            }


                        }else if(sumPrevious<sum){
                            let percentage=((sum-sumPrevious)/sum)*100
                            if(percentage>25)
                            {
                                changeHoldingData.push(shareHoldersData[i])
                            }


                        }

                    }
                  }
                  changeHoldingData=changeHoldingData.filter(item=>item.folio_number != `${data?.company_code}-0`)
                  setChangeHolding(changeHoldingData)

                  for(let k=0;k<changeHoldingData.length;k++)
                  {
                    let find=txns.find(item=>item.folio_number == changeHoldingData[k].folio_number &&  changeHoldingData[k].txn_type != "VTD"  )
                    if(find)
                    {
                        if (
                            find.txn_type == "TRS" ||
                            find.txn_type == "ETRS" 
                           
                          ){
                            let certificates=JSON.parse(find.input_certificates)
                            for(let l=0;l<certificates.length;l++)
                            {
                                let check=TransfereeData.findIndex(folio=>folio.from_folio == changeHoldingData[k].folio_number && folio.to_folio == certificates[l].folio_number)
                                if(check>-1)
                                {
                                    TransfereeData[check].shares=parseFloat(TransfereeData[check].shares) + parseFloat(certificates[l].shares_count || "0")

                                }else{
                                    let temp={to_folio:certificates[l].folio_number, from_folio:changeHoldingData[k].folio_number, shares:parseFloat(certificates[l].shares_count || "0")}
                                    TransfereeData.push(temp)
                                }
                            }

                          }else
                            {
                                let temp ={to_folio:find.folio_number, from_folio:find.from_folio, shares:find.quantity}
                                TransfereeData.push(temp)

                            }

                    }
                  }
                  
            }else {
                if(response_history_previous.data.status == 200)
                  {
                    shareHoldersDataPrevious=JSON.parse(
                        response_history_previous.data.data[response_history_previous.data.data.length - 1].shareholders
                      );

                  }
                  if(res_txn.data.status == 200)
                  {
                    let txn_filter=res_txn.data.data.filter(item=>item.txn_type != "VTD")
                    for(let i=0;i<txn_filter.length;i++)
                  {
                    let holder_sender= response_shareHolder.data.data?.find(item=>item.folio_number == txn_filter[i].from_folio )
                    let find=shareHoldersDataPrevious.find(item=>item.folio_number == txn_filter[i].from_folio)
                    let holder_rec= response_shareHolder.data.data?.find(item=>item.folio_number == txn_filter[i].folio_number )
                    let find_rec=shareHoldersDataPrevious.find(item=>item.folio_number == txn_filter[i].folio_number)

                    

                    if(find && holder_sender)
                    {
                        let sum=parseFloat(holder_sender.physical_shares || "0")+parseFloat(holder_sender.electronic_shares || "0")
                        let sumPrevious=parseFloat(find.physical_shares || "0")+parseFloat(find.electronic_shares || "0")
                      
                        if(sumPrevious>sum)
                        {
                            
                            let percentage=((sumPrevious-sum)/sumPrevious)*100
                            if(percentage>25)
                            {
                                changeHoldingData.push(holder_sender)
                            }


                        }else if(sumPrevious<sum){
                            let percentage=((sum-sumPrevious)/sum)*100
                            if(percentage>25)
                            {
                                changeHoldingData.push(holder_sender)
                            }


                        }

                    } 
                     if(find_rec && holder_rec )
                    {
                        let sum=parseFloat(holder_rec.physical_shares || "0")+parseFloat(holder_rec.electronic_shares || "0")
                        let sumPrevious=parseFloat(find_rec.physical_shares || "0")+parseFloat(find_rec.electronic_shares || "0")
                     
                        if(sumPrevious>sum)
                        {
                            let percentage=((sumPrevious-sum)/sumPrevious)*100
                            if(percentage>25)
                            {
                                changeHoldingData.push(holder_rec)
                            }


                        }else if(sumPrevious<sum){
                            let percentage=((sum-sumPrevious)/sum)*100
                            if(percentage>25)
                            {
                                changeHoldingData.push(holder_rec)
                            }


                        }

                    }
                  }
                  
                  changeHoldingData=changeHoldingData.filter(item=>item.folio_number != `${data?.company_code}-0`)
                  setChangeHolding(changeHoldingData)

                  for(let k=0;k<changeHoldingData.length;k++)
                  {
                    let find=txns.find(item=>item.folio_number == changeHoldingData[k].folio_number &&  item?.txn_type != "VTD"  )

                    
                    if(find)
                    {
                    
                        if (
                            find.txn_type == "TRS" ||
                            find.txn_type == "ETRS" 
                           
                          ){
                            let certificates=JSON.parse(find.input_certificates)
                            for(let l=0;l<certificates.length;l++)
                            {
                                let check=TransfereeData.findIndex(folio=>folio.from_folio == changeHoldingData[k].folio_number && folio.to_folio == certificates[l].folio_number)
                                if(check>-1)
                                {
                                    TransfereeData[check].shares=parseFloat(TransfereeData[check].shares) + parseFloat(certificates[l].shares_count || "0")

                                }else{
                                    let temp={to_folio:certificates[l].folio_number, from_folio:changeHoldingData[k].folio_number, shares:parseFloat(certificates[l].shares_count || "0")}
                                    TransfereeData.push(temp)
                                }
                            }

                          }else
                            {
                                let temp ={to_folio:find.folio_number, from_folio:find.from_folio, shares:find.quantity}
                                TransfereeData.push(temp)

                            }

                    }
                    
                  }

                  }
                  
                
                
            }
            // changeHoldingData=changeHoldingData.filter(item=>item.folio_number != `${data?.company_code}-0`)
            setChangeShareHolderData(TransfereeData)
            // setTimeout(() => {
            //     setLoading(false)
            // }, 1000);
            setLoading(false)
            
        } catch (error) {

            setLoading(false)
        }
    };
    useEffect(() => {
        getAllCompanies()
    }, [])
    return (
        <div>
            <div
          style={{fontFamily:"sans-serif "}}
        >
{formTemplate ?  <PDFExport
                paperSize="A4"
                margin="1cm"
                scale={0.6}
                fileName={`Statutory Requirment Form 3A`}
                pageTemplate={PageTemplate}
                ref={pdfExportComponent}
            >
                   <div style={{fontFamily: `${fontFamilyForStaticData}`, fontSize: `${fontSizeForStaticData}`, fontWeight: 500}}>
                <FormName>
                    Form 3A
                </FormName>
                <div className='row'>
                    <div className='col-md-12' >
                        <HeadingWrapper className='d-flex justify-content-center'>
                            THE COMPANIES ACT, 2017
                        </HeadingWrapper>

                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12'>
                        <HeadingWrapper className='d-flex justify-content-center'>
                            THE COMPANIES (GENERAL PROVISIONS AND FORMS) REGULATIONS, 2018
                        </HeadingWrapper>

                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12'>
                        <div style={{ fontSize: '15px', fontWeight: 'bold' }} className='d-flex justify-content-center'>
                            [Section 465 (4) and Regulations 4 & 14]
                        </div>

                    </div>
                </div>

                <div className='row mt-4'>
                    <div className='col-md-12'>
                        <HeadingWrapper className='d-flex justify-content-center'>
                            CHANGE OF MORE THAN TWENTY FIVE PERCENT IN SHAREHOLDING OR
                        </HeadingWrapper>

                    </div>
                </div>
                <div className='row mt-4'>
                    <div className='col-md-12'>
                        <HeadingWrapper className='d-flex justify-content-center'>
                            MEMBERSHIP OR VOTING RIGHTS
                        </HeadingWrapper>

                    </div>
                </div>
                <PartsWrapper className='mt-4 mb-3'>
                    Part I
                </PartsWrapper>
                <div className='row'>
                    <div className='col-md-12' style={{ padding: '25px 50px', wordWrap: 'break-word' }}>
                        <div style={{ border: '1px dashed #dddcdc', paddingLeft: '10px' }}> (Please complete in typescript or in bold block capitals.)</div>


                        <div className='d-flex' >
                            <div className='align-items-center' style={{ border: '1px dashed #dddcdc', paddingLeft: '2px', width: '7%' }}>1.1</div>
                            <div className='align-items-center' style={{ border: '1px dashed #dddcdc', width: '28%', padding: ' 5px 10px' }}>CUIN (Registration Number)  </div>
                            <div className='align-items-center  d-flex'  >{
                                '1234 552'.split("")?.map((item, idx) => {
                                    if (item == ' ') return
                                    return <div style={{ minWidth: '30px', minHeight: '34px', marginLeft: '2px', border: '1px solid #121212', borderRadius: '3px', padding: '6px 9px' }}></div>
                                })
                            }
                            </div>
                        </div>


                        <div className='d-flex mt-2'>
                            <div className='align-items-center' style={{ border: '1px dashed #dddcdc', paddingLeft: '2px', width: '7%' }}>1.2</div>
                            <div className='align-items-center' style={{ border: '1px dashed #dddcdc', width: '28%', padding: ' 5px 10px' }}>Name of the Company  </div>
                            <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '65%', padding: ' 5px 10px' }}> </ScrollWrapper>
                        </div>




                        <div className='d-flex  mt-2'>
                            <div className='align-items-center' style={{ border: '1px dashed #dddcdc', paddingLeft: '2px', width: '7%' }}>1.3</div>
                            <div className='align-items-center' style={{ border: '1px dashed #dddcdc', width: '20.20%', paddingLeft: '20px' }}>Fee Payment Details</div>
                            <div className='align-items-center' style={{ border: '1px dashed #dddcdc', paddingLeft: '2px', width: '5%' }}>1.3.1</div>
                            <div className='align-items-center' style={{ border: '1px dashed #dddcdc', width: '13.20%', paddingLeft: '20px' }}>Challan No.</div>
                            <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '17.20%', paddingLeft: '20px', minHeight: '34px' }}></ScrollWrapper>
                            <div className='align-items-center' style={{ border: '1px dashed #dddcdc', paddingLeft: '2px', width: '5%' }}>1.3.3</div>
                            <div className='align-items-center' style={{ border: '1px dashed #dddcdc', width: '13.20%', paddingLeft: '20px' }}>Amount</div>
                            <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '19.20%', paddingLeft: '20px', minHeight: '34px' }}></ScrollWrapper>
                        </div>
                        <PartsWrapper className='mt-4 mb-3'>
                            Part II
                        </PartsWrapper>


                        <div className='d-flex mt-2'>
                            <div className='align-items-center' style={{ border: '1px dashed #dddcdc', paddingLeft: '2px', width: '7%' }}>2.1</div>
                            <div className='align-items-center' style={{ border: '1px dashed #dddcdc', width: '93%', padding: ' 5px 10px', fontSize: '14px', fontWeight: 'bold' }}>Change in shareholding </div>
                            {/* <div className='align-items-center' style={{ border: '1px solid #121212', width: '28%', padding: ' 5px 10px' }}> </div> */}
                        </div>

                        <div className='d-flex mt-2'>
                            <div className='align-items-center' style={{ border: '1px dashed #dddcdc', paddingLeft: '2px', width: '7%' }}></div>
                            <div className='align-items-center' style={{ border: '1px dashed #dddcdc', paddingLeft: '2px', width: '7%' }}>2.1.1</div>
                            <div className='align-items-center' style={{ border: '1px dashed #dddcdc', width: '58%', padding: ' 5px 10px' }}>Total Number of paid up shares  </div>
                            <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '28%', padding: ' 5px 10px' }}> </ScrollWrapper>
                        </div>

                        <div className='d-flex mt-2'>
                            <div className='align-items-center' style={{ border: '1px dashed #dddcdc', paddingLeft: '2px', width: '7%' }}></div>
                            <div className='align-items-center' style={{ border: '1px dashed #dddcdc', paddingLeft: '2px', width: '7%' }}>2.1.2</div>
                            <div className='align-items-center' style={{ border: '1px dashed #dddcdc', width: '86%', padding: ' 5px 10px' }}>Particulars of change in shareholding  </div>
                            {/* <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '28%', padding: ' 5px 10px' }}> </ScrollWrapper> */}
                        </div>
                        <Wrapper className="table-responsive mt-2" >
                            <table
                                className="table table-bordered"
                                style={{ fontSize: "10px", fontFamily: "Montserrat rev=1" }}
                            >
                                <thead

                                >
                                    <th style={{ verticalAlign: 'sub', border: '1px solid #121212' }}>
                                        Name of Transferor
                                    </th>
                                    <th style={{ verticalAlign: 'sub', border: '1px solid #121212' }} >
                                        CNIC/ Passport No. of Transferor, if applicable
                                    </th>
                                    <th style={{ verticalAlign: 'sub', border: '1px solid #121212' }} >
                                        Name of Transferee/Allottee
                                    </th>
                                    <th style={{ verticalAlign: 'sub', border: '1px solid #121212' }}>
                                        CNIC/ Passport No. of Transferee/Allottee, if applicable
                                    </th>
                                    <th style={{ verticalAlign: 'sub', border: '1px solid #121212' }} >
                                        Address of Transferee/ Allottee
                                    </th>
                                    <th style={{ verticalAlign: 'sub', border: '1px solid #121212' }} >
                                        No of shares transferred/ Allotted
                                    </th>
                                    <th
                                        style={{ verticalAlign: 'sub', border: '1px solid #121212' }}

                                    >
                                        Kind / Class of Shares
                                    </th>
                                    <th
                                        style={{ verticalAlign: 'sub', border: '1px solid #121212' }}

                                    >
                                        Date of transfer/ Allotment
                                    </th>

                                </thead>
                                <tbody>

                                



                                    <tr >
                                        <td style={{ verticalAlign: 'sub', minHeight: '24px' }}></td>
                                        <td style={{ verticalAlign: 'sub', minHeight: '24px' }}></td>
                                        <td style={{ verticalAlign: 'sub', minHeight: '24px' }}> </td>
                                        <td style={{ verticalAlign: 'sub', minHeight: '24px' }}></td>
                                        <td style={{ verticalAlign: 'sub', minHeight: '24px' }}></td>
                                        <td style={{ verticalAlign: 'sub', minHeight: '24px' }}></td>
                                        <td style={{ verticalAlign: 'sub', minHeight: '24px' }}></td>
                                        <td style={{ verticalAlign: 'sub', minHeight: '24px' }}></td>

                                    </tr>
                                    <tr >
                                        <td style={{ verticalAlign: 'sub', minHeight: '24px' }}></td>

                                        <td style={{ verticalAlign: 'sub', minHeight: '24px' }}></td>
                                        <td style={{ verticalAlign: 'sub', minHeight: '24px' }}></td>
                                        <td style={{ verticalAlign: 'sub', minHeight: '24px' }}></td>
                                        <td style={{ verticalAlign: 'sub', minHeight: '24px' }}></td>
                                        <td style={{ verticalAlign: 'sub', minHeight: '24px' }}> </td>
                                        <td style={{ verticalAlign: 'sub', minHeight: '24px' }}></td>
                                        <td style={{ verticalAlign: 'sub', minHeight: '24px' }}></td>
                                    </tr>




                                </tbody>

                            </table>

                        </Wrapper>

                        <div className='d-flex mt-2'>
                            <div className='align-items-center' style={{ border: '1px dashed #dddcdc', paddingLeft: '2px', width: '7%' }}>2.2</div>
                            <div className='align-items-center' style={{ border: '1px dashed #dddcdc', width: '93%', padding: ' 5px 10px', fontSize: '14px', fontWeight: 'bold' }}>Change in voting right </div>
                            {/* <div className='align-items-center' style={{ border: '1px solid #121212', width: '28%', padding: ' 5px 10px' }}> </div> */}
                        </div>

                        <div className='d-flex mt-2'>
                            <div className='align-items-center' style={{ border: '1px dashed #dddcdc', paddingLeft: '2px', width: '7%' }}></div>
                            <div className='align-items-center' style={{ border: '1px dashed #dddcdc', paddingLeft: '2px', width: '7%' }}>2.2.1</div>
                            <div className='align-items-center' style={{ border: '1px dashed #dddcdc', width: '58%', padding: ' 5px 10px' }}>Reason & details of change in voting rights  </div>
                            <div className='align-items-center' style={{ border: '1px solid #121212', width: '28%', padding: ' 5px 10px', minHeight: '45px' }}> </div>
                        </div>

                        <div className='d-flex mt-2'>
                            <div className='align-items-center' style={{ border: '1px dashed #dddcdc', paddingLeft: '2px', width: '7%' }}></div>
                            <div className='align-items-center' style={{ border: '1px dashed #dddcdc', paddingLeft: '2px', width: '7%' }}></div>
                            <div className='align-items-center' style={{ border: '1px dashed #dddcdc', width: '48%', padding: ' 5px 10px' }}> </div>
                            <div className='align-items-center' style={{ border: '1px dashed #dddcdc', width: '8%', padding: ' 5px 10px' }}> Day</div>
                            <div className='align-items-center' style={{ border: '1px dashed #dddcdc', paddingLeft: '2px', width: '3%' }}></div>
                            <div className='align-items-center' style={{ border: '1px dashed #dddcdc', width: '8%', padding: ' 5px 10px' }}> Month</div>
                            <div className='align-items-center' style={{ border: '1px dashed #dddcdc', paddingLeft: '2px', width: '3%' }}></div>
                            <div className='align-items-center' style={{ border: '1px dashed #dddcdc', width: '16%', padding: ' 5px 10px' }}>Year </div>
                            <div></div>
                        </div>
                        <div className='d-flex '>
                            <div className='align-items-center' style={{ border: '1px dashed #dddcdc', paddingLeft: '2px', width: '7%' }}></div>
                            <div className='align-items-center' style={{ border: '1px dashed #dddcdc', paddingLeft: '2px', width: '7%' }}>2.2.2</div>
                            <div className='align-items-center' style={{ border: '1px dashed #dddcdc', width: '48%', padding: ' 5px 10px' }}>Effective date of change in voting right</div>
                            <div className='align-items-center' style={{  width: '4%', padding: ' 5px 10px', borderLeft:'1px solid #121212', borderTop:'1px solid #121212',  borderBottom:'1px solid #121212' }}> </div>
                            <div className='align-items-center' style={{ border: '1px solid #121212', width: '4%', padding: ' 5px 10px' }}> </div>
                            <div className='align-items-center' style={{ border: '1px dashed #dddcdc', paddingLeft: '2px', width: '3%' }}></div>
                            <div className='align-items-center' style={{width: '4%', padding: ' 5px 10px', borderLeft:'1px solid #121212', borderTop:'1px solid #121212',  borderBottom:'1px solid #121212' }}> </div>
                            <div className='align-items-center' style={{ border: '1px solid #121212', width: '4%', padding: ' 5px 10px' }}> </div>
                            <div className='align-items-center' style={{ border: '1px dashed #dddcdc', paddingLeft: '2px', width: '3%' }}></div>
                            <div className='align-items-center' style={{  width: '4%', padding: ' 5px 10px', borderLeft:'1px solid #121212', borderTop:'1px solid #121212',  borderBottom:'1px solid #121212' }}> </div>
                            <div className='align-items-center' style={{  width: '4%', padding: ' 5px 10px', borderLeft:'1px solid #121212', borderTop:'1px solid #121212',  borderBottom:'1px solid #121212' }}> </div>
                            <div className='align-items-center' style={{  width: '4%', padding: ' 5px 10px' , borderLeft:'1px solid #121212', borderTop:'1px solid #121212',  borderBottom:'1px solid #121212'}}> </div>
                            <div className='align-items-center' style={{ border: '1px solid #121212', width: '4%', padding: ' 5px 10px' }}> </div>
                        </div>

                        <div className='d-flex mt-2'>
                            <div className='align-items-center' style={{ border: '1px dashed #dddcdc', paddingLeft: '2px', width: '7%' }}>2.3</div>
                            <div className='align-items-center' style={{ border: '1px dashed #dddcdc', width: '93%', padding: ' 5px 10px', fontSize: '14px', fontWeight: 'bold' }}>Change in membership </div>
                            {/* <div className='align-items-center' style={{ border: '1px solid #121212', width: '28%', padding: ' 5px 10px' }}> </div> */}
                        </div>

                        <div className='d-flex mt-2'>
                            <div className='align-items-center' style={{ border: '1px dashed #dddcdc', paddingLeft: '2px', width: '7%' }}></div>
                            <div className='align-items-center' style={{ border: '1px dashed #dddcdc', paddingLeft: '2px', width: '7%' }}>2.3.1</div>
                            <div className='align-items-center' style={{ border: '1px dashed #dddcdc', width: '58%', padding: ' 5px 10px' }}>Total Number of members prior to change  </div>
                            <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '28%', padding: ' 5px 10px' }}> </ScrollWrapper>
                        </div>

                        <div className='d-flex mt-2'>
                            <div className='align-items-center' style={{ border: '1px dashed #dddcdc', paddingLeft: '2px', width: '7%' }}></div>
                            <div className='align-items-center' style={{ border: '1px dashed #dddcdc', paddingLeft: '2px', width: '7%' }}>2.3.2</div>
                            <div className='align-items-center' style={{ border: '1px dashed #dddcdc', width: '86%', padding: ' 5px 10px' }}>Particulars of change in members  </div>
                            {/* <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '28%', padding: ' 5px 10px' }}> </ScrollWrapper> */}
                        </div>
                        <Wrapper className="table-responsive mt-2" >
                            <table
                                className="table table-bordered"
                                style={{ fontSize: "10px", fontFamily: "Montserrat rev=1" }}
                            >

                                <tbody>

                                    <tr>
                                        <td colSpan={3}>Particulars of Outgoing Member(s), if any</td>
                                        <td colSpan={3}>Particulars of New Member(s)</td>
                                    </tr>
                                    <tr >
                                        <td style={{ verticalAlign: 'sub', minHeight: '24px' }}>Name</td>
                                        <td style={{ verticalAlign: 'sub', minHeight: '24px' }}> CNIC/ Passport No.</td>
                                        <td style={{ verticalAlign: 'sub', minHeight: '24px' }}>Date of cessation</td>
                                        <td style={{ verticalAlign: 'sub', minHeight: '24px' }}>Name</td>
                                        <td style={{ verticalAlign: 'sub', minHeight: '24px' }}>CNIC/ Passport No.</td>
                                        <td style={{ verticalAlign: 'sub', minHeight: '24px' }}>Date of Admission</td>

                                    </tr>

                                    <tr >
                                        <td style={{ verticalAlign: 'sub', minHeight: '24px' }}></td>
                                        <td style={{ verticalAlign: 'sub', minHeight: '24px' }}> </td>
                                        <td style={{ verticalAlign: 'sub', minHeight: '24px' }}></td>
                                        <td style={{ verticalAlign: 'sub', minHeight: '24px' }}></td>
                                        <td style={{ verticalAlign: 'sub', minHeight: '24px' }}></td>
                                        <td style={{ verticalAlign: 'sub', minHeight: '24px' }}></td>

                                    </tr>
                                    <tr >

                                        <td style={{ verticalAlign: 'sub', minHeight: '24px' }}></td>
                                        <td style={{ verticalAlign: 'sub', minHeight: '24px' }}></td>
                                        <td style={{ verticalAlign: 'sub', minHeight: '24px' }}></td>
                                        <td style={{ verticalAlign: 'sub', minHeight: '24px' }}> </td>
                                        <td style={{ verticalAlign: 'sub', minHeight: '24px' }}></td>
                                        <td style={{ verticalAlign: 'sub', minHeight: '24px' }}></td>
                                    </tr>




                                </tbody>

                            </table>

                        </Wrapper>









                        <PartsWrapper className='mt-5 mb-3'>
                            Part III
                        </PartsWrapper>
                        <div className='d-flex  mt-4' >
                            <div className='align-items-center' style={{ border: '1px dashed #dddcdc', paddingLeft: '2px', width: '7%' }}>3.1</div>
                            <div className='align-items-center' style={{ border: '1px dashed #dddcdc', width: '93%', padding: ' 5px 10px' }}>
                                <div>Declaration</div>
                                <div className='mt-2'>I do hereby solemnly, and sincerely declare that the information provided in the form is:</div>
                                <ul className='mt-3' style={{ listStyleType: 'none', }}>
                                    <li className='mt-2 '><span style={{ fontSize: '16px' }}>(i)</span> <span style={{ paddingLeft: '10px' }}>true and correct to the best of my knowledge, in consonance with the record as maintained by the Company and nothing has been concealed; and  </span></li>
                                    <li className='mt-2 '> <span style={{ fontSize: '16px' }}>(ii)</span> <span style={{ paddingLeft: '10px' }}>hereby reported after complying with and fulfilling all requirements under the relevant provisions of law, rules, regulations, directives, circulars and notifications whichever is applicable.</span></li>

                                </ul>
                            </div>
                        </div>
                        <div className='d-flex' >
                            <div className='align-items-center' style={{ border: '1px dashed #dddcdc', paddingLeft: '2px', width: '7%' }}></div>
                            <div className='align-items-center' style={{ border: '1px dashed #dddcdc', minHeight: '70px', width: '93%', padding: ' 5px 10px' }} />


                        </div>
                        <div className='d-flex mt-2'>
                            <div className='align-items-center' style={{ border: '1px dashed #dddcdc', paddingLeft: '2px', width: '7%' }}>3.2</div>
                            <div className='align-items-center' style={{ border: '1px dashed #dddcdc', width: '44%', padding: ' 5px 10px' }}>Name of Authorized Officer with designation/ Authorized Intermediary </div>
                            <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '24.5%', padding: ' 5px 10px' }}> </ScrollWrapper>
                            <ScrollWrapper className='align-items-center' style={{ borderTop: '1px solid #121212', borderBottom: '1px solid #121212', borderRight: '1px solid #121212', width: '24.5%', padding: ' 5px 10px' }}> </ScrollWrapper>
                        </div>
                        <div className='d-flex mt-2'>
                            <div className='align-items-center' style={{ border: '1px dashed #dddcdc', paddingLeft: '2px', width: '7%' }}>3.3</div>
                            <div className='align-items-center' style={{ border: '1px dashed #dddcdc', width: '44%', padding: ' 5px 10px' }}>Signatures  </div>
                            <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '49%', padding: ' 5px 10px' }}> </ScrollWrapper>
                        </div>
                        <div className='d-flex mt-2'>
                            <div className='align-items-center' style={{ border: '1px dashed #dddcdc', paddingLeft: '2px', width: '7%' }}>3.4</div>
                            <div className='align-items-center' style={{ border: '1px dashed #dddcdc', width: '44%', padding: ' 5px 10px' }}>Registration No of Authorized Intermediary, if applicable </div>
                            <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '49%', padding: ' 5px 10px' }}> </ScrollWrapper>
                        </div>

                        <div className='d-flex mt-2'>
                            <div className='align-items-center' style={{ border: '1px dashed #dddcdc', paddingLeft: '2px', width: '7%' }}></div>
                            <div className='align-items-center' style={{ border: '1px dashed #dddcdc', width: '55%', padding: ' 5px 10px' }}> </div>
                            <div className='align-items-center' style={{ border: '1px dashed #dddcdc', width: '8%', padding: ' 5px 10px' }}> Day</div>
                            <div className='align-items-center' style={{ border: '1px dashed #dddcdc', paddingLeft: '2px', width: '3%' }}></div>
                            <div className='align-items-center' style={{ border: '1px dashed #dddcdc', width: '8%', padding: ' 5px 10px' }}> Month</div>
                            <div className='align-items-center' style={{ border: '1px dashed #dddcdc', paddingLeft: '2px', width: '3%' }}></div>
                            <div className='align-items-center' style={{ border: '1px dashed #dddcdc', width: '16%', padding: ' 5px 10px' }}>Year </div>
                            <div></div>
                        </div>
                        <div className='d-flex '>
                            <div className='align-items-center' style={{ border: '1px dashed #dddcdc', paddingLeft: '2px', width: '7%' }}>3.5</div>
                            <div className='align-items-center' style={{ border: '1px dashed #dddcdc', width: '55%', padding: ' 5px 10px' }}>Day</div>
                            <div className='align-items-center' style={{  width: '4%', padding: ' 5px 10px', borderLeft:'1px solid #121212', borderTop:'1px solid #121212',  borderBottom:'1px solid #121212' }}> </div>
                            <div className='align-items-center' style={{ border: '1px solid #121212', width: '4%', padding: ' 5px 10px' }}> </div>
                            <div className='align-items-center' style={{ border: '1px dashed #dddcdc', paddingLeft: '2px', width: '3%' }}></div>
                            <div className='align-items-center' style={{  width: '4%', padding: ' 5px 10px', borderLeft:'1px solid #121212', borderTop:'1px solid #121212',  borderBottom:'1px solid #121212' }}> </div>
                            <div className='align-items-center' style={{ border: '1px solid #121212', width: '4%', padding: ' 5px 10px' }}> </div>
                            <div className='align-items-center' style={{ border: '1px dashed #dddcdc', paddingLeft: '2px', width: '3%' }}></div>
                            <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft:'1px solid #121212', borderTop:'1px solid #121212',  borderBottom:'1px solid #121212' }}> </div>
                            <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft:'1px solid #121212', borderTop:'1px solid #121212',  borderBottom:'1px solid #121212' }}> </div>
                            <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft:'1px solid #121212', borderTop:'1px solid #121212',  borderBottom:'1px solid #121212' }}> </div>
                            <div className='align-items-center' style={{ border: '1px solid #121212', width: '4%', padding: ' 5px 10px' }}> </div>
                        </div>


                    </div>
                </div>
</div>
            </PDFExport>:
            <PDFExport
                paperSize="A4"
                margin="1cm"
                scale={0.6}
                fileName={`Statutory Requirment Form 3A`}
                pageTemplate={PageTemplate}
                ref={pdfExportComponent}
            >
             {loading == true && <Spinner />}
                  {loading == false &&  <div style={{fontFamily: `${fontFamilyForStaticData}`, fontSize: `${fontSizeForStaticData}`, fontWeight: 500}}>
                <FormName>
                    Form 3A
                </FormName>
                <div className='row'>
                    <div className='col-md-12' >
                        <HeadingWrapper className='d-flex justify-content-center'>
                            THE COMPANIES ACT, 2017
                        </HeadingWrapper>

                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12'>
                        <HeadingWrapper className='d-flex justify-content-center'>
                            THE COMPANIES (GENERAL PROVISIONS AND FORMS) REGULATIONS, 2018
                        </HeadingWrapper>

                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12'>
                        <div style={{ fontSize: '15px', fontWeight: 'bold' }} className='d-flex justify-content-center'>
                            [Section 465 (4) and Regulations 4 & 14]
                        </div>

                    </div>
                </div>

                <div className='row mt-4'>
                    <div className='col-md-12'>
                        <HeadingWrapper className='d-flex justify-content-center'>
                            CHANGE OF MORE THAN TWENTY FIVE PERCENT IN SHAREHOLDING OR
                        </HeadingWrapper>

                    </div>
                </div>
                <div className='row mt-4'>
                    <div className='col-md-12'>
                        <HeadingWrapper className='d-flex justify-content-center'>
                            MEMBERSHIP OR VOTING RIGHTS
                        </HeadingWrapper>

                    </div>
                </div>
                <PartsWrapper className='mt-4 mb-3'>
                    Part I
                </PartsWrapper>
                <div className='row'>
                    <div className='col-md-12' style={{ padding: '25px 50px', wordWrap: 'break-word' }}>
                        <div style={{ border: '1px dashed #dddcdc', paddingLeft: '10px' }}> (Please complete in typescript or in bold block capitals.)</div>


                        <div className='d-flex' >
                            <div className='align-items-center' style={{ border: '1px dashed #dddcdc', paddingLeft: '2px', width: '7%' }}>1.1</div>
                            <div className='align-items-center' style={{ border: '1px dashed #dddcdc', width: '28%', padding: ' 5px 10px' }}>CUIN (Registration Number)  </div>
                            <div className='align-items-center  d-flex'  >{
                                CompanyData?.incorporation_no?.padStart(7,0)?.split("")?.map((item, idx) => {
                                    if (item == ' ') return
                                    return <div style={{ minWidth: '30px', minHeight: '34px', marginLeft: '2px', border: '1px solid #121212', borderRadius: '3px', padding: '6px 9px', fontFamily:fontfamilyforDynimicData }}>{item}</div>
                                })
                            }
                            </div>
                        </div>


                        <div className='d-flex mt-2'>
                            <div className='align-items-center' style={{ border: '1px dashed #dddcdc', paddingLeft: '2px', width: '7%' }}>1.2</div>
                            <div className='align-items-center' style={{ border: '1px dashed #dddcdc', width: '28%', padding: ' 5px 10px' }}>Name of the Company  </div>
                            <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '65%', padding: ' 5px 10px'  , fontFamily: `${fontfamilyforDynimicData}`}}>{CompanyData?.company_name || ''} </ScrollWrapper>
                        </div>




                        <div className='d-flex  mt-2'>
                            <div className='align-items-center' style={{ border: '1px dashed #dddcdc', paddingLeft: '2px', width: '7%' }}>1.3</div>
                            <div className='align-items-center' style={{ border: '1px dashed #dddcdc', width: '20.20%', paddingLeft: '20px' }}>Fee Payment Details</div>
                            <div className='align-items-center' style={{ border: '1px dashed #dddcdc', paddingLeft: '2px', width: '5%' }}>1.3.1</div>
                            <div className='align-items-center' style={{ border: '1px dashed #dddcdc', width: '13.20%', paddingLeft: '20px' }}>Challan No.</div>
                            <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '17.20%', paddingLeft: '20px', minHeight: '34px' }}></ScrollWrapper>
                            <div className='align-items-center' style={{ border: '1px dashed #dddcdc', paddingLeft: '2px', width: '5%' }}>1.3.3</div>
                            <div className='align-items-center' style={{ border: '1px dashed #dddcdc', width: '13.20%', paddingLeft: '20px' }}>Amount</div>
                            <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '19.20%', paddingLeft: '20px', minHeight: '34px' }}></ScrollWrapper>
                        </div>
                        <PartsWrapper className='mt-4 mb-3'>
                            Part II
                        </PartsWrapper>


                        <div className='d-flex mt-2'>
                            <div className='align-items-center' style={{ border: '1px dashed #dddcdc', paddingLeft: '2px', width: '7%' }}>2.1</div>
                            <div className='align-items-center' style={{ border: '1px dashed #dddcdc', width: '93%', padding: ' 5px 10px', fontSize: '14px', fontWeight: 'bold' }}>Change in shareholding </div>
                            {/* <div className='align-items-center' style={{ border: '1px solid #121212', width: '28%', padding: ' 5px 10px' }}> </div> */}
                        </div>

                        <div className='d-flex mt-2'>
                            <div className='align-items-center' style={{ border: '1px dashed #dddcdc', paddingLeft: '2px', width: '7%' }}></div>
                            <div className='align-items-center' style={{ border: '1px dashed #dddcdc', paddingLeft: '2px', width: '7%' }}>2.1.1</div>
                            <div className='align-items-center' style={{ border: '1px dashed #dddcdc', width: '58%', padding: ' 5px 10px' }}>Total Number of paid up shares  </div>
                            <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '28%', padding: ' 5px 10px' }}> </ScrollWrapper>
                        </div>

                        <div className='d-flex mt-2'>
                            <div className='align-items-center' style={{ border: '1px dashed #dddcdc', paddingLeft: '2px', width: '7%' }}></div>
                            <div className='align-items-center' style={{ border: '1px dashed #dddcdc', paddingLeft: '2px', width: '7%' }}>2.1.2</div>
                            <div className='align-items-center' style={{ border: '1px dashed #dddcdc', width: '86%', padding: ' 5px 10px' }}>Particulars of change in shareholding  </div>
                            {/* <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '28%', padding: ' 5px 10px' }}> </ScrollWrapper> */}
                        </div>
                        <Wrapper className="table-responsive mt-2" >
                            <table
                                className="table table-bordered"
                                style={{ fontSize: "10px", fontFamily: "Montserrat rev=1" }}
                            >
                                <thead

                                >
                                    <th style={{ verticalAlign: 'sub', border: '1px solid #121212' }}>
                                        Name of Transferor
                                    </th>
                                    <th style={{ verticalAlign: 'sub', border: '1px solid #121212' }} >
                                        CNIC/ Passport No. of Transferor, if applicable
                                    </th>
                                    <th style={{ verticalAlign: 'sub', border: '1px solid #121212' }} >
                                        Name of Transferee/Allottee
                                    </th>
                                    <th style={{ verticalAlign: 'sub', border: '1px solid #121212' }}>
                                        CNIC/ Passport No. of Transferee/Allottee, if applicable
                                    </th>
                                    <th style={{ verticalAlign: 'sub', border: '1px solid #121212' }} >
                                        Address of Transferee/ Allottee
                                    </th>
                                    <th style={{ verticalAlign: 'sub', border: '1px solid #121212' }} >
                                        No of shares transferred/ Allotted
                                    </th>
                                    <th
                                        style={{ verticalAlign: 'sub', border: '1px solid #121212' }}

                                    >
                                        Kind / Class of Shares
                                    </th>
                                    <th
                                        style={{ verticalAlign: 'sub', border: '1px solid #121212' }}

                                    >
                                        Date of transfer/ Allotment
                                    </th>

                                </thead>
                                <tbody>
                                {changeShareHolderData?.length > 0 && changeShareHolderData.map((item, index)=>{
                                    let find1=allShareHolders.find(item2=>item2.folio_number == item.from_folio )
                                    let find2=allShareHolders.find(item2=>item2.folio_number == item.to_folio )
                                    
                                    return(
                                        <tr key={index} >
                                        <td style={{ verticalAlign: 'sub', minHeight: '24px', fontFamily:fontfamilyforDynimicData }}>{find1?.shareholder_name}</td>
                                        <td style={{ verticalAlign: 'sub', minHeight: '24px', fontFamily:fontfamilyforDynimicData }}>{find1?.cnic ? find1?.cnic : find1?.passport_no }</td>
                                        <td style={{ verticalAlign: 'sub', minHeight: '24px', fontFamily:fontfamilyforDynimicData }}>{find2?.shareholder_name} </td>
                                        <td style={{ verticalAlign: 'sub', minHeight: '24px', fontFamily:fontfamilyforDynimicData }}>{find2?.cnic ? find2?.cnic : find2?.passport_no }</td>
                                        <td style={{ verticalAlign: 'sub', minHeight: '24px', fontFamily:fontfamilyforDynimicData }}>{find2?.street_address}</td>
                                        <td style={{ verticalAlign: 'sub', minHeight: '24px', fontFamily:fontfamilyforDynimicData }}>{item.shares}</td>
                                        <td style={{ verticalAlign: 'sub', minHeight: '24px', fontFamily:fontfamilyforDynimicData }}></td>
                                        <td style={{ verticalAlign: 'sub', minHeight: '24px', fontFamily:fontfamilyforDynimicData }}>{data?.start_date}</td>

                                    </tr>

                                    )

                                })}

                                {changeShareHolderData?.length == 0 && ['1','2'].map((item,index)=>{
                                    return(
                                        <tr key={index} >
                                        <td style={{ verticalAlign: 'sub', minHeight: '24px' }}></td>
                                        <td style={{ verticalAlign: 'sub', minHeight: '24px' }}></td>
                                        <td style={{ verticalAlign: 'sub', minHeight: '24px' }}> </td>
                                        <td style={{ verticalAlign: 'sub', minHeight: '24px' }}></td>
                                        <td style={{ verticalAlign: 'sub', minHeight: '24px' }}></td>
                                        <td style={{ verticalAlign: 'sub', minHeight: '24px' }}></td>
                                        <td style={{ verticalAlign: 'sub', minHeight: '24px' }}></td>
                                        <td style={{ verticalAlign: 'sub', minHeight: '24px' }}></td>

                                    </tr>

                                    )
                                })}



                                   
                                    




                                </tbody>

                            </table>

                        </Wrapper>

                        <div className='d-flex mt-2'>
                            <div className='align-items-center' style={{ border: '1px dashed #dddcdc', paddingLeft: '2px', width: '7%' }}>2.2</div>
                            <div className='align-items-center' style={{ border: '1px dashed #dddcdc', width: '93%', padding: ' 5px 10px', fontSize: '14px', fontWeight: 'bold' }}>Change in voting right </div>
                            {/* <div className='align-items-center' style={{ border: '1px solid #121212', width: '28%', padding: ' 5px 10px' }}> </div> */}
                        </div>

                        <div className='d-flex mt-2'>
                            <div className='align-items-center' style={{ border: '1px dashed #dddcdc', paddingLeft: '2px', width: '7%' }}></div>
                            <div className='align-items-center' style={{ border: '1px dashed #dddcdc', paddingLeft: '2px', width: '7%' }}>2.2.1</div>
                            <div className='align-items-center' style={{ border: '1px dashed #dddcdc', width: '58%', padding: ' 5px 10px' }}>Reason & details of change in voting rights  </div>
                            <div className='align-items-center' style={{ border: '1px solid #121212', width: '28%', padding: ' 5px 10px', minHeight: '45px' }}> </div>
                        </div>

                        <div className='d-flex mt-2'>
                            <div className='align-items-center' style={{ border: '1px dashed #dddcdc', paddingLeft: '2px', width: '7%' }}></div>
                            <div className='align-items-center' style={{ border: '1px dashed #dddcdc', paddingLeft: '2px', width: '7%' }}></div>
                            <div className='align-items-center' style={{ border: '1px dashed #dddcdc', width: '48%', padding: ' 5px 10px' }}> </div>
                            <div className='align-items-center' style={{ border: '1px dashed #dddcdc', width: '8%', padding: ' 5px 10px' }}> Day</div>
                            <div className='align-items-center' style={{ border: '1px dashed #dddcdc', paddingLeft: '2px', width: '3%' }}></div>
                            <div className='align-items-center' style={{ border: '1px dashed #dddcdc', width: '8%', padding: ' 5px 10px' }}> Month</div>
                            <div className='align-items-center' style={{ border: '1px dashed #dddcdc', paddingLeft: '2px', width: '3%' }}></div>
                            <div className='align-items-center' style={{ border: '1px dashed #dddcdc', width: '16%', padding: ' 5px 10px' }}>Year </div>
                            <div></div>
                        </div>
                        <div className='d-flex '>
                            <div className='align-items-center' style={{ border: '1px dashed #dddcdc', paddingLeft: '2px', width: '7%' }}></div>
                            <div className='align-items-center' style={{ border: '1px dashed #dddcdc', paddingLeft: '2px', width: '7%' }}>2.2.2</div>
                            <div className='align-items-center' style={{ border: '1px dashed #dddcdc', width: '48%', padding: ' 5px 10px' }}>Effective date of change in voting right</div>
                            <div className='align-items-center' style={{  width: '4%', padding: ' 5px 10px', borderLeft:'1px solid #121212', borderTop:'1px solid #121212',  borderBottom:'1px solid #121212',fontFamily:fontfamilyforDynimicData }}>{data?.start_date.split("-")[2].charAt(0)} </div>
                            <div className='align-items-center' style={{ border: '1px solid #121212', width: '4%', padding: ' 5px 10px', fontFamily:fontfamilyforDynimicData }}>{data?.start_date.split("-")[2].charAt(1)} </div>
                            <div className='align-items-center' style={{ border: '1px dashed #dddcdc', paddingLeft: '2px', width: '3%' }}></div>
                            <div className='align-items-center' style={{width: '4%', padding: ' 5px 10px', borderLeft:'1px solid #121212', borderTop:'1px solid #121212',  borderBottom:'1px solid #121212',fontFamily:fontfamilyforDynimicData }}>{data?.start_date.split("-")[1].charAt(0)} </div>
                            <div className='align-items-center' style={{ border: '1px solid #121212', width: '4%', padding: ' 5px 10px',fontFamily:fontfamilyforDynimicData }}>{data?.start_date.split("-")[1].charAt(1)} </div>
                            <div className='align-items-center' style={{ border: '1px dashed #dddcdc', paddingLeft: '2px', width: '3%' }}></div>
                            <div className='align-items-center' style={{  width: '4%', padding: ' 5px 10px', borderLeft:'1px solid #121212', borderTop:'1px solid #121212',  borderBottom:'1px solid #121212',fontFamily:fontfamilyforDynimicData }}>{data?.start_date.split("-")[0].charAt(0)} </div>
                            <div className='align-items-center' style={{  width: '4%', padding: ' 5px 10px', borderLeft:'1px solid #121212', borderTop:'1px solid #121212',  borderBottom:'1px solid #121212',fontFamily:fontfamilyforDynimicData }}>{data?.start_date.split("-")[0].charAt(1)} </div>
                            <div className='align-items-center' style={{  width: '4%', padding: ' 5px 10px' , borderLeft:'1px solid #121212', borderTop:'1px solid #121212',  borderBottom:'1px solid #121212',fontFamily:fontfamilyforDynimicData}}>{data?.start_date.split("-")[0].charAt(2)} </div>
                            <div className='align-items-center' style={{ border: '1px solid #121212', width: '4%', padding: ' 5px 10px',fontFamily:fontfamilyforDynimicData }}>{data?.start_date.split("-")[0].charAt(3)} </div>
                        </div>


                        <div className='d-flex mt-2'>
                            <div className='align-items-center' style={{ border: '1px dashed #dddcdc', paddingLeft: '2px', width: '7%' }}>2.3</div>
                            <div className='align-items-center' style={{ border: '1px dashed #dddcdc', width: '93%', padding: ' 5px 10px', fontSize: '14px', fontWeight: 'bold' }}>Change in membership </div>
                            {/* <div className='align-items-center' style={{ border: '1px solid #121212', width: '28%', padding: ' 5px 10px' }}> </div> */}
                        </div>

                        <div className='d-flex mt-2'>
                            <div className='align-items-center' style={{ border: '1px dashed #dddcdc', paddingLeft: '2px', width: '7%' }}></div>
                            <div className='align-items-center' style={{ border: '1px dashed #dddcdc', paddingLeft: '2px', width: '7%' }}>2.3.1</div>
                            <div className='align-items-center' style={{ border: '1px dashed #dddcdc', width: '58%', padding: ' 5px 10px' }}>Total Number of members prior to change  </div>
                            <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '28%', padding: ' 5px 10px' }}> </ScrollWrapper>
                        </div>

                        <div className='d-flex mt-2'>
                            <div className='align-items-center' style={{ border: '1px dashed #dddcdc', paddingLeft: '2px', width: '7%' }}></div>
                            <div className='align-items-center' style={{ border: '1px dashed #dddcdc', paddingLeft: '2px', width: '7%' }}>2.3.2</div>
                            <div className='align-items-center' style={{ border: '1px dashed #dddcdc', width: '86%', padding: ' 5px 10px' }}>Particulars of change in members  </div>
                            {/* <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '28%', padding: ' 5px 10px' }}> </ScrollWrapper> */}
                        </div>
                        <Wrapper className="table-responsive mt-4" >
                            <table
                                className="table table-bordered"
                                style={{ fontSize: "10px", fontFamily: "Montserrat rev=1" }}
                            >

                                <tbody>

                                    <tr>
                                        <td colSpan={3}>Particulars of Outgoing Member(s), if any</td>
                                        <td colSpan={3}>Particulars of New Member(s)</td>
                                    </tr>
                                    <tr >
                                        <td style={{ verticalAlign: 'sub', minHeight: '24px' }}>Name</td>
                                        <td style={{ verticalAlign: 'sub', minHeight: '24px' }}> CNIC/ Passport No.</td>
                                        <td style={{ verticalAlign: 'sub', minHeight: '24px' }}>Date of cessation</td>
                                        <td style={{ verticalAlign: 'sub', minHeight: '24px' }}>Name</td>
                                        <td style={{ verticalAlign: 'sub', minHeight: '24px' }}>CNIC/ Passport No.</td>
                                        <td style={{ verticalAlign: 'sub', minHeight: '24px' }}>Date of Admission</td>

                                    </tr>
                                    {changeHolding.length>0 && changeHolding.map((item,index)=>{
                                        let find=allShareHolders.find(item2=>item2.folio_number == item.folio_number )
                                        let find2=allShareHolders.find(item2=>item2.folio_number == item.folio_number && item2.created_at == data?.start_date )
                                        return (
                                            <tr key={index} >
                                        <td style={{ verticalAlign: 'sub', minHeight: '24px', fontFamily:fontfamilyforDynimicData }}>{find?.shareholder_name}</td>
                                        <td style={{ verticalAlign: 'sub', minHeight: '24px', fontFamily:fontfamilyforDynimicData }}>{find?.cnic ? find?.cnic : find?.passport_no }</td>
                                        <td style={{ verticalAlign: 'sub', minHeight: '24px', fontFamily:fontfamilyforDynimicData }}></td>
                                        <td style={{ verticalAlign: 'sub', minHeight: '24px', fontFamily:fontfamilyforDynimicData }}>{find2?.shareholder_name}</td>
                                        <td style={{ verticalAlign: 'sub', minHeight: '24px', fontFamily:fontfamilyforDynimicData}}>{find2?.cnic ? find2?.cnic : find2?.passport_no }</td>
                                        <td style={{ verticalAlign: 'sub', minHeight: '24px', fontFamily:fontfamilyforDynimicData }}></td>

                                    </tr>
                                        )
                                        
                                    })}
                                    {changeShareHolderData.length == 0 && [1,2].map((item,index)=>{
                                        return (
                                            <tr key={index} >
                                        <td style={{ verticalAlign: 'sub', minHeight: '24px' }}></td>
                                        <td style={{ verticalAlign: 'sub', minHeight: '24px' }}> </td>
                                        <td style={{ verticalAlign: 'sub', minHeight: '24px' }}></td>
                                        <td style={{ verticalAlign: 'sub', minHeight: '24px' }}></td>
                                        <td style={{ verticalAlign: 'sub', minHeight: '24px' }}></td>
                                        <td style={{ verticalAlign: 'sub', minHeight: '24px' }}></td>

                                    </tr>

                                        )
                                    })}
                                </tbody>

                            </table>

                        </Wrapper>









                        <PartsWrapper className='mt-5 mb-3'>
                            Part III
                        </PartsWrapper>
                        <div className='d-flex  mt-4' >
                            <div className='align-items-center' style={{ border: '1px dashed #dddcdc', paddingLeft: '2px', width: '7%' }}>3.1</div>
                            <div className='align-items-center' style={{ border: '1px dashed #dddcdc', width: '93%', padding: ' 5px 10px' }}>
                                <div>Declaration</div>
                                <div className='mt-2'>I do hereby solemnly, and sincerely declare that the information provided in the form is:</div>
                                <ul className='mt-3' style={{ listStyleType: 'none', }}>
                                    <li className='mt-2 '><span style={{ fontSize: '16px' }}>(i)</span> <span style={{ paddingLeft: '10px' }}>true and correct to the best of my knowledge, in consonance with the record as maintained by the Company and nothing has been concealed; and  </span></li>
                                    <li className='mt-2 '> <span style={{ fontSize: '16px' }}>(ii)</span> <span style={{ paddingLeft: '10px' }}>hereby reported after complying with and fulfilling all requirements under the relevant provisions of law, rules, regulations, directives, circulars and notifications whichever is applicable.</span></li>

                                </ul>
                            </div>
                        </div>
                        <div className='d-flex' >
                            <div className='align-items-center' style={{ border: '1px dashed #dddcdc', paddingLeft: '2px', width: '7%' }}></div>
                            <div className='align-items-center' style={{ border: '1px dashed #dddcdc', minHeight: '70px', width: '93%', padding: ' 5px 10px' }} />


                        </div>
                        <div className='d-flex mt-2'>
                            <div className='align-items-center' style={{ border: '1px dashed #dddcdc', paddingLeft: '2px', width: '7%' }}>3.2</div>
                            <div className='align-items-center' style={{ border: '1px dashed #dddcdc', width: '44%', padding: ' 5px 10px' }}>Name of Authorized Officer with designation/ Authorized Intermediary </div>
                            <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '24.5%', padding: ' 5px 10px', fontFamily:fontfamilyforDynimicData }}>{governance?.name}  </ScrollWrapper>
                            <ScrollWrapper className='align-items-center' style={{ borderTop: '1px solid #121212', borderBottom: '1px solid #121212', borderRight: '1px solid #121212', width: '24.5%', padding: ' 5px 10px',fontFamily:fontfamilyforDynimicData }}>{governance?.role}  </ScrollWrapper>
                        </div>
                        <div className='d-flex mt-2'>
                            <div className='align-items-center' style={{ border: '1px dashed #dddcdc', paddingLeft: '2px', width: '7%' }}>3.3</div>
                            <div className='align-items-center' style={{ border: '1px dashed #dddcdc', width: '44%', padding: ' 5px 10px' }}>Signatures  </div>
                            <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '49%', padding: ' 5px 10px' }}> </ScrollWrapper>
                        </div>
                        <div className='d-flex mt-2'>
                            <div className='align-items-center' style={{ border: '1px dashed #dddcdc', paddingLeft: '2px', width: '7%' }}>3.4</div>
                            <div className='align-items-center' style={{ border: '1px dashed #dddcdc', width: '44%', padding: ' 5px 10px' }}>Registration No of Authorized Intermediary, if applicable </div>
                            <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '49%', padding: ' 5px 10px' }}> </ScrollWrapper>
                        </div>

                        <div className='d-flex mt-2'>
                            <div className='align-items-center' style={{ border: '1px dashed #dddcdc', paddingLeft: '2px', width: '7%' }}></div>
                            <div className='align-items-center' style={{ border: '1px dashed #dddcdc', width: '55%', padding: ' 5px 10px' }}> </div>
                            <div className='align-items-center' style={{ border: '1px dashed #dddcdc', width: '8%', padding: ' 5px 10px' }}> Day</div>
                            <div className='align-items-center' style={{ border: '1px dashed #dddcdc', paddingLeft: '2px', width: '3%' }}></div>
                            <div className='align-items-center' style={{ border: '1px dashed #dddcdc', width: '8%', padding: ' 5px 10px' }}> Month</div>
                            <div className='align-items-center' style={{ border: '1px dashed #dddcdc', paddingLeft: '2px', width: '3%' }}></div>
                            <div className='align-items-center' style={{ border: '1px dashed #dddcdc', width: '16%', padding: ' 5px 10px' }}>Year </div>
                            <div></div>
                        </div>
                        <div className='d-flex '>
                            <div className='align-items-center' style={{ border: '1px dashed #dddcdc', paddingLeft: '2px', width: '7%' }}>3.5</div>
                            <div className='align-items-center' style={{ border: '1px dashed #dddcdc', width: '55%', padding: ' 5px 10px' }}>Day</div>
                            <div className='align-items-center' style={{  width: '4%', padding: ' 5px 10px', borderLeft:'1px solid #121212', borderTop:'1px solid #121212',  borderBottom:'1px solid #121212' }}> </div>
                            <div className='align-items-center' style={{ border: '1px solid #121212', width: '4%', padding: ' 5px 10px' }}> </div>
                            <div className='align-items-center' style={{ border: '1px dashed #dddcdc', paddingLeft: '2px', width: '3%' }}></div>
                            <div className='align-items-center' style={{  width: '4%', padding: ' 5px 10px', borderLeft:'1px solid #121212', borderTop:'1px solid #121212',  borderBottom:'1px solid #121212' }}> </div>
                            <div className='align-items-center' style={{ border: '1px solid #121212', width: '4%', padding: ' 5px 10px' }}> </div>
                            <div className='align-items-center' style={{ border: '1px dashed #dddcdc', paddingLeft: '2px', width: '3%' }}></div>
                            <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft:'1px solid #121212', borderTop:'1px solid #121212',  borderBottom:'1px solid #121212' }}> </div>
                            <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft:'1px solid #121212', borderTop:'1px solid #121212',  borderBottom:'1px solid #121212' }}> </div>
                            <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft:'1px solid #121212', borderTop:'1px solid #121212',  borderBottom:'1px solid #121212' }}> </div>
                            <div className='align-items-center' style={{ border: '1px solid #121212', width: '4%', padding: ' 5px 10px' }}> </div>
                        </div>


                    </div>
                </div>
</div>}
            </PDFExport>}
            </div>
            
            <button disabled={loading} className='btn btn-danger' onClick={(e) => {
                // if(viewPdf)
                //   SetViewPDf(true)
                if (pdfExportComponent.current) {
                    pdfExportComponent.current.save();
                }
            }}>Download PDF</button>
        </div>
    )
}
const PartsWrapper = styled.div`
color: #121212;
text-decoration: underline;
justify-content: center;
display: flex;
margin-right: 15px;
font-size: 17px;
font-weight: bold;
`;
const ScrollWrapper = styled.div`
max-height: 50px;
overflow-y: scroll;
overflow-x: hidden;
::-webkit-scrollbar{
    height: 5px;
    width: 3px;
  }
  
  ::-webkit-scrollbar-track{
    background: #F9F9FB;
  }
  ::-webkit-scrollbar-thumb{
    background: #4E515680;
    border-radius: 5px;
  
  }
`;
const HeadingWrapper = styled.div`
font-size: 17px;
font-weight: bold;
`;
const FormName = styled.div`
color: #121212;
text-decoration: underline;
justify-content: end;
display: flex;
margin-right: 15px;
font-size: 17px;
font-weight: bold;
`;
const Wrapper = styled.div`
table.table-bordered{
    border: 1px solid #121212;
     border-color: #121212; 
    // border:1px solid blue;
    // margin-top:20px;
  }
// table.table-bordered > thead > tr > th{
//     // border:1px solid blue;
//     border-color: #121212; 
// }
// table.table-bordered > tbody > tr > td{
//     // border:1px solid blue;
//     borderC-color: #121212; 
// }
 tr td {
    // border:1px solid blue;.
    border-color: #121212; 
}

`;
const WrapperDashed = styled.div`

  td {
    
    border: 1px dashed #dddcdc;
}

`;