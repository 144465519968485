import axios from "axios";
import Config from "../../config";
import RefreshTokenHandler from "./refresh-token";

const getCompanies = async (email) => {
  try {
    const token = sessionStorage.getItem("token") || "";
    const url = `${Config.baseUrl}/companies?email=${email}`;
    const result = await axios.get(url, {
      headers: {
        Authorization: token,
      },
    });
    return result;
  } catch (err) {
    if (err.response.data.status == 401) {
      let responseToHandle = err.response.data;
      let result = await RefreshTokenHandler.handleIt(responseToHandle);
      if (result.status) {
        return await getCompanies(email);
      } else {
        throw err;
      }
    } else {
      throw err;
    }
  }
};
const getAllEventData = async (email) => {
  try {
    const token = sessionStorage.getItem("token") || "";
    const url = `${Config.baseUrl}/statutoryevents/?email=${email}`;
    const result = await axios.get(url, {
      headers: {
        Authorization: token,
      },
    });
    return result;
  } catch (err) {
    if (err.response.data.status == 401) {
      let responseToHandle = err.response.data;
      let result = await RefreshTokenHandler.handleIt(responseToHandle);
      if (result.status) {
        return await getAllEventData(email);
      } else {
        throw err;
      }
    } else {
      throw err;
    }
  }
};
const getPaginatedEventData = async (
  email,
  page_number,
  page_size
  // company_code
) => {
  try {
    const token = sessionStorage.getItem("token") || "";
    const url = `${Config.baseUrl}/statutoryevents/paginate?page_size=10&email=${email}&page_number=${page_number}`;
    const result = await axios.get(url, {
      headers: {
        Authorization: token,
      },
    });
    return result;
  } catch (err) {
    if (err.response.data.status == 401) {
      let responseToHandle = err.response.data;
      let result = await RefreshTokenHandler.handleIt(responseToHandle);
      if (result.status) {
        return await getPaginatedEventData(email, page_number, page_size);
      } else {
        throw err;
      }
    } else {
      throw err;
    }
  }
};

const getPaginatedEventDataByCompany = async (
  email,
  page_number,
  // page_size,
  company_code
) => {
  try {
    const token = sessionStorage.getItem("token") || "";
    const url = `${Config.baseUrl}/statutoryevents/paginate-by-company?page_size=10&email=${email}&page_number=${page_number}&company_code=${company_code}`;
    const result = await axios.get(url, {
      headers: {
        Authorization: token,
      },
    });
    return result;
  } catch (err) {
    if (err.response.data.status == 401) {
      let responseToHandle = err.response.data;
      let result = await RefreshTokenHandler.handleIt(responseToHandle);
      if (result.status) {
        return await getPaginatedEventDataByCompany(
          email,
          page_number,
          // page_size,
          company_code
        );
      } else {
        throw err;
      }
    } else {
      throw err;
    }
  }
};

const getFolioByCounter = async (email, company_code) => {
  try {
    const token = sessionStorage.getItem("token") || "";
    const url = `${Config.baseUrl}/companies/folio-counter?email=${email}&company_code=${company_code}`;
    const result = await axios.get(url, {
      headers: {
        Authorization: token,
      },
    });
    return result;
  } catch (err) {
    if (err.response.data.status == 401) {
      let responseToHandle = err.response.data;
      let result = await RefreshTokenHandler.handleIt(responseToHandle);
      if (result.status) {
        return await getFolioByCounter(email, company_code);
      } else {
        throw err;
      }
    } else {
      throw err;
    }
  }
};

const getCertificateNo = async (email, company_code) => {
  try {
    const token = sessionStorage.getItem("token") || "";
    const url = `${Config.baseUrl}/companies/share-counter?email=${email}&company_code=${company_code}`;
    const result = await axios.get(url, {
      headers: {
        Authorization: token,
      },
    });
    return result;
  } catch (err) {
    if (err.response.data.status == 401) {
      let responseToHandle = err.response.data;
      let result = await RefreshTokenHandler.handleIt(responseToHandle);
      if (result.status) {
        return await getCertificateNo(email, company_code);
      } else {
        throw err;
      }
    } else {
      throw err;
    }
  }
};

const getCompanyById = async (email, id) => {
  try {
    const token = sessionStorage.getItem("token") || "";
    const url = `${Config.baseUrl}/companies/by-id?email=${email}&code=${id}`;
    const result = await axios.get(url, {
      headers: {
        Authorization: token,
      },
    });
    return result;
  } catch (err) {
    if (err.response.data.status == 401) {
      let responseToHandle = err.response.data;
      let result = await RefreshTokenHandler.handleIt(responseToHandle);
      if (result.status) {
        return await getCompanyById(email, id);
      } else {
        throw err;
      }
    } else {
      throw err;
    }
  }
};

const addCompany = async (
  email,
  code,
  company_name,
  isin,
  registered_name,
  symbol,
  company_secretary,
  parent_code,
  active,
  ntn,
  incorporation_no,
  sector_code,
  website,
  contact_person_name,
  contact_person_phone,
  contact_person_exchange_no,
  contact_person_email,
  ceo_name,
  ceo_phone,
  ceo_mobile,
  ceo_email,
  head_office_address,
  head_office_city,
  head_office_country,
  outstanding_shares,
  face_value,
  total_shares,
  free_float,
  treasury_shares,
  preference_shares,
  ordinary_shares,
  non_voting_shares,
  redeemable_shares,
  management_shares,
  company_type,
  authorized_persons,
  governance,
  allot_size
) => {
  try {
    const url = `${Config.baseUrl}/companies/`;

    const result = await axios.post(
      url,
      {
        email,
        code,
        company_name,
        isin,
        registered_name,
        symbol,
        company_secretary,
        parent_code,
        active,
        ntn,
        incorporation_no,
        sector_code,
        website,
        contact_person_name,
        contact_person_phone,
        contact_person_exchange_no,
        contact_person_email,
        ceo_name,
        ceo_phone,
        ceo_mobile,
        ceo_email,
        head_office_address,
        head_office_city,
        head_office_country,
        outstanding_shares,
        face_value,
        total_shares,
        free_float,
        treasury_shares,
        preference_shares,
        ordinary_shares,
        non_voting_shares,
        redeemable_shares,
        management_shares,
        company_type,
        authorized_persons,
        governance,
        allot_size,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: sessionStorage.getItem("token") || "",
        },
      }
    );
    return result;
  } catch (err) {
    if (err.response.data.status == 401) {
      let responseToHandle = err.response.data;
      let result = await RefreshTokenHandler.handleIt(responseToHandle);
      if (result.status) {
        return await addCompany(
          email,
          code,
          company_name,
          isin,
          registered_name,
          symbol,
          company_secretary,
          parent_code,
          active,
          ntn,
          incorporation_no,
          sector_code,
          website,
          contact_person_name,
          contact_person_phone,
          contact_person_exchange_no,
          contact_person_email,
          ceo_name,
          ceo_phone,
          ceo_mobile,
          ceo_email,
          head_office_address,
          head_office_city,
          head_office_country,
          outstanding_shares,
          face_value,
          total_shares,
          free_float,
          treasury_shares,
          preference_shares,
          ordinary_shares,
          non_voting_shares,
          redeemable_shares,
          management_shares,
          company_type,
          authorized_persons,
          governance,
          allot_size
        );
      } else {
        throw err;
      }
    } else {
      throw err;
    }
  }
};

const updateCompany = async (
  email,
  code,
  company_name,
  isin,
  registered_name,
  symbol,
  company_secretary,
  parent_code,
  active,
  ntn,
  incorporation_no,
  sector_code,
  website,
  contact_person_name,
  contact_person_phone,
  contact_person_exchange_no,
  contact_person_email,
  ceo_name,
  ceo_phone,
  ceo_mobile,
  ceo_email,
  head_office_address,
  head_office_city,
  head_office_country,
  outstanding_shares,
  face_value,
  total_shares,
  free_float,
  treasury_shares,
  preference_shares,
  ordinary_shares,
  non_voting_shares,
  redeemable_shares,
  management_shares,
  company_type,
  authorized_persons,
  governance,
  allot_size
) => {
  try {
    const url = `${Config.baseUrl}/companies/update`;

    const result = await axios.post(
      url,
      {
        email,
        code,
        company_name,
        isin,
        registered_name,
        symbol,
        company_secretary,
        parent_code,
        active,
        ntn,
        incorporation_no,
        sector_code,
        website,
        contact_person_name,
        contact_person_phone,
        contact_person_exchange_no,
        contact_person_email,
        ceo_name,
        ceo_phone,
        ceo_mobile,
        ceo_email,
        head_office_address,
        head_office_city,
        head_office_country,
        outstanding_shares,
        face_value,
        total_shares,
        treasury_shares,
        free_float,
        preference_shares,
        ordinary_shares,
        non_voting_shares,
        redeemable_shares,
        management_shares,
        company_type,
        authorized_persons,
        governance,
        allot_size,
      },
      {
        headers: {
          Authorization: sessionStorage.getItem("token") || "",
        },
      }
    );
    return result;
  } catch (err) {
    if (err.response.data.status == 401) {
      let responseToHandle = err.response.data;
      let result = await RefreshTokenHandler.handleIt(responseToHandle);
      if (result.status) {
        return await updateCompany(
          email,
          code,
          company_name,
          isin,
          registered_name,
          symbol,
          company_secretary,
          parent_code,
          active,
          ntn,
          incorporation_no,
          sector_code,
          website,
          contact_person_name,
          contact_person_phone,
          contact_person_exchange_no,
          contact_person_email,
          ceo_name,
          ceo_phone,
          ceo_mobile,
          ceo_email,
          head_office_address,
          head_office_city,
          head_office_country,
          outstanding_shares,
          face_value,
          total_shares,
          free_float,
          treasury_shares,
          preference_shares,
          ordinary_shares,
          non_voting_shares,
          redeemable_shares,
          management_shares,
          company_type,
          authorized_persons,
          governance,
          allot_size
        );
      } else {
        throw err;
      }
    } else {
      throw err;
    }
  }
};

const sendEmail = async (email, code, to, cc, subject, template) => {
  try {
    const url = `${Config.baseUrl}/companies/manual-statutory-alert-template?email=${email}&code=${code}&to[]=${to}&cc[]=${cc}&subject=${subject}&template=${template}`;
    const result = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem("token") || "",
      },
    });
    return result;
  } catch (err) {
    if (err.response.data.status === 401) {
      let responseToHandle = err.response.data;
      let result = await RefreshTokenHandler.handleIt(responseToHandle);
      if (result.status) {
        return await sendEmail(email, code, to, cc, subject, template);
      } else {
        throw err;
      }
    } else {
      throw err;
    }
  }
};

const updateDraftCompany = async (
  email,
  code,
  cuin,
  company_name,
  isin,
  registered_name,
  symbol,
  company_secretary,
  parent_code,
  active,
  ntn,
  incorporation_no,
  sector_code,
  website,
  contact_person_name,
  contact_person_phone,
  contact_person_exchange_no,
  contact_person_email,
  ceo_name,
  ceo_phone,
  ceo_mobile,
  ceo_email,
  head_office_address,
  head_office_city,
  head_office_country,
  province,
  outstanding_shares,
  face_value,
  total_shares,
  free_float,
  treasury_shares,
  preference_shares,
  voting_preference_shares,
  convertible_preference_shares,
  non_convertible_preference_shares,
  preference_profit_rate,
  preference_payment_schedule,
  ordinary_shares,
  non_voting_shares,
  redeemable_shares,
  // authorized_capital,
  // paid_up_capital,
  management_shares,
  company_type,
  authorized_persons,
  governance,
  // serviceObjects,
  allot_size,
  logo,
  number_of_directors,
  shareholder_directors,
  independent_directors,
  board_election_date,
  // next_board_election_date,
  // agm_date,
  // next_agm_date,
  company_auditor,
  company_registrar,
  fiscal_year,
  authorized_capital,
  paid_up_capital,
  service_providers,
  next_board_election_date,
  agm_date,
  next_agm_date,
  bussines_service,
  security_type,
  services_subscribed,
  revenue_reserves,
  cap_reserves,
  revaluation_reserves,
  corum_precense,
  corum_percentage,
  adjourned_corum_precense,
  adjourned_corum_percentage
) => {
  try {
    const url = `${Config.baseUrl}/companies/update-draft`;

    const result = await axios.post(
      url,
      {
        email,
        code,
        cuin,
        company_name,
        isin,
        registered_name,
        symbol,
        company_secretary,
        parent_code,
        active,
        ntn,
        incorporation_no,
        sector_code,
        website,
        contact_person_name,
        contact_person_phone,
        contact_person_exchange_no,
        contact_person_email,
        ceo_name,
        ceo_phone,
        ceo_mobile,
        ceo_email,
        head_office_address,
        head_office_city,
        head_office_country,
        province,
        outstanding_shares,
        face_value,
        total_shares,
        free_float,
        treasury_shares,
        preference_shares,
        voting_preference_shares,
        convertible_preference_shares,
        non_convertible_preference_shares,
        preference_profit_rate,
        preference_payment_schedule,
        ordinary_shares,
        non_voting_shares,
        redeemable_shares,
        // authorized_capital,
        // paid_up_capital,
        management_shares,
        company_type,
        authorized_persons,
        governance,
        // serviceObjects,
        allot_size,
        logo,
        number_of_directors,
        shareholder_directors,
        independent_directors,
        board_election_date,
        // next_board_election_date,
        // agm_date,
        // next_agm_date,
        company_auditor,
        company_registrar,
        fiscal_year,
        authorized_capital,
        paid_up_capital,
        service_providers,
        next_board_election_date,
        agm_date,
        next_agm_date,
        bussines_service,
        security_type,
        services_subscribed,
        revenue_reserves,
        cap_reserves,
        revaluation_reserves,
        corum_precense,
        corum_percentage,
        adjourned_corum_precense,
        adjourned_corum_percentage,
      },
      {
        headers: {
          Authorization: sessionStorage.getItem("token") || "",
        },
      }
    );
    return result;
  } catch (err) {
    if (err.response.data.status == 401 || err.response.status == 403) {
      let responseToHandle = err.response.data;
      let result = await RefreshTokenHandler.handleIt(responseToHandle);
      if (result.status) {
        return await updateDraftCompany(
          email,
          code,
          cuin,
          company_name,
          isin,
          registered_name,
          symbol,
          company_secretary,
          parent_code,
          active,
          ntn,
          incorporation_no,
          sector_code,
          website,
          contact_person_name,
          contact_person_phone,
          contact_person_exchange_no,
          contact_person_email,
          ceo_name,
          ceo_phone,
          ceo_mobile,
          ceo_email,
          head_office_address,
          head_office_city,
          head_office_country,
          province,
          outstanding_shares,
          face_value,
          total_shares,
          free_float,
          treasury_shares,
          preference_shares,
          voting_preference_shares,
          convertible_preference_shares,
          non_convertible_preference_shares,
          preference_profit_rate,
          preference_payment_schedule,
          ordinary_shares,
          non_voting_shares,
          redeemable_shares,
          // authorized_capital,
          // paid_up_capital,
          management_shares,
          company_type,
          authorized_persons,
          governance,
          // serviceObjects,
          allot_size,
          logo,
          number_of_directors,
          shareholder_directors,
          independent_directors,
          board_election_date,
          // next_board_election_date,
          // agm_date,
          // next_agm_date,
          company_auditor,
          company_registrar,
          fiscal_year,
          authorized_capital,
          paid_up_capital,
          service_providers,
          next_board_election_date,
          agm_date,
          next_agm_date,
          bussines_service,
          security_type,
          services_subscribed,
          revenue_reserves,
          cap_reserves,
          revaluation_reserves,
          corum_precense,
          corum_percentage,
          adjourned_corum_precense,
          adjourned_corum_percentage
        );
      } else {
        throw err;
      }
    } else {
      throw err;
    }
  }
};

const getElectronicPhysicalCountByCompanyCode = async (email, company_code) => {
  try {
    const token = sessionStorage.getItem("token") || "";
    const url = `${Config.baseUrl}/shareholders/by-company-electronic-physical-count?email=${email}&company_code=${company_code}`;
    const result = await axios.get(url, {
      headers: {
        Authorization: token,
      },
    });
    return result;
  } catch (err) {
    if (err.response.data.status == 401 || err.response.status == 403) {
      let responseToHandle = err.response.data;
      let result = await RefreshTokenHandler.handleIt(responseToHandle);
      if (result.status) {
        return await getElectronicPhysicalCountByCompanyCode(
          email,
          company_code
        );
      } else {
        throw err;
      }
    } else {
      throw err;
    }
  }
};

const getCompanyByMultipleCodesIssuer = async (email, ids) => {
  try {
    const token = sessionStorage.getItem("token") || "";
    const url = `${Config.baseUrl}/companies/by-multiple-ids-issuer?email=${email}&codes=${ids}`;
    const result = await axios.get(url, {
      headers: {
        Authorization: token,
      },
    });
    return result;
  } catch (err) {
    if (err.response.data.status == 401) {
      let responseToHandle = err.response.data;
      let result = await RefreshTokenHandler.handleIt(responseToHandle);
      if (result.status) {
        return await getCompanyByMultipleCodesIssuer(email, ids);
      } else {
        throw err;
      }
    } else {
      throw err;
    }
  }
};

const getAllRequirmentData = async (
  email
  // page_number,
  // page_size,
  // company_code
) => {
  try {
    const token = sessionStorage.getItem("token") || "";
    const url = `${Config.baseUrl}/statutoryrequirements?email=${email}`;
    const result = await axios.get(url, {
      headers: {
        Authorization: token,
      },
    });
    return result;
  } catch (err) {
    if (err.response.data.status == 401 || err.response.status == 403) {
      let responseToHandle = err.response.data;
      let result = await RefreshTokenHandler.handleIt(responseToHandle);
      if (result.status) {
        return await getAllRequirmentData(
          email
          // page_number,
          // page_size,
        );
      } else {
        throw err;
      }
    } else {
      throw err;
    }
  }
};

const getEventDataByCompanyCode = async (email, company_code) => {
  try {
    const token = sessionStorage.getItem("token") || "";
    const url = `${Config.baseUrl}/statutoryevents/by-company?email=${email}&company_code=${company_code}`;
    const result = await axios.get(url, {
      headers: {
        Authorization: token,
      },
    });
    return result;
  } catch (err) {
    if (err.response.data.status == 401 || err.response.status == 403) {
      let responseToHandle = err.response.data;
      let result = await RefreshTokenHandler.handleIt(responseToHandle);
      if (result.status) {
        return await getEventDataByCompanyCode(email, company_code);
      } else {
        throw err;
      }
    } else {
      throw err;
    }
  }
};

const addStatuaryEvent = async (
  email,
  company_code,
  req_code,
  title,
  start_date,
  deadline_date,
  reminder_days,
  action_date,
  action_by,
  previous_action_date,
  status,
  comments,
  closed,
  chairman,
  location,
  // effect_from,
  // last_date,
  // agm_date,
  // agm_end_date,
  // application_from,
  // application_to,
  // authorization_from,
  // authorization_to,
  // election_from,
  // election_to,
  // pol_date,
  // postal_ballot_last_date,
  // agenda_from,
  // agenda_to,
  // meeting_start_date,
  // meeting_end_date,
  online_conference,
  // voting_required,
  book_closing_from,
  book_closing_to,
  meeting_required
) => {
  try {
    const url = `${Config.baseUrl}/statutoryevents/`;

    const result = await axios.post(
      url,
      {
        email,
        company_code,
        req_code,
        title,
        start_date,
        deadline_date,
        reminder_days,
        action_date,
        action_by,
        previous_action_date,
        status,
        comments,
        closed,
        chairman,
        location,
        // effect_from,
        // last_date,
        // agm_date,
        // agm_end_date,
        // application_from,
        // application_to,
        // authorization_from,
        // authorization_to,
        // election_from,
        // election_to,
        // pol_date,
        // postal_ballot_last_date,
        // agenda_from,
        // agenda_to,
        // meeting_start_date,
        // meeting_end_date,
        online_conference,
        // voting_required,
        book_closing_from,
        book_closing_to,
        meeting_required,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: sessionStorage.getItem("token") || "",
        },
      }
    );
    return result;
  } catch (err) {
    if (err.response.data.status == 401 || err.response.status == 403) {
      let responseToHandle = err.response.data;
      let result = await RefreshTokenHandler.handleIt(responseToHandle);
      if (result.status) {
        return await addStatuaryEvent(
          email,
          company_code,
          req_code,
          title,
          start_date,
          deadline_date,
          reminder_days,
          action_date,
          action_by,
          previous_action_date,
          status,
          comments,
          closed,
          chairman,
          location,
          // effect_from,
          // last_date,
          // agm_date,
          // agm_end_date,
          // application_from,
          // application_to,
          // authorization_from,
          // authorization_to,
          // election_from,
          // election_to,
          // pol_date,
          // postal_ballot_last_date,
          // agenda_from,
          // agenda_to,
          // meeting_start_date,
          // meeting_end_date,
          online_conference,
          // voting_required,
          book_closing_from,
          book_closing_to,
          meeting_required
        );
      } else {
        throw err;
      }
    } else {
      throw err;
    }
  }
};


const getPaginatedRequirmentData = async (
  email,
  page_number,
  value,
  search_criteria
  // page_size,
  // company_code
) => {
  try {
    const token = sessionStorage.getItem("token") || "";
    const url = `${Config.baseUrl}/statutoryrequirements/paginate?page_size=50&email=${email}&page_number=${page_number}&value=${value}&search_criteria=${search_criteria}`;
    const result = await axios.get(url, {
      headers: {
        Authorization: token,
      },
    });
    return result;
  } catch (err) {
    if (err.response.data.status == 401 || err.response.status == 403) {
      let responseToHandle = err.response.data;
      let result = await RefreshTokenHandler.handleIt(responseToHandle);
      if (result.status) {
        return await getPaginatedRequirmentData(
          email,
          page_number
          // page_size,
        );
      } else {
        throw err;
      }
    } else {
      throw err;
    }
  }
};

export {
  getCompanies,
  getCertificateNo,
  addCompany,
  updateCompany,
  getCompanyById,
  getFolioByCounter,
  getPaginatedEventData,
  sendEmail,
  getPaginatedEventDataByCompany,
  getAllEventData,
  updateDraftCompany,
  getElectronicPhysicalCountByCompanyCode,
  getCompanyByMultipleCodesIssuer,
  getAllRequirmentData,
  getEventDataByCompanyCode,
  addStatuaryEvent,
  getPaginatedRequirmentData
};
