import React, { useState, useEffect } from 'react'
import { PDFExport } from "@progress/kendo-react-pdf";
import PageTemplate from "./page-template";
import {
    getCompanies,
} from "../../../store/services/company.service";
import styled from 'styled-components';
import { TiTick } from "react-icons/ti";
import { numberWithCommas } from 'utilities/utilityFunctions';
export const StatutoryForm4new = ({ data, formTemplate = false }) => {
    const pdfExportComponent = React.useRef(null);
    const [CompanyData, setCompanyData] = useState(null)
    const [loading, setLoading] = useState(false);
    const [fontFamilyForStaticData, setFontFamilyForStaticData] = useState('Montserrat, sans-serif')
    const [fontSizeForStaticData, setFontSizeForStaticData] = useState('14px')
    const [fontfamilyforDynimicData, setFontFamilyForDynmicData] = useState('Segoe Print')
    const [fontColor, setFontColor] = useState('')
    const baseEmail = sessionStorage.getItem("email") || "";
    const [governance, setGovernance] = useState({})
    const getAllCompanies = async () => {
        setLoading(true)
        try {
            const response = await getCompanies(baseEmail);
            if (response.status === 200) {
                response.data.data = response.data.data.filter((item) => item?.active === 'Y')
                const parents = response.data.data;
                const company = parents?.find(ite => ite?.code == data?.company_code)
                let Allperson = company.governance && company.governance != "" ? JSON.parse(company.governance) : []
                let sec = Allperson.find(x => x.role == "Company Secretary" && x?.active?.toLowerCase() != 'n')
                let ceo = Allperson.find(x => x.role == "CEO" && x?.active?.toLowerCase() != 'n')


                if (sec) {
                    setGovernance(sec)
                } else if (ceo) {
                    setGovernance(ceo)
                }
                setCompanyData(company)
                setTimeout(() => {
                    setLoading(false)
                }, 1000);
            }
        } catch (error) {
            setLoading(false)
        }
    };
    useEffect(() => {
        getAllCompanies()
    }, [])
    return (
        <div>
            <div
                style={{ fontFamily: "sans-serif " }}
            >

                {formTemplate ?
                    <PDFExport
                        paperSize="A4"
                        margin="1cm"
                        scale={0.6}
                        fileName={`Statutory Form 4`}
                        pageTemplate={PageTemplate}
                        ref={pdfExportComponent}
                    >
                        <div style={{ fontFamily: `${fontFamilyForStaticData}`, fontSize: `${fontSizeForStaticData}`, fontWeight: 500 }}>
                            <FormName>
                                Form 4
                            </FormName>
                            <div className='row'>
                                <div className='col-md-12' >
                                    <HeadingWrapper className='d-flex justify-content-center'>
                                        THE COMPANIES ACT, 2017
                                    </HeadingWrapper>

                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <HeadingWrapper className='d-flex justify-content-center'>
                                        THE COMPANIES REGULATIONS, 2024
                                    </HeadingWrapper>

                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <div style={{ fontSize: '15px', fontWeight: 'bold' }} className='d-flex justify-content-center'>
                                    [Sections 26, 27, 28, 29 & 32(1)(b) and Regulations 36 & 30]
                                    </div>

                                </div>
                            </div>

                            <div className='row mt-4'>
                                <div className='col-md-12'>
                                    <HeadingWrapper className='d-flex justify-content-center'>
                                    INTIMATION ABOUT PRINCIPAL LINE OF BUSINESS OR CHANGE THEREIN
                                    </HeadingWrapper>

                                </div>
                            </div>
                            <PartsWrapper className='mt-4 mb-3'>
                                Part I
                            </PartsWrapper>
                            <div className='row'>
                                <div className='col-md-12' style={{ padding: '25px 50px', wordWrap: 'break-word' }}>
                                    <div> <i>(Please complete in bold block capitals letters)</i></div>


                                    <div className='d-flex' >
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>1.1</div>
                                        <div className='align-items-center' style={{ width: '28%', padding: ' 0px 10px 10px 10px' }}>CUIN (Registration Number)  </div>
                                        <div className='align-items-center  d-flex'  >{
                                            '1234 552'.split("")?.map((item, idx) => {
                                                if (item == ' ') return
                                                return <div style={{ minWidth: '30px', minHeight: '34px', border: '1px solid #121212', borderLeft: idx != 0 ? "none" : "1px solid #121212", padding: '6px 9px' }}></div>
                                            })
                                        }
                                        </div>
                                    </div>


                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>1.2</div>
                                        <div className='align-items-center' style={{ width: '28%', padding: '0px 10px 10px 10px' }}>Name of the Company  </div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '65%', padding: ' 5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                    </div>




                                    <div className='d-flex  mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>1.3</div>
                                        <div className='align-items-center' style={{ width: '20.20%', padding: '0px 10px 10px 10px' }}>Fee Payment Details</div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '5%' }}>1.3.1</div>
                                        <div className='align-items-center' style={{ width: '13.20%', paddingLeft: '20px' }}>Challan No.</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '17.20%', paddingLeft: '20px', minHeight: '34px' }}></ScrollWrapper>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '5%' }}>1.3.3</div>
                                        <div className='align-items-center' style={{ width: '13.20%', paddingLeft: '20px' }}>Amount</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '19.20%', paddingLeft: '20px', minHeight: '34px' }}></ScrollWrapper>
                                    </div>
                                    <PartsWrapper className='mt-4 mb-3'>
                                        Part II
                                    </PartsWrapper>

                                    <div className='row mt-2'>
                                        <div className='col-md-12'>
                                            <HeadingWrapper className='text-center d-flex justify-content-center'>
                                            (This part is to be filled by a company if its object stated at serial number 1 of the object clause of memorandum is not the principal line of business)
                                            </HeadingWrapper>

                                        </div>
                                    </div>
                                    <div className='row mt-3'>
                                        <div className='col-md-12'>
                                            <HeadingWrapper className='d-flex text-center justify-content-center'>
                                            INTIMATION ABOUT PRINCIPAL LINE OF BUSINESS IN TERMS OF SECTION 27(A)(iii)(b) OR 28(1)(c)(ii) OR 29(c)(ii) (AS THE CASE MAY BE) OF THE ACT
                                            </HeadingWrapper>

                                        </div>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ width: '28%', padding: '0px 10px 10px 10px' }}>Principal line of business of the company as stated at serial number ___ of clause III of the Memorandum of Association is:</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '73%',minHeight:"90px", padding: ' 5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                    </div>

                                    <PartsWrapper className='mt-4 mb-3'>
                                        Part - III
                                    </PartsWrapper>

                                    <div className='row mt-2'>
                                        <div className='col-md-12'>
                                            <HeadingWrapper className='d-flex justify-content-center'>
                                            (This part is to be filled by a company which has changed its principal line of business)
                                            </HeadingWrapper>

                                        </div>
                                    </div>
                                    <div  className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%',fontWeight:"bold" }}>3</div>
                                        <div className='align-items-center' style={{ padding: '0px 10px 10px 10px',fontWeight:"bold" }}> CHANGE IN PRINCIPAL LINE OF BUSINESS</div>
                                    </div>

                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>3.1</div>
                                        <div className='align-items-center' style={{ width: '32%', padding: '0px 10px 10px 10px' }}> Previous Principal line of business</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '65%', padding: ' 5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>3.2</div>
                                        <div className='align-items-center' style={{ width: '32%', padding: '0px 10px 10px 10px' }}>New Principal line of business</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '65%', padding: ' 5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                    </div>

                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}></div>
                                        <div className='align-items-center' style={{ width: '60%', padding: ' 5px 10px' }}> </div>
                                        <div className='align-items-center text-center' style={{ width: '8%', padding: ' 5px' }}> Day</div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center text-center' style={{ width: '8%', padding: ' 5px' }}> Month</div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center text-center' style={{ width: '16%', padding: ' 5px' }}>Year </div>
                                        <div></div>
                                    </div>
                                    <div className='d-flex '>
                                    <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>3.3</div>
                                        <div className='align-items-center' style={{ width: '59%', padding: ' 0px 10px 10px 10px' }}> Date of passing of special resolution</div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ border: '1px solid #121212', width: '4%', padding: ' 5px' }}> </div>
                                        <div className='align-items-center' style={{ paddingLeft: '8px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ border: '1px solid #121212', width: '4%', padding: ' 5px 10px', borderRight: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ paddingLeft: '8px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', border: '1px solid #121212', }}> </div>
                                    </div>

                                    <PartsWrapper className='mt-4 mb-3'>
                                        Part - IV
                                    </PartsWrapper>

                                    <div  className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ padding:"0px 0px 10px 2px", width: '7%',fontWeight:"bold" }}>4.1</div>
                                        <div className='align-items-center' style={{ padding: ' 10px 10px 0px 10px',fontWeight:"bold" }}>Declaration:</div>
                                    </div>
                                    <div  className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ padding:"0px 0px 10px 2px", width: '7%'}}></div>
                                        <div className='align-items-center' style={{ padding: ' 5px 10px'}}>I do hereby solemnly and sincerely declare that the information provided in the form is:</div>
                                    </div>
                                    <div  className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ padding:"0px 0px 10px 2px", width: '7%'}}></div>
                                        <div className='align-items-center' style={{ padding:"0px 0px 10px 2px", width: '1%'}}></div>
                                        <div className='d-flex align-items-baseline' style={{ padding: ' 5px 10px'}}><div style={{marginLeft:"8px"}}>(i)</div><div style={{marginLeft:"28px"}}> true and correct to the best of my knowledge, in consonance with the record as maintained by the Company and nothing has been concealed; and</div></div>
                                    </div>
                                    <div  className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ padding:"0px 0px 10px 2px", width: '7%'}}></div>
                                        <div className='align-items-center' style={{ padding:"0px 0px 10px 2px", width: '2.8%'}}></div>
                                        <div className='d-flex align-items-baseline' style={{ padding: ' 5px 10px'}}><div style={{marginLeft:"8px"}}>(ii)</div><div style={{marginLeft:"24px"}}> hereby reported after complying with and fulfilling all requirements under the relevant provisions of law, rules, regulations, directives, circulars and notifications whichever is applicable</div></div>
                                    </div>
                                    <div  className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ padding:"0px 0px 10px 2px", width: '7%'}}></div>
                                        <div className='align-items-center' style={{ padding:"0px 0px 10px 2px", width: '4%'}}></div>
                                        <div className='d-flex align-items-baseline' style={{ padding: ' 5px 10px'}}><div style={{marginLeft:"8px"}}>(iii)</div><div style={{marginLeft:"20px"}}> New Principal line of business does not fall in any of the categories of business activity which is subject to license, registration, permission or approval under any law as restricted under Section 26(2) or 32(1)(c).</div></div>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>4.2</div>
                                        <div className='align-items-center' style={{ width: '28%', padding: '0px 10px 10px 10px' }}> Name of Authorized Officer with designation/ Authorized Intermediary</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '32.5%', padding: ' 5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '32.5%',borderLeft:"none", padding: ' 5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>4.3</div>
                                        <div className='align-items-center' style={{ width: '28%', padding: '0px 10px 10px 10px' }}>Signatures</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '65%', padding: ' 5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>4.4</div>
                                        <div className='align-items-center' style={{ width: '58%', padding: ' 0px 10px 10px 10px' }}> Registration No of Authorized Intermediary, if applicable</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '35%', padding: ' 5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}></div>
                                        <div className='align-items-center' style={{ width: '55%', padding: ' 5px 10px' }}> </div>
                                        <div className='align-items-center text-center' style={{ width: '8%', padding: ' 5px' }}> Day</div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center text-center' style={{ width: '8%', padding: ' 5px' }}> Month</div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center text-center' style={{ width: '16%', padding: ' 5px' }}>Year </div>
                                        <div></div>
                                    </div>
                                    <div className='d-flex '>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>4.5</div>
                                        <div className='align-items-center' style={{ width: '55%', padding: '0px 10px 10px 10px' }}>Date</div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ border: '1px solid #121212', width: '4%', padding: ' 5px 10px' }}> </div>
                                        <div className='align-items-center' style={{ paddingLeft: '8px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ border: '1px solid #121212', width: '4%', padding: ' 5px 10px', borderRight: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ paddingLeft: '8px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', border: '1px solid #121212', }}> </div>
                                    </div>

                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px',fontWeight:"bold"}}>Enclosure(s):</div>
                                    </div>
                                    <div className='d-flex align-items-center mt-2' style={{ paddingLeft: '2px', }}><div style={{marginLeft:"8px"}}>1.</div> <div style={{marginLeft:'23px'}}>Copy of the Special Resolution with evidence of filing</div></div>
                                    <div className='d-flex align-items-center' style={{ paddingLeft: '2px', }}><div style={{marginLeft:"8px"}}>2.</div> <div style={{marginLeft:'20px'}}>Amended Memorandum & Articles of Association.</div></div>
                                    <div className='d-flex align-items-center' style={{ paddingLeft: '2px', }}><div style={{marginLeft:"8px"}}>3.</div> <div style={{marginLeft:'20px'}}>Original challan or other evidence of payment of fee specified in Seventh Schedule of the Act (not applicable in case of online filing)</div></div>


                                </div>
                            </div>
                        </div>
                    </PDFExport>
                    : <PDFExport
                        paperSize="A4"
                        margin="1cm"
                        scale={0.6}
                        fileName={`Statutory Form 4`}
                        pageTemplate={PageTemplate}
                        ref={pdfExportComponent}
                    >
                        <div style={{ fontFamily: `${fontFamilyForStaticData}`, fontSize: `${fontSizeForStaticData}`, fontWeight: 500 }}>
                            <FormName>
                                Form 4
                            </FormName>
                            <div className='row'>
                                <div className='col-md-12' >
                                    <HeadingWrapper className='d-flex justify-content-center'>
                                        THE COMPANIES ACT, 2017
                                    </HeadingWrapper>

                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <HeadingWrapper className='d-flex justify-content-center'>
                                        THE COMPANIES REGULATIONS, 2024
                                    </HeadingWrapper>

                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <div style={{ fontSize: '15px', fontWeight: 'bold' }} className='d-flex justify-content-center'>
                                    [Sections 26, 27, 28, 29 & 32(1)(b) and Regulations 36 & 30]
                                    </div>

                                </div>
                            </div>

                            <div className='row mt-4'>
                                <div className='col-md-12'>
                                    <HeadingWrapper className='d-flex justify-content-center'>
                                    INTIMATION ABOUT PRINCIPAL LINE OF BUSINESS OR CHANGE THEREIN
                                    </HeadingWrapper>

                                </div>
                            </div>
                            <PartsWrapper className='mt-4 mb-3'>
                                Part I
                            </PartsWrapper>
                            <div className='row'>
                                <div className='col-md-12' style={{ padding: '25px 50px', wordWrap: 'break-word' }}>
                                    <div> <i>(Please complete in bold block capitals letters)</i></div>


                                    <div className='d-flex' >
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>1.1</div>
                                        <div className='align-items-center' style={{ width: '28%', padding: ' 0px 10px 10px 10px' }}>CUIN (Registration Number)  </div>
                                        <div className='align-items-center  d-flex'  >{
                                            '1234 552'.split("")?.map((item, idx) => {
                                                if (item == ' ') return
                                                return <div style={{ minWidth: '30px', minHeight: '34px', border: '1px solid #121212', borderLeft: idx != 0 ? "none" : "1px solid #121212", padding: '6px 9px' }}></div>
                                            })
                                        }
                                        </div>
                                    </div>


                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>1.2</div>
                                        <div className='align-items-center' style={{ width: '28%', padding: '0px 10px 10px 10px' }}>Name of the Company  </div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '65%', padding: ' 5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                    </div>




                                    <div className='d-flex  mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>1.3</div>
                                        <div className='align-items-center' style={{ width: '20.20%', padding: '0px 10px 10px 10px' }}>Fee Payment Details</div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '5%' }}>1.3.1</div>
                                        <div className='align-items-center' style={{ width: '13.20%', paddingLeft: '20px' }}>Challan No.</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '17.20%', paddingLeft: '20px', minHeight: '34px' }}></ScrollWrapper>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '5%' }}>1.3.3</div>
                                        <div className='align-items-center' style={{ width: '13.20%', paddingLeft: '20px' }}>Amount</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '19.20%', paddingLeft: '20px', minHeight: '34px' }}></ScrollWrapper>
                                    </div>
                                    <PartsWrapper className='mt-4 mb-3'>
                                        Part II
                                    </PartsWrapper>

                                    <div className='row mt-2'>
                                        <div className='col-md-12'>
                                            <HeadingWrapper className='text-center d-flex justify-content-center'>
                                            (This part is to be filled by a company if its object stated at serial number 1 of the object clause of memorandum is not the principal line of business)
                                            </HeadingWrapper>

                                        </div>
                                    </div>
                                    <div className='row mt-3'>
                                        <div className='col-md-12'>
                                            <HeadingWrapper className='d-flex text-center justify-content-center'>
                                            INTIMATION ABOUT PRINCIPAL LINE OF BUSINESS IN TERMS OF SECTION 27(A)(iii)(b) OR 28(1)(c)(ii) OR 29(c)(ii) (AS THE CASE MAY BE) OF THE ACT
                                            </HeadingWrapper>

                                        </div>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ width: '28%', padding: '0px 10px 10px 10px' }}>Principal line of business of the company as stated at serial number ___ of clause III of the Memorandum of Association is:</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '73%',minHeight:"90px", padding: ' 5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                    </div>

                                    <PartsWrapper className='mt-4 mb-3'>
                                        Part - III
                                    </PartsWrapper>

                                    <div className='row mt-2'>
                                        <div className='col-md-12'>
                                            <HeadingWrapper className='d-flex justify-content-center'>
                                            (This part is to be filled by a company which has changed its principal line of business)
                                            </HeadingWrapper>

                                        </div>
                                    </div>
                                    <div  className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%',fontWeight:"bold" }}>3</div>
                                        <div className='align-items-center' style={{ padding: '0px 10px 10px 10px',fontWeight:"bold" }}> CHANGE IN PRINCIPAL LINE OF BUSINESS</div>
                                    </div>

                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>3.1</div>
                                        <div className='align-items-center' style={{ width: '32%', padding: '0px 10px 10px 10px' }}> Previous Principal line of business</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '65%', padding: ' 5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>3.2</div>
                                        <div className='align-items-center' style={{ width: '32%', padding: '0px 10px 10px 10px' }}>New Principal line of business</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '65%', padding: ' 5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                    </div>

                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}></div>
                                        <div className='align-items-center' style={{ width: '60%', padding: ' 5px 10px' }}> </div>
                                        <div className='align-items-center text-center' style={{ width: '8%', padding: ' 5px' }}> Day</div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center text-center' style={{ width: '8%', padding: ' 5px' }}> Month</div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center text-center' style={{ width: '16%', padding: ' 5px' }}>Year </div>
                                        <div></div>
                                    </div>
                                    <div className='d-flex '>
                                    <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>3.3</div>
                                        <div className='align-items-center' style={{ width: '59%', padding: ' 0px 10px 10px 10px' }}> Date of passing of special resolution</div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ border: '1px solid #121212', width: '4%', padding: ' 5px' }}> </div>
                                        <div className='align-items-center' style={{ paddingLeft: '8px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ border: '1px solid #121212', width: '4%', padding: ' 5px 10px', borderRight: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ paddingLeft: '8px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', border: '1px solid #121212', }}> </div>
                                    </div>

                                    <PartsWrapper className='mt-4 mb-3'>
                                        Part - IV
                                    </PartsWrapper>

                                    <div  className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ padding:"0px 0px 10px 2px", width: '7%',fontWeight:"bold" }}>4.1</div>
                                        <div className='align-items-center' style={{ padding: ' 10px 10px 0px 10px',fontWeight:"bold" }}>Declaration:</div>
                                    </div>
                                    <div  className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ padding:"0px 0px 10px 2px", width: '7%'}}></div>
                                        <div className='align-items-center' style={{ padding: ' 5px 10px'}}>I do hereby solemnly and sincerely declare that the information provided in the form is:</div>
                                    </div>
                                    <div  className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ padding:"0px 0px 10px 2px", width: '7%'}}></div>
                                        <div className='align-items-center' style={{ padding:"0px 0px 10px 2px", width: '1%'}}></div>
                                        <div className='d-flex align-items-baseline' style={{ padding: ' 5px 10px'}}><div style={{marginLeft:"8px"}}>(i)</div><div style={{marginLeft:"28px"}}> true and correct to the best of my knowledge, in consonance with the record as maintained by the Company and nothing has been concealed; and</div></div>
                                    </div>
                                    <div  className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ padding:"0px 0px 10px 2px", width: '7%'}}></div>
                                        <div className='align-items-center' style={{ padding:"0px 0px 10px 2px", width: '2.8%'}}></div>
                                        <div className='d-flex align-items-baseline' style={{ padding: ' 5px 10px'}}><div style={{marginLeft:"8px"}}>(ii)</div><div style={{marginLeft:"24px"}}> hereby reported after complying with and fulfilling all requirements under the relevant provisions of law, rules, regulations, directives, circulars and notifications whichever is applicable</div></div>
                                    </div>
                                    <div  className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ padding:"0px 0px 10px 2px", width: '7%'}}></div>
                                        <div className='align-items-center' style={{ padding:"0px 0px 10px 2px", width: '4%'}}></div>
                                        <div className='d-flex align-items-baseline' style={{ padding: ' 5px 10px'}}><div style={{marginLeft:"8px"}}>(iii)</div><div style={{marginLeft:"20px"}}> New Principal line of business does not fall in any of the categories of business activity which is subject to license, registration, permission or approval under any law as restricted under Section 26(2) or 32(1)(c).</div></div>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>4.2</div>
                                        <div className='align-items-center' style={{ width: '28%', padding: '0px 10px 10px 10px' }}> Name of Authorized Officer with designation/ Authorized Intermediary</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '32.5%', padding: ' 5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '32.5%',borderLeft:"none", padding: ' 5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>4.3</div>
                                        <div className='align-items-center' style={{ width: '28%', padding: '0px 10px 10px 10px' }}>Signatures</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '65%', padding: ' 5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>4.4</div>
                                        <div className='align-items-center' style={{ width: '58%', padding: ' 0px 10px 10px 10px' }}> Registration No of Authorized Intermediary, if applicable</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '35%', padding: ' 5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}></div>
                                        <div className='align-items-center' style={{ width: '55%', padding: ' 5px 10px' }}> </div>
                                        <div className='align-items-center text-center' style={{ width: '8%', padding: ' 5px' }}> Day</div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center text-center' style={{ width: '8%', padding: ' 5px' }}> Month</div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center text-center' style={{ width: '16%', padding: ' 5px' }}>Year </div>
                                        <div></div>
                                    </div>
                                    <div className='d-flex '>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '7%' }}>4.5</div>
                                        <div className='align-items-center' style={{ width: '55%', padding: '0px 10px 10px 10px' }}>Date</div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ border: '1px solid #121212', width: '4%', padding: ' 5px 10px' }}> </div>
                                        <div className='align-items-center' style={{ paddingLeft: '8px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ border: '1px solid #121212', width: '4%', padding: ' 5px 10px', borderRight: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ paddingLeft: '8px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', border: '1px solid #121212', }}> </div>
                                    </div>

                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px',fontWeight:"bold"}}>Enclosure(s):</div>
                                    </div>
                                    <div className='d-flex align-items-center mt-2' style={{ paddingLeft: '2px', }}><div style={{marginLeft:"8px"}}>1.</div> <div style={{marginLeft:'23px'}}>Copy of the Special Resolution with evidence of filing</div></div>
                                    <div className='d-flex align-items-center' style={{ paddingLeft: '2px', }}><div style={{marginLeft:"8px"}}>2.</div> <div style={{marginLeft:'20px'}}>Amended Memorandum & Articles of Association.</div></div>
                                    <div className='d-flex align-items-center' style={{ paddingLeft: '2px', }}><div style={{marginLeft:"8px"}}>3.</div> <div style={{marginLeft:'20px'}}>Original challan or other evidence of payment of fee specified in Seventh Schedule of the Act (not applicable in case of online filing)</div></div>


                                </div>
                            </div>
                        </div>
                    </PDFExport>}
            </div>
            <button disabled={loading} className='btn btn-danger' onClick={(e) => {
                // if(viewPdf)
                //   SetViewPDf(true)
                if (pdfExportComponent.current) {
                    pdfExportComponent.current.save();
                }
            }}>Download PDF</button>
        </div>
    )
}
const PartsWrapper = styled.div`
color: #121212;
text-decoration: underline;
justify-content: center;
display: flex;
margin-right: 15px;
font-size: 17px;
font-weight: bold;
`;
const ScrollWrapper = styled.div`
max-height: 50px;
overflow-y: scroll;
overflow-x: hidden;
::-webkit-scrollbar{
    height: 5px;
    width: 3px;
  }
  
  ::-webkit-scrollbar-track{
    background: #F9F9FB;
  }
  ::-webkit-scrollbar-thumb{
    background: #4E515680;
    border-radius: 5px;
  
  }
`;
const HeadingWrapper = styled.div`
font-size: 17px;
font-weight: bold;
`;
const FormName = styled.div`
color: #121212;
text-decoration: underline;
justify-content: end;
display: flex;
margin-right: 15px;
font-size: 17px;
font-weight: bold;
`;
const Wrapper = styled.div`
table.table-bordered{
    border: 1px solid #121212
     border-color: #121212; 
    // border:1px solid blue;
    // margin-top:20px;
  }
// table.table-bordered > thead > tr > th{
//     // border:1px solid blue;
//     border-color: #121212; 
// }
// table.table-bordered > tbody > tr > td{
//     // border:1px solid blue;
//     borderC-color: #121212; 
// }
 tr td {
    // border:1px solid blue;.
    border-color: #121212; 
}

`;
const WrapperDashed = styled.div`

  td {
    
    // border: 1px dashed #dddcdc;
}

`;