import React, { useState, useEffect } from 'react'
import { PDFExport } from "@progress/kendo-react-pdf";
import PageTemplate from "./page-template";
import {
    getCompanies,
} from "../../../store/services/company.service";
import styled from 'styled-components';
import { TiTick } from "react-icons/ti";
import { numberWithCommas } from 'utilities/utilityFunctions';
export const StatutoryForm9 = ({ data, formTemplate = false }) => {
    const pdfExportComponent = React.useRef(null);
    const [CompanyData, setCompanyData] = useState(null)
    const [loading, setLoading] = useState(false);
    const [fontFamilyForStaticData, setFontFamilyForStaticData] = useState('Montserrat, sans-serif')
    const [fontSizeForStaticData, setFontSizeForStaticData] = useState('14px')
    const [fontfamilyforDynimicData, setFontFamilyForDynmicData] = useState('Segoe Print')
    const [fontColor, setFontColor] = useState('')
    const baseEmail = sessionStorage.getItem("email") || "";
    const [governance, setGovernance] = useState({})
    const getAllCompanies = async () => {
        setLoading(true)
        try {
            const response = await getCompanies(baseEmail);
            if (response.status === 200) {
                response.data.data = response.data.data.filter((item) => item?.active === 'Y')
                const parents = response.data.data;
                const company = parents?.find(ite => ite?.code == data?.company_code)
                let Allperson = company.governance && company.governance != "" ? JSON.parse(company.governance) : []
                let sec = Allperson.find(x => x.role == "Company Secretary" && x?.active?.toLowerCase() != 'n')
                let ceo = Allperson.find(x => x.role == "CEO" && x?.active?.toLowerCase() != 'n')


                if (sec) {
                    setGovernance(sec)
                } else if (ceo) {
                    setGovernance(ceo)
                }
                setCompanyData(company)
                setTimeout(() => {
                    setLoading(false)
                }, 1000);
            }
        } catch (error) {
            setLoading(false)
        }
    };
    useEffect(() => {
        getAllCompanies()
    }, [])
    return (
        <div>
            <div
                style={{ fontFamily: "sans-serif " }}
            >

                {formTemplate ?
                    <PDFExport
                        paperSize="A4"
                        margin="1cm"
                        scale={0.6}
                        fileName={`Statutory Form 9`}
                        pageTemplate={PageTemplate}
                        ref={pdfExportComponent}
                    >
                        <div style={{ fontFamily: `${fontFamilyForStaticData}`, fontSize: `${fontSizeForStaticData}`, fontWeight: 500 }}>
                            <FormName>
                                Form-9
                            </FormName>
                            <div className='row'>
                                <div className='col-md-12' >
                                    <HeadingWrapper className='d-flex justify-content-center'>
                                        THE COMPANIES ACT, 2017
                                    </HeadingWrapper>

                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <HeadingWrapper className='d-flex justify-content-center'>
                                        THE COMPANIES REGULATIONS, 2024
                                    </HeadingWrapper>

                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <div style={{ fontSize: '15px', fontWeight: 'bold' }} className='d-flex justify-content-center'>
                                        [Section 14(1)(c), 167 & 197 and Regulations 50, 54 & 30]
                                    </div>
                                </div>
                            </div>

                            <div className='row mt-4'>
                                <div className='col-md-12'>
                                    <HeadingWrapper className='text-center d-flex justify-content-center'>
                                        PARTICULARS OF DIRECTIORS AND OFFICERS, INCLUDING THE CHIEF
                                    </HeadingWrapper>
                                    <HeadingWrapper className='text-center d-flex justify-content-center'>
                                        EXECUTIVE, SECRETARY, CHEIF FINANCIAL OFFICER, AUDITORS, LEGAL
                                    </HeadingWrapper>
                                    <HeadingWrapper className='text-center d-flex justify-content-center'>
                                        ADVISER AND IN CASE OF SINGLE MEMBER COMPANY, NOMINEE OF SOLE
                                    </HeadingWrapper>
                                    <HeadingWrapper className='text-center d-flex justify-content-center'>
                                        MEMBER OR OF ANY CHANGE THEREIN
                                    </HeadingWrapper>
                                </div>
                            </div>
                            <PartsWrapper className='mt-4 mb-3'>
                                PART-I
                            </PartsWrapper>
                            <div className='row'>
                                <div className='col-md-12' style={{ padding: '25px 50px', wordWrap: 'break-word' }}>
                                    <div> <i>(Please complete in bold capital letters)</i></div>


                                    <div className='d-flex' >
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>1.1</div>
                                        <div className='align-items-center' style={{ width: '28%', padding: '0px 10px 10px 10px' }}>CUIN (Registration Number)  </div>
                                        <div className='align-items-center  d-flex'  >{
                                            '1234 552'.split("")?.map((item, idx) => {
                                                if (item == ' ') return
                                                return <div style={{ minWidth: '30px', minHeight: '34px', border: '1px solid #121212', borderLeft: idx != 0 ? "none" : "1px solid #121212", padding: '6px 9px' }}></div>
                                            })
                                        }
                                        </div>
                                    </div>



                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>1.2</div>
                                        <div className='align-items-center' style={{ width: '22%', padding: '0px 10px 10px 10px' }}>Name of the Company  </div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '71%', padding: '0px 10px 10px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                    </div>




                                    <div className='d-flex  mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>1.3</div>
                                        <div className='align-items-center' style={{ width: '20.20%', padding: '0px 10px 10px 10px' }}>Fee payment Details</div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '5%' }}>1.3.1</div>
                                        <div className='align-items-center' style={{ width: '13.20%', paddingLeft: '20px' }}>Challan No.</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '17.20%', paddingLeft: '20px', minHeight: '34px' }}></ScrollWrapper>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '5%' }}>1.3.2</div>
                                        <div className='align-items-center' style={{ width: '13.20%', paddingLeft: '20px' }}>Amount</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '19.20%', paddingLeft: '20px', minHeight: '34px' }}></ScrollWrapper>
                                    </div>
                                    <PartsWrapper className='mt-4 mb-3'>
                                        PART-II
                                    </PartsWrapper>
                                    <div className='mb-2'>
                                        <div className='d-flex font-size-larger'>
                                            <span className='form-sr-no'>2.</span><strong className='ml-2'>Particulars*:</strong>
                                        </div>
                                    </div>
                                    <Wrapper className="table-responsive mt-0" >
                                        <table className="table table-bordered"
                                            style={{ fontFamily: "Montserrat rev=1 !important" }}>
                                            <tbody>
                                                <tr>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}>Present Name in Full</td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}>NIC No. of Pakistani, or NICOP No. for overseas Pakistanis, or Passport No. in case of a foreigner</td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}>Usual residential address</td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}>Designation</td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}>Nationality**</td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}>Business Occupation***(if any)</td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}>Date of present appointment or change</td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}>Mode of appointment /change/ any other Remarks****</td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}>Nature of directorship (nominee/other*****)</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ verticalAlign: 'sub', fontWeight: "bold", width: '7%', }} >(a)</td>
                                                    <td style={{ verticalAlign: 'sub', fontWeight: "bold", width: '7%', }}>(b)</td>
                                                    <td style={{ verticalAlign: 'sub', fontWeight: "bold", width: '7%', }}>(c)</td>
                                                    <td style={{ verticalAlign: 'sub', fontWeight: "bold", width: '7%', }}>(d)</td>
                                                    <td style={{ verticalAlign: 'sub', fontWeight: "bold", width: '7%', }}>(e)</td>
                                                    <td style={{ verticalAlign: 'sub', fontWeight: "bold", width: '7%', }}>(f)</td>
                                                    <td style={{ verticalAlign: 'sub', fontWeight: "bold", width: '7%', }}>(g)</td>
                                                    <td style={{ verticalAlign: 'sub', fontWeight: "bold", width: '7%', }}>(h)</td>
                                                    <td style={{ verticalAlign: 'sub', fontWeight: "bold", width: '7%', }}>(i)</td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={9} style={{ verticalAlign: 'sub', width: '7%',fontWeight:"bold" }}>2.1 New appointment/election:</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}></td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={9} style={{ verticalAlign: 'sub', width: '7%',fontWeight:"bold" }}>2.2 Ceasing of Office/Retirement/Registration/Removal/Death/Disqualification:</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}></td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={9} style={{ verticalAlign: 'sub', width: '7%',fontWeight:"bold" }}>2.3 Any other change in particulars relating to columns (a) to (f) above:</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </Wrapper>
                                    <div className='row mb-3'>
                                        <div className='col-1 font-size-larger'>*</div>
                                        <div className='col-11'>
                                            <i>In the case of a firm, the full name, address and above-mentioned particulars of each partner, and the date on which each became a pertner.</i>
                                        </div>
                                        <div className='col-1 font-size-larger'>**</div>
                                        <div className='col-11'>
                                            <i>In case the nationality is not the nationality of origin, provide the nationality of origin as well.</i>
                                        </div>
                                        <div className='col-1 font-size-larger'>***</div>
                                        <div className='col-11'>
                                            <i>Also provide particulars of other directorships of offices held, if any.</i>
                                        </div>
                                        <div className='col-1 font-size-larger'>****</div>
                                        <div className='col-11'>
                                            <i>In case of listed company also mention whether the person was appointed as <q>independent, or executive or non-executive of female director quota</q></i>
                                        </div>
                                        <div className='col-1 font-size-larger'>*****</div>
                                        <div className='col-11'>
                                            <i>In case of a director nominated by a member or creditor the name of such nominating or appointing body shall also be mentioned in column (i), and a copy of resolution from the nominating or appointing body be attched.</i>
                                        </div>
                                    </div>
                                    <PartsWrapper className='mt-4 mb-3'>
                                            PART-III
                                        </PartsWrapper>
                                    <div className='row'>
                                        {/* part III */}
                                      
                                        <div className='col-12'>
                                            <div className='d-flex font-size-larger'>
                                                <span className='form-sr-no'>3.</span><strong className='ml-2'>Particulars of nominee in case of single member company for the purpose of section 14 or any change therein:</strong>
                                            </div>
                                        </div>
                                    </div>
                                    <Wrapper className="table-responsive mt-0" >
                                        <table className="table table-bordered"
                                            style={{ fontFamily: "Montserrat rev=1 !important" }}>
                                            <tbody>
                                                <tr>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}>Present Name in Full</td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}>NIC No. of Pakistani, or NICOP No. for overseas Pakistanis, or Passport No. in case of a foreigner</td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}>Nationality**</td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}>Usual residential address, Telephone number & Email address</td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}>Date of appointment or change</td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}>Relationship of Nominee with single member</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ verticalAlign: 'sub', width: '7%',fontWeight:"bold" }}>(a)</td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%',fontWeight:"bold" }}>(b)</td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%',fontWeight:"bold" }}>(c)</td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%',fontWeight:"bold" }}>(d)</td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%',fontWeight:"bold" }}>(e)</td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%',fontWeight:"bold" }}>(f)</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </Wrapper>
                                    <PartsWrapper className='mt-4 mb-3'>
                                            PART-IV
                                        </PartsWrapper>
                                        <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>4.1</div>
                                        <div className='align-items-center' style={{ padding: '5px 10px 0px 10px', }}>Declaration</div>
                                        </div>
                                    <div className='d-flex'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '1%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ padding: ' 0px 10px 10px 10px' }}>I do hereby solemnly, and sincerely declare that the information provided in the form is:</div>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '1%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '0%' }}></div>
                                        <div className='align-items-start d-flex' style={{ padding: ' 5px 10px', gap: "12px" }}><div>
                                            (i)</div>
                                            <div style={{ marginLeft: '14px' }}>true and correct to the best of my knowledge, in consonance with the record as maintained by the Company and nothing has been concealed; and </div>
                                        </div>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '1%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '1%' }}></div>
                                        <div className='align-items-start d-flex' style={{ padding: ' 5px 10px', gap: "12px" }}><div>
                                            (ii)</div>
                                            <div style={{ marginLeft: '11px' }}>hereby reported after complying with and fulfilling all requirements under the relevant provisions of law, rules, regulations, directives, circulars and notifications whichever is applicable.</div>
                                        </div>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>4.2</div>
                                        <div className='align-items-center' style={{ width: '28%', padding: '0px 10px 10px 10px' }}> Name of Authorized Officer with 
                                        designation/ Authorized Intermediary</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '32.5%', padding: ' 5px 10px', }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', borderLeft: "none", width: '32.5%', padding: ' 5px 10px', }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>4.3</div>
                                        <div className='align-items-center' style={{ width: '28%', padding: '0px 10px 10px 10px' }}>Signatures </div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '65%', padding: ' 5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>4.4</div>
                                        <div className='align-items-center' style={{ width: '40%', padding: ' 0px 10px 10px 10px' }}> Registration No of Authorized Intermediary, if applicable</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '53%', padding: ' 5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ width: '55%', padding: ' 5px 10px' }}> </div>
                                        <div className='align-items-center text-center' style={{ width: '8%', padding: ' 5px' }}> Day</div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center text-center' style={{ width: '8%', padding: ' 5px' }}> Month</div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center text-center' style={{ width: '16%', padding: ' 5px' }}>Year </div>
                                        <div></div>
                                    </div>
                                    <div className='d-flex '>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>3.5</div>
                                        <div className='align-items-center' style={{ width: '55%', padding: ' 0px 10px 10px 10px' }}>Date</div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ border: '1px solid #121212', width: '4%', padding: ' 5px 10px' }}> </div>
                                        <div className='align-items-center' style={{ paddingLeft: '8px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ border: '1px solid #121212', width: '4%', padding: ' 5px 10px', borderRight: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ paddingLeft: '8px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', border: '1px solid #121212', }}> </div>
                                    </div>
                                    <div className='d-flex my-2' style={{ fontWeight: "bold" }}>
                                        Enclosure:
                                    </div>
                                    <div style={{ marginLeft: "1rem" }} className='d-flex align-items-baseline'>
                                        <div style={{ marginLeft: "5px" }}>1.</div><div style={{ marginLeft: '23px' }}> Consent of each Director / Chief Executive upon their appointment/re-appointment/election on the prescribed appendix </div>
                                    </div>
                                    <div style={{ marginLeft: "1rem" }} className='d-flex align-items-baseline'>
                                        <div style={{ marginLeft: "5px" }}>2.</div><div style={{ marginLeft: '20px' }}> Copy of nomination letter from the appointing authority in case the person is nominated under any of the provisions of the Act.</div>
                                    </div>
                                    <div style={{ marginLeft: "1rem" }} className='d-flex align-items-baseline'>
                                        <div style={{ marginLeft: "5px" }}>3.</div><div style={{ marginLeft: '20px' }}> Copy of NOC/approval from concerned department (Ministries or Regulatory Authorities or SECP) is required for companies with licensing activities etc.</div>
                                    </div>
                                    <div style={{ marginLeft: "1rem" }} className='d-flex align-items-baseline'>
                                        <div style={{ marginLeft: "5px" }}>4.</div><div style={{ marginLeft: '20px' }}> Consent of auditor, in case of appointment/re-appointment of auditor</div>
                                    </div>
                                    <div style={{ marginLeft: "1rem" }} className='d-flex align-items-baseline'>
                                        <div style={{ marginLeft: "5px" }}>5.</div><div style={{ marginLeft: '20px' }}>  Copy of resignation letter (in case of resignation of a director or chief executive), duly signed by resigning director or chief executive, verified through an affidavit on stamp paper duly signed by the person who has signed this Form and attested by an oath commissioner and witnessed, be attached.</div>
                                    </div>
                                    <div style={{ marginLeft: "1rem" }} className='d-flex align-items-baseline'>
                                        <div style={{ marginLeft: "5px" }}>6.</div><div style={{ marginLeft: '20px' }}> If the person appointed is a foreigner, please provide 9 sets of Bio-Data & valid Passport for onward submission to Ministry of Interior (MOI) for clearance along with undertaking in original.</div>
                                    </div>
                                    <div style={{ marginLeft: "1rem" }} className='d-flex align-items-baseline'>
                                        <div style={{ marginLeft: "5px" }}>7.</div><div style={{ marginLeft: '20px' }}>Sufficient evidence in case of removal/ death / disqualification of Director/ Chief Executive may be attached.</div>
                                    </div>
                                    <div style={{ marginLeft: "1rem" }} className='d-flex align-items-baseline'>
                                        <div style={{ marginLeft: "5px" }}>8.</div><div style={{ marginLeft: '20px' }}> Any other documents, as required by the registrar.</div>
                                    </div>
                                    <div style={{ marginLeft: "1rem" }} className='d-flex align-items-baseline'>
                                        <div style={{ marginLeft: "5px" }}>9.</div><div style={{ marginLeft: '20px' }}> Original challan or other evidence of payment of fee specified in Seventh Schedule of the Act (not applicable in case of online filing)</div>
                                    </div>



                                </div>
                            </div>
                        </div>
                    </PDFExport>
                    : <PDFExport
                        paperSize="A4"
                        margin="1cm"
                        scale={0.6}
                        fileName={`Statutory Form 9`}
                        pageTemplate={PageTemplate}
                        ref={pdfExportComponent}
                    >
                       <div style={{ fontFamily: `${fontFamilyForStaticData}`, fontSize: `${fontSizeForStaticData}`, fontWeight: 500 }}>
                            <FormName>
                                Form-9
                            </FormName>
                            <div className='row'>
                                <div className='col-md-12' >
                                    <HeadingWrapper className='d-flex justify-content-center'>
                                        THE COMPANIES ACT, 2017
                                    </HeadingWrapper>

                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <HeadingWrapper className='d-flex justify-content-center'>
                                        THE COMPANIES REGULATIONS, 2024
                                    </HeadingWrapper>

                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <div style={{ fontSize: '15px', fontWeight: 'bold' }} className='d-flex justify-content-center'>
                                        [Section 14(1)(c), 167 & 197 and Regulations 50, 54 & 30]
                                    </div>
                                </div>
                            </div>

                            <div className='row mt-4'>
                                <div className='col-md-12'>
                                    <HeadingWrapper className='text-center d-flex justify-content-center'>
                                        PARTICULARS OF DIRECTIORS AND OFFICERS, INCLUDING THE CHIEF
                                    </HeadingWrapper>
                                    <HeadingWrapper className='text-center d-flex justify-content-center'>
                                        EXECUTIVE, SECRETARY, CHEIF FINANCIAL OFFICER, AUDITORS, LEGAL
                                    </HeadingWrapper>
                                    <HeadingWrapper className='text-center d-flex justify-content-center'>
                                        ADVISER AND IN CASE OF SINGLE MEMBER COMPANY, NOMINEE OF SOLE
                                    </HeadingWrapper>
                                    <HeadingWrapper className='text-center d-flex justify-content-center'>
                                        MEMBER OR OF ANY CHANGE THEREIN
                                    </HeadingWrapper>
                                </div>
                            </div>
                            <PartsWrapper className='mt-4 mb-3'>
                                PART-I
                            </PartsWrapper>
                            <div className='row'>
                                <div className='col-md-12' style={{ padding: '25px 50px', wordWrap: 'break-word' }}>
                                    <div> <i>(Please complete in bold capital letters)</i></div>


                                    <div className='d-flex' >
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>1.1</div>
                                        <div className='align-items-center' style={{ width: '28%', padding: '0px 10px 10px 10px' }}>CUIN (Registration Number)  </div>
                                        <div className='align-items-center  d-flex'  >{
                                            '1234 552'.split("")?.map((item, idx) => {
                                                if (item == ' ') return
                                                return <div style={{ minWidth: '30px', minHeight: '34px', border: '1px solid #121212', borderLeft: idx != 0 ? "none" : "1px solid #121212", padding: '6px 9px' }}></div>
                                            })
                                        }
                                        </div>
                                    </div>



                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>1.2</div>
                                        <div className='align-items-center' style={{ width: '22%', padding: '0px 10px 10px 10px' }}>Name of the Company  </div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '71%', padding: '0px 10px 10px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                    </div>




                                    <div className='d-flex  mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>1.3</div>
                                        <div className='align-items-center' style={{ width: '20.20%', padding: '0px 10px 10px 10px' }}>Fee payment Details</div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '5%' }}>1.3.1</div>
                                        <div className='align-items-center' style={{ width: '13.20%', paddingLeft: '20px' }}>Challan No.</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '17.20%', paddingLeft: '20px', minHeight: '34px' }}></ScrollWrapper>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '5%' }}>1.3.2</div>
                                        <div className='align-items-center' style={{ width: '13.20%', paddingLeft: '20px' }}>Amount</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '19.20%', paddingLeft: '20px', minHeight: '34px' }}></ScrollWrapper>
                                    </div>
                                    <PartsWrapper className='mt-4 mb-3'>
                                        PART-II
                                    </PartsWrapper>
                                    <div className='mb-2'>
                                        <div className='d-flex font-size-larger'>
                                            <span className='form-sr-no'>2.</span><strong className='ml-2'>Particulars*:</strong>
                                        </div>
                                    </div>
                                    <Wrapper className="table-responsive mt-0" >
                                        <table className="table table-bordered"
                                            style={{ fontFamily: "Montserrat rev=1 !important" }}>
                                            <tbody>
                                                <tr>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}>Present Name in Full</td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}>NIC No. of Pakistani, or NICOP No. for overseas Pakistanis, or Passport No. in case of a foreigner</td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}>Usual residential address</td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}>Designation</td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}>Nationality**</td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}>Business Occupation***(if any)</td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}>Date of present appointment or change</td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}>Mode of appointment /change/ any other Remarks****</td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}>Nature of directorship (nominee/other*****)</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ verticalAlign: 'sub', fontWeight: "bold", width: '7%', }} >(a)</td>
                                                    <td style={{ verticalAlign: 'sub', fontWeight: "bold", width: '7%', }}>(b)</td>
                                                    <td style={{ verticalAlign: 'sub', fontWeight: "bold", width: '7%', }}>(c)</td>
                                                    <td style={{ verticalAlign: 'sub', fontWeight: "bold", width: '7%', }}>(d)</td>
                                                    <td style={{ verticalAlign: 'sub', fontWeight: "bold", width: '7%', }}>(e)</td>
                                                    <td style={{ verticalAlign: 'sub', fontWeight: "bold", width: '7%', }}>(f)</td>
                                                    <td style={{ verticalAlign: 'sub', fontWeight: "bold", width: '7%', }}>(g)</td>
                                                    <td style={{ verticalAlign: 'sub', fontWeight: "bold", width: '7%', }}>(h)</td>
                                                    <td style={{ verticalAlign: 'sub', fontWeight: "bold", width: '7%', }}>(i)</td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={9} style={{ verticalAlign: 'sub', width: '7%',fontWeight:"bold" }}>2.1 New appointment/election:</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}></td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={9} style={{ verticalAlign: 'sub', width: '7%',fontWeight:"bold" }}>2.2 Ceasing of Office/Retirement/Registration/Removal/Death/Disqualification:</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}></td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={9} style={{ verticalAlign: 'sub', width: '7%',fontWeight:"bold" }}>2.3 Any other change in particulars relating to columns (a) to (f) above:</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </Wrapper>
                                    <div className='row mb-3'>
                                        <div className='col-1 font-size-larger'>*</div>
                                        <div className='col-11'>
                                            <i>In the case of a firm, the full name, address and above-mentioned particulars of each partner, and the date on which each became a pertner.</i>
                                        </div>
                                        <div className='col-1 font-size-larger'>**</div>
                                        <div className='col-11'>
                                            <i>In case the nationality is not the nationality of origin, provide the nationality of origin as well.</i>
                                        </div>
                                        <div className='col-1 font-size-larger'>***</div>
                                        <div className='col-11'>
                                            <i>Also provide particulars of other directorships of offices held, if any.</i>
                                        </div>
                                        <div className='col-1 font-size-larger'>****</div>
                                        <div className='col-11'>
                                            <i>In case of listed company also mention whether the person was appointed as <q>independent, or executive or non-executive of female director quota</q></i>
                                        </div>
                                        <div className='col-1 font-size-larger'>*****</div>
                                        <div className='col-11'>
                                            <i>In case of a director nominated by a member or creditor the name of such nominating or appointing body shall also be mentioned in column (i), and a copy of resolution from the nominating or appointing body be attched.</i>
                                        </div>
                                    </div>
                                    <PartsWrapper className='mt-4 mb-3'>
                                            PART-III
                                        </PartsWrapper>
                                    <div className='row'>
                                        {/* part III */}
                                      
                                        <div className='col-12'>
                                            <div className='d-flex font-size-larger'>
                                                <span className='form-sr-no'>3.</span><strong className='ml-2'>Particulars of nominee in case of single member company for the purpose of section 14 or any change therein:</strong>
                                            </div>
                                        </div>
                                    </div>
                                    <Wrapper className="table-responsive mt-0" >
                                        <table className="table table-bordered"
                                            style={{ fontFamily: "Montserrat rev=1 !important" }}>
                                            <tbody>
                                                <tr>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}>Present Name in Full</td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}>NIC No. of Pakistani, or NICOP No. for overseas Pakistanis, or Passport No. in case of a foreigner</td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}>Nationality**</td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}>Usual residential address, Telephone number & Email address</td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}>Date of appointment or change</td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}>Relationship of Nominee with single member</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ verticalAlign: 'sub', width: '7%',fontWeight:"bold" }}>(a)</td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%',fontWeight:"bold" }}>(b)</td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%',fontWeight:"bold" }}>(c)</td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%',fontWeight:"bold" }}>(d)</td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%',fontWeight:"bold" }}>(e)</td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%',fontWeight:"bold" }}>(f)</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}></td>
                                                    <td style={{ verticalAlign: 'sub', width: '7%', }}></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </Wrapper>
                                    <PartsWrapper className='mt-4 mb-3'>
                                            PART-IV
                                        </PartsWrapper>
                                        <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>4.1</div>
                                        <div className='align-items-center' style={{ padding: '5px 10px 0px 10px', }}>Declaration</div>
                                        </div>
                                    <div className='d-flex'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '1%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ padding: ' 0px 10px 10px 10px' }}>I do hereby solemnly, and sincerely declare that the information provided in the form is:</div>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '1%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '0%' }}></div>
                                        <div className='align-items-start d-flex' style={{ padding: ' 5px 10px', gap: "12px" }}><div>
                                            (i)</div>
                                            <div style={{ marginLeft: '14px' }}>true and correct to the best of my knowledge, in consonance with the record as maintained by the Company and nothing has been concealed; and </div>
                                        </div>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '1%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '1%' }}></div>
                                        <div className='align-items-start d-flex' style={{ padding: ' 5px 10px', gap: "12px" }}><div>
                                            (ii)</div>
                                            <div style={{ marginLeft: '11px' }}>hereby reported after complying with and fulfilling all requirements under the relevant provisions of law, rules, regulations, directives, circulars and notifications whichever is applicable.</div>
                                        </div>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>4.2</div>
                                        <div className='align-items-center' style={{ width: '28%', padding: '0px 10px 10px 10px' }}> Name of Authorized Officer with 
                                        designation/ Authorized Intermediary</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '32.5%', padding: ' 5px 10px', }}></ScrollWrapper>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', borderLeft: "none", width: '32.5%', padding: ' 5px 10px', }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>4.3</div>
                                        <div className='align-items-center' style={{ width: '28%', padding: '0px 10px 10px 10px' }}>Signatures </div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '65%', padding: ' 5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>4.4</div>
                                        <div className='align-items-center' style={{ width: '40%', padding: ' 0px 10px 10px 10px' }}> Registration No of Authorized Intermediary, if applicable</div>
                                        <ScrollWrapper className='align-items-center' style={{ border: '1px solid #121212', width: '53%', padding: ' 5px 10px', fontFamily: `${fontfamilyforDynimicData}` }}></ScrollWrapper>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ width: '55%', padding: ' 5px 10px' }}> </div>
                                        <div className='align-items-center text-center' style={{ width: '8%', padding: ' 5px' }}> Day</div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center text-center' style={{ width: '8%', padding: ' 5px' }}> Month</div>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}></div>
                                        <div className='align-items-center text-center' style={{ width: '16%', padding: ' 5px' }}>Year </div>
                                        <div></div>
                                    </div>
                                    <div className='d-flex '>
                                        <div className='align-items-center' style={{ paddingLeft: '2px', width: '3%' }}>3.5</div>
                                        <div className='align-items-center' style={{ width: '55%', padding: ' 0px 10px 10px 10px' }}>Date</div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ border: '1px solid #121212', width: '4%', padding: ' 5px 10px' }}> </div>
                                        <div className='align-items-center' style={{ paddingLeft: '8px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ border: '1px solid #121212', width: '4%', padding: ' 5px 10px', borderRight: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ paddingLeft: '8px', width: '3%' }}></div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', borderLeft: '1px solid #121212', borderTop: '1px solid #121212', borderBottom: '1px solid #121212' }}> </div>
                                        <div className='align-items-center' style={{ width: '4%', padding: ' 5px 10px', border: '1px solid #121212', }}> </div>
                                    </div>
                                    <div className='d-flex my-2' style={{ fontWeight: "bold" }}>
                                        Enclosure:
                                    </div>
                                    <div style={{ marginLeft: "1rem" }} className='d-flex align-items-baseline'>
                                        <div style={{ marginLeft: "5px" }}>1.</div><div style={{ marginLeft: '23px' }}> Consent of each Director / Chief Executive upon their appointment/re-appointment/election on the prescribed appendix </div>
                                    </div>
                                    <div style={{ marginLeft: "1rem" }} className='d-flex align-items-baseline'>
                                        <div style={{ marginLeft: "5px" }}>2.</div><div style={{ marginLeft: '20px' }}> Copy of nomination letter from the appointing authority in case the person is nominated under any of the provisions of the Act.</div>
                                    </div>
                                    <div style={{ marginLeft: "1rem" }} className='d-flex align-items-baseline'>
                                        <div style={{ marginLeft: "5px" }}>3.</div><div style={{ marginLeft: '20px' }}> Copy of NOC/approval from concerned department (Ministries or Regulatory Authorities or SECP) is required for companies with licensing activities etc.</div>
                                    </div>
                                    <div style={{ marginLeft: "1rem" }} className='d-flex align-items-baseline'>
                                        <div style={{ marginLeft: "5px" }}>4.</div><div style={{ marginLeft: '20px' }}> Consent of auditor, in case of appointment/re-appointment of auditor</div>
                                    </div>
                                    <div style={{ marginLeft: "1rem" }} className='d-flex align-items-baseline'>
                                        <div style={{ marginLeft: "5px" }}>5.</div><div style={{ marginLeft: '20px' }}>  Copy of resignation letter (in case of resignation of a director or chief executive), duly signed by resigning director or chief executive, verified through an affidavit on stamp paper duly signed by the person who has signed this Form and attested by an oath commissioner and witnessed, be attached.</div>
                                    </div>
                                    <div style={{ marginLeft: "1rem" }} className='d-flex align-items-baseline'>
                                        <div style={{ marginLeft: "5px" }}>6.</div><div style={{ marginLeft: '20px' }}> If the person appointed is a foreigner, please provide 9 sets of Bio-Data & valid Passport for onward submission to Ministry of Interior (MOI) for clearance along with undertaking in original.</div>
                                    </div>
                                    <div style={{ marginLeft: "1rem" }} className='d-flex align-items-baseline'>
                                        <div style={{ marginLeft: "5px" }}>7.</div><div style={{ marginLeft: '20px' }}>Sufficient evidence in case of removal/ death / disqualification of Director/ Chief Executive may be attached.</div>
                                    </div>
                                    <div style={{ marginLeft: "1rem" }} className='d-flex align-items-baseline'>
                                        <div style={{ marginLeft: "5px" }}>8.</div><div style={{ marginLeft: '20px' }}> Any other documents, as required by the registrar.</div>
                                    </div>
                                    <div style={{ marginLeft: "1rem" }} className='d-flex align-items-baseline'>
                                        <div style={{ marginLeft: "5px" }}>9.</div><div style={{ marginLeft: '20px' }}> Original challan or other evidence of payment of fee specified in Seventh Schedule of the Act (not applicable in case of online filing)</div>
                                    </div>



                                </div>
                            </div>
                        </div>
                    </PDFExport>}
            </div>
            <button disabled={loading} className='btn btn-danger' onClick={(e) => {
                // if(viewPdf)
                //   SetViewPDf(true)
                if (pdfExportComponent.current) {
                    pdfExportComponent.current.save();
                }
            }}>Download PDF</button>
        </div>
    )
}
const PartsWrapper = styled.div`
color: #121212;
text-decoration: underline;
justify-content: center;
display: flex;
margin-right: 15px;
font-size: 17px;
font-weight: bold;
`;
const ScrollWrapper = styled.div`
max-height: 50px;
overflow-y: scroll;
overflow-x: hidden;
::-webkit-scrollbar{
    height: 5px;
    width: 3px;
  }
  
  ::-webkit-scrollbar-track{
    background: #F9F9FB;
  }
  ::-webkit-scrollbar-thumb{
    background: #4E515680;
    border-radius: 5px;
  
  }
`;
const HeadingWrapper = styled.div`
font-size: 17px;
font-weight: bold;
`;
const FormName = styled.div`
color: #121212;
text-decoration: underline;
justify-content: end;
display: flex;
margin-right: 15px;
font-size: 17px;
font-weight: bold;
`;
const Wrapper = styled.div`
table.table-bordered{
    border: 1px solid #121212
     border-color: #121212; 
    // border:1px solid blue;
    // margin-top:20px;
  }
// table.table-bordered > thead > tr > th{
//     // border:1px solid blue;
//     border-color: #121212; 
// }
// table.table-bordered > tbody > tr > td{
//     // border:1px solid blue;
//     borderC-color: #121212; 
// }
 tr td {
    // border:1px solid blue;.
    border-color: #121212; 
}

`;
const WrapperDashed = styled.div`

  td {
    
    // border: 1px dashed #dddcdc;
}

`;