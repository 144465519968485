import * as yup from "yup";
import { IsJsonString } from "../../utilities/utilityFunctions";

export const addAlertValidationSchema = yup.object().shape({
  // Company Details
  title: yup.string().required("Enter Title"),
  section: yup.string(),
  regulations: yup.string(),
  deadline_days: yup.string(),
  start_days: yup.string(),
  description: yup.string(),
  days_dependent: yup.string(),
  notify_days: yup.string(),
  notify_via: yup.string(),
  active: yup.string(),
  dependent: yup.string(),
  frequency: yup.string(),
  level_ddl: yup.string(),
  company_type: yup.string().required("Select Company Type"),
  quarter: yup.string(),
  secp_form: yup.string(),
  requirement_category: yup.string(),
  non_compliance_regulation: yup.string(),
  form_code: yup.string().required("Code is Required"),
});
export const EditRequirmentScema = (requirment) =>
  yup.object().shape({
    title: yup.string().required("Enter Title").default(requirment.title),
    section: yup.string().default(requirment?.sections || ""),
    start_days: yup.string().default(requirment?.start_days || ""),
    deadline_days: yup.string().default(requirment?.deadline_days || ""),
    description: yup.string().default(requirment?.description || ""),
    regulations: yup.string().default(requirment.regulations),
    days_dependent: yup.string().default(requirment.days_to_dependent),
    notify_days: yup.string().default(requirment.notify_days),
    notify_via: yup.string().default(requirment.notify_via),
    active: yup.string().default(requirment.active),
    dependent: yup.string().default(requirment.dependent),
    frequency: yup.string().default(requirment.frequency),
    level_ddl: yup.string().default(requirment.level),
    company_type: yup
      .string()
      .required("Select Company Type")
      .default(requirment.company_type),
    quarter: yup.string().default(requirment?.quarter || ""),
    secp_form: yup.string().default(requirment?.secp_form || ""),
    requirement_category: yup.string(requirment?.requirement_category || ""),

    non_compliance_regulation: yup
      .string()
      .default(requirment?.non_compliance_regulation || ""),
    form_code: yup
      .string()
      .required("Code is Required")
      .default(requirment.code),
  });

export const addEventValidationSchema = yup.object().shape({
  // company_code: yup.object().required('Select Company'),
  title: yup.string().required("Enter Title"),
  start_date: yup.string(),
  deadline_date: yup.string(),
  reminder_days: yup.string(),
  book_closing_from: yup.string(),
  book_closing_to: yup.string(),
  action_date: yup.string(),
  previous_action_date: yup.string(),
  status: yup.string(),
  comment: yup.string(),
  // voting_required: yup.boolean(),
  attendance_required: yup.boolean(),
  eod_voting: yup.boolean(),
  eod_evoting: yup.boolean(),
  sr_voting: yup.boolean(),
  sr_evoting: yup.boolean(),
  book_closure: yup.boolean(),
  meeting_required: yup.boolean(),

  closed: yup.string(),
  action_by_text: yup.string(),
  request_code: yup.object().required("Select Requirement"),
  auto_generated: yup.string(),
  chairman: yup.string(),
  event_location: yup.string(),
});
export const EditEventScema = (event) =>
  yup.object().shape({
    // company_code: yup
    //   .object()
    //   .default(event?.company_code)
    //   .required("Select Company"),
    title: yup.string().default(event.title).required("Enter Title"),
    start_date: yup.string().default(event.start_date),
    deadline_date: yup
      .string()
      .default(event.deadline_date)
      .required("Deadline Date is required"),
    reminder_days: yup.string().default(event.reminder_days),
    action_date: yup.string().default(event.action_date),
    previous_action_date: yup.string().default(event.previous_action_date),
    status: yup.string().default(event.status),
    comment: yup.string().default(event.comments),
    closed: yup.string().default(event.closed),
    action_by_text: yup.string().default(event.action_by),
    request_code: yup
      .object()
      .default(event?.request_code)
      .required("Select Requirement"),
    auto_generated: yup.string().default(event?.auto_generated),
    chairman: yup.string().default(event?.chairman || ""),
    event_location: yup.string().default(event.location || ""),
    effect_from: yup.string().default(event?.effect_from || ""),
    last_date: yup.string().default(event?.last_date || ""),
    agm_date: yup.string().default(event?.agm_date || ""),
    agm_end_date: yup.string().default(event?.agm_end_date || ""),
    application_from: yup.string().default(event?.application_from || ""),
    application_to: yup.string().default(event?.application_to || ""),
    authorization_from: yup.string().default(event?.authorization_from || ""),
    authorization_to: yup.string().default(event?.authorization_to || ""),
    election_from: yup.string().default(event?.election_from || ""),
    election_to: yup.string().default(event?.election_to || ""),
    pol_date: yup.string().default(event?.pol_date || ""),
    postal_ballot_last_date: yup
      .string()
      .default(event?.postal_ballot_last_date || ""),
    agenda_from: yup.string().default(event?.agenda_from || ""),
    agenda_to: yup.string().default(event?.agenda_to || ""),
    meeting_start_date: yup.string().default(event?.meeting_start_date || ""),
    meeting_end_date: yup.string().default(event?.meeting_end_date || ""),
    online_conference: yup.string().default(event?.online_conference || ""),
    book_closing_from: yup.string().default(event?.book_closing_from || ""),
    book_closing_to: yup.string().default(event?.book_closing_to || ""),
    voting_required: yup.boolean().default(event?.voting_required || false),
    book_closure: yup.boolean(),
    meeting_required: yup.boolean(event?.meeting_required || false),
  });
export const ViewEventScema = (event) =>
  yup.object().shape({
    // company_code: yup
    //   .string()
    //   .default(event?.company_code)
    //   .required("Select Company"),
    title: yup.string().default(event.title).required("Enter Title"),
    start_date: yup.string().default(event.start_date),
    deadline_date: yup.string().default(event.deadline_date),
    reminder_days: yup.string().default(event.reminder_days),
    action_date: yup.string().default(event.action_date),
    previous_action_date: yup.string().default(event.previous_action_date),
    status: yup.string().default(event.status),
    comment: yup.string().default(event.comments),
    closed: yup.string().default(event.closed),
    action_by_text: yup.string().default(event.action_by),
    req_code: yup
      .object()
      .default(event?.request_code)
      .required("Select Requirement"),
    auto_generated: yup.string().default(event?.auto_generated),
    folio_no: yup.string().default(event.chairman || ""),
    event_location: yup
      .string()
      .default(event.location || "")
      .required("Enter Event Location"),
    effect_from: yup.string().default(event?.effect_from || ""),
    last_date: yup.string().default(event?.last_date || ""),
    agm_date: yup.string().default(event?.agm_date || ""),
    agm_end_date: yup.string().default(event?.agm_end_date || ""),
    application_from: yup.string().default(event?.application_from || ""),
    application_to: yup.string().default(event?.application_to || ""),
    authorization_from: yup.string().default(event?.authorization_from || ""),
    authorization_to: yup.string().default(event?.authorization_to || ""),
    election_from: yup.string().default(event?.election_from || ""),
    election_to: yup.string().default(event?.election_to || ""),
    pol_date: yup.string().default(event?.pol_date || ""),
    postal_ballot_last_date: yup
      .string()
      .default(event?.postal_ballot_last_date || ""),
    agenda_from: yup.string().default(event?.agenda_from || ""),
    agenda_to: yup.string().default(event?.agenda_to || ""),
    meeting_start_date: yup.string().default(event?.meeting_start_date || ""),
    meeting_end_date: yup.string().default(event?.meeting_end_date || ""),
    online_conference: yup.string().default(event?.online_conference || ""),
    voting_required: yup.boolean().default(event?.voting_required || false),
    book_closing_from: yup.string().default(event?.book_closing_from || ""),
    book_closing_to: yup.string().default(event?.book_closing_to || ""),
    chairman: yup.string().default(event?.chairman || ""),
  });
